<template>
  <div class="mindMap" :class="classes" :style="cursor" @wheel="wheel">
    <!-- <div style="position: absolute; bottom: 94px; right: 0; width: 15em; z-index: 99">{{ items }}</div> -->
    <transition name="slowFade">
      <svg v-if="showFull" :viewBox="viewBox" ref="map" class="map realMap" @mousedown="clickMap">
        <defs>
          <pattern id="Pattern" x="0" y="0" :width="grid" :height="grid" patternUnits="userSpaceOnUse">
            <circle class="gridDot" cx="0" cy="0" :r="1.5 * zoomMultiplier" />
            <circle class="gridDot" cx="0" :cy="grid" :r="1.5 * zoomMultiplier" />
            <circle class="gridDot" :cx="grid" cy="0" :r="1.5 * zoomMultiplier" />
            <circle class="gridDot" :cx="grid" :cy="grid" :r="1.5 * zoomMultiplier" />
          </pattern>
          <filter id="shadow" width="200" height="200" x="-100" y="-100">
            <feOffset
              result="offOut"
              in="SourceAlpha
              "
              dx="0"
              dy="3"
            />
            <feColorMatrix
              result="matrixOut"
              in="offOut"
              type="matrix"
              values=" 0 0 0 0 0 0
                0 0 0 0 0 0
                0 0 0 0 0 0
                0.15 0"
            />
            <feGaussianBlur result="blurOut" in="matrixOut" stdDeviation="3" />
            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
          </filter>

          <clipPath v-for="item in items" :id="'clip-text-' + item.id" :key="'clip-text-' + item.id">
            <rect
              class="clip"
              ref="clip"
              :width="item.width - 10"
              :height="item.height - 10"
              :x="item.x + 5"
              :y="item.y + 5"
              fill="#cccccc"
              :fill-opacity="item.color === 'none' ? 0 : 1"
            />
          </clipPath>
        </defs>
        <rect
          ref="grid"
          class="grid"
          :x="viewfinderMinX"
          :y="viewfinderMinY"
          :width="(viewfinderMaxX - viewfinderMinX) * zoomMultiplier * 10"
          :height="(viewfinderMaxY - viewfinderMinY) * zoomMultiplier * 10"
          fill="url(#Pattern)"
          fill-opacity="0.1"
        ></rect>
        <mapping-template-item v-for="item in template" :item="item" :key="'templateItem-' + item.id" />
        <transition-group name="map-items">
          <mapping-item v-for="item in items" :item="item" :key="'item-' + item.id" :working="true" />
        </transition-group>
      </svg>
    </transition>
    <transition name="slowFade">
      <svg v-if="showFull" :viewBox="viewBox" ref="cleanMap" class="cleanMap">
        <defs>
          <filter id="shadow">
            <feDropShadow dx="0" dy="2" stdDeviation="5" flood-opacity="0.1" />
          </filter>
        </defs>

        <transition-group name="map-items">
          <mapping-item v-for="item in items" :item="item" :key="'item-' + item.id" :clean="true" />
        </transition-group>
      </svg>
    </transition>

    <div class="viewFinderContainer" :class="[showFull ? 'full' : 'mini']">
      <svg :viewBox="viewfinderViewBox" ref="viewFinder" class="viewFinder">
        <mapping-template-item v-for="item in template" :item="item" :key="'templateItem-' + item.id" :clean="true" />
        <mapping-item v-for="item in items" :item="item" :viewFinder="true" :key="'item-' + item.id" :clean="true" :mini="!showFull" />
        <rect
          v-if="itemCount > 0 && showFull"
          class="viewMarker"
          :x="viewBoxX + viewStroke"
          :y="viewBoxY + viewStroke"
          :width="w - viewStroke * 2"
          :height="h - viewStroke * 2"
          :stroke-width="viewStroke"
          key="viewMarker"
        />
      </svg>
      <button @click="toggleFull">
        <span class="visuallyHidden">Toggle Map</span>
      </button>
    </div>

    <div v-if="showFull" class="overlays">
      <mapping-item-controls v-for="item in items" :item="item" :key="'controls-' + item.id" />
    </div>

    <mapping-edit-item v-if="isDetailedEditing" :item="editingItem" />

    <transition name="slideInRight">
      <div class="zoom" v-if="showFull">
        <input v-model="zoom" type="range" id="zoomLevel" name="zoomLevel" min="25" max="200" />
        <label for="zoomLevel" class="visuallyHidden">zoom</label>
        <span class="level">{{ roundZoom }}%</span>
      </div>
    </transition>
    <transition name="slideInUp">
      <div class="addBox buttonRow" v-if="showFull" :class="latestColor">
        <p>Add Box</p>
        <button @click="addSmallBox">S</button>
        <button @click="addMediumBox">M</button>
        <button @click="addLargeBox">L</button>
      </div>
    </transition>
    <transition name="slideInUp">
      <button class="download" v-if="showFull" @click="download">Download</button>
    </transition>
    <mapping-template-summary v-if="showExtras" />
    <mapping-template-builder v-if="showExtras" />
    <transition name="slideInLeft">
      <avatar-dock v-if="showFull && !usersStore.avatarsAnimating" />
    </transition>
    <transition name="slideInUp">
      <fullscreen-bar v-if="fullscreen && !isInputPhaseOrMirroring" @exit="toggleFull" />
    </transition>
    <transition name="slideInDown">
      <div class="topic" v-if="fullscreen">
        <p v-if="phaseStore.topic">{{ phaseStore.topic }}</p>
        <p v-if="!phaseStore.topic">No topic set</p>
      </div>
    </transition>
  </div>
</template>
<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import MappingItem from '@/components/MappingItem';
import MappingTemplateItem from '@/components/MappingTemplateItem';
import MappingTemplateSummary from '@/components/MappingTemplateSummary';
import MappingTemplateBuilder from '@/components/MappingTemplateBuilder';
import MappingItemControls from '@/components/MappingItemControls';
import MappingEditItem from '@/components/MappingEditItem';
import AvatarDock from '@/components/AvatarDock';

import screenfull from 'screenfull';
import FullscreenBar from '@/components/FullscreenBar';
import fonts from '@/resources/fonts';
import mappingConstants from '@/resources/mapping-constants';
const { sizes } = mappingConstants;
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useMappingStore } from '@/store/pinia/mapping';
import { useMouse } from '@vueuse/core';

export default {
  setup() {
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    const mappingStore = useMappingStore();
    const { x: mouseX, y: mouseY } = useMouse();
    return { frameStore, phaseStore, usersStore, mappingStore, mouseX, mouseY };
  },
  name: 'Mapping',
  data() {
    return {
      publishUpdate: _.debounce(this.publishUpdateRaw, 500),
      showFull: false,
      viewFinderSize: 50,
      showExtras: false,
      move: {},
      draggingArena: false,
      dragOffsetX: null,
      dragOffsetY: null,
      nextRow: 0,
    };
  },
  components: {
    MappingItem,
    MappingTemplateItem,
    MappingTemplateSummary,
    MappingTemplateBuilder,
    MappingItemControls,
    MappingEditItem,
    AvatarDock,
    FullscreenBar,
  },
  mounted() {
    let _this = this;
    this.$ee.on('nM:dialogue:logAdded', _this.logAdded);
    this.$ee.on('nM:dialogue:boxMoved', _this.updateNextRow);
    this.$ee.on('nM:dialogue:boxAdded', _this.publishAdd);
    this.$ee.on('nM:dialogue:boxDeleted', _this.publishDelete);
    this.$ee.on('nM:dialogue:update', _this.publishUpdate);
  },
  unmounted() {
    let _this = this;
    this.$ee.off('nM:dialogue:logAdded', _this.logAdded);
    this.$ee.off('nM:dialogue:boxMoved', _this.updateNextRow);
    this.$ee.off('nM:dialogue:boxAdded', _this.publishAdd);
    this.$ee.off('nM:dialogue:boxDeleted', _this.publishDelete);
    this.$ee.off('nM:dialogue:update', _this.publishUpdate);
  },
  computed: {
    ...mapPiniaState(useMappingStore, {
      showFullRaw: 'showFull',
      zoomLevel: 'zoom',
      isDragging: 'dragging',
      isEditing: 'editing',
      isDetailedEditing: 'detailedEditing',
      isResizing: 'resizing',
    }),
    ...mapPiniaState(useMappingStore, [
      'grid',
      'snap',
      'latestColor',
      'advanced',
      'viewBoxX',
      'viewBoxY',
      'items',
      'template',
      'editingItem',
      'itemCount',
      'zoomMultiplier',
      'currentItem',
      'itemCentre',
    ]),
    ...mapPiniaState(useUsersStore, ['myId', 'me']),
    ...mapPiniaState(useFrameStore, {
      w: 'contWidth',
      h: 'contHeight',
      fullscreen: 'fullscreen',
    }),
    isInputPhase() {
      return this.phaseStore.currentPhase === 'input';
    },
    isInputPhaseOrMirroring() {
      return this.isInputPhase || this.phaseStore.mirroring?.requestedBy;
    },
    zoom: {
      get() {
        return this.zoomLevel;
      },
      set(v) {
        this.setZoom(v);
      },
    },
    roundZoom() {
      return Math.round(this.zoom);
    },
    cursor() {
      return `cursor: ${this.dragOffsetX ? 'grabbing' : 'grab'}`;
    },
    viewBox() {
      return `${this.viewBoxX} ${this.viewBoxY} ${this.w * this.zoomMultiplier} ${this.h * this.zoomMultiplier}`;
    },
    cleanViewBox() {
      return `${this.minX - 10} ${this.minY - 10} ${this.width + 20} ${this.height + 20}`;
    },
    viewfinderMinX() {
      return this.minX && this.minX < this.viewBoxX ? this.minX : this.viewBoxX;
    },
    viewfinderMinY() {
      return this.minY && this.minY < this.viewBoxY ? this.minY : this.viewBoxY;
    },
    viewfinderMaxX() {
      let w = this.viewBoxX + this.w;
      return this.maxX && this.maxX > w ? this.maxX : w;
    },
    viewfinderMaxY() {
      let h = this.viewBoxY + this.h;
      return this.maxY && this.maxY > h ? this.maxY : h;
    },
    viewfinderWidth() {
      let w = this.viewfinderMaxX - this.viewfinderMinX;
      return w > 10 ? w : 10;
    },
    viewfinderHeight() {
      let h = this.viewfinderMaxY - this.viewfinderMinY;
      return h > 10 ? h : 10;
    },
    viewfinderViewBox() {
      return `${this.viewfinderMinX} ${this.viewfinderMinY} ${this.viewfinderWidth} ${this.viewfinderHeight}`;
    },
    moving() {
      return this.isDragging || this.isResizing;
    },
    classes() {
      return {
        isDragging: this.isDragging,
        isResizing: this.isResizing,
        isEditingText: this.isEditing,
      };
    },
    minX() {
      let xs = Object.values(this.items).map((item) => item.x);
      return xs.length ? Math.min(...xs) : '0';
    },
    maxX() {
      let xs = Object.values(this.items).map((item) => item.x + item.width);
      return xs.length ? Math.max(...xs) : '10';
    },
    minY() {
      let xs = Object.values(this.items).map((item) => item.y);
      return xs.length ? Math.min(...xs) : '0';
    },
    maxY() {
      let xs = Object.values(this.items).map((item) => item.y + item.height);
      return xs.length ? Math.max(...xs) : '10';
    },
    width() {
      return this.maxX - this.minX;
    },
    height() {
      return this.maxY - this.minY;
    },
    biggerThanViewport() {
      return this.width > this.w || this.height > this.h;
    },
    viewStroke() {
      if (this.viewfinderWidth > this.viewfinderHeight) {
        return this.viewfinderWidth / this.viewFinderSize;
      } else {
        return this.viewfinderHeight / this.viewFinderSize;
      }
    },
  },
  watch: {
    'frameStore.mouseDown': function (nv) {
      if (!nv && this.draggingArena) {
        this.dropArena();
      }
    },
    showFullRaw(nv) {
      let delay = nv ? 500 : 0;
      this.$nextTick(() => {
        _.delay(() => {
          this.showFull = nv;
          if (nv) {
            _.delay(this.panToStart, 1000);
          }
        }, delay);
      });
    },
    fullscreen(nv) {
      if (nv) {
        document.getElementById('mainFrame').classList.add('fullscreenOn');
        document.body.classList.add('isFullScreen');
      } else {
        document.getElementById('mainFrame').classList.remove('fullscreenOn');
        document.body.classList.remove('isFullScreen');
      }
    },
    'phaseStore.currentPhase': function () {
      this.unfull();
    },
  },
  methods: {
    toggleFull() {
      if (this.showFull) {
        this.unfull();
      } else {
        this.triggerFull();
      }
      this.mappingStore.toggleFullMap();
      this.mappingStore.setEditingMapItem(false);
    },
    triggerFull() {
      if ((screenfull.enabled || screenfull.isEnabled) && !this.fullscreen) {
        const mainFrame = document.getElementById('mainFrame');
        screenfull.toggle(mainFrame).catch(() => {});
      }
    },
    unfull() {
      if ((screenfull.enabled || screenfull.isEnabled) && this.fullscreen) {
        const mainFrame = document.getElementById('mainFrame');
        screenfull.toggle(mainFrame).catch(() => {});
      }
    },
    setZoom(v, e) {
      let ratioX = 0.5;
      let ratioY = 0.5;
      if (e) {
        ratioX = e.offsetX / this.w;
        ratioY = e.offsetY / this.h;
      }

      let newZoomMultiplier = 100 / v;

      let newW = this.w * newZoomMultiplier;
      let newH = this.h * newZoomMultiplier;

      let oldW = this.w * this.zoomMultiplier;
      let oldH = this.h * this.zoomMultiplier;

      let dX = oldW * ratioX - newW * ratioX;
      let dY = oldH * ratioY - newH * ratioY;

      this.mappingStore.updateViewBoxPosition({
        x: this.viewBoxX + dX,
        y: this.viewBoxY + dY,
        jump: true,
      });
      this.mappingStore.setZoom(v);
    },
    logAdded(data) {
      let y = this.nextRow || this.grid;
      let x = this.grid;
      const id = `card_${this.myId}_${Object.keys(this.items).length}`;

      // find furthest thing along if not new
      let rowItems = Object.values(this.items).filter((item) => item.y === y);
      if (rowItems.length) {
        let xs = Object.values(rowItems).map((item) => item.x + item.width);
        x = Math.max(...xs) + this.grid;
      }

      // Use furthest along + grid if not new, or lease

      //TODO: remove this once we have history?

      data.cardArgs = {
        localId: id,
        height: this.grid * sizes.m.card,
        width: this.grid * sizes.m.card,
        fontSize: sizes.m.font,
        x,
        y,
        color: this.noteTypeToColor(data.type),
        type: 'card',
        logType: data.type,
        text: data.description,
      };

      this.panToObject(data.cardArgs).then(() => {
        this.mappingStore.addMappingItem(data.cardArgs);
        this.$meetingmanager.addLog(data); // actually sends log
      });
    },
    panToStart() {
      let ids = Object.keys(this.items);
      if (ids.length > 0) {
        this.panToObject(this.items[ids[0]]);
      }
    },
    publishUpdateRaw(data) {
      let logData = {};
      const item = this.items[data.localId];
      if (item?.id) {
        logData.description = item.text;
        logData.type = item.logType;
        logData.cardArgs = item;
        logData.id = item.id;
        logData.editedBy = this.myId;
        this.$meetingmanager.updateLog(logData);
      }
    },
    publishAdd(data) {
      let logData = {};
      logData.description = data.text || '';
      logData.type = data.logType;
      logData.cardArgs = data;
      logData.phaseId = this.phaseStore.currentPhaseId;
      this.$meetingmanager.addLog(logData);
    },
    publishDelete(item) {
      this.$meetingmanager.deleteLog(item.id);
    },
    updateNextRow() {
      this.nextRow = this.maxY + this.grid;
    },
    panToObject(item) {
      // return `${this.viewBoxX} ${this.viewBoxY} ${this.w * this.zoomMultiplier} ${this.h * this.zoomMultiplier}`;
      return new Promise((resolve) => {
        if (this.showFull) {
          let { x, y, width, height } = item;
          let viewBoxX = this.viewBoxX;
          let viewBoxY = this.viewBoxY;

          let cx = (this.w * this.zoomMultiplier) / 2 + viewBoxX;
          let cy = (this.h * this.zoomMultiplier) / 2 + viewBoxY;
          let itemCx = x + width / 2;
          let itemCy = y + height / 2;

          let dx = itemCx - cx;
          let dy = itemCy - cy;

          Velocity(
            this.$refs.map,
            {
              tween: 1,
            },
            {
              progress: (elements, complete, remaining, start, tweenValue) => {
                this.mappingStore.updateViewBoxPosition({
                  x: viewBoxX + dx * tweenValue,
                  y: viewBoxY + dy * tweenValue,
                  jump: true,
                });
              },
              easing: 'easeInOutQuint',
              duration: 800,
              complete: resolve,
            },
          );
        } else {
          resolve();
        }
      });
    },
    noteTypeToColor(t) {
      return {
        insight: 'yellow',
        question: 'orange',
        action: 'green',
        decision: 'blue',
      }[t];
    },
    wheel(e) {
      if (e.ctrlKey) {
        e.preventDefault();
        let newZoom = this.zoomLevel - e.deltaY * 0.75;
        newZoom = newZoom < 200 ? newZoom : 200;
        newZoom = newZoom > 25 ? newZoom : 25;
        this.setZoom(newZoom, e);
      }
    },
    clickMap(ev) {
      if (ev.target === this.$refs.map || ev.target === this.$refs.grid) {
        this.mappingStore.setEditingMapItem(false);
        this.dragArena(ev);
      }
    },
    setTemplate(t) {
      if (t === 'feedback') {
        this.mappingStore.setTemplate({
          good: {
            id: 'good',
            x: 1 * this.grid,
            y: 3 * this.grid,
            width: 10 * this.grid,
            height: 25 * this.grid,
            title: 'Good',
            color: 'green',
          },
          tricky: {
            id: 'tricky',
            x: 12 * this.grid,
            y: 3 * this.grid,
            width: 10 * this.grid,
            height: 25 * this.grid,
            title: 'Tricky',
            color: 'orange',
          },
          different: {
            id: 'different',
            x: 23 * this.grid,
            y: 3 * this.grid,
            width: 10 * this.grid,
            height: 25 * this.grid,
            title: 'Different',
            color: 'blue',
          },
        });
      } else {
        this.mappingStore.setTemplate(false);
      }
    },
    dragArena({ offsetX, offsetY }) {
      this.dragOffsetX = offsetX;
      this.dragOffsetY = offsetY;
      this.draggingArena = true;
      this.mappingStore.setDraggingArena(true);
      this.$refs.map.addEventListener('mousemove', this.moveArena);
    },
    moveArena({ offsetX, offsetY }) {
      this.mappingStore.updateViewBoxPosition({
        x: (offsetX - this.dragOffsetX) * -1,
        y: (offsetY - this.dragOffsetY) * -1,
      });
      this.dragOffsetY = offsetY;
      this.dragOffsetX = offsetX;
    },
    dropArena() {
      this.draggingArena = false;
      this.mappingStore.setDraggingArena(false);
      this.dragOffsetX = this.dragOffsetY = null;
      this.$refs.map.removeEventListener('mousemove', this.moveArena);
    },
    addSmallBox({ clientX, clientY }) {
      this.addBox({
        clientX,
        clientY,
        height: this.grid * sizes.s.card,
        width: this.grid * sizes.s.card,
        fontSize: sizes.s.font,
      });
    },
    addMediumBox({ clientX, clientY }) {
      this.addBox({
        clientX,
        clientY,
        height: this.grid * sizes.m.card,
        width: this.grid * sizes.m.card,
        fontSize: sizes.m.font,
      });
    },
    addLargeBox({ clientX, clientY }) {
      this.addBox({
        clientX,
        clientY,
        height: this.grid * sizes.l.card,
        width: this.grid * sizes.l.card,
        fontSize: sizes.l.font,
      });
    },
    addBox({ clientX, clientY, height, width, fontSize }) {
      const id = `card_${this.myId}_${Object.keys(this.items).length}`;
      this.mappingStore.setAdding(id);

      let args = {
        localId: id,
        height,
        width,
        fontSize,
        x: clientX * this.zoomMultiplier - width / 2,
        y: clientY * this.zoomMultiplier - height / 2,
        color: this.latestColor,
        type: 'card',
        logType: 'note',
        text: '',
      };
      this.mappingStore.addMappingItem(args);
    },
    download() {
      const filename = `my_map.svg`;
      let text = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="${this.cleanViewBox} ">
      <defs>
        <style>
          @font-face{
              font-family:"ProximaNovaAlt";
              src:url(data:application/font-woff;charset=utf-8;base64,${fonts.proximaNovaAltRegular}) format("woff");
              font-weight:normal;font-style:normal;
          }
          text {
            font-family:ProximaNovaAlt;
          }
        </style>
      </defs>
        ${this.$refs.cleanMap.innerHTML}
      </svg>`;
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', filename);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.slideInDown-enter-active,
.slideInRight-enter-active,
.slideInLeft-enter-active,
.slideInUp-enter-active {
  transition: all 0.3s cubic-bezier(0, 1, 1, 1);
}

.slideInDown-leave-active,
.slideInRight-leave-active,
.slideInLeft-leave-active,
.slideInUp-leave-active {
  transition: all 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

:deep(.dockBackground.slideInLeft-enter-active) {
  transition: all 0.3s cubic-bezier(0, 1, 1, 1) 0.5s;
}

.slideInRight-enter-from,
.slideInRight-leave-to {
  transform: translateX(-100%);
}
.slideInLeft-enter-from,
.slideInLeft-leave-to {
  transform: translateX(100%);
}
.slideInUp-enter-from,
.slideInUp-leave-to {
  transform: translateY(100%);
}
.slideInUp-enter-from,
.slideInUp-leave-to {
  transform: translateY(-100%);
}

.map-items-enter-from,
.map-items-leave-to {
  opacity: 0;
}

.map-items-enter-active,
.map-items-leave-active {
  transition: opacity 0.3s;
}
// .realMap.slowFade-enter-active,
// .cleanMap.slowFade-enter-active {
//   transition: opacity 1s 1.5s;
// }

// MAP STUFF ----

.gridDot {
  fill: var(--c__text);
}
.map {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.cleanMap {
  pointer-events: none;
  opacity: 0;
}

.mindMap:focus {
  outline: none;
}

.isDragging text,
.isResizing text {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.settings {
  position: absolute;
  top: 1em;
  left: 1em;
  li + li {
    margin-top: 0.5em;
  }
}

.download,
.addBox,
.templates {
  position: absolute;
  bottom: 1em;
  p {
    margin-bottom: 0.5em;
  }
  button {
    display: inline-block;
  }
}

.download {
  right: 1em;
  z-index: 40;
}

.addBox {
  left: 1em;
  z-index: 40;
  button {
    height: 2em;
    width: 2em;
    border-style: solid;
    border-width: 4px;
    background: var(--c__bg);
    display: inline-block;
    padding: 0;
    @include triggered {
      background: var(--c__text);
      color: var(--c__bg);
    }
  }

  @each $color, $col in $note_full-colors {
    &.#{$color} button {
      border-color: map-get($col, border);
      @include triggered {
        background: map-get($col, border);
      }
    }
  }
}

.buttonRow button {
  margin-right: 0.5em;
}

.templates {
  left: 10em;
}

.overlays {
  position: absolute;
  top: 0;
  left: 0;
}
.viewFinderContainer {
  position: absolute;
  background: var(--c__bg);
  padding: 0.25em;
  border: 2px solid var(--c__text);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 600ms cubic-bezier(0.5, 0, 0.5, 1);

  &.full {
    bottom: calc(100% - #{rem(109px)} - #{rem(56px)});
    right: calc(100% - #{rem(109px)});
    height: rem(98px);
    width: rem(98px);
  }

  &.mini {
    bottom: rem($log-height + 11px);
    right: rem(11px);
    height: rem(50px);
    width: rem(50px);
  }
  button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  svg {
    display: block;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .viewMarker {
    fill: none;
    stroke: $c_blue;
  }
}

.zoom {
  height: 17em;
  position: absolute;
  top: rem(118px + 56px);
  left: 1em;
  width: 1em;
  padding-top: 16em;

  input {
    // height: 1em;
    position: absolute;
    width: 15em;
    top: 0;
    right: 1em;
    transform: rotateZ(0.75turn);
    transform-origin: top right;
  }
  .level {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.topic {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: rem(17px);
  min-height: rem(56px);
  background: var(--c__bg-alt);
  @include type-size('medium');
  font-weight: 300;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--c__edges);
  p {
    margin-bottom: 0;
  }
}
.mouse {
  position: absolute;
  top: 0;
  right: 1em;
  z-index: 999;
}
</style>
