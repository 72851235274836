<template>
  <div class="subContent">
    <h2 v-if="!accountStore.canCreate" key="toJoin">Already invited to a bixe meeting?<br />To join the meeting, click on your invite link.</h2>

    <h2 v-if="nextMeetings && accountStore.canCreate" key="welcomeHeader">Welcome to bixe</h2>

    <transition-group name="basic" tag="div" v-if="accountStore.canCreate" class="content welcomeContent" id="welcomeHolder" key="welcomeContent">
      <div class="actions" key="actions">
        <app-dropdown-button
          class="solo"
          :pending="isCreateRequestInProgress"
          :options="meetingTypeOptions"
          v-model="selectedMeetingType"
          @confirm="startNewMeeting"
          >Start a meeting</app-dropdown-button
        >
        <a v-if="accountStore.canCreate" id="welcomeSchedule" class="quickstart btn btn-border solo" href="/conference/schedule/meeting-name"
          >Schedule new meeting</a
        >
      </div>

      <div v-if="meetings && hasMeetings" class="upcoming" key="meetings">
        <ul>
          <meeting-listing v-for="meeting in nextMeetings" :key="meeting.id" :meeting="meeting" @navigate="navigate" />
        </ul>
      </div>

      <div v-if="loading" class="loadingMeetings" key="loading"><app-loading-spinner /> Checking for upcoming meetings&hellip;</div>
    </transition-group>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { useRouter } from 'vue-router';
import { ref, computed, onMounted, onBeforeUnmount, getCurrentInstance, watch } from 'vue';
import { nM } from '@/legacy';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import MeetingListing from '@/components/WelcomeMeetingListing';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import AppDropdownButton from '@/components/AppDropdownButton.vue';
import utils from '../../resources/utils';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $API = getCurrentInstance().appContext.config.globalProperties.$API;
const router = useRouter();

const props = defineProps(['pageName']);

const accountStore = useAccountStore();
const pageStore = usePageStore();

const meetings = ref(null);
const loading = ref(false);
const isCreateRequestInProgress = ref(false);
const selectedMeetingType = ref(accountStore.preferredMeetingType);
const updatedPreferredMeetingType = ref('');

const meetingTypeOptions = [
  { label: 'Unique Meeting ID', value: 'quickstart' },
  { label: 'Use Personal Room', value: 'personalroom' },
];

const nextMeetings = computed(() => {
  if (!meetings.value || !meetings.value.length) {
    return [];
  } else {
    return meetings.value.length > 1 ? [meetings.value[0], meetings.value[1]] : [meetings.value[0]];
  }
});
const hasMeetings = computed(() => nextMeetings.value.length > 0);

watch(selectedMeetingType, (nv) => {
  updatedPreferredMeetingType.value = nv;
});

onMounted(() => {
  pageStore.update({
    pageName: props.pageName,
    meetings: meetings.value,
  });
  if (utils.isOpenedWithinElectronShell()) {
    ipcApi.send({ name: 'checkIsOnLatestVersion' });
  }

  const meetingId = utils.storage.ls.getItem('RELAUNCH_MEETING_ID');
  if (meetingId) {
    $API
      .getMeeting(meetingId)
      .then((meeting) => {
        if (['ready', 'pending', 'started'].includes(meeting.status)) {
          utils.storage.ls.removeItem('RELAUNCH_MEETING_ID');
          navigate('/meeting/' + meetingId);
        }
      })
      .catch(() => {});
  } else {
    loading.value = true;
    $API
      .getForthcomingMeetings()
      .then((forthcoming) => {
        loading.value = false;
        _.delay(() => {
          meetings.value = forthcoming;
        }, 500);
      })
      .catch((err) => {
        console.error(err);
        $ee.emit('api:alertAdd', {
          type: 'misc',
          class: 'error',
          duration: 'temporary',
          content: nM.toErrorMessage(err),
        });
      });
  }
});
onBeforeUnmount(() => {
  isCreateRequestInProgress.value = false;
});

function navigate(where) {
  router.push(where).catch(() => null);
}

function startNewMeeting() {
  if (isCreateRequestInProgress.value) {
    return;
  }
  isCreateRequestInProgress.value = true;
  let promise = null;
  const isRoom = selectedMeetingType.value === 'personalroom';
  if (isRoom) {
    const currentUser = accountStore.currentUser;
    const params = { pinNumber: currentUser.pin_number, password: currentUser.pin_pass, preferredMeetingType: updatedPreferredMeetingType.value };
    promise = utils.genericFns.joinPersonalRoomMeeting(params);
  } else {
    promise = utils.genericFns.quickStartMeeting({ preferredMeetingType: updatedPreferredMeetingType.value });
  }
  promise
    .then((meeting) => {
      utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
      router.push(`/quickstart/${meeting.id}`).catch(() => null);
      if (updatedPreferredMeetingType.value) {
        accountStore.updatePreferredMeetingType(updatedPreferredMeetingType.value);
        updatedPreferredMeetingType.value = '';
      }
    })
    .catch((err) => {
      isCreateRequestInProgress.value = false;
      setMeetings(err, null);
    });
}

function setMeetings(err, meetings) {
  if (err) {
    $ee.emit('api:alertAdd', {
      type: 'misc',
      class: 'error',
      duration: 'temporary',
      content: nM.toErrorMessage(err),
    });
  } else {
    meetings.value = meetings;
  }
}
</script>
<style lang="scss" scoped>
.welcomeContent {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  gap: rem(12px);
  width: rem(530px);

  .loadingMeetings {
    width: rem(256px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: rem(12px);
    color: var(--c__accent);
    .loadingSpinner {
      display: inline-block;
      margin-bottom: rem(2px);
    }
  }

  .solo {
    width: rem(256px);
  }

  .solo + .solo {
    margin-top: rem(13px);
  }

  .meetingListing + .meetingListing {
    margin-top: rem(13px);
  }

  .more {
    @include type-size('large');
    color: var(--c__text);
    font-weight: 100;

    @include triggered {
      color: var(--c__accent);
    }
  }

  @media screen and (max-width: 538px) {
    .upcoming {
      margin-top: rem(13px);
    }
  }
}
</style>
