<template>
  <div class="flipHome pane agendaSidebar" :class="[meetingStore.isScheduled ? 'scheduled' : 'quick']">
    <transition-group name="flip-bits" tag="section" class="flipContent" @enter="enter" @leave="leave" @afterEnter="afterEnter">
      <app-icon icon="po3" class="po3Header" key="header" />
      <meeting-info
        v-bind="$attrs"
        id="flipTitleSection"
        key="flipTitle"
        :field="meetingStore.name"
        :edited-by="meetingStore.editing.name"
        name="name"
        edit-name="title"
        default-text="Add meeting title..."
        type="heading"
        truncate="false"
      />

      <h3 key="flipPurposeHeading" class="infoHeading">Purpose</h3>
      <meeting-info
        v-bind="$attrs"
        id="flipPurposeSection"
        key="flipPurpose"
        :field="meetingStore.purpose"
        :edited-by="meetingStore.editing.purpose"
        expanded="true"
        name="purpose"
        default-text="Add meeting purpose"
        type="paragraph"
        truncate="true"
      />

      <h3 class="infoHeading" key="flipObjectivesHeading">Objectives</h3>
      <meeting-info
        v-bind="$attrs"
        id="flipObjectivesSection"
        key="flipObjectives"
        :field="meetingStore.objectives"
        :edited-by="meetingStore.editing.objectives"
        expanded="true"
        name="objectives"
        default-text="Add meeting objectives..."
        type="list"
        truncate="false"
      />

      <h3 v-if="meetingStore.outputs.length > 0" class="infoHeading" key="flipOutputssHeading">Outputs</h3>
      <meeting-info
        v-bind="$attrs"
        v-if="meetingStore.outputs.length > 0"
        id="flipOutputsSection"
        key="flipOutputs"
        :field="meetingStore.outputs"
        :edited-by="meetingStore.editing.outputs"
        expanded="true"
        name="outputs"
        default-text="Add meeting outputs..."
        type="list"
        truncate="false"
      />

      <h3 v-if="meetingStore.outcomes.length > 0" class="infoHeading" key="flipOutcomesHeading">Outcomes</h3>
      <meeting-info
        v-bind="$attrs"
        v-if="meetingStore.outcomes.length > 0"
        id="flipOutcomesSection"
        key="flipOutcomes"
        :field="meetingStore.outcomes"
        :edited-by="meetingStore.editing.outcomes"
        expanded="true"
        name="outcomes"
        default-text="Add meeting outcomes..."
        type="list"
        truncate="false"
      />

      <div v-if="!meetingStore.isScheduled" class="lobbyTimerHolder" key="flipLobbyTime">
        <timer-disc
          v-bind="$attrs"
          id="lobbyTimer"
          type="meetingSetter"
          :current="timeStore.meetingElapsed"
          :total="timeStore.meetingLength"
          :passed-setting-time="timeStore.meetingLength"
          :remote-setting-time="timeStore.meetingLength"
          :remaining="timeStore.meetingLength"
          :showing="isLobby"
          class="setMeetingTime"
        />
      </div>

      <div key="flipSpacer" class="spacer" />
    </transition-group>

    <lobby-sidebar-schedule key="timeInfo" v-if="meetingStore.isScheduled" />

    <transition name="bottom-notice">
      <div key="flipStart" class="bottomButtonContainer">
        <button
          v-if="isLobby"
          id="startMeeting"
          ref="start"
          class="enter startButton btn btn-solid btn-loud-green btn-blue-flood"
          :class="startClass"
          @click="requestStart"
        >
          <span v-if="!showLoading">Start Meeting</span>
          <span v-if="showLoading">Loading </span>
          <app-loading-spinner v-if="showLoading" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { useSidebarStore } from '@/store/pinia/sidebar';
import MeetingInfo from '@/components/MeetingInfo';
import TimerDisc from '@/components/TimerDisc.vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import AppIcon from '@/components/AppIcon.vue';
import LobbySidebarSchedule from './LobbySidebarSchedule.vue';
import utils from '../resources/utils';

export default {
  components: {
    MeetingInfo,
    TimerDisc,
    AppLoadingSpinner,
    AppIcon,
    LobbySidebarSchedule,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    const timeStore = useTimeStore();
    return { meetingStore, messagesStore, phaseStore, usersStore, timeStore };
  },
  data() {
    return {
      flashHeyStart: false,
      expanded: false,
      userExpanded: false,
      userInteractedWithToggle: false,
      isLobby: true,
      showingNotes: false,
      debouncedIsLobbyCheck: _.debounce(this.isLobbyCheck, 500),
      debouncedToggleExpand: _.debounce(this.toggleExpand, 200),
      bubbleShowing: 'waitForOwner',
      showLoading: false,
    };
  },
  created() {
    this.$ee.on('toggleExpand', () => {
      this.expanded = !this.expanded;
    });
    this.$ee.on('bus:expandInfo', this.expandInfo);
    this.$nextTick(() => {
      this.isLobby = this.phaseStore.currentPhase === 'lobby';
      this.expanded = this.infoExpanded;
    });
  },
  mounted() {},
  beforeUnmount() {
    this.$ee.off('bus:expandInfo', this.expandInfo);
  },
  computed: {
    ...mapPiniaState(useSidebarStore, ['notice']),
    ...mapPiniaState(useUsersStore, ['owner', 'iAmModerator', 'usersOnline', 'secondUserTime']),
    animating() {
      return !this.meetingStore.initTitleCardShown || !this.usersStore.firstShown || this.phaseStore.isAnimating;
    },
    isLobbyRaw() {
      return this.phaseStore.currentPhase === 'lobby';
    },
    showMenu() {
      return !this.isLobby;
    },
    startClass() {
      if (this.secondUserTime > 300) {
        return 'seriouslyHey';
      } else {
        return this.flashHeyStart ? 'hey' : '';
      }
    },
    infoExpanded() {
      if (this.userInteractedWithToggle) {
        return this.userExpanded;
      } else {
        return this.isLobby;
      }
    },
    toggleLabel() {
      return this.expanded ? 'less' : 'more';
    },
    shouldFlash() {
      return !this.meetingStore.isRoom || this.iAmModerator;
    },
  },
  watch: {
    'phaseStore.currentPhase': function (nv) {
      if (nv !== 'lobby') {
        this.flashHeyStart = false;
        this.showLoading = false;
      }
      this.debouncedIsLobbyCheck(nv === 'lobby');
    },
    isLobbyRaw: {
      immediate: true,
      handler(nv) {
        this.debouncedIsLobbyCheck(nv);
      },
    },
    infoExpanded: {
      immediate: true,
      handler(nv) {
        this.debouncedToggleExpand(nv);
      },
    },
    animating() {
      if (this.shouldFlash && this.phaseStore.currentPhase === 'lobby') {
        _.delay(() => {
          if (this.phaseStore.currentPhase === 'lobby') {
            this.heyStart();
          }
        }, 60000);
      }
    },
  },
  methods: {
    requestStart() {
      if (this.meetingStore.isRoom && !this.iAmModerator) {
        this.messagesStore.addAlert('pleaseWaitForRoomOwner', { ownerName: utils.desanitizeString(this.owner.first_name) });
      } else if (!this.usersStore.haveModeratorsJoined) {
        this.messagesStore.addAlert('pleaseWaitForOwner', { ownerName: utils.desanitizeString(this.owner.first_name) });
      } else if (this.usersOnline.length < 2) {
        this.messagesStore.addAlert('pleaseWaitForUsers');
      } else {
        let check = this.messagesStore.interrupt('sureYouWantToStartMeeting', {});
        check
          .then(() => {
            if (this.phaseStore.currentPhase === 'lobby') {
              this.$meetingmanager.startMeeting();
              this.showLoading = true;
            }
          })
          .catch(() => {});
      }
    },
    confirmStart() {},
    heyStart() {
      this.flashHeyStart = true;
    },
    toggleInfo() {
      this.userInteractedWithToggle = true;
      this.userExpanded = !this.userExpanded;
    },
    expandInfo() {
      this.userInteractedWithToggle = true;
      this.expanded = true;
      this.userExpanded = true;
    },
    contractInfo() {
      this.userInteractedWithToggle = true;
      this.expanded = false;
      this.userExpanded = false;
    },
    toggleExpand(nv) {
      this.expanded = nv;
    },
    isLobbyCheck(nv) {
      this.isLobby = nv;
    },
    enter(el, done) {
      const cont = this.$parent.$refs.container;
      cont.style.maxHeight = cont.offsetHeight;
      cont.style.overflow = 'hidden';
      el.classList.add('flip-bits-enter-active');
      el.classList.add('flip-bits-enter');
      this.$nextTick(() => {
        el.classList.remove('flip-bits-enter');
      });
      _.delay(() => {
        el.classList.remove('flip-bits-enter-active');
        cont.style.maxHeight = 'auto';
        cont.style.overflow = 'auto';
        done();
      }, 1000);
    },
    afterEnter(el) {
      el.classList.remove('flip-bits-enter-to');
    },
    leave(el, done) {
      el.classList.add('flip-bits-leave-active');
      el.classList.add('flip-bits-leave-to');
      _.delay(() => {
        el.classList.remove('flip-bits-leave-active');
        el.classList.remove('flip-bits-leave-to');
        done();
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.flip-bits-move,
.bixeSidebar .sidebarTimer.flip-bits-move {
  transition: opacity 0.3s 1s, transform 0.6s;
}

.flip-bits-enter-active.skillMenu,
.flip-bits-enter-active.flip-bits-move.skillMenu {
  transition: opacity 0.3s, transform 0.6s;
}

.skillMenu {
  transition: opacity 0.3s 1s, transform 0.6s;
}

.flip-bits-enter-active,
.flip-bits-enter-active.flip-bits-move {
  transition: all 0.3s 0.3s;
}

.flip-bits-leave-active,
.flip-bits-leave-active.flip-bits-move,
.flip-bits-leave-to.flip-bits-move {
  transition: opacity 0.3s linear, transform 0.3s cubic-bezier(0.5, 0, 1, 0.5);
  // transition: all .3s linear;
  position: absolute !important;
}

.flip-bits-enter-from,
.flip-bits-leave-to {
  opacity: 0;
}

.flip-bits-leave-to {
  margin-top: 0;
}

:deep(.infoHeading) {
  padding: 0 rem($sidebar-left-pad);
  flex: 0 0 auto;
  font-weight: bold;
  display: inline-block;
  margin-top: 1em;
}

.lobbyTimerHolder {
  margin: auto;
  width: 100%;
}

.startButton {
  &.hey {
    animation: please-start 8s infinite linear;
    transition: all 0s;

    @include triggered {
      animation: none;
    }
  }

  &.seriouslyHey {
    animation: seriously-please-start 2.5s infinite linear;
    transition: all 0s;

    @include triggered {
      animation: none;
    }
  }
}

.spacer {
  height: rem(150px);
  flex: 0 0 rem(150px);
  width: 100%;
  display: block;
}

.scheduled .spacer {
  height: rem(230px);
  flex: 0 0 rem(230px);
}
</style>
