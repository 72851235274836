<template>
  <div class="pane outsideMenuSidebar">
    <div class="outsideMenu">
      <section v-if="has.conference" class="generalMenu">
        <h2>Meetings</h2>
        <ul>
          <li>
            <router-link to="/welcome">Home</router-link>
          </li>
          <li><a href="/conference/schedule/meeting-name">Schedule</a></li>
          <li class="joinItem">
            <router-link to="/join">Join</router-link>
          </li>
          <li>
            <a href="/conference/schedule/meetings">Meeting List</a>
          </li>
          <li>
            <router-link to="/my-room">Personal Room</router-link>
          </li>
          <li v-if="!isOpenedWithinElectronShell">
            <a :href="downloadLink" @click="navigate('/app/downloading')">Download App</a>
          </li>
          <li v-if="accountStore.canCreate">
            <router-link :disabled="isAdvancedSettingsDisabled" to="/advanced-settings">Advanced Settings</router-link>
          </li>
        </ul>
      </section>

      <section v-if="['appIndex', 'joinWithLink'].includes(pageStore.current.pageName)" class="generalMenu">
        <h2>Menu</h2>
        <ul>
          <li>
            <button @click="navigate('/join-with-link')">Join Meeting</button>
          </li>
          <li>
            <a v-if="isOpenedWithinElectronShell" href="#" @click="redirectToAuth('sign_in')">Log In</a>
            <a v-else :href="`/lauth/nowhere?returnUrl=${accountStore.baseUrl}`">Log In</a>
          </li>
        </ul>
      </section>
      <section v-else-if="!has.conference" class="generalMenu">
        <h2 v-if="accountStore.isGuest">Meetings</h2>
        <h2 v-else>Menu</h2>
        <ul>
          <li>
            <router-link to="/join">Join Meeting</router-link>
          </li>
          <li v-if="!isOpenedWithinElectronShell">
            <a :href="downloadLink" @click="navigate('/app/downloading')">Download App</a>
          </li>

          <li v-if="accountStore.isGuest || !accountStore.isLoggedIn">
            <button @click="redirectToAuth('sign_in')">Log In</button>
          </li>
        </ul>
      </section>

      <section v-if="has.account && has.type === 'owner' && (has.account.bixe_ea_account === 2 || has.account.bixe_ea_account === 4)" class="accountMenu">
        <h2>{{ has.account.name }}</h2>
        <ul>
          <li>
            <a v-if="isOpenedWithinElectronShell" href="#" @click="openExternal(accountSettingsUrl)">Account Settings</a>
            <a v-else :href="accountSettingsUrl" target="_blank" rel="noopener">Account Settings</a>
          </li>
          <li />
        </ul>
      </section>
      <section class="userMenu" v-if="userName">
        <h2>{{ userName }}</h2>
        <ul>
          <li v-if="!accountStore.isGuest">
            <a v-if="isOpenedWithinElectronShell" href="#" @click="openExternal(userSettingsUrl)">User Settings</a>
            <a v-else :href="userSettingsUrl" target="_blank" rel="noopener">User Settings</a>
          </li>
          <li v-if="!accountStore.isGuest && accountSettingsUrl && has.account.bixe_ea_account === 1">
            <a v-if="isOpenedWithinElectronShell" href="#" @click="openExternal(accountSettingsUrl)">Account Settings</a>
            <a v-else :href="accountSettingsUrl" target="_blank" rel="noopener">Account Settings</a>
          </li>
          <li>
            <a v-if="isOpenedWithinElectronShell" href="#" @click="openExternal('https://www.bixe.ai/support.html')">Support Request</a>
            <a v-else href="https://www.bixe.ai/support.html" target="_blank" rel="noopener">Support Request</a>
          </li>
          <li v-if="!accountStore.isGuest">
            <a href="javascript:void(0)" @click="logoutHandler()">Log Out</a>
            <app-loading-spinner v-if="isLogoutInProgress" class="logout-loader" />
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import utils from '../resources/utils';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';

export default {
  components: {
    AppLoadingSpinner,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      isLogoutInProgress: false,
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  computed: {
    ...mapPiniaState(useAccountStore, ['accountUrl', 'authUrl']),
    has() {
      return this.accountStore.permissions;
    },
    userName() {
      return this.accountStore.user?.name;
    },
    accountSettingsUrl() {
      return this.has.account && this.has.account.account_setting_url;
    },
    downloadLink() {
      const platform = window.navigator.platform;
      if (platform.match(/mac/gi)) {
        return 'https://downloads.now-here.com/executables/latest/BIXE.dmg';
      }
      return 'https://downloads.now-here.com/executables/latest/BIXE%20Setup.exe';
    },
    isAdvancedSettingsDisabled() {
      return this.pageStore.current.pageName === 'connectivity';
    },
    userSettingsUrl() {
      const { origin } = new URL(this.authUrl);
      return this.accountUrl.startsWith(origin) ? this.accountUrl : `${origin}${this.accountUrl}`;
    },
  },
  methods: {
    newMeeting() {
      this.$ee.emit('nM:quickstart');
    },
    showAccount() {
      this.$ee.emit('showAccount');
    },
    navigate(url) {
      this.$router.push(url).catch(() => null);
    },
    openExternal(url, closeSelf = true) {
      ipcApi.send({ name: 'openExternal', url: url, closeSelf });
    },
    logoutHandler() {
      if (this.isLogoutInProgress) {
        return;
      }
      this.isLogoutInProgress = true;
      this.$API.attemptLogout().then(() => {
        let location = '/conference/app/welcome';
        if (!this.isOpenedWithinElectronShell) {
          const { pathname } = window.location;
          location = `/lauth/nowhere?returnUrl=${pathname}`;
        }
        window.location = location;
      });
    },
    async redirectToAuth(action) {
      if (this.accountStore.isGuest) {
        await this.$API.attemptLogout(true);
      }
      if (this.isOpenedWithinElectronShell) {
        this.openExternal(`${window.location.origin}/lauth/nowhere?returnUrl=/conference/app/post-login&request-otp=1&action=${action}`, false);
        return;
      }
      window.location = `/lauth/nowhere?returnUrl=/conference&action=${action}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.outsideMenu {
  padding: rem(125px) 0 0 rem(57px);
  section + section {
    margin-top: rem(31px);
  }

  ul {
    list-style: none;
    line-height: math.div(22, 18);
  }

  h2,
  button,
  a {
    @include type-size('medium');
    line-height: math.div(22, 18);
  }

  h2 {
    font-weight: bold;
  }

  li {
    margin-top: rem(4.5px);
    .logout-loader {
      display: inline-block;
      margin-left: 6px;
    }
  }

  button,
  a,
  a:visited {
    padding: 0;
    font-weight: 200;
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }
  }

  button,
  a,
  a:visited {
    &[disabled='true'] {
      color: var(--c__disabled);
      pointer-events: none;
      @include triggered {
        color: var(--c__disabled);
      }
    }
  }
}
.joinItem {
  margin-bottom: 2em;
}
</style>
