<template>
  <div
    class="avatarCore avatar"
    :id="'user-' + id"
    ref="counter"
    :class="classes"
    :data-user-id="id"
    :data-index="index"
    :data-last="last"
    :style="{ top: top + unit, left: left + unit, opacity: opacity, transform: 'scale(' + scale + ')', pointerEvents: pointerEvents }"
    @mouseover="usersStore.onHover(id, 'avatar')"
    @mouseleave="usersStore.onUnHover(id, 'avatar')"
  >
    <transition name="scale" v-show="!hide">
      <div class="core" ref="core">
        <transition name="basic">
          <div v-if="!isConnected || videoDown" class="offlineSoonOverlay" :style="{ width: wInner, height: wInner }"></div>
        </transition>
        <avatar-inner :pos="{ width: wInner, height: wInner }" :user="user" />
        <avatar-border :pos="{ width: wOuter, height: wOuter }" :user="user" :index="index" />
      </div>
    </transition>

    <transition name="basic">
      <avatar-placeholder :user="user" v-if="showPlaceHolder" :style="{ width: wInner, height: wInner, transform: 'translateX(-50%) translateY(-50%)' }" />
    </transition>

    <avatar-labels :pos="posOuter" :user="user" :showControls="hasControls" :coreHovered="hovered" :emoting="emitterEmoting" />
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { nM } from '@/legacy';
import { useFeaturesStore } from '@/store/pinia/features';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useMediaStore } from '@/store/pinia/media';
import { useMessagesStore } from '@/store/pinia/messages';
import AvatarInner from '@/components/AvatarInner.vue';
import AvatarBorder from '@/components/AvatarBorder.vue';
import AvatarPlaceholder from '@/components/AvatarPlaceholder.vue';
import AvatarLabels from '@/components/AvatarLabels.vue';
import { whichTransitionEvent } from '@/resources/css-events';
import anim from '@/resources/animation-constants';
import { requestRestartVideo } from '../shared/constants';
import utils from '../resources/utils';
import * as geometry from '@/resources/geometry';

import { CONTROLS_PAD, INNER_RATIO_BIG, INNER_RATIO_SMALL, INNER_RATIO_BREAKPOINT } from '@/resources/constants/frame-constants';
import errorReportingService from '../services/errorReportingService';

export default {
  components: {
    AvatarInner,
    AvatarBorder,
    AvatarPlaceholder,
    AvatarLabels,
  },
  props: ['user', 'index', 'count'],
  setup() {
    const featuresStore = useFeaturesStore();
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    const messagesStore = useMessagesStore();
    return { featuresStore, frameStore, phaseStore, usersStore, messagesStore };
  },
  data() {
    return {
      reconnectVideoRequestInterval: null,
      reconnectVideoRequestCount: 0,
      offlineTimeout: null,
      offlineAwhile: false,
      added: false,
      appearing: true,
      left: 0,
      top: 0,
      scale: 1,
      opacity: 1,
      unit: 'px',
      w: 0,
      throttledUpdateVolume: _.throttle(this.updateVolumeRaw, 1000),
      debounceUpdatePositionFlip: _.debounce(this.updatePosition, 200),
      debounceUpdatePositionPop: _.debounce(this.updatePosition, 800),
      emoteTimeout: false,
      isRunningAnimation: false,
      talkingOpacity: 0,
      borders: 0,
      particles: 0,
      flags: 0,
      popping: 0,
      jittering: 0,
      nodding: 0,
      shaking: 0,
      spinning: 0,
      flywheeling: 0,
      shrugging: 0,
      shockwaves: 0,
      balloonCount: 0,
      balloonSizes: {
        wave: 0,
      },
      balloonTimeouts: {
        wave: false,
      },
      balloons: {
        wave: false,
      },
      borderStyles: {},
      offPage: false,
      page: 0,
      showEmotes: false,
      tempHideControls: false,
      hasControls: false,
      popOut: false,
      animationBench: false,
      emitterEmoting: false,
      goodChecks: 0,
    };
  },
  mounted() {
    var _this = this;
    this.$ee.on('nM:volume:' + _this.id, _this.updateVolume);
    this.$ee.on('nM:animatedAvatarIn:' + _this.id, _this.animatedIn);
    this.$ee.on(`nM:avatar:emote:${this.id}`, _this.emote);
    this.$ee.on(`nM:avatar:emoting:${this.id}`, _this.emitterStartedEmoting);
    this.$ee.on(`nM:avatar:unEmoting:${this.id}`, _this.emitterStoppedEmoting);
    this.$ee.on(`nM:avatar:hasControls:${this.id}`, _this.onControls);
    this.updatePositionData();
    if (this.currentAvatarPageList?.includes(this.id)) {
      this.offPage = false;
    } else {
      this.offPage = true;
    }
    this.$nextTick(() => {
      this.bigNudge();
    });
  },
  beforeUnmount() {
    var _this = this;
    this.$ee.off('nM:animatedAvatarIn:' + _this.id, _this.animatedIn);
    this.$ee.off(`nM:avatar:emote:${this.id}`, _this.emote);
    this.$ee.off(`nM:avatar:emoting:${this.id}`, _this.emitterStartedEmoting);
    this.$ee.off(`nM:avatar:unEmoting:${this.id}`, _this.emitterStoppedEmoting);
    this.$ee.off(`nM:avatar:hasControls:${this.id}`, _this.onControls);
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      rawPositions: 'positions',
      avatarPageNumber: 'page',
      avatarPages: 'pages',
      isMe(s) {
        return s.isMe(this.id);
      },
      isOwner(s) {
        return s.isOwner(this.id);
      },
      isMeetingModerator(s) {
        return s.isMeetingModerator(this.id);
      },
      hovered(s) {
        return s.isHovered(this.id);
      },
      isConnected(s) {
        return s.isConnected(this.id);
      },
      isAway(s) {
        return s.isAway(this.id);
      },
      isAwayInGroup(s) {
        return s.isAwayInGroup(this.id);
      },
      isSpeaking(s) {
        return s.isSpeaking(this.id);
      },
      isMuted(s) {
        return s.isMuted(this.id) && !s.isTalking(this.id);
      },
      isSysMuted(s) {
        return s.isSysMuted(this.id) && !s.isTalking(this.id);
      },
      isVideoMuted(s) {
        return s.isVideoMuted(this.id);
      },
      isSysVideoMuted(s) {
        return s.isSysVideoMuted(this.id);
      },
      hasHandUp(s) {
        return s.hasHandUp(this.id);
      },
      onMyPage(s) {
        return s.userOnMyPage(this.id);
      },
      inMyGroup(s) {
        return s.inMyGroup(this.id);
      },
      videoDown(s) {
        return s.userVideoDown(this.id);
      },
      hasSpinner(s) {
        return s.userHasSpinner(this.id);
      },
      isMoving(s) {
        return s.isMoving(this.id);
      },
    }),
    ...mapPiniaState(useUsersStore, [
      'me',
      'speaking',
      'myId',
      'layout',
      'allUsers',
      'paged',
      'resize',
      'swapping',
      'avatarsAnimating',
      'whatPage',
      'currentAvatarPageList',
      'userOnMyPage',
      'groups',
      'currentlyTalking',
    ]),
    ...mapPiniaState(useFrameStore, {
      rem: 'rem',
    }),
    ...mapPiniaState(useMediaStore, ['videoSource']),
    ...mapPiniaState(usePhaseStore, ['votes']),
    id() {
      return this.user.id;
    },
    posOuter() {
      return {
        top: this.top + this.unit,
        left: this.left + this.unit,
        opacity: this.opacity,
        transform: 'scale(' + this.scale + ')',
        pointerEvents: this.pointerEvents,
        width: this.wOuter,
        height: this.wOuter,
        w: this.w,
      };
    },
    posInner() {
      return {
        top: this.top + this.unit,
        left: this.left + this.unit,
        opacity: this.opacity,
        transform: 'scale(' + this.scale + ')',
        pointerEvents: this.pointerEvents,
        width: this.wOuter,
        height: this.wOuter,
      };
    },
    showPlaceHolder() {
      return this.usersStore.firstShown && (this.notHere || this.offlineAwhile);
    },
    innerRatio() {
      return this.w > INNER_RATIO_BREAKPOINT ? INNER_RATIO_BIG : INNER_RATIO_SMALL;
    },
    wInner() {
      return this.w * this.innerRatio + this.unit;
    },
    wLoading() {
      return (this.w * this.innerRatio) / 3 + this.unit;
    },
    wOuter() {
      return this.w + this.unit;
    },
    wControls() {
      return this.w + CONTROLS_PAD + this.unit;
    },
    isNotWithMe() {
      if (!this.user || !this.me) {
        return false;
      }
      return this.isAwayInGroup && ((!this.inMyGroup && this.usersStore.iAmAwayInGroup) || !this.usersStore.iAmAwayInGroup);
    },
    iAmSwapping() {
      return this.swapping === this.id;
    },
    hasSwapButtons() {
      return (
        this.featuresStore.customGroups &&
        this.phaseStore.settingUp &&
        this.phaseStore.settingUpPhase === 'groups' &&
        !this.avatarsAnimating &&
        this.groups.length > 1
      );
    },
    rawPosition() {
      return this.rawPositions[this.id];
    },
    last() {
      return this.index + 1 === this.count || null;
    },
    vote() {
      return this.votes[this.id];
    },
    hide() {
      return this.usersStore.hide || this.offlineAwhile || this.notHere;
    },
    randomRunning() {
      return this.usersStore.randomTicker ? true : false;
    },
    classes() {
      return {
        jsMovement: this.featuresStore.perf.popMovement || this.featuresStore.perf.flipMovement || this.featuresStore.cpuLevel === 'low',
        popOut: this.popOut,
        me: this.isMe,
        notMe: !this.isMe,
        owner: this.isOwner,
        isMeetingModerator: this.isMeetingModerator,
        user: !this.isOwner,
        handUp: this.hasHandUp,
        offlineSoon: !this.isConnected,
        offline: this.offlineAwhile,
        notHere: this.notHere,
        mute: this.isMuted || (this.isMe && this.isAway),
        sysMute: this.isSysMuted,
        muteVideo: this.isVideoMuted,
        sysMuteVideo: this.isSysVideoMuted,
        emoting: this.isEmoting,
        voted: (this.vote === 'yes' || this.vote === 'no') && this.phaseStore.phaseStage === 'voting',
        yes: this.vote === 'yes',
        no: this.vote === 'no',
        speaking: this.isSpeaking && !this.randomRunning,
        onBreak: (this.phaseStore.currentPhase === 'breakTime' && this.isAway) || (this.isMe && this.usersStore.iAmStillAway),
        loadingVideo: this.hasSpinner,
        focused: this.hovered || this.hasControls,
      };
    },
    notHere() {
      return (this.isAway || this.isNotWithMe) && !this.isMe;
    },
    showLabel() {
      return (this.homeAngle < 0 || this.controls.length <= 0) && (!this.showEmotes || !this.isMe);
    },
    isAnimating() {
      return this.isEmoting || this.isMoving;
    },
    isEmoting() {
      let emotes = [this.popping, this.jittering, this.nodding, this.shaking, this.shrugging, this.spinning, this.flywheeling];
      return emotes.reduce((a, c) => a + c) > 0;
    },

    pageLength() {
      return this.avatarPageList.length;
    },
    pointerEvents() {
      return this.scale > 0 || this.opacity > 0 ? 'auto' : 'none';
    },
  },
  watch: {
    'phaseStore.currentPhase': {
      immediate: true,
      handler(nv) {
        if (nv === 'dialogue') {
          this.talkingFader();
        }
      },
    },
    isConnected(nv) {
      if (nv) {
        const externalUserId = nM.buildExternalUserId(this.id);
        const tile = Object.values(this.$videomanager.tiles).find((t) => t.boundExternalUserId === externalUserId && !t.isContent);
        if (tile) {
          const targetElementId = `video-${tile.boundExternalUserId}`;
          this.$videomanager.attach(tile, targetElementId);
          this.$videomanager.bindVideoElement(tile, targetElementId);
        }
        clearTimeout(this.offlineTimeout);
        this.offlineAwhile = false;
      } else {
        this.offlineTimeout = setTimeout(() => {
          this.offlineAwhile = true;
        }, 60000);
      }
    },
    currentlyTalking(nv) {
      if (this.phaseStore.currentPhase === 'dialogue' && nv && nv === this.id) {
        this.talkingOpacity = 1;
      }
    },
    videoDown() {
      this.w--;
      _.delay(() => {
        this.w++;
      }, 100);
    },
    rawPosition: {
      deep: true,
      handler(nv, ov) {
        if (!nv) {
          return false;
        }
        let init = !ov;
        let noChange = true;
        if (['hmr'].includes(nv.why)) {
          noChange = false;
        }
        ['top', 'left', 'w', 'opacity', 'scale'].forEach((prop) => {
          if (nv[prop] && typeof nv[prop] !== 'undefined' && nv[prop] !== this[prop]) {
            noChange = false;
          }
        });
        this.debounceUpdatePosition(nv, init, noChange);
      },
    },
    isAnimating(nv) {
      if (!nv && this.$refs.core) {
        this.$refs.core.style.transform = '';
      }
    },
    avatarPageNumber(nv) {
      this.page = nv;
    },
    isEmoting(nv) {
      if (!nv) {
        _.delay(() => {
          if (!this.emoting && this.$refs.core) {
            this.$refs.core.style.transform = '';
          }
        }, 10);
      }
    },
    hasHandUp(nv) {
      const params = { name: utils.desanitizeString(this.user.first_name), id: this.id };
      if (!this.onMyPage) {
        if (nv) {
          this.messagesStore.addAlert('userRaisedHand', params);
        } else {
          this.messagesStore.addAlert('userLoweredHand', params);
        }
      }
    },
    isRunningAnimation(nv) {
      if (nv) {
        this.animationBench = Date.now();
      } else {
        if (['resize', 'userRejoined', 'fullscreen'].includes(this.rawPosition.why)) {
          this.animationBench = false;
        } else if (this.animationBench) {
          let timeTaken = Date.now() - this.animationBench;
          let check = this.featuresStore.perf.popMovement || this.featuresStore.cpuLevel === 'low' ? 2000 : 1500;
          if (timeTaken > check) {
            this.featuresStore.setDetectedCpuLevel('bad');
            if (this.isMe) {
              this.goodChecks = 0;
            }
          } else if (this.isMe && ['low', 'bad'].includes(this.featuresStore.cpuLevel)) {
            this.goodChecks++;
            if (this.goodChecks >= 3) {
              this.$ee.emit('retestCpu');
            }
          }
          this.animationBench = false;
        }
      }
    },
    'user.video_muted': {
      immediate: true,
      handler: function (nv) {
        if (nv) {
          this.usersStore.setVideoLoaded(this.user.id, true);
        }
      },
    },
  },
  methods: {
    emitterStartedEmoting() {
      this.emitterEmoting = true;
    },
    emitterStoppedEmoting() {
      this.emitterEmoting = false;
    },
    debounceUpdatePosition(d, init, noChange) {
      if (this.featuresStore.perf.popMovement || this.featuresStore.cpuLevel === 'low') {
        this.debounceUpdatePositionPop(d, init, noChange);
      } else {
        this.debounceUpdatePositionFlip(d, init, noChange);
      }
    },
    attemptVideoReConnectivity() {
      if (!this.reconnectVideoRequestInterval) {
        this.reconnectVideoRequestInterval = setInterval(() => {
          if (this.reconnectVideoRequestCount === 3) {
            if (this.myId === this.id) {
              this.$ee.emit('api:alertAdd', {
                type: 'misc',
                class: 'error',
                duration: 30000,
                hideOnLayout: true,
                content: 'Something went wrong. Please refresh or reload the app.',
              });
              errorReportingService.reportError('Error in video reconnectivity');
            }
            this.resetReconnectVideoRequestParams();
            return;
          }
          if (this.myId === this.id) {
            this.$videomanager.restart({ type: 'videoSource', devices: { videoSource: this.videoSource } });
          } else {
            this.$meetingmanager.requestUserAction({ actionType: 'common.requestVideo', requestedBy: this.myId, requestedTo: this.id });
          }
          this.reconnectVideoRequestCount += 1;
        }, requestRestartVideo.requestInterval);
      }
    },
    resetReconnectVideoRequestParams() {
      clearInterval(this.reconnectVideoRequestInterval);
      this.reconnectVideoRequestInterval = null;
      this.reconnectVideoRequestCount = 0;
    },
    updatePositionData() {
      if (this.rawPosition) {
        this.left = this.rawPosition.left;
        this.top = this.rawPosition.top;
        this.w = this.rawPosition.w;
        if (this.rawPosition.scale !== undefined) {
          this.scale = this.rawPosition.scale;
        } else {
          this.scale = 1;
        }
        if (this.rawPosition.opacity !== undefined) {
          this.opacity = this.rawPosition.opacity;
        } else {
          this.opacity = 1;
        }
      }
      this.appearing = false;
    },
    updatePosition(p, init, noChange) {
      if (noChange) {
        this.usersStore.cancelMove(this.id);
        return false;
      }
      if (this.featuresStore.cpuLevel !== 'bad') {
        this.usersStore.setMoving(this.id, true);
        this.$ee.emit(`nM:avatar:startMoving:${this.id}`);
        this.usersStore.updateAnimating({ v: true, id: this.id });
      }

      if (this.$refs.counter) {
        this.$refs.counter.style.transform = '';
        if (this.$refs.core) {
          this.$refs.core.style.transform = '';
        }
        let delay = 0;
        if (this.featuresStore.perf.pauseVideoWhenMoving) {
          delay = 10;
        }
        if (this.featuresStore.perf.hideVideoWhenMoving) {
          delay = 300;
        }

        if (this.rawPosition.why === 'page' && this.onMyPage) {
          delay += 300;
        }

        if (this.featuresStore.noAvatarMovement) {
          this.added = true;
          this.usersStore.setMoving(this.id, false);

          this.isRunningAnimation = false;
          this.appearing = false;
          this.updatePositionData(p);
          this.$nextTick(() => {
            this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
            this.usersStore.updateAnimating({ v: false, id: this.id });
          });
          Velocity(
            this.$refs.counter,
            { opacity: 1 },
            {
              display: 'block',
              duration: 0,
            },
          );
        } else {
          _.delay(() => {
            if (!init && !this.appearing) {
              if (this.featuresStore.perf.popMovement) {
                this.popOut = true;
                Velocity(this.$refs.counter, 'transition.blipOut', {
                  delay: 0,
                  easing: anim.BASE_EASE_IN,
                  duration: anim.BASE_SPEED * 0.5,
                  begin: () => {
                    this.isRunningAnimation = true;
                  },
                  complete: () => {
                    this.setPositions();
                    Velocity(this.$refs.counter, 'transition.blipIn', {
                      display: 'block',
                      easing: anim.BASE_EASE_IN,
                      duration: anim.BASE_SPEED,
                      delay: 10,
                      complete: () => {
                        if (this.$refs.counter) {
                          this.$refs.counter.style.transform = '';
                        }
                        this.usersStore.setMoving(this.id, false);
                        this.isRunningAnimation = false;
                        this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
                        this.usersStore.updateAnimating({ v: false, id: this.id });
                        this.popOut = false;
                      },
                    });
                  },
                });
              } else if (this.featuresStore.perf.flipMovement) {
                let flipX = geometry.flip({
                  start: this.left,
                  end: this.rawPosition.left,
                  unit: this.unit,
                });
                let flipY = geometry.flip({
                  start: this.top,
                  end: this.rawPosition.top,
                  unit: this.unit,
                });
                let flipScale = geometry.flipScale({
                  start: this.w,
                  end: this.rawPosition.w,
                });
                Velocity(this.$refs.counter, 'stop');

                if (this.rawPosition.why === 'votes' && this.rawPosition.w !== this.w) {
                  Velocity(
                    this.$refs.counter,
                    {
                      scaleX: [flipScale, flipScale],
                      scaleY: [flipScale, flipScale],
                    },
                    {
                      display: 'block',
                      duration: 0,
                    },
                  );
                  this.setPositions('size');
                  Velocity(
                    this.$refs.counter,
                    {
                      scaleX: [1, flipScale],
                      scaleY: [1, flipScale],
                    },
                    {
                      display: 'block',
                      delay: 5,
                      easing: [0.45, 0, 0.15, 1],
                      duration: 300,
                      begin: () => {
                        this.isRunningAnimation = true;
                      },
                      complete: () => {
                        _.delay(() => {
                          Velocity(
                            this.$refs.counter,
                            {
                              translateX: [flipX, flipX],
                              translateY: [flipY, flipY],
                            },
                            {
                              display: 'block',
                              duration: 0,
                              begin: () => {
                                this.setPositions('position');
                              },
                            },
                          );
                          Velocity(
                            this.$refs.counter,
                            {
                              translateX: [0, flipX],
                              translateY: [0, flipY],
                            },
                            {
                              display: 'block',
                              easing: [0.5, 0, 0, 1],
                              duration: 500,
                              begin: () => {
                                this.isRunningAnimation = true;
                              },
                              complete: () => {
                                if (this.$refs.counter) {
                                  this.$refs.counter.style.transform = '';
                                }
                                this.isRunningAnimation = false;
                                this.usersStore.setMoving(this.id, false);
                                this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
                                this.usersStore.updateAnimating({ v: false, id: this.id });
                              },
                            },
                          );
                        }, 10);
                      },
                    },
                  );
                } else {
                  Velocity(
                    this.$refs.counter,
                    {
                      translateX: [flipX, flipX],
                      translateY: [flipY, flipY],
                      scaleX: [flipScale, flipScale],
                      scaleY: [flipScale, flipScale],
                    },
                    {
                      display: 'block',
                      duration: 0,
                    },
                  );
                  this.setPositions();
                  Velocity(
                    this.$refs.counter,
                    {
                      translateX: [0, flipX],
                      translateY: [0, flipY],
                      scaleX: [1, flipScale],
                      scaleY: [1, flipScale],
                    },
                    {
                      display: 'block',
                      delay: 5,
                      easing: [0.45, 0, 0.15, 1],
                      duration: 600,
                      begin: () => {
                        this.isRunningAnimation = true;
                      },
                      complete: () => {
                        if (this.$refs.counter) {
                          this.$refs.counter.style.transform = '';
                        }
                        this.isRunningAnimation = false;
                        this.usersStore.setMoving(this.id, false);
                        this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
                        this.usersStore.updateAnimating({ v: false, id: this.id });
                      },
                    },
                  );
                }
              } else {
                this.isRunningAnimation = true;
                var transitionEvent = whichTransitionEvent();
                transitionEvent &&
                  this.$refs.counter.addEventListener(
                    transitionEvent,
                    () => {
                      this.usersStore.setMoving(this.id, false);

                      this.isRunningAnimation = false;
                      this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
                      this.usersStore.updateAnimating({ v: false, id: this.id });
                    },
                    { once: true },
                  );
                this.setPositions();
              }
            } else {
              this.added = true;
              this.usersStore.setMoving(this.id, false);

              this.isRunningAnimation = false;
              this.$ee.emit(`nM:avatar:stopMoving:${this.id}`);
              this.appearing = false;
              this.updatePositionData(p);
              Velocity(
                this.$refs.counter,
                { opacity: 1 },
                {
                  display: 'block',
                  duration: 0,
                },
              );
            }
          }, delay);
        }
      }
    },
    setPositions(type = 'all') {
      if (type === 'all' || type === 'position') {
        this.left = this.rawPosition.left;
        this.top = this.rawPosition.top;
      }
      if (type === 'all' || type === 'size') {
        this.w = this.rawPosition.w;
        if (this.rawPosition.scale !== undefined) {
          this.scale = this.rawPosition.scale;
        } else {
          this.scale = 1;
        }
      }
      if (this.rawPosition.opacity !== undefined) {
        this.opacity = this.rawPosition.opacity;
      } else {
        this.opacity = 1;
      }
    },
    talkingFader() {
      _.delay(() => {
        if (this.talkingOpacity > 0) {
          this.talkingOpacity = this.talkingOpacity - 0.1;
        }
        if (this.phaseStore.currentPhase === 'dialogue') {
          this.talkingFader();
        }
      }, 1000);
    },
    emote(c) {
      if (!this.featuresStore.settings.emoteAvatarCore) {
        return false;
      }
      var emotes = {
        yes: [this.nod],
        no: [this.shake],
        what: [this.shrug],
        thoughtful: [this.shrug],
        smile: [this.nod],
        gasp: [this.pop],
        eek: [this.pop],
        grin: [this.pop],
        grr: [this.jitterPop],
        sad: [this.pop],
        tongue: [this.jitterPop],
        uhh: [this.shrug],
        whoa: [this.jitterPop],
        star: [this.pop],
        confetti: [this.pop],
        roll: [this.flywheel],
      };
      if (emotes[c]) {
        emotes[c].forEach((f) => {
          f(c);
        });
      }
      this.emoteTimeout = setTimeout(() => {
        if (this.$refs.core) {
          this.$refs.core.style.transform = '';
        }
      }, 1000);
    },
    nod() {
      if (!this.nodding) {
        var distance = 0.5;
        Velocity(this.$refs.core, 'stop');
        Velocity(
          this.$refs.core,
          {
            translateY: 0 - distance + 'em',
            rotateZ: '-0.1deg',
          },
          {
            queue: 'nod',
            easing: anim.BASE_EASE_OUT,
            duration: 200,
            begin: () => {
              this.nodding++;
            },
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateY: distance + 'em',
            rotateZ: '0.1deg',
          },
          {
            queue: 'nod',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 200,
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateY: 0 - distance * 0.75 + 'em',
            rotateZ: '-0.1deg',
          },
          {
            queue: 'nod',
            easing: anim.BASE_EASE_OUT,
            duration: 150,
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateY: distance * 0.75 + 'em',
            rotateZ: '0.1deg',
          },
          {
            queue: 'nod',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 150,
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateY: '0em',
            rotateZ: '0deg',
          },
          {
            queue: 'nod',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 200,
            complete: () => {
              this.nodding--;
            },
          },
        );
        Velocity.Utilities.dequeue(this.$refs.core, 'nod');
      }
    },
    shake() {
      if (!this.shaking) {
        Velocity(this.$refs.core, 'stop');
        Velocity(
          this.$refs.core,
          {
            translateX: '-0.75em',
            rotateZ: '-2deg',
          },
          {
            queue: 'shake',
            easing: anim.BASE_EASE_OUT,
            duration: 150,
            begin: () => {
              this.shaking++;
            },
          },
        );
        Velocity(
          this.$refs.core,
          {
            queue: 'shake',
            translateX: '0.75em',
            rotateZ: '2deg',
          },
          {
            queue: 'shake',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 150,
          },
        );
        Velocity(
          this.$refs.core,
          {
            queue: 'shake',
            translateX: '-0.75em',
            rotateZ: '-2deg',
          },
          {
            queue: 'shake',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 150,
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateX: '0.75em',
            rotateZ: '2deg',
          },
          {
            queue: 'shake',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 150,
          },
        );
        Velocity(
          this.$refs.core,
          {
            queue: 'shake',
            translateX: '0em',
            rotateZ: '0deg',
          },
          {
            queue: 'shake',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 150,
            complete: () => {
              this.shaking--;
            },
          },
        );
        Velocity.Utilities.dequeue(this.$refs.core, 'shake');
      }
    },
    shrug() {
      var distance = 1;
      var rotate = -5;
      if (!this.shrugging) {
        Velocity(
          this.$refs.core,
          {
            translateX: distance + 'em',
            rotateZ: 0 - rotate,
          },
          {
            queue: 'shrug',
            easing: anim.BASE_EASE_OUT,
            duration: 200,
            begin: () => {
              this.shrugging++;
            },
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateX: 0 - distance + 'em',
            rotateZ: rotate,
          },
          {
            queue: 'shrug',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 200,
          },
        );
        Velocity(
          this.$refs.core,
          {
            translateX: '0em',
            rotateZ: 0,
          },
          {
            queue: 'shrug',
            easing: anim.BASE_EASE_IN_OUT,
            duration: 200,
            complete: () => {
              this.shrugging--;
            },
          },
        );
        Velocity.Utilities.dequeue(this.$refs.core, 'shrug');
      }
    },
    flywheel() {
      this.flywheeling++;
      if (this.flywheeling === 1) {
        this.flywheelStart();
      }
    },
    flywheelStart() {
      Velocity(
        this.$refs.core,
        {
          rotateZ: ['180deg', '0deg'],
        },
        {
          queue: 'spin',
          easing: 'easeInQuad',
          duration: 500,
          begin: () => {
            //this.spinning++;
          },
          complete: () => {
            if (this.flywheeling === 1) {
              this.flywheelEnd();
            } else {
              this.flywheelSpin();
            }
            //this.spinning--;
          },
        },
      );
      Velocity.Utilities.dequeue(this.$refs.core, 'spin');
    },
    flywheelSpin() {
      Velocity(
        this.$refs.core,
        {
          rotateZ: ['540deg', '180deg'],
        },
        {
          queue: 'spin',
          easing: 'linear',
          duration: 500,
          begin: () => {
            this.flywheeling = 1;
            //this.spinning++;
          },
          complete: () => {
            if (this.flywheeling === 1) {
              this.flywheelEnd();
            } else {
              this.flywheelSpin();
            }
            //this.spinning--;
          },
        },
      );
      Velocity.Utilities.dequeue(this.$refs.core, 'spin');
    },
    flywheelEnd() {
      Velocity(
        this.$refs.core,
        {
          rotateZ: ['360deg', '180deg'],
        },
        {
          queue: 'spin',
          easing: 'easeOutQuad',
          duration: 500,
          begin: () => {},
          complete: () => {
            Velocity(
              this.$refs.core,
              {
                rotateZ: ['0deg', '0deg'],
              },
              {
                queue: 'spin',
                easing: 'linear',
                duration: 30,
                complete: () => {
                  if (this.flywheeling > 1) {
                    this.flywheelStart();
                  } else {
                    this.flywheeling = 0;
                  }
                },
              },
            );
            Velocity.Utilities.dequeue(this.$refs.core, 'spin');
          },
        },
      );
      Velocity.Utilities.dequeue(this.$refs.core, 'spin');
    },
    spin() {
      if (!this.spinning) {
        Velocity(
          this.$refs.core,
          {
            rotateZ: ['360deg', '0deg'],
          },
          {
            queue: 'spin',
            easing: 'easeInOutQuad',
            duration: 1000,
            begin: () => {
              this.spinning++;
            },
            complete: () => {
              this.spinning--;
            },
          },
        );
        Velocity.Utilities.dequeue(this.$refs.core, 'spin');
      }
    },
    pop() {
      this.popping++;
      Velocity(this.$refs.core, 'stop');
      Velocity(
        this.$refs.core,
        {
          scale: 1.1,
        },
        {
          duration: 100,
          easing: anim.BASE_EASE_OUT,
          queue: 'pop',
        },
      );
      Velocity(
        this.$refs.core,
        {
          scale: 1,
        },
        {
          duration: anim.BASE_SPEED / 2,
          easing: anim.BASE_EASE_IN_OUT,
          queue: 'pop',
          complete: () => {
            this.popping--;
          },
        },
      );
      Velocity.Utilities.dequeue(this.$refs.core, 'pop');
    },
    jitterPop() {
      this.jittering++;
      Velocity(this.$refs.core, 'stop');
      Velocity(
        this.$refs.core,
        {
          scale: 1.1,
        },
        {
          duration: 100,
          easing: anim.BASE_EASE_OUT,
        },
      );
      Velocity(
        {
          scale: 1,
        },
        {
          duration: anim.BASE_SPEED / 2,
          easing: anim.BASE_EASE_IN_OUT,
        },
      );
      var wob = 100;
      var dis = '0.25em';
      Velocity(
        this.$refs.core,
        {
          translateX: ['-' + dis, 0],
        },
        {
          duration: wob / 2,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: [dis, '-' + dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: ['-' + dis, dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: [dis, '-' + dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: ['-' + dis, dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: [dis, '-' + dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: ['-' + dis, dis],
        },
        {
          duration: wob,
          queue: 'wibble',
        },
      );
      Velocity(
        this.$refs.core,
        {
          translateX: [0, '-' + dis],
        },
        {
          duration: wob / 2,
          queue: 'wibble',
          complete: () => {
            this.jittering--;
          },
        },
      );
      Velocity.Utilities.dequeue(this.$refs.core, 'wibble');
    },
    updateVolume(v) {
      if (this.featuresStore.settings.reducedAnimation || this.featuresStore.cpuLevel === 'bad' || this.featuresStore.cpuLevel === 'low') {
        this.throttledUpdateVolume(v);
      } else {
        this.updateVolumeRaw(v);
      }
    },
    updateVolumeRaw(v) {
      this.volume = v;
    },
    onControls(v) {
      this.hasControls = v;
    },
    nudge() {
      this.w--;
      _.delay(() => {
        this.w++;
      }, 5);
    },
    bigNudge() {
      this.w--;
      this.top--;
      this.left--;
      _.delay(() => {
        this.w++;
        this.top++;
        this.left++;
      }, 5);
    },
    animatedIn() {
      if (navigator.userAgent.indexOf('Firefox') != -1) {
        this.nudge();
      }
      _.delay(this.bigNudge, 1000);
      _.delay(this.bigNudge, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarCore {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0) translateY(0);
  text-align: center;
  font-weight: bold;
  list-style: none;
  z-index: $z__avatar-base-core;
  //backface-visibility: hidden;
  transition: $avatar-transition;
  &.jsMovement,
  .bad-cpu & {
    transition: none;
  }
}
.core {
  transition: all 0.5s cubic-bezier(0, 1.75, 0.8, 0.8);
}
.loadingVideo .core {
  opacity: 0;
  transform: scale(0);
}
.avatar {
  &.standIn,
  &.offline,
  &.notHere {
    background: transparent;
    z-index: $z_counter-dummy;
    .extras,
    .inner,
    .border,
    .shadow {
      opacity: 0;
    }
    .visuals {
      box-shadow: none;
    }
    .border {
      display: none;
    }
  }
}

.offlineSoonOverlay {
  background: var(--c__black);
  position: absolute;
  z-index: 4;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 50%;
}
</style>
