import { createRouter, createWebHistory } from 'vue-router';
import {
  authGuard,
  guestGuard,
  developmentGuard,
  mediaAllowedGuard,
  meetingCheckGuard,
  mobileGuard,
  permissionsGuard,
  readinessCheckStartedGuard,
  bixePlatformGuard,
  noRestrictedBrowserGuard,
  roomInviteValidityGuard,
  roomGuestGuard,
  adminReportsAccessGuard,
} from '@/guards';

const commonGuards = [noRestrictedBrowserGuard()];

export const router = createRouter({
  base: window.__INITIAL_STATE__.config.baseUrl,
  history: createWebHistory(window.__INITIAL_STATE__.config.baseUrl),
  routes: [
    {
      path: '/',
      redirect: '/welcome',
    },
    {
      path: '/allow',
      component: () => import('@/pages/AllowMedia.vue'),
      props: {
        pageName: 'allow',
      },
    },
    {
      path: '/full',
      component: () => import('@/pages/MeetingFull.vue'),
      props: {
        pageName: 'meetingFull',
      },
    },
    {
      path: '/meeting-over',
      component: () => import('@/pages/MeetingDone.vue'),
      beforeEnter: [...commonGuards],
      props: {
        pageName: 'over',
      },
    },
    {
      path: '/ejected-from-meeting',
      component: () => import('@/pages/Ejected.vue'),
      beforeEnter: [...commonGuards],
      props: {
        pageName: 'over',
      },
    },
    {
      path: '/meeting-feedback',
      component: () => import('@/pages/MeetingFeedback.vue'),
      beforeEnter: [...commonGuards],
      props: {
        pageName: 'meetingFeedback',
      },
    },
    {
      path: '/meeting/:id',
      component: () => import('@/pages/Meeting.vue'),
      beforeEnter: [...commonGuards, meetingCheckGuard(), bixePlatformGuard(), mobileGuard(), guestGuard(), mediaAllowedGuard()],
      meta: {
        target: 'meeting',
      },
      props: {
        pageName: 'meetingFrame',
      },
    },
    {
      path: '/meeting/:id/stopSharing',
      component: () => import('@/pages/bixeNativePages/StopSharingDrawer.vue'),
      beforeEnter: [authGuard()],
      props: {
        pageName: 'stopSharingDrawer',
      },
    },
    {
      path: '/meeting/:id/avatarsDock',
      component: () => import('@/pages/bixeNativePages/AvatarDockDrawer.vue'),
      beforeEnter: [authGuard()],
      props: {
        pageName: 'avatarsDockDrawer',
      },
    },
    {
      path: '/meetings',
      component: () => import('@/pages/MeetingsList.vue'),
      beforeEnter: [...commonGuards, developmentGuard(), authGuard()],
      props: {
        pageName: 'meetings',
      },
    },
    {
      path: '/meeting-has-been-ended',
      component: () => import('@/pages/MeetingDone.vue'),
      beforeEnter: [...commonGuards],
      props: {
        pageName: 'ended',
      },
    },
    {
      path: '/meeting-timed-out',
      component: () => import('@/pages/MeetingTimedOut.vue'),
      beforeEnter: [...commonGuards],
      props: {
        pageName: 'meetingTimedOut',
      },
    },
    {
      path: '/no-safari',
      component: () => import('@/pages/NoSafari.vue'),
      props: {
        pageName: 'noSafari',
      },
    },
    {
      path: '/browser-not-supported',
      component: () => import('@/pages/BrowserNotSupported.vue'),
      props: {
        pageName: 'browserNotSupported',
      },
    },
    {
      path: '/please-use-desktop',
      component: () => import('@/pages/PleaseUseDesktop.vue'),
      props: {
        pageName: 'pleaseUseDesktop',
      },
    },
    {
      path: '/please-use-media',
      component: () => import('@/pages/PleaseUseMedia.vue'),
      props: {
        pageName: 'pleaseUseMedia',
      },
    },
    {
      path: '/setup-complete',
      component: () => import('@/pages/SetupComplete.vue'),
      props: {
        pageName: 'setupComplete',
      },
    },
    {
      path: '/quickstart/:id',
      component: () => import('@/pages/Foyer.vue'),
      beforeEnter: [...commonGuards, authGuard()],
      props: {
        pageName: 'quickstart',
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/subpages/Quickstart.vue'),
          props: {
            pageName: 'quickstart',
          },
        },
      ],
    },
    {
      path: '/welcome',
      component: () => import('@/pages/Foyer.vue'),
      beforeEnter: [...commonGuards, authGuard()],
      props: {
        pageName: 'welcome',
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/subpages/Welcome.vue'),
          props: {
            pageName: 'welcome',
          },
        },
      ],
    },
    {
      path: '/join',
      component: () => import('@/pages/Join.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'join',
      },
    },
    {
      path: '/join-with-link',
      component: () => import('@/pages/JoinWithLink.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'joinWithLink',
      },
    },
    {
      path: '/join-as-guest',
      component: () => import('@/pages/JoinAsGuest.vue'),
      beforeEnter: [...commonGuards, mobileGuard(), meetingCheckGuard(), guestGuard()],
      meta: {
        target: 'guest',
      },
      props: {
        pageName: 'joinAsGuest',
      },
    },
    {
      path: '/joining-options',
      component: () => import('@/pages/JoiningOptions.vue'),
      props: {
        pageName: 'joiningOptions',
      },
    },
    {
      path: '/my-room',
      component: () => import('@/pages/Foyer.vue'),
      beforeEnter: [...commonGuards, authGuard(), permissionsGuard()],
      meta: {
        access: {
          conference: true,
        },
      },
      props: {
        pageName: 'myRoom',
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/subpages/MyRoom.vue'),
          meta: {
            access: {
              conference: true,
            },
          },
          props: {
            pageName: 'myRoom',
          },
        },
      ],
    },
    {
      path: '/room-password',
      component: () => import('@/pages/RoomPassword.vue'),
      beforeEnter: [...commonGuards, authGuard(), permissionsGuard()],
      props: {
        pageName: 'roomPassword',
      },
      meta: {
        access: {
          conference: true,
        },
      },
    },
    {
      path: '/advanced-settings',
      component: () => import('@/pages/AdvancedSettings.vue'),
      beforeEnter: [...commonGuards, authGuard(), permissionsGuard()],
      props: {
        pageName: 'advancedSettings',
      },
      meta: {
        access: {
          conference: true,
        },
      },
    },
    {
      path: '/room/:pinNumber',
      component: () => import('@/pages/Room.vue'),
      beforeEnter: [...commonGuards, roomInviteValidityGuard(), bixePlatformGuard(), roomGuestGuard(), authGuard()],
    },
    {
      path: '/check',
      component: () => import('@/pages/Check.vue'),
      beforeEnter: [...commonGuards, mediaAllowedGuard()],
      props: {
        pageName: 'check',
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/subpages/CheckStart.vue'),
          props: {
            pageName: 'checkStart',
          },
        },
        {
          path: 'how-fast-are-you',
          name: 'check.checkSpeed',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckSpeed.vue'),
          props: {
            pageName: 'checkSpeed',
          },
        },
        {
          path: 'can-you-hear-us',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckHearing.vue'),
          props: {
            pageName: 'checkHearing',
          },
        },
        {
          path: 'can-we-hear-you',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckSpeaking.vue'),
          props: {
            pageName: 'checkSpeaking',
          },
        },
        {
          path: 'can-we-see-you',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckCamera.vue'),
          props: {
            pageName: 'checkCamera',
          },
        },
        {
          path: 'sharing-your-screen',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckScreenSharing.vue'),
          props: {
            pageName: 'checkScreenSharing',
          },
        },
        {
          path: 'complete',
          beforeEnter: [readinessCheckStartedGuard()],
          component: () => import('@/pages/subpages/CheckComplete.vue'),
          props: {
            pageName: 'checkComplete',
          },
        },
      ],
    },
    {
      path: '/app/invite',
      component: () => import('@/pages/UseAppScreens.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'appInvite',
      },
    },
    {
      path: '/app/welcome',
      component: () => import('@/pages/AppIndexPage.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'appIndex',
      },
    },
    {
      path: '/app/post-login',
      component: () => import('@/pages/PostLoginPage.vue'),
      beforeEnter: [...commonGuards, mobileGuard(), authGuard()],
      props: {
        pageName: 'postLoginPage',
      },
    },
    {
      path: '/app/downloading',
      component: () => import('@/pages/Downloading.vue'),
      props: {
        pageName: 'downloading',
      },
    },
    {
      path: '/app/sign-in',
      component: () => import('@/pages/LoginPage.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'loginPage',
      },
    },
    {
      path: '/sign-in',
      component: () => import('@/pages/LoginPage.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'loginPage',
      },
    },
    {
      path: '/use-app',
      component: () => import('@/pages/UseAppScreens.vue'),
      beforeEnter: [...commonGuards, mobileGuard()],
      props: {
        pageName: 'useApp',
      },
    },
    {
      path: '/admin',
      beforeEnter: [authGuard(), adminReportsAccessGuard()],
      props: {
        pageName: 'admin',
      },
      children: [
        {
          path: 'meetings-list',
          component: () => import('@/pages/adminReports/MeetingsReport.vue'),
          props: {
            pageName: 'meetings-list',
          },
        },
      ],
    },
    {
      path: '/component-library',
      component: () => import('@/pages/ComponentTest.vue'),
      beforeEnter: [...commonGuards, developmentGuard(), mobileGuard()],
      props: {
        pageName: 'componentTest',
      },
    },
    {
      path: '/not-found',
      component: () => import('@/pages/NotFound.vue'),
      props: {
        pageName: '404',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/pages/NotFound.vue'),
      props: {
        pageName: '404',
      },
    },
  ],
});
