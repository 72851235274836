<template>
  <div class="subContent">
    <title-block :wordless="true" />
    <h2>All Done!</h2>

    <a v-if="isOpenedWithinElectronShell" href="/conference" class="btn btn-border btn-medium btn-accent">Launch&nbsp;<strong>bixe</strong></a>
    <a v-else href="/conference" class="btn btn-border btn-medium btn-accent" target="_blank" rel="noopener">Launch&nbsp;<strong>bixe</strong></a>
    <router-link class="btn btn-border btn-medium btn-accent" to="/check"> Run setup again </router-link>
  </div>
</template>

<script>
import TitleBlock from '@/components/TitleBlock.vue';
import { usePageStore } from '@/store/pinia/page';
import { useMediaStore } from '@/store/pinia/media';
import utils from '../../resources/utils';

export default {
  components: {
    TitleBlock,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  data() {
    return {
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
    this.mediaStore.initReadinessCheckSession(false);
    this.$videomanager.destroy();
    this.$readinessChecker.reset();
  },
  methods: {},
};
</script>
