<template>
  <footer class="pageFooter">
    <p v-if="!isApp">&copy; <strong>nowhere</strong> group {{ year }}</p>
  </footer>
</template>
<script setup>
import { computed } from 'vue';
import utils from '../resources/utils';
const isApp = utils.isOpenedWithinElectronShell();
const year = computed(() => new Date().getFullYear());
</script>
<style lang="scss" scoped>
footer {
  margin-top: auto;
}
</style>
