<template>
  <section class="pleaseUse page">
    <div class="content">
      <slot></slot>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.pleaseUse {
  --c__bg: #{$c_black};
  --c__text: #{$c_white};
  --c__button: #{$c_white};
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--c__bg);
  color: var(--c__text);
  position: fixed;

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  :deep(h1) {
    @include type-size('large');
    line-height: math.div(29, 24);
    font-weight: 300;
  }

  :deep(p) {
    @include type-size('medium');
    line-height: math.div(22, 18);
    margin-top: rem(21px);
  }

  :deep(a) {
    margin-top: rem(43px);
    width: rem(192px);
    height: rem(45px);
  }
}
</style>
