/* eslint-disable */
import { defineStore } from 'pinia';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { nM } from '@/legacy';

const flags = {
  dialogue: 'CREATIVE_DIALOGUE',
  feedback: 'FEEDBACK_PHASE',
};

export const useFeaturesStore = defineStore('features', {
  state() {
    return {
      debug: false,
      flags: __FEATURE_FLAGS__, // eslint-disable-line no-undef
      customGroups: false,
      dialogueMaps: false,
      sandbox: false,
      showTalking: false,
      showThemes: false,
      showSentry: false,
      showAllEmotes: false,
      showExtraSettings: false,
      showConnectivityIndicator: false,
      meetingSettings: {
        audioOnly: false,
        reducedAnimation: false,
        simplifiedGraphics: false,
        theme: 'default',
        meetingSpace: 'default',
      },
      detectedCpuLevel: false,
      hasShownDegradeMessage: false,
      settings: {},
      perf: {
        uglyMode: false,
        fakeEmotes: false,
        popMovement: false,
        hideVideoWhenMoving: false,
        pauseVideoWhenMoving: false,
        flipMovement: true,
        noMask: false,
        emoteAvatarCore: false,
      },
    };
  },
  getters: {
    cpuLevel: (state) => {
      if (!state.settings.cpuLevel || state.settings.cpuLevel === 'auto') {
        return state.detectedCpuLevel;
      } else {
        return state.settings.cpuLevel;
      }
    },
    noAnimations() {
      return this.cpuLevel === 'bad';
    },
    noTransparency() {
      return this.cpuLevel === 'bad' || this.cpuLevel === 'low';
    },
    noAvatarMovement() {
      return this.cpuLevel === 'bad' || this.cpuLevel === 'low';
    },
    useBadgeEmotes() {
      return this.cpuLevel === 'bad' || this.cpuLevel === 'low';
    },
    useSpaceAnimation(state) {
      if (this.cpuLevel === 'bad' || this.cpuLevel === 'low' || this.noAnimations) {
        return false;
      }
      return state.settings.spaceAnimation;
    },
    theme(s) {
      let pageStore = usePageStore();
      let meetingStore = useMeetingStore();

      if (!pageStore.isMeeting) {
        return s.settings.theme;
      }

      if (meetingStore.space === 'neon') {
        return 'dark';
      }
      if (meetingStore.space === 'cafe') {
        return 'light';
      }

      return s.settings.theme === 'default' ? 'light' : s.settings.theme || 'light';
    },
  },
  actions: {
    init({ settings }) {
      this.updateUserSettings(settings);
    },
    initMeeting() {},
    overrideFeatureFlag(v) {
      let flag = flags[v];
      if (!flag) {
        return false;
      }
      this.flags[flag] = !this.flags[flag];
    },
    updateUserSettings(v) {
      if (v) {
        this.settings = { ...nM.toSettings(v) };
      }
    },
    setDetectedCpuLevel(v) {
      this.detectedCpuLevel = v;
    },
    setHasShownDegradeMessage(v) {
      this.hasShownDegradeMessage = v;
    },
    togglePerfOption(v) {
      this.perf[v] = !this.perf[v];
    },
    updateMeetingSettings() {
      //TODO: Wait until we have the latest perf changes before we mess with this
    },
    toggleSandbox() {
      this.sandbox = !this.sandbox;
    },
    toggleCustomGroups() {
      this.customGroups = !this.customGroups;
    },
    toggleDialogueMaps() {
      this.dialogueMaps = !this.dialogueMaps;
    },
    toggleShowTalking() {
      this.showTalking = !this.showTalking;
    },
    toggleThemes() {
      this.showThemes = !this.showThemes;
    },
    toggleSentry() {
      this.showSentry = !this.showSentry;
    },
    toggleExtraSettings() {
      this.showExtraSettings = !this.showExtraSettings;
    },
    toggleConnectivityIndicator() {
      this.showConnectivityIndicator = !this.showConnectivityIndicator;
    },
    toggleDebug() {
      this.debug = !this.debug;
    },
    setShowAllEmotes(v) {
      this.showAllEmotes = v;
    },
    setHasShownDegradeMessage(v) {
      this.hasShownDegradeMessage = v;
    },
  },
});
