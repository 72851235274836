import $API from '../services/bixe-api.service';
import { bixeNativeAppProtocols } from '../shared/constants';

const utilityLib = {
  desanitizeString(str) {
    if (!str) {
      return '';
    }

    const sanitizedKeysMap = {
      "'": "'",
      '"': '"',
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '%60': '`',
    };
    /* eslint-disable */
    Object.entries(sanitizedKeysMap).forEach(([key, value]) => {
      const regex = new RegExp(key, 'ig');
      str = str.replace(regex, value);
    });

    return str;
  },

  sanitizeString(str) {
    if (!str) {
      return '';
    }

    const sanitizedKeysMap = {
      "'": "'",
      '"': '"',
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '`': '%60',
    };
    /* eslint-disable */
    Object.entries(sanitizedKeysMap).forEach(([key, value]) => {
      const regex = new RegExp(key, 'ig');
      str = str.replace(regex, value);
    });

    return str;
  },

  cleanDeviceLabel(p) {
    let regex = /\(.*[a-zA-Z0-9]+:.*[a-zA-Z0-9]+\)/i;
    return p.replace(regex, '');
  },

  isOpenedWithinElectronShell() {
    return typeof ipcApi !== 'undefined' && ipcApi.send;
  },

  getNativeAppProtocol: (env) => {
    return env === 'production' ? bixeNativeAppProtocols.prod : bixeNativeAppProtocols.stagingAndDev;
  },

  storage: {
    ss: {
      setItem: (key, value) => {
        window.sessionStorage.setItem(key, value);
      },
      getItem: (key) => {
        return window.sessionStorage.getItem(key);
      },
      removeItem: (key) => {
        window.sessionStorage.removeItem(key);
      },
    },
    ls: {
      setItem: (key, value) => {
        window.localStorage.setItem(key, value);
      },
      getItem: (key) => {
        return window.localStorage.getItem(key);
      },
      removeItem: (key) => {
        window.localStorage.removeItem(key);
      },

    }
  },

  genericFns: {
    startNewMeetingParams() {
      const now = Math.floor(Date.now() / 1000);
      return {
        starts_on: now,
        ends_on: now + 60 * 60,
        created_on: now,
        template: 'quickstart',
        consumer: 'conference',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    },
    quickStartMeeting: ({ preferredMeetingType = '' } = {}) => {
      return new Promise((resolve, reject) => {
        const data = utilityLib.genericFns.startNewMeetingParams();
        $API.createMeeting({ preferredMeetingType, ...data })
          .then((meeting) => {
            resolve(meeting)
          })
          .catch((err) => {
            reject(err)
          });
      });
    },
    joinPersonalRoomMeeting: ({ pinNumber, password, preferredMeetingType = '' }) => {
      const { created_on, ...data } = {
        password, preferredMeetingType, ...utilityLib.genericFns.startNewMeetingParams()
      };
      return new Promise((resolve, reject) => {
        $API.joinRoomMeeting(pinNumber, data)
          .then((meeting) => {
            resolve(meeting)
          })
          .catch((err) => {
            reject(err)
          });
      });
    }
  },

  clipBoard: {
    addItem(item) {
      if (navigator.clipboard?.writeText) {
        navigator.clipboard.writeText(item)
      }
    }
  }
};

export default utilityLib;