<template>
  <transition name="basic">
    <div v-if="actuallyShow" class="settingUpGroups">
      <div class="loadingBar">
        <div class="bar" :style="{ transform: `scaleX(${progress})` }" />
      </div>
      <h2><span v-html="heading" /><span v-if="heading" class="ellip"></span></h2>
      <p v-if="featuresStore.debug">Status: {{ groupEventStatus }} ({{ connectionState }})</p>
    </div>
  </transition>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { createGroupEvents, createGroupEventsDesc } from '../shared/constants';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export default {
  components: {},
  setup() {
    const featuresStore = useFeaturesStore();
    const phaseStore = usePhaseStore();
    return { featuresStore, phaseStore };
  },
  data() {
    return {
      actuallyShow: false,
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['iAmAwayInGroup']),
    ...mapPiniaState(usePhaseStore, ['groupEventStatus']),
    heading() {
      if (!this.groupEventStatus) {
        return '';
      }
      if (this.groupEventStatus === createGroupEvents.DEVICES_CONNECTIVITY_INTERRUPTED) {
        return 'Almost there, click here to continue';
      }
      return this.goingIntoGroup ? 'Creating breakout group' : 'Returning to meeting';
    },
    progress() {
      const eventsProgress = parseInt(this.groupEventStatus) / 100;
      return eventsProgress < 1 ? eventsProgress : 1;
    },
    connectionState() {
      return createGroupEventsDesc[this.groupEventStatus];
    },
  },
  watch: {
    actuallyShow: {
      immediate: true,
      handler(nv) {
        this.phaseStore.setGroupOverlay(nv);
      },
    },
    groupEventStatus(nv) {
      const { BREAKOUT_GROUP_PHASE_STARTING, CLOSING_LAST_SESSION, MEETING_AVAILABLE, UNSET_GROUPS_EVENT_STATUS } = { ...createGroupEvents };
      this.goingIntoGroup = BREAKOUT_GROUP_PHASE_STARTING === nv || this.iAmAwayInGroup;
      if ([BREAKOUT_GROUP_PHASE_STARTING, CLOSING_LAST_SESSION].includes(nv)) {
        this.actuallyShow = true;
      } else if (nv === MEETING_AVAILABLE) {
        this.actuallyShow = false;
        this.phaseStore.setGroupEventStatus({
          status: UNSET_GROUPS_EVENT_STATUS,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.settingUpGroups {
  background: var(--c__bg);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 900;
  h2 {
    text-align: center;
    margin-top: 1em;
    margin-bottom: auto;
    padding: 0 1.5em;
    font-weight: 300;
    height: 1em;
  }

  .ellip {
    position: relative;
    width: 0;
  }

  .ellip:after {
    overflow: hidden;
    display: inline-block;
    position: absolute;
    left: 0;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
  }

  .loadingBar {
    width: 33.333%;
    background: var(--c__text);
    margin-top: auto;
    .bar {
      width: 100%;
      height: 2px;
      background: var(--c__accent);
      transform-origin: left center;
      transition: all 0.3s cubic-bezier(0.45, 0, 0.55, 1);
    }
  }
}
</style>
