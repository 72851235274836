import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { usePxToRem } from '@/composables/usePxToRem';
import { useApplyOffscreenPages } from '@/composables/layouts/useApplyOffscreenPages';
import * as geometry from '@/resources/geometry';
import _ from 'underscore';
import { V_PAD, H_PAD } from '@/resources/constants/frame-constants';

export function useGridLayout() {
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();
  const { pxToRem } = usePxToRem();
  const { applyOffscreenPages } = useApplyOffscreenPages();

  const { usersVisibleList: users, myId, owner, page } = storeToRefs(usersStore);
  const { containerRatio, containerRatioWithSpace, contWidth, contHeight } = storeToRefs(frameStore);

  // Layout maths for dynamic grids. Compares number of users on the page with
  function gridRowData(count, page) {
    const grids = {
      9: (u, c) => {
        if (c < 0.66) {
          return {
            rowCount: 5,
            colCount: 2,
            rows: [u.slice(0, 2), u.slice(2, 4), u.slice(4, 6), u.slice(6, 8), [u[8]]],
            sizeKey: 0,
          };
        } else if (c > 1.6) {
          return {
            rowCount: 2,
            colCount: 5,
            rows: [u.slice(0, 5), u.slice(5, 9)],
            sizeKey: 0,
          };
        } else {
          return {
            rowCount: 3,
            colCount: 3,
            rows: _.chunk(u, 3),
            sizeKey: 0,
          };
        }
      },
      foo: (u, c) => {
        if (c > 1.5) {
          return {
            rowCount: 3,
            colCount: 3,
            rows: _.chunk(u, 3),
            sizeKey: 0,
          };
        } else {
          return {
            rowCount: 3,
            colCount: 3,
            rows: _.chunk(u, 3),
            sizeKey: 0,
          };
        }
      },
      10: (u, c) => {
        if (c < 0.66) {
          return {
            rowCount: 5,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.95) {
          return {
            rowCount: 4,
            colCount: 3,
            rows: [u.slice(0, 2), u.slice(2, 5), u.slice(5, 8), u.slice(8, 10)],
            sizeKey: 1,
          };
        } else if (c > 1.6) {
          return {
            rowCount: 2,
            colCount: 5,
            rows: _.chunk(u, 5),
            sizeKey: 1,
          };
        } else {
          return {
            rowCount: 3,
            colCount: 4,
            rows: [u.slice(0, 3), u.slice(3, 7), u.slice(7, 10)],
            sizeKey: 1,
          };
        }
      },
      11: (u, c) => {
        if (c < 0.6) {
          return {
            rowCount: 6,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.95) {
          return {
            rowCount: 4,
            colCount: 3,
            rows: [u.slice(0, 3), u.slice(3, 6), u.slice(6, 9), u.slice(9, 11)],
            sizeKey: 0,
          };
        } else if (c > 1.9) {
          return {
            rowCount: 2,
            colCount: 6,
            rows: _.chunk(u, 6),
            sizeKey: 0,
          };
        } else {
          return {
            rowCount: 3,
            colCount: 4,
            rows: [u.slice(0, 3), u.slice(3, 7), u.slice(7, 11)],
            sizeKey: 1,
          };
        }
      },
      12: (u, c) => {
        if (c < 0.65) {
          return {
            rowCount: 6,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 1) {
          return {
            rowCount: 4,
            colCount: 3,
            rows: _.chunk(u, 3),
            sizeKey: 0,
          };
        } else if (c > 2) {
          return {
            rowCount: 2,
            colCount: 6,
            rows: _.chunk(u, 6),
            sizeKey: 0,
          };
        } else {
          return {
            rowCount: 3,
            colCount: 4,
            rows: _.chunk(u, 4),
            sizeKey: 0,
          };
        }
      },
      13: (u, c) => {
        if (c < 0.575) {
          return {
            rowCount: 7,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.8) {
          return {
            rowCount: 5,
            colCount: 3,
            rows: [u.slice(0, 2), u.slice(2, 5), u.slice(5, 8), u.slice(8, 11), u.slice(11, 13)],
            sizeKey: 1,
          };
        } else if (c > 2.1) {
          return {
            rowCount: 2,
            colCount: 7,
            rows: _.chunk(u, 7),
            sizeKey: 0,
          };
        } else if (c > 1) {
          return {
            rowCount: 3,
            colCount: 6,
            rows: [u.slice(0, 4), u.slice(4, 9), u.slice(9, 13)],
            sizeKey: 1,
          };
        } else {
          return {
            rowCount: 4,
            colCount: 4,
            rows: [u.slice(0, 3), u.slice(3, 7), u.slice(7, 10), u.slice(10, 13)],
            sizeKey: 1,
          };
        }
      },
      14: (u, c) => {
        if (c < 0.5) {
          return {
            rowCount: 7,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.85) {
          return {
            rowCount: 5,
            colCount: 3,
            rows: [u.slice(0, 3), u.slice(3, 6), u.slice(6, 9), u.slice(9, 12), u.slice(12, 14)],
            sizeKey: 1,
          };
        } else if (c > 2.1) {
          return {
            rowCount: 2,
            colCount: 7,
            rows: _.chunk(u, 7),
            sizeKey: 0,
          };
        } else if (c > 1.35) {
          return {
            rowCount: 3,
            colCount: 6,
            rows: [u.slice(0, 5), u.slice(5, 10), u.slice(10, 14)],
            sizeKey: 1,
          };
        } else {
          return {
            rowCount: 4,
            colCount: 4,
            rows: [u.slice(0, 3), u.slice(3, 7), u.slice(7, 11), u.slice(11, 14)],
            sizeKey: 1,
          };
        }
      },
      15: (u, c) => {
        if (c < 0.6) {
          return {
            rowCount: 8,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.85) {
          return {
            rowCount: 5,
            colCount: 3,
            rows: _.chunk(u, 3),
            sizeKey: 1,
          };
        } else if (c > 2.8) {
          return {
            rowCount: 2,
            colCount: 8,
            rows: _.chunk(u, 8),
            sizeKey: 0,
          };
        } else if (c > 1.1) {
          return {
            rowCount: 3,
            colCount: 5,
            rows: _.chunk(u, 5),
            sizeKey: 1,
          };
        } else {
          return {
            rowCount: 4,
            colCount: 5,
            rows: [u.slice(0, 3), u.slice(3, 7), u.slice(7, 11), u.slice(11, 15)],
            sizeKey: 1,
          };
        }
      },
      16: (u, c) => {
        if (c < 0.5) {
          return {
            rowCount: 8,
            colCount: 2,
            rows: _.chunk(u, 2),
            sizeKey: 1,
          };
        } else if (c < 0.775) {
          return {
            rowCount: 6,
            colCount: 3,
            rows: [u.slice(0, 2), u.slice(2, 5), u.slice(5, 8), u.slice(8, 11), u.slice(11, 14), u.slice(14, 16)],
            sizeKey: 1,
          };
        } else if (c > 2.75) {
          return {
            rowCount: 2,
            colCount: 8,
            rows: _.chunk(u, 8),
            sizeKey: 0,
          };
        } else if (c > 1.5) {
          return {
            rowCount: 3,
            colCount: 6,
            rows: [u.slice(0, 5), u.slice(5, 11), u.slice(11, 16)],
            sizeKey: 1,
          };
        } else {
          return {
            rowCount: 4,
            colCount: 5,
            rows: _.chunk(u, 4),
            sizeKey: 1,
          };
        }
      },
    };
    if (users.value.length > 16) {
      return grids[count](page, containerRatioWithSpace.value);
    } else {
      return grids[count](page, containerRatio.value);
    }
  }

  function gridLayout() {
    let layoutPages = [];
    let extras;
    let checkPage = 0;

    // Set the pages
    if (users.value.length <= 16) {
      layoutPages[0] = users.value;
    } else {
      layoutPages = _.chunk(_.without(users.value, myId.value), 15);
      layoutPages = layoutPages.map((r) => [myId.value, ...r]);
      let origPage = users.value.slice(0, 16);
      if (origPage.includes(myId.value)) {
        layoutPages[0] = users.value.slice(0, 16);
      } else {
        layoutPages[0] = [owner.value.id, myId.value, ...layoutPages[0].filter((id) => id !== myId.value && id !== owner.value.id)];
      }
    }

    // Do the showing page

    if (!layoutPages[page.value]) {
      usersStore.updatePage(0);
    } else {
      checkPage = page.value;
    }

    let pos = gridPage({ page: layoutPages[checkPage], pageBasis: layoutPages[0] });

    // Do all the off pages

    let w = pos[myId.value].w;

    pos = applyOffscreenPages({
      pos,
      w,
      nextPos: contWidth.value + w + H_PAD,
      prevPos: 0 - w - H_PAD,
      pages: layoutPages,
      y: contHeight.value / 2,
      page: page.value,
    });

    // Pagers
    extras = {
      pagerTop: (contHeight.value - w) / 2,
      pagerHeight: w,
    };
    return { pos, extras, layoutName: 'grid', paged: true, pages: layoutPages };
  }

  function gridPage({ pageBasis, page }) {
    const count = users.value.length > 16 ? 16 : users.value.length;

    let pos = {};
    let { sizeKey, rowCount, colCount, rows } = gridRowData(count, page);
    let basis = gridRowData(count, pageBasis);
    const pagerPad = users.value.length > 16 ? pxToRem(75) : 0;
    const contH = contHeight.value - pxToRem(100);
    const contW = contWidth.value - pagerPad;
    const maxH = (contH - V_PAD * rowCount + V_PAD) / rowCount;
    const maxW = (contW - H_PAD * colCount + H_PAD) / colCount;
    const maxSize = maxH < maxW ? maxH : maxW;

    let isOverflow = users.value.length > 16 && page.length < 16;
    let firstRowPos = false;

    sizeKey = rows[sizeKey] ? sizeKey : 0;

    let refRow = geometry.row({
      maxSize: maxSize,
      scaled: true,
      users: basis.rows[sizeKey],
      y: 0,
      cont: contWidth.value,
      max: contW,
      pad: H_PAD,
      outerPad: H_PAD,
      row: 0,
    });

    let refsArr = basis.rows[sizeKey].map((id) => refRow[id]);
    let keyedW = refsArr[0].w;

    rows.forEach((row, i) => {
      let rowPos = geometry.row({
        maxSize: keyedW,
        scaled: true,
        users: row,
        y: 0,
        cont: contWidth.value,
        max: contW,
        pad: H_PAD,
        outerPad: H_PAD,
        row: i,
      });

      if (i === 0) {
        firstRowPos = rowPos;
      }

      if (i + 1 >= rows.length && isOverflow) {
        row.forEach((id, col) => {
          rowPos[id] = { ...firstRowPos[rows[0][col]], row: i };
        });
      }

      pos = _.extend(pos, rowPos);
    });

    let rowHeight = pos[page[0]].w + V_PAD;
    let fullHeight = rowHeight * rowCount - V_PAD;
    let initialY = (contHeight.value - fullHeight + keyedW) / 2;

    page.forEach((id) => {
      pos[id].top = initialY + rowHeight * pos[id].row;
    });

    return pos;
  }

  return { gridLayout };
}
