<template>
  <div class="groupedNotes" :class="[isDragging ? 'isDragging' : '']">
    <meeting-notes-grouped-entry v-if="insights && barMode === 'moments'" what="insight" :notes="insights" class="scrollTarget" />
    <meeting-notes-grouped-entry v-if="questions && barMode === 'moments'" what="question" :notes="questions" />
    <meeting-notes-grouped-entry v-if="decisions && barMode === 'moments'" what="decision" :notes="decisions" />
    <meeting-notes-grouped-entry v-if="actions && barMode === 'moments'" what="action" :notes="actions" />
    <meeting-notes-grouped-entry v-if="good && barMode === 'feedback'" what="good" :notes="good" />
    <meeting-notes-grouped-entry v-if="tricky && barMode === 'feedback'" what="tricky" :notes="tricky" />
    <meeting-notes-grouped-entry v-if="different && barMode === 'feedback'" what="different" :notes="different" />
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { ref, onMounted, onBeforeUnmount, computed, getCurrentInstance } from 'vue';
import { useMomentsStore } from '@/store/pinia/moments';
import { usePhaseStore } from '@/store/pinia/phase';
import MeetingNotesGroupedEntry from '@/components/MeetingNotesGroupedEntry';
const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;

const momentsStore = useMomentsStore();
const phaseStore = usePhaseStore();

const isDragging = ref(false);

onMounted(() => {
  $ee.on('nM:log:dragging', somethingDragging);
  momentsStore.stopEditing();
});

onBeforeUnmount(() => {
  $ee.off('nM:log:dragging', somethingDragging);
});

const { currentPhaseId } = storeToRefs(phaseStore);
const { barMode, ordered: logs } = storeToRefs(momentsStore);

const insights = computed(() => logs.value.filter((l) => l.type === 'insight'));
const questions = computed(() => logs.value.filter((l) => l.type === 'question'));
const actions = computed(() => logs.value.filter((l) => l.type === 'action'));
const decisions = computed(() => logs.value.filter((l) => l.type === 'decision'));
const good = computed(() => logs.value.filter((l) => l.type === 'good' && l.phaseId === currentPhaseId.value));
const tricky = computed(() => logs.value.filter((l) => l.type === 'tricky' && l.phaseId === currentPhaseId.value));
const different = computed(() => logs.value.filter((l) => l.type === 'different' && l.phaseId === currentPhaseId.value));

function somethingDragging(v) {
  this.isDragging = v;
}
</script>
<style lang="scss">
.groupedNotes.isDragging,
.groupedNotes.isDragging * {
  cursor: grabbing;
}
</style>
