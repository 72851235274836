<template>
  <div id="lobbySpace" class="space lobby lobbySpace">
    <transition name="basic">
      <div v-if="showTable" class="table dynamic" id="lobbyTable" ref="table" :style="{ top: top }">&nbsp;</div>
    </transition>
  </div>
</template>

<script setup>
import spaces from '@/resources/spaces';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';
const frameStore = useFrameStore();
const meetingStore = useMeetingStore();
const usersStore = useUsersStore();
const { positionDetails, usersVisible } = storeToRefs(usersStore);
const { ringTop } = storeToRefs(frameStore);
const { space } = storeToRefs(meetingStore);

const top = computed(() => positionDetails.value.bottom + ringTop.value + 'px');
const showTable = computed(() => usersVisible.value && spaces[space.value]?.table);
</script>
<style lang="scss" scoped>
.table {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 4rem;
  background-image: var(--bg__table-meeting);
  transition: top 500ms cubic-bezier(0.45, 0, 0.55, 1);
}
</style>
