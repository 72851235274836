<template>
  <div class="noteGroup" :class="[what, updating, !notes.length ? 'empty' : 'hasNotes', isDragging ? 'isDragging' : '']">
    <header>
      <app-icon :icon="whatIcon" />
      <h3>{{ whatLabel }} ({{ notes.length }})</h3>
    </header>
    <transition name="basic">
      <div v-if="updating" class="updateMessage"><app-loading-spinner /><span>Updating...</span></div>
    </transition>
    <transition-group type="transition" name="note-group" tag="ul" :data-type="what">
      <draggable
        v-bind="dragOptions"
        v-model="localNotes"
        class="list-group"
        :key="reloadList"
        tag="div"
        @start="startDrag"
        @end="moveItem"
        @sort="sorted"
        item-key="id"
        :data-type="what"
      >
        <template #item="{ element, index }">
          <meeting-notes-entry
            :key="element.editedBy ? element.id + '_' + element.editedBy.length : element.id"
            :note="element"
            :index="index + 1"
            :count="notes.length"
            grouped="true"
            :class="[element.user === myId ? 'mine' : 'not-mine']"
          />
        </template>
        <li v-if="!notes.length" key="noNotes" class="emptyGroup not-mine">No '{{ what }}s' added</li>
      </draggable>
    </transition-group>
  </div>
</template>

<script>
import _ from 'underscore';
import { useUsersStore } from '@/store/pinia/users';
import { mapState as mapPiniaState } from 'pinia';
import AppIcon from '@/components/AppIcon.vue';
import MeetingNotesEntry from '@/components/MeetingNotesEntry';
import draggable from 'vuedraggable';
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue';

export default {
  components: {
    AppIcon,
    MeetingNotesEntry,
    draggable,
    AppLoadingSpinner,
  },
  props: ['notes', 'what'],
  data: function () {
    return {
      isDragging: false,
      dragged: 0,
      updating: false,
      reloadList: 0,
      localNotes: [],
    };
  },
  mounted() {
    let _this = this;
    this.$ee.on('nM:log:dragging', _this.somethingDragging);
    this.$ee.on('nM:note:updated', _this.updated);
    this.localNotes = this.notes;
  },
  beforeUnmount() {
    let _this = this;
    this.$ee.off('nM:log:dragging', _this.somethingDragging);
    this.$ee.off('nM:note:updated', _this.updated);
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      iAmModerator: 'iAmModerator',
      myId: 'myId',
      users: 'allUsers',
    }),
    whatLabel() {
      return ['good', 'tricky', 'different'].includes(this.what) ? this.what : this.what + 's';
    },
    whatIcon() {
      return 'log-' + this.what;
    },
    showDelete() {
      return this.isMe || this.iAmModerator;
    },
    dragOptions() {
      return {
        animation: 0,
        delay: 0,
        easing: 'ease',
        ghostClass: 'item-ghost',
        chosenClass: 'item-chosen',
        dragClass: 'item-drag',
        direction: 'vertical',
        group: 'notes',
        filter: '.not-mine',
        sort: false,
      };
    },
  },
  watch: {
    notes: {
      deep: true,
      handler(nv, ov) {
        if (nv.length !== ov.length) {
          this.reloadList++;
          this.localNotes = nv;
          _.delay(() => {
            this.updating = false;
          }, 100);
        }
      },
    },
  },
  methods: {
    initials(id) {
      return _.findWhere(this.users, { id }).initials;
    },
    canDelete(id) {
      return this.iAmModerator || _.findWhere(this.users, { id }).me;
    },
    deleteLog(note) {
      this.$meetingmanager.deleteLog(note.id);
    },
    startDrag() {
      this.$ee.emit('nM:log:dragging', true);
    },
    stopDrag() {
      this.$ee.emit('nM:log:dragging', false);
    },
    somethingDragging(v) {
      this.isDragging = v;
    },
    updated(v) {
      if (v.type === this.what) {
        this.updating = true;
        _.delay(() => {
          this.localNotes = this.notes;
          this.reloadList++;

          _.delay(() => {
            this.updating = false;
          }, 500);
        }, 500);
      }
    },
    moveItem({ to, item }) {
      this.stopDrag();
      if (to.dataset.type !== item.dataset.type) {
        this.updating = true;
        const data = {
          id: item.dataset.itemId,
          type: to.dataset.type,
          description: item.dataset.description,
          editedBy: this.myId,
        };
        this.$meetingmanager.updateLog(data);
      }
    },
    sorted() {
      this.updating = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.item-ghost {
  opacity: 0;
}

.updating ul {
  opacity: 0;
}

* + .emptyGroup,
.hasNotes .emptyGroup {
  display: none;
}

.note-group-move {
  transition: all 0s;
}
.no-move {
  transition: transform 0s;
}

.noteGroup {
  position: relative;
}

.updateMessage {
  position: absolute;
  width: 100%;
  padding: 0.5em 0 0 2.4em;
  top: 2em;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  color: var(--c__quiet);
  background: var(--c__bg);
  z-index: 90;
  .loadingSpinner {
    margin-right: 0.5em;
  }
}
.mine:hover {
  cursor: grab;
}
.isDragging * {
  cursor: grabbing;
}
.isDragging {
  .mine:hover,
  .item-drag,
  .item-chosen,
  .item-ghost {
    cursor: grabbing;
  }
}
</style>
