function splitDuration(duration, unit) {
  let d = duration / 1000; // delta
  let r = {}; // result
  let s = {
    // structure
    years: 31536000,
    months: 2592000,
    weeks: 604800,
    days: 86400,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };

  Object.keys(s).forEach(function (key) {
    r[key] = Math.floor(d / s[key]);
    d -= r[key] * s[key];
  });

  r.secs = r.seconds;
  r.mins = r.minutes;

  return unit ? r[unit] : r;
}

function asUnit(duration, unit) {
  let d = parseInt(duration) / 1000; // delta
  let r = {}; // result
  let s = {
    // structure
    years: 31536000,
    months: 2592000,
    weeks: 604800,
    days: 86400,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };

  Object.keys(s).forEach(function (key) {
    r[key] = d / s[key];
  });

  r.secs = r.seconds;
  r.mins = r.minutes;

  return unit ? r[unit] : r;
}

export { splitDuration, asUnit };
