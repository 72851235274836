<template>
  <app-icon icon="circle-logo" :class="[animated ? 'animated' : 'still']" />
  <h2 v-if="!wordless">
    <app-icon icon="wordmark" />
    <span class="visuallyHidden">bixe</span>
  </h2>
</template>
<script>
import AppIcon from '@/components/AppIcon.vue';
export default {
  components: {
    AppIcon,
  },
  props: {
    animated: {
      type: Boolean,
      default: false,
    },
    wordless: {
      type: Boolean,
      default: false,
    },
    class: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: block;
  margin: 0 auto;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.circle-logo-icon {
  width: rem(124px);

  :deep(svg) {
    .e,
    .w {
      color: var(--c__text);
    }
    .n,
    .s {
      color: var(--c__opening);
    }
    .nw,
    .se {
      color: var(--c__breakthrough);
    }
    .ne,
    .sw {
      color: var(--c__container);
    }
  }
}

.wordmark-icon {
  width: rem(108px);
  margin-left: rem(6px);
}

.beta {
  font-family: $stack;
  position: absolute;
  width: rem(29px);
  font-size: rem(14px);
  line-height: math.div(17, 14);
  top: rem(3px);
  right: rem(-29px);
  font-weight: 300;
}

h2 {
  display: inline-block;
  position: relative;
  text-align: left;
  margin: rem(15px) auto;
  height: rem(52px);
}

.circle-logo-icon {
}

@keyframes logoSpinner {
  0% {
    fill-opacity: 0;
  }

  70% {
    fill-opacity: 0;
  }

  80% {
    fill-opacity: 1;
  }

  100% {
    fill-opacity: 0;
  }
}

$logo-animation-length: 2s;
$logo-stagger: 0.1s;

.animated {
  :deep(circle.stroke) {
    fill: currentColor;
    fill-opacity: 0;
  }
  :deep(.n) {
    animation: logoSpinner $logo-animation-length linear 0s infinite;
  }
  :deep(.ne) {
    animation: logoSpinner $logo-animation-length linear $logo-stagger infinite;
  }
  :deep(.e) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 2) infinite;
  }
  :deep(.se) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 3) infinite;
  }
  :deep(.s) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 4) infinite;
  }
  :deep(.sw) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 5) infinite;
  }
  :deep(.w) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 6) infinite;
  }
  :deep(.nw) {
    animation: logoSpinner $logo-animation-length linear ($logo-stagger * 7) infinite;
  }
}
</style>
