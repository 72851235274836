<template>
  <div id="main" class="mainContainer thePageFrame" :class="[frameStore.fullscreen ? 'fullWidthContainer' : '']">
    <transition name="basic">
      <sentry-overlay v-if="featuresStore.showSentry" />
    </transition>
    <transition name="basic">
      <theme-switcher-overlay v-if="featuresStore.showThemes" />
    </transition>

    <router-view v-slot="{ Component }">
      <transition name="basic" mode="out-in" @before-enter="startAnimation" @after-enter="stopAnimation">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { useFeaturesStore } from '@/store/pinia/features';
import { usePageStore } from '@/store/pinia/page';
import { useFrameStore } from '@/store/pinia/frame';
import SentryOverlay from '@/components/SentryOverlay.vue';
import ThemeSwitcherOverlay from '@/components/ThemeSwitcherOverlay.vue';

export default {
  components: {
    SentryOverlay,
    ThemeSwitcherOverlay,
  },
  setup() {
    const featuresStore = useFeaturesStore();
    const pageStore = usePageStore();
    const frameStore = useFrameStore();
    return { featuresStore, pageStore, frameStore };
  },
  data() {
    return {};
  },
  computed: {
    pageName() {
      return this.pageStore.current.pageName;
    },
    previousPageName() {
      return this.pageStore.previous.pageName;
    },
  },
  watch: {
    pageName: {
      immediate: true,
      handler(nv, ov) {
        if (ov) {
          document.body.classList.remove(ov + 'Page');
        }
        document.body.classList.add(nv + 'Page');
        document.body.classList.remove('undefinedPage');

        if (nv === 'meetingFrame') {
          document.body.classList.add('meeting');
        } else {
          document.body.classList.remove('meeting');
        }
      },
    },
  },
  methods: {
    change(pageName, args) {
      this.pageStore.update({
        ...args,
        pageName,
      });
    },
    startAnimation() {
      this.pageStore.startAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page');
        document.body.classList.add(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.add(this.pageName + 'Page');
      document.body.classList.add(this.pageName + 'Page-animateIn');
      document.body.classList.add('page-animating');

      if (this.pageStore.isMeeting) {
        document.body.classList.add('meeting');
      } else {
        document.body.classList.remove('meeting');
      }
    },
    stopAnimation() {
      this.pageStore.stopAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.remove(this.pageName + 'Page-animateIn');
      document.body.classList.remove('page-animating');
    },
  },
};
</script>
<style lang="scss">
.isDragging * {
  cursor: grabbing !important;
}
</style>
