<template>
  <sidebar-overlay class="settings">
    <h2 v-if="!featuresStore.showExtraSettings">Settings</h2>
    <section class="content">
      <connectivity-settings
        class="connectivitySettings"
        @audio:change="onAudioChanged"
        @video:change="onVideoChanged"
        @audioOutput:change="onAudioOutputChanged"
        v-if="!featuresStore.showExtraSettings"
      />
      <template v-if="!accountStore.isGuest && !featuresStore.showExtraSettings">
        <a v-if="isOpenedWithinElectronShell" class="accountSettings settingsPanelButton" @click="openUserSettings()">
          <app-icon icon="external-link" />
          <span class="text">Account settings</span>
        </a>
        <a
          v-else
          id="accountSettingsButton"
          ref="accountLink"
          class="accountSettings settingsPanelButton"
          :href="userSettingsUrl"
          target="_blank"
          @click="clickAccount"
        >
          <app-icon icon="external-link" />
          <span class="text">Account settings</span>
        </a>
      </template>
      <user-settings-extras v-if="featuresStore.showExtraSettings" />
    </section>
    <section class="trouble" v-if="!featuresStore.showExtraSettings">
      <span>Having trouble?</span>
      <a v-if="isOpenedWithinElectronShell" href="#" @click="launchCheckPage()">Help me to configure my settings</a>
      <a v-else href="/conference/check" target="_blank">Help me to configure my settings</a>
    </section>
  </sidebar-overlay>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';

import { useAccountStore } from '@/store/pinia/account';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { useUsersStore } from '@/store/pinia/users';
import AppIcon from '@/components/AppIcon';
import SidebarOverlay from '@/components/SidebarOverlay';
import ConnectivitySettings from '@/components/ConnectivitySettings';
import UserSettingsExtras from '@/components/UserSettingsExtras';
import utils from '../resources/utils';

export default {
  components: {
    AppIcon,
    SidebarOverlay,
    ConnectivitySettings,
    UserSettingsExtras,
  },
  setup() {
    const accountStore = useAccountStore();
    const featuresStore = useFeaturesStore();
    const phaseStore = usePhaseStore();
    const meetingStore = useMeetingStore();
    const mediaStore = useMediaStore();
    const usersStore = useUsersStore();
    return { accountStore, featuresStore, phaseStore, meetingStore, mediaStore, usersStore };
  },
  data() {
    return {
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  mounted() {
    let _this = this;
    this.$videomanager.on('audioPreviewPercent', _this.emitVolumePercentageChange);
    if (this.audioSource && this.audioOn) {
      this.$videomanager.startAudioPreview({ devices: { audioSource: this.audioSource, audioOn: this.audioOn } });
    }
  },
  beforeUnmount() {
    let _this = this;
    this.$videomanager.off('audioPreviewPercent', _this.emitVolumePercentageChange);
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['myId', 'iAmSysMuted']),
    ...mapPiniaState(useMediaStore, {
      devices: 'userDevices',
    }),
    ...mapPiniaState(useMediaStore, ['audioSource', 'videoSource', 'audioOn', 'videoOn']),
    ...mapPiniaState(useAccountStore, ['accountUrl', 'authUrl']),
    userSettingsUrl() {
      const { origin } = new URL(this.authUrl);
      return this.accountUrl.startsWith(origin) ? this.accountUrl : `${origin}${this.accountUrl}`;
    },
  },
  watch: {
    audioSource(newValue) {
      this.$ee.emit('bus:bixe-user-devices:update', {
        audioSource: newValue,
        audioOutputSource: this.devices.audioOutputSource,
        videoSource: this.devices.videoSource,
      });
      this.$ee.emit('bus:bixe-meeting-devices:persist', this.meetingStore.meetingId, { audioSource: newValue });
      if (newValue) {
        this.$videomanager.startAudioPreview({ devices: { audioSource: newValue, audioOn: true } });
      }
    },
    audioOutputSource(newValue) {
      this.$ee.emit('bus:bixe-user-devices:update', {
        audioSource: this.devices.audioSource,
        audioOutputSource: newValue,
        videoSource: this.devices.videoSource,
      });
      this.$ee.emit('bus:bixe-meeting-devices:persist', this.meetingStore.meetingId, { audioOutputSource: newValue });
    },
    videoSource(newValue) {
      this.$ee.emit('bus:bixe-user-devices:update', {
        audioSource: this.devices.audioSource,
        audioOutputSource: this.devices.audioOutputSource,
        videoSource: newValue,
      });
      this.$ee.emit('bus:bixe-meeting-devices:persist', this.meetingStore.meetingId, { videoSource: newValue });
    },
  },
  methods: {
    onVideoChanged(deviceId) {
      this.usersStore.updateUser(this.myId, { video_muted: deviceId ? false : true });
      this.mediaStore.setVideoOn(!!deviceId);
      if (deviceId) {
        this.$videomanager.updateSettings({ devices: { videoSource: deviceId }, type: 'videoSource' });
        this.$meetingmanager.unmute(this.myId, { unmuteVideo: true });
        if (this.devices.videoSource !== deviceId) {
          this.$videomanager.restart({ devices: { videoSource: deviceId }, type: 'videoSource' });
        }
      } else {
        this.$meetingmanager.mute(this.myId, { muteVideo: true });
      }
    },
    onAudioChanged(deviceId) {
      this.usersStore.updateUser(this.myId, { muted: deviceId ? false : true });
      this.mediaStore.setAudioOn(!!deviceId);
      if (deviceId) {
        this.$videomanager.updateSettings({ devices: { audioSource: deviceId }, type: 'audioSource' });
        if (this.iAmSysMuted) {
          return;
        }
        this.$meetingmanager.unmute(this.myId, { unmuteAudio: true });
      } else {
        this.$meetingmanager.mute(this.myId, { muteAudio: true });
      }
    },
    onAudioOutputChanged(deviceId) {
      const devices = { audioOutputSource: deviceId };
      this.$videomanager.updateSettings({ devices: { audioOutputSource: deviceId }, type: 'audioOutputSource' });
      this.$videomanager.restart({ devices, type: 'audioOutputSource' });
    },
    clickAccount() {
      this.$refs.accountLink.blur();
    },
    emitVolumePercentageChange(level) {
      this.$ee.emit('api:setVolumeTestMeter', level.percent / 100);
    },
    launchCheckPage() {
      ipcApi.send({ name: 'openExternal', url: `${window.location.origin}/conference/check`, closeSelf: false });
    },
    openUserSettings() {
      ipcApi.send({ name: 'openExternal', url: this.userSettingsUrl, closeSelf: false });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 0 rem(37px) 0 rem(38px);
}

:deep(.sidebarOverlay.settings) {
  padding-bottom: rem(130px);
  justify-content: center;
}

h2 {
  margin-bottom: rem(38px);
  line-height: rem(22px);
}

:deep(.currentDevice) {
  left: rem(45px);
  width: rem(340px);
  &:after {
    background: linear-gradient(to right, var(--c__bg-0) 0%, var(--c__bg-0) rem(120px), var(--c__bg-100) rem(150px));
  }
}

:deep(.meter) {
  width: rem(128px);
}

:deep(.videoInput) {
  .currentDevice {
    top: rem(1px);
  }
  .deviceIcon {
    top: rem(3px);
  }
  .devicesToggle {
    top: rem(-2px);
  }
}
:deep(.audioOutput) {
  .currentDevice {
    top: rem(1px);
  }
  .deviceIcon {
    top: 3px;
  }
}

:deep(.audioInput) {
  margin: rem(16px) 0 rem(19px) 0;
  .currentDevice {
    top: rem(3px);
  }
}

.accountSettings {
  @include type-size(x-small);
  line-height: div(22, 12);
  color: var(--c__text);
  display: flex;
  font-weight: 100;
  text-align: left;
  margin-top: rem(18px);
  padding: 0 rem(2.5px);

  @include triggered {
    color: var(--c__accent);
  }
  .icon {
    width: rem(41px);
    margin-top: rem(1.5px);
    display: inline-block;
    text-align: left;
  }
  :deep(svg) {
    width: rem(16px);
    height: rem(16px);
    display: block;
  }
  .text {
    margin-top: rem(4px);
  }
}
.trouble {
  position: absolute;
  bottom: rem(46px);
  text-align: center;
  width: 100%;
  span,
  a {
    display: block;
  }
}
</style>
