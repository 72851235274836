<template>
  <div class="notice sidebarNotice" :class="noticeClass">
    <div class="content">
      <h2 v-if="notice.heading" v-html="notice.heading" />
      <p v-if="notice.content" v-html="notice.content" />
      <div class="noticeControls">
        <button v-for="b in notice.buttons" type="button" @click.once.prevent="handleButton(b)" :key="b.value">{{ b.text }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { usePageStore } from '@/store/pinia/page';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMessagesStore } from '@/store/pinia/messages';
import { useUsersStore } from '@/store/pinia/users';
import screenInfo from '@/resources/screen-info';

const pageStore = usePageStore();
const phaseStore = usePhaseStore();
const sidebarStore = useSidebarStore();
const messagesStore = useMessagesStore();
const usersStore = useUsersStore();

const { notice } = storeToRefs(messagesStore);
const { current: currentPage } = storeToRefs(pageStore);
const { current: currentSidebar } = storeToRefs(sidebarStore);
const { currentPhase } = storeToRefs(phaseStore);
const { speakingId } = storeToRefs(usersStore);

const pageName = computed(() => currentPage.value.pageName);

const skillClass = computed(() => {
  return ['lobby', 'freeform', 'groups', 'breakTime'].includes(currentSidebar.value.phase) ? 'misc' : screenInfo[currentSidebar.value.phase].skill;
});

const noticeClass = computed(() => {
  let classes = currentSidebar.value.name === 'skillBar' ? [skillClass.value] : ['misc'];

  if (notice.value.tipClass) {
    classes = [classes, notice.value.tipClass];
  }
  return classes;
});

function handleButtonRaw({ isResolve, isReject, value }) {
  if (isResolve) {
    notice.value.resolve(value);
  }
  if (isReject) {
    notice.value.reject(value);
  }
  messagesStore.clearInterrupt();
}

const handleButton = _.throttle(handleButtonRaw, 500);

function clearEnders() {
  if (notice.value && ['sureYouWantToEndPhase', 'sureYouWantToStartPhase', 'sureYouWantToReveal', 'timeLeftToCheckIn'].includes(notice.value.messageName)) {
    messagesStore.clearInterrupt();
  }
}

// watch

watch(pageName, () => {
  messagesStore.clearInterrupt();
});

watch(currentPhase, () => {
  clearEnders();
});

watch(speakingId, () => {
  if (notice.value && notice.value.messageName === 'timeLeftToCheckIn') {
    messagesStore.clearInterrupt();
  }
});
</script>

<style lang="scss" scoped>
.sidebarNotice {
  z-index: 90;
  position: fixed;
  bottom: 0;
  right: 0;
  width: rem($sidebar-width);
  background: var(--c__bg);
  border-left: 1px solid var(--c__edges);
  border-top: 1px solid var(--c__edges);
  padding: rem(25px) rem(34px) rem(15px) rem(34px);

  .name {
    font-weight: 800;
  }

  &::before {
    content: '';
    position: absolute;
    display: none;
    height: 3px;
    left: 0;
    right: 0;
    top: -5px;
  }

  button {
    @include type-size('medium');
    flex: 1 1 50%;
    border: none;
    color: inherit;
    height: rem(33px);
    position: relative;
    text-transform: capitalize;
  }

  button:hover,
  button:focus {
    background: var(--c__bg);
    color: var(--c__accent);
  }

  .controls,
  .noticeControls {
    display: flex;
    flex-direction: row;
    border-top: solid 1px var(--c__text);
    padding-top: rem(15px);
    position: relative;
    button + button {
      margin-left: 1px;
    }
    &::after {
      top: rem(15px);
      bottom: rem(0px);
      content: '';
      background: var(--c__text);
      display: block;
      position: absolute;
      left: 50%;
      margin-left: -0.5px;
      width: 1px;
    }
  }

  &.opening,
  &.container,
  &.tension,
  &.breakthrough {
    --c__button: var(--c__white);
    --energy: var(--c__bg);
    --c__text: var(--c__text-energy);

    background-color: var(--energy);
    border-top: 2px solid var(--c__button);
    padding-bottom: rem(34px);
    &::before {
      display: block;
    }

    .noticeControls {
      border: solid 2px var(--c__button);
      color: var(--c__button);
      padding: 0;
      &::after {
        top: rem(6px);
        bottom: rem(6px);
        background: var(--c__button);
      }
    }

    button {
      height: rem(46px);
    }

    button::before,
    button::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      background: rgba(255, 255, 255, 0);
    }
    button::before {
      left: -1px;
    }
    button::after {
      right: -1px;
    }
    button:hover,
    button:focus {
      color: var(--energy);
      background: var(--c__button);
      &::before,
      &::after {
        background: rgba(255, 255, 255, 1);
      }
    }
  }

  &.short p {
    text-align: center;
  }

  &.opening {
    --energy: var(--c__skillbar-bg-opening-solid);
  }

  &.container {
    --energy: var(--c__skillbar-bg-container-solid);
  }

  &.tension {
    --energy: var(--c__skillbar-bg-tension-solid);
  }

  &.breakthrough {
    --energy: var(--c__skillbar-bg-breakthrough-solid);
  }
}

.bottom-notice-enter-active {
  transition: all 0.5s cubic-bezier(0, 1, 0.5, 1);
}
.bottom-notice-leave-active {
  transition: all 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}
.bottom-notice-enter-from,
.bottom-notice-leave-to {
  transform: translateY(100%);
}
</style>
