<template>
  <div />
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useStorage } from 'vue3-storage';
import { useMediaStore } from '@/store/pinia/media';
const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  setup() {
    const mediaStore = useMediaStore();
    return { mediaStore };
  },
  mounted() {
    const _this = this;
    this.$ee.on('bus:bixe-user-devices:update', _this.update);
  },
  unmounted() {
    const _this = this;

    this.$ee.off('bus:bixe-user-devices:update', _this.update);
  },
  computed: {
    ...mapPiniaState(useMediaStore, {
      devices: 'devices',
      audioSource: (state) => state.userDevices.audioSource,
      audioOutputSource: (state) => state.userDevices.audioOutputSource,
      videoSource: (state) => state.userDevices.videoSource,
    }),
  },
  watch: {
    devices: {
      deep: true,
      handler(newValue) {
        const userDevices = { audioSource: null, videoSource: null, audioOutputSource: null };

        const lastAudioSource = localStorage.getStorageSync('user:audioSource', this.audioSource);
        const lastVideoSource = localStorage.getStorageSync('user:videoSource', this.videoSource);
        const lastAudioOutputSource = localStorage.getStorageSync('user:audioOutputSource', this.audioOutputSource);

        const isVideoOff = localStorage.getStorageSync('user:videoOn') === false;
        const isAudioOff = localStorage.getStorageSync('user:audioOn') === false;

        const audioDevices = newValue.filter((x) => x.kind === 'audioInput');
        const videoDevices = newValue.filter((x) => x.kind === 'videoInput');
        const audioOutputDevices = newValue.filter((x) => x.kind === 'audioOutput');

        if (lastAudioOutputSource && audioOutputDevices.find((x) => x.deviceId === lastAudioOutputSource)) {
          userDevices.audioOutputSource = lastAudioOutputSource;
        } else {
          userDevices.audioOutputSource = audioOutputDevices[0]?.deviceId;
        }

        if (lastAudioSource && audioDevices.find((x) => x.deviceId === lastAudioSource)) {
          userDevices.audioSource = lastAudioSource;
        } else {
          userDevices.audioSource = audioDevices[0]?.deviceId;
        }
        this.mediaStore.setAudioOn(!isAudioOff);
        if (lastVideoSource && videoDevices.find((x) => x.deviceId === lastVideoSource)) {
          userDevices.videoSource = lastVideoSource;
        } else {
          userDevices.videoSource = videoDevices[0]?.deviceId;
        }
        this.mediaStore.setVideoOn(!isVideoOff);
        this.update(userDevices);
      },
    },
  },
  methods: {
    update({ audioSource, videoSource, audioOutputSource }) {
      localStorage.setStorageSync('user:audioSource', audioSource);
      localStorage.setStorageSync('user:videoSource', videoSource);
      localStorage.setStorageSync('user:audioOutputSource', audioOutputSource);
      this.mediaStore.updateUserDevices({ audioSource, videoSource, audioOutputSource });
    },
  },
};
</script>
