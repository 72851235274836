<template>
  <section class="page checkPages" :class="pageName" :data-page-name="pageName">
    <div class="main">
      <router-view v-slot="{ Component }">
        <transition name="basic" mode="out-in" key="route" @before-enter="startAnimation" @after-enter="stopAnimation">
          <component :is="Component" :key="pageName" />
        </transition>
      </router-view>

      <nav v-if="subPage !== 'checkStart' && subPage !== 'checkComplete'" class="subPageLinks">
        <ul>
          <li v-for="(checkPoint, index) in checkPoints" :key="index">
            <router-link
              v-if="checkPoint.createBulletPoint"
              :to="checkPoint.path"
              :class="{ current: checkPoint.subPageName === subPage, passed: checkPoint.isPassed }"
            >
              <span class="visuallyHidden">{checkPoint.accessibilityMessage}</span><span v-if="checkPoint.isPassed" class="visuallyHidden"> (complete)</span>
            </router-link>
          </li>
        </ul>
      </nav>
      <router-link v-if="showSkip" class="skip" :to="nextPath">Skip this step?</router-link>
    </div>

    <p v-if="subPage === 'checkComplete'" class="completeMessage">Invited to a meeting?<br />To join the meeting, use the link in your invite.</p>
  </section>
</template>

<script>
import _ from 'underscore';
import { usePageStore } from '@/store/pinia/page';
import { useMediaStore } from '@/store/pinia/media';
import { useStorage } from 'vue3-storage';
const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  mounted() {
    const _this = this;
    this.$readinessChecker.initialize(this.$router, localStorage);
    this.$devicemanager.getDevices().then((devices) => {
      this.mediaStore.updateDevices(devices);
    });
    this.checkPoints = this.$readinessChecker.getAllCheckPoints();
    this.$devicemanager.addStreamingDevicesChangeListener(_.debounce(_this.notifyDeviceListChange, 250));
  },
  unmounted() {
    this.$devicemanager.removeStreamingDevicesChangeListener();
  },
  computed: {
    subPage() {
      return this.pageStore.current.pageName;
    },
    previousPageName() {
      return this.pageStore.previous.pageName;
    },
    year() {
      let year = new Date().getFullYear();
      return year;
    },
    showSkip() {
      return !['checkStart', 'checkComplete', 'checkScreenSharing'].includes(this.subPage);
    },
    nextPath() {
      let currentIndex = this.checkPoints.map((p) => p.subPageName).indexOf(this.subPage);

      if (this.checkPoints[currentIndex + 1]) {
        return this.checkPoints[currentIndex + 1].path;
      } else {
        return '';
      }
    },
  },
  watch: {
    subPage() {
      this.checkPoints = this.$readinessChecker.getAllCheckPoints();
    },
  },
  data() {
    return {
      checkPoints: [],
    };
  },
  methods: {
    startAnimation() {
      this.pageStore.startAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page');
        document.body.classList.add(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.add(this.pageName + 'Page');
      document.body.classList.add(this.pageName + 'Page-animateIn');
      document.body.classList.add('page-animating');

      if (this.pageStore.isMeeting) {
        document.body.classList.add('meeting');
      } else {
        document.body.classList.remove('meeting');
      }
    },
    stopAnimation() {
      this.pageStore.stopAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.remove(this.pageName + 'Page-animateIn');
      document.body.classList.remove('page-animating');
    },
    notifyDeviceListChange(updatedDevices) {
      this.$ee.emit('nM:deviceListChanged', updatedDevices);
    },
  },
};
</script>
<style lang="scss" scoped>
.checkPages {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: var(--c__bg-alt);

  :deep(.btn),
  :deep(.btn-pair) {
    --border-width: 2px;
    width: rem(300px);
    margin-top: rem(24px);
  }

  :deep(h2) {
    font-family: $stack-deco;
    @include type-size(xx-large);
    line-height: math.div(58em, 48);
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: rem(19px);
  }

  :deep(p) {
    @include type-size(medium);
    line-height: math.div(24em, 18);
  }

  :deep(h2),
  :deep(p) {
    text-align: center;
  }

  :deep(.subContent) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: auto;
  }

  .main {
    flex: 0 0 100%;
    margin: auto 0;
    padding-top: 4em;
    .checkCompletePage & {
      padding-top: 8em;
    }
  }

  .subPageLinks {
    margin: rem(36px) auto auto auto;
    width: 100%;
    ul {
      list-style: none;
      text-align: center;
      padding: 0 1em;
    }
    li {
      display: inline-block;
      margin: 0 rem(6px);
    }
    a {
      display: inline-block;
      height: rem(12px);
      width: rem(12px);
      border-radius: 50%;
      background: var(--c__quiet);
      &.passed {
        background: var(--c__success);
        @include triggered {
          background: var(--c__accent);
        }
      }
      &.current {
        cursor: default;
        background: var(--c__text);
        @include triggered {
          background: var(--c__text);
        }
      }
    }
  }

  :deep(.loadingDevices),
  :deep(.devicesSelect) {
    width: rem(300px);
  }

  :deep(.loadingDevices) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--c__disabled);
  }

  :deep(.v-select) {
    width: rem(300px);
    position: relative;
    z-index: 5;
    margin: rem(40px) 0 0 0;
  }

  :deep(.bixeLogoIcon) {
    display: block;
    width: rem(100px);
    background: var(--c__accent);
    color: var(--c__bg);
    padding: rem(14.5px);
    border-radius: 50%;
    margin: 0 auto rem(25px) auto;
    display: block;
    svg {
      display: block;
      width: 100%;
      height: 100%;
      margin: 0;
    }
  }

  & > footer {
    width: 100%;
    flex: 0 0 100%;
    margin-top: auto;
    text-align: center;

    ul {
      list-style: none;
      text-align: center;
      margin: rem(36px) 0;
      padding: 0 1em;
    }
    li {
      font-weight: 100;
      display: inline-block;
      margin: 0 1em;
    }
    a,
    button {
      text-decoration: none;
      color: var(--c__text);
      @include triggered {
        color: var(--c__accent);
      }
    }
  }
  :deep(.completeMessage) {
    margin: auto 0;
    @include type-size(small);
  }

  :deep(.v-select__label) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    &.focusable:active,
    &.focusable:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}

.skip {
  text-align: center;
  display: block;
  @include type-size('x-small');
  line-height: math.div(15em, 12);
  margin: rem(36px) auto 0 auto;
  color: var(--c__text);
  @include triggered {
    color: var(--c__accent);
  }
}
</style>
