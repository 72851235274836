import {
  deviceKind,
  eventLevel,
  frameRateMap,
  frameRateMapReverse,
  meetingStatus,
  setups,
  phaseMap,
  phaseMapReverse,
  phaseRefreshers,
  resolutionMap,
  resolutionMapReverse,
  videoKind,
  screenSharingStatus,
  videoSource,
  videoType,
} from '@/shared/constants';

export default (nM) => {
  nM.deviceKind = deviceKind;
  nM.eventLevel = eventLevel;
  nM.frameRateMap = frameRateMap;
  nM.frameRateMapReverse = frameRateMapReverse;
  nM.meetingStatus = meetingStatus;
  nM.setups = setups;
  nM.phaseMap = phaseMap;
  nM.phaseMapReverse = phaseMapReverse;
  nM.phaseRefreshers = phaseRefreshers;
  nM.resolutionMap = resolutionMap;
  nM.resolutionMapReverse = resolutionMapReverse;
  nM.videoKind = videoKind;
  nM.screenSharingStatus = screenSharingStatus;
  nM.videoSource = videoSource;
  nM.videoType = videoType;
};
