import {
  AllHighestVideoBandwidthPolicy,
  DefaultActiveSpeakerPolicy,
  DefaultDeviceController,
  DefaultMeetingSession,
  DefaultVideoTile,
  MeetingSessionConfiguration,
} from 'amazon-chime-sdk-js';
import EventEmitter from 'wolfy87-eventemitter';
import Queue from '@/services/queue.service';
import { createGroupEvents } from '../shared/constants';
import MeetingEventsLogger from './meetingEventsLogger';

const wait = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));
const defaults = {};

class AwsChimeVideoManager extends EventEmitter {
  constructor(config) {
    super();

    /**
     * The boolean property to detect is the instance initialized or not
     * @type {Boolean}
     */
    this.initialized = false;
    this.missedPongs = null;

    this.config = Object.assign({}, defaults, config);
    this.meetingLogger = null;
    this.deviceController = null;

    this.configuration = null;
    this.observer = null;
    this.meetingSession = null;

    this.localTileId = null;
    this.tiles = {};
    this.activeTiles = new Set();
    this.incomingVideoSubscription = {
      pausedUserIds: [],
    };

    this.videoSource = null;
    this.audioSource = null;

    this.analyserNode = null;
    this.analyserNodeRequestId = null;
    this.analyserNodeCallback = () => null;

    this.queue = new Queue();
    this.queue.on('process', () => {
      this.processQueue();
    });

    this.userIdsForIncomingVideoSubs = []; // list of externalUserIds of current page, for eg ["user-32"]
    this.remoteVideoSources = []; // list of all enabled remote video sources, eg [{attendee: {externalUserId: "user-32"}}]
    this.incomingAudioVolumeSubs = []; // list of all chime attendee IDs which are subscribed for volume indicators , for eg: [62c19858-c9bc-1e82-f701-5edfb5166748]
    this.videoStreamsSubscribedTo = [];
    this.attendeeIdMap = {}; // a map of chimeAttendeeId to bixeAttendeeId, for eg: {62c19858-c9bc-1e82-f701-5edfb5166748: {chimeAttendeeId: "62c19858-c9bc-1e82-f701-5edfb5166748",bixeAttendeeId: "user-32"}}

    this.initialized = false;
    this.started = false;
    this.avSettings = {
      audioOn: false,
      videoOn: false,
    };
  }

  async init(session, metaData) {
    const configNew = new MeetingSessionConfiguration(session.meeting, session.attendee);
    if (this.initialized && configNew.meetingId === this.configuration.meetingId) {
      return;
    } else {
      await this.destroy();
    }
    this.emitUpdatedStatus(createGroupEvents.SETTING_MEETING_PARAMS);

    this.configuration = new MeetingSessionConfiguration(session.meeting, session.attendee);
    this.configuration.videoDownlinkBandwidthPolicy = new AllHighestVideoBandwidthPolicy(session.attendee.AttendeeId);
    const { externalAttendeeId, externalMeetingId, pageName } = metaData;
    this.meetingLogger = new MeetingEventsLogger({
      postUrl: this.config.postUrl,
      attendeeId: this.configuration.credentials.attendeeId,
      meetingId: this.configuration.meetingId,
      externalAttendeeId,
      externalMeetingId,
      pageName,
    });
    const sessionLogger = this.meetingLogger.getSessionLogger();
    this.deviceController = new DefaultDeviceController(sessionLogger, { enableWebAudio: this.config.enableWebAudio });
    this.meetingSession = new DefaultMeetingSession(this.configuration, sessionLogger, this.deviceController);

    this.observer = {
      audioVideoDidStart: () => {
        this.emitUpdatedStatus(createGroupEvents.AUDIO_VIDEO_DID_AVAILABLE);
        setTimeout(() => {
          this.emitUpdatedStatus(createGroupEvents.MEETING_AVAILABLE, true);
        }, 1000);
      },
      remoteVideoSourcesDidChange: (sources) => {
        this.remoteVideoSources = sources;
        this.updateIncomingVideoStreamSubscriptions();
      },
      audioInputStreamEnded: (deviceId) => {
        this.emit('audioInputStreamEnded', { deviceId });
      },
      contentShareDidStart: () => {
        this.emit('contentShareDidStart');
      },
      contentShareDidStop: () => {
        this.emit('contentShareDidStop');
      },
      videoInputStreamEnded: (deviceId) => {
        this.emit('videoInputStreamEnded', { deviceId });
      },
      videoTileDidUpdate: (tile) => {
        if (tile.boundAttendeeId) {
          this.tiles[tile.tileId] = tile;
          window.__INITIAL_STATE__.tiles = this.tiles;
          if (!this.activeTiles.has(tile.tileId)) {
            this.activeTiles.add(tile.tileId);
            if (this.incomingVideoSubscription.pausedUserIds.includes(tile.boundExternalUserId) && !tile.isContent) {
              this.meetingSession.audioVideo.pauseVideoTile(tile.tileId);
            } else {
              this.emit('video:started', { tile });
            }
          }
        }
      },
      videoTileWasRemoved: (tileId) => {
        const tile = this.tiles[tileId];
        this.meetingSession.audioVideo.removeVideoTile(tileId);
        delete this.activeTiles.delete(tileId);
        delete this.tiles[tileId];
        if (tile) {
          this.emit('video:stopped', { tile });
        }
      },
      connectionHealthDidChange: ({ consecutiveMissedPongs }) => {
        if (consecutiveMissedPongs !== this.missedPongs) {
          this.emit('missedPongs', { noOfMissedPongs: consecutiveMissedPongs });
          this.missedPongs = consecutiveMissedPongs;
        }
      },
      connectionDidBecomePoor: () => {
        this.emit('onConnectionHealthChanged', { isConnectivityWentPoor: true });
      },
      connectionDidBecomeGood: () => {
        this.emit('onConnectionHealthChanged', { isConnectivityWentPoor: false });
        if (this.avSettings.videoOn && this.videoSource) {
          this.restart({ devices: { videoSource: this.videoSource }, type: 'videoSource' });
        }
      },
    };

    this.meetingSession.eventController.addObserver({
      eventDidReceive: (eventName, attributes) => {
        this.meetingLogger.logRealtimeChimeEvent(eventName, attributes);
      },
    });
    this.meetingSession.audioVideo.addObserver(this.observer);
    this.meetingSession.audioVideo.addDeviceChangeObserver(this.observer);
    this.meetingSession.audioVideo.addContentShareObserver(this.observer);
    // ugly hack to avoid the chime device list propagation error
    this.meetingSession.audioVideo.listAudioInputDevices();

    this.meetingSession.audioVideo.realtimeSubscribeToAttendeeIdPresence((attendeeId, present, externalUserId) => {
      this.attendeeIdMap[attendeeId] = {
        chimeAttendeeId: attendeeId,
        bixeAttendeeId: externalUserId,
      };
      if (!present) {
        const tile = Object.values(this.tiles).find((t) => t.boundAttendeeId === externalUserId);

        if (tile) {
          delete this.activeTiles.delete(tile.tileId);
          delete this.tiles[tile.tileId];
        }
      }
      if (present && !this.incomingAudioVolumeSubs.includes(attendeeId)) {
        this.subscribeToVolumeIndicator(attendeeId);
      }
    });

    this.meetingSession.audioVideo.subscribeToActiveSpeakerDetector(new DefaultActiveSpeakerPolicy(), (attendeeIds) => {
      if (attendeeIds.length) {
        const bixeAttendeeIds = attendeeIds.map((id) => this.attendeeIdMap[id]?.bixeAttendeeId).filter((val) => !!val);
        this.emit('activeSpeakerChange', bixeAttendeeIds);
      }
    });

    this.queue.start();

    // mark the instance as initialized
    this.initialized = true;
    this.emit('ready');
  }

  updateLogMetaData(metaData) {
    this.meetingLogger?.updateMetaData(metaData);
  }

  async destroy() {
    if (!this.initialized) return;

    try {
      this.queue.stop();
      this.queue.clear();

      await this.meetingSession.audioVideo.removeObserver(this.observer);
      await this.meetingSession.audioVideo.removeDeviceChangeObserver(this.observer);
      await this.meetingSession.audioVideo.removeContentShareObserver(this.observer);

      this.configuration = null;
      this.meetingSession = null;
      this.observer = null;

      this.videoSource = null;
      this.audioSource = null;

      // mark the instance as not initialized
      this.initialized = false;
      this.emit('destroyed');
    } catch (err) {
      this.emit('error', err);
    }
  }

  async connect() {
    if (this.connected || !this.initialized) return;
    setTimeout(() => {
      this.connected = true;
      this.emit('connected');
    }, 0);
  }

  async disconnect() {
    if (!this.connected) return;

    setTimeout(() => {
      this.localTileId = null;
      this.tiles = {};
      this.activeTiles.clear();
      this.incomingVideoSubscription = {
        pausedUserIds: [],
      };
      this.connected = false;
      this.emit('disconnected');
    }, 0);
  }

  async startInViewOnlyMode() {
    await this.meetingSession.audioVideo.startVideoInput(null);
    await this.meetingSession.audioVideo.startAudioInput(null);
    this.meetingSession.audioVideo.start();
  }

  async start({ devices, settings, audioElement }) {
    const maxBandwidthKbps = 600;

    this.emitUpdatedStatus(createGroupEvents.STARTING_DEVICES_CONNECTIVITY);
    try {
      const width = parseInt(settings.resolution.split('x')[0], 10);
      const height = parseInt(settings.resolution.split('x')[1], 10);
      this.meetingSession.audioVideo.chooseVideoInputQuality(width, height, settings.frameRate);
      this.meetingSession.audioVideo.setVideoMaxBandwidthKbps(maxBandwidthKbps);
      if (devices.videoOn) {
        this.avSettings.videoOn = true;
        await this.meetingSession.audioVideo.startVideoInput(devices.videoSource);
      }

      const connectivityInterruptedIdentifier = setTimeout(() => {
        this.emitUpdatedStatus(createGroupEvents.DEVICES_CONNECTIVITY_INTERRUPTED);
      }, 5000);
      await this.meetingSession.audioVideo.startAudioInput(devices.audioSource || '');
      await this.meetingSession.audioVideo.chooseAudioOutput(devices.audioOutputSource || '');
      await this.meetingSession.audioVideo.bindAudioElement(audioElement);
      await DefaultDeviceController.getAudioContext().resume();
      clearTimeout(connectivityInterruptedIdentifier);
      this.emitUpdatedStatus(createGroupEvents.DEVICES_CONNECTED);
      await this.meetingSession.audioVideo.start();

      if (devices.videoSource && devices.videoOn) {
        this.localTileId = await this.meetingSession.audioVideo.startLocalVideoTile();
      }

      this.videoSource = devices.videoSource;
      this.audioSource = devices.audioSource;

      if (devices.audioOn) {
        this.avSettings.audioOn = true;
      } else {
        this.mute();
      }

      // mark the instance as started
      this.started = true;
      this.emit('started');
    } catch (err) {
      console.log('Error in attempting to start chime meeting', err);
      this.emit('error', err);
    }
  }

  async stop(dispose) {
    if (!this.initialized) return;

    await this.meetingSession.audioVideo.stopAudioInput();
    await this.meetingSession.audioVideo.stopVideoInput();
    await this.meetingSession.deviceController.destroy();
    try {
      if (this.localTileId) {
        await this.meetingSession.audioVideo.unbindVideoElement(this.localTileId);
      }

      if (this.videoSource) {
        await this.meetingSession.audioVideo.stopVideoInput();
      }

      await this.meetingSession.audioVideo.unbindAudioElement();
      await this.meetingSession.audioVideo.stop();

      this.localTileId = null;
      this.videoSource = null;
      this.audioSource = null;

      // mark the instance as stopped
      this.started = false;
      this.emit('stopped', dispose);
    } catch (err) {
      this.emit('error', err);
    }
  }

  async restart({ devices, type }) {
    if (!this.initialized) return;
    if (type === 'audioSource') {
      await this.meetingSession.audioVideo.startAudioInput(devices.audioSource);
      this.audioSource = devices.audioSource;
    }

    if (type === 'audioOutputSource') {
      await this.meetingSession.audioVideo.chooseAudioOutput(devices.audioOutputSource);
      this.audioOutputSource = devices.audioOutputSource;
    }

    if (type === 'videoSource') {
      try {
        await this.meetingSession.audioVideo.stopVideoInput();
        if (devices.videoSource) {
          await this.meetingSession.audioVideo.startVideoInput(devices.videoSource || null);
          this.localTileId = await this.meetingSession.audioVideo.startLocalVideoTile();
        }
        await wait(1000);
        this.videoSource = devices.videoSource;
      } catch (err) {
        console.error('restart|err', err);
        this.emit('error', err);
      }
    }
  }

  async startScreen({ targetElementId, sourceId } = { targetElementId: '', sourceId: null }) {
    try {
      let stream = null;
      const targetElement = document.getElementById(targetElementId);
      if (!targetElement) {
        this.queue.add('attachPresentationVideo', {
          type: 'attachPresentationVideo',
          sourceId,
          targetElementId,
        });
        return;
      }
      if (sourceId) {
        stream = await this.meetingSession.audioVideo.startContentShareFromScreenCapture(sourceId);
      } else {
        stream = await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
      }
      DefaultVideoTile.connectVideoStreamToVideoElement(stream, targetElement, true);
    } catch (err) {
      this.emit('error', err);
    }
  }

  async stopScreen() {
    try {
      await this.meetingSession.audioVideo.stopContentShare();
    } catch (err) {
      this.emit('error', err);
    }
  }

  async startPreview({ devices, targetElementId }) {
    try {
      const targetElement = document.getElementById(targetElementId);
      if (targetElement) {
        // ugly hack to avoid the chime device list propagation error
        await this.meetingSession.audioVideo.listVideoInputDevices();
        await this.meetingSession.audioVideo.startVideoInput(devices.videoSource);
        if (devices.videoOn) {
          this.meetingSession.audioVideo.startVideoPreviewForVideoInput(targetElement);
        }
      } else {
        this.queue.add(`bindPreviewVideo-${targetElementId}`, {
          type: 'bindPreviewVideo',
          devices,
          targetElementId,
        });
      }
    } catch (err) {
      console.error('startPreview|err', err);
      this.emit('error', err);
    }
  }

  async stopPreview({ targetElement }) {
    try {
      if (targetElement) {
        await this.meetingSession.audioVideo.stopVideoPreviewForVideoInput(targetElement);
        await this.meetingSession.audioVideo.stopVideoInput();
      }
    } catch (err) {
      this.emit('error', err);
    }
  }

  async startAudioPreview({ devices }) {
    try {
      await this.stopAudioPreview();
      // ugly hack to avoid the chime device list propagation error
      await this.meetingSession.audioVideo.listVideoInputDevices();
      if (devices.audioOn) {
        await this.meetingSession.audioVideo.startAudioInput(devices.audioSource);
      }
      this.analyserNode = this.meetingSession.audioVideo.createAnalyserNodeForAudioInput();

      if (!this.analyserNode || !this.analyserNode.getByteTimeDomainData) {
        return;
      }

      const data = new Uint8Array(this.analyserNode.fftSize);

      let frameIndex = 0;
      this.analyserNodeCallback = () => {
        if (frameIndex === 0) {
          this.analyserNode.getByteTimeDomainData(data);

          const lowest = 0.01;
          let max = lowest;

          data.forEach((f) => {
            max = Math.max(max, (f - 128) / 128);
          });

          const normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
          const percent = Math.min(Math.max(normalized * 100, 0), 100);
          this.emit('audioPreviewPercent', { percent });
        }

        frameIndex = (frameIndex + 1) % 2;
        this.analyserNodeRequestId = requestAnimationFrame(this.analyserNodeCallback);
      };
      this.analyserNodeRequestId = requestAnimationFrame(this.analyserNodeCallback);
    } catch (err) {
      this.emit('error', err);
    }
  }

  async stopAudioPreview() {
    if (!this.analyserNode) {
      return;
    }

    try {
      cancelAnimationFrame(this.analyserNodeRequestId);

      // Disconnect the analyser node from its inputs and outputs.
      this.analyserNode.disconnect();
      this.analyserNode.removeOriginalInputs();

      this.analyserNode = null;
      this.analyserNodeRequestId = null;
      this.analyserNodeCallback = () => null;
    } catch (err) {
      this.emit('error', err);
    }
  }

  async mute() {
    try {
      this.avSettings.audioOn = false;
      if (this.meetingSession.audioVideo.realtimeIsLocalAudioMuted()) {
        return;
      }
      await this.meetingSession.audioVideo.realtimeMuteLocalAudio();
    } catch (err) {
      this.emit('error', err);
    }
  }

  async unmute() {
    try {
      this.avSettings.audioOn = true;
      await this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
    } catch (err) {
      this.emit('error', err);
    }
  }

  async muteVideo() {
    try {
      this.avSettings.videoOn = false;
      await this.meetingSession.audioVideo.stopVideoInput();
    } catch (err) {
      this.emit('error', err);
    }
  }

  async unmuteVideo() {
    try {
      if (this.videoSource) {
        this.avSettings.videoOn = true;
        await this.meetingSession.audioVideo.startVideoInput(this.videoSource);
        await this.meetingSession.audioVideo.startLocalVideoTile();
      }
    } catch (err) {
      console.error('unmuteVideo|err', err);
      this.emit('error', err);
    }
  }

  onAvatarsListUpdated(externalUserIdsForVideoSubs) {
    this.userIdsForIncomingVideoSubs = externalUserIdsForVideoSubs;
    this.updateIncomingVideoStreamSubscriptions();
  }

  updateIncomingVideoStreamSubscriptions() {
    const validSources = this.remoteVideoSources.filter((source) => this.userIdsForIncomingVideoSubs.includes(source.attendee.externalUserId));
    const isSourcesUpdated =
      validSources.length !== this.videoStreamsSubscribedTo.length ||
      validSources.some((source) => !this.videoStreamsSubscribedTo.includes(source.attendee.externalUserId));
    if (!isSourcesUpdated) {
      return;
    }
    if (this.configuration && this.configuration.videoDownlinkBandwidthPolicy) {
      this.configuration.videoDownlinkBandwidthPolicy.chooseRemoteVideoSources(validSources);
    }
    this.videoStreamsSubscribedTo = validSources;
  }

  async bindVideoElement(tile, targetElementId) {
    try {
      const targetElement = document.getElementById(targetElementId);

      if (targetElement) {
        await this.meetingSession.audioVideo.bindVideoElement(tile.tileId, targetElement);
      } else {
        this.queue.add(`bindVideoElement-${targetElementId}`, {
          type: 'bindVideoElement',
          tileId: tile.tileId,
          targetElementId,
        });
      }
    } catch (err) {
      this.emit('error', err);
    }
  }

  async unbindVideoElement(tileId) {
    try {
      await this.meetingSession.audioVideo.unbindVideoElement(tileId);
    } catch (err) {
      this.emit('error', err);
    }
  }

  attach(tile, targetElementId) {
    const targetElement = document.getElementById(targetElementId);
    try {
      if (tile.boundVideoStream && targetElement) {
        const stream = tile.boundVideoStream.clone();
        targetElement.srcObject = stream;
        targetElement.play().catch(() => {});
      } else {
        this.queue.add(`attach-${tile.tileId}`, {
          type: 'attach',
          tileId: tile.tileId,
          targetElementId,
        });
      }
    } catch (err) {
      this.emit('error', err);
    }
  }

  detach(targetElementId, dueToPause) {
    const targetElement = document.getElementById(targetElementId);
    try {
      DefaultVideoTile.disconnectVideoStreamFromVideoElement(targetElement, dueToPause);
    } catch (err) {
      this.emit('error', err);
    }
  }

  async startNetworkTest() {
    const results = {};

    // TODO: this network test is not ready yet

    // try {
    //   const meetingReadinessChecker = new DefaultMeetingReadinessChecker(this.logger, this.meetingSession)

    //   const audioFeedback = devices.audioSource ? await meetingReadinessChecker.checkAudioConnectivity(devices.audioSource) : null
    //   const videoFeedback = devices.videoSource ? await meetingReadinessChecker.checkVideoConnectivity(devices.videoSource) : null

    //   results.audio = CheckAudioConnectivityFeedback[audioFeedback]
    //   results.video = CheckVideoConnectivityFeedback[videoFeedback]
    // } catch (err) {
    //   this.emit('error', err)
    // }

    return results;
  }

  async subscribeToVolumeIndicator(presentAttendeeId) {
    this.meetingSession.audioVideo.realtimeSubscribeToVolumeIndicator(presentAttendeeId, (attendeeId, volume, muted, signalStrength, externalUserId) => {
      if (typeof externalUserId !== 'undefined') {
        this.emit('attendeeVolumeChange', { attendeeId, volume, muted, signalStrength, externalUserId });
        this.incomingAudioVolumeSubs.push(attendeeId);
      }
    });
  }

  updateSettings({ devices, type }) {
    if (type === 'audioSource') {
      this.audioSource = devices.audioSource;
    }

    if (type === 'audioOutputSource') {
      this.audioOutputSource = devices.audioOutputSource;
    }

    if (type === 'videoSource') {
      this.videoSource = devices.videoSource;
    }
  }

  async onVideoResolutionUpdated(width, height, frameRate = 15) {
    const maxBandwidthKbps = 600;
    this.meetingSession.audioVideo.chooseVideoInputQuality(width, height, frameRate);
    this.meetingSession.audioVideo.setVideoMaxBandwidthKbps(maxBandwidthKbps);
    if (this.videoSource) {
      try {
        await this.meetingSession.audioVideo.startVideoInput(this.videoSource);
      } catch (err) {
        console.error('onVideoResolutionUpdated|err', err);
        this.emit('error', err);
      }
    }
  }

  emitUpdatedStatus(status, forced = false) {
    this.emit('setGroupsEventStatus', status, forced);
  }

  processQueue() {
    this.queue.keys().forEach((key) => {
      const { type, tileId, targetElementId, sourceId, devices } = this.queue.get(key);
      const tile = tileId && this.tiles[tileId];
      const targetElement = document.getElementById(targetElementId);

      if (type === 'attach' && tile && tile.boundVideoStream && targetElement) {
        this.queue.remove(key);
        this.attach(tile, targetElementId);
      }

      if (type === 'bindVideoElement' && tile && targetElement) {
        this.queue.remove(key);
        this.bindVideoElement(tile, targetElementId);
      }

      if (type === 'attachPresentationVideo' && targetElement) {
        this.queue.remove(key);
        this.startScreen({ targetElementId, sourceId });
      }

      if (type === 'bindPreviewVideo' && targetElement) {
        this.queue.remove(key);
        this.startPreview({ devices, targetElementId });
      }
    });
  }
}

export default AwsChimeVideoManager;
