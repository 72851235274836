import { eventEmitter } from '@/services/event-emitter.service';

export default (vueApp) => {
  vueApp.$connectivity.on('error', (err) => {
    console.error('connectivity|error', err);
  });

  vueApp.$connectivity.on('destroyed', (dispose) => {
    if (dispose) {
      const targetElement = document.getElementById('connectivityVideo');
      vueApp.$videomanager.stopPreview({ targetElement });
      vueApp.$videomanager.stopAudioPreview();
    }
  });

  vueApp.$connectivity.on('start', () => {
    eventEmitter.emit('api:startTestMeter');
  });

  vueApp.$connectivity.on('complete', (results) => {
    eventEmitter.emit('api:displayNetworkTestResults', results);
  });

  vueApp.$connectivity.on('audioPreviewPercent', (e) => {
    eventEmitter.emit('api:setVolumeTestMeter', e.percent / 100);
  });

  vueApp.$connectivity.on('settings:audioSource', (audioSource, audioOn) => {
    const devices = { audioSource, audioOn };
    vueApp.$videomanager.startAudioPreview({ devices });
  });

  vueApp.$connectivity.on('settings:videoSource', async (videoSource, videoOn, targetElementId) => {
    const devices = { videoSource, videoOn };
    const targetElement = document.getElementById(targetElementId || 'connectivityVideo');
    if (videoSource && videoOn) {
      await vueApp.$videomanager.startPreview({ devices, targetElementId: targetElementId || 'connectivityVideo' });
    } else {
      vueApp.$videomanager.stopPreview({ targetElement });
    }
  });
};
