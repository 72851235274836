<template>
  <transition name="basic">
    <h2 v-if="showTitle" ref="title" id="topTitle" class="topTitle">
      <app-icon icon="wordmark" />
      <span class="visuallyHidden">bixe</span>
      <span class="spaceName">
        {{ titleText }}
      </span>
      <span class="data"></span>
    </h2>
  </transition>
</template>

<script>
import _ from 'underscore';
import AppIcon from '@/components/AppIcon.vue';
import spaces from '@/resources/spaces';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';

export default {
  components: {
    AppIcon,
  },
  setup() {
    const phaseStore = usePhaseStore();
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    const pageStore = usePageStore();
    return { phaseStore, meetingStore, usersStore, pageStore };
  },
  data() {
    return {
      showTitle: false,
      check: _.debounce(this.checkRaw, 300),
    };
  },
  computed: {
    titleText() {
      return spaces[this.meetingStore.space]?.topTitle || '';
    },
    showTitleRaw() {
      return (
        this.pageStore.isMeeting &&
        this.meetingStore.initTitleCardShown &&
        !['review', 'input'].includes(this.phaseStore.currentPhase) &&
        !this.phaseStore.mirroring.requestedBy
      );
    },
  },
  watch: {
    showTitleRaw: {
      immediate: true,
      handler(nv) {
        this.check(nv);
      },
    },
  },
  methods: {
    checkRaw(nv) {
      this.showTitle = nv;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordmark-icon,
.spaceName,
.data {
  z-index: 90;
  position: absolute;
  .light-theme.beach-space & {
    color: var(--c__bg);
  }
  @media (prefers-color-scheme: light) {
    .dynamic-theme.beach-space & {
      color: var(--c__bg);
    }
  }
}

.wordmark-icon {
  top: rem(33px);
  left: rem(36px);
  width: rem(43px);
  height: rem(20px);
}

.spaceName {
  @include type-size(medium);
  line-height: (22em / 18);
  letter-spacing: rem(2.57px);
  text-transform: uppercase;
  top: rem(35px);
  left: rem(90px);
}
</style>
