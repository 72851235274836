import utils from '../resources/utils';
import $API from '../services/bixe-api.service';

export default () => (to, from, next) => {
  const { user, config } = window.__INITIAL_STATE__;
  const isAuthenticated = user && user.id;
  const meetingId = to.params.id || to.query.meetingId || '';
  const password = to.query.pwd || '';
  const target = to.meta?.target || 'guest';
  if (utils.isOpenedWithinElectronShell() && to.query['requested-otp']) {
    $API
      .resyncSessionDetails({ 'requested-otp': to.query['requested-otp'] })
      .then(() => {
        let returnUrl = `${config.baseUrl}${to.fullPath}`;
        if (returnUrl.includes('requested-otp')) {
          returnUrl = returnUrl.replace('requested-otp', 'previous-otp');
        }
        window.location = returnUrl;
      })
      .catch((err) => {
        console.log('resync details: ', err);
      });
    return;
  }

  if (isAuthenticated && target === 'meeting') {
    return next();
  }

  if (isAuthenticated && target === 'guest') {
    return next(`/meeting/${encodeURI(meetingId)}?pwd=${password}`);
  }

  if (target === 'meeting') {
    return next(`/joining-options?id=${encodeURI(meetingId)}&pwd=${encodeURI(password)}&flow=meeting-invite`);
  }

  return next();
};
