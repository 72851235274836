<template>
  <transition name="basic">
    <div v-show="!isPresentation && currentPhase === 'decision'" id="decisionSpace" class="decisionSpace space decision">
      <div v-if="debug" class="debugGrid poster" key="debug">
        <div
          v-for="(slot, i) in voteSlots.yes"
          :style="{ top: slot.top + 'px', left: slot.left + 'px', width: voteSlots.size + 'px', height: voteSlots.size + 'px' }"
          class="slot top"
          :key="'yes-' + i"
        >
          &nbsp;
        </div>
        <div
          v-for="(slot, i) in voteSlots.no"
          :style="{ top: slot.top + 'px', left: slot.left + 'px', width: voteSlots.size + 'px', height: voteSlots.size + 'px' }"
          class="slot bottom"
          :key="'no-' + i"
        >
          &nbsp;
        </div>
      </div>

      <transition name="basic">
        <p v-if="stage === 'voting'" class="votingExplainer">After everyone has made a choice, the results will be revealed</p>
      </transition>

      <div class="decisionFrame" :class="[stage, voted ? 'iVoted' : '']" :style="{ top: frameTop }">
        <div class="decisionBox">
          <div class="decisionTimerBar" />
          <topic-box :model-field="proposal" v-if="stage === 'proposal'" placeholder="Begin with a proposal..." @send="send" @finish="finishProposal">
            {{ speaking?.first_name }} is making a proposal
          </topic-box>
          <transition name="basic">
            <div v-if="stage !== 'proposal'" class="voteButtons" :class="voted">
              <button class="yes" @click="voteYes" :class="pleaseVote ? 'pleaseVote' : ''" ref="yesButton">
                <transition name="scale">
                  <span v-if="stage === 'voted'" class="count">{{ yesCount }}</span>
                </transition>
                <span class="text visuallyHidden">Yes</span>
                <app-icon icon="yes-vote" />
              </button>

              <transition name="scale">
                <button class="unsure" v-if="stage === 'voted'" @click="voteUnsure" :class="pleaseVote ? 'pleaseVote' : ''" ref="unsureButton">
                  <span class="count">{{ unsureCount }}</span>
                  <span class="text visuallyHidden">Unsure</span>
                  <app-icon icon="unsure-vote" />
                </button>
              </transition>
              <button class="no" @click="voteNo" :class="pleaseVote ? 'pleaseVote' : ''" ref="noButton">
                <transition name="scale">
                  <span v-if="stage === 'voted'" class="count">{{ noCount }}</span>
                </transition>
                <span class="text visuallyHidden">No</span>
                <app-icon icon="no-vote" />
              </button>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';
import { useFeaturesStore } from '@/store/pinia/features';
import AppIcon from '@/components/AppIcon';
import TopicBox from '@/components/TopicBox';
import utils from '../resources/utils';
import { ref, computed, watch, getCurrentInstance } from 'vue';

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const phaseStore = usePhaseStore();
const usersStore = useUsersStore();
const featuresStore = useFeaturesStore();
const frameStore = useFrameStore();

const { proposal: rawProposal, phaseStage: stage, proposalId, collectedVotes, isPresentation, currentPhase } = storeToRefs(phaseStore);
const { me, speaking } = storeToRefs(usersStore);
const { debug } = storeToRefs(featuresStore);
const { contHeight, voteSlots } = storeToRefs(frameStore);

const yesButton = ref(null);
const unsureButton = ref(null);
const noButton = ref(null);

const proposal = computed(() => {
  return utils.desanitizeString(rawProposal.value);
});
const voted = computed(() => {
  return me?.value.vote;
});
const pleaseVote = computed(() => {
  return !voted.value && stage.value === 'voting';
});
const frameTop = computed(() => {
  if (stage.value === 'voted') {
    return contHeight.value / 2 - 58 + 'px';
  } else {
    return contHeight.value / 3 - 58 + 'px';
  }
});
const yesCount = computed(() => {
  return collectedVotes.value.yes?.length;
});
const unsureCount = computed(() => {
  return collectedVotes.value.unsure?.length;
});
const noCount = computed(() => {
  return collectedVotes.value.no?.length;
});

watch(yesCount, (nv, ov) => {
  if (nv > ov) {
    Velocity(yesButton.value, 'callout.pulse', { duation: 600, delay: 0 });
  }
});
watch(unsureCount, (nv, ov) => {
  if (nv > ov) {
    Velocity(unsureButton.value, 'callout.pulse', { duation: 600, delay: 0 });
  }
});
watch(noCount, (nv, ov) => {
  if (nv > ov) {
    Velocity(noButton.value, 'callout.pulse', { duation: 600, delay: 0 });
  }
});

function voteYes() {
  if (voted.value === 'yes') {
    phaseStore.quickVote('unsure');
    $meetingmanager.vote('unsure');
  } else {
    phaseStore.quickVote('yes');
    $meetingmanager.vote('yes');
  }
  yesButton.value.blur();
}
function voteUnsure() {
  if (voted.value !== 'unsure') {
    phaseStore.quickVote('unsure');
    $meetingmanager.vote('unsure');
  }
  unsureButton.value.blur();
}
function voteNo() {
  if (voted.value === 'no') {
    phaseStore.quickVote('unsure');
    $meetingmanager.vote('unsure');
  } else {
    phaseStore.quickVote('no');
    $meetingmanager.vote('no');
  }
  noButton.value.blur();
}
function send(v) {
  phaseStore.quickVote(false);
  $meetingmanager.updateProposal({ id: proposalId.value || null, proposal: v });
}
function finishProposal(v) {
  $meetingmanager.updateProposal({ proposal: v, finished: true });
}
</script>

<style lang="scss" scoped>
@at-root {
  @keyframes voteButtonPulse {
    0%,
    30%,
    70%,
    100% {
      color: var(--button);
      background: var(--c__bg);
      border-color: var(--button);
    }
    40%,
    60% {
      color: var(--c__bg);
      background: var(--button);
      border-color: var(--button);
    }
  }
}
.decisionFrame {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: rem(108px);
  padding: 0 rem(50px);
  align-items: center;
  justify-content: center;
  transition: top 0.3s ease;
}

.decisionBox {
  transition: all 300ms cubic-bezier(0.45, 0, 0.55, 1) 300ms;
  width: 100%;
  min-height: rem(8px);
  border: 1px solid var(--c__edges-box);
  max-width: 100%;
  background: var(--c__bg);
  position: relative;
}
.proposal .decisionBox {
  width: rem(482px);
  min-height: rem(54px);
  margin: 0 auto;
  border: 2px solid var(--c__edges-box);
}

.voting .voteButtons {
  height: rem(140px);
  margin-top: rem(math.div(-140px, 2) + 4px);
}

.voteButtons {
  left: rem(-9px);
  height: rem(230px);
  margin-top: rem(math.div(-230px, 2) + 4px);
  width: rem(50px);
  position: absolute;
  transition: all 300ms cubic-bezier(0.45, 0, 0.55, 1);

  button.yes {
    --button: var(--c__green);
    top: 0;
    :deep(svg) {
      margin-top: rem(2px);
      margin-left: rem(1px);
    }
  }
  button.unsure {
    --button: var(--c__quiet);
    top: 50%;
    margin-top: rem(-26px);
    :deep(svg) {
      width: rem(26px);
      height: rem(26px);
      margin-top: rem(3px);
      margin-right: rem(4px);
    }
  }
  button.no {
    --button: var(--c__red);
    bottom: 0;
    :deep(svg) {
      margin-top: rem(7px);
    }
  }

  button {
    color: var(--button);
    background: var(--c__bg);
    border: currentColor rem(2px) solid;
    height: em(50px);
    width: em(50px);
    border-radius: 50%;
    position: absolute;
    z-index: 90;
  }

  :deep(svg) {
    width: rem(26px);
    height: rem(26px);
  }

  .count {
    font-weight: bold;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 1.25em;
    width: 1.25em;
    top: -0.5em;
    right: 0em;
    color: var(--c__bg);
    background-color: var(--button);
  }

  &.yes button.yes,
  &.no button.no,
  &.unsure button.unsure {
    color: var(--c__bg);
    background: var(--button);
    border-color: var(--button);
  }

  button.no,
  button.yes,
  button.unsure {
    @include triggered {
      color: var(--c__bg);
      background: var(--button);
      border-color: var(--button);
    }
  }
}

button.yes.pleaseVote,
button.no.pleaseVote {
  animation: voteButtonPulse 3s ease infinite alternate;
  @include triggered {
    animation: none;
  }
}
.votingExplainer {
  position: absolute;
  @include type-size('medium');
  height: 33vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16em;
  text-align: center;
  left: 50%;
  margin-left: -8em;
}
.voting.iAmVoted {
  button.yes {
    animation: none;
  }
  button.no {
    animation: none;
  }
}

.debugGrid {
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
  color: white;
  pointer-events: none;
  .slot {
    position: absolute;
    box-sizing: border-box;
    border: 1px solid;
    border-radius: 50%;
    transition: all 0.5s ease;
  }
  .top {
    color: #aaffaa;
  }
  .bottom {
    color: #ffaaaa;
  }
}
</style>
