const counterBlips = [];
const counterOutBlips = [];

const pairsClink = document.getElementById('pairsBounce');
pairsClink.volume = 0.1;

const pairsClink2 = document.getElementById('pairsBounce2');
pairsClink2.volume = 0.05;

const ding = document.getElementById('ding');
ding.volume = 0.3;

const knock = document.getElementById('knock');

const fx = {
  blipIn: playCounterBlip,
  blipOut: playCounterBlipOut,
  pairsClink: playPairsClink,
  ding: playDing,
  knock: playKnock,
};

function init() {
  for (var i = 0; i < 12; i++) {
    counterBlips[i] = document.getElementById('counterNoise' + (i + 1));
    counterBlips[i].volume = 0.1;
    counterOutBlips[11 - i] = document.getElementById('counterNoiseOut' + (i + 1));
    counterOutBlips[11 - i].volume = 0.05;
  }
}

function playCounterBlip(counter, total) {
  if (!total) {
    total = 1;
  }
  if (counterBlips[counter]) {
    counterBlips[counter].play().catch(() => {});
  }
}

function playCounterBlipOut(counter) {
  if (counterOutBlips[counter]) {
    counterOutBlips[counter].play().catch(() => {});
  }
}

function playPairsClink(clink) {
  if (clink) {
    pairsClink2.play().catch(() => {});
  } else {
    pairsClink.play().catch(() => {});
  }
}

function playDing() {
  ding.play().catch(() => {});
}

function playKnock() {
  knock.play().catch(() => {});
}

function play(sound, ...args) {
  if (!fx[sound]) {
    return false;
  }
  fx[sound](...args);
}

export default { init, play };
