<template>
  <li @click="handleDisabled">
    <button
      ref="icon"
      :class="[{ current: isCurrent }, phase]"
      :data-phase="phase"
      :title="title"
      :disabled="isDisabled"
      @click="handleClick"
      @keyup.space="(e) => e.preventDefault()"
    >
      <app-icon :icon="phase" class="phaseIcon" />
      <span class="text"><slot /></span>
    </button>
    <slot name="tips" />
  </li>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import AppIcon from '@/components/AppIcon.vue';

export default {
  components: {
    AppIcon,
  },
  props: ['phase', 'title'],
  setup() {
    const phaseStore = usePhaseStore();
    return { phaseStore };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['usersHere']),
    isCurrent() {
      return this.phaseStore.currentPhase === this.phase;
    },
    isDisabled() {
      if (this.notEnough) {
        return true;
      } else if (this.phaseStore.rawCurrentPhase === 'freeform' && !this.phaseStore.settingUp) {
        return false;
      } else {
        return this.phaseStore.currentPhase !== this.phase;
      }
    },
    notEnough() {
      return this.phase === 'groups' && this.usersHere.length < 4;
    },
  },
  methods: {
    handleClick() {
      if (this.notEnough) {
        this.$emit('nope', 'notEnough');
      } else {
        this.$emit('navigate', this.phase);
      }
    },
    handleDisabled() {
      if (this.notEnough) {
        Velocity(this.$refs.icon, 'callout.swing', { duation: 300 });
        if (this.notEnough) {
          this.$emit('nope', 'notEnough');
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  display: flex;
  width: rem(83px);
  height: rem(83px);
  margin: 0 1em rem(22px + 18px) 1em;
  position: relative;
}

button {
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: none;
  padding: 0;
  @include triggered {
    color: var(--c__text);
    background: transparent;
  }
}

:deep(.phaseIcon) {
  margin-bottom: rem(7px);
  position: relative;
  display: block;
}

.text {
  position: relative;
  margin: 0 -1em;
}

button:disabled .phaseIcon {
  color: var(--c__disabled);
  background: var(--c__bg);
}

button:disabled {
  color: var(--c__disabled);
  pointer-events: none;
}
</style>
