<template>
  <avatar-controls
    v-if="usersStore.firstShown && !hideAvatars && onMyPage && !hasSpinner"
    :pos="pos"
    :user="user"
    :homeAngle="homeAngle"
    :notHere="notHere"
    @showControls="showControls = true"
    @hideControls="showControls = false"
  />
  <transition name="basic">
    <avatar-emitters :pos="pos" :user="user" :showControls="showControls" v-if="!notHere && !hideAvatars && onMyPage && !hasSpinner" />
  </transition>
  <transition name="basic">
    <volume-indicator
      class="avatarVolume"
      :user="user"
      v-if="showVolume && !hideAvatars && !hasSpinner"
      :volume="userVolume"
      ref="volume"
      :style="{ width: w + 'px', height: w + 'px', top: top + 'px', left: left + 'px' }"
    />
  </transition>
  <avatar-loader :pos="pos" :user="user" v-if="!notHere && hasSpinner && !hideAvatars" />
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import AvatarControls from '@/components/AvatarControls.vue';
import AvatarEmitters from '@/components/AvatarEmitters.vue';
import AvatarLoader from '@/components/AvatarLoader.vue';
import VolumeIndicator from '@/components/VolumeIndicator.vue';
import * as geometry from '@/resources/geometry';

import { CONTROL_CLEARANCE } from '@/resources/constants/frame-constants';
import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';

export default {
  setup() {
    const usersStore = useUsersStore();
    const frameStore = useFrameStore();
    return { usersStore, frameStore };
  },
  components: {
    AvatarControls,
    AvatarEmitters,
    AvatarLoader,
    VolumeIndicator,
  },
  props: ['user', 'index', 'count'],
  data() {
    return {
      left: 0,
      top: 0,
      w: 0,
      scale: 1,
      opacity: 1,
      unit: 'px',
      debounceUpdatePosition: _.debounce(this.updatePositionData, 500),
      firstShown: false,
      showControls: false,
    };
  },
  mounted() {
    this.updatePositionData();
    let _this = this;
    this.$ee.on('nM:animatedAvatarIn:' + _this.id, _this.animatedIn);
  },
  beforeUnmount() {
    let _this = this;
    this.$ee.off('nM:animatedAvatarIn:' + _this.id, _this.animatedIn);
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      rawPositions: 'positions',
      hideAvatars: 'hide',
    }),
    ...mapPiniaState(useUsersStore, ['me', 'inMyGroup', 'myGroup', 'userOnMyPage', 'volumes']),
    ...mapPiniaState(useUsersStore, {
      hasSpinner(s) {
        return s.userHasSpinner(this.user.id);
      },
      moving(s) {
        return s.isMoving(this.user.id);
      },
      aboutToMove(s) {
        return s.aboutToMove.has(this.user.id);
      },
    }),
    id() {
      return this.user.id;
    },
    userVolume() {
      return this.volumes[this.id];
    },
    onMyPage() {
      return this.userOnMyPage(this.id);
    },
    rawPosition() {
      return this.rawPositions[this.id];
    },
    pos() {
      return {
        w: this.w,
        wOuter: this.w,
        width: this.w,
        height: this.w,
        top: this.top,
        left: this.left,
      };
    },
    connected() {
      return this.user.connected;
    },
    notHere() {
      return (this.user.away || this.isNotWithMe) && !this.user.me;
    },
    isNotWithMe() {
      if (!this.user || !this.me) {
        return false;
      }
      return (
        this.usersStore.isAwayInGroup(this.user.id) && ((!this.inMyGroup(this.user.id) && this.usersStore.iAmAwayInGroup) || !this.usersStore.iAmAwayInGroup)
      );
    },
    showVolume() {
      if (!this.onMyPage) {
        return false;
      }

      if (!this.user.connected) {
        return false;
      }
      if (this.notHere) {
        return false;
      }

      if (!this.usersStore.firstShown) {
        return false;
      }

      if (this.hasSpinner) {
        return false;
      }

      if (!this.firstShown) {
        return false;
      }

      if (this.moving || this.aboutToMove) {
        return false;
      }
      return true;
    },
    actualTopEdge() {
      return this.top - this.w / 2;
    },
    homeAngle() {
      if (this.usersStore.positionDetails.vertical) {
        return geometry.QUARTER_TURN * 2;
      } else {
        return this.actualTopEdge < CONTROL_CLEARANCE ? geometry.QUARTER_TURN : 0 - geometry.QUARTER_TURN;
      }
    },
  },
  watch: {
    rawPosition: {
      deep: true,
      handler(p, ov) {
        if (!p) {
          return false;
        }

        let init = !ov;
        if (!ov || p.top !== ov.top || p.left !== ov.left || p.w !== ov.w || p.opacity !== ov.opacity || p.scale !== ov.scale) {
          this.debounceUpdatePosition(p, init);
        }
      },
    },
    showControls(nv) {
      this.$ee.emit(`nM:avatar:hasControls:${this.id}`, nv);
    },
  },
  methods: {
    updatePositionData() {
      if (this.rawPosition) {
        this.left = this.rawPosition.left;
        this.top = this.rawPosition.top;
        this.w = this.rawPosition.w;
        if (this.rawPosition.scale !== undefined) {
          this.scale = this.rawPosition.scale;
        } else {
          this.scale = 1;
        }
        if (this.rawPosition.opacity !== undefined) {
          this.opacity = this.rawPosition.opacity;
        } else {
          this.opacity = 1;
        }
      }
    },
    animatedIn() {
      this.firstShown = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarExtras {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0) translateY(0);
  //backface-visibility: hidden;
  transition: top 0.6s cubic-bezier(0.45, 0, 0.15, 1), left 0.6s cubic-bezier(0.45, 0, 0.15, 1), transform 0.3s cubic-bezier(0.45, 0, 0.15, 1),
    opacity 0.3s ease;
}

.avatarVolume {
  position: absolute;
  border-radius: 50% !important;
  transform: translateX(-50%) translateY(-50%);
  z-index: $z__avatar-base-volume;
  transition: transform 0.3s cubic-bezier(0.45, 0, 0.15, 1), opacity 0.3s ease;
}
</style>
