const controls = {
  'muteVideo-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="hover-fill hover-stroke startWhite" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z" stroke-width="2"/>
      <rect class="stroke hover-revStroke" x="7.5" y="13.5" width="16" height="14" />
      <path class="stroke hover-revStroke" d="M26.73,20.5l6.77,6.35V14.15Z" />
      <line  class="stroke hover-revStroke" x1="31.5" y1="9.5" x2="9.3" y2="31.7" stroke-linecap="square" stroke-width="0.5"/>
    </svg>
  `,
  'settings-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="hover-fill hover-stroke startWhite" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z"stroke-width="2"/>
      <path class="stroke hover-revStroke" d="M19.92,15.39a4.61,4.61,0,1,1-3.24,1.41A4.58,4.58,0,0,1,19.92,15.39Zm-8.86,2c-.42.18-.94.35-1.48.54l-1.29.45a.35.35,0,0,0-.23.29,14.08,14.08,0,0,0,.06,3.13.35.35,0,0,0,.24.28l1.29.4c.56.17,1.09.33,1.51.49a9.32,9.32,0,0,0,.65,1.5c-.16.38-.39.83-.63,1.29s-.44.86-.66,1.33a.35.35,0,0,0,0,.29l0,.07a14.76,14.76,0,0,0,2.33,2.24.35.35,0,0,0,.37,0c.47-.23.89-.48,1.31-.71s.89-.5,1.27-.67a9.19,9.19,0,0,0,1.52.6c.18.42.36.94.55,1.49s.29.84.45,1.28a.35.35,0,0,0,.29.23,14.07,14.07,0,0,0,3.13-.06.35.35,0,0,0,.28-.24c.15-.45.28-.88.41-1.3s.33-1.08.49-1.5a9.07,9.07,0,0,0,1.5-.65c.38.16.82.38,1.28.62s.87.45,1.35.67a.35.35,0,0,0,.37,0A14.87,14.87,0,0,0,29.7,27.1a.35.35,0,0,0,0-.37c-.23-.47-.48-.9-.71-1.31s-.5-.89-.67-1.27a9.13,9.13,0,0,0,.6-1.51c.42-.18.95-.36,1.5-.55l1.26-.45a.35.35,0,0,0,.23-.29,14.08,14.08,0,0,0-.06-3.13.35.35,0,0,0-.24-.28l-1.3-.41c-.55-.17-1.08-.33-1.5-.49a9.18,9.18,0,0,0-.65-1.49c.16-.38.39-.83.63-1.29s.45-.86.66-1.34a.35.35,0,0,0,0-.37,14.8,14.8,0,0,0-2.33-2.25.35.35,0,0,0-.37,0c-.47.23-.9.48-1.31.71s-.89.5-1.27.67a9.19,9.19,0,0,0-1.52-.6c-.18-.42-.35-.94-.54-1.48s-.29-.84-.45-1.29a.35.35,0,0,0-.29-.23,14.08,14.08,0,0,0-3.13.06.35.35,0,0,0-.28.24c-.15.44-.28.88-.4,1.29s-.33,1.09-.49,1.51a9.15,9.15,0,0,0-1.49.65c-.38-.16-.83-.39-1.29-.63s-.86-.44-1.34-.66a.35.35,0,0,0-.37,0,14.8,14.8,0,0,0-2.25,2.33.35.35,0,0,0,0,.37c.24.47.48.91.72,1.33s.5.88.67,1.26A9.08,9.08,0,0,0,11.06,17.36Z" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  'settings-control-present': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke" d="M19.92,15.39a4.61,4.61,0,1,1-3.24,1.41A4.58,4.58,0,0,1,19.92,15.39Zm-8.86,2c-.42.18-.94.35-1.48.54l-1.29.45a.35.35,0,0,0-.23.29,14.08,14.08,0,0,0,.06,3.13.35.35,0,0,0,.24.28l1.29.4c.56.17,1.09.33,1.51.49a9.32,9.32,0,0,0,.65,1.5c-.16.38-.39.83-.63,1.29s-.44.86-.66,1.33a.35.35,0,0,0,0,.29l0,.07a14.76,14.76,0,0,0,2.33,2.24.35.35,0,0,0,.37,0c.47-.23.89-.48,1.31-.71s.89-.5,1.27-.67a9.19,9.19,0,0,0,1.52.6c.18.42.36.94.55,1.49s.29.84.45,1.28a.35.35,0,0,0,.29.23,14.07,14.07,0,0,0,3.13-.06.35.35,0,0,0,.28-.24c.15-.45.28-.88.41-1.3s.33-1.08.49-1.5a9.07,9.07,0,0,0,1.5-.65c.38.16.82.38,1.28.62s.87.45,1.35.67a.35.35,0,0,0,.37,0A14.87,14.87,0,0,0,29.7,27.1a.35.35,0,0,0,0-.37c-.23-.47-.48-.9-.71-1.31s-.5-.89-.67-1.27a9.13,9.13,0,0,0,.6-1.51c.42-.18.95-.36,1.5-.55l1.26-.45a.35.35,0,0,0,.23-.29,14.08,14.08,0,0,0-.06-3.13.35.35,0,0,0-.24-.28l-1.3-.41c-.55-.17-1.08-.33-1.5-.49a9.18,9.18,0,0,0-.65-1.49c.16-.38.39-.83.63-1.29s.45-.86.66-1.34a.35.35,0,0,0,0-.37,14.8,14.8,0,0,0-2.33-2.25.35.35,0,0,0-.37,0c-.47.23-.9.48-1.31.71s-.89.5-1.27.67a9.19,9.19,0,0,0-1.52-.6c-.18-.42-.35-.94-.54-1.48s-.29-.84-.45-1.29a.35.35,0,0,0-.29-.23,14.08,14.08,0,0,0-3.13.06.35.35,0,0,0-.28.24c-.15.44-.28.88-.4,1.29s-.33,1.09-.49,1.51a9.15,9.15,0,0,0-1.49.65c-.38-.16-.83-.39-1.29-.63s-.86-.44-1.34-.66a.35.35,0,0,0-.37,0,14.8,14.8,0,0,0-2.25,2.33.35.35,0,0,0,0,.37c.24.47.48.91.72,1.33s.5.88.67,1.26A9.08,9.08,0,0,0,11.06,17.36Z" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  `,
  'eek-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M17.12,31a3.82,3.82,0,1,1-3.82-3.83A3.82,3.82,0,0,1,17.12,31Zm-.6-7.44H10L9,5h8.4Z" stroke-width="1.5"/>
      <path class="stroke hover-fill" d="M30.32,31a3.82,3.82,0,1,1-3.82-3.83A3.82,3.82,0,0,1,30.32,31Zm-.6-7.44H23.2L22.2,5h8.4Z" stroke-width="1.5"/>
    </svg>
  `,
  'grr-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="hover-fill stroke" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z" stroke-width="2"/>
      <path class="fill hover-revFill" d="M28.55,22.28H11.45a2.86,2.86,0,0,0-2.85,2.85v3.42a2.86,2.86,0,0,0,2.85,2.85H28.55a2.86,2.86,0,0,0,2.85-2.85V25.13A2.86,2.86,0,0,0,28.55,22.28ZM15.44,29.12h-4a.58.58,0,0,1-.57-.57V25.13a.58.58,0,0,1,.57-.57h4Zm6.84,0H17.72V24.56h4.56Zm6.84-.57a.58.58,0,0,1-.57.57h-4V24.56h4a.58.58,0,0,1,.57.57Z" />
      <path class="fill hover-revFill" d="M24.56,17.72a2.28,2.28,0,0,0,1.67-3.83,10.48,10.48,0,0,1,2-.76,1.14,1.14,0,0,0-.55-2.21,14,14,0,0,0-2.51.94c-1.3.65-2.84,1.77-2.91,3.46h0s0,.08,0,.12A2.28,2.28,0,0,0,24.56,17.72Z" />
      <path class="fill hover-revFill" d="M11.74,13.12a10.58,10.58,0,0,1,2,.76,2.28,2.28,0,1,0,4,1.55s0-.08,0-.12c-.07-1.69-1.61-2.82-2.91-3.46a13.69,13.69,0,0,0-2.51-.94,1.14,1.14,0,0,0-1.38.83h0A1.14,1.14,0,0,0,11.74,13.12Z" />
    </svg>
  `,
  'smile-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z" stroke-width="2"/>
      <circle class="fill hover-revFill" cx="26.75" cy="13.25" r="2.25" />
      <path class="fill hover-revFill" d="M20,27.87a7.87,7.87,0,0,1-6.75-3.82l-2.89,1.74a11.25,11.25,0,0,0,19.3,0l-2.89-1.74A7.87,7.87,0,0,1,20,27.87Z" />
      <circle class="fill hover-revFill" cx="13.25" cy="13.25" r="2.25" />
    </svg>
  `,
  'heart-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M20.5,35l-2.25-2.08C10.27,25.53,5,20.66,5,14.69A8.52,8.52,0,0,1,13.52,6a9.23,9.23,0,0,1,7,3.3,9.23,9.23,0,0,1,7-3.3A8.52,8.52,0,0,1,36,14.69c0,6-5.27,10.84-13.25,18.23Z" stroke-width="1.5"/>
    </svg>
  `,
  'mute-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="hover-fill hover-stroke startWhite" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z" stroke-width="2"/>
      <g>
        <rect class="fill hover-revFill" x="19.13" y="28.19" width="1.93" height="3.85" />
        <rect class="fill hover-revFill" x="14.31" y="32.04" width="11.56" height="1" />
        <path class="stroke hover-revStroke" d="M20.09,7.5h0a5.28,5.28,0,0,1,5.28,5.28v7.7a5.28,5.28,0,0,1-5.28,5.28h0a5.28,5.28,0,0,1-5.28-5.28v-7.7A5.28,5.28,0,0,1,20.09,7.5Z" />
        <rect class="fill hover-revFill" x="18.17" y="7.96" width="1" height="6.74" />
        <rect class="fill hover-revFill" x="21.06" y="7.96" width="1" height="6.74" />
        <path class="stroke hover-revStroke" d="M11.43,20.48a8.67,8.67,0,1,0,17.33,0" stroke-width="2"/>
      </g>
      <line class="stroke hover-revStroke" x1="30.67" y1="9.41" x2="9.29" y2="30.79" stroke-linecap="square"/>
    </svg>
  `,
  'no-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M33.19,9.56c-1.55-2.77-5.28-2.79-8-3.32-1.18-.23-7.81-1-10-1.13L14.06,5l-.65,0a2.31,2.31,0,0,0-2.08,2.48,3,3,0,0,0,.11.79A2.41,2.41,0,0,0,12.51,9.7l-1.43,0-.71,0a2.49,2.49,0,0,0-2.26,2.67A2.66,2.66,0,0,0,9.7,14.85H9.53A2.63,2.63,0,0,0,7,17.57a2.64,2.64,0,0,0,2.53,2.72h0a2.6,2.6,0,0,0-1.77,2.54,2.52,2.52,0,0,0,2.37,2.63l3.77-.2h0c1.71-.08,5.19-.12,6.2-.13a11.23,11.23,0,0,0-1,2.18,10.36,10.36,0,0,0-.2,6.38,3.25,3.25,0,0,0,1.66,2,4.16,4.16,0,0,0,1.25.32,2.9,2.9,0,0,0,1.35-.08s-.1-.73-.11-1.16A9.65,9.65,0,0,1,25,28.66c2-2.5,6.09-3.05,7.69-5.87A8.58,8.58,0,0,0,34,18.65c0-1.62,0-1.5,0-3.12a30.85,30.85,0,0,0,0-3.5A6.56,6.56,0,0,0,33.19,9.56Z" stroke-width="1.5"/>
    </svg>
  `,
  'what-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M23.33,32.13a4,4,0,1,1-4-3.88A4,4,0,0,1,23.33,32.13ZM10,6.73A26.33,26.33,0,0,1,20.87,4C26.36,4,30,7.12,30,11.84c0,4.25-2.69,7.54-8,9.92v2.9H15.45l-.7-7.11c4.67-1.3,7.15-3,7.15-5,0-1.21-1.12-2-2.85-2a17.08,17.08,0,0,0-6.57,1.65Z" stroke-miterlimit="10" stroke-width="1.5"/>
    </svg>
  `,
  'sad-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke stroke-broad hover-fill" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z"/>
      <circle class="fill hover-revFill" cx="26.75" cy="13.25" r="2.25" />
      <circle class="fill hover-revFill" cx="13.25" cy="13.25" r="2.25" />
      <path class="fill hover-revFill"  d="M20,22.25a11.24,11.24,0,0,0-9.65,5.46l2.89,1.74a7.88,7.88,0,0,1,13.51,0l2.89-1.74A11.24,11.24,0,0,0,20,22.25Z">
    </svg>
  `,
  'uhh-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke stroke-broad hover-fill" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z"/>
      <path class="stroke stroke-x-broad hover-revStroke" d="M28.07,16.53a2.29,2.29,0,1,1-2.29-2.29A2.28,2.28,0,0,1,28.07,16.53Z"/>
      <path class="stroke stroke-x-broad hover-revStroke" d="M16.51,16.53a2.29,2.29,0,1,1-2.29-2.29A2.29,2.29,0,0,1,16.51,16.53Z"/>
      <line class="stroke stroke-x-broad hover-revStroke" x1="15.38" y1="28.09" x2="24.63" y2="28.09"/>
    </svg>
  `,
  'yes-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <path class="stroke hover-fill" d="M6.81,30.44c1.55,2.77,5.28,2.79,8,3.32,1.18.23,7.81,1,10,1.13l1.15.07.65,0a2.31,2.31,0,0,0,2.08-2.48,3,3,0,0,0-.11-.79c-.18-.63-1.47-1.47-2-1.76l2.32.36.71,0a2.49,2.49,0,0,0,2.26-2.67c0-1.19-.83-2.15-3.32-2.54h1.91a2.73,2.73,0,0,0,0-5.45H28.91c2.41-.69,3.31-1.32,3.31-2.54a2.52,2.52,0,0,0-2.37-2.63l-3.77.2h0c-1.71.08-5.19.12-6.2.13a11.23,11.23,0,0,0,1-2.18,10.36,10.36,0,0,0,.2-6.38,3.25,3.25,0,0,0-1.66-2A4.16,4.16,0,0,0,18.16,4a3.51,3.51,0,0,0-1.35.08,5.38,5.38,0,0,1,.11,1.16A9.65,9.65,0,0,1,15,11.34c-2,2.5-6.09,3.05-7.69,5.87A8.58,8.58,0,0,0,6,21.35c0,1.62,0,1.5,0,3.12a30.85,30.85,0,0,0,0,3.5A6.56,6.56,0,0,0,6.81,30.44Z"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
  `,
  'star-control': `
  <svg version="1.1" viewBox="0 0 40 40">
    <polygon class="stroke stroke-mid hover-fill" stroke-linejoin="round" points="20 29 9.42 34.56 11.44 22.78 2.88 14.44 14.71 12.72 20 2 25.29 12.72 37.12 14.44 28.56 22.78 30.58 34.56 20 29"/>
  </svg>
  `,
  'wave-control': `
  <svg version="1.1" viewBox="0 0 22 22">
  <g transform="translate(2,1)">
    <path class="stroke hover-fill" d="M15.33,7.7c-.21-1.77-1-5-1-5S14,1.92,12.78,2.2c-1,.23-.84,1.4-.84,1.4l.36,2.3a2.85,2.85,0,0,1-.84-.37L11,5.12,9.82,4l-.15-.15L6,.34H6A1.24,1.24,0,0,0,5.14,0,1.18,1.18,0,0,0,3.93,1.15a1.12,1.12,0,0,0,.33.79h0L8.8,6.27a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0l-5.29-5h0a1.31,1.31,0,0,0-.91-.36A1.26,1.26,0,0,0,1.1,2.31a1.19,1.19,0,0,0,.37.86h0L7,8.4a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L2,4.13a1.17,1.17,0,0,0-.83-.34A1.13,1.13,0,0,0,.06,4.89a1.07,1.07,0,0,0,.34.78h0l.74.7,4.61,4.39a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L1.74,7.36h0A1,1,0,0,0,1,7.07,1,1,0,0,0,0,8a.92.92,0,0,0,.22.57h0l4.42,4.23.76.72,1.81,1.72c.87.83,4.6,1.37,6.88-.84a5.54,5.54,0,0,0,1.82-2.75C16.25,10.14,15.54,9.47,15.33,7.7Z" stroke-width="0.75">
    </g>
  </svg>
  `,
  'toggleHand-control': `
  <svg version="1.1" viewBox="0 0 22 22">
  <g transform="rotate(45) translate(8,-8)">
    <path class="stroke hover-fill" d="M15.33,7.7c-.21-1.77-1-5-1-5S14,1.92,12.78,2.2c-1,.23-.84,1.4-.84,1.4l.36,2.3a2.85,2.85,0,0,1-.84-.37L11,5.12,9.82,4l-.15-.15L6,.34H6A1.24,1.24,0,0,0,5.14,0,1.18,1.18,0,0,0,3.93,1.15a1.12,1.12,0,0,0,.33.79h0L8.8,6.27a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0l-5.29-5h0a1.31,1.31,0,0,0-.91-.36A1.26,1.26,0,0,0,1.1,2.31a1.19,1.19,0,0,0,.37.86h0L7,8.4a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L2,4.13a1.17,1.17,0,0,0-.83-.34A1.13,1.13,0,0,0,.06,4.89a1.07,1.07,0,0,0,.34.78h0l.74.7,4.61,4.39a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L1.74,7.36h0A1,1,0,0,0,1,7.07,1,1,0,0,0,0,8a.92.92,0,0,0,.22.57h0l4.42,4.23.76.72,1.81,1.72c.87.83,4.6,1.37,6.88-.84a5.54,5.54,0,0,0,1.82-2.75C16.25,10.14,15.54,9.47,15.33,7.7Z" stroke-width="0.75">
    <g transform="translate(2,1)">
  </svg>
  `,
  'allEmotes-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <circle class="stroke stroke-mid hover-fill" cx="9" cy="20" r="3.25"/>
      <circle class="stroke stroke-mid hover-fill" cx="20" cy="20" r="3.25"/>
      <circle class="stroke stroke-mid hover-fill" cx="31" cy="20" r="3.25"/>
    </svg>
  `,
  'confetti-control': `
    <svg version="1.1" viewBox="0 0 33 33">
      <defs><style>.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{fill:none;stroke-linejoin:round;}.cls-1{stroke:#b2d235;}.cls-1,.cls-2,.cls-6{stroke-width:1.25px;}.cls-2{stroke:#ff5a50;}.cls-3{stroke:#faaf3c;}.cls-3,.cls-5{stroke-width:1.25px;}.cls-4,.cls-6{stroke:#00a2ff;}.cls-4{stroke-width:1.25px;}.cls-5{stroke:#f5d010;}</style></defs>
      <polygon class="stroke green hover-fill" stroke-linejoin="round" points="24.77 15.74 29.57 13.6 31.28 17.44 26.48 19.57 24.77 15.74"/>
      <polygon class="stroke red hover-fill" stroke-linejoin="round" points="24.77 26.24 29.57 24.1 31.28 27.94 26.48 30.07 24.77 26.24"/>
      <polygon class="stroke orange hover-fill" stroke-linejoin="round" points="14.24 18.36 17.55 14.28 20.81 16.92 17.51 21 14.24 18.36"/>
      <polygon class="stroke blue hover-fill" stroke-linejoin="round" points="16.54 5.32 19 0.68 22.71 2.65 20.25 7.29 16.54 5.32"/>
      <polygon class="stroke yellow hover-fill" stroke-linejoin="round" points="3.74 28.86 7.05 24.77 10.31 27.42 7.01 31.5 3.74 28.86"/>
      <polygon class="stroke green hover-fill" stroke-linejoin="round" points="6.92 11.54 11.72 9.4 13.43 13.24 8.63 15.37 6.92 11.54"/>
      <polygon class="stroke red hover-fill" stroke-linejoin="round" points="0.63 18.89 5.42 16.75 7.13 20.59 2.33 22.72 0.63 18.89"/>
      <polygon class="stroke orange hover-fill" stroke-linejoin="round" points="2.69 4.71 6 0.63 9.26 3.27 5.96 7.35 2.69 4.71"/>
      <polygon class="stroke blue hover-fill" stroke-linejoin="round" points="13.22 28.34 18.02 26.2 19.73 30.04 14.93 32.17 13.22 28.34"/>
      <polygon class="stroke yellow hover-fill" stroke-linejoin="round" points="25.79 6.8 29.1 2.73 32.36 5.37 29.06 9.45 25.79 6.8"/></svg>
  `,
  'eject-control': `
    <svg viewBox="0 0 40 40">
      <path class="hover-fill hover-stroke startWhite" d="M20,1A19,19,0,1,0,33.43,6.57,18.94,18.94,0,0,0,20,1Z" stroke-width="2"/>
      <rect class="stroke hover-revStroke" x="9.5" y="24.5" width="21" height="3" rx="1"/>
      <path class="stroke hover-revStroke" d="M20,10l10.1,10.69a.5.5,0,0,1,.14.36.54.54,0,0,1-.16.35.55.55,0,0,1-.34.13H10.28a.51.51,0,0,1-.35-.15A.49.49,0,0,1,9.78,21a.48.48,0,0,1,.14-.34h0l9.71-10.52A.51.51,0,0,1,20,10Z"/>
    </svg>
  `,
  'stopScreenShare-control': `
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
      <g stroke-width="1" fill="none">
        <rect class="stroke hover-revStroke" x="8.5" y="11.7" width="23" height="13" rx="2.82352948"/>
        <rect class="stroke hover-revStroke" x="12.2" y="28" width="16" height="1" rx="0.5"/>
        <line x1="8" y1="29.5" x2="32" y2="9" stroke="#ff0000" stroke-width="2" stroke-linecap="round"/>
      </g>
    </svg>
  `,
  'demote-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <circle class="fill" cx="20" cy="20" r="20"/>
      <rect class="revStroke fill" x="11.9" y="26.5" width="17" height="2"/>
      <path class="revStroke fill" d="M20.4,11.5c0.5,0.1,0.5,0.2,0.6,0.2l3.7,6.4l5.2-3.1C30,15,30.2,15,30.3,15c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3L29.1,25H11.9l-2-9.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.4,0.1l5.6,3.3l3.8-6.6C20.1,11.6,20.2,11.5,20.4,11.5z"/>
    </svg>
  `,
  'promote-control': `
    <svg version="1.1" viewBox="0 0 40 40">
      <circle class="startWhite hover-fill" cx="20" cy="20" r="20"/>
      <rect class="stroke hover-revStroke" x="11.9" y="26.5" width="17" height="2"/>
      <path class="stroke hover-revStroke" d="M20.4,11.5c0.5,0.1,0.5,0.2,0.6,0.2l3.7,6.4l5.2-3.1C30,15,30.2,15,30.3,15c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.3L29.1,25H11.9l-2-9.4c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0,0.4,0.1l5.6,3.3l3.8-6.6C20.1,11.6,20.2,11.5,20.4,11.5z"/>
    </svg>
  `,
};

export { controls };
