<template>
  <div id="container">
    <section class="page introPages useApp" data-page-name="useApp">
      <Launching-desktop-app v-if="appLaunchRequested" :webLink="webLink" :meetingId="this.meeting?.id" :pinPass="this.meeting?.pin_pass" :appLink="appLink" />
      <template v-else>
        <Download-or-use-browser
          v-if="!isPlatformInvitationViewed"
          :meetingId="this.meeting?.id"
          :appLink="appLink"
          :webLink="webLink"
          @launched="onLaunchApp"
        />
        <Use-app-or-browser v-else :meetingId="this.meeting?.id" :appLink="appLink" :webLink="webLink" @launched="onLaunchApp" />
      </template>
      <footer class="pageFooter">
        <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
      </footer>
    </section>
  </div>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useAccountStore } from '@/store/pinia/account';
import BixeApi from '@/services/bixe-api.service';
import DownloadOrUseBrowser from '@/pages/subpages/DownloadOrUseBrowser.vue';
import UseAppOrBrowser from '@/pages/subpages/UseAppOrBrowser.vue';
import LaunchingDesktopApp from '@/pages/subpages/LaunchingDesktopApp.vue';
import utils from '@/resources/utils';

export default {
  props: ['pageName'],
  components: {
    DownloadOrUseBrowser,
    UseAppOrBrowser,
    LaunchingDesktopApp,
  },
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const accountStore = useAccountStore();
    return { pageStore, meetingStore, accountStore };
  },
  mounted() {
    this.pageStore.update({ pageName: this.pageName });
    if (!this.isPersonalRoomInviteLink) {
      this.getMeetingByMeetingId();
    }
    utils.storage.ss.setItem(`platform.invitationViewed.${this.$route.query.id}`, '1');
    this.isPlatformInvitationViewed = this.isAppInviteMode ? false : !!utils.storage.ls.getItem('platform.invitationViewed');
  },
  data() {
    return {
      isPlatformInvitationViewed: true,
      isAppLaunched: false,
      meeting: null,
      appLaunchRequested: false,
    };
  },
  computed: {
    isAppInviteMode() {
      return this.pageName === 'appInvite';
    },
    isPersonalRoomInviteLink() {
      return this.$route.query.flow === 'pr-invite';
    },
    webLink() {
      if (this.isAppInviteMode) {
        return '/';
      }

      if (this.isPersonalRoomInviteLink) {
        const { id, pin } = this.$route.query;
        return `/room/${decodeURIComponent(id)}?pwd=${decodeURIComponent(pin)}`;
      }

      if (!this.meeting) {
        return '#';
      }
      return `/meeting/${this.meeting.id}?pwd=${this.meeting.pin_pass}`;
    },
    appLink() {
      const origin = window.location.origin;
      const baseUrl = `${origin}${this.accountStore.baseUrl}`;
      let destination = '';
      if (this.isPersonalRoomInviteLink) {
        const { id, pin } = this.$route.query;
        destination = `${baseUrl}/room/${decodeURIComponent(id)}?pwd=${decodeURIComponent(pin)}`;
      } else if (this.meeting && this.meeting.id && this.meeting.pin_pass) {
        destination = `${baseUrl}/meeting/${this.meeting.id}?pwd=${this.meeting.pin_pass}`;
      } else {
        destination = baseUrl;
      }
      // if (!this.meeting) {
      //   const encoded = encodeURIComponent(`${baseUrl}`);
      //   return `bixe-app://${encoded}`;
      // }
      const appProtocol = utils.getNativeAppProtocol(this.accountStore.__env);
      return `${appProtocol}://${encodeURIComponent(destination)}`;
    },
    year() {
      let year = new Date().getFullYear();
      return year;
    },
  },
  methods: {
    getMeetingByMeetingId() {
      const meetingId = this.$route.query.id;
      if (!meetingId) {
        return;
      }
      BixeApi.getMeetingValidityInfo(meetingId)
        .then((meeting) => {
          this.meeting = meeting;
        })
        .catch(() => {});
    },
    onLaunchApp() {
      this.appLaunchRequested = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.introPages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .appComponent {
    margin: auto 0;
    width: 100%;
  }
  footer {
    margin: auto 0 0 0;
    z-index: 10;
  }
}
:deep(.actions) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  & > * {
    flex: 0 1 rem(228px);
    height: rem(65px);
    margin: 0 rem(7px);
    position: relative;
  }
}
:deep(.bubbleWrapper) {
  position: relative;
}
:deep(.bubbleTip) {
  width: rem(228px);
  height: rem(65px);
  margin-left: rem(-114px);
  padding: 0;
  .content {
    height: rem(65px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
