import _ from 'underscore';

function unwrap(u) {
  var users = u.slice(0);
  var mid = users.length / 2 - (users.length % 2);
  var halfa = _.first(users, mid);
  var halfb = _.rest(users, mid);
  var glued = _.union(halfa, halfb);
  return glued;
}

function insideOut(u) {
  var users = u.slice(0);
  var queue = _.clone(users);
  var turned = [];
  var shift = true;
  while (queue.length > 0) {
    if (shift) {
      turned.push(queue.shift());
      shift = false;
    } else {
      turned.push(queue.pop());
      shift = true;
    }
  }

  return turned;
}

function userAtTop(u, user) {
  var users = u.slice(0);
  var cut = _.indexOf(users, user);
  if (cut > 0) {
    var glued = _.union(_.rest(users, cut), _.first(users, cut));
    return glued;
  } else {
    return users;
  }
}

function outsideIn(u) {
  var users = u.slice(0);
  var ins = insideOut(users);
  return ins.reverse();
}

function leftToRightLine(u) {
  var users = u.slice(0);
  return unwrap(users);
}

function clockwise(u) {
  var users = u.slice(0);
  return users;
}

function antiClockwise(u) {
  var users = u.slice(0);
  return users.reverse();
}

export { unwrap, insideOut, outsideIn, leftToRightLine, clockwise, antiClockwise, userAtTop };
