<template>
  <div class="dock" :style="dockPositions">
    <div class="dockBackground" :class="[hideAvatars ? 'collapsed' : 'expanded']"></div>
    <transition name="minus">
      <button v-if="!hideAvatars" class="hideToggle" :class="[hideAvatars ? 'hidden' : '']" @click="toggleHide">
        <span class="visuallyHidden">{{ hideLabel }}</span>
        <app-icon icon="minus" />
      </button>
    </transition>
    <transition name="kebab">
      <button v-if="hideAvatars" class="hideToggle" :class="[hideAvatars ? 'hidden' : '']" @click="toggleHide">
        <span class="visuallyHidden">{{ hideLabel }}</span>
        <app-icon icon="kebab" />
      </button>
    </transition>

    <transition name="basic">
      <div v-if="!usersStore.hide && pages.length > 1" class="avatarPagers" :style="pagerStyles">
        <div class="avatarPager back vertical" key="back">
          <button :disabled="page === 0" @click="prevAvatars">
            <app-icon icon="left-arrow" />
          </button>
          <transition name="basic">
            <div v-if="talkingPage !== false && !(talkingUser.id === speaking.id && currentPhase === 'context') && talkingPage < page" class="isSpeaking">
              <volume-indicator :volume="talkingUser.volume" />
            </div>
          </transition>
          <avatars-extra-emotes direction="back" />
        </div>
        <div class="pageNumber">
          {{ page + 1 }}
        </div>

        <div key="forward" class="avatarPager forward vertical">
          <button :disabled="lastPage" @click="nextAvatars">
            <app-icon icon="right-arrow" />
          </button>
          <transition name="basic">
            <div v-if="talkingPage !== false && !(talkingUser.id === speaking.id && currentPhase === 'context') && talkingPage > page" class="isSpeaking">
              <volume-indicator :volume="talkingUser.volume" />
            </div>
          </transition>
          <avatars-extra-emotes direction="forward" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import AppIcon from '@/components/AppIcon';

import VolumeIndicator from '@/components/VolumeIndicator';
import AvatarsExtraEmotes from '@/components/AvatarsExtraEmotes';

const usersStore = useUsersStore();
const frameStore = useFrameStore();
const phaseStore = usePhaseStore();

const { positionDetails: pos, hide: hideAvatars, pages, page, talkingPage, talkingUser, speaking } = storeToRefs(usersStore);

const { contWidth } = storeToRefs(frameStore);
const pxToRem = frameStore.pxToRem;
const { currentPhase } = storeToRefs(phaseStore);

const lastPage = computed(() => page.value + 1 >= pages.value.length);
const hideLabel = computed(() => (hideAvatars.value ? 'show avatars' : 'hide avatars'));
const pagerStyles = computed(() => {
  return {
    width: pos.value.dockWidth + 'px',
  };
});
const pagerPad = computed(() => (pages.value.length > 1 ? pxToRem(28) : 0));
const dockPositions = computed(() => {
  if (hideAvatars.value) {
    return {
      left: contWidth.value - 28 + 'px',
      width: '28px',
      top: pos.value.dockTop + 'px',
      height: '50px',
      transition: 'all .3s ease .3s',
    };
  } else {
    return {
      left: pos.value.dockLeft + 'px',
      width: pos.value.dockWidth + 'px',
      top: pos.value.dockTop + 'px',
      height: pos.value.dockHeight + pagerPad.value + 'px',
    };
  }
});
function toggleHide() {
  usersStore.toggleHide();
}
function nextAvatars() {
  usersStore.updatePage(page.value + 1);
}
function prevAvatars() {
  usersStore.updatePage(page.value - 1);
}
</script>
<style lang="scss" scoped>
.avatarPagers {
  position: absolute;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0.5em;
  align-items: center;
  justify-content: space-around;
  height: rem(50px);
  bottom: rem(28px);
  z-index: 100;
  .pageNumber {
    font-weight: 400;
  }
  @include type-size('small');
  button {
    height: rem(50px);
    padding: 0;
    :deep(svg) {
      width: rem(21px);
      height: rem(12px);
    }
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }

    &[disabled] {
      opacity: var(--opacity__disabled);
      color: var(--c__text);
    }
  }
}
.avatarPager {
  position: relative;
}
.dock {
  position: absolute;
}
.dockBackground {
  background: var(--c__dock);
  position: absolute;
  z-index: 3;
  transition: all 0.6s ease;
  box-shadow: var(--shadow);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &.expanded {
    border-radius: 4px;
  }
  &.collapsed {
    border-radius: 4px 0 0 4px;
  }
}
.hideToggle {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 28px;
  padding: 0;
  z-index: 100;
}

:deep(svg) {
  display: block;
}

:deep(.minus-icon svg) {
  margin: 26px 20px 20px 4px;
  height: 4px;
  width: 16px;
}
:deep(.kebab-icon svg) {
  margin: 10px 11px;
  height: 30px;
  width: 6px;
}

.minus-enter-active {
  transition: all 0.3s cubic-bezier(0, 1, 1, 1);
}

.minus-leave-active {
  position: absolute;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.minus-enter-from {
  opacity: 0;
}

.kebab-enter-active {
  transition: all 0.3s cubic-bezier(0, 1, 1, 1) 0.5s;
}

.kebab-leave-active {
  position: absolute;
  opacity: 0;
  transition: none;
}

.kebab-enter-from {
  transform: translateX(200%);
}
</style>
