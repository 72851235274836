import bixeApi from '@/services/bixe-api.service';

export default () => (to, from, next) => {
  const roomDetails = { id: to.params.pinNumber, pwd: to.query.pwd };

  if (roomDetails.id && roomDetails.pwd) {
    bixeApi
      .validatePersonalRoomInviteLink(roomDetails.id, roomDetails.pwd)
      .then(() => {
        next();
      })
      .catch(({ error }) => {
        console.error('Invalid room invite link', error);
        next('/join?reason=badLink');
      });
  } else {
    console.error('Tried to access Personal room without pinNumber or password');
    next('/join?reason=badLink');
  }
};
