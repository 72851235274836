<template>
  <div class="loadingSpinner">
    <svg viewBox="0 0 24 24" class="spin">
      <path d="M12,2A10,10,1,1,1,2,12" stroke-width="4" stroke-linecap="round" />
    </svg>
    <transition name="scale">
      <svg viewBox="0 0 24 24" class="win" v-if="success">
        <circle class="stroke" cx="12" cy="12" r="10" stroke-width="2" stroke-linecap="round" />
        <path d="M8,13L11,16L16,9" stroke-width="3" stroke-linecap="round" />
      </svg>
    </transition>
    <transition name="scale">
      <svg viewBox="0 0 24 24" class="fail" v-if="fail">
        <circle class="stroke" cx="12" cy="12" r="10" stroke-width="2" stroke-linecap="round" />
        <path d="M8,8L16,16" stroke-width="3" stroke-linecap="round" />
        <path d="M16,8L8,16" stroke-width="3" stroke-linecap="round" />
      </svg>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    success: {
      type: Boolean,
      default: false,
    },
    fail: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes rotateClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loadingSpinner {
  height: 1em;
  width: 1em;
  position: relative;
  svg {
  }
  .spin {
    width: 100%;
    height: 100%;
    animation: rotateClockwise 1s linear 0s infinite !important;
    path {
      fill: none;
      stroke: currentColor;
    }
  }
  .win,
  .fail {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    circle {
      fill: var(--c__bg);
      stroke: currentColor;
    }
    path {
      fill: none;
      stroke: currentColor;
    }
  }
  .win {
    color: var(--c__success);
  }
  .fail {
    color: var(--c__warn);
  }
}
</style>
