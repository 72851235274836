<template>
  <div class="poster pane" v-if="presenter?.id">
    <div class="icon">
      <svg viewBox="0 0 88 88">
        <path
          d="m66.47,55.4c-.73-.02-1.41.36-1.78.99-4.22,7.02-11.9,11.72-20.69,11.72s-16.48-4.7-20.69-11.72h0c-.27-.46-.71-.8-1.23-.94-.52-.13-1.07-.05-1.53.22-.46.28-.79.73-.91,1.25-.12.52-.04,1.07.25,1.53,4.91,8.18,13.89,13.66,24.12,13.66s19.19-5.48,24.11-13.65c.38-.61.4-1.38.06-2-.34-.63-.99-1.03-1.71-1.05h0Z"
        />
        <path
          d="m54.15,35.19c-1.1.02-1.98.93-1.97,2.03,0,4.46,3.66,8.1,8.1,8.1s8.1-3.65,8.1-8.1h0c0-.54-.2-1.05-.57-1.43-.38-.38-.89-.59-1.43-.59s-1.05.21-1.43.59c-.38.38-.58.9-.57,1.43,0,2.3-1.8,4.1-4.1,4.1s-4.1-1.81-4.1-4.1h0c0-.54-.2-1.06-.59-1.44-.38-.38-.9-.59-1.45-.59h0Z"
        />
        <path
          d="m21.58,35.19c-1.1.02-1.98.93-1.97,2.03,0,4.45,3.65,8.1,8.1,8.1s8.1-3.64,8.1-8.1h0c0-.54-.2-1.05-.57-1.43-.38-.38-.89-.59-1.43-.59s-1.05.21-1.43.59c-.38.38-.58.9-.57,1.43,0,2.29-1.82,4.1-4.1,4.1s-4.1-1.8-4.1-4.1h0c0-.54-.2-1.06-.59-1.44-.38-.38-.9-.59-1.45-.59h0Z"
        />
        <path
          d="m44,0C19.73,0,0,19.72,0,44s19.73,44,44,44,44-19.73,44-44S68.29,0,44,0Zm0,4c22.12,0,40,17.88,40,40s-17.88,40-40,40S4,66.11,4,44,21.89,4,44,4Z"
        />
      </svg>
    </div>
    <p>Waiting for {{ presenter.first_name }} to start sharing</p>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';

export default {
  computed: {
    ...mapPiniaState(useUsersStore, ['speaking', 'mirroring']),
    presenter() {
      return this.mirroring && this.mirroring.id ? this.mirroring : this.speaking;
    },
  },
};
</script>

<style lang="scss" scoped>
.pane {
  border-bottom: 1px solid #efefef;
  background: var(--grad__presentation), var(--c__bg-alt);
  z-index: 99;
  margin-top: rem(4px);
  flex-direction: column;
}

.icon {
  display: block;
  height: rem(88px);
  width: rem(88px);
  color: var(--c__active);
  border-radius: 50%;
  margin-bottom: rem(48px);
  path {
    fill: currentColor;
  }
}

p {
  @include type-size('medium');
  font-weight: 300;
}
</style>
