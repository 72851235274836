<template>
  <transition name="basic">
    <app-loading-spinner v-if="spinning" />
  </transition>
  <div v-click-outside="outside" class="bixeUserSelect">
    <button v-if="showIndicator" ref="indicator" class="selectedUser" :disabled="!phaseStore.settingUp" @click.prevent="toggleList">
      <div v-if="showingUser" class="userPic">
        <img v-if="showingUser.picture_url" :src="showingUser.picture_url" :alt="showingUser.first_name" />
        <span class="initials" v-else>
          <app-scaled-text>
            {{ initials }}
          </app-scaled-text>
        </span>
      </div>
    </button>
    <transition name="scale">
      <microskill-user-list v-if="showList" ref="list" @close="showList = false" @outside="showList = false" :tail="true" />
    </transition>
  </div>
</template>

<script>
import _ from 'underscore';

import { mapState as mapPiniaState } from 'pinia';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import MicroskillUserList from './MicroskillUserList.vue';
import anim from '@/resources/animation-constants';
import utils from '../resources/utils';
import AppScaledText from '@/components/AppScaledText.vue';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';

export default {
  components: {
    AppScaledText,
    AppLoadingSpinner,
    MicroskillUserList,
  },
  setup() {
    const usersStore = useUsersStore();
    const sidebarStore = useSidebarStore();
    const phaseStore = usePhaseStore();
    return { sidebarStore, usersStore, phaseStore };
  },
  data() {
    return {
      showingUser: false,
      currentSpeaker: false,
      showList: false,
      debouncedChangeAvatar: _.debounce(this.changeAvatar, 200),
      fullyShown: false,
      spinning: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.speakingAvatar && this.$refs.indicator) {
        this.$refs.indicator.style.display = 'block';
      }
    });
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      users: 'usersOnline',
    }),
    ...mapPiniaState(usePhaseStore, ['randomSpeakerTs', 'pendingSetupSpeaker']),
    speaking() {
      return this.usersStore.speaking;
    },
    showIndicator() {
      return (
        this.sidebarStore.current.barType !== 'info' &&
        !['breakTime', 'groups'].includes(this.sidebarStore.current.phase) &&
        !this.usersStore.iAmStillAway &&
        !this.usersStore.iAmStillAwayInGroup
      );
    },
    speakingId() {
      if (this.speaking) {
        return this.speaking.id;
      } else {
        return false;
      }
    },
    initials() {
      if (this.showingUser) {
        var first = this.showingUser.first_name ? utils.desanitizeString(this.showingUser.first_name[0].toUpperCase()) : '';
        var second = this.showingUser.last_name ? utils.desanitizeString(this.showingUser.last_name[0].toUpperCase()) : '';
        return first + second;
      } else {
        return '';
      }
    },
  },
  watch: {
    speakingId: {
      immediate: true,
      handler: function () {
        this.debouncedChangeAvatar();
      },
    },
    randomSpeakerTs(nv) {
      if (nv) {
        this.randomAnimation(this.pendingSetupSpeaker);
      }
    },
    'phaseStore.settingUp': function () {
      this.debouncedChangeAvatar();
    },
  },
  methods: {
    avatarString() {
      if (this.speakingId) {
        const ava = document.querySelector('user-' + this.speaking.id + ' .userPic');
        if (ava) {
          return `<span class="userPic">${ava.innerHTML}</span><span class="name">${utils.desanitizeString(this.speaking.first_name)}</span>`;
        }
      } else {
        return '';
      }
    },
    toggleList() {
      this.showList = !this.showList;
    },
    randomAnimation(userId) {
      let ids = this.users.map((u) => u.id);
      let extra = ids.slice(0, ids.indexOf(userId) + 1);
      let list = [];

      if (ids.length < 3) {
        list = [...ids, ...ids, ...ids, ...extra];
      } else if (ids.length === 3) {
        list = [...ids, ids[1], ...extra];
      } else if (ids.length < 5) {
        list = [...ids, ...ids, ...extra];
      } else {
        list = [...ids, ...extra];
      }

      let startDelay = 800 / (list.length - extra.length);
      let addedDelay = 0;

      var swap = (delay) => {
        if (list.length > 0) {
          this.usersStore.updateRandomTicker(list.shift());
          if (list.length < 6) {
            addedDelay += 100;
          }
          _.delay(() => {
            swap(delay + addedDelay);
          }, delay);
        } else {
          this.phaseStore.updateSetupSpeaker({ speaker: userId, isRandom: false });
          _.delay(() => {
            this.usersStore.updateRandomTicker(false);
          }, delay);
          _.delay(() => {
            this.spinning = false;
          }, delay + 400);
        }
      };

      swap(startDelay);
    },
    outside() {
      // if (this.fullyShown) {
      //   this.showList = false;
      // }
      this.showList = false;
    },
    enterList(el, done) {
      Velocity(
        el,
        {
          scale: [1, 0],
        },
        {
          display: 'flex',
          duration: 300,
          easing: 'easeOutQuad',
          complete: () => {
            done();
            this.fullyShown = true;
          },
        },
      );
    },
    leaveList(el, done) {
      this.fullyShown = false;
      Velocity(
        el,
        {
          scale: [0, 1],
        },
        {
          display: 'none',
          duration: 300,
          easing: 'easeOutQuad',
          complete: done,
        },
      );
    },
    changeAvatar() {
      if (this.currentSpeaker !== this.speakingId && this.$refs.indicator) {
        Velocity(this.$refs.indicator, 'transition.expandOut', {
          duration: anim.BASE_SPEED / 2,
          complete: () => {
            this.showingUser = this.speaking;
            this.$nextTick(() => {
              if (this.$refs.indicator) {
                Velocity(this.$refs.indicator, 'transition.expandIn', {
                  display: 'flex',
                  duration: anim.BASE_SPEED / 2,
                });
              }
            });
          },
        });
        this.currentSpeaker = this.speakingId;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bixeUserSelect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.loadingSpinner {
  .opening & {
    --energy: var(--c__skillbar-bg-opening-solid);
  }

  .container & {
    --energy: var(--c__skillbar-bg-container-solid);
  }

  .tension & {
    --energy: var(--c__skillbar-bg-tension-solid);
  }

  .breakthrough & {
    --energy: var(--c__skillbar-bg-breakthrough-solid);
  }

  margin-left: rem(18px);
  border-radius: 50%;
  height: rem(87px);
  width: rem(87px);
  z-index: 5;
  position: absolute;
  right: -2px;
  top: -2px;
  color: var(--c__bg);
  background: var(--energy);

  :deep(path) {
    stroke-width: 2;
  }
}

.selectedUser {
  margin-left: rem(18px);
  background: var(--c__bg);
  border-radius: 50%;
  height: rem(83px);
  width: rem(83px);
  box-shadow: 0 0 4px rgba(black, 0.2);
  display: none;
  padding: 5px;
  border: solid 2px var(--c__avatar-border-edge);
  z-index: 3;
  .userPic {
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 5px;
    right: 5px;
    img {
      background: var(--grad__initials);
    }
  }
  img,
  .span {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  img {
    z-index: 2;
  }
  .initials {
    z-index: 1;
    background: var(--grad__initials);
    display: block;
    border-radius: 50%;
    :deep(svg) {
      display: block;
    }
  }
  .name {
    display: block;
    margin: 0.5em 0 0 0;
    color: var(--c__bg);
    visibility: hidden;
  }
}
</style>
