<template>
  <div :class="name" @click="handleDisabled">
    <button ref="icon" :disabled="isDisabled" @click="handleClick" @keyup.space="(e) => e.preventDefault()" :title="title">
      <app-icon icon="leave" />
      <span class="text" :class="[hideText ? 'visuallyHidden' : '']">
        <slot />
      </span>
    </button>
    <slot name="tips">
      <bubble-tip
        class="warn"
        :direction="tipDirection"
        :show="checkPlease"
        :buttons="[
          { event: 'cancel', text: 'Cancel' },
          { event: 'confirm', text: 'Yes' },
        ]"
        @close="close"
        @button="handleTipButton"
      >
        <template v-if="name === 'leave'">
          <strong>Leave meeting?</strong>
        </template>
        <template v-if="name === 'finish'">
          <strong>End meeting for all?</strong>
        </template>
      </bubble-tip>
    </slot>
  </div>
</template>

<script>
import utils from '../resources/utils.js';
import BubbleTip from '@/components/BubbleTip.vue';
import AppIcon from '@/components/AppIcon.vue';
import { localStorageKeysMap } from '../shared/constants';

import { usePhaseStore } from '@/store/pinia/phase';

export default {
  components: {
    BubbleTip,
    AppIcon,
  },
  props: {
    name: {
      type: String,
    },
    isDisabled: {
      required: false,
      default: false,
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    tipDirection: {
      type: String,
      default: 'above',
    },
    title: {
      type: String,
      default: 'leave',
    },
  },
  setup() {
    const phaseStore = usePhaseStore();
    return { phaseStore };
  },
  data() {
    return {
      checkPlease: false,
    };
  },
  computed: {},
  methods: {
    close() {
      this.checkPlease = false;
      this.$emit('hide-bubble');
    },
    handleClick() {
      /**
       * intentionally avoiding `await` for `getIsEligibleForFeedback` to bootup feedback service
       * this will help us avoid cold-boot, delayed response from feedback service when its actually needed.
       * */
      this.$API.getIsEligibleForFeedback();
      if (this.phaseStore.currentPhase === 'endMeeting') {
        this.$ee.emit(`nM:meeting:${this.name}`);
        this.$emit('exit');
      } else {
        if (this.checkPlease) {
          this.$emit('hide-bubble');
          this.checkPlease = false;
        } else {
          this.$emit('show-bubble');
          this.checkPlease = true;
        }
      }
    },
    handleTipButton(ev) {
      this.checkPlease = false;
      this.$emit('hide-bubble');
      if (ev === 'confirm') {
        utils.storage.ls.removeItem(localStorageKeysMap.RELAUNCH_MEETING_ID);
        this.$ee.emit(`nM:meeting:${this.name}`);
        this.$emit('exit');
      }
    },
    handleDisabled() {
      if (this.isDisabled) {
        Velocity(this.$refs.icon, 'callout.swing', { duation: 300 });
        this.$emit('nope');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .icon {
    height: rem(40px);
    width: rem(40px);
    border: 2px solid var(--c__leave-hamburger-border);
    background: var(--c__leave-hamburger-fill);
    color: var(--c__leave-hamburger-color);
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .text {
    color: var(--c__text);
    line-height: 1;
    margin-top: rem(11px);
  }

  .leave-icon :deep(svg) {
    width: rem(29px);
    height: rem(22px);
  }

  .finish-icon :deep(svg) {
    width: rem(24px);
    height: rem(24px);
  }

  @include triggered {
    .icon {
      background: var(--c__leave-hamburger-fill-hover);
      color: var(--c__leave-hamburger-color-hover);
      border-color: var(--c__leave-hamburger-border-hover);
    }
    .text {
      color: var(--c__warn-loud);
    }
  }

  .skillBar.breakTime &,
  .skillBar.groups & {
    .icon {
      background: var(--c__leave-misc-fill);
      color: var(--c__leave-misc-color);
      border-color: var(--c__leave-misc-border);
    }

    @include triggered {
      color: var(--c__warn-loud);
      .icon {
        background: var(--c__leave-misc-fill-hover);
        color: var(--c__leave-misc-color-hover);
        border-color: var(--c__leave-misc-border-hover);
      }
    }
  }

  .skillBar.opening &,
  .skillBar.container &,
  .skillBar.breakthrough & {
    .icon {
      border-color: var(--c__leave-skill-border);
      background: var(--c__leave-skill-fill);
      color: var(--c__leave-skill-color);
    }

    .text {
      color: var(--c__text-energy);
    }
    @include triggered {
      .icon {
        border-color: var(--c__leave-skill-border-hover);
        background: var(--c__leave-skill-fill-hover);
        color: var(--c__leave-skill-color-hover);
      }
      .text {
        color: var(--c__warn-loud);
      }
    }
  }
}
</style>
