<template>
  <draggable
    v-bind="dragOptions"
    v-model="fields"
    class="listInput"
    :class="isDragging ? 'isDragging' : ''"
    @change="capture"
    @start="isDragging = true"
    @end="isDragging = false"
    item-key="id"
  >
    <template #item="{ element, index }">
      <div class="row" :key="'item-' + index">
        <div class="content">
          <input
            :id="name + 'Field-' + index"
            ref="input"
            v-model.trim="element.value"
            type="text"
            :placeholder="index > 0 ? 'Add another...' : `Add ${item}...`"
            :class="[index === 0 ? 'focusPlease' : '', limit && element.value.length > limit ? 'over' : 'ok']"
            :maxlength="limit"
            @keyup.tab.prevent="nextField(index)"
            @keyup.prevent="onInput"
            @keyup.enter.prevent="handleEnter($event, index)"
            @input="onInput"
          />
          <app-text-limit-message v-if="limit" :value="element.value" :limit="limit" :warn-limit="warnLimit" />
          <div v-if="element.value !== '' && fields.length > 2" class="dragHandle" aria-hidden="true">
            <span class="visuallyHidden">Drag</span>
            <app-icon icon="drag" />
          </div>
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import AppIcon from '@/components/AppIcon.vue';
import AppTextLimitMessage from '@/components/AppTextLimitMessage.vue';

export default {
  components: {
    draggable,
    AppIcon,
    AppTextLimitMessage,
  },
  props: {
    fieldData: {
      type: Array,
    },
    name: {
      type: String,
    },
    item: {
      type: String,
    },
    limit: {
      type: Number,
    },
    maxItems: {
      type: Number,
      default: 0,
    },
    warnLimit: {
      type: Number,
    },
  },
  data: function () {
    return {
      fields: [{ value: '' }],
      isDragging: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 100,
        easing: 'ease',
        ghostClass: 'item-ghost',
        chosenClass: 'item-chosen',
        dragClass: 'item-drag',
        direction: 'vertical',
        handle: '.dragHandle',
      };
    },
  },
  watch: {
    fieldData: {
      immediate: true,
      deep: true,
      handler() {
        if (this.fieldData && this.fieldData.length > 0) {
          this.fields = this.fieldData.map((v) => {
            return { value: v };
          });
        } else {
          this.fields = [{ value: '' }];
        }
        this.checkFields();
      },
    },
  },
  mounted() {
    if (this.fieldData && this.fieldData.length > 0) {
      this.fields = this.fieldData.map((v) => {
        return { value: v };
      });
    } else {
      this.fields = [{ value: '' }];
    }
    this.checkFields();
  },
  methods: {
    capture() {
      return new Promise((resolve) => {
        this.$emit(
          'capture',
          this.fields.map((f) => f.value).filter((f) => f !== ''),
        );
        resolve();
      });
    },
    handleEnter($event, i) {
      if ($event.shiftKey) {
        this.nextField(i);
      } else {
        this.save();
      }
    },
    save() {
      this.capture().then(() => {
        this.$emit('save');
      });
    },
    onInput() {
      this.checkFields().then(this.capture);
    },
    clearField(index) {
      this.fields[index].value = '';
      this.checkFields().then(this.capture);
    },
    nextField(i) {
      if (this.fields[i + 1]) {
        const name = this.name + 'Field-' + (i + 1);
        let elem = document.getElementById(name);
        if (elem) {
          elem.focus();
        }
      }
    },
    checkFields: function () {
      return new Promise((resolve) => {
        this.fields = this.fields.filter((f) => f.value !== '');

        if (this.fields.length === 0) {
          this.fields = [{ value: '' }];
        }

        if (this.fields[this.fields.length - 1].value !== '') {
          if (!this.maxItems || (this.maxItems && this.fields.length < this.maxItems)) {
            this.fields = [...this.fields, { value: '' }];
          }
        }

        resolve();
      });
    },
  },
};
</script>

<style lang="scss">
.listInput {
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .item-ghost {
    opacity: 0.5;
  }
  .item-chosen {
    opacity: 1;
    .dragHandle {
      opacity: 1;
    }
  }
  .item-drag {
    opacity: 0;
  }
  .dragHandle {
    padding: rem(10px) 0 0 1em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    align-items: center;
    //transition: all 0.3s ease;
    svg {
      height: rem(10px);
      width: rem(13px);
    }
  }

  .row:hover .dragHandle,
  .dragHandle:focus,
  .dragHandle:active {
    display: flex;
    cursor: grab;
  }

  &.isDragging {
    & * {
      cursor: grabbing;
    }

    .row:hover .dragHandle {
      cursor: grabbing;
      display: none;
    }
    .item-chosen .dragHandle,
    .item-chosen.item-ghost .dragHandle {
      display: flex;
      cursor: grabbing;
    }
  }
}
</style>
