<template>
  <div id="bixe-sidebar-container" class="bixeSidebarContainer">
    <div id="bixe-sidebar" class="bixeSidebar" :class="[frameStore.fullscreen ? 'bixe-sidebar-collapsed' : '']">
      <div id="sidebar" class="sidebar" ref="sidebar" :class="currentPane + '-showing'">
        <div v-if="sidebarVisible" ref="container">
          <transition-group name="flipPanes" v-on:before-enter="beforeEnter" @enter="enter" @after-enter="afterEnter" @leave="leave">
            <outside-sidebar v-if="currentPane === 'outside'" key="outside" />
            <lobby-sidebar v-if="currentPane === 'lobby'" key="lobby" />
            <waiting-room-sidebar v-if="currentPane === 'waitingRoom'" key="waitingRoom" />
            <hamburger-menu-sidebar v-if="currentPane === 'hamburger'" class="meetingMenu" />
            <po3-sidebar v-if="currentPane === 'po3'" key="lobby" />
            <meeting-time-sidebar v-if="currentPane === 'meetingTime'" key="lobby" />
            <microskill-sidebar v-if="currentPane === 'skillBar'" key="skillBar" panel="skillBar" />
            <end-meeting-sidebar v-if="currentPane === 'endBar'" key="endBar" panel="endBar" />
            <what-next-sidebar v-if="currentPane === 'whatNext'" />
            <microskills-menu-sidebar v-if="currentPane === 'microskills'" />
            <edit-title-sidebar v-if="currentPane === 'name'" key="editTitle" :focusing="focusing" />
            <edit-purpose-sidebar v-if="currentPane === 'purpose'" key="editPurpose" />
            <edit-meeting-info-list-sidebar
              v-if="currentPane === 'objectives'"
              key="editObjectives"
              class="editObjectives"
              name="objectives"
              title="Objectives"
              :field="meetingStore.objectives"
            />
            <edit-meeting-info-list-sidebar
              v-if="currentPane === 'outcomes'"
              key="editOutcomes"
              class="editOutcomes"
              name="outcomes"
              title="Outcomes"
              :field="meetingStore.outcomes"
            />
            <edit-meeting-info-list-sidebar
              v-if="currentPane === 'outputs'"
              key="editOutputs"
              class="editOutputs"
              name="outputs"
              title="Outputs"
              :field="meetingStore.outputs"
            />
            <edit-check-in-out-question-sidebar v-if="currentPane === 'checkInQuestion'" key="editCheckInQuestion" />
            <edit-proposal-sidebar v-if="currentPane === 'proposal'" key="editProposal" />
            <edit-topic-sidebar v-if="currentPane === 'topic'" key="editTopic" />
            <user-settings-sidebar v-if="currentPane === 'settings'" key="settings" />
            <emote-grid-sidebar v-if="currentPane === 'emoteGrid'" key="emotes" />
            <participant-list-sidebar v-if="currentPane === 'participantList'" key="participantList" />
            <spaces-sidebar v-if="currentPane === 'spaces'" key="emotes" />
          </transition-group>

          <transition name="bottom-notice">
            <sidebar-notice v-if="hasNotice" key="notices" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import OutsideSidebar from '@/components/OutsideSidebar';
import HamburgerMenuSidebar from '@/components/HamburgerMenuSidebar';
import LobbySidebar from '@/components/LobbySidebar';
import WaitingRoomSidebar from '@/components/WaitingRoomSidebar';
import Po3Sidebar from '@/components/Po3Sidebar';
import MeetingTimeSidebar from '@/components/MeetingTimeSidebar';
import MicroskillSidebar from '@/components/MicroskillSidebar';
import EndMeetingSidebar from '@/components/EndMeetingSidebar';
import WhatNextSidebar from '@/components/WhatNextSidebar';
import MicroskillsMenuSidebar from '@/components/MicroskillsMenuSidebar';
import EditTitleSidebar from '@/components/EditTitleSidebar';
import EditPurposeSidebar from '@/components/EditPurposeSidebar';
import EditMeetingInfoListSidebar from '@/components/EditMeetingInfoListSidebar';
import EditProposalSidebar from '@/components/EditProposalSidebar';
import EditTopicSidebar from '@/components/EditTopicSidebar';
import EditCheckInOutQuestionSidebar from '@/components/EditCheckInOutQuestionSidebar';
import EmoteGridSidebar from '@/components/EmoteGridSidebar';
import ParticipantListSidebar from '@/components/ParticipantListSidebar';
import SpacesSidebar from '@/components/SpacesSidebar';
import UserSettingsSidebar from '@/components/UserSettingsSidebar';
import SidebarNotice from '@/components/SidebarNotice';
import { whichTransitionEvent } from '@/resources/css-events';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useMomentsStore } from '@/store/pinia/moments';
import { usePageStore } from '@/store/pinia/page';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { useTimeStore } from '@/store/pinia/time';
import { useFeaturesStore } from '@/store/pinia/features';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';

export default {
  components: {
    OutsideSidebar,
    HamburgerMenuSidebar,
    LobbySidebar,
    WaitingRoomSidebar,
    Po3Sidebar,
    MeetingTimeSidebar,
    MicroskillSidebar,
    EndMeetingSidebar,
    WhatNextSidebar,
    MicroskillsMenuSidebar,
    EditTitleSidebar,
    EditPurposeSidebar,
    EditTopicSidebar,
    EditMeetingInfoListSidebar,
    EditProposalSidebar,
    EditCheckInOutQuestionSidebar,
    EmoteGridSidebar,
    ParticipantListSidebar,
    SpacesSidebar,
    UserSettingsSidebar,
    SidebarNotice,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const momentsStore = useMomentsStore();
    const pageStore = usePageStore();
    const sidebarStore = useSidebarStore();
    const phaseStore = usePhaseStore();
    const timeStore = useTimeStore();
    const featuresStore = useFeaturesStore();
    const frameStore = useFrameStore();
    return { featuresStore, meetingStore, messagesStore, momentsStore, pageStore, sidebarStore, phaseStore, timeStore, frameStore };
  },
  data: function () {
    return {
      focusing: false,
    };
  },
  created() {
    const _this = this;
    this.$ee.on('nM:requestFinish', _this.requestFinish);
  },
  beforeUnmount() {
    const _this = this;
    this.$ee.off('nM:requestFinish', _this.requestFinish);
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['iAmSpeaking', 'iAmOverdue', 'iAmInWaitingRoom']),
    ...mapPiniaState(useMessagesStore, ['notice', 'hasNotice']),
    pageName() {
      return this.pageStore.current.pageName;
    },
    currentPane() {
      if (this.pageStore.isMeeting) {
        if (!this.meetingStore.initTitleCardShown || this.phaseStore.currentPhase === 'init') {
          return false;
        }
      }
      return this.sidebarStore.current.name;
    },
    prevPane() {
      return this.sidebarStore.current.name;
    },
    panelDirection() {
      if (!this.currentPane) {
        return 'right';
      }
      if (!this.prevPane) {
        return 'left';
      }

      if (this.sidebarStore.current.position > this.sidebarStore.previous.position) {
        return 'left';
      } else {
        return 'right';
      }
    },
    sidebarVisible() {
      return this.sidebarStore.isOpen || this.sidebarStore.isAnimating;
    },
    absentFromCheckIn() {
      return (
        ['checkIn', 'checkOut'].includes(this.phaseStore.currentPhase) && this.iAmSpeaking && this.currentPane !== 'skillBar' && this.timeStore.alarmRunning
      );
    },
  },
  watch: {
    currentPane(nv, ov) {
      // Handle unlocking
      if (this.sidebarStore.current.isEditPane) {
        this.$meetingmanager.lockField(this.sidebarStore.current.name);
      }
      if (this.sidebarStore.previous.isEditPane) {
        _.delay(() => {
          this.$meetingmanager.unlockField(this.sidebarStore.previous.name);
        }, 500);
      }

      // Handle hide/show
      if (this.featuresStore.noAnimations) {
        document.body.classList.remove('sidebar-opening');
        document.body.classList.remove('sidebar-closing');
        if (nv) {
          document.body.classList.add('sidebar-open');
          document.body.classList.remove('sidebar-closed');
          this.sidebarStore.setIsOpen(true);
        } else {
          document.body.classList.add('sidebar-closed');
          document.body.classList.remove('sidebar-open');
          this.sidebarStore.setIsOpen(false);
        }
      } else {
        var ev = whichTransitionEvent();
        var el = this.$refs.sidebar;

        if (!el) {
          return false;
        }

        if (nv && !ov) {
          this.sidebarStore.setIsOpening(true);
          this.sidebarStore.setIsOpen(true);
          document.body.classList.add('sidebar-opening');
          document.body.classList.remove('sidebar-closed');

          ev &&
            el.addEventListener(
              ev,
              () => {
                this.sidebarStore.setIsOpening(false);
                this.sidebarStore.setIsOpen(true);
                document.body.classList.add('sidebar-open');
                document.body.classList.remove('sidebar-opening');
              },
              { once: true },
            );
        } else if (!nv && ov) {
          this.sidebarStore.setIsClosing(true);
          document.body.classList.add('sidebar-closing');
          document.body.classList.remove('sidebar-open');

          ev &&
            el.addEventListener(
              ev,
              () => {
                this.sidebarStore.setIsClosing(false);
                this.sidebarStore.setIsOpen(false);
                document.body.classList.add('sidebar-closed');
                document.body.classList.remove('sidebar-closing');
                if (document.body.classList.contains('sidebar-open')) {
                  document.body.classList.remove('sidebar-open');
                  console.log(' --> removing sidebar-open');
                }
              },
              { once: true },
            );
        }
      }
    },
    pageName() {
      this.sidebarStore.closeSidebar();
    },
    absentFromCheckIn(nv) {
      if (nv) {
        this.messagesStore
          .addAlert('youNeedToEndYourTurn')
          .then(() => {
            this.$meetingmanager.finish();
          })
          .catch(() => {});
      } else {
        this.messagesStore.removeAlert({ messageName: 'youNeedToEndYourTurn' });
      }
    },
    iAmOverdue(nv) {
      if (nv) {
        this.sidebarStore.iAmOverdue();
      }
    },
    iAmInWaitingRoom(nv) {
      if (nv) {
        this.sidebarStore.iAmInWaitingRoom();
      } else {
        this.sidebarStore.requestPane(false, { force: false }, 'finishedWaiting');
      }
    },
  },
  methods: {
    // Animate panes
    beforeEnter(el) {
      this.focusing = false;
      el.style.transform = this.panelDirection === 'right' ? 'translate(-100%)' : 'translate(100%)';
      this.sidebarStore.setIsEntering(true);
    },
    enter(el, done) {
      let initVal = this.panelDirection === 'right' ? '-100%' : '100%';
      Velocity(
        el,
        {
          translateX: ['0%', initVal],
        },
        {
          easing: 'easeInOutQuint',
          duration: 600,
          complete: done,
        },
      );
    },
    afterEnter(el) {
      this.focusing = true;
      const focusMe = el.querySelector('.focusPlease');
      if (focusMe) {
        focusMe.focus();
      }
      this.sidebarStore.setIsEntering(false);
    },
    requestFinish() {
      this.messagesStore
        .interrupt('sureYouWantToFinishMeeting')
        .then(() => {
          this.$ee.emit('nM:meeting:finish');
        })
        .catch(() => {});
    },
    leave(el, done) {
      let finalVal = this.panelDirection === 'left' ? '-100%' : '100%';

      if (!this.currentPane) {
        // Don't slide it if we are hiding the sidebar
        finalVal = '0%';
      }
      this.sidebarStore.setIsLeaving(true);
      Velocity(
        el,
        {
          translateX: [finalVal, '0%'],
        },
        {
          easing: 'easeInOutQuint',
          duration: 600,
          complete: () => {
            this.sidebarStore.setIsLeaving(false);
            done();
          },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bixeSidebarContainer {
  &.sidebarHidden {
    display: none;
  }
}

.bixeSidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  :deep(.pane) {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    top: 0;
    left: 0;
    bottom: 0;
    width: rem($sidebar-width);
    overflow-y: auto;
    overflow-x: hidden;
    @include no-scrollbars;
    background: var(--c__sidebar-bg);

    @include dark-mode('neon') {
      background: var(--grad__sidebar);
    }
  }

  .sidebar {
    border-left: 1px solid var(--c__edges);
    width: rem($sidebar-width);
    transform: translateX(100%);
    transition: transform $sidebar-duration $sidebar-ease-general;
    height: calc(100vh - #{$header-height});
    height: calc(var(--vh, 1vh) * 100 - #{$header-height});
    position: absolute;
    right: 0;
    top: $header-height;
    z-index: 100;
    display: flex;
    overflow: hidden;

    .sidebar-open &,
    .sidebar-opening & {
      transform: translateX(0%);
    }

    .sidebar-opening & {
      transition: transform $sidebar-duration $sidebar-ease-enter;
    }

    .sidebar-closing & {
      transition: transform $sidebar-duration $sidebar-ease-exit;
    }
  }

  &.bixe-sidebar-collapsed {
    .sidebar {
      width: 0;
    }
  }

  &.bixe-sidebar-collapsed {
    :deep(.pane) {
      width: 0;
    }
  }
}

.sidebarContent:focus {
  outline: none;
}

:deep(.bottomButtonContainer) {
  position: absolute;
  bottom: 0;
  height: rem($log-height);
  width: 100%;

  button {
    position: absolute;
    bottom: 0;
    height: rem($log-height);
  }

  .loadingSpinner {
    margin-left: 1em;
  }
}

:deep(.agendaSidebar) {
  .flipContent {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    @include no-scrollbars;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // padding-bottom: rem(150px);
  }

  .po3Header {
    width: rem(28px);
    height: rem(26px);
    margin: rem(88px) 0 0 rem($sidebar-left-pad);
  }

  .purpose.truncated {
    margin-bottom: 3em;
  }

  .name {
    margin-top: rem(24px);

    h2 {
      font-weight: bold;
    }

    h2,
    .sizer {
      @include type-size('medium');
      line-height: 1;
      padding: rem(2px) 0;
    }

    .isEditing {
      top: 0;
    }
  }

  .setMeetingTime {
    margin-top: auto;
    padding-top: rem(25px);
    flex: 999 1 auto;
  }

  .sidebarTimer {
    flex: 999 0 auto;
  }
}

.debuggy {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(black, 0.9);
  color: white;
  padding: 1em;
  width: 18em;
  z-index: 9999;
  border-radius: 0 0 1em 0;
}

.sidebar.microskills-showing::after {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: rem(80px);
  background: linear-gradient(to bottom, var(--c__bg-100) 0px, var(--c__bg-100) 80%, var(--c__bg-0) 100%);
  display: block;
}
</style>
