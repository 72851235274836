<template>
  <edit-sidebar class="editProposal">
    <div class="part">
      <h2>Proposal</h2>
      <form @submit.prevent @keyup.enter.exact="saveAndReturn">
        <app-auto-textarea :modelValue="field" late-focus="true" placeholder="Add proposal..." :limit="255" @update:modelValue="handleInput" />
        <app-text-limit-message :limit="255" :warn-limit="200" :value="field" />
      </form>
      <div class="actions btn-pair">
        <button type="button" @click.prevent="cancel">Cancel</button>
        <button type="button" @click.prevent="saveAndReturn">Save</button>
      </div>
    </div>
  </edit-sidebar>
</template>

<script>
import AppAutoTextarea from '@/components/AppAutoTextarea.vue';
import AppTextLimitMessage from '@/components/AppTextLimitMessage';
import EditSidebar from '@/components/EditSidebar.vue';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';

export default {
  components: {
    AppAutoTextarea,
    AppTextLimitMessage,
    EditSidebar,
  },
  setup() {
    const phaseStore = usePhaseStore();
    const sidebarStore = useSidebarStore();
    return { phaseStore, sidebarStore };
  },
  computed: {
    fieldContent() {
      return this.phaseStore.proposal;
    },
  },
  data() {
    return {
      field: '',
    };
  },
  watch: {
    fieldContent: {
      immediate: true,
      handler(nv) {
        this.field = nv;
      },
    },
  },
  methods: {
    handleInput(e) {
      this.field = e;
    },
    saveAndReturn() {
      const id = this.phaseStore.proposalId || null;
      this.$meetingmanager.updateProposal({ id, proposal: this.field || '' });
      this.$nextTick(() => {
        this.sidebarStore.finishedWithPane();
      });
    },
    cancel() {
      this.sidebarStore.finishedWithPane();
    },
  },
};
</script>
