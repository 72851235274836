<template>
  <div class="skillBar microskillSidebar endMeeting misc phase pane">
    <div class="endSkill">
      <div class="info">
        <h2>Meeting finished&hellip;</h2>
        <p>Your meeting has now finished and this space gives you 30 seconds to say goodbye to your colleagues. See you again soon!</p>
      </div>

      <timer-disc
        v-bind="$attrs"
        key="phase-timer"
        type="phase"
        showing="true"
        :current="timeStore.phaseElapsed"
        :total="timeStore.phaseLength"
        :passed-setting-time="timeStore.phaseSettingTime"
        :remaining="timeStore.phaseRemaining"
        :remote-setting-time="timeStore.phaseSettingTime"
      />

      <microskill-sidebar-buttons key="buttons" />
    </div>
  </div>
</template>

<script setup>
import { useTimeStore } from '@/store/pinia/time';
import TimerDisc from '@/components/TimerDisc.vue';
import MicroskillSidebarButtons from '@/components/MicroskillSidebarButtons';
const timeStore = useTimeStore();
</script>
<style lang="scss" scoped>
.skillBar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: rem(83px);
  z-index: 5;
  width: rem($sidebar-width);
  @include no-scrollbars;
}
.endSkill {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding-bottom: rem(28px);
  .info {
    h2 {
      margin: rem(94px) rem(34px) rem(20px) rem(34px);
      @include type-size(medium);
      font-weight: bold;
    }
    p {
      margin: rem(20px) rem(34px) 0 rem(34px);
    }
  }
}
</style>
