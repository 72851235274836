<template>
  <div class="subContent">
    <h2 key="myRoomHeader">Personal Room</h2>

    <div key="myRoom" class="content myRoomContent">
      <div class="controls">
        <div class="btn-pair btn-pair-bold">
          <a id="myRoomEmail" class="btn" :href="mailToRoom"><span class="text">Send email</span></a>
          <button ref="copyRoomBtn" type="button" @click="onInvitePRLink">
            <span class="text">Copy link</span>
          </button>

          <transition name="basic">
            <span v-if="successMsg" class="copied">Copied!</span>
          </transition>
        </div>
        <button id="startRoomMeeting" class="start btn solo btn-bold btn-border" @click.prevent="roomGo">
          <span v-if="isCreateRequestInProgress"><app-loading-spinner /></span>
          <span v-else class="text">Start meeting in my room</span>
        </button>
      </div>
      <p>
        Your personal room number : <span id="roomNumber">{{ roomDisplayNumber }}</span>
      </p>
      <p>
        Room password : <router-link to="/room-password">{{ roomPassword }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import emailString from '@/resources/emails';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import _ from 'underscore';
import utils from '../../resources/utils';

export default {
  props: ['pageName'],
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      meeting: null,
      successMsg: false,
      isCreateRequestInProgress: false,
    };
  },
  components: {
    AppLoadingSpinner,
  },
  created() {
    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'checkIsOnLatestVersion' });
    }
  },
  mounted() {
    this.pageStore.update({ pageName: this.pageName });
  },
  computed: {
    roomNumber() {
      return this.accountStore.currentUser.pin_number;
    },
    roomPassword() {
      return this.accountStore.currentUser.pin_pass;
    },
    roomUrl() {
      const origin = window.location.origin;
      return `${origin}${this.accountStore.baseUrl}/room/${this.roomNumber}?pwd=${this.roomPassword}`;
    },
    roomDisplayNumber() {
      let str = this.roomNumber + '';
      return str[0] + str[1] + str[2] + ' ' + str[3] + str[4] + str[5] + ' ' + str[6] + str[7] + str[8];
    },
    mailToRoom() {
      return emailString('room', {
        roomUrl: this.roomUrl,
        roomNumber: this.roomNumber,
        roomPassword: this.roomPassword,
        baseUrl: this.accountStore.baseUrl,
      });
    },
  },
  beforeUnmount() {
    this.isCreateRequestInProgress = false;
  },
  methods: {
    roomGo() {
      if (this.isCreateRequestInProgress) {
        return;
      }
      this.isCreateRequestInProgress = true;
      const currentUser = this.accountStore.currentUser;
      const params = { pinNumber: currentUser.pin_number, password: currentUser.pin_pass };
      utils.genericFns
        .joinPersonalRoomMeeting(params)
        .then((meeting) => {
          this.isCreateRequestInProgress = false;
          utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
          this.$router.push(`/meeting/${meeting.id}`).catch(() => null);
        })
        .catch(() => {
          this.$ee.emit('nM:inlineMessage:wrongMeetingInfo');
          this.isCreateRequestInProgress = false;
        });
    },
    onInvitePRLink() {
      utils.clipBoard.addItem(this.roomUrl);
      this.success();
    },
    success() {
      this.successMsg = true;
      this.$refs.copyRoomBtn.blur();

      _.delay(() => {
        this.successMsg = false;
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.controls {
  margin-bottom: rem(29px);
}
p {
  margin: 0;
}
</style>
