let subjects = {
  scheduled: 'bixe Meeting Invite',
  quickstart: 'bixe Meeting Invite',
  roomStarted: 'bixe Meeting Room Invite',
  room: 'bixe Meeting Room Invite',
};

let bodyTexts = {
  scheduled: (m) => {
    let text = `You have been invited to a scheduled bixe meeting.`;

    if (m.name) {
      text += `\n\nMeeting Name: ${m.name}`;
    }

    if (m.startDateString === m.endDateString) {
      text += `\n\n${m.startDateString}\n${m.startTimeString + '-' + m.endTimeString}  ${m.timezoneOffset}`;
    } else {
      text += `\n\n${m.startDateString} ${m.startTimeString} to\n ${m.endDateString} ${m.endTimeString} ${m.timezoneOffset}`;
    }

    if (m.purpose) {
      text += `\n\nPurpose: ${m.purpose}`;
    }

    if (m.objectives && m.objectives.length > 0) {
      text += `\n\nObjectives: \n- ${m.objectives.join('\n- ')}`;
    }

    text += `\n\nMeeting link: ${window.location.origin}${m.baseUrl}/meeting/${m.meetingId}?pwd=${m.pinPass}`;
    text += `\nMeeting number: ${m.pinNumber}`;
    text += `\nPassword: ${m.pinPass}`;

    return text;
  },
  quickstart: (m) => {
    let text = 'Please meet me in bixe.';
    text += `\n\n\nPlease use the link below:`;
    text += `\n\nMeeting link: ${window.location.origin}${m.baseUrl}/meeting/${m.meetingId}?pwd=${m.pinPass}`;
    text += `\nMeeting number: ${m.pinNumber}`;
    text += `\nPassword: ${m.pinPass}`;
    return text;
  },
  roomStarted: (m) => {
    let text = 'Please meet me in my bixe meeting room.';
    text += `\n\n\nPlease use the link below:`;
    text += `\n\nMeeting link: ${m.inviteLink}`;
    text += `\nPersonal room number: ${m.prInviteDetails.pin}`;
    text += `\nPassword: ${m.prInviteDetails.pass}`;
    return text;
  },
  room: (m) => {
    let text = `Please meet me in my bixe meeting room.`;
    text += `\n\n\nPlease use the link below:`;
    text += `\n\nMeeting link: ${m.roomUrl}`;
    text += `\nPersonal room number: ${m.roomNumber}`;
    text += `\nPassword: ${m.roomPassword}`;
    return text;
  },
};

export default (type, meeting, fake) => {
  let subject = subjects[type];
  let body = bodyTexts[type];
  return fake
    ? `mailto:?Subject=${subject}&Body=${body(meeting)}`
    : `mailto:?Subject=${subject ? encodeURIComponent(subject) : ''}&Body=${body ? encodeURIComponent(body(meeting)) : ''}`;
};
