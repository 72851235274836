<template>
  <transition name="bubble" v-on:afterEnter="here" @afterLeave="gone">
    <div class="bubbleHolder" :class="[direction, name]" v-if="show">
      <div ref="cont" class="bubbleTip" v-click-outside="close">
        <div class="content">
          <div class="message">
            <slot />
          </div>
          <div v-if="name === 'invite'" class="buttons btn-pair">
            <a v-if="isOpenedWithinElectronShell" href="#" @click="openExternal(meetingEmailString)">Send Email</a>
            <a v-else :href="meetingEmailString" target="_blank" rel="noopener">Send Email</a>
            <button ref="copyBtn" type="button" @click="meetingInviteLinkCloned()">
              <span class="text">Copy link</span>
              <transition name="basic">
                <span v-if="successMsg" class="copied">Copied!</span>
              </transition>
            </button>
          </div>
          <div v-if="buttons" class="buttons btn-pair">
            <button v-for="(button, i) in buttons" @click="handleClick(button.event)" :key="'button-' + i">
              {{ button.text }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import _ from 'underscore';
import { ref, computed, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useAccountStore } from '@/store/pinia/account';
import emailString from '@/resources/emails';
import utils from '../resources/utils';

const $API = getCurrentInstance().appContext.config.globalProperties.$API;

defineProps({
  name: {},
  show: { default: false },
  direction: { default: 'above ' },
  buttons: { default: false },
});

const emit = defineEmits(['button', 'close']);

const accountStore = useAccountStore();
const meetingStore = useMeetingStore();

const visible = ref(false);
const successMsg = ref(false);
const isOpenedWithinElectronShell = utils.isOpenedWithinElectronShell();
const copyBtn = ref(null);

const { meetingUrl, personalRoomUrl, meetingId, isScheduled, isRoom, emailInfo } = storeToRefs(meetingStore);
const { baseUrl } = storeToRefs(accountStore);

const meetingEmailString = computed(() => {
  let text;
  if (isScheduled.value) {
    text = emailString('scheduled', { ...emailInfo.value, baseUrl: baseUrl.value });
  } else if (isRoom.value) {
    text = emailString('roomStarted', { ...emailInfo.value, baseUrl: baseUrl.value, inviteLink: personalRoomUrl.value });
  } else {
    text = emailString('quickstart', { ...emailInfo.value, baseUrl: baseUrl.value });
  }
  return text;
});

function handleClick(ev) {
  emit('button', ev);
  close();
}
function close() {
  if (visible.value) {
    emit('close');
  }
}
function here() {
  visible.value = true;
}
function gone() {
  visible.value = false;
}
function openExternal(url) {
  ipcApi.send({ name: 'openExternal', url: url, closeSelf: false });
}
async function meetingInviteLinkCloned() {
  const inviteLink = isRoom.value ? personalRoomUrl.value : meetingUrl.value;
  utils.clipBoard.addItem(inviteLink);
  copySuccess();
  await $API.insertMeetingStats(meetingId.value, { action: 'menu_copy_invite_used', source: 'meetingSidebar' });
}
function copySuccess() {
  successMsg.value = true;
  copyBtn.value.blur();
  _.delay(() => {
    successMsg.value = false;
  }, 3000);
}
</script>
<style lang="scss" scoped>
$bubble-tail-length: 18px;
$bubble-tail-width: 48px;
$bubble-tail-length-small: 6px;
$bubble-tail-width-small: 16px;

.bubbleHolder {
  position: absolute;
  height: 1px;
  width: 1px;
  z-index: 1;

  &.above {
    top: 0;
    left: 50%;
  }

  &.below {
    bottom: 0;
    left: 50%;
  }
}

.bubble-enter-active {
  transition: transform 0.3s cubic-bezier(0, 0.5, 0.5, 1);
}

.bubble-leave-active {
  transition: transform 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.bubble-enter-from,
.bubble-leave-to {
  transform: scale(0);
}

:deep(.bubbleTip) {
  background: var(--c__bg);
  filter: drop-shadow(0px 1px 10px var(--c__shadow));
  position: absolute;
  padding: rem(28px);
  width: rem($sidebar-width);
  margin-left: rem($sidebar-width * -0.5);
  transition: transform 10.5s ease;

  will-change: filter;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; /* Chrome and Safari */
  -moz-backface-visibility: hidden; /* Firefox */

  h2 {
    font-weight: 800;
  }

  &::after {
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    left: 50%;
    margin-left: rem(math.div($bubble-tail-width, -2));
  }

  .below & {
    top: rem($bubble-tail-length);
    transform-origin: 50% rem(0 - $bubble-tail-length) !important;
    text-align: center;
    &::after {
      border-top: rem($bubble-tail-length) solid transparent;
      border-bottom: rem($bubble-tail-length) solid var(--c__bg);
      border-left: rem(math.div($bubble-tail-width, 2)) solid transparent;
      border-right: rem(math.div($bubble-tail-width, 2)) solid transparent;
      top: rem(0 - ($bubble-tail-length * 2));
      margin-top: 1px;
    }
  }

  .above & {
    bottom: rem($bubble-tail-length);
    transform-origin: 50% rem(0 - $bubble-tail-length) !important;
    text-align: center;
    &::after {
      border-bottom: rem($bubble-tail-length) solid transparent;
      border-top: rem($bubble-tail-length) solid var(--c__bg);
      border-left: rem(math.div($bubble-tail-width, 2)) solid transparent;
      border-right: rem(math.div($bubble-tail-width, 2)) solid transparent;
      bottom: rem(0 - ($bubble-tail-length * 2));
      margin-bottom: 1px;
    }
  }

  .rightTail & {
    margin-left: rem(($sidebar-width * -0.5) - 57px);
    &::after {
      margin-left: rem(math.div($bubble-tail-width, -2) + 57px);
    }
  }

  .small.leftTail & {
    padding: rem(10px);
    width: auto;
    margin-left: rem(math.div($bubble-tail-width-small, -2));
    bottom: rem($bubble-tail-length-small);
    transform-origin: 50% rem(0 - $bubble-tail-length-small) !important;
    &::after {
      left: 0;
      margin-left: 0;
      border-bottom: rem($bubble-tail-length-small) solid transparent;
      border-top: rem($bubble-tail-length-small) solid var(--c__bg);
      border-left: rem(math.div($bubble-tail-width-small, 2)) solid transparent;
      border-right: rem(math.div($bubble-tail-width-small, 2)) solid transparent;
      bottom: rem(0 - ($bubble-tail-length-small * 2));
    }
  }

  .buttons {
    margin-top: 1em;
    height: rem(50px);
    border-width: 2px;
    --c__button: var(--c__text-loud);
    a,
    button,
    .copied {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      @include triggered {
        .text {
          text-decoration: none;
        }
      }
    }
    .text {
      margin: 0;
    }
  }

  .copied {
    position: absolute;
    background: var(--c__bg);
    color: var(--c__success);
    line-height: rem(33px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.warn .message,
.invite .invite .message,
.reviewBubble .message {
  @include type-size('medium');
}

.invite .invite .message {
  font-weight: bold;
}

.warn .buttons,
.exit .warn .buttons {
  --c__button: var(--c__warn);
}
</style>
