import Mousetrap from 'mousetrap';
import { eventEmitter } from '@/services/event-emitter.service';
import _ from 'underscore';

let helpOverlay = null;
let helpShowing = false;
let spaceDown = false;
let pushingToTalk = false;
let isMeeting = false;

const sequences = {
  '?': {
    e: 'keyboard:showHelp',
    desc: 'show/hide help',
  },
  'g s': {
    e: 'showSettings',
    desc: '<strong>g</strong>o to <strong>s</strong>ettings',
  },
  'm a': {
    e: 'toggleMute',
    desc: 'toggle <strong>m</strong>ute <strong>a</strong>udio',
    args: { viaShortKey: true },
  },
  'm v': {
    e: 'toggleMuteVideo',
    desc: 'toggle <strong>m</strong>ute <strong>v</strong>ideo',
    args: { viaShortKey: true },
  },
  'e !': {
    isEmote: true,
    e: 'emote:key',
    args: 'eek',
    secret: true,
  },
  'e ?': {
    isEmote: true,
    e: 'emote:key',
    args: 'what',
    secret: true,
  },
  'e i': {
    isEmote: true,
    e: 'emote:key',
    args: 'eek',
    desc: '<strong>e</strong>mote: <strong>!</strong>',
  },
  'e q': {
    isEmote: true,
    e: 'emote:key',
    args: 'what',
    desc: '<strong>e</strong>mote: <strong>?</strong>',
  },
  'e y': {
    isEmote: true,
    e: 'emote:key',
    args: 'yes',
    desc: '<strong>e</strong>mote: <strong>y</strong>es',
  },
  'e n': {
    isEmote: true,
    e: 'emote:key',
    args: 'no',
    desc: '<strong>e</strong>mote: <strong>n</strong>o',
  },
  'e h': {
    isEmote: true,
    e: 'emote:key',
    args: 'smile',
    desc: '<strong>e</strong>mote: <strong>h</strong>appy',
  },
  'e a': {
    isEmote: true,
    e: 'emote:key',
    args: 'grr',
    desc: '<strong>e</strong>mote: <strong>a</strong>ngry',
  },
  'e s': {
    isEmote: true,
    e: 'emote:key',
    args: 'sad',
    desc: '<strong>e</strong>mote: <strong>s</strong>ad',
  },
  'e l': {
    isEmote: true,
    e: 'emote:key',
    args: 'heart',
    desc: '<strong>e</strong>mote: <strong>l</strong>ove',
  },
  'e *': {
    isEmote: true,
    e: 'emote:key',
    args: 'star',
    desc: '<strong>e</strong>mote: star',
    secret: true,
  },
  'e x': {
    isEmote: true,
    e: 'emote:key',
    args: 'star',
    desc: '<strong>e</strong>mote: star',
    secret: true,
  },
  'e u': {
    isEmote: true,
    e: 'emote:key',
    args: 'uhh',
    desc: '<strong>e</strong>mote: uhh',
    secret: true,
  },
  'e c': {
    isEmote: true,
    e: 'emote:key',
    args: 'confetti',
    desc: '<strong>e</strong>mote: confetti',
    secret: true,
  },
  'e w': {
    isEmote: true,
    e: 'emote:key',
    args: 'wave',
    desc: '<strong>e</strong>mote: wave',
    secret: true,
  },
  'e r': {
    isEmote: true,
    e: 'emote:key',
    args: 'roll',
    desc: '<strong>e</strong>mote: rolling',
    secret: true,
  },
  'e t': {
    isEmote: true,
    e: 'emote:key',
    args: 'thoughtful',
    desc: '<strong>e</strong>mote: thoughtful',
    secret: true,
  },
  's u n d o w n': {
    e: 'darkmode',
    desc: 'turn on dark mode',
    secret: true,
  },
  's u n r i s e': {
    e: 'unDarkmode',
    desc: 'turn off dark mode',
    secret: true,
  },
  'c h a n g e': {
    e: 'toggleCustomGroups',
    desc: 'turn on experimental custom groups mode',
    secret: true,
  },
  'm a p': {
    e: 'toggleDialogueMap',
    desc: 'turn on Mapping',
    secret: true,
  },
  't a l k i n g': {
    e: 'toggleShowTalking',
    desc: 'toggle offscreen talking indicators',
    secret: true,
  },
  'a g e n t s m i t h': {
    e: 'toggleSandbox',
    desc: 'turn on experimental sandbox',
    secret: true,
  },
  'b u g g y': {
    e: 'toggleSentry',
    desc: 'Show the Sentry form',
    secret: true,
  },
  't h e m e s': {
    e: 'toggleThemes',
    desc: 'Show the theme switcher',
    secret: true,
  },
  'h u': {
    e: 'toggleHand',
    desc: 'raise hand',
    secret: true,
  },
  'h d': {
    e: 'handDown',
    desc: 'lower hand',
    secret: true,
  },
  'w i f i s p i d e r': {
    e: 'toggleConnectivityIndicator',
    desc: 'show numerical connectivity indicator',
    secret: true,
  },
  's h o w d e b u g': {
    e: 'toggleDebug',
    desc: 'Show debug info',
    secret: true,
  },
  'd i a l o g u e': {
    e: 'toggleDialogue',
    desc: 'Toggle dialogue phase',
    secret: true,
  },
  'f e e d b a c k': {
    e: 'toggleFeedback',
    desc: 'Toggle feedback phase',
    secret: true,
  },
  's h o w h o s t': {
    e: 'showHostInfo',
    desc: 'Show host info',
    secret: true,
  },
  'd e v c o n s o l e': {
    e: 'toggleDevConsole',
    desc: 'Toggle permission to open close dev tools in electron app',
    secret: true,
  },
  's e t t i n g s': {
    e: 'toggleExtraSettings',
    desc: 'Toggle extra dev settngs',
    secret: true,
  },
  'l o l': {
    e: 'test',
    desc: '',
    secret: true,
  },
};

let emoteList = '';

function updatePage({ pageName }) {
  if (pageName === 'meetingFrame') {
    isMeeting = true;
  } else {
    isMeeting = false;
    if (helpShowing) {
      hideHelp();
    }
  }
}

function toggleHelp() {
  if (helpShowing) {
    hideHelp();
  } else {
    showHelp();
  }
}

function showHelp() {
  if (isMeeting) {
    helpOverlay.style.display = 'none';
    document.body.appendChild(helpOverlay);
    Velocity(helpOverlay, 'transition.fadeIn', {
      duration: 300,
      display: 'flex',
    });
    helpShowing = true;
  }
}

function hideHelp() {
  Velocity(helpOverlay, 'transition.fadeOut', {
    duration: 300,
    display: 'none',
    complete: () => {
      document.body.removeChild(helpOverlay);
    },
  });
  helpShowing = false;
}

function checkSpace() {
  if (!pushingToTalk && spaceDown) {
    if (isMeeting) {
      eventEmitter.emit('startTalking');
    }
    pushingToTalk = true;
  }
}

function muteKeyUp() {
  if (pushingToTalk && spaceDown) {
    if (isMeeting) {
      eventEmitter.emit('stopTalking');
    }
    pushingToTalk = false;
  }
  spaceDown = false;
}

function muteKeyDownRaw() {
  if (!spaceDown) {
    spaceDown = true;
    _.delay(() => {
      if (spaceDown) {
        checkSpace();
      }
    }, 250);
  }
}

const muteKeyDown = _.throttle(muteKeyDownRaw, 1000, { trailing: false });

function windowBlur() {
  muteKeyUp();
}

function windowFocus() {
  muteKeyUp();
}

export default {
  init: () => {
    for (const [sequence, a] of Object.entries(sequences)) {
      const { e, desc, secret, args } = a;
      Mousetrap.bind(sequence, () => {
        eventEmitter.emit('nM:' + e, args);
      });

      Mousetrap.bind(sequence.toUpperCase(), () => {
        eventEmitter.emit('nM:' + e, args);
      });

      if (!secret) {
        emoteList += `<li><code>${sequence}</code> <span class="desc">${desc}</li>`;
      }
    }

    // const throttledSpaceKeyDown = _.throttle(muteKeyDown, 500)

    helpOverlay = document.createElement('div');
    helpOverlay.classList.add('helpOverlay');
    helpOverlay.innerHTML = `<div class="help"><h2>Keyboard Shortcuts</h2><ul>${emoteList}</ul></div>`;

    helpOverlay.addEventListener('click', hideHelp);

    Mousetrap.bind('space', muteKeyDown, 'keydown');
    Mousetrap.bind('space', muteKeyUp, 'keyup');

    eventEmitter.on('nM:keyboard:showHelp', toggleHelp);
    eventEmitter.on('nM:updatePage', updatePage);

    window.addEventListener('blur', windowBlur);
    window.addEventListener('focus', windowFocus);
  },
};
