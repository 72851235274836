<template>
  <please-use :class="pageName" :data-page-name="pageName">
    <h1>Not available on Safari</h1>
    <p>We are working hard to get bixe running across all devices but for the moment please use Google Chrome (or Edge).</p>
    <div class="actions">
      <a class="btn btn-border btn-light" href="https://www.google.com/chrome/browser/desktop/index.html">Download Chrome</a>
      <a class="btn btn-border btn-light" href="https://www.microsoft.com/en-us/edge ">Download Edge</a>
    </div>
  </please-use>
</template>

<script>
import PleaseUse from '@/components/PleaseUse';
import { usePageStore } from '@/store/pinia/page';
export default {
  components: {
    PleaseUse,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
};
</script>
<style lang="scss" scoped>
p {
  max-width: 23em;
}
.actions {
  width: 100%;
  display: flex;
  justify-content: center;
  a.btn {
    color: white;
    border: 1px solid white;
    margin-left: rem(11px);
    margin-right: rem(11px);
    @include triggered {
      background: white;
      color: black;
    }
  }
}
</style>
