<template>
  <li v-if="meeting" class="meetingListing" :class="['status-' + meeting.status]">
    <p class="title">{{ title }}</p>
    <p class="time">{{ starts }}{{ ends }} on {{ date }}</p>
    <button class="go" @click.prevent="go" v-if="canGo">Go to Meeting</button>
  </li>
</template>

<script>
import { nM } from '@/legacy';
import utils from '../resources/utils';

export default {
  props: ['meeting'],
  computed: {
    title() {
      return this.meeting.name ? utils.desanitizeString(this.meeting.name) : 'no title';
    },
    purpose() {
      return this.meeting.purpose;
    },
    type() {
      return this.meeting.step ? 'scheduled' : 'quickstart';
    },
    startDate() {
      return new Date(this.meeting.starts_on * 1000);
    },
    endDate() {
      return new Date(this.meeting.ends_on * 1000);
    },
    date() {
      return Intl.DateTimeFormat('en-uk', { dateStyle: 'medium' }).format(this.startDate);
    },
    starts() {
      return Intl.DateTimeFormat('en-uk', { timeStyle: 'short' }).format(this.startDate);
    },
    ends() {
      return this.meeting.ends_on ? '-' + Intl.DateTimeFormat('en-uk', { timeStyle: 'short' }).format(this.endDate) : '';
    },
    finished() {
      return this.meeting.status === 'finished';
    },
    label() {
      if (this.finished) {
        return 'finished';
      } else if (!['ready', 'started'].includes(this.meeting.status) && this.meeting.type === 'scheduled') {
        return 'scheduling';
      } else {
        return this.meeting.type;
      }
    },
    canGo() {
      return this.meeting && ['ready', 'pending', 'started'].includes(this.meeting.status) && !this.finished;
    },
  },
  methods: {
    go() {
      this.$router.push('/meeting/' + this.meeting.id).catch(() => null);
    },
    deleteMeeting() {
      this.$API.deleteMeeting(this.meeting.id).catch((err) => {
        this.$ee.emit('api:alertAdd', {
          type: 'misc',
          class: 'error',
          duration: 'temporary',
          content: nM.toErrorMessage(err),
        });
      });
    },
    timeFromStamp(UNIX_timestamp) {
      const a = new Date(UNIX_timestamp * 1000);
      let hour = a.getHours();
      let min = a.getMinutes();
      hour = hour < 10 ? '0' + hour : hour;
      min = min < 10 ? '0' + min : min;
      return hour + ':' + min;
    },
  },
};
</script>
