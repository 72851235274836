<template>
  <section class="part devicePart" :class="[type, type + 'On', type + deviceDirection]">
    <audio v-if="device === 'speaker'" id="alarmAudio" ref="tester">
      <source :src="accountStore.baseUrl + '/assets/audio/alarm.mp3'" />
      <source :src="accountStore.baseUrl + '/assets/audio/alarm.ogg'" />
    </audio>
    <h3 :class="[currentLabel ? 'on' : 'off']">
      <app-icon :icon="'connectivity-' + iconName" class="deviceIcon" />
      <span class="type visuallyHidden">{{ type }} {{ direction }}</span>
    </h3>
    <div v-click-outside="hideList" class="deviceRow">
      <p class="currentDevice" @click="showList = !showList">
        {{ currentLabel }}
      </p>
      <div class="devices">
        <button class="devicesToggle" :class="{ open: showList }" @click="showList = !showList">
          <span class="text visuallyHidden">Show {{ type }} devices</span>
          <app-icon icon="down-arrow" />
        </button>
        <transition name="basic">
          <ul v-if="showList" class="deviceList dropdown">
            <li v-for="(x, i) in devicesList" :key="'device-' + i">
              <button class="option" :class="[x.deviceId === currentDeviceId ? 'current' : '']" @click.prevent="send(x.deviceId, x.label)">
                {{ x.label.replace(/\s*\(.*?\)\s*/g, '') }}
              </button>
            </li>
            <li v-if="devicesList && currentId" class="turnOff">
              <button class="disable" @click.prevent="disable">Turn off {{ device }}</button>
            </li>
            <li v-if="!devicesList || devicesList.length === 0"><span class="noDevices">No devices available</span></li>
          </ul>
        </transition>
      </div>
      <div v-if="type === 'audio' && direction === 'in'" class="meter" id="audio-meter">
        <div id="audio-meter-level" class="bar" :style="{ width: volumeLevel * 100 + '%' }" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import AppIcon from '@/components/AppIcon.vue';
import utils from '@/resources/utils.js';
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import { useMediaStore } from '@/store/pinia/media';
import { useUsersStore } from '@/store/pinia/users';
import { useStorage } from 'vue3-storage';
const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  components: {
    AppIcon,
  },
  props: {
    type: {
      required: true,
    },
    currentId: {
      required: true,
    },
    on: {
      required: true,
    },
    direction: {
      default: 'in',
    },
    device: {
      required: true,
    },
  },
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  data() {
    return {
      showList: false,
      volumeLevel: 0,
      canChange: true,
      refreshInterval: null,
    };
  },
  mounted() {
    let _this = this;
    if (this.device === 'microphone') {
      this.$ee.on('api:setVolumeTestMeter', _this.setVolumeLevel);
    }
    if (this.device === 'speaker') {
      try {
        this.$refs.tester.setSinkId('default');
      } catch (error) {
        this.canChange = false;
      }
    }
  },
  destroy() {
    let _this = this;
    if (this.device === 'microphone') {
      this.$ee.off('api:setVolumeTestMeter', _this.setVolumeLevel);
    }
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['devices']),
    ...mapPiniaState(useUsersStore, ['iAmMuted', 'iAmSysMuted', 'me']),
    isConnectivityCheckPage() {
      return this.pageStore.current.pageName === 'connectivity';
    },
    deviceDirection() {
      return this.direction === 'in' ? 'Input' : 'Output';
    },
    iconName() {
      return this.direction === 'in' ? this.type : this.type + this.deviceDirection;
    },
    currentLabel() {
      return this.currentDevice ? this.currentDevice : `No ${this.device} selected`;
    },
    devicesList() {
      if (!this.canChange) {
        return [
          {
            deviceId: 'default',
            label: 'Default/Built-in output device',
          },
        ];
      } else {
        return this.devices
          .filter((x) => x.kind === this.type + this.deviceDirection)
          .map((d) => {
            return {
              deviceId: d.deviceId,
              label: utils.cleanDeviceLabel(d.label),
            };
          });
      }
    },
    currentDevice() {
      let n;
      if (!this.canChange) {
        n = this.devicesList.find((x) => {
          return x.deviceId === 'default';
        });
      } else {
        n = this.devicesList.find((x) => {
          return x.deviceId === this.currentId;
        });
      }

      if (!n) {
        return false;
      } else {
        return utils.cleanDeviceLabel(n.label);
      }
    },
    currentDeviceId() {
      if (!this.canChange) {
        return 'default';
      } else {
        return this.currentId;
      }
    },
  },
  watch: {
    on(nv) {
      if (this.type === 'audio' && !nv) {
        this.volumeLevel = 0;
      }
    },
  },
  methods: {
    send(deviceId) {
      this.$devicemanager.stopAudioStream();
      this.$emit('change', deviceId);
      if (this.type === 'audio') localStorage.setStorageSync('user:audioSource', deviceId);
      if (this.type === 'video') localStorage.setStorageSync('user:videoSource', deviceId);
      if (this.type === 'audioOutput') localStorage.setStorageSync('user:audioOutputSource', deviceId);
      this.hideList();
    },
    disable() {
      this.$emit('change', null);
      this.hideList();
    },
    setVolumeLevel(level) {
      if (this.on && (this.isConnectivityCheckPage || (this.me && !this.iAmMuted && !this.iAmSysMuted))) {
        this.volumeLevel = level;
      }
    },
    hideList() {
      this.showList = false;
    },
  },
};
</script>

<style lang="scss" scoped>
section.devicePart {
  width: auto;
  position: relative;
  height: rem(21px);
}

.devices {
  margin-bottom: 3em;
  position: absolute;
  z-index: 1;
  top: rem(8px);
  right: rem(-1px);
}

.currentDevice {
  @include type-size('x-small');
  line-height: div(15, 12);
  font-weight: 100;
  position: absolute;
  width: rem(355px);
  left: rem(44px);
  cursor: pointer;
  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to right, var(--c__bg-alt-0) 0%, var(--c__bg-alt-0) rem(130px), var(--c__bg-alt-100) rem(160px));
  }
}

.deviceIcon {
  position: absolute;
  left: 0;
  :deep(svg) {
    width: 100%;
    display: block;
  }
}

.videoInput {
  z-index: 4;
  .currentDevice {
    top: rem(2px);
  }
  .devices {
    top: rem(3px);
  }
  .deviceIcon {
    width: rem(23px);
    top: rem(5px);
    left: rem(1px);
  }
  .devicesToggle {
    top: rem(0px);
  }
}

.audioInput {
  z-index: 3;
  margin: rem(18px) 0 rem(19px) 0;
  .currentDevice {
    top: -1px;
  }
  .devices {
    top: rem(5px);
  }
  .deviceIcon {
    width: rem(15px);
    left: rem(3px);
    top: rem(-1.5px);
  }
  .devicesToggle {
    top: rem(0px);
  }
}
.meter {
  position: absolute;
  width: rem(171px);
  left: rem(44px);
  height: 2px;
  top: rem(20px);
  background: var(--c__placeholder-alt);
  .bar {
    height: 2px;
    background: var(--c__accent);
  }
}

.audioOutput {
  z-index: 2;
  .currentDevice {
    top: rem(3px);
  }
  .devices {
    top: rem(4px);
  }
  .deviceIcon {
    width: rem(23px);
    left: rem(2px);
    top: rem(1px);
  }
  .devicesToggle {
    top: rem(2px);
  }
}

.devicesToggle {
  z-index: 5;
  width: rem(21px);
  height: rem(12px);
  border: none;
  display: block;
  position: absolute;
  right: rem(2px);
  transform: rotateZ(0deg);
  transform-origin: center;
  transition: all 300ms cubic-bezier(0.45, 0, 0.55, 1);
  padding: 0;
  background: transparent;
  &.open {
    transform: rotateZ(180deg);
  }
  @include triggered {
    background: transparent;
  }
  svg {
    width: rem(26px);
    position: relative;
    top: 0;
    left: 0;
  }
}
.deviceList {
  transform-origin: 92% 18%;
  z-index: 4;
  list-style: none;
  top: rem(-16px);
  right: rem(-8px);
  padding-top: 0.5em;
  padding-right: rem(26px + 8px);
  width: rem(242px);
  button {
    @include type-size('tiny');
    padding: 0.5em;
    font-weight: 100;
    background: none;
    border: none;
    line-height: math.div(15, 12);
    text-align: left;
    &:hover,
    &:active,
    &:focus {
      color: var(--c__accent-alt);
    }
    &.current {
      color: var(--c__accent);
    }
  }
  li {
    padding-right: 0.5em;
  }
  li + li {
    margin-top: rem(15px);
  }
}
.turnOff {
  border-top: 1px solid var(--c__edges);
  margin-right: rem(-26px - 8px);
  margin-left: -0.5em;
  padding: 1em 0.5em 0.5em 0.5em;
}
.noDevices {
  display: inline-block;
  padding: 0.5em;
}
</style>
