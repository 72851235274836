<template>
  <table-frame class="endPages" :page-name="pageName" :table="table">
    <template #content>
      <slot></slot>
    </template>
    <template #actions>
      <div v-if="hasActions && actions" class="content actions">
        <div class="createButtons overButtons">
          <button v-if="accountStore.canCreate" class="endNew btn btn-border solo overButton" @click.prevent="quickstart">
            <span v-if="isCreateRequestInProgress">
              <app-loading-spinner />
            </span>
            <span v-else>Create a meeting</span>
          </button>
        </div>
      </div>
    </template>
  </table-frame>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import TableFrame from '@/components/TableFrame';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import utils from '../resources/utils';

export default {
  components: {
    TableFrame,
    AppLoadingSpinner,
  },
  props: {
    pageName: String,
    table: {
      default: 'end',
      type: String,
    },
    actions: {
      default: true,
      type: Boolean,
    },
  },
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
  data() {
    return {
      isCreateRequestInProgress: false,
    };
  },
  computed: {
    hasActions() {
      return this.accountStore.canCreate || (!this.accountStore.isGuest && this.accountStore.isLoggedIn);
    },
    actionClass() {
      return this.hasActions ? 'hasActions' : 'noActions';
    },
  },
  methods: {
    quickstart() {
      if (this.isCreateRequestInProgress) {
        return;
      }
      this.isCreateRequestInProgress = true;
      const isRoom = this.accountStore.preferredMeetingType === 'personalroom';
      let promise;
      if (isRoom) {
        const currentUser = this.accountStore.currentUser;
        const params = { pinNumber: currentUser.pin_number, password: currentUser.pin_pass };
        promise = utils.genericFns.joinPersonalRoomMeeting(params);
      } else {
        promise = utils.genericFns.quickStartMeeting();
      }
      promise
        .then((meeting) => {
          utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
          this.$router.push(`/quickstart/${meeting.id}`).catch(() => null);
          this.isCreateRequestInProgress = false;
        })
        .catch((err) => {
          this.isCreateRequestInProgress = false;
          this.setMeetings(err, null);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.createButtons {
  margin: 0;
  height: rem(150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  a + a {
    margin-top: auto;
  }
}

.hasActions {
  margin-bottom: rem(60px);
}

:deep(h2) {
  margin: auto 0 1em 0;
  padding-top: rem(32.5px);
}

:deep(&.noActions h2) {
  margin: auto 0;
}

:deep(.outerContent) {
  display: flex;
  text-align: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: rem(100px);
}
</style>
