<template>
  <section class="roomPassword page introPages" data-page-name="roomPassword">
    <header class="extra">
      <div class="content">
        <h2>Room password</h2>
        <form class="basicsForm" @submit.prevent="send" @keyup.enter.prevent="send">
          <transition-group name="basic">
            <div class="row" :class="{ hasError: error }" id="roomPasswordRow" key="pass">
              <label for="roomPassword" class="visuallyHidden">Room Password</label>
              <input
                id="roomPasswordField"
                ref="field"
                v-model.trim="password"
                type="text"
                name="meetingID"
                placeholder="Room Password..."
                @keyup="addTimeout"
                @blur="check"
              />
              <transition name="basic">
                <p v-if="error" class="error">
                  {{ error }}
                </p>
              </transition>
            </div>
            <div key="actions" class="btn-pair btn-pair-bold row">
              <button id="cancelRoomPassword" class="btn" @click.prevent="back">
                <span class="text">Cancel</span>
              </button>
              <button id="saveRoomPassword" class="btn" :disabled="disabled" @click.prevent="send">
                <span class="text">Save</span>
              </button>
            </div>
          </transition-group>
        </form>
      </div>
    </header>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';

import validate from 'validate.js';

export default {
  props: ['pageName'],
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      password: '',
      error: false,
      valid: false,
      timeout: null,
    };
  },
  mounted() {
    const roomPassword = this.accountStore.currentUser.pin_pass;
    this.pageStore.update({
      roomPassword,
      pageName: this.pageName,
    });
    this.password = roomPassword;
    this.$refs.field.focus();
    this.check();
  },
  computed: {
    disabled() {
      return this.error !== false;
    },
  },
  methods: {
    check() {
      let errors = validate.single(this.password, {
        presence: {
          message: 'Please fill out this field',
          allowEmpty: false,
        },
        length: {
          minimum: 6,
          maximum: 50,
          message: 'Password must be between 6 and 50 characters',
        },
        format: {
          pattern: '[a-z0-9]+',
          flags: 'i',
          message: 'Passwords cannot contain special characters.',
        },
      });
      if (errors) {
        this.error = errors[0];
      } else {
        this.error = false;
      }
    },
    addTimeout() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.check();
      }, 2000);
    },
    back() {
      this.$router.push('/my-room').catch(() => null);
    },
    send() {
      if (this.errors) {
        return false;
      } else {
        this.$API
          .updateRoomPassword(this.password)
          .then(() => {
            this.accountStore.updatePassword(this.password);
            this.$router.push('/my-room').catch(() => null);
            this.$ee.emit('api:alertAdd', {
              type: 'misc',
              class: 'success',
              duration: 'temporary',
              content: 'The room password has been updated',
            });
          })
          .catch(() => {
            this.$ee.emit('nM:inlineMessage:roomPasswordValidation');
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  background: var(--c__bg-alt);
  text-align: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: rem(100px);
}

.outerContent {
  width: 100%;
}

:deep(.content) {
  margin-bottom: rem(60px);
}

:deep(h2) {
  @include type-size(large);
  margin: auto 0 rem(35px) 0;
}

:deep(.solo),
:deep(.btn-pair) {
  width: rem(300px);
  flex: 0 0 auto;
}

:deep(.solo + .solo),
:deep(.btn-pair + .solo) {
  margin-top: rem(13px);
}

footer {
  margin: auto 0;
  min-height: 3em;
  p {
    margin-bottom: 0;
  }
}

.table {
  width: 100%;
  background-image: var(--bg__table-foyer);
}

:deep(.subContent) {
  margin-top: auto;
}
.basicsForm .row {
  margin: rem(48px) 0;
}
</style>
