const sizes = {
  s: {
    card: 4,
    font: 0,
  },
  m: {
    card: 6,
    font: 2,
  },
  l: {
    card: 9,
    font: 3,
  },
};

export default { sizes };
