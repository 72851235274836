<template>
  <div />
</template>

<script setup>
import _ from 'underscore';
import { ref, watch, onMounted, onBeforeUnmount, nextTick, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useMediaStore } from '@/store/pinia/media';
import { useMappingStore } from '@/store/pinia/mapping';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';
import { useSoloLayout } from '@/composables/layouts/useSoloLayout';
import { useRingLayout } from '@/composables/layouts/useRingLayout';
import { useFishbowlLayout } from '@/composables/layouts/useFishbowlLayout';
import { useCouchLayout } from '@/composables/layouts/useCouchLayout';
import { useLinesLayout } from '@/composables/layouts/useLinesLayout';
import { useGridLayout } from '@/composables/layouts/useGridLayout';
import { useVotingLayout } from '@/composables/layouts/useVotingLayout';
import { useChooseGroupsLayout } from '@/composables/layouts/useChooseGroupsLayout';

import utils from '@/resources/utils';

import { whichTransitionEvent } from '@/resources/css-events';
const transitionEvent = whichTransitionEvent();
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;
const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;

import { SOFA_LIMIT } from '@/resources/constants/frame-constants';

const meetingStore = useMeetingStore();
const messagesStore = useMessagesStore();
const mediaStore = useMediaStore();
const mappingStore = useMappingStore();
const featuresStore = useFeaturesStore();
const phaseStore = usePhaseStore();
const frameStore = useFrameStore();
const usersStore = useUsersStore();
const pageStore = usePageStore();

const { containerSet, contWidth, orientation, fullscreen, viewportH: h, viewportW: w } = storeToRefs(frameStore);
const { isAvatarsDockDrawer } = storeToRefs(pageStore);

const {
  usersVisibleList: users,
  allUsers: all,
  myId,
  groups,
  page,
  speaking,
  hide,
  iAmAway,
  iAmInWaitingRoom,
  iAmAwayInGroup,
  myGroup,
  debugLayout,
  userOnMyPage,
} = storeToRefs(usersStore);

const { currentPhase, setupPhase, votes, myVote, isPresentation, phaseStage, someoneIsMirroring } = storeToRefs(phaseStore);
const { space, spaceDetails, useNotes } = storeToRefs(meetingStore);
const { screenSharing } = storeToRefs(mediaStore);
const { showFull: showFullMap } = storeToRefs(mappingStore);
const { customGroups, noAnimations } = storeToRefs(featuresStore);

const newToPlace = ref([]);
const main = document.getElementById('main');

// Lifecycle Hooks
onMounted(() => {
  frameStore.onResize();
  $ee.on('nM:toggleSandbox', toggleSandbox);
  $ee.on('nM:sandboxUpdate', sandboxUpdate);
  $ee.on('nM:event:userAdded', newAdded);
  $meetingmanager.on('user:rejoin', onUserRejoin);
  transitionEvent && main.addEventListener(transitionEvent, containerUpdate);

  if (module.hot) {
    module.hot.dispose(onHmr);
  }
});

onBeforeUnmount(() => {
  $ee.off('nM:toggleSandbox', toggleSandbox);
  $ee.off('nM:sandboxUpdate', sandboxUpdate);
  $ee.off('nM:event:userAdded', newAdded);
  $meetingmanager.off('user:rejoin', onUserRejoin);
  transitionEvent && main && main.removeEventListener(transitionEvent, containerUpdate);

  if (module.hot) {
    module.hot.removeDisposeHandler(onHmr);
  }
});

// Import Layouts
const { soloLayout } = useSoloLayout();
const { ringLayout } = useRingLayout();
const { fishbowlLayout } = useFishbowlLayout();
const { couchLayout } = useCouchLayout();
const { lowRowLayout, contextLayout, reviewLayout, presentationLayout, sharingSidebarLayout, dialogueSidebarLayout, pagedRowsLayout, feedbackRowsLayout } =
  useLinesLayout();
const { gridLayout } = useGridLayout();
const { votedLayout } = useVotingLayout();
const { chooseGroupsLayout } = useChooseGroupsLayout();

function basicLayout(list) {
  list = list || users.value;
  if (list.length > 8) {
    return gridLayout();
  } else {
    return list.length <= SOFA_LIMIT ? couchLayout() : ringLayout();
  }
}

const layouts = {
  basic: basicLayout,
  couch: couchLayout,
  voted: votedLayout,
  review: reviewLayout,
  grid: gridLayout,
  ring: ringLayout,
  pagedRows: pagedRowsLayout,
  lowRow: lowRowLayout,
  fishbowl: fishbowlLayout,
  away: soloLayout,
  fullscreen: sharingSidebarLayout,
  input: presentationLayout,
  chooseGroups: chooseGroupsLayout,
  lobby: () => {
    if (users.value.length > 8) {
      return spaceDetails.value.lobbyStyle === 'rows' ? pagedRowsLayout() : gridLayout();
    } else {
      return pagedRowsLayout();
    }
  },
  context: () => (users.value.length <= SOFA_LIMIT ? couchLayout() : contextLayout()),
  decision: () => (phaseStage.value === 'voted' ? votedLayout() : lowRowLayout()),
  groups: () => {
    if (iAmAwayInGroup.value) {
      return basicLayout(myGroup.value);
    } else {
      return basicLayout(users.value);
    }
  },
  dialogue: () => {
    if (showFullMap.value) {
      return dialogueSidebarLayout();
    } else {
      if (phaseStage.value === 'topic') {
        return lowRowLayout();
      } else {
        if (users.value.length > 8) {
          return gridLayout();
        } else {
          return ringLayout();
        }
      }
    }
  },
  feedback: () => {
    if (phaseStage.value === 'topic') {
      return lowRowLayout();
    } else if (phaseStage.value === 'reviewing') {
      return reviewLayout();
    } else {
      if (users.value.length <= SOFA_LIMIT) {
        return couchLayout();
      } else if (users.value.length >= 10) {
        return feedbackRowsLayout();
      } else {
        return fishbowlLayout();
      }
    }
  },
  endMeeting: () => {
    if (users.value.length > 8) {
      return spaceDetails.value.lobbyStyle === 'rows' ? pagedRowsLayout() : gridLayout();
    } else {
      return pagedRowsLayout();
    }
  },
};

function getLayout() {
  if (!users.value || users.value.length < 1) {
    return { pos: false, details: false };
  }

  let check = ['freeform', 'setTime', 'checkIn', 'checkOut'].includes(currentPhase.value) ? 'basic' : currentPhase.value;

  if (setupPhase.value === 'groups' && groups.value.length > 1) {
    check = customGroups.value ? 'chooseGroups' : 'basic';
  }

  if (isPresentation.value) {
    check = 'input';
  }

  if (fullscreen.value && (currentPhase.value !== 'dialogue' || someoneIsMirroring.value)) {
    check = 'fullscreen';
  }

  if (iAmAway.value || iAmInWaitingRoom.value) {
    check = 'away';
  }

  if (isAvatarsDockDrawer.value) {
    check = 'fullscreen';
  }

  if (debugLayout.value) {
    check = debugLayout.value;
  }

  const currentLayout = layouts[check] ? layouts[check] : layouts.basic;

  const p = currentLayout();

  let details = layoutDetails(p);

  return { pos: p.pos, details };
}

function layoutDetails({ pos, layoutName, paged, extras, pages, groupAdds }) {
  let minX = false;
  let maxX = false;
  let minY = false;
  let maxY = false;
  let userCount = 0;
  let ids = [];

  Object.keys(pos).forEach((id) => {
    let p = pos[id];
    let top = p.top - p.w / 2;
    let bottom = p.top + p.w / 2;
    let left = p.left - p.w / 2;
    let right = p.left + p.w / 2;

    minX = minX ? Math.min(minX, left) : left;
    minY = minY ? Math.min(minY, top) : top;
    maxX = maxX ? Math.max(maxX, right) : right;
    maxY = maxY ? Math.max(maxY, bottom) : bottom;

    ids.push(id);
    userCount++;
  });

  return {
    all,
    userCount,
    ids,
    layoutName,
    paged,
    groupAddPositions: groupAdds || [],
    pages: paged ? pages : [[...users.value]],
    users: users.value,
    top: minY,
    left: minX,
    bottom: maxY,
    right: maxX,
    width: maxX - minX,
    height: maxY - minY,
    ...extras,
  };
}

function updateRaw(why) {
  if (containerSet.value) {
    nextTick(() => {
      let layout = getLayout();
      _.each(layout.pos, (l) => {
        l.why = why;
      });

      usersStore.updatePositions({
        ...layout,
        resize: why === 'resize' || why === 'resizeC',
        clearOffset: why === 'phase',
        why,
      });

      newToPlace.value.forEach((u) => {
        if (u.id !== myId.value && !userOnMyPage.value(u.id)) {
          messagesStore.addAlert('userAdded', { name: utils.desanitizeString(u.first_name + ' ' + u.last_name) });
        }
      });
      newToPlace.value = [];
      usersStore.clearNewUsers();
    });
  }
}

const update = _.debounce(updateRaw, 50);
const updateNextTick = (why) => {
  nextTick(update.bind(this, why));
};
const updateWithContainer = (why) => {
  frameStore.updateContainer();
  updateNextTick(why);
};

const onUserRejoin = updateNextTick.bind(this, 'userRejoined');
const sandboxUpdate = update.bind(this, 'sandbox');
function toggleSandbox() {
  featuresStore.toggleSandbox();
  update('sandbox');
}
function onHmr() {
  update('hmr');
}
function newAdded({ users }) {
  newToPlace.value = [...newToPlace.value, ...users];
}

function containerUpdate(e) {
  if (e.currentTarget !== e.target) {
    return;
  }
  frameStore.updateContainer();
  updateNextTick('resizeC');
}

// Watch all the things
watch(currentPhase, () => {
  usersStore.updatePage(0);

  frameStore.updateContainer();
  update('phase');
  _.delay(() => {
    update('phaseRedraw');
  }, 3000);
});

watch(groups, (nv, ov) => {
  if (typeof nv === 'undefined' && typeof ov === 'undefined') {
    return false;
  }
  update('groups');
});

watch(hide, (nv) => {
  if (nv) {
    updateRaw('hide');
  } else {
    update('hide');
  }
});

watch(fullscreen, (nv) => {
  if (!nv) {
    usersStore.showUsers();
  }
  frameStore.updateContainer();
  usersStore.updatePage(0);
  update('fullscreen');
});

watch(speaking, (nv, ov) => {
  if (nv && ov && nv.id !== ov.id) {
    update('speaking');
  }
});

//! check this
watch(votes, () => {
  if (currentPhase.value === 'decision') {
    update('votes');
  }
});

watch(myVote, () => {
  if (currentPhase.value === 'decision') {
    update('votes');
  }
});

watch(users, (nv, ov) => {
  if (nv.length !== ov.length) {
    if (currentPhase.value === 'decision') {
      updateNextTick('userCountV');
    } else {
      update('userCountV');
    }
  }
});

watch(all, (nv, ov) => {
  if (nv.length !== ov.length) {
    update('userCountA');
  }
  // Do it an extra time to fix errors
  _.delay(() => {
    update('userCountAExtra');
  }, 2000);
});

watch(useNotes, () => {
  _.delay(updateWithContainer.bind(this, 'notes'), 10);
});

watch(contWidth, () => {
  if (noAnimations) {
    _.delay(() => {
      nextTick(updateWithContainer.bind(this, 'container'));
    }, 10);
  } else {
    update('container');
  }
});

watch(h, updateWithContainer.bind(this, 'resize'));
watch(w, updateWithContainer.bind(this, 'resize'));
watch(orientation, updateWithContainer.bind(this, 'orientation'));
watch(page, update.bind(this, 'page'));
watch(phaseStage, update.bind(this, 'phaseStage'));
watch(isPresentation, update.bind(this, 'mirroring'));
watch(customGroups, update.bind(this, 'customGroups'));
watch(screenSharing, update.bind(this, 'screenshare'));
watch(space, update.bind(this, 'space'));
watch(showFullMap, update.bind(this, 'showFullMap'));
watch(debugLayout, update.bind(this, 'agentSmith'));
</script>
