import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';

export function usePxToRem() {
  const frameStore = useFrameStore();
  const { rem, baseRem } = storeToRefs(frameStore);
  function pxToRem(px) {
    return (px / baseRem.value) * rem.value;
  }

  return { pxToRem };
}
