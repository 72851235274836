import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { CIRCLE_PACK } from '@/resources/position-constants';
import * as geometry from '@/resources/geometry';

import { C_PAD } from '@/resources/constants/frame-constants';
import _ from 'underscore';

export function useChooseGroupsLayout() {
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();

  const { usersVisibleList: users, groups } = storeToRefs(usersStore);
  const { ringBasis, cY, cX } = storeToRefs(frameStore);

  const groupAddsPositions = ref([]);

  function chooseGroupsLayout() {
    const slotsLength = users.value.length + groups.value.length;
    const fullSpace = ringBasis.value / CIRCLE_PACK[slotsLength];
    const outerSpace = fullSpace - C_PAD;

    let pos = {};
    let groupAdds = [];

    let slotAngle = geometry.FULL_CIRCLE / slotsLength;

    let nudge = (slotAngle * groups.value[0].length) / 2 - slotAngle * 0.5;

    let slots = geometry.circle({
      angleNudge: users.value.length > 2 ? 0 - nudge : geometry.QUARTER_TURN * 3 - nudge,
      cX: cX.value,
      cY: cY.value,
      r: (ringBasis.value - outerSpace) / 2,
      count: slotsLength,
      order: _.range(slotsLength),
    });

    let groupAddSlots = geometry.circle({
      angleNudge: users.value.length > 2 ? 0 - nudge : geometry.QUARTER_TURN * 3 - nudge,
      cX: cX.value,
      cY: cY.value,
      r: (ringBasis.value - outerSpace * 3) / 2,
      count: slotsLength * 2,
      order: _.range(slotsLength * 2),
    });

    let slotCount = 0;
    let groupAddCount = 0;

    groups.value.forEach((group) => {
      let groupSlot = groupAddCount + group.length - 1;
      groupAdds.push(groupAddSlots[groupSlot]);
      groupAddCount += group.length * 2 + 2;

      group.forEach((id) => {
        pos[id] = { ...slots[slotCount] };
        slotCount++;
      });

      slotCount++;
    });

    users.value.forEach((id) => {
      pos[id].w = fullSpace;
    });

    return { pos, groupAdds, layoutName: 'chooseGroups' };
  }

  return { chooseGroupsLayout, groupAddsPositions };
}
