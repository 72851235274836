<template>
  <div class="confirmDeleteLog">
    <p>Are you sure you want to <strong>delete</strong> this moment?</p>
    <div class="actions">
      <button class="confirm btn btn-border btn-short btn-warn" @click="deleteLog">Delete</button>
      <button class="cancelDelete" @click="cancel">Cancel</button>
    </div>
  </div>
</template>

<script>
import { useMomentsStore } from '@/store/pinia/moments';

export default {
  props: ['id'],
  setup() {
    const momentsStore = useMomentsStore();
    return { momentsStore };
  },
  methods: {
    deleteLog() {
      this.$meetingmanager.deleteLog(this.id);
      this.momentsStore.stopEditing();
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
.confirmDeleteLog {
  background: var(--c__bg-alt);
  border-left: solid 0.5em var(--c__warn);
  z-index: 10;
  position: relative;
  margin-left: -1.5em;
  margin-right: -1em;
  padding: 1em;
  min-height: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  strong {
    color: var(--c__warn-loud);
  }
  .actions {
    display: flex;
    justify-content: flex-start;
    position: relative;
  }

  .confirm {
    @include type-size('tiny');
    flex: 0 0 auto;
    width: auto;
    padding: 0.5em 1em;
    margin-right: 1em;
  }

  .cancelDelete {
    color: var(--c__kinda-quiet);
    @include triggered {
      color: var(--c__accent);
    }
  }

  .groupedNotes & {
    margin-left: -4.2em;
    margin-right: -2em;
    padding-left: 2em;
  }
}
</style>
