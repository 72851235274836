<template>
  <exit-lobby class="meetingFull page endPages" data-page-name="meetingFull" :actions="false">
    <h2>Sorry, this meeting is full. Please contact the meeting owner to find out if you can join.</h2>
  </exit-lobby>
</template>

<script>
import ExitLobby from '@/components/ExitLobby';
import { usePageStore } from '@/store/pinia/page';

export default {
  components: {
    ExitLobby,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
};
</script>
<style lang="scss" scoped>
h2 {
  padding: 0 1em;
  max-width: 20em;
}
</style>
