<template>
  <section :class="[name, expanded ? 'expanded' : 'truncated']" class="infoSection">
    <div class="infoDisplayer" :class="[editedBySomeoneElse ? 'edited' : 'editable']">
      <h2 v-if="heading" class="infoHeading heading-small">{{ heading }}</h2>
      <!-- eslint-disable -->
      <h2 v-if="type === 'heading'" id="meetingTitle" ref="displayer" class="displayer meetingTitle para" :class="field ? 'hasContent' : 'pleaseSet'">
        {{ desanitizeString(displayText) }}
      </h2>

      <p v-if="type === 'paragraph'" class="displayer para" :class="field || optional ? 'hasContent' : 'pleaseSet'" ref="displayer">
        {{ desanitizeString(displayText) }}
      </p>

      <ul v-if="type === 'list'" class="displayer" ref="displayer" :class="sizingField.length > 0 ? 'hasContent' : 'pleaseSet'">
        <li v-for="(item, i) in displayText" :key="'item-' + i">
          <span class="text">{{ desanitizeString(item) }}</span>
        </li>
      </ul>
      <transition name="basic">
        <div class="isEditing" :class="size" v-if="editedBySomeoneElse && editedBy">
          <span class="text">{{ editedByName }} is editing</span><span class="elipHolder"><span class="elip">&hellip;</span></span>
        </div>
      </transition>
      <button class="edit" @click.prevent="askForEdit" :disabled="isDisabled">
        <span class="text">Edit {{ editName ? editName : name }}</span>
      </button>
    </div>
    <span class="sizer" :class="field ? 'floating' : ''" ref="sizer">&nbsp;</span>
  </section>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import utils from '../resources/utils';

export default {
  props: ['field', 'editedBy', 'name', 'defaultText', 'type', 'expanded', 'truncate', 'heading', 'editName', 'optional'],

  setup() {
    const sidebarStore = useSidebarStore();
    return { sidebarStore };
  },
  data() {
    return {
      desanitizeString: utils.desanitizeString,
      limit: 22,
      size: 'small',
      displayText: false,
      truncString: '<span class="extraText"> &hellip; <button class="btn-borderless infoMoreLess infoMore">more</button></span>',
      textChange: _.debounce(this.textChangeRaw, 100),
      oldField: false,
    };
  },
  created() {
    this.$ee.on('bus:meetingStart', this.reset);
  },
  beforeUnmount() {
    this.$ee.off('bus:meetingStart', this.reset);
  },
  mounted() {
    this.$nextTick(() => {
      this.displayText = this.rawText;
      this.oldField = this.field;
      if (this.$refs.displayer) {
        this.$refs.displayer.addEventListener('click', (e) => {
          e.preventDefault();
          if (!e.target.classList.contains('infoMore')) {
            return false;
          }
          this.askForMore();
        });

        let $extra = this.$refs.displayer.querySelector('.extraText');
        if ($extra) {
          $extra.style.opacity = 1;
        }
      }
    });
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      me: 'me',
      myId: 'myId',
      users: 'allUsers',
    }),
    sizingField() {
      return this.editedBy ? this.oldField : this.field;
    },
    isDisabled() {
      return !!this.editedBy;
    },
    rawText() {
      if (this.type === 'list') {
        return this.sizingField.length > 0 ? this.sizingField : [this.defaultText];
      } else if (this.type === 'paragraph') {
        if (!this.field) {
          if (!this.defaultText) {
            return '';
          } else {
            return this.defaultText;
          }
        } else {
          return this.field;
        }
      } else {
        return this.field || this.defaultText;
      }
    },
    editedBySomeoneElse() {
      return this.editedBy && this.editedBy !== -1 && this.me && this.editedBy !== this.myId;
    },
    editedByMe() {
      return this.editedBy && this.me && this.editedBy === this.myId;
    },
    isShowing() {
      return this.$parent.currentPane === 'agenda';
    },
    editedByName() {
      const user = _.findWhere(this.users, { id: this.editedBy });
      return utils.desanitizeString(user.first_name || '');
    },
  },
  watch: {
    rawText(nv, ov) {
      this.textChange(nv, ov);
    },
    editedBy: {
      immediate: true,
      handler(nv) {
        if (nv || this.isShowing) {
          _.delay(() => {
            this.size = this.sizeFromField();
          }, 100);
        }
        this.oldField = this.field;
      },
    },
    field: {
      immediate: true,
      deep: true,
      async handler(nv) {
        if (nv || this.isShowing) {
          _.delay(() => {
            this.size = this.sizeFromField();
          }, 100);
        }
      },
    },
  },
  methods: {
    editText: function (name) {
      return `<span class="text">${name} is editing</span><span class="elipHolder"><span class="elip">&hellip;</span></span>`;
    },
    sizeFromField: function () {
      if (this.$refs.sizer) {
        const sizerHeight = this.$refs.sizer.offsetHeight;
        const fieldHeight = this.$refs.displayer.offsetHeight;
        if (!fieldHeight) {
          return this.size;
        } else {
          return fieldHeight > sizerHeight ? 'big' : 'small';
        }
      }
    },
    askForEdit: function (e) {
      e.currentTarget.blur();
      this.sidebarStore.requestPane(this.name, {}, 'infoAskToEdit');
    },
    isTrunc(str) {
      const i = 0 - this.truncString.length;
      return str.slice(i) === this.truncString;
    },
    reset() {
      this.$nextTick(() => {
        this.displayText = this.rawText;
        this.size = 'small';
      });
    },
    askForMore() {
      this.$ee.emit('bus:expandInfo');
    },
    textChangeRaw(nv, ov) {
      if (!this.$refs.displayer) {
        return false;
      }
      if ((this.isTrunc(nv) && !this.isTrunc(ov)) || (!this.isTrunc(nv) && this.isTrunc(ov))) {
        let $extra = this.$refs.displayer.querySelector('.extraText');
        Velocity(
          $extra,
          {
            opacity: 0,
          },
          {
            duration: 200,
            complete: () => {
              this.displayText = nv;
              this.$nextTick(() => {
                if (this.$refs.displayer) {
                  $extra = this.$refs.displayer.querySelector('.extraText');
                  Velocity(
                    $extra,
                    {
                      opacity: 1,
                    },
                    {
                      duration: 200,
                    },
                  );
                }
              });
            },
          },
        );
      } else {
        this.displayText = nv;
        this.$nextTick(() => {
          if (this.$refs.displayer) {
            let $extra = this.$refs.displayer.querySelector('.extraText');
            if ($extra) {
              $extra.style.opacity = 1;
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.infoSection {
  position: relative;
  .edit {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    border: none;
    text-align: right;
    color: var(--c__accent);
    opacity: 0;
    background: var(--c__bg-90);
    transition: opacity 0.3s linear 0.1s;

    .text {
      visibility: hidden;
      font-weight: bold;
    }

    &:focus {
      opacity: 1;
    }

    &:focus:not(:focus-visible) {
      opacity: 0;
    }

    .text {
      visibility: visible;
    }
  }

  .sizer {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    width: 100%;
  }

  .para {
    white-space: pre-wrap;
    margin-bottom: 0;
    -webkit-hyphens: auto;
    hyphens: auto;
  }

  .infoDisplayer {
    position: relative;

    @include triggered {
      color: var(--c__accent);
    }

    &.edited {
      @include triggered {
        color: var(--c__text);
      }
    }
    .container &,
    .opening &,
    .breakthrough & {
      @include triggered {
        color: var(--c__text-energy-hover);
      }
    }

    ul {
      margin-left: 1em;
      list-style: none;
      li {
        position: relative;
      }
      li::before {
        content: '\2022';
        display: block;
        position: absolute;
        line-height: 0.8em;
        top: 0.2em;
        left: -1em;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        color: var(--c__text);
        text-align: center;
      }
      .text {
        display: block;
        // margin-left: -0.5em;
      }
      &.pleaseSet {
        margin-left: 0;
        list-style: none;
        .text {
          margin-left: 0;
        }
      }
    }
  }

  .isEditing {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--c__bg-90);
    color: var(--c__kinda-quiet);
    font-weight: bold;
    z-index: 9;
    .elipHolder {
      font-size: rem(27px);
      width: 1em;
      bottom: 50%;
      left: 50%;
      margin-left: -0.5em;
      position: absolute;
    }
    .text {
      width: 100%;
      position: absolute;
      top: 50%;
      text-align: center;
    }
  }

  .small.isEditing {
    .elipHolder {
      right: rem($sidebar-left-pad);
      left: auto;
      top: -0.45em;
      bottom: auto;
    }
    .text {
      top: 0.2em;
      text-align: left;
      left: rem($sidebar-left-pad);
      width: auto;
    }
  }

  .pleaseSet,
  .pleaseSet .infoMore {
    color: var(--c__warn);
  }

  .pleaseSet .infoMore:hover,
  .pleaseSet .infoMore:active {
    color: var(--c__accent);
  }

  .elip {
    animation: info-elip 1500ms infinite;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
  }
}

.checkInQuestion .isEditing,
.proposal .isEditing {
  top: 1.2em;
  left: 0.4em;
}

.infoDisplayer {
  padding: 0 rem($sidebar-left-pad);
}

.infoDisplayer .infoHeading {
  padding: 0;
}

.infoMoreLess {
  font-weight: bold;
  color: $c_mid-grey;
  text-align: left;
  display: block;
  padding: 0;
  &:hover,
  &:active {
    color: $c_accent;
  }
}

.infoMore {
  display: inline;
  position: relative;
  z-index: 9;
}

.infoLess {
  margin: 0 rem($sidebar-left-pad) 2em rem($sidebar-left-pad);
  flex: 0 0 1em;
}
</style>
