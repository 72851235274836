<template>
  <app-modal-overlay @close="close">
    <h2>Something Wrong?</h2>
    <form>
      <label for="sentryMessage">Tell us about the problem:</label>
      <textarea id="sentryMessage" ref="field" name="sentryMessage" placeholder="What happened? What were you doing? What did you expect to happen?" />
      <div class="actions">
        <button class="cancel btn-short btn-borderless" @click.prevent="close">Cancel</button>
        <button ref="send" class="send btn btn-border btn-short" @click.prevent="send">
          {{ sendingMsg }}
        </button>
      </div>
    </form>
  </app-modal-overlay>
</template>

<script>
import AppModalOverlay from '@/components/AppModalOverlay';
import { useFeaturesStore } from '@/store/pinia/features';

export default {
  components: {
    AppModalOverlay,
  },
  setup() {
    const featuresStore = useFeaturesStore();
    return { featuresStore };
  },
  data() {
    return {
      sendingMsg: 'Send',
    };
  },
  methods: {
    send() {
      let value = this.$refs.field.value;
      Sentry.captureMessage(value);
      this.close();
    },
    close() {
      this.$refs.field.value = '';
      this.featuresStore.toggleSentry();
    },
  },
};
</script>
