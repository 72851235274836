<template>
  <div class="extraButtons">
    <transition-group name="basic">
      <div class="screenShareHolder" @click="handleDisabledScreenShare" ref="screenShareButtonHolder" v-if="show">
        <button
          class="screenShareButton"
          @click="handleScreenShareDebounced"
          :class="[phaseBarClass, pageStore.isMeeting ? 'inMeeting' : 'outOfMeeting', { 'on-skillBar': onBar }]"
          ref="screenShareButton"
          title="Share Screen"
          :disabled="!!noScreenShareReason"
        >
          <app-icon icon="input" />
        </button>
        <bubble-tip name="noScreenShare" :show="showScreenShareMessage" direction="below" @close="showScreenShareMessage = false">
          <span v-html="noScreenShareReason"></span>
        </bubble-tip>
      </div>
      <button
        v-if="showParticipanListButton"
        class="participantListButton"
        @click="handleParticipantListButton"
        ref="participantListButton"
        title="Show Waiting Room List"
      >
        <app-icon icon="menu-participants" />
      </button>
      <exit-meeting-button v-if="show" class="exitButton" :hide-text="true" tip-direction="below" :name="usersStore.iAmModerator ? 'finish' : 'leave'"
        >Exit Meeting</exit-meeting-button
      >
    </transition-group>
  </div>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'underscore';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMediaStore } from '@/store/pinia/media';
import BubbleTip from '@/components/BubbleTip.vue';
import ExitMeetingButton from '@/components/ExitMeetingButton.vue';
import AppIcon from '@/components/AppIcon.vue';

const sidebarStore = useSidebarStore();
const pageStore = usePageStore();
const meetingStore = useMeetingStore();
const frameStore = useFrameStore();
const usersStore = useUsersStore();
const phaseStore = usePhaseStore();
const mediaStore = useMediaStore();

const showScreenShareMessage = ref(false);
const screenShareButton = ref(null);

const { screenSharing } = storeToRefs(mediaStore);
const { me, iAmModerator, speaking, iAmSpeaking, allUsers, usersHere, iAmAwayInGroup, iAmMirroring, iAmSharingScreen, inWaitingRoom, iAmInWaitingRoom } =
  storeToRefs(usersStore);
const { currentName: sidebarName, current: currentSidebar } = storeToRefs(sidebarStore);
const { currentPhase, settingUp, mirroring } = storeToRefs(phaseStore);
const { fullscreen } = storeToRefs(frameStore);
const { isMeeting } = storeToRefs(pageStore);
const { initTitleCardShown } = storeToRefs(meetingStore);

const onBar = computed(() => sidebarName.value === 'skillBar');
const phaseBarClass = computed(() => (onBar.value ? 'on-' + currentSidebar.value.phase : 'on-' + sidebarName.value));

const noScreenShareReason = computed(() => {
  if (currentPhase.value === 'lobby') {
    return 'You cannot share your screen until the meeting starts.';
  }

  if (iAmInWaitingRoom.value) {
    return "You can only share your screen once you've joined the meeting";
  }

  if (iAmMirroring.value || (currentPhase.value === 'input' && iAmSharingScreen.value)) {
    return 'You are already sharing your screen';
  }

  if (mirroring.value.requestedBy && mirroring.value.requestedBy !== me.value.id) {
    const presenter = allUsers.value.find((u) => u.id === mirroring.value.requestedBy);
    if (presenter) {
      return `${presenter.first_name} is currently sharing their screen.`;
    }
    return 'Screen share not allowed at the moment.';
  }
  if (settingUp.value || currentSidebar.value.barType === 'info') {
    return 'You cannot share your screen during phase setup.';
  }
  if (currentPhase.value === 'groups' && !iAmAwayInGroup.value) {
    return 'You cannot share your screen while finishing Breakout Groups.';
  }
  if (currentPhase.value === 'checkIn') {
    return 'You cannot share your screen during Check-In.';
  }
  if (currentPhase.value === 'checkOut') {
    return 'You cannot share your screen during Check-Out.';
  }
  if (currentPhase.value === 'breakTime') {
    return 'You cannot share your screen during&nbsp;break.';
  }
  if (currentPhase.value === 'endMeeting') {
    return 'You can only share your screen during the meeting.';
  }
  if (usersHere.value.length < 2) {
    return 'You cannot share your screen where there are no other&nbsp;users';
  }
  if (!['freeform', 'groups', 'dialogue'].includes(currentPhase.value) && !iAmSpeaking.value) {
    return `Only ${speaking.value?.first_name} can share their screen in this phase`;
  }

  return false;
});

const show = computed(() => {
  if (currentSidebar.value.noLeaveButton || fullscreen.value) {
    return false;
  } else if (isMeeting.value) {
    return initTitleCardShown.value ? true : false;
  } else {
    return false;
  }
});

const showParticipanListButton = computed(() => show.value && inWaitingRoom.value.length > 0 && iAmModerator.value);

watch(noScreenShareReason, (nv) => {
  if (!nv) {
    showScreenShareMessage.value = false;
  }
});

function handleScreenShare() {
  if (noScreenShareReason.value) {
    handleDisabledScreenShare();
  } else {
    if (currentPhase.value === 'input') {
      $ee.emit('bus:videoSourceChanged', screenSharing.value ? 'none' : 'screen');
      return;
    }
    if (mirroring.value.requestedBy) {
      $meetingmanager.stopMirroring({ mirroringId: mirroring.value.mirroringId });
    } else {
      $meetingmanager.startMirroring({ inputType: 'screen' });
    }
  }
}

const handleScreenShareDebounced = _.debounce(handleScreenShare, 1000);

function handleDisabledScreenShare() {
  if (noScreenShareReason.value) {
    Velocity(screenShareButton.value, 'callout.swing', { duation: 800 });
    if (noScreenShareReason.value) {
      showScreenShareMessage.value = noScreenShareReason.value;
    }
  }
}

function handleParticipantListButton() {
  sidebarStore.requestPane('participantList', {}, 'topButton');
}
</script>
<style lang="scss" scoped>
.screenShareButton,
.exitButton,
.participantListButton {
  border-radius: 50%;
  padding: 0;
}

.screenShareHolder,
.exitButton,
.participantListButton {
  top: rem(22px);
  height: rem(40px);
  width: rem(40px);
  position: absolute;
  z-index: 110;
}

.extraButtons {
  & > *:nth-child(1) {
    right: rem(76px);
  }

  & > *:nth-child(2) {
    right: rem(130px);
  }

  & > *:nth-child(3) {
    right: rem(184px);
  }
}

.screenShareButton {
  color: var(--c__text);
  border: 1px solid currentColor;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    opacity: var(--opacity__disabled);
    pointer-events: none;
  }

  .icon {
    width: 100%;
    display: block;
    pointer-events: none;
  }

  :deep(svg) {
    width: 100%;
    display: block;
  }

  @include triggered {
    color: var(--c__accent);
  }

  &.on-skillBar {
    color: var(--c__text-energy);

    @include triggered {
      color: var(--c__text-energy-invert);
    }
  }

  &.on-skillBar.on-breakTime,
  &.on-skillBar.on-dialogue,
  &.on-skillBar.on-groups,
  &.on-skillBar.on-endMeeting {
    color: var(--c__text);

    @include triggered {
      color: var(--c__accent);
    }
  }
}

.screenShareHolder {
  :deep(.bubbleTip) {
    margin-left: rem(-203px);

    &:after {
      left: 67.75%;
    }
  }
}

.exitButton {
  :deep(button) {
    padding: 0;
  }

  :deep(.bubbleTip) {
    margin-left: rem(-149px);

    &:after {
      left: 49.75%;
    }
  }
}

.participantListButton {
  color: var(--c__bg);
  background: var(--c__orange);

  .icon {
    height: rem(17px);
    margin-bottom: rem(1px);
  }
  :deep(svg) {
    width: rem(20px);

    .revFill {
      fill: var(--c__orange);
    }
  }

  @include triggered {
    background: var(--c__orange-darker);

    :deep(.revFill) {
      fill: var(--c__orange-darker);
    }
  }
}
</style>
