<template>
  <edit-sidebar class="editPurpose">
    <div class="part">
      <h2>Purpose</h2>
      <form @submit.prevent @keyup.enter.exact="saveAndReturn">
        <app-auto-textarea :modelValue="field" late-focus="true" placeholder="Add Meeting Purpose..." :limit="255" @update:modelValue="handleInput" />
      </form>
      <app-text-limit-message :limit="255" :warn-limit="200" :value="field" />
      <div class="actions btn-pair">
        <button type="button" @click.prevent="back()">Cancel</button>
        <button type="button" @click.prevent="saveAndReturn()">Save</button>
      </div>
    </div>
  </edit-sidebar>
</template>

<script>
import AppAutoTextarea from '@/components/AppAutoTextarea.vue';
import AppTextLimitMessage from '@/components/AppTextLimitMessage.vue';
import EditSidebar from '@/components/EditSidebar.vue';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';
import utils from '../resources/utils.js';

export default {
  components: {
    AppAutoTextarea,
    AppTextLimitMessage,
    EditSidebar,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const sidebarStore = useSidebarStore();
    return { meetingStore, sidebarStore };
  },
  computed: {
    fieldContent() {
      return this.meetingStore.purpose;
    },
  },
  data() {
    return {
      field: '',
    };
  },
  watch: {
    fieldContent: {
      immediate: true,
      handler(nv) {
        this.field = utils.desanitizeString(nv);
      },
    },
  },
  methods: {
    handleInput(e) {
      this.field = e;
    },
    saveAndReturn() {
      this.meetingStore.updateLocalMeetingInfo({ prop: 'purpose', value: this.field || '' });
      this.$meetingmanager.updateMeeting({
        purpose: this.field || '',
        trigger: 'purpose',
      });
      this.$nextTick(() => {
        this.sidebarStore.finishedWithPane();
      });
    },
    back() {
      this.sidebarStore.finishedWithPane();
    },
  },
};
</script>
