<template>
  <div ref="frame" class="meetingElements">
    <div id="mainFrame" class="contentFrame">
      <title-card />
      <groups-overlay v-if="!me.inWaitingRoom" />
      <avatars-frame />
      <meeting-phases v-if="!me.inWaitingRoom" />
      <meeting-notes v-if="!frameStore.fullscreen" />
      <audio-video-mute-state-subscriber v-if="me.id" :user="me" />
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

import { mapState as mapPiniaState } from 'pinia';
import AvatarsFrame from '@/components/AvatarsFrame';
import AudioVideoMuteStateSubscriber from '@/components/AudioVideoMuteStateSubscriber';
import MeetingPhases from '@/components/MeetingPhases';
import TitleCard from '@/components/TitleCard';
import GroupsOverlay from '@/components/GroupsOverlay';
import MeetingNotes from '@/components/MeetingNotes';
import { asUnit } from '@/resources/time-helpers';
import { useStorage } from 'vue3-storage';
import utils from '../resources/utils';
import { usePhaseStore } from '@/store/pinia/phase';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useMediaStore } from '@/store/pinia/media';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { useFrameStore } from '@/store/pinia/frame';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });

export default {
  components: {
    AvatarsFrame,
    AudioVideoMuteStateSubscriber,
    MeetingPhases,
    TitleCard,
    GroupsOverlay,
    MeetingNotes,
  },
  setup() {
    const phaseStore = usePhaseStore();
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const mediaStore = useMediaStore();
    const sidebarStore = useSidebarStore();
    const usersStore = useUsersStore();
    const timeStore = useTimeStore();
    const frameStore = useFrameStore();
    return { phaseStore, pageStore, meetingStore, messagesStore, sidebarStore, usersStore, timeStore, frameStore, mediaStore };
  },
  data() {
    return {
      waitingReview: false,
      myPing: false,
    };
  },
  mounted() {
    let _this = this;
    this.$meetingmanager.ping();
    this.myPing = setInterval(() => {
      this.$meetingmanager.ping();
    }, 60000);

    function updateNow() {
      if (_this.pageStore.isMeeting) {
        _this.timeStore.updateNow();
        _.delay(() => {
          requestAnimationFrame(updateNow);
        }, _this.timeStore.tick);
      }
    }
    requestAnimationFrame(updateNow);
    utils.storage.ss.removeItem('platform.tempPreference');
  },
  created() {
    const _this = this;
    this.$ee.on('nM:meeting:leave', _this.leave);
    this.$ee.on('nM:meeting:fade', _this.fade);
    this.$ee.on('nM:meeting:finish', _this.finishMeeting);
    this.$meetingmanager.on('event:created', (obj) => {
      if (this.me.inWaitingRoom) {
        return;
      }
      _this.handleMeetingHistory(obj);
    });
  },
  beforeUnmount() {
    const _this = this;
    this.$ee.off('nM:meeting:leave', _this.leave);
    this.$ee.off('nM:meeting:fade', _this.fade);
    this.$ee.off('nM:meeting:finish', _this.finishMeeting);
    this.$meetingmanager.off('event:created', _this.handleMeetingHistory);
  },
  computed: {
    ...mapPiniaState(useMediaStore, {
      audioSource: (s) => s.userDevices.audioSource,
      isCameraPermissionGranted: (s) => s.isCameraPermissionGranted,
    }),
    ...mapPiniaState(useUsersStore, ['me', 'myId', 'getUserById', 'iAmModerator', 'whatPage', 'isNonEmptyGroup']),
    ...mapPiniaState(useTimeStore, ['meetingRemaining', 'meetingLength', 'meetingOverDue']),
    meetingRemainingAsMinutes() {
      return this.meetingRemaining ? asUnit(this.meetingRemaining, 'minutes') : null;
    },
    meetingLengthAsMinutes() {
      return this.meetingLength ? asUnit(this.meetingLength, 'minutes') : null;
    },
    wrongPhaseForReview() {
      return ['lobby', 'review', 'checkOut', 'endMeeting'].indexOf(this.phaseStore.currentPhase) > -1;
    },
  },
  watch: {
    meetingRemaining() {
      if (!this.iAmModerator || this.meetingOverdue || this.meetingStore.startedEndWell || this.wrongPhaseForReview || this.meetingLengthAsMinutes < 44.9)
        return; // jshint ignore:line
      //if (!this.iAmModerator || this.meetingOverdue || this.meetingStore.startedEndWell || this.wrongPhaseForReview) return // jshint ignore:line

      if (this.waitingReview && this.meetingRemainingAsMinutes <= 15 && this.meetingStore.useGuide) {
        this.pleaseReview();
      }
    },
    'meetingStore.meetingId': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.readWaitingReview();
          this.$devicemanager
            .getDevices(this.audioSource)
            .then((devices) => {
              this.mediaStore.updateDevices(devices);
              setTimeout(() => {
                this.$ee.emit('bus:bixe-meeting-devices:init', newValue);
              }, 100);
            })
            .catch((e) => {
              if (e === 'AUDIO_PERMISSION_DENIED') {
                utils.storage.ls.removeItem('mediaAllowed');
                this.$router.push(`/allow?redirectUrl=${encodeURI(this.$route.path)}`);
              }
            });
        }
      },
    },
    'phaseStore.currentPhase': function () {},
    isCameraPermissionGranted: {
      immediate: true,
      handler: function (nv) {
        if (nv === false) {
          this.$ee.emit('nM:toggleMuteVideo', { isMuteRequested: true });
        }
      },
    },
  },
  methods: {
    handleMeetingHistory({ payload }) {
      //TODO: make some of these one offs
      const user = this.getUserById(payload?.user_id);
      const firstName = utils.desanitizeString(user?.first_name || '');
      const lastName = utils.desanitizeString(user?.last_name || '');
      const triggeredBy = payload?.meta?.trigger || false;
      switch (payload.level) {
        case 50: // more time
          if (payload.user_id !== this.myId) {
            if (this.phaseStore.currentPhase === 'breakTime') {
              this.messagesStore.addAlert('breakExtended', { addedBy: firstName });
            }
            if (this.phaseStore.currentPhase === 'groups') {
              this.messagesStore.addAlert('groupExtended', { addedBy: firstName });
            }
          }
          break;
        case 55: // meeting extended
          if (payload.user_id !== this.myId && payload.meta.secondsAdded && this.phaseStore.currentPhase !== 'lobby' && triggeredBy !== 'scheduler') {
            let amount = Math.round(payload.meta.secondsAdded / 60);
            this.messagesStore.addAlert('meetingExtended', { addedBy: firstName, amount });
          }
          break;
        case 60: // ended early
          if (payload.user_id !== this.myId) {
            if (this.phaseStore.currentPhase === 'breakTime' && this.usersStore.iAmAway) {
              this.messagesStore.addAlert('breakEndedEarly', { endedBy: firstName });
            }
            if (this.phaseStore.currentPhase === 'groups' && this.usersStore.iAmAwayInGroup) {
              this.messagesStore.addAlert('groupEndedEarly', { endedBy: firstName });
            }
          }
          break;
        case 70: // user left
          if (this.whatPage(this.myId) !== this.whatPage(payload.user_id)) {
            this.messagesStore.addAlert('userLeft', { name: firstName + ' ' + lastName });
          }
          break;
        case 80: {
          // user added to break out group
          let myGroupMembers = [];
          if (this.phaseStore.currentPhaseData && this.phaseStore.currentPhaseData.groups) {
            myGroupMembers = this.phaseStore.currentPhaseData.groups.find((group) => group.includes(this.myId));
          }
          if (payload.user_id !== this.myId && myGroupMembers.includes(payload.user_id) && this.isNonEmptyGroup) {
            this.messagesStore.addAlert('userAddedToGroup', { name: firstName });
          }
          break;
        }
        case 90: // user ejected
          if (payload?.meta?.user?.id) {
            const user = this.getUserById(payload.meta.user.id);
            const firstName = utils.desanitizeString(user.first_name || '');
            const lastName = utils.desanitizeString(user.last_name || '');
            this.messagesStore.addAlert('userEjected', { name: firstName + ' ' + lastName });
          }
          break;
        default:
          break;
      }
    },
    onPhaseStarted(v) {
      this.phaseStore.onNewPhase(v);
    },
    readWaitingReview() {
      const value = sessionStorage.getStorageSync(`meetings:${this.meetingStore.meetingId}:waitingReview`);
      this.waitingReview = value ? value === 'true' : true;
    },
    pleaseReview() {
      sessionStorage.setStorageSync(`meetings:${this.meetingStore.meetingId}:waitingReview`, 'false', 24 * 3600 * 1000);
      this.waitingReview = false;
      if (this.sidebarStore.current.name === 'whatNext') {
        this.meetingStore.setReviewNotice(true);
      } else {
        this.messagesStore
          .addAlert('pleaseReview')
          .then((res) => {
            if (res) {
              this.meetingStore.startEndWell();
              this.$meetingmanager.startEndWell();
              if (this.phaseStore.currentPhase !== 'freeform') {
                setTimeout(() => {
                  this.$meetingmanager.endPhase();
                }, 300);
              }
              this.meetingStore.setReviewNotice(false);
            } else {
              this.$meetingmanager.ignoreEndWell();
              this.meetingStore.setReviewNotice(false);
            }
          })
          .catch(() => {});
      }
    },
    finishMeeting() {
      if (this.phaseStore.rawCurrentPhase === 'endMeeting') {
        this.$meetingmanager.endMeeting();
        this.$videomanager.stop(true);
      } else {
        this.$meetingmanager.finishMeeting();
      }
    },
    leave() {
      this.$videomanager.stop(true);
      Velocity(
        this.$refs.frame,
        {
          opacity: 0,
        },
        {
          duration: 600,
          complete: () => {
            this.$meetingmanager.leaveMeeting();
          },
        },
      );
    },
    fade() {
      Velocity(
        this.$refs.frame,
        {
          opacity: 0,
        },
        {
          duration: 600,
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.meetingElements {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
