<template>
  <sidebar-overlay class="settings">
    <h2>Reactions</h2>
    <ul class="content">
      <li v-for="emote in emotes" :key="emote.icon">
        <button :class="emote.icon" @click="send(emote.name)">
          <app-icon :icon="emote.icon + '-control'" aria-hidden="true" />
          <span class="visuallyHidden">{{ emote.description }}</span>
        </button>
        <span class="key">
          <code>{{ emote.code[0] }}</code>
          <code>{{ emote.code[1] }}</code>
        </span>
      </li>
      <li class="hand">
        <button class="btn-border btn-question" @click="handleHand">
          {{ handLabel }}
        </button>
        <span class="key">
          <code>h</code>
          <code>u</code>
        </span>
      </li>
    </ul>
  </sidebar-overlay>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import AppIcon from '@/components/AppIcon';
import SidebarOverlay from '@/components/SidebarOverlay';

const emoteList = [
  {
    name: 'yes',
    icon: 'yes',
    description: 'thumbs up',
    code: ['e', 'y'],
  },
  {
    name: 'no',
    icon: 'no',
    description: 'thumbs down',
    code: ['e', 'n'],
  },
  {
    name: 'wave',
    icon: 'wave',
    description: 'wave',
    code: ['e', 'w'],
  },
  {
    name: 'what',
    icon: 'what',
    description: 'question',
    code: ['e', 'q'],
  },
  {
    name: 'eek',
    icon: 'eek',
    description: 'insight',
    code: ['e', 'i'],
  },
  {
    name: 'smile',
    icon: 'smile',
    description: 'happy',
    code: ['e', 'h'],
  },
  {
    name: 'grr',
    icon: 'grr',
    description: 'angry',
    code: ['e', 'a'],
  },
  {
    name: 'sad',
    icon: 'sad',
    description: 'sad',
    code: ['e', 's'],
  },
  {
    name: 'uhh',
    icon: 'uhh',
    description: 'uhh...',
    code: ['e', 'u'],
  },
  {
    name: 'confetti',
    icon: 'confetti',
    description: 'celebrate',
    code: ['e', 'c'],
  },
  {
    name: 'heart',
    icon: 'heart',
    description: 'love',
    code: ['e', 'l'],
  },
  {
    name: 'star',
    icon: 'star',
    description: 'star',
    code: ['e', 'x'],
  },
];

export default {
  components: {
    AppIcon,
    SidebarOverlay,
  },

  setup() {
    const sidebarStore = useSidebarStore();
    return { sidebarStore };
  },
  mounted() {
    this.setAutoClose();
  },
  beforeUnmount() {
    this.autoCloseTimeout = false;
  },
  data() {
    return {
      autoCloseTimeout: false,
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['me']),
    emotes: () => emoteList,
    handLabel() {
      return this.me.hand ? 'lower hand' : 'raise hand';
    },
    handCode() {
      return this.me.hand ? 'd' : 'u';
    },
  },
  methods: {
    send(emote) {
      this.$ee.emit('nM:emote:key', emote);
      this.setAutoClose();
    },
    handleHand() {
      if (this.me.hand) {
        this.$meetingmanager.handDown();
      } else {
        this.$meetingmanager.handUp();
      }
      this.setAutoClose();
    },
    setAutoClose() {
      clearTimeout(this.autoCloseTimeout);
      this.autoCloseTimeout = setTimeout(() => {
        if (this.sidebarStore.current.name === 'emoteGrid') {
          this.sidebarStore.closeHamburger();
        }
      }, 30000);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  list-style: none;
  gap: rem(27px);
  margin: rem(38px) rem(63px);
}
li {
  text-align: center;
}
li.hand {
  grid-column-start: 1;
  grid-column-end: 4;
  button {
    height: auto;
    padding: 0.5em 0;
  }
}
:deep(svg) {
  width: 100%;
  height: rem(40px);
}
code {
  border: 1px solid var(--c__quiet-button);
  color: var(--c__placeholder);
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: rem(10px);
  height: rem(12px);
  width: rem(12px);
  line-height: rem(9px);
  text-align: center;
  font-family: $body-font;
}

.separator {
  color: var(--c__placeholder);
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  @each $button in $emotes {
    &.#{$button} {
      color: var(--c__emote-#{$button});
    }
  }
}
button.confetti {
  :deep(.blue) {
    color: var(--c__blue);
  }
  :deep(.green) {
    color: var(--c__green);
  }
  :deep(.yellow) {
    color: var(--c__yellow);
  }
  :deep(.orange) {
    color: var(--c__orange);
  }
  :deep(.red) {
    color: var(--c__red);
  }
}
</style>
