<template>
  <transition name="slowFade">
    <div v-show="show" id="lobbySofa" class="sofa" :class="howMany" :style="{ top: top }">
      <div id="sofaSizer" class="sofaSizer" :style="sizerStyles">
        <svg class="sofaPart leftLeg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 280">
          <polygon class="shadow" points="73.18 205 56.96 279.79 66.26 279.79 90.54 205 73.18 205" fill="#888a8e" />
          <path class="main" d="M73.18,203.77,57,279.79h9.3l5.18-16.21C66.6,257.77,70.49,229.2,73.18,205Z" fill="#afb2af" />
        </svg>
        <svg class="sofaPart rightLeg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91 280">
          <polygon class="shadow" points="17.36 205 0 205 24.28 279.79 33.58 279.79 17.36 205" fill="#888a8e" />
          <path class="main" d="M30.12,263.56C21,256.66,10.49,229.74,0,203.77l24.28,76h9.3Z" fill="#afb2af" />
        </svg>
        <svg class="sofaPart sofaTop" xmlns="http://www.w3.org/2000/svg" viewBox="-1 0 625 280" preserveAspectRatio="none">
          <g class="back">
            <path class="light" d="M576,26.57A32,32,0,0,0,544.49,0H78.86A32,32,0,0,0,47.3,26.57L30.25,125.26H593.1Z" fill="#e6e9ec" />
            <path class="mid" d="M576,31.4c-2.65-14.6-16-25.27-31.56-25.27H78.86C63.28,6.13,50,16.79,47.3,31.4L30.25,125.26H593.1Z" fill="#dadfe3" />
            <path class="shade" d="M593.1,125.26H30.25l8.28-45.58c67.21,40.88,471.58,40.81,546.25-.24Z" fill="#d3d8db" />
          </g>
          <g class="seat">
            <path class="light" d="M0,125.26v63.58c0,8.87,5.84,16.07,13,16.07H610.3c7.21,0,13-7.19,13-16.07V125.26Z" fill="#e6e9ec" />
            <path class="mid" d="M0,191.86a13,13,0,0,0,13,13H610.3a13,13,0,0,0,13-13Q311.68,204.91,0,191.86Z" fill="#dadfe3" />
          </g>
        </svg>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'underscore';
import spaces from '@/resources/spaces';
import { SOFA_LIMIT } from '@/resources/constants/frame-constants';
import { useReady } from '@/composables/useReady';

import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';

const { readyForAvatars } = useReady();

const frameStore = useFrameStore();
const phaseStore = usePhaseStore();
const meetingStore = useMeetingStore();
const usersStore = useUsersStore();

const hadSofa = ref(false);
const showSofaNow = ref(false);

const { positionDetails: pos, usersVisible, iAmAway, iAmStillAway, iAmAwayInGroup, iAmInWaitingRoom } = storeToRefs(usersStore);
const { fullscreen, contWidth } = storeToRefs(frameStore);
const { space } = storeToRefs(meetingStore);
const { currentPhase } = storeToRefs(phaseStore);

const show = computed(() => {
  if (!readyForAvatars.value) {
    return false;
  }

  // Full screen
  if (fullscreen.value) {
    return false;
  }

  // Too many
  if (usersVisible.value.length > SOFA_LIMIT) {
    return false;
  }

  // it is a break and you should have one there
  if (spaces[space.value]?.breakSofa && (iAmAway.value || iAmStillAway.value || iAmInWaitingRoom.value)) {
    return true;
  }

  // Should you even have one?
  if (!spaces[space.value]?.sofa) {
    return false;
  }

  if (iAmAwayInGroup.value && currentPhase.value === 'groups') {
    return true;
  }

  if (pos.value?.layoutName === 'couch') {
    return true;
  }

  return false;
});

const howMany = computed(() => ['nobody', 'solo', 'duo', 'trio'][pos.value.userCount]);
const top = computed(() => pos.value.bottom + 'px');
// const width = computed(() => {
//   pos.value.userCount > 1 ? 120 : 60;
//   return pos.value.width + pad > contWidth.value - 48 ? contWidth.value - 48 + 'px' : pos.value.width + pad + 'px';
// });

const sizerStyles = computed(() => {
  let pad = pos.value.userCount > 1 ? 120 : 0;
  let width, left;

  if (pos.value.width + pad > contWidth.value - 48) {
    // doesn't have room
    width = contWidth.value - 48 + 'px';
    left = '24px';
  } else {
    width = pos.value.width + pad + 'px';
    left = pos.value.left - pad / 2 + 'px';
  }

  return {
    width,
    left,
  };
});

watch(
  show,
  (nv, ov) => {
    hadSofa.value = nv;
    if (ov && !nv) {
      showSofaNow.value = nv;
    }
    if (nv && !ov) {
      _.delay(() => {
        showSofaNow.value = nv;
      }, 600);
    }
  },
  { immediate: true },
);
</script>
<style lang="scss" scoped>
.sofa {
  position: absolute;
  left: 0;
  width: 100%;
  transform: translateY(-42%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: top 0.5s cubic-bezier(0.45, 0, 0.55, 1), margin 0.5s cubic-bezier(0.45, 0, 0.55, 1), opacity 0.5s ease;

  height: math.div(280, $design-height) * 100vh;
  height: calc(var(--vh, 1vh) * (280 / #{$design-height}) * 100);
  z-index: 10;
}
.sofaSizer {
  transition: all 500ms cubic-bezier(0.45, 0, 0.55, 1);
  height: math.div(280, $design-height) * 100vh;
  height: calc(var(--vh, 1vh) * (280 / #{$design-height}) * 100);
  // width: 624px;
  position: absolute;

  &:before {
    position: absolute;
    content: '';
    bottom: -3px;
    height: 5px;
    background: var(--c__shadow-opaque);
    box-shadow: 0 0 10px var(--c__shadow-opaque), 0 0 5px var(--c__shadow-opaque), 0 0 3px var(--c__shadow-opaque);
    left: 0;
    right: 0;
    border-radius: 50%;
  }
}

.sofaPart {
  height: math.div(280, $design-height) * 100vh;
  height: calc(var(--vh, 1vh) * (280 / #{$design-height}) * 100);
  position: absolute;
  top: 0;
  bottom: 0;
}

.leftLeg {
  left: 0;
}

.rightLeg {
  right: 0;
}

.leftLeg,
.rightLeg {
  transition: all 300ms ease;
  .shadow {
    fill: var(--c__sofa-leg-shade);
  }
  .main {
    fill: var(--c__sofa-leg-mid);
  }
}

.sofa.solo {
  margin-top: rem(-5px);
  .leftLeg {
    left: -6vh;
    left: calc(var(--vh, 1vh) * -6);
  }

  .rightLeg {
    right: -6vh;
    right: calc(var(--vh, 1vh) * -6);
  }
}

.sofaTop {
  left: 0;
  right: 0;
  width: 100%;
  .light {
    fill: var(--c__sofa-main-light);
  }
  .mid {
    fill: var(--c__sofa-main-mid);
  }
  .shade {
    fill: var(--c__sofa-main-shade);
  }
}

.sofa {
  @include dark-mode('neon') {
    .sofaPart {
      fill-opacity: 0;
    }
    .leftLeg .shadow,
    .rightLeg .shadow,
    .back .mid,
    .seat .light {
      stroke: var(--c__accent);
      stroke-width: 2;
    }
  }
}
</style>
