import utils from '../resources/utils';
import $API from '../services/bixe-api.service';

export default () => (to, from, next) => {
  const { user, config } = window.__INITIAL_STATE__;
  const isAuthenticated = user && user.id;
  if (isAuthenticated) {
    next();
  } else {
    let returnUrl = `${config.baseUrl}${to.fullPath}`;

    if (utils.isOpenedWithinElectronShell()) {
      if (!to.query['requested-otp']) {
        window.location = `/conference/app/welcome`;
        return;
      }
      $API
        .resyncSessionDetails({ 'requested-otp': to.query['requested-otp'] })
        .then(() => {
          if (returnUrl.includes('requested-otp')) {
            returnUrl = returnUrl.replace('requested-otp', 'previous-otp');
          }
          window.location = returnUrl;
        })
        .catch((err) => {
          console.log('resync details', err);
          window.location = `/conference/app/welcome`;
        });
    } else {
      window.location = `/lauth/nowhere?returnUrl=${encodeURI(returnUrl)}`;
    }
  }
};
