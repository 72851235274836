<template>
  <div class="v-select" :class="className">
    <span v-if="hasSlot('label') || label" class="v-select__label" :id="labelId">
      <slot name="label">{{ label }}:</slot>
    </span>
    <div class="v-select__inner">
      <button
        :id="buttonId"
        ref="button"
        class="v-select__btn"
        :disabled="disabled"
        :aria-expanded="ariaExpanded"
        :aria-labelledby="`${labelId ? labelId : ''} ${buttonId}`"
        type="button"
        aria-haspopup="listbox"
        @click="toggle"
        @blur="buttonBlurHandler"
      >
        <span v-if="hasSlot('prepend')" class="v-select__prepend"> <slot name="prepend" /> </span
        ><span v-if="((placeholder || hasSlot('placeholder')) && modelValue === undefined) || !optionsHasValue" class="v-select__placeholder">
          <slot name="placeholder" :placeholder="placeholder">{{ placeholder }}</slot> </span
        ><span class="v-select__selected">
          <slot v-if="modelValue !== undefined && optionsHasValue" :value="modelValue" :option="currentOption" name="selected">{{
            currentOption ? currentOption.label : modelValue
          }}</slot> </span
        ><span class="v-select__arrow">
          <slot name="arrow"
            ><svg viewBox="0 0 255 255">
              <path d="M0 64l128 127L255 64z" /></svg
          ></slot>
        </span>
      </button>
      <transition :name="transition ? transition.name : ''" :mode="transition ? transition.mode : ''">
        <div v-if="open" class="v-select__menu">
          <ul
            v-if="options && options.length"
            ref="list"
            class="v-select__list"
            :aria-activedescendant="modelValue && getOptionId(modelValue)"
            :aria-labelledby="labelId"
            role="listbox"
            tabindex="-1"
            @keydown="keydownHandler"
            @keyup.end="setLastSelected"
            @keyup.home="setFirstSelected"
            @keyup.esc="escapeHandler"
            @keyup="printHandler"
            @blur="menuBlurHandler"
          >
            <li
              v-for="(option, index) in options"
              :id="getOptionId(option)"
              :key="index"
              ref="options"
              class="v-select__option"
              role="option"
              :class="{ 'v-select__option--selected': isSelected(option) }"
              :aria-selected="isSelected(option) ? 'true' : 'false'"
              @click="clickHandler(option)"
            >
              <slot name="option" :option="option" :value="modelValue">
                <span>{{ option.label }}</span>
              </slot>
            </li>
          </ul>
          <template v-else>
            <div class="v-select__no-options">
              <slot name="no-optioms">
                <span>No options provided</span>
              </slot>
            </div>
          </template>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
//TODO: This should be converted back into a lib once it is stable.
//* This is based on https://github.com/andrewvasilchuk/vue-accessible-select

import { KEY_RETURN, KEY_ESCAPE, KEY_SPACE, KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN } from 'keycode-js';

const config = {
  transition: null,
};

export default {
  name: 'AppSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: true,
    },
    transition: {
      type: Object,
      default: () => config.transition || {},
    },
    label: String,
    placeholder: String,
    disabled: Boolean,
  },
  data() {
    const { _uid } = this;

    return {
      open: false,
      timeout: null,
      printedText: '',
      localId_: _uid,
    };
  },
  computed: {
    labelId() {
      return this.label ? `v-select-label-${this.localId_}` : false;
    },
    buttonId() {
      return `v-select-button-${this.localId_}`;
    },
    ariaExpanded() {
      return this.open ? 'true' : 'false';
    },
    className() {
      return {
        'v-select--opened': this.open,
        'v-select--disabled': this.disabled,
      };
    },
    currentOption() {
      return Array.isArray(this.options) && this.options.find((option) => option.value === this.modelValue);
    },
    currentOptionIndex() {
      return this.options.findIndex((option) => option === this.currentOption);
    },
    optionsHasValue() {
      return this.options.findIndex((option) => option.value === this.modelValue) !== -1;
    },
  },
  watch: {
    open(val) {
      if (val) {
        setTimeout(() => {
          document.addEventListener('click', this.clickListener);

          // if list is present in DOM
          const { list } = this.$refs;

          if (list) {
            list.focus();

            // * if option is selected, then scroll to it
            const { modelValue } = this;
            if (modelValue || modelValue === 0) {
              this.scrollToSelected();
            }
          }

          this.$emit('open');
        }, 0);
      } else {
        document.removeEventListener('click', this.clickListener);
        this.$emit('close');
      }
    },
    modelValue(val) {
      // * if option is selected, then scroll to it
      if (val || val == 0) {
        this.scrollToSelected();
      }
    },
  },
  /*
   * SSR Safe Client Side ID attribute generation
   * id's can only be generated client side, after mount.
   * this._uid is not synched between server and client.
   */
  mounted() {
    this.$nextTick(() => {
      // Update dom with auto ID after dom loaded to prevent
      // SSR hydration errors.
      this.localId_ = this._uid;
    });
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    emit(val) {
      this.$emit('update:modelValue', val);
      this.$emit('input', val);
      this.$emit('change', val);
    },
    clickListener(e) {
      const { target } = e;
      const closestBtn = target.closest('.v-select__btn');
      const closestList = target.closest('.v-select__list');

      if (closestList !== this.$refs.list && closestBtn !== this.$refs.button) {
        this.open = false;
      }
    },
    isSelected(option) {
      return this.modelValue === option.value;
    },
    clickHandler(option) {
      this.emit(option.value);
      this.open = false;
    },
    keydownHandler(e) {
      if (e.keyCode === KEY_ESCAPE) {
        return;
      }
      // prevent from default scrolling

      if ([KEY_SPACE, KEY_LEFT, KEY_UP, KEY_RIGHT, KEY_DOWN].indexOf(e.keyCode) > -1) {
        e.preventDefault();
      }

      const { currentOptionIndex } = this;
      // if neither option is selected then select the first

      if (currentOptionIndex === -1) {
        this.emit(this.options[0].value);
        return;
      }

      switch (e.keyCode) {
        case KEY_UP:
          if (currentOptionIndex !== 0) this.emit(this.options[currentOptionIndex - 1].value);
          break;
        case KEY_DOWN:
          if (currentOptionIndex !== this.options.length - 1) this.emit(this.options[currentOptionIndex + 1].value);
          break;
        case KEY_RETURN:
          setTimeout(() => {
            this.open = false;
            this.$refs.button.focus();
          }, 0);
          break;
      }
    },
    getOptionId(option) {
      return `v-select-option-${this.options.indexOf(option)}_${this.localId_}`;
    },
    setFirstSelected() {
      this.emit(this.options[0].value);
    },
    setLastSelected() {
      this.emit(this.options[this.options.length - 1].value);
    },
    escapeHandler() {
      this.open = false;
      this.$refs.button.focus();
    },
    printHandler(e) {
      this.printedText += String.fromCharCode(e.keyCode);

      this.selectByText(this.printedText);

      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        this.printedText = '';
      }, 500);
    },
    selectByText(text) {
      for (let option of this.options) {
        if (String(option.label).toUpperCase().startsWith(text)) {
          this.emit(option.value);
          return;
        }
      }
    },
    scrollToSelected() {
      // * get current option DOM node
      if (Array.isArray(this.$refs.options)) {
        const currentOption = this.$refs.options[this.currentOptionIndex];

        if (currentOption) {
          const { offsetTop, clientHeight } = currentOption;

          const { list } = this.$refs;

          const currentVisibleArea = list.scrollTop + list.clientHeight;

          if (offsetTop < list.scrollTop) {
            list.scrollTop = offsetTop;
          } else if (offsetTop + clientHeight > currentVisibleArea) {
            list.scrollTop = offsetTop - list.clientHeight + clientHeight;
          }
        }
      }
    },
    buttonBlurHandler(e) {
      let target = e.relatedTarget;
      if (target === null) {
        target = document.activeElement;
      }
      if (target !== this.$refs.list && this.open) {
        this.open = false;
      }
    },
    menuBlurHandler(e) {
      let target = e.relatedTarget;
      if (target === null) {
        target = document.activeElement;
      }
      if (target !== this.$refs.button) {
        this.open = false;
      }
    },
    hasSlot(name) {
      return Boolean(this.$slots[name]) || Boolean(this.$slots[name]);
    },
  },
};
</script>
<style lang="scss">
// v-select__menu styles
$v-select-menu-position-top: 100% !default;

// v-select__arrow styles
$v-select-arrow-size: 8px !default;

.v-select {
  &__inner {
    position: relative;
  }

  &__menu {
    position: absolute;
    top: $v-select-menu-position-top;
    left: 0;
    width: 100%;
  }

  &__list {
    padding-left: 0;
    margin: {
      top: 0;
      bottom: 0;
    }
    list-style: none;
    overflow: auto;
  }

  &__arrow {
    margin-left: auto;

    svg {
      display: inline-block;
      width: $v-select-arrow-size;
      height: $v-select-arrow-size;
      vertical-align: middle;
    }
  }
}
</style>
