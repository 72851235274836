<template>
  <section class="meetings page" data-page-name="meetings">
    <div class="meetingsLists">
      <transition name="basic">
        <section v-if="pendingMeetings" class="currentMeetings">
          <h2>Current</h2>
          <ul>
            <meetings-list-item v-for="meeting in pendingMeetings" :key="meeting.id" :meeting="meeting" @navigate="navigate" />
          </ul>
        </section>
      </transition>
      <transition name="basic">
        <section v-if="completedMeetings" class="completedMeetings">
          <h2>Finished</h2>
          <ul>
            <meetings-list-item v-for="meeting in pendingMeetings" :key="meeting.id" :meeting="meeting" @navigate="navigate" />
          </ul>
        </section>
      </transition>
    </div>
  </section>
</template>

<script>
import { nM } from '@/legacy';
import { usePageStore } from '@/store/pinia/page';
import MeetingsListItem from '@/components/MeetingsListItem.vue';
import BixeApi from '@/services/bixe-api.service';

export default {
  components: {
    MeetingsListItem,
  },

  beforeRouteEnter(to, from, next) {
    BixeApi.getMeetings()
      .then((meetings) => {
        next((vm) => vm.setMeetings(meetings));
      })
      .catch((err) => {
        this.$ee.emit('api:alertAdd', {
          type: 'misc',
          class: 'error',
          duration: 'temporary',
          content: nM.toErrorMessage(err),
        });
      });
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  data() {
    return {
      meetings: null,
    };
  },
  computed: {
    pendingMeetings() {
      return this.meetings && this.meetings.filter((meeting) => meeting.status !== 'finished');
    },
    completedMeetings() {
      return this.meetings && this.meetings.filter((meeting) => meeting.status === 'finished');
    },
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      meetings: this.meetings,
    });
  },
  methods: {
    setMeetings(meetings) {
      this.meetings = meetings;
    },
    navigate(where) {
      this.$router.push(where).catch(() => null);
    },
  },
};
</script>
