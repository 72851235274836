import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMediaStore } from '@/store/pinia/media';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { usePageStore } from '@/store/pinia/page';
import { clockwise } from '@/resources/order';
import { useApplyOffscreenPages } from '@/composables/layouts/useApplyOffscreenPages';
import { usePxToRem } from '@/composables/usePxToRem';
import * as geometry from '@/resources/geometry';
import { V_PAD, H_PAD, INPUT_RATIO, OUTER_H_PAD, OUTER_V_PAD, CIRCLE_TOP_PAD } from '@/resources/constants/frame-constants';
import _ from 'underscore';

export function useLinesLayout() {
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();
  const meetingStore = useMeetingStore();
  const mediaStore = useMediaStore();
  const phaseStore = usePhaseStore();
  const pageStore = usePageStore();
  const { pxToRem } = usePxToRem();

  const { usersVisibleList: users, speaking, iAmSpeaking, iAmMirroring, page, myId, myGroup, iAmAwayInGroup } = storeToRefs(usersStore);
  const { isAvatarsDockDrawer } = storeToRefs(pageStore);

  // Layout constants
  const CONTEXT_SPEAKER_TOP = 35;
  const CONTEXT_SPEAKER_SIZE = 0.5;
  const LOW_PAGED_SIZE = 0.25;
  const PRESENT_PAGED_SIZE = 0.24;
  const FULLSCREEN_PAGED_SIZE = 100;
  const FULLSCREEN_OUTER_H_PAD = 28;
  const LOBBY_EXTRA_PAD = 30;

  const { viewportH, contWidth, contHeight, cX } = storeToRefs(frameStore);
  const { spaceDetails } = storeToRefs(meetingStore);
  const { someoneIsMirroring, mirroring, currentPhase, phaseStage } = storeToRefs(phaseStore);
  const { screenSharing } = storeToRefs(mediaStore);

  const { applyOffscreenPages } = useApplyOffscreenPages();

  const hasSpeakerOutsideLine = computed(() => {
    if (isAvatarsDockDrawer.value) {
      return false;
    }
    if (currentPhase.value === 'feedback' && phaseStage.value === 'responding') {
      return true;
    }
    if (currentPhase.value === 'input' && !screenSharing.value) {
      return true;
    }
    if (screenSharing.value || someoneIsMirroring.value) {
      return false;
    }
    if (currentPhase.value === 'context') {
      return true;
    }
    return false;
  });

  // Building blocks
  function splitIntoLines({ space, w, pad, users, iAmOutside, lineMax, linesPerBlock }) {
    // Generate a maximum per row if it isn't aready there
    lineMax = lineMax || Math.floor(space / (w + pad));

    // It can't be less than 1
    lineMax = Math.max(lineMax, 1);
    let blockLength = lineMax * linesPerBlock;

    let lines = [];

    if (users.length <= lineMax) {
      // If we don't need multiple rows, just show them all
      lines[0] = users;
    } else {
      if (iAmOutside) {
        // If your own avatar is going to be elsewhere in the layout, don't re-add to to pages.
        let blocks = _.chunk(users, blockLength);
        blocks.forEach((block) => {
          if (block.length <= lineMax) {
            lines = [...lines, block];
          } else {
            lines = [...lines, ..._.chunk(block, Math.ceil(block.length / 2))];
          }
        });
      } else {
        if (lineMax > 1 || linesPerBlock > 1) {
          users = [myId.value, ..._.without(users, myId.value)];
          let blocks = _.chunk(_.without(users, myId.value), blockLength - 1);

          blocks = blocks.map((b) => {
            return [myId.value, ...b];
          });

          blocks.forEach((block) => {
            if (block.length <= lineMax) {
              lines = [...lines, block];
            } else {
              lines = [...lines, ..._.chunk(block, Math.ceil(block.length / 2))];
            }
          });
        } else {
          // If it is single user rows, make own user the first

          lines = [[myId.value], ..._.chunk(_.without(users, myId.value), 1)];
        }
      }
    }
    return lines;
  }

  function splitIntoBlocks({ space, w, pad, linePad, users, iAmOutside, lineMax, linesPerBlock, crossSpace }) {
    // if we didn't specify lines per block, work it out from w
    linesPerBlock = Math.ceil(linesPerBlock || crossSpace + linePad / (w + linePad));

    // if we didn't specify w, work it out from lines
    w = w || crossSpace / (linesPerBlock + (linesPerBlock - 1 * linePad));

    let lines = splitIntoLines({ space, w, pad, users, iAmOutside, lineMax, linesPerBlock });

    return _.chunk(lines, linesPerBlock);
  }

  function block({ lines, cX, cY, w, pad, linePad, vertical, cont }) {
    let lineCount = lines.length;
    let pos = {};

    if (!vertical) {
      // horizontal
      // work out row height, total height and starting position
      let rowHeight = w + linePad;
      let totalHeight = rowHeight * lineCount - linePad;
      let startingY = cY - totalHeight / 2 + w / 2;
      // loop over the rows &  lay out each row
      lines.forEach((users, i) => {
        let thisRow = geometry.row({
          w,
          pad,
          users,
          y: startingY + rowHeight * i,
          cont: cont || contWidth.value,
          max: cont || contWidth.value,
          maxSize: w,
          scaled: false,
        });
        pos = { ...pos, ...thisRow };
      });
    } else {
      // vertical
      // work out column width, total width and starting position
      let columnWidth, totalWidth, startingX;
      if (lineCount === 1) {
        columnWidth = w / 2;
        totalWidth = columnWidth;
        startingX = cX - totalWidth / 2;
      } else {
        columnWidth = w + linePad;
        totalWidth = columnWidth * lineCount - linePad;
        startingX = cX - totalWidth / 2;
      }
      // loop over the columns
      lines.forEach((users, i) => {
        let thisCol = geometry.column({
          w,
          pad,
          users,
          x: startingX + columnWidth * i,
          cont: contHeight.value,
          max: contHeight.value,
          maxSize: w,
          scaled: false,
        });

        pos = { ...pos, ...thisCol };
      });
    }

    return pos;
  }

  function basicPageInfo({ w, cY, vertical }) {
    let iAmOutside = (iAmSpeaking.value || iAmMirroring.value) && hasSpeakerOutsideLine.value;
    let prevPos, nextPos, extras, space, pad, linePad;

    if (vertical) {
      space = contHeight.value - OUTER_V_PAD * 2;
      pad = V_PAD;
      linePad = H_PAD;
      extras = {};
    } else {
      space = contWidth.value - OUTER_H_PAD * 2;
      pad = H_PAD;
      linePad = V_PAD;

      prevPos = 0 - w - H_PAD;
      nextPos = contWidth.value * 2 + w + H_PAD;
      extras = {
        pagerTop: cY - w / 2,
        pagerHeight: w,
      };
    }

    return {
      space,
      pad,
      linePad,
      prevPos,
      nextPos,
      extras,
      iAmOutside,
    };
  }

  function pagedLine({ w, cY, cX, users, vertical, lineMax }) {
    let pos, refs;
    const { space, pad, linePad, prevPos, nextPos, extras, iAmOutside } = basicPageInfo({ w, cY, cX, vertical });

    // generate page list
    let pages = splitIntoLines({ space, w, pad, linePad, users, iAmOutside, linesPerBlock: 1, lineMax });

    // if our current page doesn't exist any more reset to the last one
    if (!pages[page.value]) {
      usersStore.updatePage(pages.length - 1);
    }

    // generate postions
    // generate ref positions (based on first page) and turn it into an array based on the order in the first page so we can look stuff up
    refs = block({ lines: [pages[0]], cX, cY, w, pad, linePad, vertical });
    refs = pages[0].map((id) => refs[id]);

    // generate current page positions
    pos = block({ lines: [pages[page.value]], cX, cY, w, pad, linePad, vertical });

    // apply offpage positions
    pos = applyOffscreenPages({
      pos,
      pages,
      refs,
      w,
      nextPos,
      prevPos,
      y: cY,
      page: page.value,
      vertical,
    });

    // give arrow positions etc and update pages
    let r = w / 2;
    let vPosses = _.pluck(pos, 'top');
    let hPosses = _.pluck(pos, 'left');
    let topY = Math.min(...vPosses);
    let bottomY = Math.max(...vPosses);
    let leftX = Math.min(...hPosses);
    let wPad = 28;
    let hPad = 56;

    extras.dockLeft = leftX - r - wPad;
    //extras.dockWidth = contWidth.value - extras.dockLeft - 28;
    extras.dockWidth = w + wPad * 2;
    extras.dockTop = topY - r - hPad;
    extras.dockHeight = bottomY - topY + w + hPad * 2;

    return { pos, extras, layoutPages: pages };
  }

  function pagedBlock({ w, cY, cX, users, vertical, lineMax, linesPerBlock }) {
    let pos, refs;
    const { space, pad, linePad, prevPos, nextPos, extras, iAmOutside } = basicPageInfo({ w, cY, cX, vertical });

    // generate page list
    let pages = splitIntoBlocks({ space, w, pad, linePad, users, iAmOutside, linesPerBlock, lineMax });

    // if our current page doesn't exist any more reset to the last one
    if (!pages[page.value]) {
      usersStore.updatePage(pages.length - 1);
    }

    // generate postions
    // generate ref positions (based on first page) and turn it into an array based on the order in the first page so we can look stuff up
    refs = block({ lines: pages[0], cX, cY, w, pad, linePad, vertical });
    refs = pages[0].map((id) => refs[id]);

    // generate current page positions
    pos = block({ lines: pages[page.value], cX, cY, w, pad, linePad, vertical });

    // apply offpage positions
    pos = applyOffscreenPages({
      pos,
      pages,
      refs,
      w,
      nextPos,
      prevPos,
      y: cY,
      page: page.value,
    });

    // give arrow positions etc and update pages

    return { pos, extras, layoutPages: pages };
  }

  // Actual Layouts

  // the weird lobby one
  //TODO: see if we can simplify this
  function pagedRowsLayout() {
    //TODO: See if this can be simplified further using the contructors above

    // Make reference positions
    let switchLimit = 5;
    let limit = switchLimit;
    let rowCount = users.value.length <= switchLimit ? 1 : 2;
    let vAnchor = spaceDetails.value.table ? 0.3333 : 0.45;
    let maxArea = viewportH.value * 0.6666 - OUTER_V_PAD * 2 - V_PAD * rowCount - LOBBY_EXTRA_PAD;
    let maxSize = maxArea / rowCount;

    if (users.value.length > switchLimit) {
      limit = Math.floor((contWidth.value - OUTER_H_PAD * 2) / (maxSize + H_PAD)) * 2;
    }

    let layoutPages = [];

    if (users.value.length <= limit) {
      layoutPages[0] = users.value;
    } else {
      if (limit <= 1) {
        layoutPages = [[myId.value], ..._.chunk(_.without(users.value, myId.value), 1)];
      } else {
        layoutPages = _.chunk(_.without(users.value, myId.value), limit - 1);
        layoutPages = layoutPages.map((r) => [myId.value, ...r]);
      }
    }

    let rows = [];
    let corePos = {};
    let pos = {};

    let testUsers = layoutPages[0];

    if (users.value.length <= switchLimit) {
      rows[0] = testUsers;
    } else {
      rows = _.chunk(testUsers, Math.ceil(testUsers.length / 2));
    }

    let center = 0 + viewportH.value * vAnchor + LOBBY_EXTRA_PAD;
    let firstW = false;

    rows.forEach((row, i) => {
      let rowPos = geometry.row({
        maxSize: firstW || maxSize,
        scaled: true,
        users: row,
        y: 0,
        cont: contWidth.value,
        max: contWidth.value,
        pad: H_PAD,
        outerPad: OUTER_H_PAD,
        row: i,
      });

      corePos = _.extend(corePos, rowPos);

      if (i === 0) {
        firstW = rowPos[row[0]].w;
      }
    });

    let w = corePos[testUsers[0]].w;
    let rowHeight = w + V_PAD;
    let fullHeight = rowHeight * rows.length - V_PAD;
    let initialY = center - fullHeight / 2 + w / 2;

    testUsers.forEach((id) => {
      corePos[id].top = initialY + rowHeight * corePos[id].row;
    });

    let prevPos = 0 - w - H_PAD;
    let nextPos = contWidth.value * 2 + w + H_PAD;

    let extras = {
      pagerTop: center - w / 2,
      pagerHeight: w,
    };

    layoutPages[page.value].forEach((id, ri) => {
      pos[id] = corePos[testUsers[ri]];
    });

    pos = applyOffscreenPages({
      pos,
      pages: layoutPages,
      refs: layoutPages[0].map((id) => pos[id]),
      w,
      nextPos,
      prevPos,
      y: center,
      page: page.value,
    });

    return { pos, layoutName: 'waitingHigh', extras, paged: true, pages: layoutPages };
  }

  function lowRowLayout() {
    let { pos, layoutPages, extras } = pagedLine({
      w: contHeight.value * LOW_PAGED_SIZE,
      cY: contHeight.value * 0.75,
      users: clockwise(users.value),
    });

    return { pos, extras, layoutName: 'lowRow', paged: true, pages: layoutPages };
  }

  function contextLayout() {
    let orderedUsers = clockwise(users.value);
    let main = _.without(orderedUsers, speaking.value.id);

    let speakerW = contHeight.value * CONTEXT_SPEAKER_SIZE;
    let spaceUnder = contHeight.value - speakerW - CIRCLE_TOP_PAD + CONTEXT_SPEAKER_TOP;

    // Check width we would get if it is 1 row
    let oneRowMaxSpace = contWidth.value - OUTER_H_PAD * 2;
    let oneRowSpace = (H_PAD + contHeight.value * LOW_PAGED_SIZE) * main.length - H_PAD;

    let w = oneRowSpace > oneRowMaxSpace ? spaceUnder * 0.35 : contHeight.value * LOW_PAGED_SIZE;
    let cY = speakerW + spaceUnder / 2 + CIRCLE_TOP_PAD;

    let { pos, extras, layoutPages } = pagedBlock({
      w,
      cY,
      cX: cX.value,
      users: main,
      linesPerBlock: 2,
    });

    pos[speaking.value.id] = {
      left: cX.value,
      top: speakerW / 2 + CIRCLE_TOP_PAD + CONTEXT_SPEAKER_TOP,
      w: speakerW,
    };

    layoutPages = layoutPages.map((row) => [speaking.value.id, ...row]);

    layoutPages = layoutPages.map((page) => {
      return page.flat();
    });

    return { pos, extras, layoutName: 'audience', paged: true, pages: layoutPages };
  }

  function feedbackRowsLayout() {
    let orderedUsers = clockwise(users.value);
    let main = _.without(orderedUsers, speaking.value.id);

    let speakerW = contHeight.value * CONTEXT_SPEAKER_SIZE;
    let spaceUnder = contHeight.value - speakerW - CIRCLE_TOP_PAD + CONTEXT_SPEAKER_TOP;

    // Check width we would get if it is 1 row
    let oneRowMaxSpace = contWidth.value - OUTER_H_PAD * 2;
    let oneRowSpace = (H_PAD + contHeight.value * LOW_PAGED_SIZE) * main.length - H_PAD;

    let w = oneRowSpace > oneRowMaxSpace ? spaceUnder * 0.35 : contHeight.value * LOW_PAGED_SIZE;
    let cY = speakerW + spaceUnder / 2 + CIRCLE_TOP_PAD;

    let { pos, extras, layoutPages } = pagedBlock({
      w,
      cY,
      cX: cX.value,
      users: main,
      linesPerBlock: 2,
    });

    pos[speaking.value.id] = {
      left: cX.value,
      top: speakerW / 2 + CIRCLE_TOP_PAD + CONTEXT_SPEAKER_TOP,
      w: speakerW,
    };

    layoutPages = layoutPages.map((row) => [speaking.value.id, ...row]);

    layoutPages = layoutPages.map((page) => {
      return page.flat();
    });

    return { pos, extras, layoutName: 'audience', paged: true, pages: layoutPages };
  }

  function reviewLayout() {
    let { pos, layoutPages, extras } = pagedLine({
      cY: pxToRem(135),
      pad: pxToRem(48),
      w: pxToRem(150),
      users: users.value,
    });

    return { pos, extras, layoutName: 'review', paged: true, pages: layoutPages };
  }

  function getPresentationUsers() {
    let orderedUsers = iAmAwayInGroup.value ? clockwise(myGroup.value) : clockwise(users.value);
    let usersToShow = [];

    if (someoneIsMirroring.value) {
      usersToShow = _.without(orderedUsers, mirroring.value.requestedBy);
      usersToShow = [mirroring.value.requestedBy, ...usersToShow];
    } else {
      usersToShow = _.without(orderedUsers, speaking.value.id);
      if (screenSharing.value) {
        usersToShow = [speaking.value.id, ...usersToShow];
      }
    }
    return usersToShow;
  }

  function sharingSidebarLayout() {
    let w = pxToRem(FULLSCREEN_PAGED_SIZE);

    let { pos, layoutPages, extras } = pagedLine({
      w,
      cX: contWidth.value - FULLSCREEN_OUTER_H_PAD - w / 2,
      users: getPresentationUsers(),
      lineMax: 4,
      vertical: true,
    });

    return { pos, extras, layoutName: 'fullScreenPresentation', paged: true, pages: layoutPages };
  }

  function dialogueSidebarLayout() {
    let w = pxToRem(FULLSCREEN_PAGED_SIZE);
    let { pos, layoutPages, extras } = pagedLine({
      w,
      cX: contWidth.value - FULLSCREEN_OUTER_H_PAD - w / 2,
      users: clockwise(users.value),
      lineMax: 4,
      vertical: true,
    });
    return { pos, extras, layoutName: 'dialogueLayout', paged: true, pages: layoutPages };
  }

  function presentationLayout() {
    let w = contHeight.value * PRESENT_PAGED_SIZE;
    let videoHeight = contHeight.value * INPUT_RATIO;
    let spaceUnder = contHeight.value - videoHeight;

    let cY = videoHeight + spaceUnder / 2;

    let { pos, layoutPages, extras } = pagedLine({
      w,
      cY,
      users: getPresentationUsers(),
    });

    return { pos, extras, layoutName: 'presentation', paged: true, pages: layoutPages };
  }

  return { lowRowLayout, contextLayout, reviewLayout, presentationLayout, sharingSidebarLayout, dialogueSidebarLayout, pagedRowsLayout, feedbackRowsLayout };
}
