/* eslint-disable camelcase */

import EventEmitter from 'wolfy87-eventemitter';
import { connect as connectToSocket } from 'socket.io-client';
import EventsManager from './events-manager.service';

export default class MeetingManager extends EventEmitter {
  constructor(config) {
    super();

    this.config = config;
    this.syncMeetingStateInterval = null;
    this.eventsManager = new EventsManager(this);
  }

  get connected() {
    return !!this.socket && this.socket.connected;
  }

  get connecting() {
    return !!this.socket && this.socket.connecting;
  }

  startPeriodicallySyncMeetingState() {
    const preventAutoResync = localStorage.getItem('PREVENT_AUTO_RESYNC');
    if (this.syncMeetingStateInterval) {
      clearInterval(this.syncMeetingStateInterval);
      this.syncMeetingStateInterval = null;
    }
    if (preventAutoResync) {
      return;
    }
    this.syncMeetingStateInterval = setInterval(() => {
      this.requestReSync();
    }, 30000);
  }

  connect(state, { avDeviceState }, { requestResync, isDuplicateClient }) {
    this.firstRun = true;
    this.meeting_state = state;

    this.socket = connectToSocket(this.config.realtimeServerConfig.baseUrl, {
      path: this.config.realtimeServerConfig.path,
      forceNew: true,
      query: `meetingId=${state.meeting.id}`,
    });

    this.socket.on('connection:complete', () => {
      this.emit('connected');
      this.socket.emit('join', avDeviceState.muteAudio, avDeviceState.muteVideo, isDuplicateClient);
      if (requestResync) {
        this.startPeriodicallySyncMeetingState();
      }
    });

    this.socket.on('disconnect', () => {
      this.emit('disconnected');
    });

    this.socket.on('success', (data) => {
      this.emit('success', data);
    });

    this.socket.on('join', ({ shouldAutoStart, meetingState }) => {
      const isNotStartedYet = state.meeting.status === 'pending';
      if (shouldAutoStart && isNotStartedYet) {
        this.startMeeting();
      }
      if (meetingState) {
        this.meeting_state = meetingState;

        this.emit('meeting_state', meetingState, true, true);
        this.eventsManager.process({ firstRun: true });
      }
    });

    this.socket.on('info', (data) => {
      this.emit('info', data);
    });

    this.socket.on('error', (data) => {
      this.emit('error', data);
    });

    this.socket.on('meeting.State', (meetingState) => {
      this.meeting_state = meetingState;

      this.emit('meeting_state', meetingState, this.firstRun, false);
      this.eventsManager.process({ firstRun: this.firstRun });
      this.firstRun = false;
    });

    this.socket.on('meeting.resyncState', (event) => {
      const preventAutoResync = localStorage.getItem('PREVENT_AUTO_RESYNC');
      if (preventAutoResync) {
        return;
      }
      if (event.state) {
        console.log('[client_event] meeting.resyncState');
        this.meeting_state = event.state;

        this.emit('meeting_state', event.state, true, true);
        this.eventsManager.process({ firstRun: false });
      }
    });

    this.socket.on('meeting.event', (event) => {
      if (this.eventsManager[event.name]) {
        console.log('[client_event]: ' + event.name);
        this.eventsManager[event.name](event);
      } else {
        console.log('[unhandled_client_event]', event.name);
      }
    });
  }

  disconnect() {
    if (this.connected || this.connecting) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
    }
    if (this.syncMeetingStateInterval) {
      clearInterval(this.syncMeetingStateInterval);
      this.syncMeetingStateInterval = null;
    }
    this.meeting_state = null;
  }

  addTime(time) {
    this.socket.emit('phase.AddTime', time);
  }

  startMeeting() {
    this.socket.emit('common.StartMeeting');
  }

  leaveMeeting() {
    this.socket.emit('common.LeaveMeeting', { haveLeaveConfirmation: true });
  }

  finishMeeting() {
    this.socket.emit('common.FinishMeeting');
  }

  endMeeting() {
    this.socket.emit('common.EndMeeting');
  }

  updateAdvancedSetting(name, value) {
    this.socket.emit('common.updateAdvancedSetting', { name, value });
    // updates meeting (individual) setting and emits `on_advanced_setting_updated`
  }

  ping() {
    this.socket.emit('common.Ping');
  }

  handUp() {
    this.socket.emit('common.HandUp');
  }

  handDown() {
    this.socket.emit('common.HandDown');
  }

  eject(user) {
    this.socket.emit('common.admin.Eject', user.id);
  }

  approveJoiningRequest({ userId }) {
    this.socket.emit('common.admin.approveJoiningRequest', { userId });
  }

  approveAllJoiningRequests() {
    this.socket.emit('common.admin.approveAllJoiningRequests');
  }

  rejectJoiningRequest({ userId }) {
    this.socket.emit('common.admin.rejectJoiningRequest', { userId });
  }

  onJoiningRequestApproved({ muteAudio, muteVideo }) {
    this.socket.emit('common.joinMeetingFromWaitingRoom', { muteAudio, muteVideo });
  }

  recommendAsMeetingController({ userId }) {
    this.socket.emit('common.admin.RecommendAsModerator', { userId });
  }

  removeUserFromMeetingControllers({ userId }) {
    this.socket.emit('common.admin.RemoveFromModerators', { userId });
  }

  confirmJoiningAsMeetingController() {
    this.socket.emit('common.ConfirmJoiningAsModerator');
  }

  rejectMeetingControllerRecommendation() {
    this.socket.emit('common.RejectJoiningAsModerator');
  }

  mute(userId, { muteAudio = false, muteVideo = false }) {
    this.socket.emit('common.Mute', userId, muteAudio, muteVideo);
  }

  sysMute(userId, { muteAudio = false, muteVideo = false }) {
    this.socket.emit('common.admin.Mute', userId, muteAudio, muteVideo);
  }

  unmute(userId, { unmuteAudio = false, unmuteVideo = false }) {
    this.socket.emit('common.UnMute', userId, unmuteAudio, unmuteVideo);
  }

  sysUnmute(userId, { unmuteAudio = false, unmuteVideo = false }) {
    this.socket.emit('common.admin.UnMute', userId, unmuteAudio, unmuteVideo);
  }

  doneEditingPhaseStartedOn({ forceStart }) {
    this.socket.emit('phase.admin.DoneEdition', { forceStart });
  }

  moveToPhase(moduleId, args) {
    const newArgs = Object.assign(
      {
        module_id: moduleId,
      },
      args,
    );

    this.socket.emit('phase.admin.MoveToPhase', moduleId, newArgs);
  }

  startTalking() {
    this.socket.emit('common.StartTalking');
  }

  stopTalking() {
    this.socket.emit('common.StopTalking');
  }

  skipBeginWell() {
    this.socket.emit('common.ui.SkipBeginWell');
  }

  startEndWell() {
    this.socket.emit('common.ui.StartEndWell');
  }

  ignoreEndWell() {
    this.socket.emit('common.ui.IgnoreEndWell');
  }

  lockField(field) {
    this.socket.emit('common.LockField', field);
  }

  unlockField(field) {
    this.socket.emit('common.UnlockField', field);
  }

  addLog(data) {
    this.socket.emit('common.Log', data);
  }

  updateLog(data) {
    this.socket.emit('common.UpdateLog', data);
  }

  deleteLog(logId) {
    this.socket.emit('common.DeleteLog', logId);
  }

  switchSource(source, isSourceActive = false, screenSharingStarted = 0) {
    this.socket.emit('stage.SwitchSource', source, isSourceActive, screenSharingStarted);
  }

  changeView(view, args) {
    this.socket.emit('setup.ChangeView', view, args);
  }

  updateMeeting(data) {
    this.socket.emit('common.UpdateMeeting', data);
  }

  updateProposal(data) {
    this.socket.emit('common.UpdateProposal', data);
  }

  updateTopic(data) {
    this.socket.emit('common.UpdateTopic', data);
  }

  emote(type) {
    this.socket.emit('common.Emote', type);
  }

  vote(vote) {
    this.socket.emit('common.Vote', vote);
  }

  requestUserAction(actionType = '', requestedBy = '', requestedTo = '') {
    this.socket.emit('common.requestAction', actionType, requestedBy, requestedTo);
  }

  startMirroring(data) {
    this.socket.emit('common.StartMirroring', data);
  }

  updateMirroring(updateObj) {
    this.socket.emit('common.UpdateMirroring', updateObj);
  }

  stopMirroring(stopMirroringObj) {
    this.socket.emit('common.StopMirroring', stopMirroringObj);
  }

  publishConnectionHealthChanged({ isConnectivityWentPoor }) {
    this.socket.emit('common.ConnectionHealthChanged', { isConnectivityWentPoor });
  }

  endVoting() {
    this.socket.emit('phase.EndVoting');
  }

  endResponding(data) {
    this.socket.emit('phase.EndResponding', { ...data });
  }

  endingPhase() {
    this.socket.emit('phase.EndingPhase');
  }

  haveReturned() {
    this.socket.emit('stage.IHaveReturned');
  }

  changeNextTime(time) {
    this.socket.emit('setup.ChangeNextTime', time);
  }

  changeNextSpeaker({ speakerId, isRandom }) {
    this.socket.emit('setup.ChangeNextSpeaker', { speakerId, isRandom });
  }

  changeNextQuestion(questions) {
    this.socket.emit('setup.ChangeNextQuestions', { questions });
  }

  changeNextGroups(groups) {
    this.socket.emit('setup.ChangeNextGroups', groups);
  }

  changeQuestion(questions) {
    this.socket.emit('setup.ChangeQuestions', { questions });
  }

  endPhase() {
    this.socket.emit('phase.EndPhase');
  }

  requestReSync() {
    this.socket.emit('common.requestReSync');
  }

  finish(userId) {
    if (userId) {
      this.socket.emit('stage.UserOffStage', userId);
    } else {
      this.socket.emit('stage.OnIWantToLeaveTheStage');
    }
  }
}
