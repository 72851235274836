<template>
  <transition name="basic">
    <div class="hamburgerHolder" :class="[pageStore.isMeeting ? 'inMeeting' : 'outOfMeeting']">
      <app-button
        @real-click="handleClick"
        @disabled-click="handleDisabledClick"
        class="buttonHolder"
        :button-class="[
          phaseBarClass,
          isMeeting ? 'inMeeting' : 'outOfMeeting',
          { 'on-skillBar': onBar },
          sidebarOpen ? 'barOpen' : 'barClosed',
          iAmInWaitingRoom ? 'inWaitngRoom' : '',
          'hamburger',
        ]"
        ref="button"
        v-if="show"
        :title="[isCross ? 'close menu' : 'menu']"
        :disabled="disabled"
        :wobble="true"
      >
        <transition name="hamburger">
          <app-icon v-if="isCross" icon="menu-cross" key="cross" />
        </transition>
        <transition name="hamburger">
          <div v-if="!isCross && isMeeting" class="menuLabel">menu</div>
        </transition>
        <transition name="hamburger">
          <app-icon v-if="!isCross && !isMeeting" icon="menu-hamburger" key="hamburger" />
        </transition>
      </app-button>

      <bubble-tip name="noMenu" :show="showTip" direction="below" @close="showTip = false">
        <span v-html="noMenuReason"></span>
      </bubble-tip>
    </div>
  </transition>
</template>

<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePageStore } from '@/store/pinia/page';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import AppIcon from '@/components/AppIcon.vue';
import AppButton from '@/components/AppButton.vue';
import BubbleTip from '@/components/BubbleTip.vue';
const usersStore = useUsersStore();
const pageStore = usePageStore();
const sidebarStore = useSidebarStore();
const meetingStore = useMeetingStore();
const frameStore = useFrameStore();

const showTip = ref(false);

const { fullscreen } = storeToRefs(frameStore);
const { iAmInWaitingRoom } = storeToRefs(usersStore);
const { currentName: currentSidebarName, current: currentSidebar, sidebarOpen, mustBeOpen, meetingMenuOpen, isAnimating } = storeToRefs(sidebarStore);
const { isMeeting } = storeToRefs(pageStore);
const { initTitleCardShown } = storeToRefs(meetingStore);

const onBar = computed(() => currentSidebarName.value === 'skillBar' && sidebarOpen.value);
const phaseBarClass = computed(() => {
  let suffix = onBar.value ? currentSidebar.value.phase : currentSidebarName.value;
  return 'on-' + suffix;
});

const disabled = computed(() => isAnimating.value || (iAmInWaitingRoom.value && !isCross.value));

const noMenuReason = computed(() => {
  if (iAmInWaitingRoom.value) {
    return 'The menu is only available in the meeting';
  } else {
    return 'You cannot use the menu here';
  }
});

const isCross = computed(() => {
  if (!isMeeting.value) {
    return sidebarOpen.value;
  }
  if (mustBeOpen.value) {
    return meetingMenuOpen.value || currentSidebar.value.isEditPane;
  } else {
    return sidebarOpen.value;
  }
});

const show = computed(() => {
  if (!initTitleCardShown.value && isMeeting.value) {
    return false;
  }
  if (fullscreen.value) {
    return false;
  }
  return true;
});

function handleDisabledClick() {
  if (isAnimating.value) {
    return false;
  }
  showTip.value = true;
}

function handleClick() {
  if (disabled.value) {
    return false;
  }

  if (isAnimating.value) {
    return false;
  }

  if (!isMeeting.value) {
    if (isCross.value) {
      sidebarStore.requestPane(false, {}, 'hamburgerButton');
    } else {
      sidebarStore.requestPane('outside', {}, 'hamburgerButton');
    }
  } else {
    if (!isCross.value && sidebarOpen.value) {
      sidebarStore.requestPane('hamburger', {}, 'hamburgerButton');
      return true;
    }
    if (isCross.value) {
      if (currentSidebar.value.isEditPane) {
        sidebarStore.finishedWithPane();
      } else if (currentSidebar.value.inHamburger) {
        sidebarStore.closeHamburger();
      } else {
        sidebarStore.requestBack();
      }
    } else {
      sidebarStore.requestPane('hamburger', {}, 'hamburgerButton');
    }
  }
}
</script>
<style lang="scss" scoped>
.hamburgerHolder {
  position: absolute;
  padding: 0;
  z-index: 110;
  &.inMeeting {
    right: rem(23px);
    top: rem(22px);
    height: rem(40px);
    width: rem(40px);
    :deep(button) {
      border-radius: 50%;
      border: 1px solid currentColor;
    }
    :deep(.icon) {
      top: rem(9px);
      right: rem(8px);
      position: absolute;
      width: rem(22px);
      height: rem(22px);
    }
  }

  &.outOfMeeting {
    right: 0;
    top: 0;
    height: rem(56px);
    width: rem(56px);

    :deep(.icon) {
      top: rem(18px);
      right: rem(24px);
      position: absolute;
      width: rem(32px);
      height: rem(32px);
    }
  }
}
:deep(.buttonWrapper) {
  z-index: 110;
}
:deep(.buttonWrapper),
:deep(button.hamburger) {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

:deep(button.hamburger) {
  color: var(--c__text);
  .meetingFramePage.beach-space.light-theme & {
    color: var(--c__white);
  }
  .beach-space.light-theme.sidebar-open &,
  .beach-space.light-theme.sidebar-opening &,
  .beach-space.light-theme.sidebar-closing & {
    color: var(--c__text);
  }

  .menuLabel {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :deep(svg) {
    width: 100%;
    pointer-events: none;
  }

  @include triggered {
    color: var(--c__accent);
    .beach-space.light-theme & {
      color: var(--c__black);
    }
  }

  .menuLabel {
    @include type-size(x-small);
    font-weight: 400;
  }
  &.on-skillBar.barOpen {
    color: var(--c__text-energy);
    @include triggered {
      color: var(--c__text-energy-invert);
    }
  }

  &.on-skillBar.on-breakTime.barOpen,
  &.on-skillBar.on-groups.barOpen,
  &.on-skillBar.on-dialogue.barOpen,
  &.on-skillBar.on-endMeeting.barOpen {
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }
  }

  &.on-waitingRoom {
    opacity: var(--opacity__disabled);
    color: var(--c__text);
    @include triggered {
      color: var(--c__text);
    }
  }
}

.hamburger-enter-active {
  transition: transform 0.5s cubic-bezier(0, 0.5, 0.5, 1), opacity 0.5s;
}

.hamburger-leave-active {
  transition: transform 0.25s cubic-bezier(0.2, 0, 1, 0.5), opacity 0.25s;
}

.hamburger-enter-from,
.hamburger-leave-to {
  &.menu-cross-icon {
    transform: scale(0);
  }
  &.menuLabel {
    transform: rotateZ(-180deg) scale(0);
    opacity: 0;
  }
  &.menu-hamburger-icon {
    transform: scaleX(0);
  }
}

:deep(.bubbleTip) {
  margin-left: rem(-256px);
  &:after {
    left: 85.5%;
  }
}
</style>
