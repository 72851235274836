import { defineStore } from 'pinia';
import { nM } from '@/legacy';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export const useTimeStore = defineStore('time', {
  state() {
    return {
      tick: 1000,
      tickForSmallerDrawers: 1000 * 5,
      now: null,
      meetingStart: 0,
      meetingStartTimeString: '',
      meetingStartDateString: '',
      meetingEnd: 0,
      meetingEndTimeString: '',
      meetingEndDateString: '',
      meetingTimeEdited: false,
      settingTime: 0,
      phaseTimeModified: 0,
      phaseTimerStatus: false,
      times: {},
      alarmRunning: false,
      hasInteractedWithTimer: false,
    };
  },
  getters: {
    meetingOverdue: (state) => state.now >= state.meetingEnd,
    meetingElapsed: (state) => (state.meetingStart ? state.now - state.meetingStart : 0),
    meetingLength: (state) => (state.meetingEnd ? state.meetingEnd - state.meetingStart : 0),
    meetingOverdueBy() {
      return this.meetingElapsed - this.meetingLength;
    },
    meetingRemaining() {
      if (!this.meetingLength || this.meetingOverdue) {
        return 0;
      } else {
        return this.meetingLength - this.meetingElapsed;
      }
    },
    phaseSettingTime(state) {
      const phaseStore = usePhaseStore();
      if (!state.times[phaseStore.setupPhase]) {
        return 0;
      } else {
        return state.settingTime ? state.settingTime * 1000 : state.times[phaseStore.setupPhase].defaultTime * 1000;
      }
    },
    phaseStart() {
      const phaseStore = usePhaseStore();
      const { currentPhaseData, currentPhase, onStage, onStageData } = phaseStore;

      if (currentPhaseData) {
        if (['checkIn', 'checkOut'].includes(currentPhase) && onStage) {
          return new Date(onStageData.started_on * 1000);
        } else if (currentPhase === 'groups' && currentPhaseData.edition_started_on) {
          return new Date(currentPhaseData.edition_started_on * 1000);
        } else {
          return new Date(currentPhaseData.started_on * 1000);
        }
      } else {
        return new Date();
      }
    },
    phaseExpectedEnd() {
      const phaseStore = usePhaseStore();
      const { currentPhaseData, currentPhase, onStage, onStageData } = phaseStore;

      if (!currentPhaseData) {
        return new Date();
        // } else if (rs.meeting.breakEndedEarly || rs.meeting.groupsReassemble || rg.iAmStillAway || rg.iAmStillAwayInGroup) {
        //   return new Date();
        //TODO: check if we need to above and add it back in.
      } else {
        if (['checkIn', 'checkOut'].includes(currentPhase) && onStage) {
          return new Date(onStageData.timeout * 1000);
        } else {
          return new Date(this.phaseStart.getTime() + currentPhaseData.duration * 1000);
        }
      }
    },
    phaseElapsed(state) {
      const phaseStore = usePhaseStore();
      const { currentPhaseData, currentPhase } = phaseStore;
      if (currentPhase === 'groups' && currentPhaseData.edition_started_on) {
        return currentPhaseData.edition_started_on ? state.now - this.phaseStart : 0;
      } else {
        return currentPhaseData.started_on ? state.now - this.phaseStart : 0;
      }
    },
    phaseLength() {
      if (this.phaseExpectedEnd && this.phaseStart) {
        return this.phaseExpectedEnd - this.phaseStart;
      } else {
        return 0;
      }
    },
    phaseSetTime() {
      const phaseStore = usePhaseStore();
      const { default_duration, duration } = phaseStore.currentPhaseData;
      if (default_duration) {
        return default_duration * 1000;
      } else if (duration) {
        return duration * 1000;
      } else {
        return 0;
      }
    },
    phaseRemaining(s) {
      if (!this.phaseExpectedEnd) {
        return 0;
      } else {
        if (this.phaseExpectedEnd && s.now < this.phaseExpectedEnd) {
          return this.phaseLength - this.phaseElapsed;
        } else {
          return 0;
        }
      }
    },
    phaseStatus() {
      const usersStore = useUsersStore();
      const phaseStore = usePhaseStore();
      if (!this.phaseLength) {
        return 'unlimited';
      }
      let prop = this.phaseRemaining / this.phaseLength;

      if (
        phaseStore.breakEndedEarly ||
        phaseStore.groupsReassemble ||
        usersStore.iAmStillAway ||
        usersStore.iAmStillAwayInGroup ||
        (phaseStore.rawCurrentPhase !== 'breakTime' && usersStore.iAmAway)
      ) {
        return 'overdue';
      }
      if (this.phaseRemaining <= 0) {
        return 'overdue';
      } else if (prop < 0.25) {
        return 'end';
      } else if (prop < 0.5) {
        return 'mid';
      } else {
        return 'start';
      }
    },
  },
  actions: {
    initMeeting(data, isReSync) {
      this.meetingUpdated(data);
      if (data.state.next_phase && !isReSync) {
        this.updateSetupTime(data.state.next_phase);
      }

      if (data.history.filter((event) => event.level === 55).length > 0) {
        this.meetingTimeEdited = true;
      }
    },
    setTimes(data) {
      this.times = {
        meeting: {
          defaultTime: 3600,
          max: 18000,
          min: 900,
          ratio: 1,
          step: 60,
        },
        ...nM.toTimesObject(data),
      };
    },
    updateNow() {
      this.now = new Date();
    },
    onEventCreated({ event }) {
      if (event.level === 55) {
        this.meetingTimeEdited = true;
      }
    },
    meetingUpdated({ meeting }) {
      const { started_on, starts_on, ends_on, status } = meeting;

      if (ends_on) {
        this.meetingEnd = new Date(ends_on * 1000);
        this.endDateString = Intl.DateTimeFormat('en-uk', { dateStyle: 'medium' }).format(this.meetingEnd);
        this.endTimeString = Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.meetingEnd);
      }

      if (status === 'started') {
        if (started_on) {
          this.meetingStart = new Date(started_on * 1000);
        } else {
          this.meetingStart = new Date();
        }
      } else if (starts_on) {
        this.meetingStart = new Date(starts_on * 1000);
      }
      this.startDateString = Intl.DateTimeFormat('en-uk', { dateStyle: 'medium' }).format(this.meetingStart);
      this.startTimeString = Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.meetingStart);
    },
    updateView() {},
    updateSetupTime({ time }) {
      this.settingTime = time;
    },
    updateSettingTime({ total }) {
      this.settingTime = total;
    },
    updatePhaseTimerStatus(v) {
      this.phaseTimerStatus = v;
    },
    startAlarm() {
      this.alarmRunning = true;
    },
    stopAlarm() {
      this.alarmRunning = false;
    },
    interactedWithTimer(v) {
      this.hasInteractedWithTimer = { ...this.hasInteractedWithTimer, ...v };
    },
  },
});
