import { defineStore } from 'pinia';
import { useStorage } from 'vue3-storage';
import { eventEmitter } from '@/services/event-emitter.service';
const localStorage = useStorage({ namespace: '', storage: 'local' });

export const useMediaStore = defineStore('media', {
  state() {
    return {
      isCameraPermissionGranted: true,
      readinessSessionInitialized: false,
      devices: [],
      userDevices: {},
      audioSource: false,
      audioOutputSource: false,
      videoSource: false,
      videoSourceActive: false,
      videoKind: false,
      audioOn: true,
      audioOutputOn: true,
      videoOn: true,
      stopScreenSharingRequestTs: 0,
    };
  },
  getters: {
    screenSharing: (s) => s.videoKind === 'screen',
    cameraSharing: (s) => s.videoKind === 'camera',
  },
  actions: {
    initMeeting(data) {
      this.setPresentationSources({ phase: data.state.current_phase });
    },
    initReadinessCheckSession(v) {
      this.readinessSessionInitialized = v;
    },
    setPresentationSources({ phase }) {
      this.videoKind = phase.source || false;
      this.videoSourceActive = phase.isSourceActive || false;
    },
    sourceSwitched({ isSourceActive, source }) {
      this.videoKind = source;
      this.videoSourceActive = isSourceActive;
      eventEmitter.emit('bus:videoSourceChanged', source);
    },
    updateDevices(v) {
      this.devices = [...v];
    },
    updateUserDevices(v) {
      this.userDevices = { ...v };
    },
    setAudioSource(v) {
      this.audioSource = v;
    },
    setAudioOutputSource(v) {
      this.audioOutputSource = v;
    },
    setVideoSource(v) {
      this.videoSource = v;
    },
    setAudioOn(v) {
      localStorage.setStorageSync('user:audioOn', v);
      this.audioOn = v;
    },
    setAudioOutputOn(v) {
      localStorage.setStorageSync('user:audioOutputOn', v);
      this.audioOutputOn = v;
    },
    setVideoOn(v) {
      localStorage.setStorageSync('user:videoOn', v);
      this.videoOn = v;
    },
    markAsCameraPermissionNotGranted() {
      this.isCameraPermissionGranted = false;
    },
    setStopScreenSharingTimestamp(v) {
      this.stopScreenSharingRequestTs = v;
    },
  },
});
