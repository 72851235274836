<template>
  <transition name="loader">
    <div v-if="hasSpinner && (initialPos || connectivity)" class="loadingAvatar" :class="{ animated: !noAnimations, solid: noTransparency }" :style="styles">
      <div class="pulser" @mouseover="hovered = true" @mouseleave="hovered = false">
        <span class="text">Loading&hellip;</span>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

import { storeToRefs } from 'pinia';
import { useFeaturesStore } from '@/store/pinia/features';
import { useUsersStore } from '@/store/pinia/users';
import _ from 'underscore';

const usersStore = useUsersStore();
const { userHasSpinner } = storeToRefs(usersStore);
const props = defineProps(['pos', 'user', 'connectivity']);
const initialPos = ref(false);
const hovered = ref(false);

const { noTransparency, noAnimations } = useFeaturesStore();
const left = computed(() => (props.pos?.left ? props.pos.left - props.pos.w / 2 : 0));
const top = computed(() => (props.pos?.top ? props.pos.top - props.pos.w / 2 : 0));
const hasSpinner = computed(() => {
  let id = props.connectivity ? 'connectivity' : props.user.id;
  return userHasSpinner.value(id);
});

const styles = computed(() => {
  if (props.connectivity) {
    return {};
  } else {
    return { width: props.pos.w + 'px', height: props.pos.w + 'px', top: top.value + 'px', left: left.value + 'px' };
  }
});

const moveToPlace = _.debounce(moveToPlaceRaw, 300);
function moveToPlaceRaw() {
  initialPos.value = true;
}

watch(
  top,
  (nv) => {
    if (nv) {
      moveToPlace();
    }
  },
  { immediate: true },
);

watch(hovered, (nv) => {
  if (props.user?.id) {
    if (nv) {
      usersStore.onHover(props.user.id, 'loader');
    } else {
      usersStore.onUnHover(props.user.id, 'loader');
    }
  }
});
</script>

<style lang="scss" scoped>
.loadingAvatar {
  border-radius: 50%;
  position: absolute;
  transform-origin: 50% 50%;
  z-index: 10;
  .pulser {
    transform-origin: 50% 50%;
    position: absolute;
    top: 16.667%;
    left: 16.667%;
    bottom: 16.667%;
    right: 16.667%;
    border-radius: 50%;
    background: var(--c__accent);
    content: '';
    box-shadow: var(--shadow);
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--c__bg);
    font-weight: bold;
  }
  &.animated {
    animation: soft-pulse 0.5s ease infinite alternate;
    .text {
      opacity: 0;
    }
  }
  &.solid {
    .pulser {
      opacity: 1;
    }
  }
}

@keyframes soft-pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.loadingAvatar.loader-enter-active.animated {
  transition: all 0.6s cubic-bezier(0.5, 0, 1, 0.5);
  &::before {
    animation: none;
  }
}

.loadingAvatar.loader-leave-active.animated {
  transition: transform 0.5s cubic-bezier(0, 1, 0.5, 1), opacity 0.5s cubic-bezier(0, 0.5, 0.5, 1);
}

.loadingAvatar.loader-enter-from.animated {
  opacity: 0;
  transform: scale(0);
}
.loadingAvatar.loader-leave-to.animated {
  transform: scale(3);
  opacity: 0;
}
</style>
