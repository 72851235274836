import { eventEmitter } from '@/services/event-emitter.service';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    app.config.globalProperties.$ee = eventEmitter;
  },
};
