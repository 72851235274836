<template>
  <UseDraggable class="avatarSandbox" :initialValue="{ x: 18, y: 18 }">
    <header class="sizeData">
      {{ contW }}x{{ contH }}px, <strong>{{ containerRatio }}:1</strong>
    </header>
    <section v-if="show === 'users'" key="users">
      <h2>Users ({{ visible.length }})</h2>
      <button class="addUser" @click="usersStore.addSandboxUser" :disabled="visible.length >= 100">Add a user</button>
      <ul class="userList">
        <li class="everyone">
          <header>
            <button class="userName" @click="toggleShowing('allUsers')">Everyone</button>
            <button class="showHideControls" @click="toggleShowing('allUsers')">
              <span v-if="showingControls.allUsers">Hide Controls</span>
              <span v-if="!showingControls.allUsers">Show Controls</span>
            </button>
          </header>
          <div class="controls" v-if="showingControls.allUsers">
            <button @click="usersStore.updateAllUsers({ vote: 'yes' })">Vote Yes</button>
            <button @click="usersStore.updateAllUsers({ vote: 'no' })">Vote No</button>
            <button @click="usersStore.updateAllUsers({ vote: 'unsure' })">Vote unsure</button>
            <button @click="randomVotesForAll">Random Votes</button>
          </div>
        </li>
        <li v-for="user in visible" :key="'user-' + user.id">
          <header>
            <button class="userName" @click="toggleShowing(user.id)">{{ desensitizedFirstName(user) }} ( {{ Math.round(positions[user.id]?.w) }}px )</button>
            <button class="showHideControls" @click="toggleShowing(user.id)">
              <span v-if="showingControls[user.id]">Hide Controls</span>
              <span v-if="!showingControls[user.id]">Show Controls</span>
            </button>
            <button class="removeUser" @click="usersStore.usersLeft({ users: [user] })" :disabled="user.id === me.id">Remove</button>
          </header>
          <div class="controls" v-if="showingControls[user.id]">
            <button :disabled="user.id === speaking.id" @click="makeSpeaker(user.id)">Speaker</button>
            <button :disabled="user.owner" @click="makeOwner(user.id)">Owner</button>
            <button @click="usersStore.updateUser(user.id, { vote: 'yes' })">Vote Yes</button>
            <button @click="usersStore.updateUser(user.id, { vote: 'no' })">Vote No</button>
            <button @click="usersStore.updateUser(user.id, { vote: 'unsure' })">Vote Neutral</button>
            <button v-if="!user.inWaitingRoom" @click="usersStore.usersJoinWaitingRoom({ users: [user] })">Waiting Room</button>
            <button v-if="user.inWaitingRoom" @click="usersStore.usersLeaveWaitingRoom({ users: [user] })">Admit</button>
          </div>
        </li>
        <ul />
      </ul>
      <button class="addUser" @click="usersStore.removeSandboxUsers">Remove All Fake Users</button>
    </section>
    <section v-if="show === 'phases'" key="layouts">
      <h2>layouts</h2>
      <p>Current: {{ currentLayout }}</p>
      <div class="controls">
        <button @click="usersStore.setDebugLayout(false)">Auto</button>
        <button @click="usersStore.setDebugLayout('ring')">Ring</button>
        <button @click="usersStore.setDebugLayout('couch')">Sofa</button>
        <button @click="usersStore.setDebugLayout('context')">Context</button>
        <button @click="usersStore.setDebugLayout('grid')">grid</button>
        <button @click="usersStore.setDebugLayout('pagedRows')">Paged Rows</button>
        <button @click="usersStore.setDebugLayout('lowRow')">Low Row</button>
        <button @click="usersStore.setDebugLayout('input')">Presentation</button>
        <button @click="usersStore.setDebugLayout('fullscreen')">Sidebar</button>
        <button @click="usersStore.setDebugLayout('voted')">Voted</button>
        <button @click="usersStore.setDebugLayout('fishbowl')">fishbowl</button>
      </div>
      <h2>Phase Layouts</h2>
      <div class="controls">
        <button @click="usersStore.setDebugLayout('lobby')">Lobby</button>
        <button @click="usersStore.setDebugLayout('context')">Context</button>
        <button @click="usersStore.setDebugLayout('review')">Review</button>
        <button @click="usersStore.setDebugLayout('endMeeting')">End Meeting</button>
      </div>
    </section>
    <nav>
      <button @click="show = 'users'">Users</button>
      <button @click="show = 'phases'">Layouts</button>
    </nav>
  </UseDraggable>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useFeaturesStore } from '@/store/pinia/features';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import _ from 'underscore';

import { UseDraggable } from '@vueuse/components';
import utils from '../resources/utils';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const meetingStore = useMeetingStore();
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { featuresStore, meetingStore, frameStore, phaseStore, usersStore };
  },
  components: {
    UseDraggable,
  },
  data() {
    return {
      showUsers: false,
      show: 'users',
      showingControls: {},
    };
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['screenSharing']),
    ...mapPiniaState(usePhaseStore, ['settingUp', 'settingUpPhase', 'collectedVotes', 'votes']),
    ...mapPiniaState(useUsersStore, ['positions', 'usersVisible', 'myGroup', 'groups', 'all', 'me', 'owner', 'speaking', 'layout', 'debugLayout']),
    ...mapPiniaState(useFrameStore, ['containerRatio', 'fullscreen']),
    ...mapPiniaState(useFrameStore, {
      contH: 'contHeight',
      contW: 'contWidth',
      h: 'viewportH',
      w: 'viewportW',
    }),
    visible() {
      if (!this.meetingStore.initTitleCardShown || !this.all || this.all.length < 1) {
        return [];
      } else if (this.phaseStore.isPresentation && !this.screenSharing) {
        return this.all.filter((u) => !u.gone && u.id !== this.speaking.id);
      } else {
        return this.usersVisible;
      }
    },
    positionArgs() {
      return {
        speaking: this.speaking && this.speaking.id,
        users: this.visible.map((u) => u.id),
        all: this.all && this.all.map && this.all.map((u) => u.id),
        me: this.me?.id,
        iAmAway: this.usersStore.iAmAway,
        iAmAwayInGroup: this.usersStore.iAmAwayInGroup,
        settingUpPhase: this.settingUpPhase,
        owner: this.owner && this.owner.id,
        screen: this.currentPhase,
        groups: this.groups,
        group: this.myGroup,
        votes: this.collectedVotes,
        screenStage: this.phaseStore.phaseStage,
        screenSharing: this.screenSharing,
        fullscreen: this.fullscreen,
        customGroups: this.featuresStore.customGroups,
        contWidth: this.frameStore.contWidth,
        ringWidth: this.frameStore.ringWidth,
        contHeight: this.frameStore.contHeight,
        ringHeight: this.frameStore.ringHeight,
        ringTop: this.frameStore.ringTop,
        ringLeft: this.frameStore.ringLeft,
      };
    },
    currentLayout() {
      if (this.debugLayout) {
        return this.layout + ' (debug)';
      } else {
        return this.layout + ' (auto)';
      }
    },
  },
  methods: {
    desensitizedFirstName(user) {
      return user && user.first_name ? utils.desanitizeString(user.first_name) : '';
    },
    makeSpeaker(id) {
      this.usersStore.updateSandboxSpeaker(id);
    },
    makeOwner(id) {
      this.usersStore.updateSandboxOwner(id);
    },
    randomVotesForAll() {
      this.visible.forEach((user) => {
        let vote = _.sample(['yes', 'no', 'unsure']);
        this.usersStore.updateUser(user.id, { vote });
      });
    },
    update() {
      this.$nextTick(() => {
        this.$ee.emit('nM:sandboxUpdate');
      });
    },
    toggleShowing(id) {
      this.showingControls[id] = !this.showingControls[id];
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarSandbox {
  z-index: 9999;
  position: absolute;
  top: 1em;
  left: 1em;
  border: 0.5em solid white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  border-radius: 1em;
  color: white;
  width: 25em;
  background: rgba(#333333, 0.9);
}

.avatarSandbox > header {
  display: block;
  padding: 1em;
  border-bottom: 2px solid currentColor;
}

button {
  flex: 1 1 auto;
  background: transparent;
  color: white;
  border: 1px solid white;
  @include triggered {
    background: $c_accent;
  }
  &:disabled {
    border: 1px solid $c_dark-mid;
    color: $c_dark-mid;
    background: transparent;
  }
}

nav {
  display: flex;
  border-top: 2px solid currentColor;
  button {
    border: none;
    padding: 0.5em;
  }
  button:first-child {
    border-radius: 0 0 0 0.5em;
  }
  button:last-child {
    border-radius: 0 0 0.5em 0;
  }
}
h2 {
  text-align: center;
  margin-bottom: 0.5em;

  @include type-size(medium);
  button {
    text-transform: uppercase;
    border: none;
    font-weight: bold;
    padding: 0;
    width: 100%;
    position: relative;
    text-align: left;
    justify-content: space-between;
    color: var(--c__text);
    &::after {
      content: '\25BC';
      font-size: 0.5em;
      line-height: 2em;
    }
    @include triggered {
      background: transparent;
      color: white;
    }
  }
  &.current button::after {
    content: '\25B6';
  }
}

div + h2 {
  margin-top: 1em;
}
section {
  padding: 1em;
}
section + section {
  margin-top: 1em;
}
label {
  font-weight: bold;
  display: block;
}
label + label {
  margin-top: 0.5em;
}
input {
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  &:focus {
    border-bottom: 1px solid $c_accent;
    outline: none;
  }
}
section > * + * {
  margin-top: 1em;
}
ul {
  list-style: none;
}
li + li {
  margin-top: 0.5em;
}

.controls button {
  margin: 0.25em;
}

.controls {
  display: flex;
  flex-wrap: wrap;
}

.addUser,
.removeAll {
  width: 100%;
  padding: 0.5em;
}
.userList {
  max-height: 50vh;
  max-height: calc(var(--vh, 1vh) * 70);
  overflow: scroll;
  header {
    display: flex;
    justify-content: space-between;
    button {
      flex: 0 0 auto;
      border: none;
    }
    .userName {
      padding: 0;
      text-align: left;
      font-weight: bold;
      margin-right: auto;
    }
  }
  .controls {
    margin-bottom: 1em;
  }
}
</style>
