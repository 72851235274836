<template>
  <span class="icon" :class="extraClass" v-html="svg" />
</template>

<script>
import iconSvgs from '@/resources/icon-svgs';

export default {
  props: ['icon'],
  computed: {
    svg() {
      return iconSvgs(this.icon);
    },
    extraClass() {
      return this.icon + '-icon';
    },
  },
};
</script>
