<template>
  <div class="avatarBacking" :style="pos">
    <Transition name="randomiser">
      <div v-if="hasRandom" class="randomBorder"></div>
    </Transition>
    <div class="avatarBorder" :style="borderStyles"></div>
    <div class="avatarShadow"></div>
  </div>
</template>

<script>
import _ from 'underscore';
import { colors } from '@/resources/colors';
import { useFeaturesStore } from '@/store/pinia/features';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    return { featuresStore, meetingStore, usersStore };
  },
  components: {},
  props: ['user', 'pos', 'index', 'count'],
  data() {
    return {
      borders: 0,
      animationStyles: {},
    };
  },
  mounted() {
    let _this = this;
    this.$ee.on(`nM:avatar:emote:${this.user.id}`, _this.emote);
  },
  beforeUnmount() {
    let _this = this;
    this.$ee.off(`nM:avatar:emote:${this.user.id}`, _this.emote);
  },
  computed: {
    hasRandom() {
      return this.usersStore.randomTicker === this.user.id;
    },
    isEmoting() {
      let emotes = [this.borders];
      return emotes.reduce((a, c) => a + c) > 0;
    },
    extraBorder() {
      if (this.meetingStore.space !== 'neon') {
        return 'var(--c__avatar-border-edge)';
      } else {
        let idNum = parseInt(this.user.id);
        let colorId = idNum % colors.neonList.length;
        return colors.neonList[colorId];
      }
    },
    borderStyles() {
      return {
        '--extraBorder': this.extraBorder,
        ...this.animationStyles,
      };
    },
  },
  watch: {},
  methods: {
    emote(c) {
      var emotes = {
        yes: [this.longBorder],
        no: [this.longBorder],
        what: [this.longBorder],
        smile: [this.longBorder],
        gasp: [this.border],
        eek: [this.border],
        grin: [this.border],
        grr: [this.border],
        sad: [this.longBorder],
        wave: [this.longBorder],
        tongue: [this.border],
        uhh: [this.border],
        whoa: [this.border],
        heart: [this.heartBorder],
        star: [this.border],
      };

      if (emotes[c]) {
        emotes[c].forEach((f) => {
          f(c);
        });
      }
    },
    border(c, length) {
      this.borders++;
      if (this.featuresStore.useBadgeEmotes) {
        length = 1000;
      } else {
        length = length || 800;
      }
      this.animationStyles = {
        backgroundColor: colors[c],
        color: colors[c],
      };
      _.delay(() => {
        this.borders--;
        if (this.borders < 1) {
          this.borders = 0;
          this.animationStyles = {};
        }
      }, length);
    },
    longBorder(c) {
      this.border(c, 1300);
    },
    heartBorder(c) {
      this.border(c, 800);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarBacking {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50% !important;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
}
.avatarBorder,
.avatarShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
}

.avatarShadow {
  background: transparent;
  box-shadow: 0 1px 4px rgba(black, 0.3), 0 1px 10px rgba(black, 0.1);
  .simplifiedGraphics &,
  .bad-cpu &,
  .low-cpu & {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.avatarBorder {
  transition: background-color 300ms, color 300ms;
  border-width: 2px;
  border-style: solid;
  color: var(--extraBorder);
  background: var(--c__avatar-border);
}

.speaking .avatarBorder {
  color: var(--extraBorder);
  .skillBar-setup &,
  .checkIn &,
  .checkIn-animateIn &,
  .checkOut &,
  .checkOut-animateIn &,
  .input &,
  .input-animateIn &,
  .context &,
  .context-animateIn &,
  .review &,
  .review-animateIn & {
    background-color: var(--c__active);
    color: var(--c__active);
  }
}

@each $class, $color in $avatar-borders {
  .#{$class} .avatarBorder {
    background-color: $color;
    color: $color;
  }
}

.speaking .avatarBorder {
  color: var(--extraBorder);
}

.speaking.yes .avatarBorder {
  color: var(--c__green);
}
.speaking.no .avatarBorder {
  color: var(--c__red);
}
.speaking.voted .avatarBorder {
  color: var(--c__yellow);
}

.decision {
  .end,
  .speaking.end,
  .overdue,
  .speaking.overdue {
    &.avatar .avatarBorder {
      background: var(--c__bg);
      color: var(--extraBorder);
    }

    .voted.avatar .avatarBorder,
    .voted.speaking.avatar .avatarBorder {
      background: var(--c__yellow);
      color: var(--c__yellow);
    }
    .yes.avatar .avatarBorder,
    .yes.speaking.avatar .avatarBorder {
      background: var(--c__green);
      color: var(--c__green);
    }
    .no.avatar .avatarBorder,
    .no.speaking.avatar .avatarBorder {
      background: var(--c__red);
      color: var(--c__red);
    }
  }
}

.randomiser-enter-active {
  transition: opacity 0.15s;
}

.randomiser-leave-active {
  transition: opacity 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.randomiser-enter-from,
.randomiser-leave-to {
  opacity: 0;
}

.randomBorder {
  background: var(--c__active);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  border-radius: 50%;
}
</style>
