const CONCENTRIC_RINGS = {
  1: [{ users: 1, positions: false }],
  2: [{ users: 2, positions: false }],
  3: [{ users: 3, positions: false, vMod: 0.25 }],
  4: [{ users: 4, positions: false }],
  5: [{ users: 5, positions: false }],
  6: [{ users: 6, positions: false }],
  7: [{ users: 7, positions: false }],
  8: [{ users: 8, positions: false }],
  9: [{ users: 9, positions: false }],
  10: [{ users: 10, positions: false }],
  11: [
    { users: 6, positions: false, turnMod: -1 / 12, sizeMod: 1.25, vMod: 0.3 },
    { users: 5, positions: false, turnMod: 7 / 12 },
  ],
  12: [
    { users: 6, positions: false, sizeMod: 1.25 },
    { users: 6, positions: false },
  ],
  13: [
    { users: 7, positions: false, sizeMod: 1.2, vMod: 0.25 },
    { users: 6, positions: false, turnMod: 4 / 7 },
  ],
  14: [
    { users: 7, positions: false, sizeMod: 1.2 },
    { users: 7, positions: false },
  ],
  15: [
    { users: 8, positions: false, sizeMod: 1.1, turnMod: 0.5 / 8, vMod: 0.25 },
    { users: 7, positions: 8, turnMod: 4.5 / 8 },
  ],
  16: [
    { users: 8, positions: false, sizeMod: 1.1 },
    { users: 8, positions: false },
  ],
  17: [
    { users: 9, positions: false, sizeMod: 1.2, vMod: 0.2 },
    { users: 8, positions: false, turnMod: 5 / 9 },
  ],
  18: [
    { users: 6, positions: false, sizeMod: 1.5 },
    { users: 6, positions: false },
    { users: 6, positions: false },
  ],
  19: [
    { users: 10, positions: false, sizeMod: 1.2, turnMod: -1 / 20, vMod: 0.2 },
    { users: 9, positions: 10, turnMod: 11 / 20 },
  ],
  20: [
    { users: 10, positions: false, sizeMod: 1.2 },
    { users: 10, positions: false },
  ],
  21: [
    { users: 11, positions: false, sizeMod: 1.2, vMod: 0.2 },
    { users: 10, positions: 11, turnMod: 11 / 20 },
  ],
  22: [
    { users: 11, positions: false, sizeMod: 1.2 },
    { users: 11, positions: false },
  ],
  23: [
    { users: 12, positions: false, sizeMod: 1.35 },
    { users: 11, positions: 12 },
  ],
  24: [
    { users: 12, positions: false, sizeMod: 1.35 },
    { users: 12, positions: false },
  ],
  25: [
    { users: 13, positions: false, sizeMod: 1.35 },
    { users: 12, positions: 13 },
  ],
  26: [
    { users: 13, positions: false, sizeMod: 1.35 },
    { users: 13, positions: 13 },
  ],
  27: [
    { users: 13, positions: false, sizeMod: 1.35 },
    { users: 12, positions: 13 },
  ],
  28: [
    { users: 7, positions: false },
    { users: 7, positions: false },
    { users: 7, positions: false },
    { users: 7, positions: false },
  ],
  29: [
    { users: 15, positions: false, sizeMod: 1.35 },
    { users: 14, positions: 13 },
  ],
  30: [
    { users: 15, positions: false, sizeMod: 1.35 },
    { users: 15, positions: 13 },
  ],
};

const CIRCLE_PACK = {
  // Maaaaagic~
  1: 1.75,
  2: 2,
  3: 2.154,
  4: 2.414,
  5: 2.701,
  6: 3,
  7: 3.304,
  8: 3.613,
  9: 3.923,
  10: 4.236,
  11: 4.58,
  12: 4.863,
  13: 5.21,
  14: 5.53,
  15: 5.86,
  16: 6.21,
  17: 6.54,
  18: 6.85,
  19: 7.15,
  20: 7.5,
};

export { CONCENTRIC_RINGS, CIRCLE_PACK };
