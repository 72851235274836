import _ from 'underscore';
import { controls } from '../resources/icons/controls-icons.js';
import { emotes } from '../resources/icons/emotes-icons.js';
import { phases } from '../resources/icons/phase-icons.js';
import { log } from '../resources/icons/log-icons.js';

const icons = {
  ...controls,
  ...emotes,
  ...phases,
  ...log,
  'menu-hamburger': `
    <svg version="1.1" viewBox="0 0 32 32">
      <line class="bar stroke" x1="0" x2="32" y1="6" y2="6" stroke-width="2" />
      <line class="bar stroke" x1="0" x2="32" y1="16" y2="16" stroke-width="2" />
      <line class="bar stroke" x1="0" x2="32" y1="26" y2="26" stroke-width="2" />
    </svg>
  `,
  'menu-cross': `
    <svg version="1.1" viewBox="0 0 32 32">
      <line class="stroke bar" x1="4" x2="28" y1="4" y2="28" stroke-width="2" />
      <line class="stroke bar" x1="4" x2="28" y1="28" y2="4" stroke-width="2" />
    </svg>
  `,
  'menu-participants': `
  <svg viewBox="0 0 20 17">
    <path class="stroke stroke-mid" d="m14.3,7.09c-1.24,0-2.25-.96-2.25-2.14s1.01-2.14,2.25-2.14,2.25.96,2.25,2.14-1.01,2.14-2.25,2.14"/>
    <path class="stroke stroke-mid" d="m17.23,14.8h-5.87c-.86,0-1.57-.64-1.57-1.43v-2.02c0-1.41,1.25-2.55,2.79-2.55h3.41c1.54,0,2.79,1.14,2.79,2.55v2.02c0,.79-.7,1.43-1.57,1.43h0Z"/>
    <path class="stroke stroke-mid revFill" d="m9.89,15.8H2.71c-1.06,0-1.91-.8-1.91-1.79v-2.52c0-1.76,1.53-3.19,3.41-3.19h4.17c1.88,0,3.41,1.43,3.41,3.19v2.52c0,.99-.86,1.79-1.91,1.79h0Z"/>
    <path class="stroke stroke-mid" d="m6.3,6.16c-1.52,0-2.75-1.2-2.75-2.68S4.78.8,6.3.8s2.75,1.2,2.75,2.68-1.23,2.68-2.75,2.68"/></svg>
  `,
  po3: `
  <svg version="1.1" viewBox="0 0 28 26">
    <path class="fill" d="M2.05,24.12H0V.11H9a6.82,6.82,0,0,1,7.24,7,6.84,6.84,0,0,1-7.24,7H2.05ZM8.78,12.28a5,5,0,0,0,5.29-5.15A5,5,0,0,0,8.78,2H2.05v10.3Z"/>
    <path class="fill" d="M22.66,8.27A4,4,0,0,1,19.51,7l.9-1.14a3.14,3.14,0,0,0,2.2.91c1,0,1.51-.42,1.51-1s-.49-.92-1.61-.92l-1,0V3.3c.14,0,.7,0,1,0C23.4,3.31,24,3,24,2.46s-.66-.95-1.49-.95a2.89,2.89,0,0,0-2,.83l-.85-1.07A3.94,3.94,0,0,1,22.68,0c1.87,0,3,.84,3,2.17A2,2,0,0,1,23.95,4a2,2,0,0,1,1.87,1.92C25.82,7.3,24.58,8.27,22.66,8.27Z"/>
    <path class="fill" d="M20.79,25.12a7,7,0,1,0-7-7A7,7,0,0,0,20.79,25.12Zm0-2a5,5,0,1,1,5-5A5,5,0,0,1,20.79,23.12Z"/>
  </svg>
  `,
  microskills: `
  <svg version="1.1" viewBox="0 0 34 34">
    <g>
      <path class="stroke" d="M20.17,4.42A3.42,3.42,0,1,1,16.75,1a3.42,3.42,0,0,1,3.42,3.42Z" stroke-width="2"/>
      <path class="stroke" d="M20.17,29.08a3.42,3.42,0,1,1-3.42-3.42,3.42,3.42,0,0,1,3.42,3.42Z" stroke-width="2"/>
      <path class="stroke" d="M27.89,10.46a3.42,3.42,0,1,1,0-4.84,3.42,3.42,0,0,1,0,4.84Z" stroke-width="2"/>
      <path class="stroke" d="M10.46,27.89a3.42,3.42,0,1,1,0-4.84,3.42,3.42,0,0,1,0,4.84Z" stroke-width="2"/>
      <path class="stroke" d="M29.08,20.17a3.42,3.42,0,1,1,3.42-3.42,3.42,3.42,0,0,1-3.42,3.42Z" stroke-width="2"/>
      <path class="stroke" d="M4.42,20.17a3.42,3.42,0,1,1,3.42-3.42,3.42,3.42,0,0,1-3.42,3.42Z" stroke-width="2"/>
      <path class="stroke" d="M23,27.89a3.42,3.42,0,1,1,4.84,0,3.42,3.42,0,0,1-4.84,0Z" stroke-width="2"/>
      <path class="stroke" d="M5.61,10.46a3.42,3.42,0,1,1,4.84,0,3.42,3.42,0,0,1-4.84,0Z"stroke-width="2"/>
    </g>
    <g>
      <path class="stroke" d="M10.94,27.26a11.82,11.82,0,0,0,2.46,1.14" stroke-width="2"/>
      <path class="stroke" d="M5.56,20a11.8,11.8,0,0,0,.91,2.47" stroke-width="2"/>
      <path class="stroke" d="M6.77,11.21a11.81,11.81,0,0,0-1,2.37" stroke-width="2"/>
      <path class="stroke" d="M13.59,5.73a11.82,11.82,0,0,0-2.37,1" stroke-width="2"/>
      <path class="stroke" d="M22.44,6.47A11.8,11.8,0,0,0,20,5.56" stroke-width="2"/>
      <path class="stroke" d="M28.4,13.39a11.82,11.82,0,0,0-1.14-2.46" stroke-width="2"/>
      <path class="stroke" d="M27.56,22.78a11.83,11.83,0,0,0,1-2.65" stroke-width="2"/>
      <path class="stroke" d="M20.13,28.6a11.83,11.83,0,0,0,2.65-1" stroke-width="2"/>
    </g>
  </svg>
  `,
  spaces: `
  <svg version="1.1" viewBox="0 0 32 22">
    <circle class="stroke stroke-mid" cx="14" cy="8" r="2.3"/>
    <polygon class="stroke stroke-mid" points="4 21 9.33 14 15 21 4 21"/>
    <polygon class="stroke stroke-mid" points="14 21 21.26 8 29 21 14 21"/>
    <rect class="stroke stroke-broad" x="1" y="1" width="30" height="20"/>
  </svg>
  `,
  'toggle-notes': `
  <svg version="1.1" viewBox="0 0 34 34">
    <rect class="stroke stroke-broad" x="1" y="1" width="32" height="32" rx="2" ry="2"/>
    <rect class="fill" x="7" y="25" width="19" height="2"/>
    <rect class="fill" x="7" y="19" width="19" height="2"/>
    <rect class="fill" x="7" y="13" width="19" height="2"/>
    <rect class="fill" x="7" y="7" width="19" height="2"/>
  </svg> 
  `,
  invite: `
  <svg version="1.1" viewBox="0 0 26 30">
    <path class="stroke" d="M11,11a5,5,0,1,1,5-5,5,5,0,0,1-5,5" stroke-width="2"/>
    <path class="stroke" d="M17.52,29h-13A3.42,3.42,0,0,1,1,25.66V21a6.09,6.09,0,0,1,6.21-6h7.59A6.09,6.09,0,0,1,21,21v4.71A3.42,3.42,0,0,1,17.52,29Z" stroke-width="2"/>
    <line class="stroke" x1="22.5" y1="7" x2="22.5" y2="12" stroke-linecap="square" stroke-width="1.5"/>
    <line class="stroke" x1="20" y1="9.5" x2="25" y2="9.5" stroke-linecap="square" stroke-width="1.5"/>
  </svg>
  `,
  'meeting-time': `
  <svg version="1.1" viewBox="0 0 32 32">
    <circle class="stroke" cx="16" cy="16" r="15" stroke-width="2"/>
    <line class="stroke" x1="16" y1="16" x2="16" y2="5.89" stroke-linecap="square"/>
    <line class="stroke" x1="20.95" y1="20.95" x2="16" y2="16" stroke-linecap="square"/>
  </svg>
  `,
  wordmark: `
  <svg version="1.1" viewBox="0 0 43 20">
    <path class="fill b" fill-rule="evenodd" d="M6.53,16.99c-2.03,0-3.15-1.74-3.15-3.77s1.12-3.74,3.15-3.74,3.17,1.74,3.17,3.74-1.13,3.77-3.17,3.77Zm0-10.53c-1.24,0-2.34,.29-3.25,.79V0H0V13.23c0,3.66,2.47,6.78,6.53,6.78s6.55-3.13,6.55-6.79-2.46-6.76-6.55-6.76h0Z"/>
    <polygon class="fill i" fill-rule="evenodd" points="13.94 19.61 17.12 19.61 17.12 6.67 13.94 6.67 13.94 19.61"/>
    <polyline class="fill x" fill-rule="evenodd" points="27.63 19.63 24.66 15.31 21.67 19.63 17.88 19.63 22.51 13.06 18.14 6.83 21.97 6.83 24.66 10.75 27.33 6.83 31.16 6.83 26.82 13.06 31.45 19.63 27.63 19.63"/>
    <path class="fill e" fill-rule="evenodd" d="M36.73,9.09c-2.04,0-2.93,1.49-3.06,2.79h6.17c-.1-1.25-.93-2.79-3.11-2.79Zm-6.47,4.03c0-3.79,2.69-6.85,6.47-6.85s6.27,2.92,6.27,7.17v.81h-9.3c.23,1.6,1.48,2.92,3.6,2.92,1.06,0,2.51-.46,3.31-1.27l1.48,2.27c-1.24,1.19-3.21,1.81-5.16,1.81-3.8,0-6.68-2.68-6.68-6.88h0Z"/>
  </svg>
  `,
  'circle-logo': `
  <svg version="1.1" viewBox="0 0 124 124">
    <defs>
    <mask id="circ-UNIQ" x="0" y="0" width="124" height="124">
      <circle cx="62" cy="13.5" r="13.5" fill="white" />
      <circle cx="62" cy="110.5" r="13.5" fill="white"  />
      <circle cy="62" cx="13.5" r="13.5" fill="white"  />
      <circle cy="62" cx="110.5" r="13.5" fill="white"  />
      <circle cy="27.75" cx="27.75" r="13.5" fill="white"  />
      <circle cy="27.75" cx="96.25" r="13.5" fill="white"  />
      <circle cy="96.25" cx="27.75" r="13.5" fill="white"  />
      <circle cy="96.25" cx="96.25" r="13.5" fill="white"  />
    </mask>
    </defs>

    <circle cx="62" cy="13.5" r="13.5" mask="url(#circ-UNIQ)" class="stroke n" stroke-width="8"/>
    <circle cx="62" cy="110.5" r="13.5" mask="url(#circ-UNIQ)" class="stroke s" stroke-width="8"/>
    <circle cy="62" cx="13.5" r="13.5" mask="url(#circ-UNIQ)" class="stroke w" stroke-width="8"/>
    <circle cy="62" cx="110.5" r="13.5"mask="url(#circ-UNIQ)" class="stroke e" stroke-width="8" />
    <circle cy="27.75" cx="27.75" r="13.5" mask="url(#circ-UNIQ)" class="stroke nw" stroke-width="8"/>
    <circle cy="27.75" cx="96.25" r="13.5" mask="url(#circ-UNIQ)" class="stroke ne" stroke-width="8"/>
    <circle cy="96.25" cx="27.75" r="13.5" mask="url(#circ-UNIQ)" class="stroke sw" stroke-width="8"/>
    <circle cy="96.25" cx="96.25" r="13.5" mask="url(#circ-UNIQ)" class="stroke se" stroke-width="8"/>
  </svg>
  `,

  miniBreak: `
  <svg version="1.1" viewBox="0 0 24 24">
    <path class="stroke" d="M16.48,8.66c2.76,0,5,1.79,5,4s-2.24,4-5,4" stroke-width="1.4"/>
    <path class="stroke" d="M16.51,6.24v9.69h0A6.15,6.15,0,0,1,10.25,22,6.15,6.15,0,0,1,4,15.93H4V6.24" stroke-width="1.4"/>
    <line class="stroke" x1="4.01" y1="10.67" x2="16.48" y2="10.67" stroke-width="0.7"/>
    <path class="stroke" d="M8.38,2c-2.49,3.63,2.49,3,0,6.66" stroke-width="0.7"/>
    <path class="stroke" d="M12,2c-2.49,3.63,2.49,3,0,6.66" stroke-width="0.7"/>
  </svg>
  `,
  leave: `
  <svg version="1.1" viewBox="0 0 29 22">
    <g transform="translate(6 1)">
      <line class="stroke" x1="7.06" y1="10.28" x2="20.2" y2="10.28" stroke-linecap="round" stroke-width="0.9"/>
      <line class="stroke" x1="16.09" y1="6.99" x2="20.2" y2="10.28" stroke-linecap="round" stroke-width="0.9"/>
      <line class="stroke" x1="16.09" y1="13.56" x2="20.2" y2="10.28" fill="none" stroke="#000" stroke-linecap="round" stroke-width="0.9"/>
      <polyline class="stroke" points="13.14 13.38 13.14 19.62 0.9 19.62 0.9 0.9 13.14 0.9 13.14 7.14" stroke-linejoin="round" stroke-width="1.8" />
    </g>
  </svg>

  `,
  finish: `
  <svg version="1.1" viewBox="0 0 24 24">
    <polygon class="stroke" points="16.52 12.05 5.53 19.05 5.53 5.05 16.52 12.05" stroke-linejoin="round" stroke-width="1.5"/>
    <line class="stroke" x1="17.5" y1="5.5" x2="17.5" y2="18.5" stroke-linecap="round" stroke-width="1.5"/>
  </svg>

  `,
  skip: `
  <svg version="1.1" viewBox="0 0 26 14">
    <line class="stroke" x1="0.7" y1="6.61" x2="24.35" y2="6.61" stroke-linecap="round" stroke-width="1.4"/>
    <line  class="stroke" x1="16.96" y1="0.7" x2="24.35" y2="6.61" stroke-linecap="round" stroke-width="1.4"/>
    <line  class="stroke" x1="16.96" y1="12.52" x2="24.35" y2="6.61" stroke-linecap="round" stroke-width="1.4"/>
  </svg>
  `,
  'yes-vote': `
    <svg version="1.1" viewBox="0 0 23 23">
    <g transform="scale(-1, 1) translate(-21.5, 0)">
      <path class="fill" fill-rule="evenodd" d="M19,14.85c0-1.17,0-1.09,0-2.26a6.22,6.22,0,0,0-.9-3c-1.15-2-4.11-2.45-5.54-4.26A7,7,0,0,1,11.12.92,4.07,4.07,0,0,1,11.2.07a2.45,2.45,0,0,0-1-.06,3,3,0,0,0-.9.23A2.36,2.36,0,0,0,8.13,1.67,7.56,7.56,0,0,0,8.28,6.3,8.32,8.32,0,0,0,9,7.93L8,8a1.92,1.92,0,0,1,.47,1.28,2.24,2.24,0,0,1-1.28,2,2.35,2.35,0,0,1,0,4.1,2.16,2.16,0,0,1,1.18,1.92A2,2,0,0,1,7.5,19h.6a2,2,0,0,1,2,2,1.72,1.72,0,0,1-.4,1.13c1.61-.32,3.21-.62,4.83-.9s3-.58,3.83-2a4.72,4.72,0,0,0,.54-1.8,22.52,22.52,0,0,0,0-2.54"/>
      <path class="fill" fill-rule="evenodd" d="M6.86,8.14A1.73,1.73,0,0,0,6,7.92H6L2.06,7.74a1.51,1.51,0,1,0,0,3H6A1.51,1.51,0,0,0,7.49,9.25a1.24,1.24,0,0,0-.64-1.11"/>
      <path class="fill" fill-rule="evenodd" d="M5.78,11.7H1.62a1.62,1.62,0,1,0,0,3.24h.3l.25,0H6a1.62,1.62,0,0,0-.21-3.23"/>
      <path class="fill" fill-rule="evenodd" d="M6,15.88h-4a1.41,1.41,0,0,0,.21,2.8H2.7l3.23-.08a1.33,1.33,0,0,0,1.41-1.32A1.41,1.41,0,0,0,6,15.88"/>
      <path class="fill" fill-rule="evenodd" d="M7.84,19.69H4.42a1.3,1.3,0,0,0,0,2.59l.41,0,3-.16h0A1.16,1.16,0,0,0,9.14,21a1.3,1.3,0,0,0-1.3-1.3"/>
      </g>
    </svg>
  `,
  'unsure-vote': `
    <svg version="1.1" viewBox="0 0 23 23">
    <g transform="scale(-1, 1) translate(-21.5, 1)  rotate(90, 10, 11)">
      <path class="fill" fill-rule="evenodd" d="M19,14.85c0-1.17,0-1.09,0-2.26a6.22,6.22,0,0,0-.9-3c-1.15-2-4.11-2.45-5.54-4.26A7,7,0,0,1,11.12.92,4.07,4.07,0,0,1,11.2.07a2.45,2.45,0,0,0-1-.06,3,3,0,0,0-.9.23A2.36,2.36,0,0,0,8.13,1.67,7.56,7.56,0,0,0,8.28,6.3,8.32,8.32,0,0,0,9,7.93L8,8a1.92,1.92,0,0,1,.47,1.28,2.24,2.24,0,0,1-1.28,2,2.35,2.35,0,0,1,0,4.1,2.16,2.16,0,0,1,1.18,1.92A2,2,0,0,1,7.5,19h.6a2,2,0,0,1,2,2,1.72,1.72,0,0,1-.4,1.13c1.61-.32,3.21-.62,4.83-.9s3-.58,3.83-2a4.72,4.72,0,0,0,.54-1.8,22.52,22.52,0,0,0,0-2.54"/>
      <path class="fill" fill-rule="evenodd" d="M6.86,8.14A1.73,1.73,0,0,0,6,7.92H6L2.06,7.74a1.51,1.51,0,1,0,0,3H6A1.51,1.51,0,0,0,7.49,9.25a1.24,1.24,0,0,0-.64-1.11"/>
      <path class="fill" fill-rule="evenodd" d="M5.78,11.7H1.62a1.62,1.62,0,1,0,0,3.24h.3l.25,0H6a1.62,1.62,0,0,0-.21-3.23"/>
      <path class="fill" fill-rule="evenodd" d="M6,15.88h-4a1.41,1.41,0,0,0,.21,2.8H2.7l3.23-.08a1.33,1.33,0,0,0,1.41-1.32A1.41,1.41,0,0,0,6,15.88"/>
      <path class="fill" fill-rule="evenodd" d="M7.84,19.69H4.42a1.3,1.3,0,0,0,0,2.59l.41,0,3-.16h0A1.16,1.16,0,0,0,9.14,21a1.3,1.3,0,0,0-1.3-1.3"/>
      </g>
    </svg>
  `,
  'no-vote': `
    <svg version="1.1" viewBox="0 0 23 23">
    <g transform="scale(-1, 1) translate(-21.5, 0)">
        <path class="fill" fill-rule="evenodd" d="M19.4,5.1a4.78,4.78,0,0,0-.53-1.8c-1.11-2-3.81-2-5.78-2.41C12.23.73,7.45.18,5.91.08L5.09,0,4.62,0a1.67,1.67,0,0,0-1.5,1.8,2.15,2.15,0,0,0,.08.57,1.74,1.74,0,0,0,.77,1l-1,0H2.43A1.81,1.81,0,0,0,.81,5.3,1.93,1.93,0,0,0,1.94,7.14H1.82A1.91,1.91,0,0,0,0,9.12a1.91,1.91,0,0,0,1.82,2h0A1.89,1.89,0,0,0,.56,12.94a1.82,1.82,0,0,0,1.71,1.91L5,14.71H5c1.23-.06,3.74-.09,4.47-.1a8.17,8.17,0,0,0-.74,1.58,7.57,7.57,0,0,0-.15,4.62,2.36,2.36,0,0,0,1.19,1.42,3,3,0,0,0,.9.23,2.51,2.51,0,0,0,1-.06,3.93,3.93,0,0,1-.08-.84A7,7,0,0,1,13,17.17c1.43-1.81,4.39-2.21,5.54-4.26a6.26,6.26,0,0,0,.9-3c0-1.18,0-1.09,0-2.26a22.54,22.54,0,0,0,0-2.54"/>
        </g>
    </svg>
  `,
  'mute-indicator': `
    <svg version="1.1" viewBox="0 0 40 40">
      <g>
        <rect class="fill" x="19.13" y="28.19" width="1.93" height="3.85" />
        <rect class="fill" x="14.31" y="32.04" width="11.56" height="1" />
        <path class="stroke" d="M20.09,7.5h0a5.28,5.28,0,0,1,5.28,5.28v7.7a5.28,5.28,0,0,1-5.28,5.28h0a5.28,5.28,0,0,1-5.28-5.28v-7.7A5.28,5.28,0,0,1,20.09,7.5Z" />
        <rect class="fill" x="18.17" y="7.96" width="1" height="6.74" />
        <rect class="fill" x="21.06" y="7.96" width="1" height="6.74" />
        <path class="stroke" d="M11.43,20.48a8.67,8.67,0,1,0,17.33,0" stroke-width="2"/>
      </g>
      <line class="stroke hover-revStroke" x1="30.67" y1="9.41" x2="9.29" y2="30.79" stroke-linecap="square"/>
    </svg>
  `,
  'up-arrow': `
    <svg version="1.1" viewBox="0 0 21 12">
      <path class="fill" d="M20.84,10.44l-1.41,1.39a.52.52,0,0,1-.73,0L10.43,4,2.32,11.86a.53.53,0,0,1-.74,0L.16,10.44a.51.51,0,0,1,0-.73L10.05.14a.53.53,0,0,1,.74,0L12,1.3l8.9,8.42A.5.5,0,0,1,20.84,10.44Z"/>
    </svg>
  `,
  'down-arrow': `
  <svg version="1.1" viewBox="0 0 22 13">
    <path class="fill" d="M21.35,2.78l-8.9,8.42-1.16,1.14a.53.53,0,0,1-.74,0L.65,2.78a.51.51,0,0,1,0-.73L2.08.66a.53.53,0,0,1,.74,0l8.11,7.84L19.2.66a.52.52,0,0,1,.73,0l1.41,1.39A.5.5,0,0,1,21.35,2.78Z"/>
  </svg>
  `,
  'left-arrow': `
  <svg version="1.1" viewBox="0 0 12 21">
    <path class="fill" d="M10.44.16l1.39,1.41a.52.52,0,0,1,0,.73L4,10.57l7.84,8.11a.53.53,0,0,1,0,.74l-1.4,1.43a.51.51,0,0,1-.73,0L.14,10.95a.53.53,0,0,1,0-.74L1.3,9,9.72.15A.5.5,0,0,1,10.44.16Z"/>
  </svg>
  `,
  'right-arrow': `
  <svg version="1.1" viewBox="0 0 12 21">
    <path class="fill" d="M2.28.15,10.7,9l1.14,1.16a.53.53,0,0,1,0,.74l-9.57,9.9a.51.51,0,0,1-.73,0L.16,19.42a.53.53,0,0,1,0-.74L8,10.57.16,2.3a.52.52,0,0,1,0-.73L1.56.16A.5.5,0,0,1,2.28.15Z"/>
  </svg>
  `,
  clock: `
    <svg version="1.1" viewBox="0 0 26 26">
      <path class="stroke" d="M4.51,4.51A12,12,0,1,0,13.07,1" stroke-linecap="round" stroke-width="2"/>
      <line class="stroke" x1="13.5" y1="12.5" x2="7.5" y2="6.5" stroke-linecap="round" stroke-width="2"/>
    </svg>
  `,
  notes: `
    <svg version="1.1" viewBox="0 0 21 17">
      <path class="fill" d="M6,0H20a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H6A1,1,0,0,1,5,1H5A1,1,0,0,1,6,0Z"/>
      <path class="fill" d="M6,5H20a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H6A1,1,0,0,1,5,6H5A1,1,0,0,1,6,5Z"/>
      <path class="fill" d="M6,10H20a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1H5A1,1,0,0,1,6,10Z"/>
      <path class="fill" d="M6,15H20a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1H5A1,1,0,0,1,6,15Z"/>
      <circle class="fill" cx="1" cy="1" r="1"/>
      <circle class="fill" cx="1" cy="6" r="1"/>
      <circle class="fill" cx="1" cy="11" r="1"/>
      <circle class="fill" cx="1" cy="16" r="1"/>
    </svg>
  `,
  pencil: `
    <svg version="1.1" viewbox="0 0 16 16">
      <path class="fill" d="M16 2.5c0-1.381-1.119-2.5-2.5-2.5-0.818 0-1.544 0.393-2 1l-9 9 3.5 3.5 9-9c0.607-0.456 1-1.182 1-2z" />
      <path class="fill" d="M0 16l1.5-5 3.5 3.5z" />
    </svg>
  `,
  download: `
    <svg version="1.1" viewBox="0 0 33 23">
      <path class="stroke" d="M20.21,13l-3.91,3.91L12.38,13m3.86,3.22V7.38" stroke-linecap="round" stroke-linejoin="round"/>
      <path class="stroke" d="M17.91.75a9.4,9.4,0,0,1,9.35,10.44,5,5,0,0,1,4.47,4.53q0,.22,0,.44V18a3.7,3.7,0,0,1-3.7,3.7H3.9A3.15,3.15,0,0,1,.75,18.6v-1a4.49,4.49,0,0,1,1.42-3.28,6.09,6.09,0,0,1,7.1-7.87A9.41,9.41,0,0,1,17.91.75Z" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
  `,
  filter: `
    <svg version="1.1" viewBox="0 0 24 24">
      <path class="stroke" d="M22,4l-8,9.15V20l-3.17-2.5V12.86L3,4Z" stroke-linejoin="round" stroke-width="1.6"/>
    </svg>
  `,
  cross: `
    <svg version="1.1" viewBox="0 0 15 15">
      <line class="stroke" x1="0.5" y1="0.5" x2="14.5" y2="14.5" stroke-width="1"/>
      <line class="stroke" x1="0.5" y1="14.5" x2="14.5" y2="0.5" stroke-width="1"/>
    </svg>
  `,
  collapse: `
    <svg version="1.1" viewBox="0 0 10 10">
      <line class="stroke" x1="1" y1="1" x2="9" y2="9" stroke-width="1.5"/>
      <line class="stroke" x1="1" y1="9" x2="9" y2="1" stroke-width="1.5"/>
    </svg>
  `,
  mic: `
    <svg version="1.1" viewBox="0 0 30 30">
      <g transform="translate(3.5 1.5)">
        <rect class="stem fill" x="10.06" y="22" width="2" height="4"/>
        <path class="cage fill" d="M11.06,1a5,5,0,0,1,5,5v8a5,5,0,0,1-10,0V6a5,5,0,0,1,5-5m0-1a6,6,0,0,0-6,6v8a6,6,0,0,0,12,0V6a6,6,0,0,0-6-6Z"/>
        <rect class="cageline fill" x="12.06" y="1" width="1" height="7"/>
        <rect class="cageline fill" x="9.06" y="1" width="1" height="7"/>
        <path class="stroke cup" d="M3.14,14.54a7.92,7.92,0,0,0,15.83,0"/>
        <rect class="fill base" x="5.06" y="26" width="12" height="1"/>
        <path class="stroke strike singleStrike" d="M22.56,2.5.35,24.7" stroke-width="0.5"/>
        <g class="doubleStrike">
          <path class="stroke strike sys" d="M22.56,2.5.35,24.7" stroke-width="0.5" transform="translate(-1.25 -1.25)"/>
          <path class="stroke strike user" d="M22.56,2.5.35,24.7" stroke-width="0.5" transform="translate(1.25 1.25)"/>
        </g>
      </g>
    </svg>
  `,
  camera: `
    <svg version="1.1" viewBox="0 0 30 30">
      <g transform="translate(1.5 3.5)">
        <rect class="stroke cambody" x="0.5" y="4.35" width="16" height="14"/>
        <polygon class="stroke lens" points="19.73 11.35 26.5 5.01 26.5 17.7 19.73 11.35"/>
        <path class="stroke strike singleStrike" d="M24.5.35,2.3,22.56"/>
        <g class="doubleStrike">
          <path class="stroke strike sys" d="M24.5.35,2.3,22.56" transform="translate(-1.25 -1.25)"/>
          <path class="stroke strike user" d="M24.5.35,2.3,22.56" transform="translate(1.25 1.25)"/>
        </g>
      </g>
    </svg>
  `,
  'connectivity-connection': `
    <svg version="1.1" viewBox="0 0 30 21">
      <path class="fill" d="M18.3,17c-1-0.9-2.3-1.5-3.8-1.5c-1.4,0-2.6,0.5-3.6,1.4l3.8,3.8L18.3,17z"/>
      <path class="fill" d="M14.6,12c2.4,0,4.6,1,6.2,2.5l1.4-1.4c-2-1.9-4.7-3.1-7.7-3.1c-2.9,0-5.5,1.1-7.5,3l1.4,1.4
        C10.1,12.9,12.2,12,14.6,12z"/>
      <path class="fill" d="M14.6,7c3.8,0,7.3,1.5,9.8,4l1.4-1.4C22.9,6.8,18.9,5,14.6,5c-4.3,0-8.2,1.7-11,4.4l1.4,1.4
        C7.5,8.5,10.8,7,14.6,7z"/>
      <path class="fill" d="M14.6,2c5.2,0,9.9,2.1,13.3,5.5l1.4-1.4C25.5,2.3,20.3,0,14.6,0C8.9,0,3.8,2.3,0,5.9l1.4,1.4
      C4.8,4,9.5,2,14.6,2z"/>
    </svg>
  `,
  'connectivity-video': `
    <svg version="1.1" viewBox="0 0 23 13">
      <rect class="stroke" x="0.5" y="0.5" width="14" height="12"/>
      <path class="stroke" d="M16.73,6.5,22.5,1.15v10.7Z"/>
    </svg>
  `,
  'connectivity-audio': `
    <svg version="1.1" viewBox="0 0 15 23">
      <g class="mic">
        <path class="stroke" d="M7,.5H7A4.49,4.49,0,0,1,11.52,5v7A4.49,4.49,0,0,1,7,16.5H7A4.5,4.5,0,0,1,2.52,12V5A4.5,4.5,0,0,1,7,.5Z"/>
        <rect class="fill" x="5.02" y="1" width="1" height="6"/>
        <rect class="fill" x="8.02" y="1" width="1" height="6"/>
      </g>
      <g class="cup">
        <rect class="fill" x="6.02" y="18.5" width="2" height="3"/>
        <rect class="fill" x="2.02" y="21.5" width="10" height="1"/>
        <path class="stroke" d="M.5,12.51c0,3.77,3.74,6.16,6.61,6.16s6.45-2.39,6.45-6.16"/>
      </g>
    </svg>
  `,
  'connectivity-audioOutput': `
    <svg viewBox="0 0 23 19">
    <path class="stroke"  d="M12.82.85V18.14L4.57,13.48H.5V5.53l4.07,0Z"/>
    <path class="stroke"  d="M15.87,7A3.4,3.4,0,0,1,17.4,9.5,3.4,3.4,0,0,1,15.87,12"/>
    <path class="stroke"  d="M18.93,5Q22,7.25,22,9.5T18.93,14"/>
    </svg>
  `,
  kebab: `
    <svg version="1.1" viewBox="0 0 3 15">
      <circle class="fill" cx="1.5" cy="1.5" r="1.5"/>
      <circle class="fill" cx="1.5" cy="7.5" r="1.5"/>
      <circle class="fill" cx="1.5" cy="13.5" r="1.5"/>
    </svg>
  `,
  minus: `
    <svg version="1.1" viewBox="0 0 8 2">
      <line class="stroke" x1="1" y1="1" x2="7" y2="1" stroke-width="1" stroke-linecap="round"/>
    </svg>
  `,
  bandwidth: `
  <svg version="1.1" viewBox="0 0 13 11">
    <path class="stroke" d="M.42,2.38A14.23,14.23,0,0,1,6.42,1a14.23,14.23,0,0,1,6,1.38" fill="none" stroke-width="2"/>
    <path class="stroke"d="M2.22,6a10,10,0,0,1,4.2-1,10,10,0,0,1,4.2,1" fill="none" stroke-width="2"/>
    <path class="stroke" d="M4,9.55A5.69,5.69,0,0,1,6.42,9a5.69,5.69,0,0,1,2.4.55" fill="none" stroke-width="2"/>
  </svg>
  `,
  drag: `
    <svg version="1.1" viewbox="0 0 13 10">
      <line class="stroke" x1="0" x2="13" y1="0.5"  y2="0.5" stroke-width="1"/>
      <line class="stroke" x1="0" x2="13" y1="3.5"  y2="3.5" stroke-width="1"/>
      <line class="stroke" x1="0" x2="13" y1="6.5"  y2="6.5" stroke-width="1"/>
      <line class="stroke" x1="0" x2="13" y1="9.5"  y2="9.5" stroke-width="1"/>
    </svg>
  `,
  'external-link': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path id="box" class="stroke" d="M14.68,9v3.6a2.7,2.7,0,0,1-2.7,2.7H3.37a2.69,2.69,0,0,1-2.69-2.7V4a2.69,2.69,0,0,1,2.69-2.7H7.68" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="arrow" class="stroke" d="M6.14,10l9-9.11m-4.39.77L15.29.68m-.95,4.57L15.29.68" stroke-linecap="round"/>
    </svg>
  `,
};

export default (name) => {
  if (!icons[name]) {
    return '';
  } else {
    const id = _.uniqueId(name);
    return icons[name].split('UNIQ').join(id);
  }
};
