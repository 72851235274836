import EventEmitter from 'wolfy87-eventemitter';
import RPC from '@/services/rpc.service';

// TODO: refactor it, not the best solution
const { config } = window.__INITIAL_STATE__;
const API = {};
const ee = new EventEmitter();

const isUnauthorized = (data) => data && data.error && data.error.code === 'Unauthorized';
const isSettingsUpdated = (method, url) => method === 'PUT' && url.indexOf(`${config.baseUrl}/settings`) > -1;

function ajax(method, endpoint, ...others) {
  const url = `${config.baseUrl}${endpoint}`;

  return new Promise((resolve, reject) => {
    RPC[method.toLowerCase()]
      .apply(RPC, [url, ...others])
      .then((res) => {
        if (isSettingsUpdated(method, url)) {
          ee.emit('settings:updated', res.data.response);
        }

        if (res.config.responseType === 'blob') {
          resolve(res.data);
        } else {
          resolve(res.data.response);
        }
      })
      .catch((err) => {
        if (err?.response?.data && isUnauthorized(err.response.data)) {
          let locationUrl = new URL(window.location.href);
          let redirectAddress = '';
          if (locationUrl?.pathname) {
            redirectAddress += locationUrl.pathname;
          }
          if (locationUrl?.search) {
            redirectAddress += locationUrl.search;
          }
          ee.emit('token:errored');
          document.cookie = '';
          window.location = `/lauth/logout?returnUrl=${encodeURI(redirectAddress)}`;
          return;
        }

        reject(err?.response?.data || { message: err?.response?.statusText || 'Request aborted' });
      });
  });
}

API.on = (...args) => {
  ee.on.apply(ee, args);
};

API.off = (...args) => {
  ee.off.apply(ee, args);
};

API.getMeeting = (meetingId, queryParams = '') => {
  return ajax('GET', `/api/meetings/${meetingId}?${queryParams}`);
};

API.getMeetingValidityInfo = (meetingId) => {
  return ajax('GET', `/api/meetings/${meetingId}/validity-info`);
};

API.getMeetings = () => {
  return ajax('GET', '/api/meetings');
};

API.getForthcomingMeetings = () => {
  return ajax('GET', '/api/meetings/forthcoming');
};

API.getMeetingState = (meetingId) => {
  return ajax('GET', `/api/meetings/${meetingId}/state`);
};

API.getMeetingSession = (meetingId, optionsObj) => {
  const queryParams = new URLSearchParams(optionsObj);
  return ajax('GET', `/api/meetings/${meetingId}/session?${queryParams}`);
};

API.getIsEligibleForFeedback = () => {
  return ajax('GET', `/api/feedback/is-eligible`);
};

API.submitMeetingFeedback = (meetingId, feedback) => {
  return ajax('post', `/api/meetings/${meetingId}/feedback`, feedback);
};

API.getGroupSession = (meetingId, phaseId, groupId) => {
  return ajax('GET', `/api/meetings/${meetingId}/phases/${phaseId}/groups/${groupId}/session`);
};

API.downloadMeetingNotes = (meetingId, timezone, isMeetingEnded = 0) => {
  const timezoneEncoded = encodeURIComponent(timezone);
  let downloadUrl = `/api/meetings/${meetingId}/download/notes?`;
  if (isMeetingEnded) {
    downloadUrl += 'meetingEnded=true&';
  }
  downloadUrl += `timezone=${timezoneEncoded}`;
  return ajax('DOWNLOAD', downloadUrl);
};

API.createMeeting = (data) => {
  return ajax('POST', '/api/meetings', data);
};

API.updateMeeting = (meetingId, data) => {
  return ajax('PUT', `/api/meetings/${meetingId}`, data);
};

API.deleteMeeting = (meetingId) => {
  return ajax('DELETE', `/api/meetings/${meetingId}`);
};

API.joinMeeting = (meetingId, data) => {
  return ajax('POST', `/api/meetings/${meetingId}/users`, data);
};

API.setAsJoined = (meetingId) => {
  return ajax('POST', `/api/meetings/${meetingId}/user/set-as-joined`);
};

API.validatePersonalRoomInviteLink = (pinNumber, pwd) => {
  return ajax('GET', `/api/rooms/${pinNumber}/validate-invite-link?pwd=${pwd}`);
};

API.joinRoomMeeting = (pinNumber, data) => {
  return ajax('PUT', `/api/rooms/${pinNumber}/meetings`, data);
};

API.updateRoomPassword = (password) => {
  const data = {
    password,
  };

  return ajax('PUT', '/api/users/me/room', data);
};

API.authenticate = (username, password) => {
  const data = {
    email: username,
    password: password,
  };

  return ajax('POST', '/api/auth', data);
};

API.attemptLogout = (isJson) => {
  return ajax('GET', `/logout?${isJson ? 'isJson=1' : ''}`);
};

API.createUser = (data) => {
  return ajax('POST', '/api/users', data);
};

API.createGuestUser = (data) => {
  return ajax('POST', '/api/auth/guest', data);
};

API.rebuildDatabase = () => {
  return ajax('POST', '/api/admin/rebuild');
};

API.getMpts = () => {
  return ajax('GET', '/api/mpts');
};

API.getUserSettings = () => {
  return ajax('GET', '/api/users/me/settings');
};

API.updateSettings = (settings) => {
  return ajax('PUT', '/api/users/me/settings', settings);
};

API.insertMeetingStats = (meetingId, stats) => {
  return ajax('POST', `/api/statistics/meetings/${meetingId}`, { data: stats });
};

API.updateMeetingDefaultSettings = (settings) => {
  return ajax('PUT', '/api/users/me/meetingSettings', settings);
};

API.getUserPermissions = () => {
  return ajax('GET', '/api/users/me/permissions');
};

API.initializeReadinessCheck = (data) => {
  return ajax('POST', '/api/readiness-check', data);
};

API.markAsCompleted = (data) => {
  return ajax('POST', '/api/readiness-check/complete', data);
};

API.resyncSessionDetails = (data) => {
  return ajax('POST', '/api/auth/sync-session', data);
};

/** admin reporting APIs */
API.isAuthorizedToAccessAdminReports = ({ reportName }) => {
  return ajax('GET', `/api/reporting/is-authorized?reportName=${reportName}`);
};

API.getMeetingsForAdminReport = ({ filterObj }) => {
  return ajax('POST', '/api/reporting/meetings-list', { filterObj });
};

API.getMeetingAgendaForMeetingIDs = (data) => {
  let downloadUrl = `/api/reporting/meeting-agenda`;
  return ajax('POST', downloadUrl, data);
};

API.deleteMultipleMeetings = (data) => {
  return ajax('POST', '/api/reporting/delete-meetings', data);
};

export default API;
