<template>
  <div id="container">
    <section class="page introPages loginPage" data-page-name="loginPage">
      <section class="app-component login">
        <div class="content">
          <title-block />

          <form id="signInForm" class="basicsForm" @submit.prevent="handleForm">
            <p class="intro error" v-if="signInError">{{ signInError }}</p>
            <transition-group name="basic">
              <div class="row" :class="{ hasError: emailError }" key="emailAddress">
                <label for="emailAddressField" class="visuallyHidden">Email Address</label>
                <input
                  id="emailAddressField"
                  v-model.trim="emailAddress"
                  type="email"
                  name="email"
                  placeholder="Email address..."
                  @keyup="setFieldTimeout('email')"
                  @blur="checkEmail"
                />
                <transition name="basic">
                  <p v-if="emailError" class="error">
                    {{ emailError }}
                  </p>
                </transition>
              </div>
              <div class="row" :class="{ hasError: passwordError }" key="password">
                <label for="passwordField" class="visuallyHidden">Password</label>
                <input
                  v-model.trim="password"
                  type="password"
                  id="passwordField"
                  name="password"
                  placeholder="Password..."
                  @keyup="setFieldTimeout('password')"
                  @blur="checkPassword"
                />
                <transition name="basic">
                  <p v-if="passwordError" class="error">
                    {{ passwordError }}
                  </p>
                </transition>
              </div>
            </transition-group>
            <div class="actions">
              <button type="submit" :disabled="isLoading" class="btn btn-border btn-mid-border btn-blue-flood">Login</button>
            </div>
          </form>
          <p class="minor">
            If you don't have an account,<br />
            please <a href="/auth/register" @click.prevent="redirectToAuth('sign_up')">sign up</a>.
          </p>
        </div>
      </section>
      <footer class="pageFooter">
        <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
      </footer>
    </section>
  </div>
</template>

<script>
import TitleBlock from '@/components/TitleBlock.vue';

import { usePageStore } from '@/store/pinia/page';
import { nM } from '@/legacy';

export default {
  components: {
    TitleBlock,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  data() {
    return {
      isLoading: false,
      emailAddress: '',
      emailError: false,
      emailTimeout: null,
      password: '',
      passwordError: false,
      passwordTimeout: null,
      signInError: '',
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    redirectToAuth(action) {
      window.location = `/lauth/nowhere?returnUrl=/conference/welcome&action=${action}`;
    },
    setFieldTimeout(field) {
      this.signInError = '';
      if (field === 'email') {
        clearTimeout(this.emailTimeout);
        this.emailTimeout = setTimeout(() => {
          this.checkEmail();
        }, 2000);
      } else if (field === 'password') {
        clearTimeout(this.passwordTimeout);
        this.passwordTimeout = setTimeout(() => {
          this.checkPassword();
        }, 2000);
      }
    },
    checkEmail() {
      this.emailError = this.emailAddress ? '' : 'Please fill out this field';
    },
    checkPassword() {
      this.passwordError = this.password ? '' : 'Please fill out this field';
    },
    handleForm(eve) {
      eve.preventDefault();
      this.signInError = '';
      if (this.emailAddress && this.password) {
        this.isLoading = true;
        this.$API
          .authenticate(this.emailAddress, this.password)
          .then(() => {
            this.$router.push('/');
          })
          .catch((err) => {
            this.signInError = nM.toErrorMessage(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
section {
  display: flex;
  align-items: stretch;
}
section,
.introPages {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  .app-component {
    margin: auto 0;
    width: 100%;
    form {
      width: rem(300px);
      margin: 0 auto;
    }
    p {
      @include type-size('medium');
      margin: rem(16px) auto;
      width: rem(250px);
      text-align: center;
      a {
        color: inherit;
        text-decoration: underline;
        @include triggered {
          color: var(--c__accent);
        }
      }
      &.minor {
        @include type-size('small');
      }
      &.error {
        text-align: left;
        margin: rem(8px) 0;
      }
    }
    .btn-loud-blue {
      color: var(--c__bg);
    }
  }
  footer {
    margin: auto 0 0 0;
  }
}
</style>
