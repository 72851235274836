<template>
  <exit-lobby :pageName="pageName">
    <h2 v-if="pageName === 'over'">Thanks for using bixe!</h2>
    <h2 v-if="pageName === 'ended'">This meeting ended</h2>

    <template #footer>
      <p v-if="pageName === 'over'">
        How was your meeting? We would love to hear from you.
        <a class="btn-borderless btn-link" href="mailto:support@now-here.com?subject=bixe%20Video%20Feedback">Get in touch&hellip;</a>
      </p>
      <p v-if="pageName === 'ended'">
        Having problems with bixe? <a class="btn-borderless btn-link" href="https://www.bixe.ai/support.html" target="_blank">Contact Support</a>
      </p>
    </template>
  </exit-lobby>
</template>

<script>
import ExitLobby from '@/components/ExitLobby';
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import utils from '../resources/utils';

export default {
  components: {
    ExitLobby,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  created() {
    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'checkIsOnLatestVersion' });
      ipcApi.send({ name: 'releaseWakeLock' });
    }
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
    if (this.pageName === 'over' && this.accountStore.isGuest) {
      this.accountStore.updateUser({ user: null });
      this.$API.attemptLogout(true);
    }
  },
};
</script>
