<template>
  <section id="downloading" class="prelaunch page poster" data-page-name="prelaunch">
    <div class="content">
      <title-block />

      <p class="subtitle">Done downloading? &hellip; Next</p>
      <ol>
        <li>Double click the file in your downloads folder to open it.</li>
        <li>Follow the instructions to install bixe onto your computer.</li>
        <li>After the app has installed click this button…</li>
      </ol>

      <div class="actions">
        <a v-if="!meetingStore.meetingId" :href="appLink" class="btn btn-solid btn-loud-blue btn-mid-border btn-dark-blue-flood">Open the app</a>
        <a v-else :href="appLink" class="btn btn-solid btn-loud-blue btn-dark-blue-flood">Connect to your meeting</a>
      </div>
      <div class="tryAgain">
        <p>Problems downloading? <a :href="downloadLink" download> Try again </a></p>
      </div>
    </div>
    <footer class="pageFooter">
      <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
    </footer>
  </section>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useAccountStore } from '@/store/pinia/account';
import TitleBlock from '@/components/TitleBlock.vue';
import BixeApi from '@/services/bixe-api.service';
import utils from '../resources/utils';

export default {
  components: {
    TitleBlock,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const accountStore = useAccountStore();
    return { pageStore, meetingStore, accountStore };
  },
  data() {
    return {
      meeting: null,
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });

    this.getMeetingByMeetingId();
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    appLink() {
      const origin = window.location.origin;
      const welcomePage = `${origin}${this.accountStore.baseUrl}`;
      let encoded = '';

      if (this.meeting && this.meeting.pin_pass) {
        encoded = encodeURIComponent(`${welcomePage}/meeting/${this.meeting.id}?pwd=${this.meeting.pin_pass}`);
      } else if (!this.meetingStore.meetingId) {
        encoded = encodeURIComponent(`${welcomePage}/app/welcome`);
      } else {
        return '#';
      }
      const appProtocol = utils.getNativeAppProtocol(this.accountStore.__env);
      return `${appProtocol}://${encoded}`;
    },
    downloadLink() {
      const platform = window.navigator.platform;
      if (platform.match(/mac/gi)) {
        return 'https://downloads.now-here.com/executables/latest/BIXE.dmg';
      }
      return 'https://downloads.now-here.com/executables/latest/BIXE%20Setup.exe';
    },
  },
  methods: {
    getMeetingByMeetingId() {
      if (!this.meetingStore.meetingId) {
        return;
      }
      BixeApi.getMeeting(this.meetingStore.meetingId)
        .then((meeting) => {
          this.meeting = meeting;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
section,
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: rem(372px);
  margin: auto;
}

.actions {
  width: rem(300px);
  margin: 0 0 rem(36px) 0;
  a {
    font-weight: 200;
  }
}
ol {
  margin: rem(27px) 0 rem(34px) 0;
  list-style: none;
  li + li {
    margin-top: rem(15px);
  }
  li {
    counter-increment: steps;
    display: flex;
    align-items: center;
    font-weight: 200;
    letter-spacing: rem(-0.18px);
  }
  li::before {
    content: counter(steps);
    @include type-size(medium);
    width: rem(26px);
  }
}
p.subtitle {
  @include type-size('large');
  font-weight: 200;
  margin: rem(25px) 0 0 0;
}

.tryAgain {
  letter-spacing: rem(-0.18px);
  font-weight: 200;
  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: rem(9px);
  }
}
</style>
