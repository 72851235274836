<template>
  <div class="skip">
    <button class="nextPart" @click="handleClick">
      <app-icon icon="skip" />
      <span class="text"><slot /></span>
    </button>
    <bubble-tip
      direction="above"
      :show="showCheck"
      :buttons="[
        { event: 'cancel', text: 'Cancel' },
        { event: 'confirm', text: 'Yes' },
      ]"
      @button="handleTipButton"
    >
      <template v-if="to === 'endWell'">
        <strong>Are you ready to end<br />your meeting well?</strong>
      </template>
      <template v-if="to === 'inputXchanges'">
        <strong>Move on to Meet Well?</strong>
      </template>
      <template v-if="to === 'finish'">
        <strong
          >Are you ready to end your<br />
          meeting now?</strong
        >
      </template>
    </bubble-tip>
    <bubble-tip
      direction="above"
      :show="meetingStore.showReviewNotice"
      class="reviewBubble"
      :buttons="[
        { event: 'reviewTip:reject', text: 'No' },
        { event: 'reviewTip:endWell', text: 'Yes' },
      ]"
      @button="handleReviewButton"
    >
      <strong>You have 15 mins left.<br />Want to Review & Check-Out?</strong>
    </bubble-tip>
  </div>
</template>

<script>
import BubbleTip from '@/components/BubbleTip.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';

export default {
  setup() {
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    return { meetingStore, phaseStore };
  },
  components: {
    BubbleTip,
    AppIcon,
  },
  props: ['to'],
  data() {
    return {
      showCheck: false,
    };
  },
  computed: {},
  methods: {
    handleClick() {
      this.showCheck = true;
    },
    handleTipButton(event) {
      this.showCheck = false;
      if (event === 'confirm') {
        if (this.to === 'endWell') {
          this.$meetingmanager.startEndWell();
          this.meetingStore.startEndWell();
        } else if (this.to === 'inputXchanges') {
          this.$meetingmanager.skipBeginWell();
          this.meetingStore.skipBeginWell();
        } else {
          this.$ee.emit('nM:meeting:finish');
        }
      }
    },
    handleReviewButton(event) {
      if (event === 'reviewTip:endWell') {
        this.meetingStore.startEndWell();
        this.$meetingmanager.startEndWell();
        if (this.phaseStore.currentPhase !== 'freeform') {
          setTimeout(() => {
            this.$meetingmanager.endPhase();
          }, 300);
        }
        this.meetingStore.setReviewNotice(false);
      } else {
        this.$meetingmanager.ignoreEndWell();
        this.meetingStore.setReviewNotice(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.skip {
  position: relative;
}

.icon {
  height: rem(38px);
  width: rem(38px);
  border: 1px solid var(--c__text);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon :deep(svg) {
  width: rem(26px);
  height: rem(14px);
}

:deep(.text) {
  line-height: 1;
  margin-top: rem(11px);
}

.nextPart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include triggered {
    .icon {
      background-color: var(--c__text);
      color: var(--c__bg);
    }
  }
}
</style>
