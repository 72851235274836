<template>
  <g :id="id" class="templateArea" :class="[classes, item.color]">
    <rect
      class="shape"
      ref="box"
      v-if="item.type !== 'text'"
      :width="item.width"
      :height="item.height"
      :x="x"
      :y="y"
      stroke-width="4"
      :stroke="cardStroke"
      :fill="cardFill"
      :fill-opacity="0.25"
      :stroke-opacity="0.8"
    />

    <text :x="x + 10" :y="y + 10" alignment-baseline="hanging" :fill="cardStroke">{{ item.title }}</text>
  </g>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMappingStore } from '@/store/pinia/mapping';
import { useFrameStore } from '@/store/pinia/frame';
import { useMouse } from '@vueuse/core';

const cardStrokes = {
  blue: '#00a2ff',
  red: '#ff5a50',
  orange: '#faaf3c',
  green: '#b2d235',
  purple: '#9a00de',
  yellow: '#f5d010',
  white: '#cccccc',
  black: '#222222',
  none: '#000000',
};
const cardFills = {
  blue: '#b3efff',
  red: '#ffcecb',
  orange: '#fcdfbc',
  green: '#e8f2c2',
  yellow: '#fcf1b7',
  purple: '#e6bff7',
  white: '#ffffff',
  black: '#222222',
  none: '#ffffff',
};

export default {
  name: 'MappingTemplateItem',
  data() {
    return {};
  },
  props: {
    item: Object,
    clean: Boolean,
  },
  setup() {
    const frameStore = useFrameStore();
    const mappingStore = useMappingStore();
    const { x: mouseX, y: mouseY } = useMouse();
    return { frameStore, mappingStore, mouseX, mouseY };
  },
  computed: {
    ...mapPiniaState(useMappingStore, ['viewBoxX', 'viewBoxY', 'zoom', 'em', 'items', 'typeScale', 'templateChildren']),
    ...mapPiniaState(useMappingStore, {
      isDragging: 'dragging',
      isResizing: 'resizing',
      addingItem: 'adding',
      editingItem: 'editing',
    }),
    ...mapPiniaState(useFrameStore, {
      frameH: 'contHeight',
      frameW: 'contWidth',
      mouseX: 'clientX',
      mouseY: 'clientY',
    }),
    font() {
      return this.clean ? 'proximaNovaAltRegular' : false;
    },
    id() {
      return this.clean ? 'templateItem-' + this.item.id : 'liveTemplateItem-' + this.item.id;
    },
    lineHeightEm() {
      return this.lineHeight + 'em';
    },
    classes() {
      return {};
    },
    cardStroke() {
      return cardStrokes[this.item.color];
    },
    cardFill() {
      return cardFills[this.item.color];
    },
    textColor() {
      return this.item.color === 'black' ? '#ffffff' : '#222222';
    },
    x() {
      return this.item.x;
    },
    y() {
      return this.item.y;
    },
  },
  watch: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.templateArea {
  pointer-events: none;
}
</style>
