<template>
  <UseDraggable id="fullScreenHeader" class="fullScreenHeader" :initialValue="initialPosition" :handle="handle">
    <div :class="{ frame: true, 'frame-input-phase': isInputPhase }" ref="handle">
      <template v-if="isInputPhase">
        <meeting-notes-bar mini="true" v-if="meetingStore.useNotes" />
        <div id="miniTimer" class="miniTimer">
          <timer-disc
            v-bind="$attrs"
            key="phase-timer"
            type="mini"
            showing="true"
            :current="timeStore.phaseElapsed"
            :total="timeStore.phaseLength"
            :passed-setting-time="timeStore.phaseSettingTime"
            :remaining="timeStore.phaseRemaining"
          />
        </div>
        <div class="btn-pair btn-pair-light">
          <button class="btn" @click="moreTime" @keyup.space="(e) => e.preventDefault()">More time</button>
          <button id="exitFullScreen" class="btn" @click="$emit('exit', true)" @keyup.space="(e) => e.preventDefault()">Exit fullscreen</button>
        </div>
      </template>
      <template v-else>
        <div class="btn-single">
          <button id="exitFullScreen" class="btn btn-border" @click="$emit('exit', true)" @keyup.space="(e) => e.preventDefault()">Exit fullscreen</button>
        </div>
      </template>
    </div>
  </UseDraggable>
</template>

<script>
import { useMeetingStore } from '@/store/pinia/meeting';
import { useTimeStore } from '@/store/pinia/time';
import { ref } from 'vue';
import { UseDraggable } from '@vueuse/components';
import MeetingNotesBar from '@/components/MeetingNotesBar';
import TimerDisc from '@/components/TimerDisc';
import utils from '@/resources/utils';

export default {
  components: {
    MeetingNotesBar,
    TimerDisc,
    UseDraggable,
  },
  props: ['isInputPhase'],
  setup() {
    const meetingStore = useMeetingStore();
    const timeStore = useTimeStore();
    const handle = ref();
    return { meetingStore, timeStore, handle };
  },
  data() {
    let marginBottom = 120;
    if (utils.isOpenedWithinElectronShell() && navigator.userAgent.match(/windows/i)) {
      marginBottom = 190;
    }
    return {
      initialPosition: {
        x: 0,
        y: window.screen.height - marginBottom,
      },
    };
  },
  methods: {
    moreTime() {
      this.$ee.emit('nM:fullscreenMoreTime');
    },
  },
};
</script>

<style lang="scss" scoped>
.fullScreenHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 20;

  .frame {
    background: var(--c__bg-alt-90);
    border-radius: 4px;
    border: 1px solid var(--c__edges);
    &.frame-input-phase {
      padding-right: rem(20px);
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  $mini-timer-size: 40px;

  :deep(.miniTimer) {
    height: rem($mini-timer-size);
    width: rem($mini-timer-size);
    border-radius: 50%;
    position: relative;
    margin: rem(16px) rem(32px) rem(19px) rem(24px);
    flex: 0 0 rem($mini-timer-size);
    .miniDisc {
      color: var(--c__time-ok);
      height: rem($mini-timer-size);
      width: rem($mini-timer-size);
      border-radius: 50%;
    }

    circle {
      stroke: currentColor;
    }

    span {
      position: absolute;
      font-size: rem(18px);
      line-height: rem($mini-timer-size);
      width: 100%;
      top: 0;
      left: 0;
      text-align: center;
      color: var(--c__text);
    }
    .mid {
      color: var(--c__time-mid);
    }
    .end {
      color: var(--c__time-over);
    }
    .overdue {
      animation: overdue-jitter 2s linear 1s infinite;
      color: white;
      background: var(--c__red);
      span {
        color: var(--c__bg);
      }
      circle {
        stroke: var(--c__red);
        fill: var(--c__red);
      }
    }
  }
  .btn-single {
    padding: rem(12px) rem(30px);
    button {
      padding: rem(12px) rem(30px);
    }
  }
  .btn-pair {
    height: rem(50px);
    margin: rem(14px) 0;
    flex: 0 0 rem(300px);
    button {
      width: rem(150px);
    }

    border-width: 2px;

    & > * {
      &::after,
      &::before {
        width: 1px;
      }
      &::after {
        right: -1px;
      }
      &::before {
        left: -1px;
      }
    }
  }
}
</style>
