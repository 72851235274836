<template>
  <div class="subContent checkSpeaking" :class="displayedStatus">
    <transition-group name="basic-group" mode="out-in">
      <div class="audioCheckIndicator" key="indicator">
        <volume-indicator v-if="audioStatus === 'success'" :volume="volumeLevel" />
        <app-icon class="disc" :class="audioStatus" icon="mic" />
      </div>
      <h2 key="heading">Can we hear you?</h2>
      <p v-if="displayedStatus === 'pending'" key="pendingMsg">Please say something into your microphone.</p>
      <p v-if="displayedStatus === 'success'" key="successMsg">Bravo, we can hear you. Next we will get your camera working.</p>
      <p v-if="displayedStatus === 'fail'" class="failedMessage" key="failMsg">
        We didn’t catch that, you might need to select a different microphone&hellip;?
      </p>
      <div v-if="!devices" class="loadingDevices" key="loading">fetching your devices <app-loading-spinner /></div>
      <app-select v-if="devices" class="deviceSelect" v-model="selectedDevice" :options="deviceOptions" placeholder="please select a device" key="select">
        <template #arrow>
          <app-icon icon="down-arrow" />
        </template>
        <template #placeholder> please select a device </template>
        <template #label> please select a device </template>
      </app-select>
      <button class="btn btn-border btn-medium btn-blue-flood" @click="moveToNextStep()" :disabled="disableButton" key="next">Next</button>
    </transition-group>
  </div>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { mapState as mapPiniaState } from 'pinia';
import { useMediaStore } from '@/store/pinia/media';
import { useStorage } from 'vue3-storage';
import utils from '@/resources/utils.js';
import AppIcon from '@/components/AppIcon';
import AppSelect from '@/components/AppSelect';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import VolumeIndicator from '@/components/VolumeIndicator';

const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  components: {
    AppLoadingSpinner,
    AppIcon,
    VolumeIndicator,
    AppSelect,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
    let _this = this;
    this.$ee.on('api:setVolumeTestMeter', _this.setVolumeLevel);
    setTimeout(_this.setTimedOut, 7000);
  },
  beforeUnmount() {
    let _this = this;
    this.$ee.off('api:setVolumeTestMeter', _this.setVolumeLevel);
    this.$videomanager.mute();
  },
  data() {
    return {
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkCamera,
      volumeLevel: 0,
      audioStatus: 'pending', // possible values : 'pending', 'success', 'fail'
      selectedDevice: undefined,
      isAudioInputCheckingStarted: false,
      timedOut: false,
    };
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['devices', 'audioSource']),
    displayedStatus() {
      if (this.audioStatus === 'pending' && this.timedOut) {
        return 'fail';
      } else {
        return this.audioStatus;
      }
    },
    disableButton() {
      return this.displayedStatus !== 'success';
    },
    deviceOptions() {
      return this.devices
        .filter((d) => d.kind === 'audioInput')
        .map((d) => {
          return {
            value: d.deviceId,
            label: utils.cleanDeviceLabel(d.label),
          };
        });
    },
  },
  watch: {
    selectedDevice(nv) {
      this.$videomanager.stopAudioPreview();
      this.$readinessChecker.markAsStarted(this.pageName);
      try {
        this.testAudioInput(nv).then((status) => {
          this.previewAudioFromSelectedDevice(status);
        });
        this.$nextTick(() => {
          this.audioStatus = 'pending';
        });
      } catch (e) {
        console.log('Error in checking audio input');
      }
    },
    audioSource: {
      immediate: true,
      handler(nv) {
        if (this.devices && this.devices.length && nv && !this.selectedDevice) {
          // wait till devices and last selected audio input device loaded to show selected device
          this.selectedDevice = nv;
        }
      },
    },
    deviceOptions: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.length && !this.selectedDevice) {
          if (this.audioSource) {
            this.selectedDevice = this.audioSource;
          } else if (nv.length) {
            this.selectedDevice = nv[0]?.value;
          } else {
            this.selectedDevice = 'default';
          }
        }
      },
    },
  },
  methods: {
    setTimedOut() {
      this.timedOut = true;
    },
    moveToNextStep() {
      this.$readinessChecker.moveToNextStep({ nextPageName: this.nextPageName });
    },
    testAudioInput(deviceId) {
      return new Promise((resolve) => {
        this.$readinessChecker.checkAudioInput(deviceId).then((resp) => {
          resolve(resp.status);
        });
      });
    },
    previewAudioFromSelectedDevice(readinessStatus) {
      this.mediaStore.setAudioSource(this.selectedDevice || false);
      this.$connectivity.updateSettings('audioSource', this.selectedDevice, true);
      this.$emit('audio:change', this.selectedDevice);
      localStorage.setStorageSync('user:audioSource', this.selectedDevice);
      this.$readinessChecker.markAsCompleted({ subPageName: this.pageName, isPassed: readinessStatus });
    },
    setVolumeLevel(level) {
      this.volumeLevel = Math.floor(level * 10);
      const VOLUME_THRESHOLD = 2;
      if (this.volumeLevel > VOLUME_THRESHOLD && this.selectedDevice) {
        this.audioStatus = 'success';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.audioCheckIndicator {
  height: rem(94px);
  width: rem(94px);
  position: relative;
  margin-bottom: rem(27px);
  .disc {
    height: rem(94px);
    width: rem(94px);
    color: var(--c__disabled);
    border: 3px solid currentColor;
    background: var(--c__bg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    .success & {
      color: var(--c__success);
    }
    .fail & {
      color: var(--c__warn);
    }
  }

  :deep(.strike) {
    display: none;
  }
  :deep(svg) {
    display: block;
    width: rem(60px);
  }
  :deep(.volumeIndicator) {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
  }

  :deep(.volume) {
    --color: var(--c__success-25);
  }
}
.checkSpeaking {
  .failedMessage {
    max-width: 19em;
  }
  p {
    max-width: 20em;
  }
}
</style>
