import _ from 'underscore';
class ReadinessCheckPoint {
  constructor(subPageName, createBulletPoint, path, accessibilityMessage) {
    this.isInProgress = false;
    this.isCompleted = false;
    this.isPassed = false;
    this.subPageName = subPageName;
    this.createBulletPoint = createBulletPoint;
    this.path = `/check/${path}`;
    this.accessibilityMessage = accessibilityMessage;
  }
}

export default class BaseReadinessChecker {
  constructor() {
    this.$router = null;
    this.localStorage = null;
    this.initialized = false;
    this.meta = {
      attendeeId: null,
      externalAttendeeId: null,
      meetingId: null,
      externalMeetingId: null,
    };
    this.checkPointNames = {
      checkStart: 'checkStart',
      checkSpeed: 'checkSpeed',
      checkHearing: 'checkHearing',
      checkSpeaking: 'checkSpeaking',
      checkCamera: 'checkCamera',
      checkScreenSharing: 'checkScreenSharing',
      checkComplete: 'checkComplete',
    };
    this.checkPoints = [];
  }

  setMetaData(meta) {
    this.meta = Object.assign(this.meta, meta);
  }

  initialize(router, storageService) {
    this.$router = router;
    this.localStorage = storageService;
    this.initialized = true;
    let { checkStart, checkSpeed, checkHearing, checkSpeaking, checkCamera, checkScreenSharing, checkComplete } = this.checkPointNames;
    this.checkPoints = [
      new ReadinessCheckPoint(checkStart, false, '', ''),
      new ReadinessCheckPoint(checkSpeed, true, 'how-fast-are-you', 'How fast are you?'),
      new ReadinessCheckPoint(checkHearing, true, 'can-you-hear-us', 'Can you hear us?'),
      new ReadinessCheckPoint(checkSpeaking, true, 'can-we-hear-you', 'Can we hear you?'),
      new ReadinessCheckPoint(checkCamera, true, 'can-we-see-you', 'Can we see you?'),
      new ReadinessCheckPoint(checkScreenSharing, true, 'sharing-your-screen', 'Can you share your screen?'),
      new ReadinessCheckPoint(checkComplete, false, 'complete', ''),
    ];
    return this;
  }

  getAllCheckPointNames() {
    return this.checkPointNames;
  }

  getAllCheckPoints() {
    return this.checkPoints;
  }

  getCheckPointBySubPageName(subPageName) {
    return this.checkPoints.find((checkPoint) => checkPoint.subPageName === subPageName);
  }

  getCheckPointIndexBySubPageName(subPageName) {
    return this.checkPoints.findIndex((checkpoint) => checkpoint.subPageName === subPageName);
  }

  isStarted() {
    return this.initialized;
  }

  markAsStarted(subPageName) {
    let checkPoint = this.getCheckPointBySubPageName(subPageName);
    if (checkPoint) {
      checkPoint.isInProgress = true;
    }

    return this;
  }

  markAsCompleted({ subPageName, isPassed }) {
    let checkPoint = this.getCheckPointBySubPageName(subPageName);
    if (checkPoint) {
      checkPoint.isPassed = isPassed;
      checkPoint.isInProgress = false;
      checkPoint.isCompleted = true;
    }

    return this;
  }

  moveToNextStep({ nextPageName }) {
    let checkPoint = this.getCheckPointBySubPageName(nextPageName);
    this.$router.push(checkPoint.path).catch(() => {});

    return this;
  }

  reset() {
    this.initialized = false;
    this.meta = {
      attendeeId: null,
      externalAttendeeId: null,
      meetingId: null,
      externalMeetingId: null,
    };
    this.checkPoints = this.checkPoints.map((checkPoint) => ({ ...checkPoint, isCompleted: false, isInProgress: false, isPassed: false }));

    return this;
  }

  getAttendeeDetails() {
    // TODO: @taha - this method doesn't fit here and should be moved to any other better place
    let attendeeId = this.localStorage.getStorageSync('readinessCheck:attendeeId');
    if (!attendeeId) {
      attendeeId = _.uniqueId('attendee_');
      this.localStorage.setStorageSync('readinessCheck:attendeeId', attendeeId);
    }
    return { attendeeId };
  }
}
