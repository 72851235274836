<template>
  <section class="join page" data-page-name="join">
    <div class="extra">
      <div class="content">
        <h2>Join</h2>
        <form id="joinMeetingForm" class="basicsForm" @submit.prevent="handleForm">
          <p v-if="(!hasWrongInfo && !badLink && !wrongLink) || edited || sending" class="intro">
            To join a meeting, use the invite link you were sent. Or enter the meeting details&hellip;
          </p>
          <p v-if="(wrongLink || hasWrongInfo) && !edited && !sending" class="intro error">
            Sorry, you entered an incorrect number or password. Please check and try again.
          </p>
          <p v-if="badLink && !attemptedForm && !edited && !sending" class="intro error">
            This meeting link is invalid. Please check the details and try again or contact the meeting owner.
          </p>
          <transition-group name="basic" tag="div">
            <div class="row" :class="{ hasError: idError }" key="id">
              <label for="joinMeetingField" class="visuallyHidden">Meeting or Personal Room number</label>
              <input
                id="joinMeetingField"
                v-model.trim="id"
                type="text"
                name="meetingID"
                autocomplete="off"
                placeholder="Meeting or Personal Room number..."
                @keyup="setIdTimeout"
                @blur="checkId"
              />
              <transition name="basic">
                <p v-if="idError" class="error">
                  {{ idError }}
                </p>
              </transition>
            </div>
            <div class="row" :class="{ hasError: passwordError }" key="pass">
              <label for="meetingPasswordField" class="visuallyHidden">Password</label>
              <input
                id="meetingPasswordField"
                v-model.trim="password"
                type="text"
                name="password"
                autocomplete="off"
                placeholder="Password..."
                @keyup="setPasswordTimeout"
                @blur="checkPassword"
              />
              <transition name="basic">
                <p v-if="passwordError" class="error">
                  {{ passwordError }}
                </p>
              </transition>
            </div>
            <browser-guarded-button id="joinMeetingGo" type="submit" key="continue" class="btn solo btn-border" :disabled="invalid">
              <span v-if="sending" class="inline-block"><app-loading-spinner /></span>
              <span v-else>Continue</span>
            </browser-guarded-button>
          </transition-group>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import utils from '../resources/utils';

export default {
  props: ['pageName'],
  components: {
    AppLoadingSpinner,
    BrowserGuardedButton,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      id: '',
      password: '',
      hasWrongInfo: false,
      idError: false,
      passwordError: false,
      idTimeout: null,
      passwordTimeout: null,
      attemptedForm: false,
      edited: false,
      sending: false,
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
    let _this = this;
    this.sending = false;
    this.$ee.on('nM:inlineMessage:wrongMeetingInfo', _this.wrongInfo);
  },
  unmounted() {
    let _this = this;
    this.$ee.off('nM:inlineMessage:wrongMeetingInfo', _this.wrongInfo);
    this.sending = false;
  },
  computed: {
    strippedId() {
      return this.id.split(' ').join('').split('-').join('');
    },
    invalid() {
      return this.idError || this.passwordError || !this.id || !this.password;
    },
    badLink() {
      return this.$route.query.reason === 'badLink';
    },
    wrongLink() {
      return this.$route.query.reason === 'wrongLink';
    },
  },
  methods: {
    handleForm() {
      if (this.sending) {
        return;
      }
      this.checkId();
      this.checkPassword();
      if (!this.idError && !this.passwordError) {
        this.edited = false;
        this.sending = true;
        const isLoggedIn = this.accountStore.isLoggedIn;
        this.$API
          .validatePersonalRoomInviteLink(this.strippedId, this.password)
          .then(() => {
            if (!isLoggedIn) {
              this.$router.push(`/joining-options?id=${this.strippedId}&pwd=${this.password}&flow=pr-invite`);
              return;
            }

            utils.genericFns
              .joinPersonalRoomMeeting({ pinNumber: this.strippedId, password: this.password })
              .then((meeting) => {
                this.sending = false;
                utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
                this.$router.push(`/meeting/${meeting.id}`).catch(() => null);
              })
              .catch(() => {
                this.sending = false;
                this.$ee.emit('nM:inlineMessage:wrongMeetingInfo');
              });
          })
          .catch(() => {
            this.$ee.emit('nM:inlineMessage:wrongMeetingInfo');
          });
      }
    },
    setIdTimeout() {
      clearTimeout(this.idTimeout);
      this.edited = true;
      if (this.idError) {
        this.checkId();
      } else {
        this.idTimeout = setTimeout(() => {
          this.checkId();
        }, 2000);
      }
    },
    setPasswordTimeout() {
      clearTimeout(this.passwordTimeout);
      this.edited = true;
      if (this.passwordError) {
        this.checkPassword();
      } else {
        this.passwordTimeout = setTimeout(() => {
          this.checkPassword();
        }, 2000);
      }
    },
    checkId() {
      if (!this.id) {
        this.idError = 'Please fill out this field';
      } else {
        this.idError = false;
      }
    },
    checkPassword() {
      if (!this.password) {
        this.passwordError = 'Please fill out this field';
      } else {
        this.passwordError = false;
      }
    },
    wrongInfo() {
      this.sending = false;
      this.attemptedForm = true;
      this.hasWrongInfo = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding-bottom: 5em;
}
</style>
