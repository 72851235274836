import * as Sentry from '@sentry/vue';

class ErrorReportingService {
  initialized = false;

  setConfigParams({ app, env, dsn }) {
    this.initialized = true;
    Sentry.init({
      app,
      dsn,
      environment: env,
      integrations: [new Sentry.Replay()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  reportError(err) {
    if (this.initialized) {
      Sentry.captureException(err);
    } else {
      console.log('[ErrorReportingService]', err);
    }
  }
}

export default new ErrorReportingService();
