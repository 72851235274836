const RANDOMNESS = 30;
const SOFA_LIMIT = 3;
const CONTROLS_PAD = 55;
const INPUT_RATIO = 0.66;
const V_PAD = 14;
const V_PAD_COLUMN = 22;
const H_PAD = 14;
const COUCH_H_PAD = 24;
const OUTER_V_PAD = 50;
const OUTER_H_PAD = 50;
const C_PAD = 10;
const CIRCLE_TOP_PAD = 30;
const CIRCLE_BOTTOM_PAD = 40;
const CIRCLE_H_PAD = 30;
const LOGBAR = 80;
const INNER_RATIO_BIG = 0.93;
const INNER_RATIO_SMALL = 0.9;
const INNER_RATIO_BREAKPOINT = 180;
const CONTROL_CLEARANCE = 50;
const AVATAR_BUTTON = 40;

export {
  RANDOMNESS,
  SOFA_LIMIT,
  CONTROLS_PAD,
  INPUT_RATIO,
  V_PAD,
  V_PAD_COLUMN,
  H_PAD,
  COUCH_H_PAD,
  OUTER_V_PAD,
  OUTER_H_PAD,
  C_PAD,
  CIRCLE_TOP_PAD,
  CIRCLE_BOTTOM_PAD,
  CIRCLE_H_PAD,
  LOGBAR,
  INNER_RATIO_BIG,
  INNER_RATIO_SMALL,
  INNER_RATIO_BREAKPOINT,
  CONTROL_CLEARANCE,
  AVATAR_BUTTON,
};
