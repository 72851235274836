/* eslint-disable camelcase */

import { useMomentsStore } from '@/store/pinia/moments';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useTimeStore } from '@/store/pinia/time';
import { useMappingStore } from '@/store/pinia/mapping';
import { useMediaStore } from '@/store/pinia/media';

import utils from '../resources/utils.js';
// import { trigger } from 'mousetrap';

export const keyBy = (array, key) => (array || []).reduce((r, x) => Object.assign(r, { [key ? x[key] : x]: x }), {});

export default class EventsManager {
  constructor(manager) {
    this.manager = manager;
    this.meetingStore = useMeetingStore();
    this.momentsStore = useMomentsStore();
    this.phaseStore = usePhaseStore();
    this.usersStore = useUsersStore();
    this.sidebarStore = useSidebarStore();
    this.timeStore = useTimeStore();
    this.mappingStore = useMappingStore();
    this.mediaStore = useMediaStore();
    this.messagesStore = useMessagesStore();
  }

  process() {
    const { meeting_state } = this.manager;

    (meeting_state.client_events || []).forEach((event) => {
      console.log('[client_event]', event.name);
      if (this[event.name]) {
        this[event.name](event.data);
      }
    });
  }

  on_event_created(data) {
    this.manager.emit('event:created', { payload: data.event });
    this.phaseStore.addToHistory(data);
    this.usersStore.onEventCreated(data);
    this.timeStore.onEventCreated(data);
    this.mappingStore.add(data);
  }

  on_event_updated(data) {
    this.manager.emit('event:updated', data);
    this.phaseStore.updateHistory(data);
    this.mappingStore.update(data);
  }

  on_event_deleted(data) {
    this.manager.emit('event:deleted', data);
    //this.momentsStore.delete(data);
    this.mappingStore.delete(data);
  }

  on_note_added(data) {
    //TODO: remove once name updated
    this.manager.emit('note:created', data);
    this.momentsStore.onNoteAdded(data);
    this.usersStore.mustNotBeOfflineOrGone(data.userId); // They added a note so are probably here
  }
  on_note_created(data) {
    this.manager.emit('note:created', data);
    this.momentsStore.onNoteAdded(data);
    this.usersStore.mustNotBeOfflineOrGone(data.userId); // They added a note so are probably here
  }

  on_note_updated(data) {
    this.manager.emit('note:updated', data);
    this.momentsStore.onNoteUpdated(data);
    this.usersStore.mustNotBeOfflineOrGone(data.userId); // They edited a note so are probably here
  }

  on_note_deleted(data) {
    this.momentsStore.onNoteDeleted(data);
  }

  on_field_locked(data) {
    // this.manager.emit('field:locked', data);
    this.phaseStore.lockField(data);
    this.meetingStore.lockField(data);
  }

  on_field_unlocked(data) {
    // this.manager.emit('field:unlocked', data);
    this.phaseStore.unlockField(data);
    this.meetingStore.unlockField(data);
  }

  on_meeting_starts(data) {
    this.manager.emit('meeting:started', data);
    this.timeStore.meetingUpdated(data);
    this.meetingStore.applySettings(data.meeting);

    this.sidebarStore.applySettings(data.meeting);
  }

  on_meeting_finished(data) {
    this.manager.emit('meeting:finished', data);
  }

  on_meeting_ended(data) {
    this.manager.emit('meeting:ended', data);
  }

  on_meeting_updated(data) {
    this.manager.emit('meeting:updated', data);
    this.timeStore.meetingUpdated(data);
    this.meetingStore.meetingUpdated(data);
  }

  on_advanced_setting_updated({ name, value, updatedBy }) {
    this.meetingStore.updateAdvancedSettings({ name, value, updatedBy });
    this.sidebarStore.updateAdvancedSettings({ name, value, updatedBy });
  }

  on_this_phase_starts(data) {
    this.usersStore.updatePhase(data);
    this.phaseStore.updatePhase(data);
    this.phaseStore.phaseStarted(data);
    this.mediaStore.setPresentationSources(data);
    this.sidebarStore.updatePhase(data);
  }

  on_this_phase_ended(data) {
    this.phaseStore.phaseEnded(data);
    this.mediaStore.setPresentationSources(data);
    this.manager.emit('phase:ended', data);
  }

  on_this_phase_ending(data) {
    this.phaseStore.phaseEnding(data);
    this.manager.emit('phase:ending', data);
  }

  on_next_speaker_changed(data) {
    this.manager.emit('phase:next:speaker', data);
    this.phaseStore.updateSetupSpeaker(data);
  }

  on_next_time_changed(data) {
    this.manager.emit('phase:next:time', data);
    this.timeStore.updateSetupTime(data);
  }

  on_next_questions_changed(data) {
    this.manager.emit('phase:next:question', data);
    this.phaseStore.updateQuestion(data);
  }
  on_next_topic_changed(data) {
    this.phaseStore.updateTopic(data);
  }

  on_next_groups_changed(data) {
    this.manager.emit('phase:next:groups', data);
    this.phaseStore.updateSetupGroups(data);
  }

  on_questions_changed(data) {
    this.manager.emit('phase:question', data);
    this.phaseStore.updateQuestion(data);
  }

  on_topic_changed(data) {
    this.phaseStore.updateTopic(data);
  }

  on_proposal_updated(data) {
    this.phaseStore.updateProposal(data);
  }

  on_proposal_finished() {
    this.phaseStore.proposalFinished();
  }

  on_topic_updated(data) {
    this.phaseStore.updateTopic(data);
  }

  on_topic_finished() {
    this.phaseStore.topicFinished();
  }
  on_responding_ended() {
    this.phaseStore.respondingFinished();
  }

  on_voting_ended() {
    this.phaseStore.updatePhaseStage('voted');
  }

  on_time_added(data) {
    this.phaseStore.updateTime(data);
    this.manager.emit('phase:duration', data);
  }

  on_tip_created(data) {
    this.manager.emit('tip:created', data);
  }

  on_users_away(data) {
    this.usersStore.usersAway(data);
    this.manager.emit('user:away', data);
  }

  on_users_unaway(data) {
    this.usersStore.usersUnaway(data);
    this.sidebarStore.usersUnaway(data);
    this.manager.emit('user:unaway', data);
  }

  on_users_returned(data) {
    this.usersStore.usersReturned(data);
    this.sidebarStore.usersReturned(data);
    this.manager.emit('user:returned', data);
  }

  on_users_unreturned(data) {
    this.usersStore.usersUnreturned(data);
    this.manager.emit('user:unreturned', data);
    if (data.updatedBreakoutGroups) {
      this.phaseStore.updateCurrentPhase({ updatedBreakoutGroups: data.updatedBreakoutGroups });
    }
  }

  on_users_rejoin(data) {
    this.manager.emit('user:rejoin', data);
    this.usersStore.onJoin(data);
  }

  on_user_online(data) {
    this.usersStore.onUserOnline(data);
  }

  on_user_offline(data) {
    this.usersStore.onUserOffline(data);
  }

  on_recommend_as_moderator({ recommendedUserId }) {
    this.usersStore.recommendAsModerator({ recommendedUserId });
  }

  on_moderator_recommendation_rejected({ triggeredBy, wasRecommendedBy }) {
    const me = this.usersStore.me;
    if (wasRecommendedBy === me.id) {
      this.messagesStore
        .addAlert('promotionRejected', { toPromote: utils.desanitizeString(this.usersStore.getUserById(triggeredBy).first_name), id: triggeredBy })
        .catch(() => {});
    }
    this.usersStore.rejectRecommendationAsModerator({ rejectedBy: triggeredBy });
  }

  on_moderator_recommendation_accepted(data) {
    this.usersStore.acceptRecommendationAsModerator(data);
  }

  on_removed_from_moderators(data) {
    this.usersStore.onRemovedFromModerators(data);
  }

  on_user_join_waiting_room(data) {
    this.usersStore.usersJoinWaitingRoom(data);
  }

  on_join_meeting_request_approved(data) {
    this.manager.emit('user:joiningRequestApproved', data);
  }

  joined_meeting_from_waiting_room(data) {
    this.usersStore.onJoinedMeetingFromWaitingRoom(data);
  }

  on_join_meeting_request_rejected(data) {
    this.usersStore.onWaitingRoomRequestRejected(data);
    if (!data.haveLeftMeeting) {
      this.manager.emit('user:removedFromMeeting', data);
    }
  }

  on_users_left(data) {
    this.usersStore.usersLeft(data);
    this.manager.emit('user:left', data);
  }

  on_users_eject(data) {
    this.usersStore.usersEjected(data);
    this.manager.emit('user:removedFromMeeting', data, true);
  }

  on_user_on_stage({ user }) {
    this.manager.emit('phase:stage:on', { user });
    this.phaseStore.updateOnStage({ speaker: user });
  }

  on_user_off_stage({ user }) {
    this.manager.emit('phase:stage:off', { user });
    this.phaseStore.updateOnStage({ speaker: null });
  }

  on_talking_started({ triggeredBy }) {
    const me = this.usersStore.me;
    if (triggeredBy === me) {
      return;
    }
    this.usersStore.updateUser(triggeredBy, { talking: true });
  }

  on_talking_stopped({ triggeredBy }) {
    const me = this.usersStore.me;
    if (triggeredBy === me) {
      return;
    }
    this.usersStore.updateUser(triggeredBy, { talking: false, muted: true });
  }

  on_users_muted({ users }) {
    this.usersStore.handleMutes({ users });
  }

  on_users_unmuted({ users }) {
    this.usersStore.handleMutes({ users });
  }

  on_users_voted({ triggeredBy, vote }) {
    this.usersStore.updateUser(triggeredBy, { vote });
  }

  on_hand_up({ triggeredBy }) {
    this.usersStore.updateUser(triggeredBy, { hand: true });
    this.usersStore.mustNotBeOfflineOrGone(triggeredBy); // They raised their hand, so they must be here
  }

  on_hand_down({ triggeredBy }) {
    this.usersStore.updateUser(triggeredBy, { hand: false });
    this.usersStore.mustNotBeOfflineOrGone(triggeredBy); // They lowered their hand, so they must be here
  }

  on_view_changed(data) {
    this.manager.emit('phase:view:changed', data);
    this.phaseStore.updateView(data);
    this.sidebarStore.updateView(data);
    this.timeStore.updateView(data);
  }

  on_ignored_end_well_changed() {
    this.manager.emit('on_ignored_end_well:updated');
    this.meetingStore.ignoreEndWell();
  }

  on_skipped_begin_well_changed() {
    this.manager.emit('skipped_begin_well:updated');
    this.meetingStore.skipBeginWell();
  }

  on_started_end_well_changed() {
    this.manager.emit('on_started_end_well:updated');
    this.meetingStore.startEndWell();
  }

  on_request_action(data) {
    /**
     * possible action types : common.requestVideo
     */
    this.manager.emit(`action:${data.actionType}`, data);
  }

  on_emote_added(data) {
    this.manager.emit(`emote:added`, data);
    this.usersStore.mustNotBeOfflineOrGone(data.triggeredBy); // They emoted, so they must be here
  }

  on_source_switched(data) {
    this.mediaStore.sourceSwitched(data);
  }

  on_mirroring_started(data) {
    const me = this.usersStore.me;
    if (data.groupMembers && !data.groupMembers.includes(me.id)) {
      // Mirroring started for another group;
      return;
    }
    this.phaseStore.requestStartMirroring(data);
  }

  on_connection_health_changed({ triggeredBy, isConnectivityWentPoor }) {
    const me = this.usersStore.me;
    if (triggeredBy === me.id) {
      return;
    }
    if (isConnectivityWentPoor) {
      this.usersStore.showUnstableNetworkWarn(triggeredBy);
    } else {
      this.usersStore.hideUnstableNetworkWarn(triggeredBy);
    }
  }

  on_mirroring_updated({ isActive, mirroringId }) {
    this.phaseStore.updateMirroringDetails(mirroringId, { isActive });
  }

  on_mirroring_ended({ alreadyEnded, mirroringId }) {
    if (alreadyEnded) {
      return;
    }
    this.phaseStore.requestEndMirroring({ mirroringId });
  }
}
