<template>
  <edit-sidebar class="editQuestion">
    <div class="part">
      <h2>Edit {{ phaseName }} Question&hellip;</h2>
      <TransitionGroup name="questions-modes" mode="out-in">
        <div v-if="!showCustom" class="preGen" key="pregen">
          <ul class="defaults">
            <li v-for="(label, key) in defaultQuestions" :key="key">
              <app-checkbox v-model="questions" :id="key" :disabled="questions.length >= 3 && !questions.includes(label)" :value="label">{{
                label
              }}</app-checkbox>
            </li>
          </ul>
        </div>
        <div v-if="showCustom" class="custom" key="custom">
          <app-list-input
            key="list"
            :field-data="questions"
            item="a question"
            name="questions"
            :limit="255"
            :warn-limit="200"
            :max-items="3"
            @capture="capture"
            @save="saveAndReturn"
          />
        </div>
        <div class="actions" key="actions">
          <div class="btn-pair">
            <button type="button" @click.prevent="cancel">Cancel</button>
            <button type="button" @click.prevent="saveAndReturn">Save</button>
          </div>
          <button class="extra" v-if="!showCustom" @click="showCustom = true">Create my own questions</button>
          <button class="extra" v-if="showCustom" @click="showCustom = false">Use default questions</button>
        </div>
      </TransitionGroup>
    </div>
  </edit-sidebar>
</template>
<script setup>
import { ref, computed, nextTick, getCurrentInstance, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import EditSidebar from '@/components/EditSidebar';
import AppListInput from '@/components/AppListInput';
import AppCheckbox from '@/components/AppCheckbox';

const checkInDefaultQuestions = {
  howAreYou: 'How are you?',
  howIsYourWork: 'How is your work going?',
  anyConcerns: 'Any concerns to address?',
  priorities: 'What are your meeting priorities?',
};

const checkOutDefaultQuestions = {
  finalThoughts: 'Any final thoughts?',
  whatDidYouLearn: 'What did you learn?',
  howIsYourEnergy: 'How is your energy?',
};

const phaseStore = usePhaseStore();
const sidebarStore = useSidebarStore();
const { currentPhase, setupPhase, settingUp, questions: rawQuestions } = storeToRefs(phaseStore);
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const showCustom = ref(false);
const questions = ref([]);

const phaseName = computed(() => {
  if (currentPhase.value === 'checkOut' || setupPhase.value === 'checkOut') {
    return 'Check-Out';
  } else {
    return 'Check-In';
  }
});

const defaultQuestions = computed(() => {
  if (currentPhase.value === 'checkOut' || setupPhase.value === 'checkOut') {
    return checkOutDefaultQuestions;
  } else {
    return checkInDefaultQuestions;
  }
});

onMounted(() => {
  questions.value = rawQuestions.value.map((q) => q.description);
  let customQuestions = questions.value.filter((q) => !Object.values(defaultQuestions.value).includes(q));
  if (customQuestions.length >= 1) {
    showCustom.value = true;
  }
});

function capture(data) {
  questions.value = data;
}

function saveAndReturn() {
  let payload = questions.value.map((q) => {
    return { description: q };
  });
  if (settingUp.value) {
    $meetingmanager.changeNextQuestion(payload);
  } else {
    $meetingmanager.changeQuestion(payload);
  }

  nextTick(() => {
    sidebarStore.finishedWithPane();
  });
}
function cancel() {
  sidebarStore.finishedWithPane();
}

watch(showCustom, (v) => {
  if (!v) {
    questions.value = questions.value.filter((q) => Object.values(defaultQuestions.value).includes(q));
  }
});
</script>
<style lang="scss" scoped>
.editQuestion {
  h2 {
    margin: rem(105px) 0 rem(11px) 0;
  }
}

.part {
  position: relative;
}
.preGen {
  width: rem(192px);

  .defaults {
    list-style: none;
    li + li {
      margin-top: 0.5em;
    }
  }
}

.questions-modes-enter-active,
.questions-modes-enter-active.question-modes-move {
  transition: all 0.3s 0.3s;
}

.questions-modes-leave-active {
  transition: opacity 0.3s cubic-bezier(0.5, 0, 1, 0.5);
  position: absolute;
}

.questions-modes-enter-from,
.questions-modes-leave-to {
  opacity: 0;
}

.questions-modes-move {
  transition: transform 0.3s cubic-bezier(0.6, 0, 0, 1) 0.3s;
}
</style>
