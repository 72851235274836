<template>
  <div>
    <div class="avatarEmoteOverlay" :style="{ top: pos.top + 'px', left: pos.left + 'px', width: pos.width + 'px', height: pos.width + 'px' }">
      <transition-group class="emoteBadges emotes" tag="div" name="emoteFade">
        <div class="emoteBadge" v-for="badge in emoteBadges" :key="badge.key" :class="badge.name">
          <app-icon :icon="badge.name + '-emote'" />
        </div>
      </transition-group>
      <div class="emotes" ref="overEmotes"></div>
    </div>
    <div
      class="avatarMask"
      :style="{
        maskImage: mask,
        top: pos.top - (animationPad + pos.w) / 2 + 'px',
        left: pos.left - (animationPad + pos.w) / 2 + 'px',
        width: pos.width + animationPad + 'px',
        height: pos.width + animationPad + 'px',
      }"
    >
      <div
        class="avatarEmitter"
        :class="classes"
        :style="{
          top: '50%',
          left: '50%',
          width: pos.width + 'px',
          height: pos.width + 'px',
          opacity: moving ? 0 : 1,
        }"
      >
        <div class="emotes" ref="emotes"></div>
        <div class="flash" ref="flashes"></div>
        <app-icon icon="hand" class="hand" v-if="user.connected && !notHere" />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import AppIcon from '@/components/AppIcon.vue';
import * as geometry from '@/resources/geometry';
import anim from '@/resources/animation-constants';
import iconSvgs from '@/resources/icon-svgs';
import utils from '../resources/utils';
import { useFeaturesStore } from '@/store/pinia/features';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMessagesStore } from '@/store/pinia/messages';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const usersStore = useUsersStore();
    const messagesStore = useMessagesStore();
    return { featuresStore, usersStore, messagesStore };
  },
  components: {
    AppIcon,
  },
  props: ['user', 'pos', 'index', 'count', 'showControls'],
  data() {
    return {
      firstHand: false,
      particles: 0,
      flags: 0,
      balloonCount: 0,
      balloonSizes: {
        wave: 0,
      },
      balloonTimeouts: {
        wave: false,
      },
      balloons: {
        wave: false,
      },
      emoteBadges: [],
    };
  },
  mounted() {
    let _this = this;
    this.$ee.on(`nM:avatar:emote:${this.user.id}`, _this.emote);
  },
  beforeUnmount() {
    let _this = this;
    this.$ee.off(`nM:avatar:emote:${this.user.id}`, _this.emote);
  },
  computed: {
    ...mapPiniaState(useFrameStore, ['rem']),
    ...mapPiniaState(useUsersStore, ['me', 'inMyGroup', 'myGroup', 'getUserById', 'currentAvatarPageList', 'userOnMyPage']),
    ...mapPiniaState(useUsersStore, {
      moving(s) {
        return s.isMoving(this.user.id);
      },
    }),
    animationPad() {
      return this.pos.w * 1.5;
    },
    mask() {
      return `radial-gradient(closest-side circle, rgba(0, 0, 0, 0) ${this.pos.w / 2}px, rgba(0, 0, 0, 1) ${this.pos.w / 2}px, black 80%, rgba(0,0,0,0) 100%)`;
    },
    myUser() {
      return this.getUserById(this.user.id);
    },
    classes() {
      return {
        handUp: this.myUser?.hand && this.usersStore.firstShown,
        hideHand: this.hideHand,
        hasControls: this.showControls,
        me: this.myUser?.me,
      };
    },
    handUp() {
      return this.user.hand;
    },
    isNotWithMe() {
      if (!this.user || !this.me) {
        return false;
      }
      return (
        this.usersStore.isAwayInGroup(this.user.id) && ((!this.inMyGroup(this.user.id) && this.usersStore.iAmAwayInGroup) || !this.usersStore.iAmAwayInGroup)
      );
    },
    notHere() {
      return (this.user.away || this.isNotWithMe) && !this.user.me;
    },
    onMyPage() {
      return this.userOnMyPage(this.user.id);
    },
    isEmoting() {
      let emotes = [this.particles, this.flags, this.balloonCount, this.emoteBadges.length];
      return emotes.reduce((a, c) => a + c) > 0;
    },
    hideHand() {
      return this.showControls && !this.firstHand;
    },
    noParticles() {
      return this.featuresStore.useBadgeEmotes;
    },
  },
  watch: {
    showControls(nv) {
      if (!nv) {
        this.firstHand = false;
      }
    },
    handUp(nv) {
      const params = { name: utils.desanitizeString(this.user.first_name), id: this.user.id };
      if (this.user.me && nv && this.showControls) {
        this.firstHand = true;
      }
      if (!this.onMyPage) {
        if (nv) {
          this.messagesStore.addAlert('userRaisedHand', params);
        } else {
          this.firstHand = false;
          this.messagesStore.addAlert('userLoweredHand', params);
        }
      }
    },
    isEmoting(nv) {
      if (nv) {
        this.$ee.emit(`nM:avatar:emoting:${this.user.id}`);
      } else {
        this.$ee.emit(`nM:avatar:unEmoting:${this.user.id}`);
      }
    },
  },
  methods: {
    emote(c) {
      var emotes = {
        yes: [this.risingBurst],
        no: [this.fallingBurst],
        what: [this.burst],
        smile: [this.risingLowFlag],
        gasp: [this.flash, this.burst],
        eek: [this.burst],
        grin: [this.burst],
        roll: [this.spiralBurst],
        grr: [this.burst],
        sad: [this.fallingFlag],
        wave: [this.balloon],
        tongue: [this.flag],
        uhh: [this.spiralFlag],
        whoa: [this.jitterPop, this.burst],
        heart: [this.echoes],
        star: [this.burst, this.shockwave],
        confetti: [this.burst],
        insight: [this.logFlag],
        good: [this.logFlag],
        tricky: [this.logFlag],
        suggestion: [this.logFlag],
        action: [this.logFlag],
        decision: [this.logFlag],
        question: [this.logFlag],
        thoughtful: [this.balloon],
      };

      if (emotes[c]) {
        emotes[c].forEach((f) => {
          if (f) {
            f(c);
          }
        });
      }
    },
    removeParticle(el) {
      if (el && el.parentNode) {
        //el.parentNode.removeChild(el);
      }
    },
    flag(c, extra, angle) {
      if (this.noParticles) {
        this.addBadge(c);
        return false;
      }
      var w = parseInt(this.pos.wOuter);
      var unit = 'px';
      var r = w / 2;
      var $c = this.$refs.emotes;

      angle = angle || 0 - Math.PI * 0.75;
      var angleMod = 0;
      var posR = w / 2 + this.rem * 2.5;
      var duration = 600;
      var $extra = false;
      var startScale = 0.5;
      var mainScale = 1;
      var endScale = 3;

      let extraAnimation = false;
      let extraEasing = 'easeInCirc';
      let extraClass = 'fall';
      let extraDuration;
      var outDelay = 800;

      this.flags++;

      if (c === 'heart') {
        outDelay = 950;
      }

      if (c === 'star') {
        posR = posR * 1.25;
      }

      if (c === 'insight') {
        outDelay = 800 + 150 * 5;
      }

      var $container = $c;

      if (extra === 'spiral') {
        extraClass = 'spiral';
        extraAnimation = { rotateZ: ['90deg', '-360deg'] };
        extraEasing = 'easeInOutQuad';
        extraDuration = 2000;
        posR = w * 0.75;
      }

      if (extra === 'fall') {
        extraAnimation = { translateY: ['100%', 0] };
        posR = w * 0.85;
        duration = 1000;
        outDelay = 400;
        angleMod = (Math.random() * Math.PI) / 8;
      }

      if (extra === 'rise') {
        extraAnimation = { translateY: ['-2em', 0] };

        extraEasing = 'easeOutQuint';
        // posR = w;
        duration = 1000;
        outDelay = 0;
        angleMod = (Math.random() * Math.PI) / 8;
      }

      if (extra === 'riseBig') {
        extraAnimation = { translateY: ['-100%', 0] };
        posR = w / 2 + 50;
        duration = 1000;

        outDelay = 400;
        angleMod = (Math.random() * Math.PI) / 8;
      }

      if (extraAnimation) {
        $extra = document.createElement('div');
        $extra.classList.add(extraClass, 'flagHolder');
        $c.appendChild($extra);

        Velocity($extra, 'stop');

        Velocity($extra, extraAnimation, {
          easing: extraEasing,
          duration: extraDuration ? extraDuration : outDelay + duration,
        });

        $container = $extra;
      }

      var endPos = geometry.circlePoint({
        r: posR,
        cX: 0,
        cY: 0,
        angle: angle + angleMod,
      });

      const $p = document.createElement('i');
      $p.classList.add(c, 'flag');
      $p.innerHTML = iconSvgs(c + '-emote');
      $p.style.top = endPos.top + r + unit;
      $p.style.left = endPos.left + r + unit;

      $container.appendChild($p);

      Velocity(
        $p,
        {
          scale: [mainScale, startScale],
          translateX: [0, 0 - (endPos.left - r) + unit],
          translateY: [0, 0 - (endPos.top - r) + unit],
          opacity: [1, 'linear', 1],
        },
        {
          duration: duration,
          easing: 'easeOutCirc',
          complete: () => {
            this.echoes(c, $p);
          },
        },
      );

      Velocity(
        $p,
        {
          scale: [endScale, mainScale],
          opacity: [0, 'easeOutCirc', 1],
        },
        {
          duration: 1000,
          delay: outDelay > 0 ? outDelay : 0,
          easing: 'easeInOutCirc',
          complete: () => {
            this.removeParticle($p);
            this.flags--;
            if ($extra) {
              this.removeParticle($extra);
            }
          },
        },
      );
    },
    risingFlag(c) {
      this.flag(c, 'rise');
    },
    risingLowFlag(c) {
      this.flag(c, 'riseBig', Math.PI * 0.9);
    },
    risingVerticalFlag(c) {
      this.flag(c, 'rise', Math.PI * -0.6);
    },
    fallingFlag(c) {
      this.flag(c, 'fall');
    },
    spiralFlag(c) {
      this.flag(c, 'spiral');
    },
    logFlag() {
      this.flag();
    },
    balloon(c) {
      if (this.featuresStore.cpuLevel === 'bad' && c === 'wave') {
        this.addBadge(c);
        return false;
      }
      clearTimeout(this.balloonTimeouts[c]);
      this.balloonTimeouts[c] = setTimeout(() => {
        if (c === 'thoughtful') {
          this.removeOverBalloon(c);
        } else {
          this.removeBalloon(c);
        }
      }, 1600);
      if (this.balloons[c]) {
        this.inflateBalloon(c);
      } else {
        if (c === 'thoughtful') {
          this.addOverBalloon(c);
        } else {
          this.addBalloon(c);
        }
      }
    },
    addBalloon(c) {
      this.balloonCount++;
      var w = parseInt(this.pos.wOuter);
      var unit = 'px';
      var r = w / 2;
      var $c = this.$refs.emotes;

      let angle = 0 - Math.PI * 0.75;
      var posR = w / 2 + this.rem * 3.5;
      var duration = 300;
      var startScale = 0.5;
      var mainScale = 1.2;
      // var endScale = 4;
      // var outDelay = 1500;
      var $container = $c;

      var endPos = geometry.circlePoint({
        r: posR,
        cX: 0,
        cY: 0,
        angle: angle,
      });

      this.balloons[c] = document.createElement('i');
      this.balloons[c].classList.add(c, 'flag');
      this.balloons[c].classList.add(c, 'balloon');
      this.balloons[c].innerHTML = `<span class="sizer">${iconSvgs(c + '-emote')}</span>`;
      this.balloons[c].style.top = endPos.top + r + unit;
      this.balloons[c].style.left = endPos.left + r + unit;

      $container.appendChild(this.balloons[c]);

      Velocity(
        this.balloons[c],
        {
          scale: [mainScale, startScale],
          translateX: [0, 0 - (endPos.left - r) + unit],
          translateY: [0, 0 - (endPos.top - r) + unit],
          opacity: [1, 'linear', 1],
        },
        {
          duration: duration,
          easing: 'easeOutCirc',
          complete: () => {
            this.animateBalloon(c);
          },
        },
      );
    },
    addOverBalloon(c) {
      this.balloonCount++;
      var $container = this.$refs.overEmotes;

      var duration = 300;
      var startScale = 0.5;
      var mainScale = 1.2;

      this.balloons[c] = document.createElement('i');
      this.balloons[c].classList.add(c, 'flag');
      this.balloons[c].classList.add(c, 'balloon');
      this.balloons[c].innerHTML = `<span class="sizer">${iconSvgs(c + '-emote')}</span>`;
      this.balloons[c].style.top = 'auto';
      this.balloons[c].style.bottom = '0';
      this.balloons[c].style.left = '50%';

      $container.appendChild(this.balloons[c]);

      Velocity(
        this.balloons[c],
        {
          scale: [mainScale, startScale],
          translateY: [0, '100%'],
          opacity: [1, 0],
        },
        {
          duration: duration,
          easing: 'easeOutCirc',
          complete: () => {
            // this.animateBalloon(c);
          },
        },
      );
    },
    inflateBalloon(c) {
      if (this.balloonSizes[c] < 12) {
        this.balloonSizes[c]++;
      }
      let sizer = this.balloons[c].querySelector('.sizer');
      if (sizer) {
        let scaleMod = c === 'thoughtful' ? 10 : 4;
        sizer.style.transform = `scale(${1 + this.balloonSizes[c] / scaleMod})`;
      }
    },
    removeBalloon(c) {
      Velocity(this.balloons[c], 'stop', true);
      Velocity(
        this.balloons[c],
        {
          scale: 0,
          opacity: 0,
        },
        {
          duration: 300,
          easing: 'easeInCirc',
          complete: () => {
            this.removeParticle(this.balloons[c]);
            this.baloonCount--;
            this.balloons[c] = false;
            this.balloonTimeouts[c] = false;
            this.balloonSizes[c] = 0;
          },
        },
      );
    },
    removeOverBalloon(c) {
      Velocity(this.balloons[c], 'stop', true);
      Velocity(
        this.balloons[c],
        {
          scale: 0,
          transformY: '100%',
          opacity: 0,
        },
        {
          duration: 300,
          easing: 'easeInCirc',
          complete: () => {
            this.removeParticle(this.balloons[c]);
            this.baloonCount--;
            this.balloons[c] = false;
            this.balloonTimeouts[c] = false;
            this.balloonSizes[c] = 0;
          },
        },
      );
    },
    animateBalloon(c) {
      if (this.balloons[c]) {
        if (c === 'wave') {
          Velocity(
            this.balloons[c],
            {
              rotateZ: '-10deg',
            },
            {
              queue: 'wave',
              easing: anim.BASE_EASE_IN_OUT,
              duration: 150,
            },
          );

          Velocity(
            this.balloons[c],
            {
              rotateZ: '10deg',
            },
            {
              queue: 'wave',
              easing: anim.BASE_EASE_IN_OUT,
              duration: 150,
              complete: () => {
                this.animateBalloon(c);
              },
            },
          );

          Velocity.Utilities.dequeue(this.balloons[c], 'wave');
        }
      }
    },
    burst(c, extra) {
      if (this.noParticles) {
        this.addBadge(c);
        return false;
      }
      var w = parseInt(this.pos.wOuter);
      var unit = 'px';
      var $c = this.$refs.emotes;
      var i = 0;
      var total = 5;
      var $extra = false;
      var $container = $c;
      var roundOffset = 0;
      let animationLength = 1500;

      const getCirclePos = geometry.circlePoint;

      if (c === 'confetti') {
        total = this.featuresStore.cpuLevel === 'great' ? 40 : 20;
      }

      var slice = (Math.PI * 2) / total;
      var offset = Math.random() * slice;

      if (extra === 'fall' || extra === 'rise') {
        animationLength = 1500;
        $extra = document.createElement('div');
        $extra.classList.add('fall');
        $c.appendChild($extra);
        $container = $extra;
        Velocity(
          $extra,
          {
            translateY: extra === 'fall' ? ['15em', '0em'] : ['-15em', '0em'],
          },
          {
            easing: 'easeInCirc',
            duration: animationLength,
            complete: () => {
              //$c.removeChild($extra);
            },
          },
        );
      }
      if (extra === 'spiral') {
        $extra = document.createElement('div');
        $extra.classList.add('spiral');
        $c.appendChild($extra);
        $container = $extra;
        Velocity(
          $extra,
          {
            rotateZ: ['180deg', '0deg'],
          },
          {
            easing: 'easeOutQuint',
            duration: animationLength,
            complete: () => {
              //$c.removeChild($extra);
            },
          },
        );
      }

      var incrementRemove = (el) => {
        this.removeParticle(el[0]);
        this.particles--;
      };

      var basicRemove = (el) => {
        this.removeParticle(el[0]);
      };

      this.particles++;
      let startOpacity = 1;

      for (i; i < total; i++) {
        //var startOpacity = Math.random() > 0.7 ? 0.5 + Math.random() * 0.5 : 0.9 + Math.random() * 0.1;

        var startRand = Math.random();
        var endRand = Math.random();
        var startSize = 1 * startRand;
        var endSize = startSize + 1 * endRand;

        var startPos = getCirclePos({
          r: w / 2,
          cX: 0,
          cY: 0,
          angle: slice * i + offset + roundOffset,
        });

        //let extra = w * 0.3 > 10 ? 10 : w * 0.3
        //var endR = w / 2 + w * 0.3 * Math.random();
        //var endR = w / 2 + extra * Math.random();

        var endPos = getCirclePos({
          r: w / 2,
          cX: 0,
          cY: 0,
          angle: slice * i + offset + roundOffset,
        });

        var rotateZ;
        if (['heart', 'star', 'confetti', 'roll'].includes(c)) {
          rotateZ = [360 * Math.random() + 'deg', 360 * Math.random() + 'deg'];
        } else {
          rotateZ = [30 * Math.random() - 15 + 'deg', 30 * Math.random() - 15 + 'deg'];
        }

        const $p = document.createElement('i');
        $p.classList.add(c);
        if (c !== 'confetti') {
          $p.innerHTML = iconSvgs(c + '-emote');
        }

        $container.appendChild($p);

        var complete = i === 0 ? incrementRemove : basicRemove;

        Velocity(
          $p,
          {
            scale: [endSize, startSize],
            translateX: [endPos.left + unit, startPos.left + unit],
            translateY: [endPos.top + unit, startPos.top + unit],
            opacity: [0, 'easeInQuint', startOpacity],
            rotateZ: rotateZ,
          },
          {
            duration: animationLength,
            easing: 'easeOutCirc',
            complete: complete,
          },
        );

        _.delay(() => {
          $p.classList.add('over');
        }, 500);
      }
    },
    risingBurst(c) {
      this.burst(c, 'rise');
    },
    fallingBurst(c) {
      this.burst(c, 'fall');
    },
    spiralBurst(c) {
      this.burst(c, 'spiral');
    },
    echoes(c, $c) {
      if (this.noParticles && !$c) {
        this.addBadge(c);
        return false;
      }

      if (c === 'heart' || c === 'insight') {
        var echoCount = 0;
        var shape = false;

        var flag = $c ? true : false;
        var duration = 300;

        $c = $c || this.$refs.emotes;

        var anim = {
          opacity: [0, 'linear', 1],
        };

        var initCss = {
          opacity: 0,
        };

        if (c === 'insight') {
          echoCount = 5;
          initCss.rotateZ = '45deg';
          anim.rotateZ = ['45deg', '45deg'];
        }

        if (c === 'heart') {
          echoCount = 1;
          shape = iconSvgs('heart-emote');
          duration = 800;
        }

        if (flag) {
          anim.scale = [1, 0];
        } else {
          anim.scale = [1.75, 0.7];
        }

        var echoFinish = ($e) => {
          this.removeParticle($e[0]);
        };

        var delay = 0;
        for (var i = 0; i < echoCount; i++) {
          const $p = document.createElement('i');
          $p.classList.add(c + 'Echo', 'echo');
          if (shape) {
            $p.innerHTML = shape;
          }
          $p.style.display = 'none';

          $c.appendChild($p);

          Velocity($p, initCss, 0);

          Velocity($p, anim, {
            duration: duration,
            delay: delay,
            display: 'block',
            easing: anim.BASE_EASE_OUT,
            complete: echoFinish,
          });

          delay = delay + 100;
          duration = duration * 1.5;
        }
      }
    },
    shockwave(c) {
      if (this.noParticles) {
        return false;
      }
      this.shockwaves++;
      const flash = document.createElement('i');
      flash.classList.add(c);
      flash.classList.add('shockwave');
      this.$refs.flashes.appendChild(flash);
      Velocity(
        flash,
        {
          translateZ: 0,
          scale: [2, 1],
          opacity: [0, anim.BASE_EASE_OUT, 1],
        },
        {
          duration: 800,
          easing: 'easeOutCirc',
          complete: (el) => {
            this.removeParticle(el);
            this.shockwaves--;
          },
        },
      );
    },
    addBadge(c) {
      let id = _.uniqueId('badge_');
      this.emoteBadges.push({
        name: c,
        key: id,
      });
      _.delay(() => {
        this.emoteBadges = this.emoteBadges.filter((emo) => emo.key !== id);
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarEmoteOverlay,
.avatarMask {
  z-index: $z__avatar-base-emotes;
}

.avatarEmitter,
.avatarEmoteOverlay {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
  //backface-visibility: hidden;
  border-radius: 50%;
  pointer-events: none;
}

.avatarMask {
  pointer-events: none;
  position: absolute;
}
.avatarEmitter {
  transition: top 0s 0.6s, left 0s 0.6s, transform 0s 0.3s, opacity 0.3s ease;
}
.avatarEmoteOverlay {
  transition: top 0.6s cubic-bezier(0.45, 0, 0.15, 1), left 0.6s cubic-bezier(0.45, 0, 0.15, 1), transform 0.3s cubic-bezier(0.45, 0, 0.15, 1),
    opacity 0.3s ease;
}

.emotes,
.flash {
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.shockwave {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  border-radius: 50%;
  background-image: radial-gradient(farthest-side at 50% 50%, var(--c__bg-alt-0) 50%, var(--color) 100%);
  border: 1px solid var(--color);
}

// Hand

.hand {
  position: absolute;
  display: block;
  top: -5%;
  left: -5%;
  right: 50%;
  bottom: 50%;
  transform-origin: bottom right;
  transition: all 0.6s cubic-bezier(0.8, 0, 0.2, 1);
  transform: scale(0.1) rotate(-90deg);
  pointer-events: none;
  opacity: 0;
  :deep(svg) {
    width: 35%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: center;
    transition: transform 0.7s cubic-bezier(0.8, 0, 0.2, 1);
    transform: rotateZ(0deg);
  }
  :deep(.fill) {
    fill: var(--c__orange);
  }
}

.handUp .hand {
  opacity: 1;
  transform: rotate(0deg) scale(1);
  :deep(svg) {
    transform: rotateZ(40deg);
  }
}

.handUp.hideHand .hand {
  transform: translateX(#{rem(-40px)}) translateY(#{rem(-40px)});
}

// Emotes

.emotes {
  pointer-events: none;
  --color: var(--c__accent);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
