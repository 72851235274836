<template>
  <audio id="alarmAudio" ref="sound">
    <source :src="accountStore.baseUrl + '/assets/audio/alarm.mp3'" />
    <source :src="accountStore.baseUrl + '/assets/audio/alarm.ogg'" />
  </audio>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useAccountStore } from '@/store/pinia/account';
import { useTimeStore } from '@/store/pinia/time';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';

export default {
  setup() {
    const phaseStore = usePhaseStore();
    const accountStore = useAccountStore();
    const timeStore = useTimeStore();
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    const pageStore = usePageStore();
    return { phaseStore, accountStore, timeStore, meetingStore, usersStore, pageStore };
  },
  data() {
    return {
      running: false,
      alarmTimeout: null,
      volume: 0.6,
      alarmOn: false,
      alarmBroadcast: _.debounce(this.alarmBroadcastRaw, 500),
    };
  },
  computed: {
    ...mapPiniaState(usePhaseStore, ['breakEndedEarly', 'groupsReassemble']),
    ...mapPiniaState(useUsersStore, ['iAmSpeaking', 'iAmInWaitingRoom']),
    phaseOverTime() {
      if (this.timeStore.phaseLength === 0) {
        return false;
      } else if (this.timeStore.phaseRemaining <= 0) {
        return true;
      } else {
        return false;
      }
    },
    noAlarm() {
      if (['lobby', 'setTime', 'freeform'].includes(this.phaseStore.currentPhase) || this.iAmInWaitingRoom) {
        return true;
      }
      if (this.pageStore.current.pageName === 'stopSharingDrawer') {
        return false;
      }
      if (!this.meetingStore.initTitleCardShown) {
        return true;
      }
      return false;
    },
    shouldRunAlarm() {
      if (this.noAlarm || this.phaseStore.isAnimating || this.phaseStore.settingUp || this.phaseStore.groupsOverlayShowing) {
        return false;
      } else if (
        this.phaseOverTime &&
        (this.iAmSpeaking || this.phaseStore.currentPhase === 'endMeeting' || this.usersStore.iAmAway || this.usersStore.iAmAwayInGroup)
      ) {
        return true;
      } else if (this.breakEndedEarly && this.usersStore.iAmAway) {
        return true;
      } else if (this.usersStore.iAmStillAway || this.usersStore.iAmStillAwayInGroup) {
        return true;
      } else if (this.groupsReassemble && this.usersStore.iAmAwayInGroup) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    shouldRunAlarm(nv) {
      this.alarmBroadcast(nv);
    },
    noAlarm() {
      this.alarmBroadcast();
    },
    running(nv, ov) {
      if (nv !== ov) {
        if (nv) {
          document.body.classList.add('alarmRunning');
          document.body.classList.remove('alarmNotRunning');
        } else {
          document.body.classList.add('alarmNotRunning');
          document.body.classList.remove('alarmRunning');
        }
      }
    },
    'timeStore.alarmRunning': function (run) {
      if (run && !this.running) {
        this.running = true;
        this.start();
      } else if (!run && this.running) {
        this.stop();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.sound.volume = 0;
    });
  },
  methods: {
    alarmBroadcastRaw(nv) {
      if (nv) {
        this.timeStore.startAlarm();
      } else {
        this.timeStore.stopAlarm();
      }
    },
    start() {
      _.delay(this.run, 200);
    },
    run() {
      if (this.timeStore.alarmRunning) {
        this.$refs.sound.play().catch(() => {});

        if (this.$refs.sound.volume === 0) {
          this.currentTime = 0;
          Velocity(
            this.$refs.sound,
            {
              tween: this.volume,
            },
            {
              progress: (elements, complete, remaining, start, tweenValue) => {
                this.$refs.sound.volume = tweenValue;
              },
              duration: 200,
            },
          );
        }

        this.alarmTimeout = setTimeout(() => {
          if (this.timeStore.alarmRunning) {
            this.start();
          }
        }, 300);
      }
    },
    stop() {
      this.running = false;
      Velocity(this.$refs.sound, 'stop');
      Velocity(
        this.$refs.sound,
        {
          tween: [0, this.volume],
        },
        {
          progress: (elements, complete, remaining, start, tweenValue) => {
            this.$refs.sound.volume = tweenValue;
          },
          duration: 200,
          complete: () => {
            this.$refs.sound.pause();
            this.currentTime = 0;
          },
        },
      );
      clearTimeout(this.alarmTimeout);
    },
  },
};
</script>
