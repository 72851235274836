export default () => (to, from, next) => {
  const { user } = window.__INITIAL_STATE__;
  const isAuthenticated = user && user.id;

  if (!isAuthenticated) {
    return next(false);
  }

  const access = to.meta.access;

  if (access.conference && access.conference !== user.permissions.conference) {
    return next('/not-found');
  }

  return next();
};
