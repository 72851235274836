<template>
  <transition name="basic">
    <div v-if="show" id="titleCard" ref="card" class="titleCard" :class="cardClass">
      <h1>{{ title }}</h1>
      <p v-if="card === 'greenRoom'">
        {{ desc }}
      </p>
    </div>
  </transition>
</template>

<script>
import _ from 'underscore';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { pageStore, phaseStore, meetingStore, usersStore };
  },
  data() {
    return {
      showingTime: null,
      show: false,
      quickShown: false,
    };
  },
  mounted() {
    const _this = this;
    this.$ee.on('nM:showTitleCard', _this.quickShowCard);
  },
  beforeUnmount() {
    const _this = this;
    this.$ee.off('nM:showTitleCard', _this.quickShowCard);
  },
  computed: {
    card() {
      return this.meetingStore.titleCard;
    },
    pageName() {
      return this.pageStore.current.pageName;
    },
    visibleUsers() {
      return this.allUsers && this.allUsers.length > 0 ? this.allUsers.filter((u) => !u.gone) : [];
    },
    meetingSpaceTitle() {
      return this.visibleUsers > 3 ? 'Talking Circle' : 'Zen Cafe';
    },
    cardLength() {
      if (this.card === 'greenRoom') {
        return 2000;
      } else if (this.card === 'meetingSpace') {
        return 1000;
      } else {
        return 1500;
      }
    },
    title() {
      if (this.card === 'greenRoom') {
        return 'Green Room';
      } else if (this.card === 'meetingSpace') {
        return this.usersStore.iAmInWaitingRoom ? 'Waiting Room' : 'Meeting Space';
      } else {
        return '';
      }
    },
    desc() {
      if (this.card === 'greenRoom') {
        return 'The Green Room is where you arrive before your meeting starts';
      } else if (this.card === 'meetingSpace') {
        return `Welcome to the ${this.meetingSpaceTitle}`;
      } else {
        return '';
      }
    },
    cardClass() {
      return this.card + 'Card';
    },
  },
  watch: {
    pageName: {
      immediate: true,
      handler(nv) {
        if (nv === 'meetingFrame') {
          this.cardCheck(this.phaseStore.currentPhase, 'init');
        }
      },
    },
    'phaseStore.currentPhase': {
      immediate: true,
      handler(nv, ov) {
        if (nv) {
          if (this.phaseStore.skipTitleCard) {
            return;
          }
          this.cardCheck(nv, ov);
        }
      },
    },
  },
  methods: {
    cardCheck(nv) {
      if (!nv) {
        return false;
      }
      if (this.phaseStore.currentPhase === 'endMeeting' && this.phaseStore.isFirstPhase) {
        // don't show a card if going straight into end
        this.meetingStore.updateTitleCard(false, 'skipForEnd');
        return false;
      }
      if (!['endMeeting', 'init'].includes(nv) && (!this.page || (this.pageStore.isMeeting && nv !== 'ended'))) {
        let cardName = '';
        if (this.meetingStore.useLobby && ['lobby', 'waiting'].includes(nv)) {
          cardName = 'greenRoom';
        } else {
          cardName = 'meetingSpace';
        }
        if ((this.card !== 'meetingSpace' && cardName !== this.card) || this.quickShown) {
          this.meetingStore.updateTitleCard(cardName, 'was quickShown?');
          this.quickShown = false;
          this.$nextTick(() => {
            this.showCard();
          });
        }
      }
    },
    quickShowCard(cardName) {
      this.quickShown = true;
      this.meetingStore.updateTitleCard(cardName, 'quickshow');
      this.$nextTick(() => {
        this.show = true;
      });
    },
    showCard() {
      this.show = true;
      this.showingTime = window.setTimeout(this.hideCard, this.cardLength);
    },
    hideCard() {
      this.show = false;
      _.delay(() => {
        this.meetingStore.updateTitleCard(false, 'hideCard');
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.titleCard {
  position: absolute;
  z-index: 200;
  background: var(--c__bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: $header-height;
  left: 0;
  bottom: 0;
  width: 100vw;
  width: calc(var(--vw, 1vw) * 100);

  h1 {
    font-size: rem(90px);
    line-height: math.div(110, 90);
    margin-bottom: 0;
    font-weight: 100;
  }
  p {
    @include type-size('small');
    line-height: math.div(17, 14);
    margin-top: rem(11px);
    margin-bottom: 0;
  }

  aside {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: math.div($header-height, 2);
  }

  &.meetingSpaceCard {
    background: var(--c__bg-alt);

    h1 {
      margin-bottom: 0.5em;
    }
    aside {
      height: rem(131px);
      width: rem(292px);
      margin-top: rem(23px);
      background-image: url($base-url + '/assets/images/sofa.png');
    }

    .talkingCircle & {
      aside {
        display: none;
      }
      p {
        margin-bottom: 4em;
      }
    }
  }

  &.greenRoomCard {
    aside {
      margin-top: rem(34px);
      height: rem(91px);
      width: rem(295px);
      background-image: var(--bg__table-meeting-shadowless);
    }
  }
}
</style>
