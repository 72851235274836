const colors = {
  white: '#ffffff',
  palestGrey: '#fafafa',
  ghostWhite: '#f8f8f8',
  paleGrey: '#f8f8f8',
  paleishGrey: '#efefef',
  lightGrey: '#d8d8d8',
  lighterGrey: '#c8c8c8',
  grey: '#c0c0c0',
  midGrey: '#979797',
  black: '#000000',
  charcoal: '#333333',
  blue: '#00a2ff',
  darkerBlue: '#0071bc',
  iceBlue: '#6dcff6',
  red: '#ff5a50',
  darkRed: '#c20000',
  redLighter: '#ff7059',
  orange: '#faaf3c',
  green: '#b2d235',
  yellow: '#f5d010',
  purple: '#9a00de',
  pine: '#369b85',
};

colors.neonList = [colors.blue, colors.red, colors.orange, colors.iceBlue, colors.green, colors.purple, colors.pine, colors.darkerBlue];

colors.default = colors.white;
colors.accent = colors.blue;
colors.yes = colors.green;
colors.ask = colors.orange;
colors.no = colors.red;
colors.mute = colors.accent;
colors.muteVideo = colors.accent;
colors.selected = colors.accent;
colors.offline = colors.white;
colors.sysMute = colors.accent;
colors.sysMuteVideo = colors.accent;
colors.hasQuestion = colors.orange;
colors.askingQuestion = colors.orange;
colors.speaking = colors.accent;
colors.chosen = colors.accent;
colors.voted = colors.yellow;
colors.yesVote = colors.green;
colors.noVote = colors.red;
colors.hmmVote = colors.orange;
colors.away = colors.white;
colors.problems = colors.white;

colors.grr = colors.red;
colors.what = colors.orange;
colors.eek = colors.yellow;
colors.smile = colors.yellow;
colors.grin = colors.yellow;
colors.roll = colors.yellow;
colors.sad = colors.blue;
colors.uhh = colors.purple;
colors.whoa = colors.rd;
colors.heart = colors.red;
colors.star = colors.yellow;
colors.wave = colors.blue;

colors.insight = colors.yellow;
colors.question = colors.orange;
colors.decision = colors.blue;
colors.action = colors.green;
colors.good = colors.blue;
colors.tricky = colors.blue;
colors.different = colors.blue;

export { colors };
