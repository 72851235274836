<template>
  <div class="meetingTimeInfo">
    <section class="starts" v-click-outside="closeStartTimes">
      <span class="label" @click="closeStartTimes">Starts</span>
      <span class="date" @click="closeStartTimes">{{ startDateString }}</span>
      <input
        type="time"
        v-model="localStartTimeString"
        step="300"
        ref="startTimeInput"
        @change="interactedWithStart = true"
        @focus="showStartTimes = true"
        :class="[error ? 'hasError' : '']"
      />
      <transition name="basic">
        <div class="picker" v-if="showStartTimes">
          <ul class="minutes">
            <li v-for="time in startTimesList" :class="[time.value === localStartTimeString ? 'current' : '']" :key="time.label">
              <button @click="setStart(time.value)">{{ time.label }}</button>
            </li>
          </ul>
        </div>
      </transition>
    </section>
    <transition name="basic">
      <p class="error" v-if="error">{{ error }}</p>
    </transition>
    <section class="ends" v-click-outside="closeEndTimes">
      <span class="label" @click="closeEndTimes">Ends</span>
      <span class="date" @click="closeEndTimes">{{ endDateString }}</span>
      <input type="time" v-model="localEndTimeString" step="300" ref="endTimeInput" @change="interactedWithEnd = true" @focus="showEndTimes = true" />
      <transition name="basic">
        <div class="picker" v-if="showEndTimes">
          <ul class="minutes">
            <li v-for="time in endTimesList" :class="[time.label === localEndTimeString ? 'current' : '']" :key="time.label">
              <button @click="setEnd(time.value)">{{ time.label }}</button>
            </li>
          </ul>
        </div>
      </transition>
    </section>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';
import { useTimeStore } from '@/store/pinia/time';
import _ from 'underscore';

const minutesOptions = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
const hoursOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export default {
  setup() {
    const phaseStore = usePhaseStore();
    return { phaseStore };
  },
  data() {
    return {
      interactedWithStart: false,
      interactedWithEnd: false,
      localStartTimeString: 0,
      localEndTimeString: 0,
      otherTime: '',
      broadcastTime: _.debounce(this.broadcastTimeRaw, 600),
      showStartTimes: false,
      showEndTimes: false,
      error: false,
    };
  },
  created() {},
  mounted() {
    // Check if meeting is in the past
    let now = new Date();
    if (this.startTime < now && this.phaseStore.currentPhase === 'lobby' && this.usersOnline.length === 1) {
      let newEnd = new Date();
      newEnd.setTime(now.getTime() + this.meetingLength);
      // If so, update the time to be based on now
      this.broadcastTime({
        start: now,
        end: newEnd,
      });
    } else {
      this.updateFields();
    }
  },
  beforeUnmount() {},
  computed: {
    ...mapPiniaState(useTimeStore, ['meetingLength']),
    ...mapPiniaState(useTimeStore, {
      startTime: 'meetingStart',
      endTime: 'meetingEnd',
    }),
    ...mapPiniaState(useUsersStore, ['usersOnline']),
    localStartTime() {
      if (this.localStartTimeString) {
        let newStart = new Date(this.startTime);
        let [hours, minutes] = this.localStartTimeString.split(':');
        newStart.setHours(hours);
        if (minutes) {
          newStart.setMinutes(minutes);
        }
        return newStart;
      } else {
        return new Date();
      }
    },
    localEndTime() {
      if (this.localEndTimeString) {
        let newEnd = new Date(this.startTime);
        let [hours, minutes] = this.localEndTimeString.split(':');
        hours = parseInt(hours);
        minutes = parseInt(minutes);
        newEnd.setHours(hours);
        if (minutes) {
          newEnd.setMinutes(minutes);
        }
        if (newEnd <= this.localStartTime) {
          newEnd.setHours(newEnd.getHours() + 24);
        }
        return newEnd;
      } else {
        return new Date();
      }
    },
    startTimeString() {
      return Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.startTime);
    },
    startDateString() {
      return Intl.DateTimeFormat('en-uk', { weekday: 'short', day: 'numeric', month: 'short' }).format(this.startTime);
    },
    endTimeString() {
      return Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.endTime);
    },
    endDateString() {
      return Intl.DateTimeFormat('en-uk', { weekday: 'short', day: 'numeric', month: 'short' }).format(this.endTime);
    },
    startTimesList() {
      return minutesOptions.map((m) => {
        let label = String(m);
        label = label.length < 2 ? '0' + label : label;
        let value = `${this.startTimeString.split(':')[0]}:${label}`;
        return {
          value,
          label,
        };
      });
    },
    endTimesList() {
      return minutesOptions.map((m) => {
        let label = String(m);
        label = label.length < 2 ? '0' + label : label;
        let value = `${this.endTimeString.split(':')[0]}:${label}`;
        return {
          value,
          label,
        };
      });
    },
    hoursList() {
      return hoursOptions.map((value) => {
        let label = String(value);
        label = label.length < 2 ? '0' + label : label;
        return {
          value,
          label,
        };
      });
    },
  },
  watch: {
    startTime() {
      this.updateFields();
    },
    localStartTimeString() {
      let newEnd = new Date(this.localStartTime);

      let now = new Date();
      if (this.localStartTime < now && this.interactedWithStart) {
        this.error = 'Start time must be later than current time';
      } else {
        this.error = false;
      }

      if (!this.interactedWithEnd) {
        newEnd.setTime(newEnd.getTime() + this.meetingLength);
        this.localEndHours = newEnd.getHours();
        this.localEndMinutes = newEnd.getMinutes();
        if (newEnd <= this.localStartTime) {
          newEnd.setHours(newEnd.getHours() + 24);
        }
      } else {
        newEnd.setHours(this.localEndTime.getHours());
        newEnd.setMinutes(this.localEndTime.getMinutes());
        if (newEnd <= this.localStartTime) {
          newEnd.setHours(newEnd.getHours() + 24);
        }
      }

      if (!this.error) {
        this.broadcastTime({ start: this.localStartTime, end: newEnd });
      }
    },
    localEndTimeString() {
      let newEnd = new Date(this.localStartTime);
      newEnd.setHours(this.localEndTime.getHours());
      newEnd.setMinutes(this.localEndTime.getMinutes());
      if (newEnd <= this.localStartTime) {
        newEnd.setHours(newEnd.getHours() + 24);
      }
      this.broadcastTime({ start: this.localStartTime, end: newEnd });
    },
    endTime() {
      this.updateFields();
    },
  },
  methods: {
    broadcastTimeRaw({ start, end }) {
      this.$nextTick(() => {
        if (this.phaseStore.currentPhase === 'lobby') {
          this.$meetingmanager.updateMeeting({
            starts_on: start.getTime() / 1000,
            ends_on: end.getTime() / 1000,
            trigger: 'scheduler',
          });
        }
      });
    },
    updateFields() {
      this.localStartTimeString = Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.startTime);
      this.localEndTimeString = Intl.DateTimeFormat('en-uk', { timeStyle: 'short', hourCycle: 'h24' }).format(this.endTime);
    },
    setStart(m) {
      this.localStartTimeString = m;
      this.showStartTimes = false;
      this.interactedWithStart = true;
    },
    setEnd(m) {
      this.localEndTimeString = m;
      this.showEndTimes = false;
      this.interactedWithEnd = true;
    },
    setStartHour(h) {
      this.localStartTimeString = `${h}:${this.localStartTimeString.split(':')[0]}`;
      //this.showStartTimes = false;
      this.interactedWithStart = true;
    },
    setEndHour(h) {
      this.localEndTimeString = `${h}:${this.localEndTimeString.split(':')[0]}`;
      //this.showEndTimes = false;
      this.interactedWithEnd = true;
    },
    closeStartTimes() {
      this.showStartTimes = false;
    },
    closeEndTimes() {
      this.showEndTimes = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.meetingTimeInfo {
  padding: rem(25px) rem(30px) rem(20px) rem(30px);
  position: absolute;
  bottom: rem(80px);
  width: 100%;
  border-top: 1px solid var(--c__edges);
  background: var(--c__bg);
  .error {
    margin-right: rem(-20px);
    color: var(--c__warn);
    position: absolute;
    left: rem(30px);
    top: rem(43px);
  }
  .starts,
  .ends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
  .starts {
    margin: 0;
  }
  .ends {
    margin: rem(13px) 0 0 0;
  }

  .date {
    margin-right: auto;
  }

  .label {
    font-weight: bold;
    flex: 0 0 rem(85px);
  }
  input[type='time'] {
    border: none;
    width: 4.75em;
    color: var(--c__text);
    @include triggered {
      outline: none;
      outline-color: transparent;
      outline-style: none;
    }
    &.hasError {
      color: var(--c__warn);
    }
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }

  .picker {
    position: absolute;
    bottom: 2em;
    right: 1em;
    background: white;
    //width: 7em;
    text-align: center;
    border: 1px solid var(--c__edges);
    padding: 0.5em 0;
    border-radius: 0.5em;
    box-shadow: var(--shadow);
    display: flex;
    ul {
      list-style: none;
    }
    .hours {
      width: 5em;
      column-count: 2;
      column-gap: 0;
      padding-right: 0.5em;
      border-right: 1px solid var(--c__edges);
      li {
        display: inline-block;
      }
    }
    .minutes {
      width: 3em;
    }
    button {
      width: 100%;
      @include triggered {
        background: var(--c__accent);
        color: var(--c__bg);
      }
    }
    .current button {
      background: var(--c__accent);
      color: var(--c__bg);
    }
  }
}
</style>
