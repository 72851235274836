<template>
  <div ref="emitter" class="emoteBubbles emotes" v-bind="$attrs" />
  <transition-group class="emoteBadges emotes extraBadges" tag="div" name="emoteFade">
    <div class="emoteBadge" v-for="badge in emoteBadges" :key="badge.key" :class="badge.name">
      <app-icon :icon="badge.name + '-emote'" />
    </div>
  </transition-group>
</template>

<script>
import _ from 'underscore';
import iconSvgs from '@/resources/icon-svgs';

import AppIcon from '@/components/AppIcon.vue';

const bubbleDirections = ['', '-big', '-bigger', '-biggest'];
const blastDirections = [-2, -1, 0, 1, 2];

const blasts = ['confetti'];
const bigs = ['smile', 'grin', 'tongue', 'sad', 'grr', 'uhh'];
const logs = ['insight', 'question', 'action', 'decision'];
import { useFeaturesStore } from '@/store/pinia/features';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    return { featuresStore };
  },
  props: {
    direction: {
      default: 'back',
    },
    vertical: {
      default: false,
    },
  },
  components: {
    AppIcon,
  },
  data() {
    return {
      emoteBadges: [],
    };
  },
  computed: {
    side() {
      return this.direction === 'back' ? 'right' : 'left';
    },
    bubbleDirections() {
      return ['up', ...bubbleDirections.map((d) => this.side + d)];
    },
  },
  mounted() {
    var _this = this;
    this.$ee.on(`nM:emoteEmitter:${_this.direction}`, _this.emote);
  },

  beforeUnmount() {
    var _this = this;
    this.$ee.off(`nM:emoteEmitter:${_this.direction}`, _this.emote);
  },
  methods: {
    emote(type) {
      if (this.featuresStore.useBadgeEmotes) {
        this.addBadge(type);
        return false;
      }
      this.addFlash(type);

      if (blasts.includes(type)) {
        this.addBlast(type);
      } else if (bigs.includes(type)) {
        this.addBigBubble(type);
      } else {
        this.addBubble(type);
      }
    },
    addBubble(type) {
      const $particle = document.createElement('div');
      const $p = document.createElement('i');
      $p.classList.add(type);

      const iconName = logs.includes(type) ? 'log-' + type : type + '-emote';

      $p.innerHTML = iconSvgs(iconName);

      $particle.classList.add('particle', 'bubble', _.sample(this.bubbleDirections));
      $particle.appendChild($p);
      this.$refs.emitter.appendChild($particle);

      _.delay(() => {
        this.removeParticle($particle);
      }, 2000);
    },
    addBigBubble(type) {
      const $particle = document.createElement('div');
      const $p = document.createElement('i');
      $p.classList.add(type);
      $p.innerHTML = iconSvgs(type + '-emote');

      $particle.classList.add('particle', 'bubble', 'big', _.sample(this.bubbleDirections.slice(0, 3)));
      $particle.appendChild($p);
      this.$refs.emitter.appendChild($particle);

      _.delay(() => {
        this.removeParticle($particle);
      }, 2000);
    },
    addFlash(type) {
      const $p = document.createElement('i');
      $p.classList.add('flash', type);
      this.$refs.emitter.appendChild($p);
      _.delay(() => {
        this.removeParticle($p);
      }, 500);
    },
    addBlast(type) {
      const $c = document.createElement('div');
      $c.classList.add('blast', type);

      _.shuffle(blastDirections).forEach((dir) => {
        let y = Math.random() * -20 - 10;
        let x = dir + 0.5 - Math.random();

        let $particle = document.createElement('div');
        let $p = document.createElement('i');
        let $div = document.createElement('div');
        $p.classList.add(type);

        $particle.classList.add('particle', 'bubble');
        $particle.style.top = y + 'em';
        $particle.style.left = x + 'em';

        $particle.style.transform = `translateX(${0 - x}em) translateY(${0 - y}em)`;
        $div.style.transform = `rotateZ(${180 - Math.random() * 360}deg)`;
        $div.style.opacity = 1;

        $particle.appendChild($p);
        $p.appendChild($div);
        $c.appendChild($particle);
        _.delay(() => {
          $div.style.transform = `rotateZ(0deg) scale(1.5)`;
          $particle.style.transform = `translateX(0) translateY(0)`;
          //$div.style.opacity = 0;
          $particle.style.opacity = 0;
        }, 10);
      });

      this.$refs.emitter.appendChild($c);
      _.delay(() => {
        this.removeParticle($c);
      }, 2000);
    },
    removeParticle(el) {
      if (el && el.parentNode) {
        el.parentNode.removeChild(el);
      }
    },
    addBadge(c) {
      let id = _.uniqueId('badge_');
      this.emoteBadges.push({
        name: c,
        key: id,
      });
      _.delay(() => {
        this.emoteBadges = this.emoteBadges.filter((emo) => emo.key !== id);
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
@keyframes emote-float-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-1em);
  }
}

@keyframes emote-float-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1em);
  }
}
@keyframes emote-float-left-big {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2em);
  }
}

@keyframes emote-float-right-big {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(2em);
  }
}

@keyframes emote-float-left-bigger {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-3em);
  }
}

@keyframes emote-float-right-bigger {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(3em);
  }
}

@keyframes emote-float-left-biggest {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-4em);
  }
}

@keyframes emote-float-right-biggest {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(4em);
  }
}

@keyframes emote-float-up-fade {
  0% {
    transform: scale(0.5) translateY(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }
  100% {
    transform: scale(2) translateY(-12em);
    opacity: 0;
  }
}

@keyframes emote-float-up-fade-less {
  0% {
    transform: scale(0.5) translateY(0);
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }
  100% {
    transform: scale(2) translateY(-6em);
    opacity: 0;
  }
}

@keyframes emote-rotate {
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes emote-wave {
  0% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

@keyframes emote-flash {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes emote-jitter {
  0% {
    transform: scale(1.25) rotateZ(-15deg);
  }
  50% {
    transform: scale(1) rotateZ(0deg);
  }
  100% {
    transform: scale(1.25) rotateZ(15deg);
  }
}

.emoteBubbles {
  background: rgb(255, 0, 0, 0);
  height: 1px;
  width: 1px;
  position: absolute;
  left: 50%;
  top: 50%;
  pointer-events: none;
  .bubble {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    animation: emote-float-up-fade 2s linear forwards;
    i {
      display: block;
      width: 2em;
      height: 2em;
      margin: -1em 0 0 -1em;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    &.big {
      animation: emote-float-up-fade-less 2s linear forwards;
    }

    &.left i {
      animation: emote-float-left 1s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.right i {
      animation: emote-float-right 1s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.left-big i {
      animation: emote-float-left-big 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.right-big i {
      animation: emote-float-right-big 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.left-bigger i {
      animation: emote-float-left-bigger 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.right-bigger i {
      animation: emote-float-right-bigger 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.left-biggest i {
      animation: emote-float-left-biggest 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    &.right-biggest i {
      animation: emote-float-right-biggest 1.5s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }
    .star svg,
    .uhh svg {
      animation: emote-rotate 2s linear infinite;
    }

    .grr svg {
      animation: emote-jitter 0.3s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
    }

    .wave {
      width: 4em;
      height: 4em;
      svg {
        transform-origin: bottom right;
        animation: emote-wave 0.25s cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
      }
    }

    .insight,
    .question,
    .action,
    .decision {
      width: 1em;
      height: 1em;
      margin: -0.5em 0 0 -0.5em;
    }
  }
  .confetti.blast {
    .bubble {
      animation: none;
      transition: all 1s cubic-bezier(0, 1, 1, 1);
    }

    i {
      background: none;
      & > div {
        transition: all 1s linear;
        height: 1em;
        width: 0.7em;
      }
    }

    .particle:nth-child(5n) i div {
      background: $c_accent;
    }
    .particle:nth-child(5n + 1) i div {
      background: $c_green;
    }
    .particle:nth-child(5n + 2) i div {
      background: $c_yellow;
    }
    .particle:nth-child(5n + 3) i div {
      background: $c_red;
    }
    .particle:nth-child(5n + 4) i div {
      background: $c_orange;
    }
  }
  .flash {
    position: absolute;
    margin-left: -1em 0 0 -1em;
    width: 2em;
    height: 2em;
    top: 50%;
    left: 50%;
    transform-origin: center center;
    border-radius: 50%;
    animation: emote-flash 0.5s cubic-bezier(0, 1, 1, 1) forwards;
    background-image: radial-gradient(farthest-side at 50% 50%, var(--c__bg-alt-0) 50%, var(--color) 100%);
    border: 1px solid var(--color);
  }
}

.extraBadges {
  top: 50%;
  left: 50%;
  position: absolute;
  height: 3em;
  width: 3em;
  margin: -1.5em 0 0 -1.5em;
  border-radius: 50%;
  .horizontal & {
    margin: -4.5em 0 0 -1.5em;
  }
  .vertical & {
    margin: -1.5em 0 0 -4.5em;
  }
}
</style>
