<template>
  <please-use class="biggerScreen">
    <img class="biggerPlease" :src="accountStore.baseUrl + '/assets/images/toosmall.png'" />
    <h1>This window is too small</h1>
    <p>Please expand your browser window to a larger size</p>
  </please-use>
</template>
<script>
import PleaseUse from '@/components/PleaseUse';
import { useAccountStore } from '@/store/pinia/account';
export default {
  components: {
    PleaseUse,
  },
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
};
</script>
<style lang="scss" scoped>
.biggerScreen {
  display: none;
  opacity: 0;
  p {
    max-width: 15em;
  }
  img {
    height: rem(64px);
    width: rem(88px);
    margin-bottom: rem(45px);
  }
}

html.noOverlay .biggerScreen,
.pleaseUseDesktopPage .biggerScreen {
  display: none !important;
}

@include respond-to-smaller-than(750px) {
  .biggerScreen {
    opacity: 1;
    display: flex;
  }
}
@include respond-to-shorter-than(570px) {
  .biggerScreen {
    opacity: 1;
    display: flex;
  }
}
</style>
