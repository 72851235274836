<template>
  <div class="avatarPlaceholder" v-show="!hide">
    <svg class="dots" viewBox="0 0 100 100">
      <circle r="49" cx="50" cy="50" />
    </svg>
    <app-scaled-text font-size="10" :class="startClass" v-if="!moving">{{ label }}</app-scaled-text>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import _ from 'underscore';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import AppScaledText from '@/components/AppScaledText.vue';

const props = defineProps(['user']);
const startClass = ref('initial');

onMounted(() => {
  _.delay(() => {
    startClass.value = 'showing';
  }, 50);
});

const usersStore = useUsersStore();
const { hide, moving: movingList } = storeToRefs(usersStore);
const moving = computed(() => movingList.value.has(props.user.id));
const label = computed(() => (props.user.connected ? 'Away' : 'Offline'));
</script>
<style lang="scss" scoped>
.avatarPlaceholder {
  position: absolute;
  //backface-visibility: hidden;
  transition: top 0.6s cubic-bezier(0.45, 0, 0.15, 1), left 0.6s cubic-bezier(0.45, 0, 0.15, 1), transform 0.3s cubic-bezier(0.45, 0, 0.15, 1),
    opacity 0.3s ease;
  border-radius: 50%;

  z-index: $z__avatar-base-core;
}
.dots {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  right: 0.5em;
  bottom: 0.5em;
  pointer-events: none;
  //background: radial-gradient(closest-side, var(--c__bg), var(--c__bg) 75%, var(--c__bg-0) 100%);
  border-radius: 50%;
  circle {
    fill: var(--c__bg-100);
    stroke: var(--c__quiet);
    stroke-width: 2;
    stroke-dasharray: 7%;
  }
}

@keyframes resizeFix {
  0% {
    width: 99%;
    height: 99%;
    opacity: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.scaledText {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 50%;
  pointer-events: none;
  transition: all 0.5s;
  :deep(svg) {
    animation: resizeFix 0.3s 0.3s both;
  }
}

:deep(text) {
  fill: var(--c__text);
}

.scaledText.initial {
  width: 99.9%;
  opacity: 0;
}
</style>
