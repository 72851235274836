import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';

export function useReady() {
  const usersStore = useUsersStore();
  const meetingStore = useMeetingStore();

  const { allUsers } = storeToRefs(usersStore);
  const { initTitleCardShown } = storeToRefs(meetingStore);

  const readyForAvatars = computed(() => {
    return initTitleCardShown.value && allUsers.value && allUsers.value.length >= 1;
  });

  return { readyForAvatars };
}
