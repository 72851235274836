<template>
  <section class="page endPages poster" :class="[pageName]" :data-page-name="pageName">
    <transition mode="out-in" name="basic">
      <div v-if="loading" class="loadingFeedback">
        <app-loading-spinner />
      </div>
    </transition>
    <transition mode="out-in" name="basic">
      <div v-if="isEligibleForFeedback && !loading" class="meetingFeedback content">
        <h2>Rate bixe</h2>
        <NowhereFeedbackForm @submit="onSubmit" />
      </div>
    </transition>
  </section>
</template>

<script setup>
import '@now-here/feedback-ui/style.css';

import { computed, ref, onMounted, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { NowhereFeedbackForm } from '@now-here/feedback-ui';
import utils from '../resources/utils';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';

const $API = getCurrentInstance().appContext.config.globalProperties.$API;

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const route = useRoute();

const props = defineProps(['pageName']);
const accountStore = useAccountStore();
const pageStore = usePageStore();

const { canCreate } = storeToRefs(accountStore);

const isEligibleForFeedback = ref(false);
const loading = ref(true);

onMounted(() => {
  pageStore.update({
    pageName: props.pageName,
  });

  loading.value = true;
  $API
    .getIsEligibleForFeedback()
    .then((resp) => {
      if (!resp.isEligible) {
        onActionTaken();
        return;
      }
      loading.value = false;
      isEligibleForFeedback.value = true;
    })
    .catch(() => {
      loading.value = false;
    });
});

const meetingId = computed(() => {
  return route.query.id;
});

function onSubmit({ feedback, promise }) {
  const redirectDelay = feedback?.rating > 0 ? 800 : 0;
  $API.submitMeetingFeedback(meetingId.value, feedback).finally(() => {
    promise.resolve();
    setTimeout(onActionTaken, redirectDelay);
  });
}

function onActionTaken() {
  if (utils.isOpenedWithinElectronShell()) {
    if (canCreate.value) {
      $ee.emit('navigate', `/welcome`);
    } else {
      $ee.emit('navigate', `/meeting-has-been-ended`);
    }
  } else {
    $ee.emit('navigate', `/meeting-over?id=${meetingId.value}`);
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: var(--c__bg-alt);
  text-align: center;
}

.endPages > .meetingFeedback.content {
  padding-top: 0;
  h2 {
    font-weight: 300;
    margin-bottom: rem(24px);
    @include type-size(small);
  }
}

.loadingFeedback {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  .text {
    position: absolute;
    top: 50%;
    margin-top: -0.75em;
    text-align: center;
    width: 100%;
    opacity: 0;
  }

  .loadingSpinner {
    height: 24px;
    width: 24px;
    path {
      stroke-width: 2;
    }
  }
}
</style>
