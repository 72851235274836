import _ from 'underscore';

export default (nM) => {
  const config = {
    baseUrl: '',
  };
  // const config = window.bixe.config;

  nM.callbackPromise = (resolve, reject) => {
    return function (err, ...args) {
      if (err) {
        reject(err);
      } else {
        resolve.apply(null, args);
      }
    };
  };

  nM.normalizeUrl = function (url) {
    if (config.baseUrl !== '/' && url.startsWith(config.baseUrl)) {
      return url.replace(config.baseUrl, '');
    }

    return url;
  };

  nM.toErrorMessage = function (err) {
    if (err.error && err.error.status === 500) {
      return 'Errm... Something went wrong. Please refresh or reload the app';
    }

    return err.error ? err.error.message : err.message;
  };

  nM.toTimeObject = (mpt) => {
    const DEFAULT_PHASE_DURATION_SECONDS = 300;
    const DEFAULT_RATIO = 1;
    const DEFAULT_STEP = 60;

    switch (mpt.module_id) {
      case 'checkin':
      case 'checkout':
        return {
          defaultTime: mpt.default_time_per_turn,
          max: mpt.max_default_time_per_turn,
          min: mpt.min_default_time_per_turn,
          ratio: DEFAULT_RATIO,
          step: mpt.step,
        };

      case 'freeform':
      case 'finish':
        return {
          defaultTime: DEFAULT_PHASE_DURATION_SECONDS,
          max: DEFAULT_PHASE_DURATION_SECONDS * 2,
          min: DEFAULT_PHASE_DURATION_SECONDS,
          ratio: DEFAULT_RATIO,
          step: DEFAULT_STEP,
        };

      default:
        return {
          defaultTime: mpt.duration,
          max: mpt.max_duration,
          min: mpt.min_duration,
          ratio: DEFAULT_RATIO,
          step: mpt.step,
        };
    }
  };

  nM.toTimesObject = (mpts) => {
    const times = {};

    const phases = ['break', 'checkin', 'checkout', 'context', 'decision', 'freeform', 'finish', 'groups', 'input', 'review', 'dialogue', 'feedback'];
    const indexed = _.indexBy(mpts, 'module_id');

    phases.forEach((x) => {
      const mpt = indexed[x];
      const key = nM.phaseMap[x];

      if (mpt) {
        times[key] = nM.toTimeObject(mpt);
      }
    });

    return times;
  };

  nM.toSettings = (settings) => {
    return {
      cpuLevel: settings.cpuLevel || 'auto',
      quality: nM.resolutionMap[settings.resolution] || 'high',
      framerate: nM.frameRateMap[settings.frame_rate] || 'high',
      frame_rate: settings.frame_rate,
      resolution: settings.resolution,
      audioOnly: settings.audio_only,
      reduced_animation: settings.reduced_animation,
      reducedAnimation: settings.reduced_animation,
      simplifiedGraphics: settings.simplified_graphics,
      simplified_graphics: settings.simplified_graphics,
      theme: settings.theme || 'default',
      meetingSpace: settings.meetingSpace || 'default',
      spaceAnimation: settings.spaceAnimation,
      meetingDefaultSettings: settings.meetingDefaultSettings,
    };
  };

  nM.toVideoSettings = (settings) => {
    return {
      resolution: settings.resolution || nM.resolutionMapReverse[settings.quality],
      frameRate: settings.frame_rate || nM.frameRateMapReverse[settings.framerate],
    };
  };

  nM.buildExternalUserId = (userId) => `user-${userId}`;

  nM.parseExternalUserId = (externalUserId) => parseInt(externalUserId.substr(5), 10);
};
