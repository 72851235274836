<template>
  <div v-if="!featuresStore.perf.uglyMode" class="volumeContainer">
    <div class="volume" :style="style" />
  </div>
</template>

<script>
import { useFeaturesStore } from '@/store/pinia/features';
import { useUsersStore } from '@/store/pinia/users';
export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const usersStore = useUsersStore();
    return { featuresStore, usersStore };
  },
  props: {
    volume: {
      default: 0,
    },
    user: {
      default: {},
    },
  },
  computed: {
    moving() {
      return this.usersStore.isMoving(this.user.id);
    },
    volumeTransform() {
      return this.volume ? `scale(${1 + this.volume / 2.5})` : 'scale(1)';
    },
    opacity() {
      return this.volume > 0 && !this.moving ? 1 : 0;
    },
    style() {
      if (this.featuresStore.settings.simplified_graphics) {
        return { transform: this.volumeTransform };
      } else {
        return { transform: this.volumeTransform, opacity: this.opacity };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatarVolume {
  .volume {
    z-index: 0;
    border: rem(1px) solid rgba($c_accent, 0.2);
    transform-origin: center;
    transition: all 0.5s ease;
    pointer-events: none;
    @include light-mode('beach') {
      border: rem(1px) solid rgba(#003399, 0.2);
    }
  }
}
.offline .volume,
.notHere .volume {
  display: none;
}

.volume {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  z-index: 0;
  border: rem(2px) solid rgba($c_accent, 0.25);
  transform-origin: center;
  transition: all 0.3s ease;
  pointer-events: none;
  .simplifiedGraphics &,
  .bad-cpu &,
  .low-cpu & {
    border: 0.5px solid lighten($c_accent, 35%);
    @include dark-mode() {
      border: 0.5px solid darken($c_accent, 20%);
    }
    @include light-mode('beach') {
      border: 0.5px solid rgba(#003399, 1);
    }
  }
}
</style>
