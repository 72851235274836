<template>
  <section class="app-component launching-now poster">
    <div class="content">
      <title-block />
      <p class="launching">Launching app...</p>

      <div class="tryAgain">
        <p>Meeting didn't open in the app? <a class="underlined" :href="appLink">Try again</a></p>
        <p>
          <span class="leadin">Launch failed? </span>
          <browser-guarded-button :to="`${webLink}&chosenPlatform=browser`" :inline="true">Open in browser</browser-guarded-button>
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import TitleBlock from '@/components/TitleBlock.vue';
import { useAccountStore } from '@/store/pinia/account';

export default {
  components: {
    TitleBlock,
    BrowserGuardedButton,
  },
  props: {
    webLink: String,
    appLink: String,
    meetingId: String,
    pinPass: String,
  },
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.buttonWrapper {
  position: relative;
}

:deep(.bubbleTip .message) {
  @include type-size('small');
}

section,
.content {
  flex: 1;
  display: flex;
}
.content {
  flex-direction: column;
  justify-content: space-around;
}
p.launching {
  @include type-size('large');
  font-weight: 100;
  margin-top: rem(38px);
}

.tryAgain {
  margin: rem(68px) 0 0 0;
  p {
    margin: 0;
  }
}

a {
  color: var(--c__accent);
  @include triggered {
    color: var(--c__accent-alt);
  }
}

.icon {
  margin-top: auto;
}
</style>
