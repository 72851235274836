import utils from '../resources/utils';

const redirectUrls = ['/meeting/', '/room/', '/join', '/meeting-has-been-ended', '/meeting-finished', '/join-as-guest', '/check'];

export default () => async (to, from, next) => {
  const config = window.__INITIAL_STATE__.config;

  if (!config.video.enabled) {
    return next();
  }

  const mediaAllowed = utils.storage.ls.getItem('mediaAllowed');
  const isNative = utils.isOpenedWithinElectronShell();

  const startsWith = redirectUrls.some((x) => decodeURI(to.fullPath).startsWith(x));
  const query = startsWith ? { redirectUrl: to.fullPath } : {};
  if (!mediaAllowed && !isNative) {
    return next({ path: '/allow', query });
  }

  return next();
};
