<template>
  <div class="neon poster">
    <transition name="slowFade">
      <div v-if="hasSkyline" class="skyline"></div>
    </transition>
    <transition name="slowFade" v-if="meetingStore.initTitleCardShown && usersStore.firstShown">
      <div class="propsFrame poster">
        <avatars-sofa />
        <transition name="basic">
          <div v-if="hasCoffeeTable" class="coffeeTable" :style="tableStyle"></div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import AvatarsSofa from '@/components/AvatarsSofa';

import { useFrameStore } from '@/store/pinia/frame';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const frameStore = useFrameStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { frameStore, meetingStore, phaseStore, usersStore };
  },
  components: {
    AvatarsSofa,
  },
  data() {
    return {
      interval: null,
    };
  },
  mounted() {},
  computed: {
    ...mapPiniaState(useUsersStore, {
      userPositions: 'positions',
      positionDetails: 'positionDetails',
    }),
    hasSkyline() {
      if (!this.usersStore.firstShown) {
        return false;
      }

      if (this.positionDetails.layoutName === 'couch' || this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom) {
        return false;
      }
      if (['waiting', 'lobby', 'endMeeting', 'finish', 'ended', 'input'].includes(this.phaseStore.currentPhase) || this.phaseStore.isPresentation) {
        return false;
      }
      return true;
    },
    hasCoffeeTable() {
      return this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom;
    },
    tableStyle() {
      let css = {
        left: this.frameStore.contWidth - (this.positionDetails.left + this.positionDetails.width) + 'px',
        top: this.positionDetails.bottom + 'px',
      };
      return css;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.skyline {
  width: 100%;
  background-repeat: repeat-x;
  background-size: contain;
  height: 20%;
  position: absolute;
  top: 50%;
  background-image: url($base-url + '/assets/images/spaces/neon/skyline.svg');
  z-index: 10;
  display: block;
}

.propsFrame {
  @include sidebar-response;
}

.coffeeTable {
  position: absolute;
  //left: 33.333%;
  z-index: 90;
  pointer-events: none;
  transform: translateY(-15.5%);
  width: math.div(192, $design-height) * 100vh;
  width: calc(var(--vh, 1vh) * (192 / #{$design-height}) * 100);
  height: math.div(187, $design-height) * 100vh;
  height: calc(var(--vh, 1vh) * (187 / #{$design-height}) * 100);
  display: block;
  transition: all 500ms cubic-bezier(0.45, 0, 0.55, 1);

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
  }

  &:after {
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    background-image: url($base-url + '/assets/images/spaces/neon/neon-coffee-table.svg');
  }

  &:before {
    bottom: -3px;
    height: 5px;
    background: var(--c__shadow-opaque);
    box-shadow: 0 0 10px var(--c__shadow-opaque), 0 0 5px var(--c__shadow-opaque), 0 0 3px var(--c__shadow-opaque);
    left: -1em;
    right: -1em;
    border-radius: 50%;
    z-index: -1;
  }
}
</style>
