<template>
  <section>
    <h3 class="heading-small">{{ title }}</h3>
    <ul class="skillMenu" :class="directionClass">
      <slot></slot>
    </ul>
    <slot name="after"></slot>
  </section>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    sequential: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    directionClass() {
      return this.sequential ? 'sequential' : 'nonSequential';
    },
  },
};
</script>
<style lang="scss" scoped>
.skillMenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: flex-start;
  margin: auto 0 rem(14px) 0;
  flex: 0 0 auto;

  &.sequential {
    flex-direction: column;
    align-items: center;
    :deep(li) {
      margin-bottom: rem(22px + 18px);
    }
  }
}

h3 {
  margin-bottom: 1em;
  text-align: center;
}
</style>
