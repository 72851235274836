<template>
  <div
    class="dropdownButton wrapper"
    v-click-outside="
      () => {
        expanded = false;
      }
    "
  >
    <div class="core" :class="[expanded ? 'open' : 'closed']">
      <button class="confirm" @click.prevent="emit('confirm')" :disabled="expanded" ref="goButton">
        <span class="text">
          <slot>Go</slot>
        </span>
      </button>
      <button class="toggle" @click="expanded = !expanded" :aria-expanded="expanded" ref="toggle" v-if="!pending">
        <app-icon icon="down-arrow" />
        <span class="visuallyHidden">Toggle Options</span>
      </button>
      <span v-if="pending"><app-loading-spinner /></span>
    </div>
    <transition name="quickFade">
      <ul class="optionsList" v-if="expanded" ref="optionList" tabindex="-1">
        <li v-for="option in options" :key="option.value">
          <label
            >{{ option.label }}<input type="radio" v-model="selected" :value="option.value" @click="onSelect" ref="optionItems" class="visuallyHidden" /><span
              class="token"
              role="presentation"
            ></span
          ></label>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script setup>
import { ref, defineModel, watch, nextTick } from 'vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import AppIcon from '@/components/AppIcon';
const emit = defineEmits(['confirm', 'change']);
defineProps(['pending', 'options', 'current']);
const selected = defineModel();
const expanded = ref(false);
const goButton = ref();
const toggle = ref();
const optionList = ref();
const optionItems = ref([]);

watch(expanded, (nv) => {
  nextTick(() => {
    if (nv && optionList.value) {
      optionList.value.focus();
    } else if (!nv) {
      toggle.value.blur();
    }
  });
});

function onSelect() {
  setTimeout(() => {
    expanded.value = false;
  }, 300);
}
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
  --border-width: 3px;
  width: 100%;
  border: var(--border-width) solid var(--c__text);
}

$left-pad: 35px;

label,
.core {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 rem($left-pad);
}

.core button,
.core {
  height: rem(65px);
}

button,
li {
  @include type-size('medium');
}

button[disabled] {
  color: var(--c__disabled);
}

ul {
  position: absolute;
  top: 100%;
  background: var(--c__bg-alt);
  left: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  list-style: none;
  border: var(--border-width) solid var(--c__text);
  border-top: none;
  padding-bottom: rem(22px);
}

li + li {
  margin-top: rem(22px);
}

label {
  width: 100%;
  @include triggered {
    color: var(--c__accent);
  }
  &:focus-within {
    color: var(--c__accent);
  }
}

.token {
  display: block;
  height: 1em;
  width: 1em;
  border: solid 1px currentColor;
  border-radius: 50%;
  position: relative;
  &::after {
    content: ' ';
    border-radius: 50%;
    display: block;
    position: absolute;
    background: currentColor;
    top: 25%;
    left: 25%;
    right: 25%;
    bottom: 25%;
    transform: scale(0);
    transition: scale 0.3s ease;
  }
}
input:checked + .token::after {
  transform: scale(1);
}

.confirm {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-left: rem($left-pad);
  text-align: left;
  display: block;
  &:hover,
  &:focus {
    background: var(--c__text);
    color: var(--c__bg);
  }
}

.confirm:hover + .toggle,
.confirm:focus + .toggle {
  color: var(--c__bg);
}

.core:hover {
  background: var(--c__text);
  color: var(--c__bg);
  &.open,
  &.open .confirm {
    background: var(--c__bg-alt);
    color: var(--c__disabled);
  }

  &.open {
    .confirm:hover + .toggle,
    .confirm:focus + .toggle {
      color: var(--c__text);
    }
  }
}

.toggle {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 rem($left-pad) 0 0;
  .icon {
    transition: transform 0.5s ease;
    width: 1em;
  }
  &[aria-expanded='true'] .icon {
    transform: rotateZ(180deg);
  }
  &:hover {
    color: var(--c__bg);
  }
  &:focus {
    color: var(--c__accent);
  }
  .core.open &:hover {
    color: var(--c__text);
  }
}
.loadingSpinner {
  position: absolute;
  right: rem(37px);
  top: rem(24px);
}
</style>
