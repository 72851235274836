<template>
  <section class="startWith phaseExtra">
    <b>Start With&hellip;</b>
    <p>{{ speakerName }}</p>
    <Transition name="scale">
      <MicroskillUserList v-if="showList" @close="showList = false" @outside="showList = false" title="Start With&hellip;" />
    </Transition>
    <app-stealth-button class="toggleUserList edit" @click="showList = true"><span class="text">Show User List</span></app-stealth-button>
  </section>
</template>

<script setup>
import MicroskillUserList from '@/components/MicroskillUserList.vue';
import AppStealthButton from '@/components/AppStealthButton.vue';
import { ref, computed } from 'vue';
import { useUsersStore } from '@/store/pinia/users';
import { storeToRefs } from 'pinia';
import utils from '../resources/utils';

const usersStore = useUsersStore();
const { speaking } = storeToRefs(usersStore);

const showList = ref(false);
const speakerName = computed(() => {
  return utils.desanitizeString(`${speaking.value.first_name} ${speaking.value.last_name}`);
});
</script>

<style lang="scss" scoped>
section {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  margin-right: rem(24px);
  margin-left: rem(34px);
  padding-top: 1em;
  position: relative;
  z-index: 10;
}
</style>
