<template>
  <div class="beach poster" :class="showClass">
    <transition name="basic">
      <div v-show="hasBoats" class="sea" :class="[featuresStore.useSpaceAnimation ? 'animated' : 'static']" ref="sea"></div>
    </transition>
    <transition name="basic">
      <div class="staticSea static" v-show="!featuresStore.useSpaceAnimation && hasBoats">
        <div class="boat left liner-2"></div>
        <div class="boat right small-2"></div>
        <div class="boat left sailboat-3"></div>
        <div class="boat right tanker"></div>
        <div class="boat left small-4"></div>
      </div>
    </transition>
    <transition name="basic">
      <div v-if="hasFurniture" class="chairs">
        <div class="parasol" :style="parasolStyle"></div>
        <div class="cocktails" :style="tableStyle"></div>
        <div
          v-for="(pos, id) in userPositions"
          class="chair"
          :key="'chair-' + id"
          :style="{ top: pos.top - pos.w * 0.13 + 'px', left: pos.left - pos.w / 2 + 'px', width: pos.w + 'px', height: pos.w + 'px' }"
        ></div>
        <div class="glow"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { whichAnimationEvent } from '@/resources/css-events';
import _ from 'underscore';
const animationEvent = whichAnimationEvent();

const fastBoats = ['speedboat', 'small-1', 'small-2'];
const closeBoats = ['speedboat', 'small-1', 'small-2', 'small-3', 'small-4'];
const midBoats = ['cruiser', 'sailboat-1', 'sailboat-2'];
const farBoats = ['liner', 'tanker'];

import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { featuresStore, phaseStore, usersStore };
  },
  data() {
    return {
      interval: null,
      debouncedSpaceAnimationSwitch: _.debounce(this.spaceAnimationSwitch, 200),
    };
  },
  mounted() {
    this.interval = setInterval(this.addBoat, 10000);
    if (this.featuresStore.useSpaceAnimation) {
      this.addBoat(true);
      this.addBoat(true);
      this.addBoat(true);
      this.addBoat(true);
      this.addBoat(true);
    }
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      userPositions: 'positions',
      positionDetails: 'positionDetails',
      iAmAway: 'iAmAway',
    }),
    hasFurniture() {
      if (this.phaseStore.currentPhase === 'ended') {
        return false;
      }
      if (this.positionDetails.layoutName === 'couch' || this.positionDetails.layoutName === 'solo') {
        return true;
      } else {
        return false;
      }
    },
    hasBoats() {
      return !this.phaseStore.isPresentation;
    },
    parasolStyle() {
      let css = {
        height: this.positionDetails.height * 1.7 + 'px',
      };
      if (this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom) {
        css.left = '80%';
      }

      return css;
    },
    tableStyle() {
      let size = this.positionDetails.height * 0.7;
      let css = {
        height: size + 'px',
        width: size + 'px',
        transform: 'translateX(-50%)',
      };

      if (this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom) {
        css.left = '80%';
      }
      return css;
    },
    showClass() {
      if (this.usersStore.firstShown) {
        if (this.iAmAway || this.usersStore.iAmInWaitingRoom) {
          return ['hide'];
        } else if (
          this.phaseStore.currentPhase === 'waiting' ||
          this.phaseStore.currentPhase === 'finish' ||
          this.phaseStore.currentPhase === 'ended' ||
          this.phaseStore.settingUpPhase === 'breakTime'
        ) {
          return ['show', 'showMountain'];
        } else {
          return ['show'];
        }
      } else {
        return ['hide'];
      }
    },
  },
  watch: {
    'featuresStore.useSpaceAnimation': function (nv) {
      this.debouncedSpaceAnimationSwitch(nv);
    },
  },
  methods: {
    spaceAnimationSwitch(nv) {
      if (nv) {
        Velocity(
          this.$refs.sea,
          {
            opacity: 0,
          },
          {
            duration: 300,
            complete: () => {
              this.$refs.sea.replaceChildren();
              this.addBoat(true);
              this.addBoat(true);
              this.addBoat(true);
              Velocity(
                this.$refs.sea,
                {
                  opacity: 1,
                },
                {
                  delay: 500,
                  duration: 300,
                },
              );
            },
          },
        );
      } else {
        Velocity(
          this.$refs.sea,
          {
            opacity: 0,
          },
          {
            duration: 300,
            complete: () => {
              this.$refs.sea.replaceChildren();
              Velocity(
                this.$refs.sea,
                {
                  opacity: 1,
                },
                {
                  delay: 500,
                  duration: 300,
                },
              );
            },
          },
        );
      }
    },
    addBoat(initial) {
      if (!this.featuresStore.useSpaceAnimation || !initial || !this.hasBoats) {
        return false;
      }

      let $sea = this.$refs.sea;
      if ($sea) {
        const direction = Math.random() > 0.5 ? 'left' : 'right';
        let $boat = document.createElement('i');
        let perspective = Math.random();
        let boatList = [...closeBoats];
        if (perspective > 0.8) {
          boatList = [...closeBoats, ...midBoats, ...farBoats];
        } else if (perspective > 0.5) {
          boatList = [...closeBoats, ...midBoats];
        }

        let boat = _.sample(boatList);

        let speedMod = _.includes(fastBoats, boat) ? 0.25 : 1;

        $boat.classList.add('boat', direction, boat);

        $boat.style.setProperty('--vertical', perspective * 100 + '%');
        $boat.style.setProperty('--size', 1 - perspective);
        $boat.style.setProperty('--z', Math.floor((1 - perspective) * 100));
        $boat.style.setProperty('--animation-speed', 60 + (40 + Math.random() * 20) * perspective * speedMod + 's');
        if (initial) {
          if (direction === 'left') {
            $boat.style.setProperty('right', Math.floor(Math.random() * 100) + '%');
          } else {
            $boat.style.setProperty('left', Math.floor(Math.random() * 100) + '%');
          }
        }

        $sea.appendChild($boat);

        animationEvent &&
          $boat.addEventListener(animationEvent, () => {
            $sea.removeChild($boat);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.beach {
  background: linear-gradient(to top, #fefae7 0%, #fefae7 rem(192px), #31c6e2 rem(192px), #2d70b6 rem(256px), transparent rem(256px)),
    linear-gradient(to top, #7bbff7 rem(20px), #2d75d5 rem(768px));
}

.glow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  background: radial-gradient(circle at 90% 100%, rgba(255, 255, 100, 0.6), rgba(255, 255, 0, 0.6) 20vmax, rgba(255, 100, 0, 0.4) 40vmax, transparent 75vmax);
  z-index: 60;
}

@keyframes fire {
  0%,
  30%,
  100% {
    opacity: 0.8;
  }
  20%,
  50% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.9;
  }
}

@keyframes boatLeft {
  0% {
    transform: translateX(0vw);
  }
  100% {
    transform: translateX(-150vw);
  }
}

@keyframes boatRight {
  0% {
    transform: translateX(0vw) scaleX(-1);
  }
  100% {
    transform: translateX(150vw) scaleX(-1);
  }
}

.sea,
.staticSea {
  position: absolute;
  left: 0;
  right: 0;
  bottom: rem(220px);
  height: rem(35px);
  z-index: 10;
}

.chair,
.parasol,
.table,
.cocktails {
  transition: all $avatar-animation-duration $avatar-ease;
  @include transition-optional;
}

.chair {
  z-index: 30;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url($base-url + '/assets/images/spaces/beach/chair.svg');
}

.parasol {
  z-index: 20;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url($base-url + '/assets/images/spaces/beach/parasol.svg');
  width: 80%;
  // top: rem(20px);
  left: 50%;
  transform: translateX(-52%);
  bottom: rem(100px);
  .sidebar-open & {
    margin-left: rem(-150px);
  }
  .hasNotes & {
    bottom: rem(160px);
  }
}
.cocktails {
  z-index: 20;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: url($base-url + '/assets/images/spaces/beach/cocktails.svg');
  // top: rem(20px);
  left: 50%;
  bottom: rem(95px);
  .sidebar-open & {
    margin-left: rem(-150px);
  }
  .hasNotes & {
    bottom: rem(155px);
  }
}

:deep(.boat) {
  --vertical: 50%;
  --animation-speed: 4s;
  --size: 100%;
  position: absolute;
  bottom: var(--vertical, 50%);
  z-index: var(--z, 10);
  height: calc(6em * var(--size));
  width: calc(15em * var(--size));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  background-image: var(--bg);
  // &::after {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   display: block;
  //   content: '';

  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   background-position: bottom;
  //   background-image: var(--bg);
  //   transform-origin: bottom center;
  //   transform: scaleY(-0.1);
  //   opacity: 0.5;
  // }

  .animated &.left {
    right: rem(-200px);
    animation: boatLeft var(--animation-speed, 4s) linear;
  }
  .animated &.right {
    left: rem(-200px);
    animation: boatRight var(--animation-speed, 4s) linear;
  }
  &.tanker {
    width: calc(150em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/tanker.svg')};
  }
  &.liner {
    width: calc(80em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/liner.svg')};
  }
  &.liner-2 {
    width: calc(80em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/liner-2.svg')};
  }
  &.cruiser {
    width: calc(35em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/cruiser.svg')};
  }
  &.sailboat-3 {
    width: calc(7em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/sailboat-3.svg')};
  }
  &.sailboat-2 {
    width: calc(20em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/sailboat-2.svg')};
  }
  &.sailboat-1 {
    width: calc(40em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/sailboat-1.svg')};
  }
  &.speedboat {
    width: calc(11em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/speedboat-1.svg')};
  }
  &.small-1 {
    width: calc(15em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/small-1.svg')};
  }
  &.small-2 {
    width: calc(17em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/small-2.svg')};
  }
  &.small-3 {
    width: calc(18em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/small-3.svg')};
  }
  &.small-4 {
    width: calc(17em * var(--size));
    --bg: #{url($base-url + '/assets/images/spaces/beach/small-4.svg')};
  }
}

.staticSea {
  .boat {
    height: 50vh;
  }
  .sailboat-3 {
    width: 10vw;
    left: 50%;
    bottom: 85%;
  }
  .liner-2 {
    transform: scaleX(-1);
    width: 10vw;
    left: 7%;
    bottom: 100%;
  }
  .small-2 {
    width: 10vw;
    left: 18%;
    bottom: 20%;
  }
  .small-4 {
    transform: scaleX(-1);
    width: 10vw;
    right: 15%;
    bottom: 0%;
  }
  .tanker {
    transform: scaleX(-1);
    width: 10vw;
    right: 7%;
    bottom: 100%;
  }
}

@mixin dark-beach-background {
  background: linear-gradient(to top, #848381 0%, #253a45 rem(192px), #334a90 rem(192px), #002349 rem(256px), transparent rem(256px)),
    linear-gradient(to top, #0055c4 rem(20px), #19001e rem(768px));
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: rem(256px);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 80%), radial-gradient(white, rgba(255, 255, 255, 0.2) 2px, transparent 6px),
      radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 3px), radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 0.25em),
      radial-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) 2px, transparent 0.5em),
      radial-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) 2px, transparent 0.5em);
    background-size: 100% 100%, 47em 29em, 53em 73em, 29em 11em, 23em 17em, 47em 23em;
    background-position: 0 0, 0 0, 3vw 5vh, 2vw 11vh, 7vw 13vh, 13vw 1vh;
    mix-blend-mode: screen;
  }
  :deep(.boat) {
    filter: brightness(35%) sepia(0.9) hue-rotate(180deg) contrast(140%);
  }

  .chair,
  .parasol,
  .table,
  .cocktails {
    filter: brightness(35%) saturate(40%) contrast(140%);
  }
  .glow {
    opacity: 0.8;
    //animation: fire 10s infinite;
    mix-blend-mode: screen;
  }
}

.dark-theme .beach {
  @include dark-beach-background;
}
@media (prefers-color-scheme: dark) {
  .dynamic-theme .beach {
    @include dark-beach-background;
  }
}
</style>
