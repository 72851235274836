<template>
  <div :class="frameClass">
    <div
      v-if="type !== 'mini'"
      class="timeContainer"
      :class="[statusClass, { setting: setting, jangle: timeStore.alarmRunning && type !== 'meeting' }]"
      @mousedown="mouseDown"
      @mouseup="mouseUp"
      @mousemove="mouseMove"
      @mouseout="mouseOut"
    >
      <p class="number" :class="[blinkClass, timerNumberClass, blankSecNudge]">
        <span ref="mins" class="mins">{{ bigText }}</span
        ><span v-if="hasSecs" class="secs">{{ smallText }}</span>
      </p>
      <p class="unit">
        <span class="min">{{ units }}</span
        ><span v-if="hasEach" class="each"> each</span>
      </p>
      <p class="total">
        {{ totalText }}
      </p>
      <svg class="timerCanvas" viewBox="0 0 232 232" preserveAspectRatio="xMidYMid meet" pointer-events="none">
        <circle class="track" r="106" cx="116" cy="116" transform="rotate(-90 116 116)" />
        <circle
          ref="bar"
          class="bar"
          r="106"
          cx="116"
          cy="116"
          transform="rotate(-90 116 116)"
          :style="{ strokeDasharray: c, strokeDashoffset: visibleDash }"
        />
        <g ref="marker" class="marker" :class="pulseClass">
          <circle class="visibleDot" r="8" cx="116" cy="10" />
          <circle class="invisibleDot" r="8" cx="116" cy="10" />
          <circle class="invisibleDot" r="8" cx="116" cy="222" />
        </g>
        <circle class="overdueRing" r="106" cy="116" cx="116" />
      </svg>
    </div>
    <div v-if="type === 'mini'" class="miniDisc" :class="statusClass">
      <svg viewBox="0 0 31 31">
        <circle
          id="miniTimerBar"
          ref="bar"
          class="bar"
          r="14.5"
          cx="15.5"
          cy="15.5"
          stroke-width="2"
          fill="none"
          transform="rotate(-90 15.5 15.5)"
          :style="{ strokeDasharray: c, strokeDashoffset: visibleDash }"
        />
      </svg>
      <span id="miniTimerNumber" ref="mins">{{ bigText }}</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
/* eslint-disable vue/no-mutating-props */

import _ from 'underscore';
import { nextTick } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { splitDuration, asUnit } from '@/resources/time-helpers';

export default {
  props: ['type', 'total', 'current', 'remaining', 'remoteSettingTime', 'passedSettingTime', 'showing', 'viewOnly'],
  setup(props) {
    const r = props.type === 'mini' ? 14.5 : 106;
    const c = Math.PI * r * 2;

    const sidebarStore = useSidebarStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    const timeStore = useTimeStore();
    return { sidebarStore, phaseStore, usersStore, timeStore, c };
  },
  data: function () {
    return {
      interacted: false,
      settingProp: 1,
      settingTime: 0,
      addingTime: false,
      snappedSettingTime: 0,
      snappedProp: 0,
      tracking: false,
      clicking: false,
      bigText: '00',
      settingNum: 0,
      visibleDash: 0,
      statusClass: false,
      alarmOn: false,
      timerUpdated: false,
      c: 0,
      tempSettingProp: 0,
      debouncedUpdateStatusClass: _.debounce(this.updateStatusClass, 300),
      debouncedNumberChange: _.debounce(function (newValue) {
        if (this.bigText !== newValue && this.$refs.mins) {
          Velocity(
            this.$refs.mins,
            {
              rotateX: ['90deg', '0deg'],
            },
            {
              duration: 300,
              complete: () => {
                this.bigText = isNaN(this.minsTextRaw) ? 0 : this.minsTextRaw;

                if (this.$refs.mins) {
                  Velocity(
                    this.$refs.mins,
                    {
                      rotateX: ['0deg', '90deg'],
                    },
                    {
                      duration: 300,
                    },
                  );
                }
              },
            },
          );
        }
      }, 500),
    };
  },
  mounted() {
    this.$ee.on('bus:state:on_next_time_changed', this.changed);
    this.$ee.on('bus:state:meeting_length_changed', this.changed);
    this.$ee.on('nM:addTimeLocal', this.addTimeLocal);

    if (this.type !== 'mini') {
      this.$refs.marker.style.transform = `rotateZ(${360 * this.prop}deg)`;
      this.visibleDash = this.propToDash(this.prop);
    }

    if (this.setting) {
      this.prop = 0;
      this.settingProp = 0;
    }

    nextTick(() => {
      this.settingTime = this.passedSettingTime;
      this.timerUpdated = false;
      if (this.type === 'meetingSetter') {
        this.settingProp = this.settingTime / 1000 / this.timeStore.times.meeting.max;
        nextTick(() => {
          this.snappedSettingTime = this.settingTime;
          this.bigText = isNaN(this.minsTextRaw) ? 0 : this.minsTextRaw;
          this.visibleDash = this.dash;
        });
      }

      if (this.type === 'phase') {
        this.settingProp = this.settingTime / 1000 / this.timeData.max;
        this.visibleDash = this.dash;
        nextTick(() => {
          this.snappedSettingTime = this.settingTime;
          this.bigText = isNaN(this.minsTextRaw) ? 0 : this.minsTextRaw;
        });
      }
    });
  },
  beforeUnmount() {
    this.$ee.off('bus:state:on_next_time_changed', this.changed);
    this.$ee.off('bus:state:meeting_length_changed', this.changed);
    this.$ee.off('nM:addTimeLocal', this.addTimeLocal);
    if (this.type === 'phase') {
      this.$ee.emit('nM:unTimeUp');
    }
    if (this.type === 'meetingSetter') {
      // this.$ee.off('bus:state:on_meeting_updated', this.meetingUpdated);
    }
  },
  computed: {
    ...mapPiniaState(usePhaseStore, ['breakEndedEarly', 'groupsReassemble', 'preparing', 'setupTimeEdited']),
    ...mapPiniaState(useUsersStore, ['iAmModerator']),
    ...mapPiniaState(useTimeStore, ['now', 'meetingTimeEdited']),
    ...mapPiniaState(useTimeStore, {
      timeTick: 'tick',
    }),
    timeTickMs() {
      return this.timeTick + 'ms';
    },
    settingUp() {
      return this.phaseStore.settingUp && !this.preparing;
    },
    viewChanger() {
      return this.sidebarStore.current.phase;
    },
    setting() {
      if (this.usersStore.iAmStillAway || this.usersStore.iAmStillAwayInGroup) {
        return false;
      } else if (this.settingUp && this.type === 'phase') {
        return true;
      } else if (this.type === 'meetingSetter') {
        return true;
      } else {
        return false;
      }
    },
    frameClass() {
      return this.type === 'mini' ? '' : 'sidebarTimer';
    },
    statusClassRaw() {
      if (
        this.breakEndedEarly ||
        this.groupsReassemble ||
        this.usersStore.iAmStillAway ||
        this.usersStore.iAmStillAwayInGroup ||
        (this.phaseStore.rawCurrentPhase !== 'breakTime' && this.usersStore.iAmAway)
      ) {
        return 'overdue';
      } else if (this.setting || this.preparing || this.total === 0 || this.sidebarStore.isAnimating) {
        return false;
      } else {
        if (this.remaining <= 0) {
          return 'overdue';
        } else if (this.prop < 0.25) {
          return 'end';
        } else if (this.prop < 0.5) {
          return 'mid';
        } else {
          return 'start';
        }
      }
    },
    settingMins() {
      return Math.round(asUnit(this.settingTime, 'mins'));
    },
    timeData() {
      if (this.type === 'meetingSetter') {
        return { ...this.timeStore.times.meeting };
      } else if (this.phaseStore.settingUp) {
        let timerDetails = { ...this.timeStore.times[this.phaseStore.setupPhase] };
        if (this.timeStore.settingTime) {
          timerDetails.defaultTime = this.timeStore.settingTime;
        }
        return timerDetails;
      } else {
        return { ...this.timeStore.times[this.phaseStore.currentPhase] };
      }
    },
    settingSecs() {
      const sec = splitDuration(this.snappedSettingTime, 'secs');
      return sec < 10 && sec !== 0 ? '0' + sec : sec;
    },
    minsTextRaw() {
      return this.type === 'mini' ? this.miniMins : this.normalMins;
    },
    normalMins() {
      // const time = this.setting ? this.snappedSettingTime : this.remaining;

      let time;
      if (this.preparing) {
        time = this.preparing * 1000;
      } else {
        time = this.setting ? this.snappedSettingTime : this.remaining;
      }

      let mins;
      let prefix = '';

      let { minutes, hours } = asUnit(time);

      if (this.type === 'meetingSetter') {
        if (minutes <= 90) {
          mins = Math.floor(minutes);
        } else {
          mins = Math.round(hours * 10) / 10;
          if (mins % 1 === 0) {
            mins = mins + '.0';
          }
        }
      } else if (minutes < 1 && this.setting && 1 === 2) {
        mins = Math.round(minutes);
      } else {
        mins = Math.floor(minutes);
      }

      mins = prefix + mins;

      return mins;
    },
    miniMins() {
      return this.remaining ? Math.ceil(asUnit(this.remaining, 'minutes')) : 0;
    },
    smallText() {
      let sec;
      if (this.preparing) {
        sec = splitDuration(this.preparing * 1000, 'secs');
      } else {
        sec = this.setting ? this.settingSecs : splitDuration(this.remaining, 'secs');
      }
      sec = sec || 0;

      if (this.setting && (sec === 0 || sec === '00')) {
        return '';
      } else {
        return ':' + (sec < 10 ? '0' + sec : sec);
      }
    },
    blankSecNudge() {
      return this.smallText === '' ? 'blankSecNudge' : false;
    },
    totalText() {
      return '';
    },
    hasSecs() {
      return this.type !== 'meetingSetter' ? true : false;
    },
    hasEach() {
      return this.setting && ['checkIn', 'checkOut'].includes(this.phaseStore.currentPhase);
    },
    timerProp() {
      return this.remaining / this.total;
    },
    minSettingTime() {
      return this.timeData.min * 1000;
    },
    maxSettingTime() {
      return this.timeData.max * 1000;
    },
    defaultSettingProp() {
      return this.settingTime / this.timeData.max;
    },
    prop() {
      if (this.preparing) {
        return 1;
      }
      if (this.setting) {
        return this.settingProp || this.defaultSettingProp;
      } else {
        return this.timerProp > 1 ? 1 : this.timerProp;
      }
    },
    dash() {
      return this.propToDash(this.prop);
    },
    units() {
      if (this.type === 'meetingSetter') {
        if (this.viewOnly) {
          let minsStr = Math.round(asUnit(this.remaining, 'minutes') * 10) / 10 === 1 ? 'min' : 'mins';
          return asUnit(this.remaining, 'minutes') <= 90 ? minsStr : 'hours';
        } else {
          return asUnit(this.snappedSettingTime, 'minutes') <= 90 ? 'mins' : 'hours';
        }
      } else {
        const time = this.setting ? this.settingTime : this.remaining;
        let each = ['checkIn', 'checkOut'].includes(this.sidebarStore.current.phase) && this.setting ? ' each' : '';
        let min = Math.round(asUnit(time, 'minutes') * 10) / 10 === 1 ? 'min' : 'mins';
        return min + each;
      }
    },
    blinkClass() {
      return !this.timeStore.hasInteractedWithTimer[this.type] && this.iAmModerator && this.type === 'meetingSetter' ? 'blink' : '';
    },
    pulseClass() {
      if (this.type === 'meetingSetter' && this.meetingTimeEdited) {
        return '';
      } else if (this.type === 'phase' && this.setupTimeEdited) {
        return '';
      } else {
        return !this.timerUpdated && !this.timeStore.hasInteractedWithTimer[this.type] ? 'pulse' : '';
      }
    },
    timerNumberClass() {
      return 'digit-' + (isNaN(this.minsTextRaw) ? 0 : this.minsTextRaw);
    },
  },
  watch: {
    'sidebarStore.current.phase': {
      immediate: true,
      handler(nv) {
        const inter = {};
        inter[this.type] = false;
        this.timeStore.interactedWithTimer(inter);

        nextTick(() => {
          this.timerUpdated = false;
        });
        if (this.type !== 'meetingSetter') {
          this.publishToStore();
        }
        if (
          nv === 'freeform' &&
          !this.usersStore.iAmStillAway &&
          !this.usersStore.iAmAway &&
          !this.usersStore.iAmStillAwayInGroup &&
          !this.usersStore.iAmAwayInGroup
        ) {
          this.updateStatusClass(false);
        } else {
          this.updateStatusClass(this.statusClassRaw);
        }
      },
    },
    minsTextRaw: {
      immediate: true,
      handler: function (nv, ov) {
        if (nv === ov) {
          return false;
        }
        if (this.setting) {
          this.bigText = isNaN(nv) ? 0 : nv;
        } else if (this.addingTime) {
          this.bigText = isNaN(nv) ? 0 : nv;
          this.addingTime = false;
        } else {
          this.debouncedNumberChange(nv);
        }
      },
    },
    prop: {
      immediate: true,
      handler(newVal, oldVal) {
        if (!newVal || newVal === oldVal) {
          return false;
        }

        if (this.showing && !(this.type === 'meetingSetter' && this.phaseStore.currentPhase === 'freeform')) {
          if (this.clicking || this.isAnimating) {
            this.visibleDash = this.propToDash(newVal);
            this.visibleRotate = this.rotate;
            if (this.$refs.bar) {
              this.$refs.bar.style.strokeDashoffset = this.propToDash(newVal);
            }
            if (this.$refs.marker) {
              this.$refs.marker.style.transform = `rotateZ(${360 * newVal}deg)`;
            }
          } else if (this.setting) {
            if (this.$refs.marker) {
              Velocity(
                this.$refs.marker,
                {
                  rotateZ: [360 * newVal + 'deg', 360 * oldVal + 'deg'],
                },
                {
                  duration: 300,
                  easing: 'easeInOutQuad',
                  complete: () => {
                    this.visibleRotate = this.rotate;
                  },
                },
              );
            }

            if (this.$refs.bar) {
              Velocity(
                this.$refs.bar,
                {
                  strokeDashoffset: this.propToDash(newVal),
                },
                {
                  duration: 300,
                  easing: 'easeInOutQuad',
                  complete: () => {
                    this.visibleDash = this.propToDash(newVal);
                  },
                },
              );
            }

            let minVal = asUnit(this.settingTime, 'minutes');

            var args = {
              total: minVal && minVal < 1 ? 30 : Math.round(minVal) * 60,
              screen: this.setupBar,
              max: this.timeData.max / 1000,
            };

            this.snappedSettingTime = this.settingTime;
            this.timeStore.updateSettingTime(args);
          } else {
            if (this.$refs.bar) {
              this.visibleDash = this.propToDash(newVal);
            }
          }
        }
      },
    },
    statusClassRaw: {
      immediate: true,
      handler: function (nv) {
        this.debouncedUpdateStatusClass(nv);
      },
    },
    remaining(nv) {
      if (this.type === 'meetingSetter') {
        this.settingProp = nv / 1000 / this.timeStore.times.meeting.max;
        this.settingTime = nv;
        this.snappedSettingTime = nv;
      }
    },
    remoteSettingTime(nv) {
      if (this.type === 'meetingSetter') {
        this.settingTime = nv;
        this.snappedSettingTime = nv;
        this.settingProp = nv / 1000 / this.timeData.max;
      }
      if (this.type === 'phase') {
        this.settingTime = nv;
        this.snappedSettingTime = nv;
        this.settingProp = nv / 1000 / this.timeData.max;
      }
    },
    timeData: {
      deep: true,
      immediate: true,
      handler() {
        if (this.type === 'phase') {
          this.settingTime = this.timeData.defaultTime * 1000;
          this.snappedSettingTime = this.timeData.defaultTime * 1000;
        }
      },
    },
    showing(nv) {
      if (nv) {
        if (this.type === 'phase') {
          this.settingProp = this.settingTime / 1000 / this.timeData.max;
          nextTick(() => {
            this.snappedSettingTime = this.settingTime;
            this.bigText = this.minsTextRaw;
            this.visibleDash = this.dash;

            this.visibleRotate = this.rotate;
            if (this.$refs.bar) {
              this.$refs.bar.style.strokeDashoffset = this.propToDash(this.prop);
            }
            if (this.$refs.marker) {
              this.$refs.marker.style.transform = `rotateZ(${360 * this.prop}deg)`;
            }
          });
        }
      }
    },
  },
  methods: {
    propToDash(prop) {
      return this.c * (1 - prop);
    },
    mouseDown(e) {
      if (this.setting) {
        this.clicking = true;
        this.trackPos(e);
      }
    },
    mouseUp(e) {
      if (this.setting) {
        this.clicking = false;
        this.trackPos(e, true);
      }
    },
    mouseMove(e) {
      if (this.setting) {
        if (this.clicking) {
          this.trackPos(e);
        }
      }
    },
    mouseOut(e) {
      if (this.setting) {
        if (this.clicking) {
          this.trackPos(e, true);
        }
        this.$nextTick(() => {
          this.clicking = false;
        });
      }
    },
    trackPos(e, snap) {
      const deltaX = e.offsetX - e.target.clientWidth / 2;
      const deltaY = e.offsetY - e.target.clientHeight / 2;
      const rad = Math.atan2(deltaY, deltaX); // In radians
      let prop = rad / (Math.PI * 2);
      prop = prop < 0 ? 1 + prop : prop;
      prop = (prop + 0.25) % 1;

      // Handle the 0 threshold
      if (prop > 0.95 && this.settingProp < 0.05) {
        prop = this.tempSettingProp;
      } else if (this.settingProp > 0.95 && prop < 0.05) {
        // seconds = setupTime.max;
        prop = this.tempSettingProp;
      }

      if (snap) {
        prop = this.steppedProp(prop);
      }

      this.settingProp = prop;
      this.snappedProp = this.steppedProp(prop);
      this.tempSettingProp = this.steppedProp(prop);
      this.snappedSettingTime = Math.floor(this.snappedProp * this.maxSettingTime);

      if (snap) {
        this.settingTime = Math.floor(prop * this.maxSettingTime);
        this.updateTime();
      }
    },
    steppedProp(prop) {
      let seconds = Math.floor((this.maxSettingTime / 1000) * prop);
      let step = 30;
      let minimum = 30;

      if (this.type === 'meetingSetter') {
        if (seconds >= 5400) {
          step = 1800;
        } else {
          step = 900;
        }
        minimum = 900;
      } else {
        if (seconds < 50) {
          step = 30;
        } else if (seconds >= 50 && seconds <= 300) {
          step = 60;
        } else {
          step = 300;
        }
      }

      seconds = seconds > minimum ? seconds : minimum;

      let s = Math.round(seconds / step) * step;

      return (s / this.maxSettingTime) * 1000;
    },
    updateTime() {
      if (this.type === 'meetingSetter') {
        const end = Math.round(asUnit(this.settingTime, 'minutes')) * 60000;
        const newEnd = new Date(this.timeStore.meetingStart.getTime() + end);
        this.$meetingmanager.updateMeeting({
          ends_on: newEnd.getTime() / 1000,
          trigger: 'timerDisc',
        });
      } else {
        this.publishToStore();
        this.$meetingmanager.changeNextTime(Math.round(this.settingTime / 1000));
      }
      const inter = {};
      inter[this.type] = true;
      this.timeStore.interactedWithTimer(inter);
    },
    updateStatusClass(nv) {
      if (nv === 'overdue') {
        if (!this.alarmOn && !this.isAnimating && !this.phaseStore.isAnimating) {
          this.alarmOn = true;
          //this.alarmBroadcastRaw();
        }
      } else if (this.alarmOn) {
        this.alarmOn = false;
        // this.alarmBroadcast();
      }
      this.statusClass = nv;
      this.timeStore.updatePhaseTimerStatus(nv);
    },
    publishToStore() {
      if (this.type === 'phase') {
        var args = {
          total: Math.round(asUnit(this.settingTime, 'minutes')) * 60,
          screen: this.setupBar,
          max: this.timeData.max,
        };
        this.timeStore.updateSettingTime(args);
      }
    },
    changed() {
      this.timerUpdated = true;
    },
    addTimeLocal() {
      this.addingTime = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes timerBlink {
  0%,
  25%,
  75%,
  100% {
    opacity: 1;
  }
  45%,
  55% {
    opacity: 0;
  }
}

@keyframes timerNumberIn {
  from {
    opacity: 0;
  }
}

@keyframes blob-pulse {
  0% {
    r: 5;
  }
  100% {
    r: 10;
  }
}

.setting {
  .bar,
  .marker {
    animation: fadeIn 300ms 1000ms both;
  }
}

.sidebarTimer {
  --timer-bar: var(--c__time-base);
  --timer-unit: var(--c__text);
  --timer-minutes: var(--c__text);
  --timer-seconds: var(--c__text);

  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transition: all 0.75s cubic-bezier(0, 1, 0.5, 1) 0.5s;

  &.skillbar-bits-move {
    transition: all 0.75s cubic-bezier(0, 1, 0.5, 1) 0.5s;
  }

  .marker {
    transform-origin: center;
    transform: rotateZ(0deg);
  }

  .visibleDot {
    fill: var(--timer-bar);
    transform-origin: center;
    r: 1;
    opacity: 0;
    transition: r 400ms ease, opacity 400ms ease;
  }

  .invisibleDot {
    opacity: 0;
  }

  .setting {
    .visibleDot {
      r: 8;
      opacity: 1;
    }

    .pulse .visibleDot {
      animation: blob-pulse 0.8s ease infinite alternate;
    }
  }

  .blink {
    animation: timerBlink 2s infinite 5s;
  }

  .timeContainer {
    display: block;
    width: rem(232px);
    height: rem(232px);
    border-radius: 50%;
    position: relative;
  }

  .number,
  .unit,
  .total {
    display: block;
    width: 100%;
    text-align: center;
    line-height: 1;
    position: absolute;
    left: 0;
  }

  .number {
    top: rem(64px);
    pointer-events: none;
  }

  .mins {
    @include type-size('massive');
    font-weight: 100;
    display: inline-block;
    color: var(--timer-minutes);
  }

  .digit-1 .mins,
  .digit-4 .mins {
    margin-left: -0.05em;
  }
  .digit-2 .mins {
    margin-left: 0.03em;
  }
  .digit-3 .mins {
    margin-left: 0.04em;
  }
  .digit-5 .mins {
    margin-left: 0.01em;
  }
  .digit-10 .mins,
  .digit-15 .mins {
    margin-left: 0.05em;
  }

  .blankSecNudge {
    margin-left: 0.4em;
  }

  .secs {
    color: var(--timer-seconds);
    display: inline-block;
    width: 1rem;
  }

  .unit {
    top: rem(158px);
    margin-top: 0;
    pointer-events: none;
    color: var(--timer-unit);
  }

  .total {
    top: rem(180px);
    margin-top: 0;
    pointer-events: none;
    color: var(--timer-unit);
  }

  .timerCanvas {
    position: absolute;
    width: rem(232px);
    height: rem(232px);
    top: 0;
    display: block;
  }

  .track,
  .bar {
    fill: none;
  }

  .track {
    stroke: var(--c__timer-track);
    stroke-width: 2;
    transition: stroke 250ms 0.2s, opacity 150ms, r 250ms;
  }

  .bar {
    stroke-width: 6;
    stroke: var(--timer-bar);
    transition: all v-bind(timeTickMs) linear;
  }

  .setting .bar {
    transition: stroke 250ms 0.2s, opacity 150ms, r 250ms;
  }

  .overdueRing {
    stroke-width: 0;
    stroke: var(--c__warn);
    opacity: 0;
    fill: none;
    transition: stroke 0.2s ease 0.2s, opacity 0.2s ease 0.2s, r 0.4s ease-out, stroke-width 0.4s ease-out;
  }

  .jangle,
  .overdue {
    animation: overdue-jitter 2s linear 0s infinite;
  }

  .overdue {
    .bar,
    .track {
      opacity: 0;
    }

    .overdueRing {
      r: (106 - 9);
      stroke-width: 20;
      opacity: 1;
    }
  }

  .mid {
    --timer-bar: var(--c__time-mid);
  }

  .end {
    --timer-bar: var(--c__time-over);
  }
}
</style>
