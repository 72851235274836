<template>
  <section class="extraSettings">
    <h2>Extra Settings</h2>
    <ul class="settings">
      <li>
        <label class="textLabel" for="spaceAnimationToggle">Space Animations</label>
        <appInfoBubble><strong>Toggle Space animation effects </strong><br />Disabling space animations may help on slower machines </appInfoBubble>
        <AppToggle :modelValue="spaceAnimation" name="spaceAnimationToggle" @change="publishUpdate('spaceAnimation', $event.target.checked)"> </AppToggle>
        <transition name="basic">
          <app-loading-spinner v-if="loadingSpaceAnimation" :success="loadingSpaceAnimation === 'success'" :fail="loadingSpaceAnimation === 'fail'" />
        </transition>
      </li>
      <li>
        <label class="textLabel" for="simplifiedGraphicsToggle">Simplified Graphics</label>
        <appInfoBubble
          ><strong>Toggle simplifed graphics</strong><br />(not implemented) Removes more processor intensive things like drop shadows and translucency
        </appInfoBubble>
        <AppToggle :modelValue="simplifiedGraphics" name="simplifiedGraphicsToggle" @change="publishUpdate('simplifiedGraphics', $event.target.checked)">
        </AppToggle>
        <transition name="basic">
          <app-loading-spinner
            v-if="loadingSimplifiedGraphics"
            :success="loadingSimplifiedGraphics === 'success'"
            :fail="loadingSimplifiedGraphics === 'fail'"
          />
        </transition>
      </li>
      <li>
        <label class="textLabel" for="ReducedAnimationToggle">Reduced Animation</label>
        <appInfoBubble><strong>Toggle reduced animations</strong><br />(not implemented) Cuts down some animation effects </appInfoBubble>
        <AppToggle :modelValue="reducedAnimation" name="ReducedAnimationToggle" @change="publishUpdate('reducedAnimation', $event.target.checked)"> </AppToggle>
        <transition name="basic">
          <app-loading-spinner v-if="loadingReducedAnimation" :success="loadingReducedAnimation === 'success'" :fail="loadingReducedAnimation === 'fail'" />
        </transition>
      </li>
      <li>
        CPU Level Style:
        <div class="radioPills">
          <label> <input class="visuallyHidden" type="radio" value="auto" v-model="cpuLevel" /><span class="control"> Auto </span></label>
          <label> <input class="visuallyHidden" type="radio" value="bad" v-model="cpuLevel" /><span class="control"> Bad </span></label>
          <label> <input class="visuallyHidden" type="radio" value="low" v-model="cpuLevel" /><span class="control"> Low </span></label>
          <label> <input class="visuallyHidden" type="radio" value="normal" v-model="cpuLevel" /><span class="control"> Normal </span></label>
          <label> <input class="visuallyHidden" type="radio" value="great" v-model="cpuLevel" /><span class="control"> Great </span></label>

          <transition name="basic">
            <app-loading-spinner v-if="loadingCpuLevel" :success="loadingCpuLevel === 'success'" :fail="loadingCpuLevel === 'fail'" />
          </transition>
          <p class="using">(Detected: {{ featuresStore.detectedCpuLevel }}, Using: {{ featuresStore.cpuLevel }})</p>
        </div>
      </li>
      <li>
        Quality:
        <div class="radioPills">
          <label> <input class="visuallyHidden" type="radio" value="low" v-model="quality" /><span class="control"> Low </span></label>
          <label> <input class="visuallyHidden" type="radio" value="medium" v-model="quality" /><span class="control"> Medium </span></label>
          <label> <input class="visuallyHidden" type="radio" value="high" v-model="quality" /><span class="control"> High </span></label>

          <transition name="basic">
            <app-loading-spinner v-if="loadingQuality" :success="loadingQuality === 'success'" :fail="loadingQuality === 'fail'" />
          </transition>
        </div>
      </li>
      <li>
        Frame Rate:
        <div class="radioPills">
          <label> <input class="visuallyHidden" type="radio" value="low" v-model="framerate" /><span class="control"> Low </span></label>
          <label> <input class="visuallyHidden" type="radio" value="medium" v-model="framerate" /><span class="control"> Medium </span></label>
          <label> <input class="visuallyHidden" type="radio" value="high" v-model="framerate" /><span class="control"> High </span></label>

          <transition name="basic">
            <app-loading-spinner v-if="loadingFramerate" :success="loadingFramerate === 'success'" :fail="loadingFramerate === 'fail'" />
          </transition>
        </div>
      </li>
      <li>
        Theme:
        <div class="radioPills">
          <label> <input class="visuallyHidden" type="radio" value="light" v-model="theme" /><span class="control"> Light </span></label>
          <label> <input class="visuallyHidden" type="radio" value="dark" v-model="theme" /><span class="control"> Dark </span></label>
          <label> <input class="visuallyHidden" type="radio" value="dynamic" v-model="theme" /><span class="control"> Dynamic </span></label>

          <transition name="basic">
            <app-loading-spinner v-if="loadingTheme" :success="loadingTheme === 'success'" :fail="loadingTheme === 'fail'" />
          </transition>
        </div>
      </li>
      <li>
        <button
          class="buttonToggle"
          @click="featuresStore.togglePerfOption('noMask')"
          v-html="featuresStore.perf.noMask ? 'Turn on video mask' : 'Turn off video mask'"
        ></button>
        <button
          class="buttonToggle"
          @click="featuresStore.togglePerfOption('uglyMode')"
          v-html="featuresStore.perf.uglyMode ? 'Turn off ugly video mode' : 'Turn on ugly video mode'"
        ></button>
        <button
          class="buttonToggle"
          @click="featuresStore.togglePerfOption('pauseVideoWhenMoving')"
          v-html="featuresStore.perf.pauseVideoWhenMoving ? 'Don\'t pause video when moving' : 'Pause video when moving'"
        ></button>
        <button
          class="buttonToggle"
          @click="featuresStore.togglePerfOption('hideVideoWhenMoving')"
          v-html="featuresStore.perf.hideVideoWhenMoving ? 'Don\'t hide video when moving' : 'Hide video when moving'"
        ></button>
      </li>
    </ul>
  </section>
</template>

<script>
import AppToggle from '@/components/AppToggle.vue';
import AppInfoBubble from '@/components/AppInfoBubble.vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue';
import _ from 'underscore';
import { nM } from '@/legacy';
import { useFeaturesStore } from '@/store/pinia/features';
import { useMediaStore } from '@/store/pinia/media';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const mediaStore = useMediaStore();
    return { featuresStore, mediaStore };
  },
  components: {
    AppToggle,
    AppInfoBubble,
    AppLoadingSpinner,
  },

  data() {
    return {
      spaceAnimation: false,
      reducedAnimation: false,
      simplifiedGraphics: false,
      quality: false,
      framerate: false,
      cpuLevel: false,
      theme: false,
      error: false,
      loaders: {
        spaceAnimation: false,
        reducedAnimation: false,
        simplifiedGraphics: false,
        quality: false,
        framerate: false,
        theme: false,
        cpuLevel: false,
      },
    };
  },
  mounted() {
    this.spaceAnimation = this.featuresStore.useSpaceAnimation;
    this.quality = this.featuresStore.settings.quality;
    this.cpuLevel = this.featuresStore.settings.cpuLevel;
    this.framerate = this.featuresStore.settings.framerate || nM.frameRateMap[this.featuresStore.settings.frame_rate];
    this.theme = this.featuresStore.settings.theme;
    this.reducedAnimation = this.featuresStore.settings.reducedAnimation || this.featuresStore.settings.reduced_animation;
    this.simplifiedGraphics = this.featuresStore.settings.simplifiedGraphics || this.featuresStore.settings.simplified_graphics;
  },
  computed: {
    loadingSpaceAnimation() {
      return this.loaders.spaceAnimation;
    },
    loadingQuality() {
      return this.loaders.quality;
    },
    loadingCpuLevel() {
      return this.loaders.cpuLevel;
    },
    loadingFramerate() {
      return this.loaders.framerate;
    },
    loadingTheme() {
      return this.loaders.theme;
    },
    loadingSimplifiedGraphics() {
      return this.loaders.simplifiedGraphics;
    },
    loadingReducedAnimation() {
      return this.loaders.reducedAnimation;
    },
  },
  watch: {
    'featuresStore.settings.quality': {
      handler(nv) {
        this.quality = nv;
      },
    },
    'featuresStore.settings.framerate': {
      handler(nv) {
        this.framerate = nv;
      },
    },
    'featuresStore.settings.cpuLevel': {
      handler(nv) {
        this.cpuLevel = nv;
      },
    },
    cpuLevel(nv) {
      if (this.featuresStore.settings.cpuLevel !== nv) {
        this.publishUpdate('cpuLevel', nv, true);
      }
    },
    quality(nv) {
      if (this.featuresStore.settings.quality !== nv) {
        this.publishUpdate('resolution', nM.resolutionMapReverse[nv], true);
      }
    },
    framerate(nv) {
      if (this.featuresStore.settings.framerate !== nv) {
        this.publishUpdate('frame_rate', nM.frameRateMapReverse[nv], true);
      }
    },
    theme(nv) {
      if (this.featuresStore.settings.theme !== nv) {
        this.publishUpdate('theme', nv, true);
      }
    },
  },
  methods: {
    publishUpdate(prop, val, watcher) {
      if (watcher && val === this.featuresStore.settings[prop]) {
        return false;
      }
      this.error = false;
      let data = {};
      let oldVal = this.featuresStore.settings[prop];
      let externalVal = val;

      if (prop === 'spaceAnimation' || prop === 'simplifiedGraphics' || prop === 'reducedAnimation') {
        externalVal = val ? 'true' : 'false';
      }

      if (prop === 'simplifiedGraphics') {
        data['simplified_graphics'] = externalVal;
      } else if (prop === 'reducedAnimation') {
        data['reduced_animation'] = externalVal;
      } else {
        data[prop] = externalVal;
      }

      this[prop] = val;
      this.loaders[prop] = 'loading';

      this.$API
        .updateSettings(data)
        .then((r) => {
          this.featuresStore.updateUserSettings(r);
          this.loaders[prop] = 'success';
          if (this.mediaStore.videoOn && this.quality) {
            if (['resolution', 'frame_rate'].includes(prop)) {
              const [width, height] = nM.resolutionMapReverse[this.quality].split('x');
              this.$videomanager.onVideoResolutionUpdated(width, height, nM.frameRateMapReverse[this.framerate]);
            }
          }
        })
        .catch((err) => {
          console.log('error in updating settings!!', err, prop, oldVal);
          this.loaders[prop] = 'fail';
          this.error = true;
          _.delay(() => {
            this[prop] = oldVal;
          }, 500);
        })
        .finally(() => {
          _.delay(() => {
            this.loaders[prop] = false;
          }, 1000);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  @include type-size(medium);
  text-align: center;
  margin: 2em 0 1em 0;
}

aside {
  margin: rem(70px) auto 0 auto;
  text-align: center;
  max-width: rem(180px);
  font-weight: 200;
  min-height: 2em;
  position: relative;
  p {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
  }
}

.error {
  color: var(--c__warn);
}

.settings {
  li {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 100;
  }
  li + li {
    margin-top: 1em;
  }

  .loadingSpinner {
    color: var(--c__accent);
    position: absolute;
    top: 50%;
    right: -1.5em;
    margin-top: -0.5em;
    height: 1em;
    width: 1em;
  }

  .textLabel {
    text-align: left;
    display: block;
    width: rem(136px);
    flex: 0 0 rem(136px);
    padding: 0;
  }

  .info {
    margin-right: auto;
  }
}

.radioPills {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
  margin-top: 0.5em;
  gap: 1em;
  position: relative;
  input {
    visibility: hidden;
  }
  label {
    display: inline-block;
    flex: 1 1 auto;
  }
  .control {
    display: block;
    border-radius: 2px;
    border: var(--c__accent) 1px solid;
    text-align: center;
    padding: 0 0.5em;
  }

  input:checked + .control {
    background: var(--c__accent);
    color: var(--c__bg);
    font-weight: 400;
  }
}

.buttonToggle {
  margin-top: 1em;
  padding: 0.25em;
  display: block;
  border-radius: 2px;
  border: var(--c__accent) 1px solid;
  width: 100%;
  @include triggered {
    background: var(--c__accent);
    color: var(--c__bg);
    font-weight: 400;
  }
}
.using {
  width: 100%;
}
</style>
