import { defineStore } from 'pinia';

export const useAccountStore = defineStore('account', {
  state() {
    return {
      id: 0,
      name: '',
      description: '',
      slug: '',
      config: {},
      preferredMeetingType: '',
      defaultMeetingSettings: {
        lobby: false,
        guide: false,
        notes: false,
        space: 'nowhere',
      },
      permissions: {
        conference: false,
        designer: false,
        planner: false,
        runner: false,
      },
      meetingOwnerId: -1,
      user: {
        email: false,
        first_name: false,
        id: false,
        last_name: false,
        name: false,
        nowhere_enabled: false,
        nowhere_id: false,
        picture_url: false,
        username: false,
        meta: {},
      },
    };
  },
  getters: {
    __env: (state) => state.config.environment,
    canCreate: (state) => state.permissions.conference,
    currentUser: (state) => (state.user && state.user.id ? state.user : null),
    isLoggedIn: (state) => state.user && state.user.id,
    isMeetingOwner: (state) => (state.user && state.user.id ? state.user.id === state.meetingOwnerId : false),
    isGuest: (state) => state.user?.meta && state.user.meta.guest,
    baseUrl: (state) => state.config.baseUrl,
    accountUrl: (state) => state.config.nowhereAuth.accountUrl,
    authUrl: (state) => state.config.nowhereAuth.authUrl,
  },
  actions: {
    init(data) {
      if (data.settings) {
        this.updateDefaultMeetingSettings(data.settings);
      }
      if (data.user?.settings?.preferredMeetingType) {
        this.preferredMeetingType = data.user.settings.preferredMeetingType;
      }
      this.updatePermissions(data);
      this.updateAccount(data);
      this.updateUser(data);
      this.updateConfig(window.__INITIAL_STATE__);
    },
    initMeeting() {},
    setOwnerId(id) {
      this.meetingOwnerId = id;
    },
    updateDefaultMeetingSettings({ meetingDefaultSettings }) {
      this.defaultMeetingSettings = { ...this.defaultMeetingSettings, ...meetingDefaultSettings };
    },
    updatePermissions({ permissions }) {
      this.permissions = { ...this.permissions, ...permissions };
    },
    updateUser({ user }) {
      this.user = user ? { ...user } : null;
    },
    updateConfig({ config }) {
      this.config = { ...config };
    },
    updatePassword(password) {
      this.user = {
        ...this.user,
        pin_pass: password,
      };
    },
    updatePreferredMeetingType(preferredMeetingType) {
      this.preferredMeetingType = preferredMeetingType;
    },
    updateAccount({ account }) {
      this.id = account.id;
      this.name = account.name;
      this.description = account.description;
      this.slug = account.slug;
    },
  },
});
