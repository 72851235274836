<template>
  <section class="allow page poster" data-page-name="allow">
    <header class="extra">
      <div class="message">
        <svg class="media" viewBox="0 0 94 38">
          <g>
            <rect x="13.34" y="30.51" width="2.79" height="5.55" />
            <polygon points="23.12 36.05 23.12 37.44 6.36 37.44 6.36 36.05 23.12 36.05" />
            <path d="M2.17,19.41a12.57,12.57,0,0,0,25.14,0" fill="none" stroke="#000" stroke-width="3" />
            <path
              d="M14.74.75h0a7.63,7.63,0,0,1,7.63,7.63v11A7.63,7.63,0,0,1,14.74,27h0a7.63,7.63,0,0,1-7.63-7.63v-11A7.63,7.63,0,0,1,14.74.75Z"
              fill="none"
              stroke="#000"
              stroke-width="1.5"
            />
            <rect x="11.94" y="1.39" width="1.4" height="9.7" />
            <rect x="16.14" y="1.39" width="1.4" height="9.7" />
          </g>
          <g>
            <rect x="54.25" y="9.25" width="24" height="21" fill="none" stroke="#000" stroke-width="1.5" />
            <path d="M83.1,19.75l10.15,9.52v-19Z" fill="none" stroke="#000" stroke-width="1.5" />
          </g>
        </svg>
        <p>To get started with bixe please allow access to your camera and microphone. You will only need to do this once.</p>
      </div>
    </header>
  </section>
</template>

<script>
import { nM } from '@/legacy';
import utils from '../resources/utils';
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import errorReportingService from '../services/errorReportingService';

export default {
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
    this.getDevicePermissions({ video: true, audio: true }, true);
  },
  methods: {
    async getDevicePermissions(permissionsObj, needAllKindOfDevicePermissions) {
      try {
        const AVStreams = await navigator.mediaDevices.getUserMedia(permissionsObj);
        AVStreams.getTracks().forEach((track) => track.stop());
        utils.storage.ls.setItem('mediaAllowed', true);
        const redirectUrl = this.$route.query.redirectUrl;
        const url = redirectUrl ? nM.normalizeUrl(redirectUrl) : '/welcome';
        this.$router.push(url).catch(() => null);
      } catch (err) {
        errorReportingService.reportError(err);
        if (err instanceof DOMException) {
          if (needAllKindOfDevicePermissions) {
            this.getDevicePermissions({ audio: true }, false);
          }
        } else {
          this.$router.push('/please-use-media').catch(() => null);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.allow {
  & > header {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 25%;
  }

  .message {
    width: rem(325px);
    position: relative;
    margin: auto;
    text-align: center;
  }

  p {
    @include type-size('medium');
    margin: rem(22px) 0 0 0;
  }

  .media {
    width: rem(101px);
    height: rem(60px);
  }

  .arrow {
    width: rem(130px);
    height: rem(123px);
    position: absolute;
    left: rem(-130px - 21px);
    bottom: rem(55px);
  }
}
</style>
