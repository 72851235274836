<template>
  <please-use :class="pageName" :data-page-name="pageName">
    <img class="desktopPlease" :src="accountStore.baseUrl + '/assets/images/desktopplease.png'" />
    <h1>Not available on this device</h1>
    <p>Please access bixe from a computer</p>
  </please-use>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import PleaseUse from '@/components/PleaseUse';
export default {
  components: {
    PleaseUse,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
};
</script>
<style lang="scss" scoped>
img {
  height: rem(65px);
  width: rem(100px);
  margin-bottom: rem(42px);
}
</style>
