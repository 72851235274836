/* eslint-disable */
import Vue from 'vue';
import utils from '../resources/utils';

export default () => (to, from, next) => {
  if (utils.isOpenedWithinElectronShell()) {
    // the ipcApi (or inter process communications API) is only available in electron shell
    // do not show this platform guard when user is already inside electron app.
    return next();
  }

  const { chosenPlatform, pwd: pinPass } = to.query; /** `chosenPlatform`: from the "Open in browser link" even when the preferred platform was App */
  const preferredPlatform = utils.storage.ls.getItem('platform.preferred-v2'); /** `preferredPlatform`: always open the app option */
  const meetingId = to.params.id || to.query.meetingId || '';
  const personalRoomPin = to.params.pinNumber || '';
  const identifier = meetingId || personalRoomPin;
  const tempPref = utils.storage.ss.getItem('platform.tempPreference');

  if ([chosenPlatform, tempPref].includes('browser')) {
    utils.storage.ss.setItem('platform.tempPreference', 'browser');
    return next();
  }

  const showInvitation = utils.storage.ss.getItem(`platform.invitationViewed.${identifier}`);
  if (showInvitation !== '1' || preferredPlatform === 'app') {
    return next(`/use-app?id=${encodeURIComponent(identifier)}&pin=${encodeURIComponent(pinPass)}&flow=${personalRoomPin ? 'pr-invite' : 'meeting-invite'}`);
  }

  return next();
};
