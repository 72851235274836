export const deviceKind = {
  AUDIO_INPUT: 'audioInput',
  AUDIO_OUTPUT: 'audioOutput',
  VIDEO_INPUT: 'videoInput',
  NONE: 'none',
};

export const eventLevel = {
  EMOTE: 5,
  DEFAULT: 10,
  PROPOSAL: 20,
  VOTE: 30,
  JOIN_MEETING: 40,
  ADD_TIME: 50,
  EARLY_FINISH: 60,
  LEAVE_MEETING: 70,
  JOIN_GROUP: 80,
  EJECT: 90,
  TOPIC: 100,
};

export const screenSharingStatus = {
  SHARING_STARTED: 1,
  SHARING_STOPPED: -1,
};

export const localStorageKeysMap = {
  RELAUNCH_MEETING_ID: 'RELAUNCH_MEETING_ID',
  UPDATE_BIXE_NATIVE_REMINDED_ON: 'UPDATE_BIXE_NATIVE_REMINDED_ON',
  CURRENT_BIXE_NATIVE_VERSION: 'CURRENT_BIXE_NATIVE_VERSION',
};

export const frameRateMap = {
  7: 'low',
  15: 'medium',
  30: 'high',
};

export const frameRateMapReverse = {
  low: '7',
  medium: '15',
  high: '30',
};

export const meetingStatus = {
  PENDING: 'pending',
  STARTED: 'started',
  FINISHED: 'finished',
};

export const setups = {
  'contextSetup:time': 'context',
  'checkinSetup:time': 'checkIn',
  'checkInSetup:time': 'checkIn',
  'inputSetup:time': 'input',
  'decisionSetup:time': 'decision',
  'breakSetup:time': 'breakTime',
  'groupsSetup:time': 'groups',
  'dialogueSetup:time': 'dialogue',
  'feedbackSetup:time': 'feedback',
  'reviewSetup:time': 'review',
  'checkoutSetup:time': 'checkOut',
  'checkOutSetup:time': 'checkOut',
};

export const phaseMap = {
  init: 'init',
  waiting: 'lobby',
  freeform: 'freeform',
  context: 'context',
  'contextSetup:time': 'context',
  checkin: 'checkIn',
  checkinSetup: 'checkIn',
  'checkinSetup:time': 'checkIn',
  'checkInSetup:time': 'checkIn',
  input: 'input',
  'inputSetup:time': 'input',
  decision: 'decision',
  'decisionSetup:time': 'decision',
  'breakSetup:time': 'breakTime',
  Break: 'breakTime',
  break: 'breakTime',
  'groupsSetup:time': 'groups',
  groups: 'groups',
  'dialogueSetup:time': 'dialogue',
  dialogue: 'dialogue',
  'feedbackSetup:time': 'feedback',
  feedback: 'feedback',
  review: 'review',
  'reviewSetup:time': 'review',
  checkout: 'checkOut',
  checkOut: 'checkOut',
  'checkoutSetup:time': 'checkOut',
  'checkOutSetup:time': 'checkOut',
  finish: 'endMeeting',
  ended: 'ended',
};

export const phaseMapReverse = {
  breakTime: 'break',
  checkIn: 'checkin',
  checkOut: 'checkout',
  context: 'context',
  decision: 'decision',
  feedback: 'feedback',
  freeform: 'freeform',
  endMeeting: 'finish',
  groups: 'groups',
  lobby: 'waiting',
  input: 'input',
  review: 'review',
  ended: 'ended',
};

export const phaseRefreshers = [
  'on_this_phase_starts',
  'on_meeting_starts',
  'on_view_changed',
  'on_proposal_finished',
  'on_time_added',
  'on_next_questions_changed',
  'on_questions_changed',
  'on_proposal_updated',
  'on_users_voted',
  'on_voting_ended',
  'on_topic_updated',
  'on_topic_finished',
];

export const resolutionMap = {
  '320x240': 'low',
  '640x480': 'medium',
  '1280x720': 'high',
};

export const resolutionMapReverse = {
  low: '320x240',
  medium: '640x480',
  high: '1280x720',
};

export const videoKind = {
  MAIN: 'main',
  PRESENTATION: 'presentation',
  CONNECTIVITY: 'connectivity',
};

export const videoSource = {
  AUDIO: 'audio',
  CAMERA: 'camera',
  SCREEN: 'screen',
  NONE: 'none',
};

export const videoType = {
  LOCAL: 'local',
  REMOTE: 'remote',
};

export const createGroupEvents = {
  UNSET_GROUPS_EVENT_STATUS: 0,
  BREAKOUT_GROUP_PHASE_STARTING: 5,
  CLOSING_LAST_SESSION: 10,
  CLOSED_LAST_SESSION: 20,
  GENERATED_NEW_SESSION_ID: 30,
  SETTING_MEETING_PARAMS: 40,
  ATTEMPTING_CONNECTION: 50,
  CONNECTED: 60,
  STARTING_DEVICES_CONNECTIVITY: 70,
  DEVICES_CONNECTIVITY_INTERRUPTED: 80,
  DEVICES_CONNECTED: 90,
  AUDIO_VIDEO_DID_AVAILABLE: 100,
  MEETING_AVAILABLE: 110,
};

export const createGroupEventsDesc = {
  0: 'Unset groups event status',
  5: 'Breakout groups phase starting',
  10: 'Stopping previous meeting',
  20: 'Stopped last connected audio and video',
  30: 'Setting up new meeting',
  40: 'Setting up meeting details',
  50: 'Meeting created, attempting to join',
  60: 'Joined new meeting',
  70: 'Starting connectivity with devices',
  80: 'Almost there, click here to continue',
  90: 'Starting sharing audio and video with all participants',
  100: 'Done with all the required setups, please continue',
  110: 'Users can now communicate with each other',
};

export const readinessCheckResponses = {
  SUCCEEDED: 'Succeeded',
  CONNECTION_FAILED: 'ConnectionFailed',
};

export const requestRestartVideo = {
  requestInterval: 12000,
  restartAllowedAfter: 10000,
};

export const logTypes = {
  insight: {
    icon: 'log-insight',
    desc: 'insight',
    placeholder: 'Capture an insight...',
  },
  question: {
    icon: 'log-question',
    desc: 'question',
    placeholder: 'Capture a question...',
  },
  decision: {
    icon: 'log-decision',
    desc: 'decision',
    placeholder: 'Capture a decision...',
  },
  action: {
    icon: 'log-action',
    desc: 'action',
    placeholder: 'Capture an action...',
  },
};
export const feedbackTypes = {
  good: {
    icon: 'log-good',
    desc: 'good',
    placeholder: "What's good?",
  },
  tricky: {
    icon: 'log-tricky',
    desc: 'tricky',
    placeholder: "What's tricky?",
  },
  different: {
    icon: 'log-different',
    desc: 'different',
    placeholder: 'What could be different?',
  },
};

export const bixeNativeAppProtocols = {
  prod: 'bixe-app',
  stagingAndDev: 'bixe-staging-app',
};
