<template>
  <div class="poster pane">
    <section>
      <h2>How will you give an input?</h2>
      <ul>
        <li>
          <button @click.prevent="updatePresentationMode('camera')">
            <span class="icon">
              <svg viewBox="0 0 196 129">
                <path
                  d="m98,31.83c-12.68,0-22.96,10.22-22.96,22.83,0,12.61,10.28,22.83,22.96,22.83,12.68,0,22.96-10.22,22.96-22.83s-10.28-22.83-22.96-22.83Zm.02,51.5c-25.83.01-47.11,20.1-48.39,45.67h96.74c-1.28-25.55-22.54-45.64-48.35-45.67Z"
                  fill-rule="evenodd"
                  isolation="isolate"
                />
              </svg>
            </span>
            <span class="btn btn-border">Share your camera</span>
          </button>
        </li>
        <li>
          <button @click.prevent="updatePresentationMode('screen')">
            <span class="icon">
              <svg viewBox="0 0 196 129">
                <path
                  id="Monitor"
                  d="m162.35,31.83H32.81v72.85h54.88c-.83,10.76-9.81,19.22-20.77,19.22v.03h-.45v5.06h62.25v-5.06h-.45v-.03c-10.97,0-19.94-8.47-20.77-19.22h54.86V31.83Zm-57.84,37.43v16.01h-12.52v-16.01h-8.53l14.61-18,14.61,18h-8.17Z"
                  fill-rule="evenodd"
                  isolation="isolate"
                />
              </svg>
            </span>
            <span class="btn btn-border">Share your screen</span>
          </button>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  methods: {
    updatePresentationMode(presentationMode) {
      this.$emit('onPresentationModeUpdated', { presentationMode });
    },
  },
};
</script>

<style lang="scss" scoped>
.pane {
  background: var(--c__bg);
  flex-wrap: wrap;
  z-index: 99;
}
h2 {
  width: 100%;
  margin-bottom: rem(62px);
  text-align: center;
  @include type-size('large');
}
ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: rem(35px);
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

li {
  flex: 0 0 auto;
  width: rem(196px);
}

button {
  padding: 0;
  flex-direction: column;
  width: 100%;

  .icon {
    display: block;
    background: var(--c__edges);
    height: rem(129px);
    width: 100%;
  }

  path {
    fill: rgba(0, 0, 0, 0.2);
  }

  .btn {
    margin-top: rem(18px);
    height: rem(42px);
    @include type-size('small');
  }
}
</style>
