<template>
  <app-modal-overlay class="themeSwitcher" @close="close">
    <section>
      <h2>Themes</h2>
      <div class="themeList">
        <label><input type="radio" value="default" v-model="selectedTheme" /><span>Light (default)</span></label>
        <label><input type="radio" value="dark" v-model="selectedTheme" /><span>Dark</span></label>
        <label><input type="radio" value="dynamic" v-model="selectedTheme" /><span>Dynamic</span></label>
      </div>
    </section>
    <section class="actions">
      <button class="done btn-short btn-border btn-light btn-green-flood" @click.prevent="close">Done</button>
    </section>
  </app-modal-overlay>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useFeaturesStore } from '@/store/pinia/features';
import { useMeetingStore } from '@/store/pinia/meeting';
import AppModalOverlay from '@/components/AppModalOverlay';

export default {
  components: {
    AppModalOverlay,
  },
  setup() {
    const featuresStore = useFeaturesStore();
    const meetingStore = useMeetingStore();
    return { featuresStore, meetingStore };
  },
  data() {
    return {
      selectedTheme: false,
      selectedSpace: false,
    };
  },
  mounted() {
    this.selectedTheme = this.featuresStore.theme === 'light' ? 'default' : this.featuresStore.theme;
    this.selectedSpace = this.meetingStore.space;
  },
  computed: {
    ...mapPiniaState(useFeaturesStore, {
      theme: (s) => s.settings.theme,
    }),
  },
  watch: {
    selectedTheme(nv) {
      if (nv !== this.theme) {
        this.requestTheme(nv);
      }
    },
    selectedSpace(nv) {
      if (nv !== this.meetingStore.space) {
        this.requestSpace(nv);
      }
    },
  },
  methods: {
    requestTheme(theme) {
      this.$API.updateSettings({ theme: theme }).then((r) => {
        this.featuresStore.updateUserSettings(r);
      });
    },
    requestSpace(space) {
      this.$API.updateSettings({ meetingSpace: space }).then((r) => {
        this.featuresStore.updateUserSettings(r);
      });
    },
    close() {
      this.featuresStore.toggleThemes();
    },
  },
};
</script>

<style lang="scss" scoped>
section + section,
.actions {
  margin-top: 2em;
}
.themeList {
  @include type-size('medium');
  margin-top: 1em;
  label {
    display: block;
  }
  label + label {
    margin-top: 0.25em;
  }
  input {
    margin-right: 0.5em;
  }
}
</style>
