<template>
  <div />
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import utils from '../resources/utils';
import errorReportingService from '../services/errorReportingService';

export default {
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
  mounted() {
    const pinNumber = (this.$route.params.pinNumber || '').replace('-', '').trim();
    const password = (this.$route.query.pwd || '').trim();

    utils.genericFns
      .joinPersonalRoomMeeting({ pinNumber, password })
      .then((meeting) => {
        utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
        if (this.accountStore.isLoggedIn) {
          this.$router.push(`/meeting/${meeting.id}`).catch(() => null);
        } else {
          this.$router.push(`/joining-options?id=${encodeURI(meeting.id)}&pwd=${encodeURI(meeting.pin_pass)}&flow=meeting-invite`);
        }
      })
      .catch((err) => {
        errorReportingService.reportError(err);
        this.$router.push('/join?reason=badLink').catch(() => null);
      });
  },
};
</script>
