<template>
  <div class="templateSummary" v-if="areasWithChildren.length > 0">
    <h3>Template Summary:</h3>
    <ul>
      <li v-for="area in areasWithChildren" :key="area">
        {{ area }}
        <ul>
          <li v-for="item in templateChildrenWithText(area)" :key="'item-' + item">
            {{ items[item].text }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMappingStore } from '@/store/pinia/mapping';

export default {
  name: 'MappingTemplateSummary',
  data() {
    return {};
  },
  computed: {
    ...mapPiniaState(useMappingStore, ['items', 'templateAreas', 'templateChildrenWithText']),
    areasWithChildren() {
      if (!this.templateAreas) {
        return false;
      } else {
        return this.templateAreas.filter((area) => this.templateChildrenWithText(area).length > 0);
      }
    },
  },
  watch: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.templateSummary {
  padding: 1em;
  position: absolute;
  bottom: 6em;
  right: 1em;
  width: 20em;
  background: rgba(white, 0.5);
  max-height: 40vh;
  border: 1px solid #eeeeee;
  & > ul {
    padding: 0;
    list-style: none;
    text-transform: capitalize;
  }
  ul ul {
    text-transform: none;
    list-style: disc inside;
    padding-left: 1em;
  }
}
</style>
