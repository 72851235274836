<template>
  <div class="noteBar" :class="[extraClass, mode]" v-click-outside="outsideTypes">
    <transition name="scale">
      <button v-if="mini && (showTypes || initialDone)" @click="collapse" class="collapse" @keyup.space="(e) => e.preventDefault()">
        <app-icon icon="collapse" />
        <span class="visuallyHidden">Collapse moments</span>
      </button>
    </transition>
    <div class="wrapper">
      <div class="types" :class="[whatTypes, { open: showTypes }]">
        <transition-group name="typesListTransition" tag="ul" class="typeChoices">
          <li v-for="(type, name) in visibleTypes" :class="[name === currentType && showTypes ? 'current' : '', name]" :key="'type-' + name">
            <button class="btn-borderless" @click="handleTypes" :data-name="name">
              <app-icon :icon="type.icon" />
              <span class="desc">{{ type.desc }}</span>
            </button>
          </li>
        </transition-group>
      </div>
      <form v-show="showForm" @submit.prevent="send()">
        <div ref="textInput" class="inputWrapper">
          <label v-if="initialDone" id="noteLabel" key="label" class="visuallyHidden">{{ placeholder }}</label>
          <button v-if="!initialDone && !mini" class="fakePlaceholder" key="initButton" @click="handleInit">{{ initialPlaceholder }}</button>
          <app-auto-textarea
            v-if="initialDone"
            key="field"
            v-model="logText"
            focus="true"
            :value="logText"
            :placeholder="placeholder"
            labelledby="noteLabel"
            @enter="send"
            @up="tryUp"
            @click="handleFieldClick"
          />
        </div>
        <button class="send" type="submit" ref="sendButton" title="send" :disabled="!initialDone">
          <app-icon icon="log-send" />
        </button>
        <avatars-extra-emotes direction="log" class="emotes notesEmotes" />
      </form>

      <app-button
        v-if="!mini"
        ref="expandToggle"
        class="expandLog"
        :button-class="expanded"
        title="toggle log"
        :disabled="phaseStore.currentPhase === 'review' || isFeedbackReview"
        @real-click="toggleOpen"
        wobble="true"
        blur-on-click="true"
      >
        <app-icon icon="log-expand" />
      </app-button>
      <div ref="emitter" class="emitter"></div>

      <transition name="bottom-notice">
        <p v-if="success" class="saved">Saved!</p>
      </transition>
    </div>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { ref, computed, watch, onMounted, onBeforeUnmount, getCurrentInstance, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import AppIcon from '@/components/AppIcon.vue';
import AppButton from '@/components/AppButton.vue';
import AppAutoTextarea from '@/components/AppAutoTextarea.vue';
import AvatarsExtraEmotes from '@/components/AvatarsExtraEmotes.vue';

import { colors } from '@/resources/colors';
import { logTypes, feedbackTypes } from '@/shared/constants';
import { useMomentsStore } from '@/store/pinia/moments';
import { useAccountStore } from '@/store/pinia/account';
import { usePhaseStore } from '@/store/pinia/phase';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useFeaturesStore } from '@/store/pinia/features';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;
const momentsStore = useMomentsStore();
const accountStore = useAccountStore();
const phaseStore = usePhaseStore();
const frameStore = useFrameStore();
const featuresStore = useFeaturesStore();
const usersStore = useUsersStore();

const props = defineProps(['mini']);

const initialDone = ref(false);
const currentLogType = ref('insight');
const currentFeedbackType = ref('good');
const logText = ref('');
const success = ref(false);
const showTypes = ref(true);
const attemptingSend = ref(false);
const animatingSent = ref(false);
const placeholder = ref('Capture an insight...');
const textInput = ref(null);
const sendButton = ref(null);
const emitter = ref(null);

onMounted(() => {
  $ee.on('nM:note:created', noteCreated);
  if (storedContent.value) {
    logText.value = storedContent.value;
    currentType.value = storedType.value;
    initialDone.value = true;
    nextTick(() => {
      $ee.emit('nM:resizeAutotext');
    });
  }
  if (props.mini) {
    showTypes.value = false;
  } else {
    showTypes.value = true;
  }
});
onBeforeUnmount(() => {
  $ee.off('nM:note:created', noteCreated);
});

const { noAnimations } = storeToRefs(featuresStore);
const { currentUser } = storeToRefs(accountStore);
const { isFeedback, phaseStage, currentPhase, currentPhaseId } = storeToRefs(phaseStore);
const { myId } = storeToRefs(usersStore);
const { ordered: logs, barContent: storedContent, barType: storedType, barMode: mode, momentsOpen: frameOpen } = storeToRefs(momentsStore);
const { fullscreen } = storeToRefs(frameStore);

const isFeedbackReview = computed(() => isFeedback.value && phaseStage.value === 'reviewing');
const initialPlaceholder = computed(() => (isFeedback.value ? 'Capture your feedback...' : 'Capture key moments...'));
const whatTypes = computed(() => (mode.value === 'feedback' ? 'feedback' : 'moments'));
const types = computed(() => (mode.value === 'feedback' ? feedbackTypes : logTypes));
const currentType = computed(() => (mode.value === 'feedback' ? currentFeedbackType.value : currentLogType.value));

const currentTypeObj = computed(() => {
  let t = {};
  t[currentType.value] = types.value[currentType.value];
  return t;
});
const visibleTypes = computed(() => (showTypes.value ? types.value : currentTypeObj.value));
const expanded = computed(() => (frameOpen.value ? 'expanded' : 'closed'));
const rawPlaceholder = computed(() => (types.value[currentType.value] ? types.value[currentType.value].placeholder : types.value[0].placeholder));
const showForm = computed(() => {
  if (!props.mini || (!showTypes.value && initialDone.value)) {
    return true;
  } else {
    return false;
  }
});
const extraClass = computed(() => {
  if (!props.mini) {
    return false;
  } else {
    if (showTypes.value) {
      return 'choosing';
    } else if (!initialDone.value) {
      return 'closed';
    } else {
      return 'showing';
    }
  }
});

watch(rawPlaceholder, (nv) => {
  placeholder.value = nv;
});
watch(logText, (nv) => {
  storeContent(nv);
});
watch(currentPhase, (nv) => {
  if (nv === 'feedback') {
    showTypes.value = true;
  }
});

function storeContentRaw(nv) {
  momentsStore.storeBarContent({
    value: nv,
    type: currentType.value,
  });
}
const storeContent = _.debounce(storeContentRaw, 200);

function toggleOpen() {
  if (frameOpen.value) {
    momentsStore.closeMoments();
  } else {
    momentsStore.openMoments();
  }
}

// changeMode(to) {
//   this.closeTypes();
//   this.$nextTick(() => {

//     // if (textInput.value.querySelector('.real')) {
//     //   textInput.value.querySelector('.real').focus();
//     // }
//     _.delay(() => {
//       showTypes.value = true;
//     }, 100);
//   });
// },

function handleTypes(e) {
  let to = e.target.dataset.name;
  e.target.blur();
  initialDone.value = true;
  if (to === currentType.value) {
    toggleTypes();
  } else {
    changeCurrent(to);
  }
}

function handleFieldClick() {
  if (!logText.value && !props.mini) {
    showTypes.value = false;
  }
}
function handleInit() {
  showTypes.value = false;
  _.delay(() => {
    initialDone.value = true;
  }, 10);
}
function changeCurrent(to) {
  closeTypes();
  if (mode.value === 'feedback') {
    currentFeedbackType.value = to;
  } else {
    currentLogType.value = to;
  }
  nextTick(() => {
    textInput.value.querySelector('.real').focus();
  });
}
function toggleTypes() {
  showTypes.value = !showTypes.value;
}
function outsideTypes() {
  if (!props.mini) {
    closeTypes();
  }
}
function closeTypes() {
  if (initialDone.value) {
    showTypes.value = false;
  }
}
function collapse() {
  initialDone.value = false;
  showTypes.value = false;
}
function send() {
  if (logText.value && !animatingSent.value && !attemptingSend.value) {
    attemptingSend.value = true;

    const data = {
      type: currentType.value,
      description: logText.value,
      phaseId: currentPhaseId.value,
    };

    if (currentPhase.value === 'dialogue' && phaseStage.value !== 'topic') {
      $ee.emit('nM:dialogue:logAdded', data);
    } else {
      $meetingmanager.addLog(data);
    }

    fly();
    _.delay(checkSent, 5000);
  }
}

function clear() {
  logText.value = '';
}

function checkSent() {
  if (attemptingSend.value) {
    attemptingSend.value = false;
    logFailed();
  }
}

function fly() {
  animatingSent.value = true;
  sendButton.value.blur();
  let outline = sendButton.value.querySelector('.main');
  let fill = sendButton.value.querySelector('.sending');

  sendButton.value.style.color = colors[currentType.value];
  sendButton.value.style.transformOrigin = '100% 50%';

  Velocity(outline, { opacity: 0 }, 200);
  Velocity(fill, { opacity: 1 }, 200);

  Velocity(
    sendButton.value,
    {
      scaleY: [0, 1],
      translateX: ['165%', '0%'],
      opacity: 0,
    },
    {
      easing: 'easeInQuint',
      duration: 400,
      delay: 100,
    },
  );

  Velocity(
    textInput.value,
    {
      opacity: 0,
      translateX: ['5em', '0em'],
    },
    {
      duration: 200,
      easing: 'easeInQuint',
    },
  );
}

function noteCreated(event) {
  if (currentUser.value.id === event.userId) {
    logSuccess();
  }
}
function tryUp() {
  if (!logText.value && logs.value.length > 0) {
    let myLogs = logs.value.filter((log) => log.user === myId.value);
    momentsStore.startEditing(myLogs[myLogs.length - 1].id);
    momentsStore.openMoments();
  }
}
function logSuccess() {
  if (props.mini && fullscreen.value) {
    attemptingSend.value = false;
    resetField();
    _.delay(() => {
      reset(true);
    }, 500);
  } else if (!props.mini && !fullscreen.value && emitter.value) {
    attemptingSend.value = false;
    if (!noAnimations.value) {
      let expand = emitter.value;
      let burst = document.createElement('div');

      burst.classList.add('burst');
      burst.style.color = colors[currentType.value];
      burst.style.transform = 'scale(0)';
      expand.appendChild(burst);

      Velocity(
        burst,
        {
          scale: [1.5, 0],
          opacity: [0, 1],
        },
        {
          duration: 1000,
          easing: 'easeOutQuint',
          delay: 800,
          complete: () => {
            expand.removeChild(burst);
          },
        },
      );
    }
    resetField();
    _.delay(() => {
      if (document.activeElement.matches('textarea')) {
        document.activeElement.blur();
      }
      reset(true);
      showTypes.value = true;
    }, 1500);
  }
}

function logFailed() {
  if (sendButton.value) {
    let fill = sendButton.value.querySelector('.sending');
    let outline = sendButton.value.querySelector('.main');

    fill.style.transformOrigin = '50% 50%';

    Velocity(
      fill,
      {
        rotateZ: ['360deg', '0deg'],
      },
      {
        easing: 'easeOutQuint',
        duration: 600,
        delay: 0,
      },
    );

    Velocity(
      sendButton.value,
      {
        scaleY: [1, 0],
        translateX: ['0%', '165%'],
        opacity: 1,
      },
      {
        easing: 'easeOutQuint',
        duration: 600,
        delay: 0,
        complete: () => {
          _.delay(() => {
            Velocity(outline, { opacity: 1 }, 200);
            Velocity(fill, { opacity: 0 }, 200);
            _.delay(() => {
              sendButton.value.style.color = null;
            }, 200);
            sendButton.value.style.transform = null;
            sendButton.value.opacity = null;
          }, 200);

          _.delay(() => {
            animatingSent.value = false;
            Velocity(
              textInput.value,
              {
                opacity: 1,
                translateX: [0, 0],
              },
              {
                duration: 200,
                complete: () => {
                  textInput.value.style.transform = null;
                  textInput.value.opacity = null;
                },
              },
            );
          }, 300);
        },
      },
    );
  }
}
function resetField() {
  if (textInput.value) {
    clear();
    $ee.emit('bus:sentLog');
    Velocity(
      textInput.value,
      {
        opacity: 1,
        translateX: [0, 0],
      },
      {
        duration: 200,
        complete: () => {
          textInput.value.style.transform = null;
          textInput.value.opacity = null;
        },
      },
    );
  }
}
function reset() {
  if (sendButton.value) {
    let outline = sendButton.value.querySelector('.main');
    let fill = sendButton.value.querySelector('.sending');

    sendButton.value.style.color = null;
    outline.style.opacity = null;
    fill.style.opacity = null;

    animatingSent.value = false;

    Velocity(
      sendButton.value,
      {
        scaleX: [1, 0],
        scaleY: [1, 0],
        translateX: [0, 0],
        opacity: 1,
      },
      {
        easing: 'easeOutQuint',
        duration: 200,
        complete: () => {
          sendButton.value.style.transform = null;
          sendButton.value.opacity = null;
        },
      },
    );
  }
}
</script>
<style lang="scss">
@keyframes momentHeyBounce {
  0%,
  95% {
    transform-origin: 50% 100%;
    transform: scaleX(1) scaleY(1) translateY(0);
  }

  97% {
    transform: scaleX(1.1) scaleY(0.7) translateY(0);
  }

  99% {
    transform-origin: 50% 0%;
    transform: scaleX(1) scaleY(1.2) translateY(-0.6em);
  }

  100% {
    transform-origin: 50% 0%;
    transform: scaleX(1) scaleY(1) translateY(-0.6em);
  }
}

@keyframes momentHey {
  0%,
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.noteBar li button .icon {
  transform-origin: 50% 100%;
}

$moment-easing: cubic-bezier(1, 0, 0.8, 1);
$moment-length: 5s;

.noteBar .types.open li.insight,
.noteBar .types.open li.good {
  animation: momentHey $moment-length $moment-easing 3s infinite alternate;
}
.noteBar .types.open li.question,
.noteBar .types.open li.tricky {
  animation: momentHey $moment-length $moment-easing 3s infinite alternate;
}
.noteBar .types.open li.decision,
.noteBar .types.open li.different {
  animation: momentHey $moment-length $moment-easing 3.1s infinite alternate;
}
.noteBar .types.open li.action {
  animation: momentHey $moment-length $moment-easing 3.15s infinite alternate;
}

.noteBar .types.open.feedback {
  li.insight,
  li.question,
  li.decision,
  li.action {
    animation: none !important;
    opacity: 0;
  }
}

.noteBar .types.open.moments {
  li.good,
  li.tricky,
  li.different {
    animation: none !important;
    opacity: 0;
  }
}

.noteBar .emoteBubbles {
  left: auto;
  right: 2.3em;
  top: 48%;
  .flash {
    height: 3em;
    width: 3em;
    margin: -1.5em 0 0 -1.5em;
  }
}

.noteBar .emoteBadges {
  left: auto;
  right: 0.8em;
  top: 48%;
  .flash {
    height: 3em;
    width: 3em;
    margin: -1.5em 0 0 -1.5em;
  }
}
.emitter {
  position: absolute;
  width: rem(1px);
  right: rem(32px);
  height: rem(1px);
  top: rem(37px);
  svg {
    height: rem(20px);
    width: rem(22px);
    transition: transform 0.3s ease;
    // rotate(180 9.5 6.5) inline svg transform version
  }

  .burst {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 3em;
    width: 3em;
    margin: -1.5em 0 0 -1.5em;
    border: 0.5em solid currentColor;
    background: currentColor;
    border-radius: 50%;
  }
}
</style>
