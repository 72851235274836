<template>
  <section class="appDrawer stopSharingDrawer">
    <section class="presenterBar">
      <div class="msg">
        You are currently sharing<br v-if="drawerState.hasTimer" />
        your screen. <b class="btnStopSharing noDrag" @click="stopSharing()">Stop sharing?</b>
      </div>
      <div class="divider" v-if="drawerState.hasTimer">&nbsp;</div>
      <div id="miniTimer" class="miniTimer" v-if="drawerState.hasTimer">
        <timer-disc
          key="phase-timer"
          type="mini"
          showing="true"
          :current="timeStore.phaseElapsed"
          :total="timeStore.phaseLength"
          :passed-setting-time="timeStore.phaseSettingTime"
          :remaining="timeStore.phaseRemaining"
        />
      </div>
      <div class="addPhaseTime" v-if="drawerState.hasAddMoreBtn">
        <button class="btn btn-border btn-blue-flood noDrag" @keyup.space="(e) => e.preventDefault()" @click="addMoreTime()">More time</button>
      </div>
    </section>
  </section>
</template>

<script setup>
import { onMounted, getCurrentInstance, onBeforeMount, computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMomentsStore } from '@/store/pinia/moments';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { usePageStore } from '@/store/pinia/page';

import utils from '@/resources/utils';
import bixeApi from '@/services/bixe-api.service';
import TimerDisc from '@/components/TimerDisc';

const route = useRoute();
const meetingStore = useMeetingStore();
const mediaStore = useMediaStore();
const phaseStore = usePhaseStore();
const sidebarStore = useSidebarStore();
const momentsStore = useMomentsStore();
const usersStore = useUsersStore();
const timeStore = useTimeStore();
const pageStore = usePageStore();
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const hasActiveSocketConnection = ref(false);
const drawerState = ref({ hasTimer: false, hasAddMoreBtn: false, drawerDimension: { width: 0, height: 0 } });
const shouldDrawerBeVisible = computed(() => hasActiveSocketConnection.value && (phaseStore.mirroring.isActive || mediaStore.videoSourceActive));

watch(shouldDrawerBeVisible, async (value) => {
  if (value && utils.isOpenedWithinElectronShell()) {
    await ipcApi.send({ name: 'showStopSharingWindow', dimensions: { ...drawerState.value.drawerDimension } });
  }
});

function updateNow() {
  timeStore.updateNow();
  setTimeout(() => {
    requestAnimationFrame(updateNow);
  }, timeStore.tickForSmallerDrawers);
}

function getDrawerState({ state }, { myId }) {
  const iAmModerator = (state.meetingControllers || []).includes(myId);
  const moduleId = state?.current_phase?.module_id;
  let dState = {
    hasTimer: moduleId && moduleId !== 'freeform',
    hasAddMoreBtn: moduleId && moduleId !== 'freeform',
    drawerDimension: { width: 0, height: 0 },
  };
  if (moduleId === 'groups') {
    dState.hasAddMoreBtn = state.current_phase.active === myId || iAmModerator;
  }
  if (dState.hasTimer && dState.hasAddMoreBtn) {
    dState.drawerDimension = { width: 600, height: 75 };
  } else if (dState.hasTimer) {
    dState.drawerDimension = { width: 365, height: 75 };
  } else {
    dState.drawerDimension = { width: 475, height: 50 };
  }

  return dState;
}

onBeforeMount(async () => {
  pageStore.update({ pageName: 'stopSharingDrawer' });
});

onMounted(() => {
  if (!utils.isOpenedWithinElectronShell()) {
    // drawer should only be visible in bixe-native app
    return;
  }
  bixeApi.getMeetingState(route.params.id).then(async (meetingState) => {
    const isResync = false;
    phaseStore.initMeeting(meetingState, isResync);
    usersStore.initMeeting(meetingState, isResync);
    meetingStore.initMeeting(meetingState, isResync);
    momentsStore.initMeeting(meetingState, isResync);
    sidebarStore.initMeeting(meetingState, isResync);
    timeStore.initMeeting(meetingState, isResync);
    mediaStore.initMeeting(meetingState, isResync);
    requestAnimationFrame(updateNow);

    drawerState.value = getDrawerState(meetingState, { myId: window.__INITIAL_STATE__.user.id });
    $meetingmanager.connect(meetingState, { avDeviceState: {} }, { requestResync: false, isDuplicateClient: true });
    $meetingmanager.once('connected', async () => {
      hasActiveSocketConnection.value = true;
    });
  });
});

const stopSharing = () => {
  ipcApi.send({ name: 'hideStopSharingWindow' });
};

const addMoreTime = () => {
  $meetingmanager.addTime(60);
};
</script>

<style lang="scss" scoped>
.stopSharingDrawer {
  display: flex;
  justify-content: center;
  user-select: none;
  -webkit-user-select: none;
  -webkit-app-region: drag;
  cursor: pointer;
  background: var(--c__bg-alt-90);
  .presenterBar {
    padding: rem(16px) rem(40px);
    display: flex;
    align-items: center;
    .noDrag {
      -webkit-app-region: no-drag;
    }
    .addPhaseTime {
      margin-left: rem(16px);
      .btn {
        height: rem(48px);
        width: rem(250px);
      }
    }
    .msg {
      font-size: rem(18px);
      line-height: rem(24px);
      font-weight: 100;
      .btnStopSharing {
        cursor: pointer;
        color: var(--c__red);
        @include triggered {
          color: var(--c__red-dark);
        }
      }
    }
    .divider {
      position: relative;
      margin-left: rem(16px);
    }
    .divider:after {
      content: '';
      position: absolute;
      border: 1px solid var(--c__edges);
      height: 415%;
      top: -155%;
    }

    $mini-timer-size: 40px;

    :deep(.miniTimer) {
      height: rem($mini-timer-size);
      width: rem($mini-timer-size);
      border-radius: 50%;
      position: relative;
      margin-left: rem(16px);
      flex: 0 0 rem($mini-timer-size);
      .miniDisc {
        color: var(--c__time-ok);
        height: rem($mini-timer-size);
        width: rem($mini-timer-size);
        border-radius: 50%;
      }

      circle {
        stroke: currentColor;
      }

      span {
        position: absolute;
        font-size: rem(18px);
        line-height: rem($mini-timer-size);
        width: 100%;
        top: 0;
        left: 0;
        text-align: center;
        color: var(--c__text);
      }
      .mid {
        color: var(--c__time-mid);
      }
      .end {
        color: var(--c__time-over);
      }
      .overdue {
        animation: overdue-jitter 2s linear 1s infinite;
        color: white;
        background: var(--c__red);
        span {
          color: var(--c__bg);
        }
        circle {
          stroke: var(--c__red);
          fill: var(--c__red);
        }
      }
    }
  }
}
</style>
