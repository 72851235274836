<template>
  <div class="editItem">
    <form @submit.prevent="done">
      <h1>Edit card</h1>
      <label for="cardText">Card content</label>
      <textarea id="cardText" v-model="text"></textarea>
      <p class="label" for="colors">Card Colours</p>
      <fieldset class="colors">
        <span v-for="color in colors" :key="color" :class="color">
          <input
            type="radio"
            name="cardColor"
            :id="'color-' + color"
            :value="color"
            v-model="selectedColor"
            :checked="color === item.color ? 'selected' : ''"
          />
          <label :for="'color-' + color">{{ color }}</label>
        </span>
      </fieldset>
      <div class="finish">
        <button class="btn done" type="submit">Done</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMappingStore } from '@/store/pinia/mapping';

export default {
  name: 'MappingEditItem',
  data() {
    return {
      text: '',
      selectedColor: 'yellow',
      showing: false,
    };
  },
  props: {
    item: Object,
  },

  setup() {
    const mappingStore = useMappingStore();
    return { mappingStore };
  },
  directives: {},
  computed: {
    ...mapPiniaState(useMappingStore, {
      isDragging: 'dragging',
      isResizing: 'resizing',
      colors: 'colors',
    }),
  },
  mounted() {
    this.text = this.item.text;
    this.selectedColor = this.item.color;
    this.showing = true;
    this.popupItem = this.$el;
  },
  watch: {
    text(nv) {
      this.updateItem({ text: nv });
    },
    selectedColor(nv) {
      this.updateItem({ color: nv });
    },
  },
  methods: {
    updateItem(args) {
      this.mappingStore.updateItem({ localId: this.item.localId, ...args });
    },
    done() {
      this.mappingStore.setEditingMapItem(false);
    },
    outside() {
      if (this.showing) {
        this.done();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.editItem {
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

form {
  background: white;
  color: black;
  padding: 1em;
}

textarea {
  width: 30em;
  height: 10em;
  padding: 0.5em;
}

label {
  display: block;
}

.colors {
  padding: 0;
  border: none;
  span {
    display: inline-block;
    margin-right: 0.5em;
    font-weight: bold;
    position: relative;
  }

  input {
    appearance: none;
    background: none;
    border: 2px solid;
    height: 100%;
    left: 0;
    opacity: 0.00001;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  label {
    padding: 0.25em 0.5em;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
  }

  @each $color, $col in $note_colors {
    .#{$color} {
      label {
        // background: $c__yellow;
        border-color: $col;
        @include triggered {
          background: $col;
        }
      }

      input:checked + label,
      input:hover + label {
        background: $col;
      }
    }
  }

  .none label {
    border-style: dashed;
  }

  .black label {
    @include triggered {
      color: white;
    }
  }

  .black input:checked + label,
  .black input:hover + label {
    color: white;
  }

  .none label {
    border-color: #dddddd;
  }

  .none input:checked + label,
  .none input:hover + label {
    border-color: black;
    background: #dddddd;
    color: black;
  }
}

.finish {
  text-align: right;
  margin: 1em 0 0 0;
}
</style>
