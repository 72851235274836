import $API from '../services/bixe-api.service';

export default () => (to, from, next) => {
  $API
    .isAuthorizedToAccessAdminReports({ reportName: to.path })
    .then(() => {
      next();
    })
    .catch((err) => {
      console.log('authorization error', err);
      return next('/not-found');
    });
};
