import { defineStore } from 'pinia';
import { eventEmitter } from '@/services/event-emitter.service';
import _ from 'underscore';

function parseMoment(l) {
  return {
    id: l.id,
    user: l.userId,
    content: l.description,
    type: l.type || 'note',
    editedBy: l.editedBy,
    timestamp: l.timestamp,
    phaseId: l.phaseId,
  };
}

export const useMomentsStore = defineStore('moments', {
  state() {
    return {
      barMode: 'moments',
      momentsOpen: false,
      isGrouped: false,
      iAmEditing: false,
      history: [],
      toAdd: [],
      toDelete: [],
      toUpdate: [],
      moments: null,
      barContent: false,
      barType: false,
    };
  },
  getters: {
    ordered: (state) => _.sortBy(state.moments, 'timestamp'),
    initialized: (state) => state.moments !== null,
  },
  actions: {
    initMeeting() {},
    replaceAll(notes) {
      this.moments = notes.map((l) => parseMoment(l));
    },
    onNoteAdded(ev) {
      if (this.iAmEditing && this.isGrouped) {
        this.toAdd.push(ev);
      } else {
        this.add(ev);
        this.handleBacklog();
      }
    },
    onNoteUpdated(ev) {
      if (this.iAmEditing && this.isGrouped) {
        this.toUpdate.push(ev);
      } else {
        this.update(ev);
        this.handleBacklog();
      }
    },
    onNoteDeleted(ev) {
      if (this.iAmEditing && this.isGrouped) {
        this.toDelete.push(ev);
      } else {
        this.delete(ev);
        this.handleBacklog();
      }
    },
    handleBacklog() {
      this.toAdd.forEach((ev) => {
        this.add(ev);
      });
      this.toAdd = [];
      this.toDelete.forEach((ev) => {
        this.delete(ev);
      });
      this.toDelete = [];
      this.toUpdate.forEach((ev) => {
        this.update(ev);
      });
      this.toUpdate = [];
    },
    add(ev) {
      if (this.moments === null) {
        this.moments = [];
      }
      this.moments.push(parseMoment(ev));
      // emit event to show bubble-up animation (a note has been added) on meeting notes bar.
      eventEmitter.emit('nM:note:created', ev);
    },
    delete(ev) {
      this.moments = this.moments.filter((m) => m.id !== ev.id);
    },
    update(ev) {
      let index = _.findIndex(this.moments, (m) => m.id === ev.id);
      if (index > -1) {
        this.moments[index] = parseMoment(ev);
        eventEmitter.emit('nM:note:updated', ev);
      }
    },
    startEditing(id) {
      this.iAmEditing = id;
    },
    stopEditing() {
      this.iAmEditing = false;
      this.handleBacklog();
    },
    openMoments() {
      this.momentsOpen = true;
    },
    closeMoments() {
      this.momentsOpen = false;
    },
    toggleMoments() {
      this.momentsOpen = !this.momentsOpen;
    },
    groupMoments() {
      this.isGrouped = true;
    },
    ungroupMoments() {
      this.isGrouped = false;
    },
    changeBarMode(v) {
      this.barMode = v;
    },
    storeBarContent({ value, type }) {
      this.barContent = value;
      this.barType = type;
    },
  },
});
