<template>
  <div>
    <transition name="slowFade">
      <div v-if="initialPending && !hasDevices && !devicesFailed" class="skeleton poster" :class="[isFirefox ? 'noOutput' : 'hasOutput']">
        <div class="content pending">
          <div class="skeletonItem fakeVideo"></div>
          <div class="skeletonItem fakeVideoIn fakeDevice"></div>
          <div class="skeletonItem fakeAudioIn fakeDevice"></div>
          <div v-if="!isFirefox" class="skeletonItem fakeAudioOut fakeDevice"></div>
        </div>
      </div>
    </transition>
    <transition name="slowFade">
      <div v-if="initialPending && !hasDevices && devicesFailed" class="skeleton poster" :class="[isFirefox ? 'noOutput' : 'hasOutput']">
        <div class="content failed">
          <div class="fakeVideo">
            <svg version="1.1" viewBox="0 0 140 140">
              <path
                class="fill"
                d="M70,140c-18.6,0-36.4-7.4-49.5-20.5C7.4,106.4,0,88.6,0,70s7.4-36.4,20.5-49.5S51.4,0,70,0
	s36.4,7.4,49.5,20.5C132.6,33.6,140,51.4,140,70c0,18.6-7.4,36.4-20.5,49.5C106.4,132.6,88.6,140,70,140z M70,5.6
	c-17.1,0-33.5,6.8-45.5,18.9C12.4,36.5,5.6,52.9,5.6,70s6.8,33.5,18.9,45.5c12.1,12.1,28.5,18.9,45.5,18.9s33.5-6.8,45.5-18.9
	c12.1-12.1,18.9-28.5,18.9-45.5c0-17.1-6.8-33.4-18.9-45.5S87.1,5.6,70,5.6z"
              />
              <path
                class="fill"
                d="M103.5,66.9c-1.3-0.2-2.5,0.5-3,1.6c-1.5,3.3-5,5.2-8.6,4.6c-3.6-0.6-6.3-3.5-6.7-7.1
	c-0.1-1.3-1.1-2.3-2.3-2.5c-0.9-0.1-1.8,0.1-2.4,0.7c-0.6,0.6-1,1.5-0.9,2.4c0.6,6.1,5.3,11.1,11.3,12.1c6.1,1,12.1-2.2,14.6-7.8
	c0.4-0.8,0.4-1.7-0.1-2.5C105.1,67.6,104.3,67.1,103.5,66.9L103.5,66.9z"
              />
              <path
                class="fill"
                d="M57.2,63.5c-1.3,0.2-2.2,1.2-2.3,2.5c-0.4,3.6-3.1,6.5-6.7,7.1c-3.6,0.6-7.1-1.3-8.6-4.6
	c-0.3-0.7-0.9-1.2-1.6-1.5c-0.7-0.3-1.5-0.2-2.2,0.1c-0.7,0.3-1.2,0.9-1.5,1.6c-0.3,0.7-0.2,1.5,0.1,2.2c2.6,5.6,8.6,8.8,14.6,7.8
	c6.1-1,10.7-6,11.3-12.1c0.1-0.9-0.2-1.7-0.9-2.4C58.9,63.6,58,63.3,57.2,63.5L57.2,63.5z"
              />
              <path
                class="fill"
                d="M90.9,105.3c0-1.2-0.7-2.2-1.8-2.6c-12.2-5.1-26-5.1-38.2,0c-1.1,0.4-1.8,1.5-1.8,2.6
	c0,0.9,0.5,1.8,1.3,2.3c0.8,0.5,1.8,0.6,2.6,0.3c10.9-4.5,23.1-4.5,34,0c0.9,0.4,1.9,0.3,2.6-0.3C90.4,107.1,90.9,106.2,90.9,105.3
	L90.9,105.3z"
              />
            </svg>
          </div>
          <p>We're having trouble loading your devices&hellip; please refresh the page and try again.</p>
          <button class="btn btn-border" @click.prevent="refresh">Refresh</button>
        </div>
      </div>
    </transition>
    <transition name="basic">
      <div v-if="hasDevices && !devicesFailed && initialPending">
        <transition-group name="basic" tag="div" class="content">
          <connectivity-settings
            key="core"
            pre="true"
            show-video="true"
            @audio:change="onAudioChanged"
            @audio-output:change="onAudioOutputChanged"
            @video:change="onVideoChanged"
            class="connectivityScreen"
          />

          <section class="part connectionTest" :class="resultClass" id="pre-call-test" key="test">
            <h3>
              <app-icon icon="connectivity-connection" />
              <transition name="basic">
                <span v-if="!result || resultClass === 'poor'" class="heading-testing" key="testing">Connectivity test</span>
              </transition>
              <transition name="basic">
                <span v-if="resultClass === 'excellent'" class="heading-success" key="">Great Connectivity!</span>
              </transition>
              <transition name="basic">
                <span v-if="resultClass === 'ok'" class="heading-ok">OK Connectivity</span>
              </transition>
            </h3>
            <div class="controls">
              <span id="test-status">
                <label v-html="testLabel" />
                <button v-if="testing" id="connectivity-cancel">x</button>
              </span>
              <div id="precall-test-meter" class="meter">
                <div id="precall-test-meter-level" class="bar" :style="{ width: level * 100 + '%', animationPlayState: testing ? 'running' : 'paused' }" />
              </div>
            </div>
          </section>

          <section v-if="resultClass === 'poor'" class="part" key="results">
            <div id="pre-call-test-results">
              <div id="results">
                <span class="result-bad">
                  Connectivity is poor, we suggest you proceed without video.
                  <button id="connectivityRetest" class="precall-retest" @click.prevent="retest">Retest</button>
                </span>
              </div>
            </div>
          </section>

          <section key="enter" class="save part">
            <div>
              <button id="connectivityEnter" class="btn enterMeeting btn-border btn-green-flood" @click.prevent="go">
                <span v-if="isLoading"><app-loading-spinner /></span>
                <span v-else>{{ enterText }}</span>
              </button>
            </div>
          </section>
        </transition-group>
      </div>
    </transition>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import { useAccountStore } from '@/store/pinia/account';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { useStorage } from 'vue3-storage';
import ConnectivitySettings from '@/components/ConnectivitySettings';
import AppIcon from '@/components/AppIcon';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import utils from '@/resources/utils';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });

export default {
  components: {
    ConnectivitySettings,
    AppIcon,
    AppLoadingSpinner,
  },
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const mediaStore = useMediaStore();
    const accountStore = useAccountStore();
    const usersStore = useUsersStore();
    return { pageStore, meetingStore, mediaStore, accountStore, usersStore };
  },
  data() {
    return {
      TEST_DURATION_MAX: 250,
      isLoading: false,
      level: 0,
      testing: true,
      result: false,
      hideTest: false,
      testMeterInterval: null,
      placeholderTimeOut: false,
      devicesFailed: false,
      initialPending: false,
      timeoutForFailedToPickupCamera: null,
    };
  },
  mounted() {
    let _this = this;
    this.$ee.on('api:startTestMeter', _this.startTest);
    this.$ee.on('api:stopTestMeter', _this.stopTest);
    this.$ee.on('api:displayNetworkTestResults', _this.results);
    this.aboutToShow();
    setTimeout(() => {
      _this.placeholderTimeOut = true;
      _this.initialPending = true;
    }, 1000);
    this.timeoutForFailedToPickupCamera = setTimeout(() => {
      if (this.videoOn && _this.usersStore.userVideoDown('connectivity')) {
        _this.mediaStore.setVideoOn(false);
        _this.usersStore.setVideoLoaded('connectivity', true);
        _this.onVideoChanged(null);
      }
    }, 10000);
    setTimeout(() => {
      if (!_this.hasDevices) {
        _this.devicesFailed = true;
      }
    }, 30000);
  },
  unmounted() {
    let _this = this;
    this.isLoading = false;
    this.$ee.off('api:startTestMeter', _this.startTest);
    this.$ee.off('api:stopTestMeter', _this.stopTest);
    this.$ee.off('api:displayNetworkTestResults', _this.results);
    this.$ee.emit('connectivity:hidden');
    clearTimeout(this.timeoutForFailedToPickupCamera);
  },
  computed: {
    ...mapPiniaState(useMediaStore, {
      devices: 'userDevices',
      deviceList: 'devices',
      audioSource: 'audioSource',
      audioOutputSource: 'audioOutputSource',
      videoSource: 'videoSource',
      audioOn: 'audioOn',
      audioOutputOn: 'audioOutputOn',
      videoOn: 'videoOn',
    }),
    hasAudioIn() {
      return this.deviceList.filter((d) => d.kind === 'audioInput').length > 0;
    },
    hasAudioOut() {
      return this.deviceList.filter((d) => d.kind === 'audioOutput').length > 0;
    },
    hasVideoIn() {
      return this.deviceList.filter((d) => d.kind === 'videoInput').length > 0;
    },
    isFirefox() {
      return navigator.userAgent.indexOf('Firefox') != -1;
    },
    hasDevices() {
      if (!this.placeholderTimeOut) {
        return false;
      }
      return this.hasAudioIn;
    },
    started() {
      return this.pageStore.current?.meeting?.status === 'started';
    },
    enterText() {
      const isScheduled = this.pageStore.current?.meeting?.meta?.scheduled;
      if (this.meetingStore.useLobby || isScheduled) {
        if (this.started) {
          return this.accountStore.isMeetingOwner ? 'Enter Meeting Space' : 'Enter Waiting Room';
        } else {
          return 'Enter Green Room';
        }
      } else {
        return this.accountStore.isMeetingOwner ? 'Enter Meeting' : 'Enter Waiting Room';
      }
    },
    testLabel() {
      return this.testing ? 'testing&hellip;' : '';
    },
    resultClass() {
      if (this.result === 'precall-tick') {
        return 'excellent';
      } else if (this.result === 'precall-warning') {
        return 'ok';
      } else if (this.result === 'precall-error') {
        return 'poor';
      } else {
        return '';
      }
    },
  },
  methods: {
    aboutToShow() {
      this.$devicemanager
        .getDevices(this.devices.audioSource)
        .then((devices) => {
          if (devices.length) {
            this.mediaStore.setAudioOn(true);
            this.mediaStore.setAudioOutputOn(true);
            this.mediaStore.setVideoOn(this.mediaStore.isCameraPermissionGranted);
            this.mediaStore.updateDevices(devices);
            setTimeout(() => {
              this.mediaStore.setAudioSource(this.devices.audioSource);
              this.mediaStore.setAudioOutputSource(this.devices.audioOutputSource);
              this.mediaStore.setVideoSource(this.devices.videoSource);

              this.$connectivity.init({
                audioSource: this.audioSource || null,
                audioOutputSource: this.audioOutputSource || null,
                videoSource: this.videoSource || null,
                audioOn: this.audioOn,
                audioOutputOn: this.audioOutputOn,
                videoOn: this.videoOn,
              });
            }, 100);
          }
        })
        .catch((e) => {
          if (e === 'AUDIO_PERMISSION_DENIED') {
            utils.storage.ls.removeItem('mediaAllowed');
            const address = new URL(window.location.href);
            this.$router.push(`/allow?redirectUrl=${encodeURI(address.pathname + address.search)}`);
            return;
          }
          const noDevicesError = ['Requested device not found', 'The object can not be found here.'];
          if (noDevicesError.includes(e.message)) {
            this.$router.push('/please-use-media');
          }
        });
    },
    go() {
      if (this.isLoading) {
        return;
      }
      const dateVisited = Date.now();
      const meetingId = this.$route.params.id;

      nextTick(() => {
        sessionStorage.setStorageSync(`meetings:${meetingId}:dateVisited`, dateVisited);
      });

      this.$ee.emit('bus:bixe-user-devices:update', {
        audioSource: this.audioSource,
        audioOutputSource: this.audioOutputSource,
        videoSource: this.videoSource,
      });
      this.$ee.emit('bus:bixe-meeting-devices:update', meetingId, {
        audioSource: this.audioSource,
        audioOutputSource: this.audioOutputSource,
        videoSource: this.videoSource,
      });

      this.isLoading = true;

      this.$API.setAsJoined(meetingId).then(() => {
        this.stopTest(true);
      });
    },
    refresh() {
      location.reload();
      return false;
    },
    stopTest(dispose) {
      this.level = 0;
      this.testing = false;
      clearInterval(this.testMeterInterval);
      this.$connectivity.stopTest(dispose);
    },
    retest() {
      this.result = false;
      this.$connectivity.startTest();
    },
    cancelTest() {
      this.hideTest = true;
      this.stopTest(false);
    },
    startTest() {
      this.testing = true;
      this.result = false;
      var preCallTestProgress = 0;
      this.testMeterInterval = setInterval(() => {
        preCallTestProgress++;
        this.level = preCallTestProgress / this.TEST_DURATION_MAX;
        if (preCallTestProgress === this.TEST_DURATION_MAX) {
          clearInterval(this.testMeterInterval);
        }
      }, 100);
    },
    results(results) {
      clearInterval(this.testMeterInterval);
      this.testing = false;
      this.level = 1;
      this.result = results.classification;
    },

    onAudioChanged(deviceId) {
      this.$connectivity.updateSettings('audioSource', deviceId, this.audioOn);
    },
    onAudioOutputChanged(deviceId) {
      this.$connectivity.updateSettings('audioOutputSource', deviceId, this.audioOutputOn);
    },
    onVideoChanged(deviceId) {
      this.$connectivity.updateSettings('videoSource', deviceId, this.videoOn);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(h3) {
  padding-left: rem(42px);
}

:deep(.deviceSelect) {
  width: rem(262px);
}

.enterMeeting {
  margin-top: rem(31px);
}
.skeleton {
  background: var(--c__bg);
  position: absolute;
  z-index: 10;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .content {
    background: var(--c__bg);
    width: rem(262px);
    height: rem(392px + 96px);
    flex: 0 0 auto;
    min-height: 0;
    justify-content: flex-start;
  }
  &.noOutput .content {
    height: rem(371px + 96px);
  }

  @keyframes skeleton-pulse {
    0% {
      opacity: 0.3;
    }
    100% {
      opacity: 0.1;
    }
  }

  .skeletonItem {
    display: block;
    background: var(--c__text);
    animation: skeleton-pulse 1s ease infinite alternate;
  }

  .fakeVideo {
    border-radius: 50%;
    height: rem(262px);
    width: rem(262px);
  }

  .fakeDevice {
    width: 100%;
    height: rem(22px);
    border-radius: 0.25em;
    margin-top: rem(18px);
  }

  .fakeVideoIn {
    margin-top: rem(30px);
  }
  .failed {
    .fakeVideo {
      padding: rem(61px);
      color: red;
      path {
        fill: var(--c__text);
      }
    }
    p {
      margin: 0 rem(5px) rem(60px) rem(5px);
      @include type-size('medium');
      font-weight: 100;
      text-align: center;
    }
  }
}
</style>
