import { defineStore } from 'pinia';
import sidebarPanes from '@/resources/sidebarPanes';
import { usePageStore } from '@/store/pinia/page';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import _ from 'underscore';
import { nM } from '@/legacy';

function mappedPhase(p) {
  return nM.phaseMap[p];
}

export const useSidebarStore = defineStore('sidebar', {
  state() {
    return {
      currentPhase: 'init',
      trueView: '',
      useGuide: false,
      previous: false,
      tempPrevious: false,
      current: false,
      requested: false,
      base: false,
      pending: false,
      isEntering: false,
      isLeaving: false,
      isOpening: false,
      isClosing: false,
      isOpen: false,
      latestReason: false,
      latestSkillBar: {},
      phaseSource: 'microskills',
      notice: false,
      backToMenu: false,
      iCancelled: false,
      settingsPaneIsShowing: false,
    };
  },
  getters: {
    me() {
      const { me } = useUsersStore();
      return me;
    },
    sidebarOpen: (state) => state.current !== false,
    meetingMenuOpen: (state) => !!state.current?.inHamburger,
    isAnimating: (state) => state.isEntering || state.isLeaving || state.isOpening || state.isClosing,
    isVisible() {
      return this.sidebarOpen || this.isAnimating;
    },
    po3SettingBackTo() {
      let phaseStore = usePhaseStore();
      if (phaseStore.currentPhase === 'lobby') {
        return 'lobby';
      } else {
        return 'po3';
      }
    },
    isMeeting() {
      let pageStore = usePageStore();
      return pageStore.isMeeting;
    },
    isEnd() {
      let phaseStore = usePhaseStore();
      if (phaseStore.currentPhase === 'endMeeting') {
        return true;
      }
      if (phaseStore.currentPhase === 'finish') {
        return true;
      }

      return this.isMeeting ? false : true;
    },
    mustBeOpen() {
      let phaseStore = usePhaseStore();
      let usersStore = useUsersStore();

      if (phaseStore.currentPhase === 'ended') {
        return false;
      }
      if (usersStore.iAmInWaitingRoom) {
        return true;
      }
      if (phaseStore.currentPhase === 'lobby') {
        return true;
      }
      if (phaseStore.currentPhase !== 'freeform') {
        return true;
      }
      if (phaseStore.settingUp) {
        return true;
      }
      return this.isMeeting && this.useGuide;
    },
    currentName: (s) => s.current.name,
  },
  actions: {
    initMeeting(data, isResync) {
      this.useGuide = data.meeting.advancedSettings.guide;
      this.handleSkillBar(data.state, isResync);
      this.checkFields(data.state, isResync);
    },
    updatePhase(data) {
      this.handleSkillBar({ view: data.phase.module_id });
      this.backToMenu = false;
    },
    updateView(data) {
      let usersStore = useUsersStore();
      if (usersStore.iAmInWaitingRoom) {
        return;
      }
      if (data.view === 'freeform' && nM.setups[data.previous_view]) {
        if (this.backToMenu) {
          if (this.iCancelled) {
            this.iCancelled = false;
            return false;
          } else {
            this.requestPhaseSource();
          }
        } else {
          this.requestPane(false);
        }
      } else {
        this.iCancelled = false;
        this.handleSkillBar(data);
      }
    },
    checkFields({ fields }, isResync) {
      if (!isResync) {
        _.each(fields, (id, field) => {
          if (id === this.me.id) {
            this.requestPane(field, {}, 'locked');
          }
        });
      }
    },
    handleSkillBar(data, isResync) {
      let usersStore = useUsersStore();
      let setupFor = nM.setups[data.view];
      let phase = mappedPhase(data.view);

      if (phase === mappedPhase(this.trueView) && isResync) {
        return false;
      }

      this.trueView = data.view;

      if (phase === 'ended') {
        this.requestPane(false, { force: true, ending: true }, 'end of meeting');
        return false;
      }

      if (!phase) {
        this.requestPane(false);
        return false;
      }

      if (usersStore.iAmInWaitingRoom) {
        this.iAmInWaitingRoom();
        return true;
      }

      if (usersStore.iAmAway) {
        this.requestPane('skillBar', { phase: 'breakTime', barType: 'phase' }, 'on_update_view');
        return true;
      }

      if (usersStore.iAmAwayInGroup) {
        this.requestPane('skillBar', { phase: 'groups', barType: 'phase' }, 'on_update_view');
        return true;
      }

      if (setupFor) {
        this.requestPane('skillBar', { phase: setupFor, barType: 'setTime' }, 'on_update_view');
        return true;
      } else {
        this.currentPhase = phase;
      }

      if (phase === 'lobby') {
        this.requestPane('lobby', {}, 'on_update_view');
        return true;
      }
      if (phase === 'endMeeting') {
        this.requestPane('endBar', {}, 'on_update_view');
        return true;
      }

      if (phase === 'freeform') {
        if (this.useGuide) {
          this.requestPane('whatNext', {}, 'on_update_view');
        } else {
          this.requestPane(false, {}, 'on_update_view');
        }
        return true;
      }
      this.requestPane('skillBar', { phase: phase, barType: 'phase' }, 'on_update_view');
    },
    applySettings({ advancedSettings }) {
      this.useGuide = advancedSettings.guide;
      if (!this.useGuide && this.current.name === 'whatNext') {
        this.closeSidebar();
      }
    },
    updateAdvancedSettings({ name, value }) {
      if (name === 'guide') {
        this.useGuide = value;
        if (!value && this.current.name === 'whatNext') {
          this.closeSidebar();
        }
      }
    },

    requestPane(v, args = {}, why = '??') {
      // handle if you triggered the change yourself

      if (args.iStarted) {
        this.backToMenu = true;
      }

      // Track the skill bar details in case we need to show something else a while
      if (v === 'skillBar') {
        this.latestSkillBar = args;
      }

      let { force, ending } = args;

      // Make sure it is a real pane
      if (v && !sidebarPanes[v]) {
        console.log('[err] not a real sidebar pane');
      }

      this.latestReason = why;

      if (v) {
        this.requested = { ...sidebarPanes[v], ...args };
      } else {
        // make sure you con only close the sidebar if not in a meeting with a guide
        if (this.useGuide && this.isMeeting && !ending) {
          this.requested = { ...sidebarPanes.whatNext, ...args };
        } else {
          this.requested = false;
        }
      }

      // Prevent back-to loops
      if (!this.current.noBackTo && !args.resetBase) {
        this.previous = { ...this.current };
      }

      // Save an extra back for when you're looking at an overlay like settings
      if (this.requested.temp) {
        this.tempPrevious = { ...this.current };
      } else {
        this.tempPrevious = false;
      }

      // Clear where you go back to for some weird annoying cases
      if (args.resetBase) {
        this.previous = false;
        this.base = false;
      }

      // If ok, change the pane. If not, say we have one ready for when the current one is finished with, to prevent interrupts.
      if (!this.current.noInterrupt || force || this.requested.temp) {
        this.changePane(args);
        this.pending = false;
      } else {
        this.pending = true;
      }
    },
    changePane(args = {}) {
      if (this.requested) {
        if (this.requested.inHamburger && !this.current.inHamburger && !this.current.isEditPane && !args.resetBase && this.current.barType !== 'info') {
          this.base = this.current ? { ...this.current } : false; // Controls where we go back to when you close the hamburger menu.
        }
        this.current = { ...this.requested };

        // Set where to go back to after skillBar
        if (this.current.name === 'microskills') {
          this.phaseSource = 'microskills';
        }
        if (this.current.name === 'whatNext') {
          this.phaseSource = 'whatNext';
        }
      } else {
        this.current = false;
      }
    },
    finishedWithPane() {
      if (this.pending) {
        this.changePane();
      } else {
        this.requestBack({ force: true });
      }
    },
    requestPhaseSource(args = {}) {
      if (args.iCancelled) {
        this.iCancelled = true;
      }
      this.requestPane(this.phaseSource, { ...args, resetBase: true }, 'requestPhaseSource');
    },
    requestBack(args = {}) {
      if (this.current.backTo) {
        if (this.current.backTo === 'skillBar') {
          args = { ...this.latestSkillBar, ...args };
        }
        if (this.current.backTo === 'po3') {
          this.requestPane(this.po3SettingBackTo, { ...args, force: true }, 'back');
        } else {
          this.requestPane(this.current.backTo, { ...args, force: true }, 'back');
        }
      } else if (this.previous) {
        this.requestPane(this.previous.name, { ...this.previous, ...args }, 'back');
      } else {
        if (this.useGuide && this.inMeeting) {
          this.requestPane('whatNext', args, 'back');
        } else {
          if (this.currentPhase === 'lobby') {
            this.requestPane(false, args, 'back');
          } else if (this.current.backTo) {
            this.requestPane(this.current.backTo, args, 'back');
          } else {
            this.requestPane(false, args, 'back');
          }
        }
      }
    },
    closeHamburger() {
      if (this.tempPrevious) {
        let args = this.tempPrevious.name === 'skillBar' ? this.latestSkillBar : {};
        this.requestPane(this.tempPrevious.name, args, 'closingHamburger to tempPrevious');
        this.tempPrevious = false;
      } else if (this.base) {
        let args = this.base.name === 'skillBar' ? this.latestSkillBar : {};
        this.requestPane(this.base.name, args, 'closingHamburger to base');
      } else {
        this.requestPane(false, {}, 'closingHamburger to close');
      }
    },
    closeSidebar() {
      this.requestPane(false, { force: true }, 'closeSidebar');
    },
    meetingOver() {
      this.requestPane(false, { force: true, ending: true }, 'closeSidebar');
    },
    toggleSettings() {
      if (this.current.name === 'settings') {
        this.requestBack();
      } else {
        this.requestPane('settings');
      }
    },
    usersUnaway({ users }) {
      let usersStore = useUsersStore();
      if (users.filter((u) => usersStore.isMe(u.id)).length > 0) {
        _.delay(() => {
          this.handleSkillBar({ view: this.trueView });
        }, 10);
      }
    },
    usersReturned({ users }) {
      let usersStore = useUsersStore();
      if (users.filter((u) => usersStore.isMe(u.id)).length > 0) {
        _.delay(() => {
          this.handleSkillBar({ view: this.trueView });
        }, 10);
      }
    },
    updateNotice(v) {
      this.notice = v;
    },
    iAmOverdue() {
      if (this.current.name !== 'skillBar') {
        this.requestPane('skillBar', this.latestSkillBar, 'overdue');
      }
    },
    iAmInWaitingRoom() {
      this.requestPane('waitingRoom', this.latestSkillBar, 'waiting');
    },
    showSettingsPane() {
      this.settingsPaneIsShowing = true;
    },
    hideSettingsPane() {
      this.settingsPaneIsShowing = false;
    },
    setIsOpening(v) {
      this.isEntering = v;
    },
    setIsOpen(v) {
      this.isOpen = v;
    },
    setIsClosing(v) {
      this.isClosing = v;
    },
    setIsEntering(v) {
      this.isEntering = v;
    },
    setIsLeaving(v) {
      this.isLeaving = v;
    },
  },
});
