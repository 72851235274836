import { nM } from '@/legacy';
import bixeApi from '@/services/bixe-api.service';

export default () => async (to, from, next) => {
  const isPrInviteFlow = to.query.flow === 'pr-invite';
  if (isPrInviteFlow) {
    next();
    return;
  }
  const meetingId = to.query.meetingId || to.params.id;
  const pwd = to.query.pwd || false;
  try {
    const meeting = await bixeApi.getMeetingValidityInfo(meetingId);
    const isSafari = nM.detector.isSafari();
    const isFirefox = nM.detector.isFirefox();
    const { user, config } = window.__INITIAL_STATE__;
    const isAuthenticated = user && user.id;

    if (!isAuthenticated && meeting.pin_pass !== pwd) {
      return next('/join?reason=badLink');
    }

    if (meeting.status === 'finished') {
      return next(`/meeting-has-been-ended?id=${encodeURI(meetingId)}`);
    }

    if ((isSafari && !config.safari?.allowed) || (isFirefox && !config.firefox?.allowed)) {
      return next(`/use-app?id=${encodeURI(meetingId)}`);
    }

    return next();
  } catch (err) {
    if (err.error?.code === 'GuestUserAttemptedToJoinAnotherMeeting') {
      return next(`/joining-options?id=${encodeURI(meetingId)}&pwd=${encodeURI(pwd)}&flow=meeting-invite`);
    }
    return next('/join?reason=badLink');
  }
};
