<template>
  <section class="appDrawer avatarsDockDrawer">
    <avatars-frame v-if="hasActiveChimeConnection" />
    <avatar-dock class="avatar-dock-container" v-if="hasActiveChimeConnection" />
  </section>
</template>
<style lang="scss">
html {
  background: transparent !important;
  body {
    background: transparent;
    .avatar-dock-container {
      top: 45px !important;
    }
    .outerContainer {
      background: transparent;
      .background {
        background: transparent;
      }
    }
  }
}
</style>
<script setup>
import _ from 'underscore';
import { onMounted, getCurrentInstance, onBeforeMount, computed, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';

import AvatarsFrame from '@/components/AvatarsFrame';
import AvatarDock from '@/components/AvatarDock';

import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMomentsStore } from '@/store/pinia/moments';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { useFrameStore } from '@/store/pinia/frame';
import { usePageStore } from '@/store/pinia/page';

import utils from '@/resources/utils';
import bixeApi from '@/services/bixe-api.service';

const route = useRoute();
const frameStore = useFrameStore();
const meetingStore = useMeetingStore();
const mediaStore = useMediaStore();
const phaseStore = usePhaseStore();
const sidebarStore = useSidebarStore();
const momentsStore = useMomentsStore();
const usersStore = useUsersStore();
const timeStore = useTimeStore();
const pageStore = usePageStore();

const pxToRem = frameStore.pxToRem;
const props = defineProps(['pageName']);

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;
const $videomanager = getCurrentInstance().appContext.config.globalProperties.$videomanager;

const { positionDetails, pages, hide: isAvatarDockMinimized } = storeToRefs(usersStore);
const hasActiveSocketConnection = ref(false);
const hasActiveChimeConnection = ref(false);
const drawerState = ref({ dimension: { width: 205, height: 410 } });

const shouldDrawerBeVisible = computed(() => {
  const isChimeConnectionEstablished = hasActiveSocketConnection.value;
  const iHaveStartedMirroring = phaseStore.mirroring.isActive && usersStore.iAmMirroring;
  const iHaveStartedPresenting = mediaStore.videoSourceActive && usersStore.iAmPresenting;

  return isChimeConnectionEstablished && (iHaveStartedMirroring || iHaveStartedPresenting);
});
const pagerPad = computed(() => (pages.value.length > 1 ? pxToRem(28) : 0));

watch(shouldDrawerBeVisible, async (value) => {
  if (value && utils.isOpenedWithinElectronShell()) {
    await ipcApi.send({ name: 'showAvatarsDockDrawer', dimensions: { ...drawerState.value.dimension } });
  }
});

watch(isAvatarDockMinimized, (value) => {
  const dimensions = {
    width: value ? 40 : parseInt(drawerState.value.dimension.width),
    height: parseInt(drawerState.value.dimension.height + pagerPad.value + 100),
  };
  ipcApi.send({ name: 'updateAvatarsDockDrawerDimensions', dimensions });
});

watch(
  positionDetails,
  _.debounce(({ dockHeight }) => {
    if (!shouldDrawerBeVisible.value || isAvatarDockMinimized.value) {
      return;
    }
    if (drawerState.value.dimension.height === dockHeight) {
      return;
    }
    drawerState.value = { dimension: { width: 205, height: dockHeight } };
    ipcApi.send({
      name: 'updateAvatarsDockDrawerDimensions',
      dimensions: {
        width: parseInt(drawerState.value.dimension.width),
        height: parseInt(drawerState.value.dimension.height + pagerPad.value + 100),
      },
    });
  }, 750),
  { immediate: true },
);

onBeforeMount(async () => {
  pageStore.update({ pageName: props.pageName });
});

onMounted(() => {
  if (!utils.isOpenedWithinElectronShell()) {
    // avatar dock drawer should only be visible in bixe-native app
    return;
  }
  initializeMeetingAndVideoManager();
});

function initializeMeetingAndVideoManager() {
  $videomanager.on('ready', () => {
    $videomanager.startInViewOnlyMode();
    hasActiveChimeConnection.value = true;
  });
  Promise.all([bixeApi.getMeetingState(route.params.id), bixeApi.getMeetingSession(route.params.id, { isViewOnly: true })])
    .then(async ([meetingState, meetingSession]) => {
      const isResync = false;
      phaseStore.initMeeting(meetingState, isResync);
      usersStore.initMeeting(meetingState, isResync);
      meetingStore.initMeeting(meetingState, isResync);
      momentsStore.initMeeting(meetingState, isResync);
      sidebarStore.initMeeting(meetingState, isResync);
      timeStore.initMeeting(meetingState, isResync);
      mediaStore.initMeeting(meetingState, isResync);
      meetingStore.updateTitleCard(false);

      $meetingmanager.connect(meetingState, { avDeviceState: {} }, { requestResync: false, isDuplicateClient: true });
      $meetingmanager.once('connected', async () => {
        hasActiveSocketConnection.value = true;
        $videomanager.init(meetingSession, {
          externalAttendeeId: window.__INITIAL_STATE__?.user?.id,
          externalMeetingId: route.params.id,
          pageName: props.pageName,
        });
      });
    })
    .catch((err) => {
      console.error(err);
    });
}
</script>
