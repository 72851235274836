<template>
  <section class="advancedSettings page poster" data-page-name="advancedSettings">
    <div class="content">
      <h2>Advanced Settings</h2>
      <ul class="settings">
        <li>
          <label class="textLabel" for="lobbyToggle">Green Room</label>
          <appInfoBubble><strong>Green Room</strong><br />Gather before the meeting starts and set the meeting duration and purpose.</appInfoBubble>
          <AppToggle :modelValue="lobby" name="lobbyToggle" @change="publishUpdate('lobby', $event.target.checked)"> </AppToggle>
          <transition name="basic">
            <app-loading-spinner v-if="loadingLobby" :success="loadingLobby === 'success'" :fail="loadingLobby === 'fail'" />
          </transition>
        </li>
        <li>
          <label class="textLabel" for="guideToggle">Meeting Guide</label>
          <appInfoBubble
            ><strong>Meeting Guide</strong><br />For meetings with 4 or more participants activate a menu that encourages you to begin and end
            well.</appInfoBubble
          >
          <AppToggle :modelValue="guide" name="guideToggle" @change="publishUpdate('guide', $event.target.checked)"> </AppToggle>
          <transition name="basic">
            <app-loading-spinner v-if="loadingGuide" :success="loadingGuide === 'success'" :fail="loadingGuide === 'fail'" />
          </transition>
        </li>
        <li>
          <label class="textLabel" for="notesToggle">Meeting Notes</label>
          <appInfoBubble
            ><strong>Show Meeting Notes for everyone </strong><br />Turn on meeting notes so all participants can capture key moments.</appInfoBubble
          >
          <AppToggle :modelValue="notes" name="notesToggle" @change="publishUpdate('notes', $event.target.checked)"> </AppToggle>
          <transition name="basic">
            <app-loading-spinner v-if="loadingNotes" :success="loadingNotes === 'success'" :fail="loadingNotes === 'fail'" />
          </transition>
        </li>
        <li>
          <button class="textLabel" @click="showSpaces = true">Meeting Space</button>
          <appInfoBubble><strong>Default Meeting Space</strong><br />Choose the default meeting space for all your meetings.</appInfoBubble>
          <button class="currentSpace" title="change the space" @click="showSpaces = true" v-html="currentSpaceLabel"></button>
        </li>
      </ul>
      <aside>
        <transition name="basic" mode="out-in">
          <p v-if="error" class="error" key="error">Something went wrong, Please try again later</p>
        </transition>
        <transition name="basic" mode="out-in">
          <p v-if="!error" key="msg">Choose the default settings for all your meetings</p>
        </transition>
      </aside>
    </div>
    <transition name="basic">
      <div v-if="showSpaces" class="spaces poster">
        <div class="content">
          <h2>Choose Meeting Space</h2>
          <div class="spaceList">
            <label>
              <input
                class="visuallyHidden"
                type="radio"
                v-model="space"
                id="nowhere-space"
                value="nowhere"
                @change="publishUpdate('space', $event.target.value)"
              />
              <div class="thumb"><img :src="accountStore.baseUrl + spaces.nowhere.thumb" /></div>
              <span class="textLabel">
                <strong> {{ spaces.nowhere.title }} </strong>
              </span>
              <transition name="basic">
                <app-loading-spinner v-if="loadingSpace && space === 'nowhere'" :success="loadingSpace === 'success'" :fail="loadingSpace === 'fail'" />
              </transition>
            </label>
            <label>
              <input class="visuallyHidden" type="radio" v-model="space" id="cafe-space" value="cafe" @change="publishUpdate('space', $event.target.value)" />
              <div class="thumb"><img :src="accountStore.baseUrl + spaces.cafe.thumb" /></div>
              <span class="textLabel"> {{ spaces.cafe.title }} </span>
              <transition name="basic">
                <app-loading-spinner v-if="loadingSpace && space === 'cafe'" :success="loadingSpace === 'success'" :fail="loadingSpace === 'fail'" />
              </transition>
            </label>
            <label>
              <input class="visuallyHidden" type="radio" v-model="space" id="zen-space" value="zen" @change="publishUpdate('space', $event.target.value)" />
              <div class="thumb"><img :src="accountStore.baseUrl + spaces.zen.thumb" /></div>
              <span class="textLabel"> {{ spaces.zen.title }} </span>
              <transition name="basic">
                <app-loading-spinner v-if="loadingSpace && space === 'zen'" :success="loadingSpace === 'success'" :fail="loadingSpace === 'fail'" />
              </transition>
            </label>
            <label>
              <input class="visuallyHidden" type="radio" v-model="space" id="beach-space" value="beach" @change="publishUpdate('space', $event.target.value)" />
              <div class="thumb"><img :src="accountStore.baseUrl + spaces.beach.thumb" /></div>
              <span class="textLabel"> {{ spaces.beach.title }} </span>
              <transition name="basic">
                <app-loading-spinner v-if="loadingSpace && space === 'beach'" :success="loadingSpace === 'success'" :fail="loadingSpace === 'fail'" />
              </transition>
            </label>
            <label>
              <input class="visuallyHidden" type="radio" v-model="space" id="neon-space" value="neon" @change="publishUpdate('space', $event.target.value)" />
              <div class="thumb"><img :src="accountStore.baseUrl + spaces.neon.thumb" /></div>
              <span class="textLabel"> {{ spaces.neon.title }} </span>
              <transition name="basic">
                <app-loading-spinner v-if="loadingSpace && space === 'neon'" :success="loadingSpace === 'success'" :fail="loadingSpace === 'fail'" />
              </transition>
            </label>
          </div>

          <button class="backButton" @click="showSpaces = false">
            <app-icon icon="left-arrow" />
            <span class="visuallyHidden"> Back </span>
          </button>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import AppToggle from '@/components/AppToggle.vue';
import AppIcon from '@/components/AppIcon.vue';
import AppInfoBubble from '@/components/AppInfoBubble.vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner.vue';
import _ from 'underscore';
import spaces from '@/resources/spaces';
import utils from '../resources/utils';

export default {
  props: ['pageName'],
  components: {
    AppToggle,
    AppIcon,
    AppInfoBubble,
    AppLoadingSpinner,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      lobby: false,
      guide: false,
      notes: false,
      space: 'nowhere',
      spaces: spaces,
      showSpaces: false,
      error: false,
      loaders: {
        lobby: false,
        guide: false,
        notes: false,
        space: false,
      },
    };
  },
  created() {
    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'checkIsOnLatestVersion' });
    }
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
    this.lobby = this.accountStore.defaultMeetingSettings.lobby;
    this.guide = this.accountStore.defaultMeetingSettings.guide;
    this.notes = this.accountStore.defaultMeetingSettings.notes;
    this.space = this.accountStore.defaultMeetingSettings.space;
  },
  computed: {
    loadingLobby() {
      return this.loaders.lobby;
    },
    loadingGuide() {
      return this.loaders.guide;
    },
    loadingNotes() {
      return this.loaders.notes;
    },
    loadingSpace() {
      return this.loaders.space;
    },
    currentSpaceLabel() {
      if (this.space === 'nowhere') {
        return '<b>nowhere</b>';
      }

      return spaces[this.space].title;
    },
  },
  methods: {
    publishUpdate(prop, val) {
      this.error = false;
      let data = { ...this.accountStore.defaultMeetingSettings };
      let oldVal = this.accountStore.defaultMeetingSettings[prop];
      data[prop] = val;
      this[prop] = val;
      this.loaders[prop] = 'loading';

      this.$API
        .updateMeetingDefaultSettings({ meetingDefaultSettings: data })
        .then((resp) => {
          console.log('settings updated', resp);
          this.accountStore.updateDefaultMeetingSettings({ meetingDefaultSettings: data });
          this.loaders[prop] = 'success';
        })
        .catch((err) => {
          console.log('error in updating settings!!', err, prop, oldVal);

          this.loaders[prop] = 'fail';
          this.error = true;
          _.delay(() => {
            this[prop] = oldVal;
          }, 500);
        })
        .finally(() => {
          _.delay(() => {
            this.loaders[prop] = false;
          }, 1000);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
h2 {
  @include type-size(medium);
  font-weight: 300;
  text-align: center;
  margin-bottom: rem(47px);
}

aside {
  margin: rem(70px) auto 0 auto;
  text-align: center;
  max-width: rem(180px);
  font-weight: 200;
  min-height: 2em;
  position: relative;
  p {
    position: absolute;
    top: 0;
    margin: 0;
    width: 100%;
  }
}

.error {
  color: var(--c__warn);
}

.settings {
  li {
    width: rem(253px);
    @include type-size(medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  button {
    @include type-size(medium);
  }
  li + li {
    margin-top: 0.5em;
  }

  .loadingSpinner {
    color: var(--c__accent);
    position: absolute;
    top: 50%;
    right: -1.5em;
    margin-top: -0.5em;
    height: 1em;
    width: 1em;
  }

  .textLabel {
    text-align: left;
    display: block;
    width: rem(136px);
    flex: 0 0 rem(136px);
    padding: 0;
  }

  .info {
    margin-right: auto;
  }
  .currentSpace {
    text-align: right;
    display: block;
    padding: 0;
    font-weight: bold;
    color: var(--c__accent);
  }
  .textLabel {
  }
}

.backButton {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 2em 0 0;
  width: 3em;
  height: 2em;
  @include triggered {
    color: var(--c__accent);
  }
  .icon {
    width: 100%;
    height: 100%;
  }
}

.spaces {
  background: var(--c__bg);

  .content {
    position: relative;
    margin: 1em;
  }

  label {
    padding: 0 rem(9px);
    position: relative;
  }

  .spaceList {
    display: flex;
    flex-wrap: wrap;
    max-width: rem(700px);
    justify-content: center;
    gap: rem(27px);
    margin-top: 2em;
  }
  .thumb {
    position: relative;
    &::after {
      border: 6px solid var(--c__accent);
      position: absolute;
      top: rem(-3px);
      left: rem(-3px);
      right: rem(-3px);
      bottom: rem(-3px);
      display: block;
      content: '';
      opacity: 0;
      transition: all 0.3s;
    }
  }
  img {
    display: block;
    height: rem(118px);
    width: rem(158px);
  }
  input:checked + .thumb:after,
  label:hover .thumb:after {
    opacity: 1;
  }
  .textLabel {
    text-align: center;
    display: block;
  }
  .loadingSpinner {
    position: absolute;
    left: 50%;
    top: rem(59px);
    margin: -0.75em 0 0 -0.75em;
    color: var(--c__accent);
    background: var(--c__bg);
    padding: 0.25em;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
  }
}
</style>
