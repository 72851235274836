<template>
  <div ref="scroller" class="notesScroller">
    <Transition name="basic">
      <div v-if="loading && !error" class="placeholder poster">
        fetching notes&hellip;
        <app-loading-spinner />
      </div>
    </Transition>
    <Transition name="basic">
      <div v-if="!loading && error" class="placeholder error poster">
        Could not get notes&hellip;
        <button class="btn-solid btn-short btn-blue-flood" @click="getNotes">Try again</button>
      </div>
    </Transition>
    <Transition name="basic">
      <transition-group v-if="!loading && !error" name="basic" mode="out-in" tag="div">
        <transition-group
          v-if="hasNotes && !grouped && !isFeedback"
          key="ungrouped"
          class="noteList"
          name="staggered-fade"
          tag="ul"
          :css="false"
          @before-enter="beforeEnter"
          @enter="enter"
          @leave="leave"
        >
          <meeting-notes-entry
            v-for="(note, index) in notes"
            :key="note.id"
            :note="note"
            :index="index + 1"
            :count="notes.length"
            :class="[index + 1 === notes.length ? 'scrollTarget' : '']"
          />
        </transition-group>
        <meeting-notes-grouped v-if="grouped || isFeedback" key="grouped" />
        <div class="noNotes empty" v-if="!hasNotes && !isFeedback && !grouped" key="noNotes">
          <p>Capture a moment in the box below&hellip;</p>
        </div>
      </transition-group>
    </Transition>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import MeetingNotesGrouped from '@/components/MeetingNotesGrouped';
import MeetingNotesEntry from '@/components/MeetingNotesEntry';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import { useMomentsStore } from '@/store/pinia/moments';
import { useMeetingStore } from '@/store/pinia/meeting';

export default {
  components: {
    AppLoadingSpinner,
    MeetingNotesGrouped,
    MeetingNotesEntry,
  },
  props: ['notes', 'showing'],
  setup() {
    const momentsStore = useMomentsStore();
    const meetingStore = useMeetingStore();
    return { momentsStore, meetingStore };
  },
  data() {
    return {
      loading: false,
      error: false,
      emptyMessages: {
        action: `No Actions added.`,
        question: 'No Questions added.',
        decision: 'No Decisions added.',
        insight: 'No Insights added.',
        note: 'No Group notes added.',
      },
    };
  },
  computed: {
    ...mapPiniaState(useMomentsStore, ['barMode']),
    isFeedback() {
      return this.barMode === 'feedback';
    },
    grouped() {
      return this.momentsStore.isGrouped;
    },
    hasNotes() {
      return this.notes.length > 0;
    },
  },
  watch: {
    showing(show) {
      if (show) {
        this.getNotes();
        this.scroll();
      }
    },
    grouped(nv) {
      if (nv) {
        this.scroll();
      }
    },
  },
  methods: {
    getNotes() {
      this.loading = true;
      this.$API
        .getMeeting(this.meetingStore.meetingId, 'notes=1')
        .then((response) => {
          this.momentsStore.replaceAll(response.notes);
        })
        .catch((err) => {
          console.log('could not get notes', err);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    beforeEnter(el) {
      el.style.opacity = 0;
    },
    enter(el, done) {
      var delay = el.dataset.index * 150;
      setTimeout(function () {
        Velocity(el, { opacity: 1 }, { complete: done });
      }, delay);
    },
    beforeLeave(el) {
      el.style.position = 'absolute';
    },
    leave(el, done) {
      Velocity(el, { opacity: 0 }, { complete: done });
    },
    scroll() {
      if (this.grouped) {
        this.$refs.scroller.scrollTop = 0;
      } else {
        this.$refs.scroller.scrollTop = this.$refs.scroller.scrollHeight;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.placeholder {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  @include type-size(large);
  font-weight: 200;
  color: var(--c__placeholder);
  button {
    display: inline-block;
  }
}
</style>
