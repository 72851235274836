import { defineStore } from 'pinia';
import { eventEmitter } from '@/services/event-emitter.service';

export const usePageStore = defineStore('page', {
  state() {
    return {
      current: {},
      previous: {},
      animating: false,
    };
  },
  getters: {
    isMeeting: (state) => state.current.pageName === 'meetingFrame',
    isAvatarsDockDrawer: (state) => state.current.pageName === 'avatarsDockDrawer',
  },
  actions: {
    init() {},
    update(data) {
      this.previous = { ...this.current };
      this.current = { ...data };

      eventEmitter.emit('nM:updatePage', data);
    },
    startAnimating() {
      this.animating = true;
    },
    stopAnimating() {
      this.animating = false;
    },
  },
});
