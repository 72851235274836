<template>
  <div class="textarea autoTextarea wrapper" :class="[overClass, $attrs.class]">
    <textarea
      class="real"
      v-bind="attrs.listeners"
      v-focus="focus"
      v-model="currentValue"
      ref="real"
      :class="lateFocus ? 'focusPlease' : ''"
      :style="inputStyle"
      :maxLength="limit"
      :placeholder="placeholder"
      :aria-labelledby="labelledby"
      @focus="resize"
      @keydown="keydown"
      @keydown.enter.exact="$emit('enter')"
      @keyup.up="$emit('up')"
      @keyup="$emit('keyup')"
      @keyup.esc.prevent="$emit('cancel')"
    ></textarea>
    <textarea v-model="currentValue" class="shadow" ref="shadow" tabindex="-1" :maxLength="limit"></textarea>
  </div>
</template>

<script>
import Focus from '@/directives/focus.directive';
import _ from 'underscore';
import { nextTick } from 'vue';
export default {
  compatConfig: { COMPONENT_V_MODEL: false },
  inheritAttrs: false,
  directives: {
    focus: Focus,
  },
  props: {
    modelValue: {},
    limit: {
      type: Number,
      defaut: null,
    },
    placeholder: String,
    labelledby: {
      type: String,
      default: null,
    },
    lateFocus: {
      default: false,
    },
    focus: {
      default: undefined,
    },
  },
  data() {
    return {
      currentValue: this.modelValue,
      inputHeight: '29',
      resizeDelay: 0,
    };
  },
  mounted() {
    let _this = this;
    this.$ee.on('nM:resizeAutotext', _this.resize);
    nextTick(() => {
      this.resize();
      _.delay(this.resize, 1000);
    });
  },
  computed: {
    attrs() {
      const onRE = /^on[^a-z]/;
      const attributes = {};
      const listeners = {};
      const { $attrs } = this;

      for (const property in $attrs) {
        if (onRE.test(property)) {
          listeners[property] = $attrs[property];
        } else {
          attributes[property] = $attrs[property];
        }
      }

      return { attributes, listeners };
    },
    inputStyle() {
      return {
        'min-height': this.inputHeight,
      };
    },
    overClass() {
      if (!this.limit || (this.currentValue && this.currentValue.length <= this.limit)) {
        return 'ok';
      } else {
        return 'over';
      }
    },
  },
  watch: {
    currentValue(nv) {
      let delay = nv.endsWith('\n') && nv.endsWith('\n\n') ? this.resizeDelay : 0;
      _.delay(this.resize, delay);
      this.$emit('update:modelValue', this.currentValue);
    },
    modelValue(nv) {
      if (nv.endsWith) {
        let delay = nv.endsWith('\n') && nv.endsWith('\n\n') ? this.resizeDelay : 0;
        this.currentValue = this.modelValue;
        this.$nextTick(() => {
          _.delay(this.resize, delay);
          this.$emit('update:modelValue', this.currentValue);
        });
      }
    },
    focus(nv) {
      if (nv) {
        this.$refs.real.focus();
      }
    },
  },
  methods: {
    resize() {
      if (this.$refs.shadow) {
        this.inputHeight = `${this.$refs.shadow.scrollHeight}px`;
      }
    },
    keydown(e) {
      // Stop adding a carridge return if you hit enter to send
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  &.over {
    --border: var(--c__warn);
  }
}
textarea {
  width: 100%;
  padding: 0.5em 0;
  border: none;
  resize: none;
  overflow: hidden;
  // font-size: 16px;
  height: 0;
  transition: min-height 0.3s;
  margin-top: rem(10px);

  @include placeholder-content {
    color: var(--c__placeholder);
    font-weight: 200;
  }

  &:focus {
    outline: none;
  }

  &.shadow {
    max-height: 0;
    pointer-events: none;
    opacity: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
