<template>
  <div class="meetingTimer" :class="{ open: timerBarOpen }" :rel="relMinutes">
    <div v-if="showBar" class="bar" :style="{ width: barWidth }" :class="timerClass" />
    <div v-if="showBar" class="labels" :class="timerClass">
      <div class="elapsed">
        {{ elapsedText }}
      </div>
      <div class="remaining">
        {{ remainingText }}
      </div>
    </div>
    <button :disabled="!showBar" @click="showTimer">Show meeting timer</button>
  </div>
</template>

<script>
import { splitDuration, asUnit } from '@/resources/time-helpers';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useTimeStore } from '@/store/pinia/time';
import { useUsersStore } from '@/store/pinia/users';
import _ from 'underscore';

export default {
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    const sidebarStore = useSidebarStore();
    const timeStore = useTimeStore();
    const usersStore = useUsersStore();
    return { pageStore, meetingStore, sidebarStore, phaseStore, timeStore, usersStore };
  },
  data() {
    return {
      barWidth: '100%',
      throttledUpdateBarWidth: _.throttle(this.updateBarWidth, 1000),
    };
  },
  computed: {
    timerBarOpen() {
      return this.sidebarStore.current.name === 'meetingTime';
    },
    longerThanHour() {
      return this.timeStore.meetingLength >= 1000 * 60 * 60;
    },
    elapsedText() {
      let { hours, mins, secs } = splitDuration(this.timeStore.meetingElapsed);

      if (this.longerThanHour) {
        return `${hours}:${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
      } else {
        return `${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
      }
    },
    remainingText() {
      if (this.timeStore.meetingOverdue) {
        let { hours, mins, secs } = splitDuration(this.timeStore.meetingOverdueBy);

        if (this.longerThanHour) {
          return `-${hours}:${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
        } else {
          return `-${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
        }
      } else {
        let { hours, mins, secs } = splitDuration(this.timeStore.meetingRemaining);
        if (this.longerThanHour) {
          return `${hours}:${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
        } else {
          return `${mins >= 10 ? mins : '0' + mins}:${secs >= 10 ? secs : '0' + secs}`;
        }
      }
    },
    amountThroughMeeting() {
      if (!this.timeStore.meetingOverdue) {
        var ratio = this.timeStore.meetingElapsed / this.timeStore.meetingLength;
        return ratio;
      } else {
        return 1;
      }
    },
    outroLength() {
      let l = asUnit(this.timeStore.meetingLength, 'minutes');
      let out = 2;

      if (l > 120) {
        out = 20;
      } else if (l > 60) {
        out = 15;
      } else if (l > 30) {
        out = 10;
      } else if (l > 15) {
        out = 4;
      }

      return out;
    },
    showBar() {
      if (!this.pageStore.isMeeting) {
        return false;
      } else {
        return (
          this.phaseStore.currentPhase &&
          !['waiting', 'init', 'lobby', 'endMeeting'].includes(this.phaseStore.currentPhase) &&
          this.meetingStore.initTitleCardShown
        );
      }
    },
    timerClass() {
      if (this.timeStore.now > this.timeStore.meetingEnd) {
        return 'overdue';
      } else if (asUnit(this.timeStore.meetingRemaining, 'minutes') < this.outroLength) {
        return 'ending';
      } else {
        return 'running';
      }
    },
    relMinutes() {
      return Math.floor(asUnit(this.timeStore.meetingLength, 'minutes'));
    },
  },
  watch: {
    amountThroughMeeting(nv) {
      this.throttledUpdateBarWidth(nv);
    },
  },
  methods: {
    showTimer() {
      if (this.usersStore.iAmInWaitingRoom) return;
      this.sidebarStore.requestPane('meetingTime', { force: true });
    },
    updateBarWidth(ratio) {
      this.barWidth = 100 * ratio + '%';
    },
  },
};
</script>
<style lang="scss" scoped>
.meetingTimer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: rem($top-timer-expanded-height);
  background: transparent;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.5s, margin-right $sidebar-duration $sidebar-ease-general;

  button {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    z-index: 10;
    opacity: 0;
    width: 100%;
  }

  .sidebar-opening &,
  .sidebar-open & {
    margin-right: rem($sidebar-width);
  }

  .isFullScreen & {
    margin-right: 0;
  }

  &::before {
    content: '';
    display: block;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
    height: rem($top-timer-mini-height);
    background: var(--c__bg);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .meeting & {
    opacity: 1;
  }

  .lobby &,
  .endMeeting &,
  .endMeeting-animateIn & {
    opacity: 0;
  }

  .bar {
    position: absolute;
    left: 0;
    height: rem($top-timer-mini-height);
    top: 0;
    background: var(--c__time-ok);
    width: 100%;
    transform-origin: left;
    transition: height 0.3s ease, background-color 0.3s;
  }

  .bar.ending {
    background: var(--c__time-mid);
  }
  .bar.overdue {
    background: var(--c__time-over-light);
  }

  .labels {
    opacity: 0;
    transition: all 0.3s;
    color: var(--c__text-loud);
    & > * {
      position: absolute;
      top: 0;
      line-height: rem($top-timer-expanded-height);
    }
  }

  .labels.overdue {
    color: var(--c__bg);
  }

  .elapsed {
    left: 0.5em;
  }

  .remaining {
    right: 0.5em;
  }

  &:hover,
  &.open {
    .labels {
      opacity: 1;
    }
    &::before {
      opacity: 1;
      height: rem($top-timer-expanded-height);
    }
    .bar {
      height: rem($top-timer-expanded-height);
    }
  }
}
</style>
