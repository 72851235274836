<template>
  <section class="appComponent useAppComponent poster">
    <h2>Experience the bixe desktop app</h2>
    <aside>
      <img class="sampleUsers" id="imgSampleUsers" :src="accountStore.baseUrl + '/assets/images/sample-users.png'" />
      <div id="useAppTable" class="table">&nbsp;</div>
    </aside>
    <div class="actions">
      <browser-guarded-button class="btn btn-border btn-mid-border btn-dark-blue-flood" @click="onLaunchBrowser">Open in browser</browser-guarded-button>

      <a :href="downloadLink" @click="downloadAppClickHandler()" download class="btn btn-solid btn-loud-blue btn-mid-border btn-dark-blue-flood">
        Download the app
      </a>
    </div>
    <div class="launchApp">
      <span>Already have the bixe app? <a :href="appLink" class="btn-link" @click="onLaunchApp"> Launch it now </a> </span>
    </div>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import utils from '@/resources/utils';

export default {
  props: {
    meetingId: String,
    appLink: String,
    webLink: String,
  },
  components: {
    BrowserGuardedButton,
  },
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
  data() {
    return {
      showSafariTip: false,
    };
  },
  computed: {
    downloadLink() {
      const platform = window.navigator.platform;
      if (platform.match(/mac/gi)) {
        return 'https://downloads.now-here.com/executables/latest/BIXE.dmg';
      }
      return 'https://downloads.now-here.com/executables/latest/BIXE%20Setup.exe';
    },
  },
  methods: {
    onLaunchApp() {
      this.setPlatformInvitationViewed();
      this.$emit('launched');
    },
    onLaunchBrowser() {
      this.setPlatformInvitationViewed();
      this.$router.push(this.webLink).catch(() => {});
    },
    downloadAppClickHandler() {
      this.setPlatformInvitationViewed();
      this.$router.push(`/app/downloading?${this.meetingId ? `id=${this.meetingId}` : ''}`);
    },
    setPlatformInvitationViewed() {
      utils.storage.ls.setItem('platform.invitationViewed', Date.now());
    },
  },
};
</script>
<style lang="scss" scoped>
section.appComponent {
  h2 {
    margin-bottom: rem(36px);
    @include type-size('large');
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sampleUsers {
  width: rem(325px);
}

.table {
  background-image: var(--bg__table-meeting);
  height: rem(96px);
  margin-top: 0;
}
.actions {
  margin: rem(41px) auto rem(37px) auto;
}
</style>
