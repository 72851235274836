<template>
  <div class="buttonWrapper">
    <button :title="title" :class="buttonClass" :disabled="disabled" @click="realClick" ref="button">
      <slot></slot>
    </button>
    <div v-if="disabled" class="fakeClick" @click="fakeClick" aria-hidden="true"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps(['disabled', 'wobble', 'buttonClass', 'title', 'blurOnClick']);
const emit = defineEmits(['real-click', 'disabled-click']);

const button = ref(null);

function realClick() {
  if (props.blurOnClick) {
    button.value.blur();
  }
  emit('real-click');
}
function fakeClick() {
  if (props.wobble) {
    Velocity(button.value, 'callout.swing', { duation: 300 });
  }
  emit('disabled-click');
}
</script>
<style lang="scss" scoped>
.buttonWrapper {
  position: relative;
}
button {
  z-index: 1;
}
.fakeClick {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  opacity: 0;
  z-index: 2;
}
</style>
