<template>
  <div class="cafe poster">
    <transition name="slowFade">
      <div class="wall poster" v-if="hasWall"></div>
    </transition>
    <transition name="slowFade" v-if="meetingStore.initTitleCardShown && usersStore.firstShown">
      <div class="propsFrame poster">
        <avatars-sofa />
        <transition name="basic">
          <div v-if="hasCoffeeTable" class="coffeeTable" :style="tableStyle"></div>
        </transition>
        <transition name="basic">
          <div v-if="hasGridBg" class="gridBg">
            <div class="sofa"></div>
            <div class="table"></div>
            <div class="plant"></div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import AvatarsSofa from '@/components/AvatarsSofa';
export default {
  components: {
    AvatarsSofa,
  },

  setup() {
    const frameStore = useFrameStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { frameStore, meetingStore, phaseStore, usersStore };
  },
  data() {
    return {
      interval: null,
    };
  },
  mounted() {},
  computed: {
    ...mapPiniaState(useUsersStore, {
      userPositions: 'positions',
      positionDetails: 'positionDetails',
      usersVisible: 'usersVisible',
    }),
    hasCoffeeTable() {
      return this.positionDetails?.layoutName === 'couch' || this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom;
    },
    hasGridBg() {
      if (this.positionDetails?.layoutName === 'couch' || this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom) {
        return false;
      } else if (['waiting', 'lobby', 'endMeeting', 'finish', 'ended', 'input'].includes(this.phaseStore.currentPhase) || this.phaseStore.isPresentation) {
        return false;
      } else {
        return true;
      }
    },
    hasWall() {
      if (this.usersVisible.length < 3) {
        return false;
      }
      if (['waiting', 'lobby', 'endMeeting', 'finish', 'ended'].includes(this.phaseStore.currentPhase)) {
        return false;
      }
      return true;
    },
    tableStyle() {
      let css = {
        bottom: this.meetingStore.useNotes ? this.frameStore.pxToRem(100) + 'px' : this.frameStore.pxToRem(30) + 'px',
        top: 'auto',
      };

      if (this.usersStore.iAmAway || this.usersStore.iAmInWaitingRoom) {
        css.left = '70%';
      }
      return css;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.cafe {
}
.wall {
  background-image: linear-gradient(to top, rgba(216, 216, 216, 0) 0, var(--c__bg) rem(255px), var(--c__bg-0) rem(255px));
  background-color: var(--c__bg-alt);
  position: absolute;
  z-index: 0;
}
.coffeeTable {
  position: absolute;
  left: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  background-image: url($base-url + '/assets/images/spaces/cafe/coffee-table.svg');
  z-index: 100;
  width: 100%;
  pointer-events: none;
  transform: translateX(-50%);
  width: math.div(282, $design-height) * 100vh;
  width: calc(var(--vh, 1vh) * (282 / #{$design-height}) * 100);
  height: math.div(260, $design-height) * 100vh;
  height: calc(var(--vh, 1vh) * (260 / #{$design-height}) * 100);
  display: block;
  transition: all 0.5s cubic-bezier(0.45, 0, 0.55, 1);
  &:before {
    position: absolute;
    content: '';
    bottom: 0px;
    height: 5px;
    background: var(--c__shadow-opaque);
    box-shadow: 0 0 10px var(--c__shadow-opaque), 0 0 5px var(--c__shadow-opaque), 0 0 3px var(--c__shadow-opaque);
    left: -1em;
    right: -1em;
    border-radius: 50%;
  }

  @include transition-optional;
}

.gridBg {
  position: absolute;
  width: 100%;
  bottom: rem(250px);
  top: 0;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  gap: 1em;
  & > div {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center bottom;
  }
  .sofa {
    flex: 0 1 rem(435px);
    background-image: url($base-url + '/assets/images/spaces/cafe/sofa-basic.svg');
  }
  .table {
    flex: 0 1 rem(197px);
    background-image: url($base-url + '/assets/images/spaces/cafe/coffee-table.svg');
    margin: auto 0 0 0;
  }
  .plant {
    flex: 0 1 rem(188px);
    background-image: url($base-url + '/assets/images/spaces/cafe/potplant.svg');
  }
}

.propsFrame {
  @include sidebar-response;
}
</style>
