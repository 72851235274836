<template>
  <section class="appComponent useAppComponent">
    <h2>Launch bixe meeting</h2>
    <div class="actions">
      <browser-guarded-button class="btn btn-border btn-mid-border btn-dark-blue-flood" @click="onOpenAppInBrowser">Open in browser</browser-guarded-button>
      <a :href="appLink" @click="onLaunchApp" class="btn btn-solid btn-loud-blue btn-mid-border btn-dark-blue-flood" ref="appLauncher">Open the app</a>
    </div>
    <div class="platformPreference">
      <input type="checkbox" id="platformPreference" v-model="preference" @change="onPrefChanged()" />
      <label for="platformPreference">Always open the app</label>
    </div>
    <div class="download">
      <span>Don't have the bixe app? <a :href="downloadLink" @click="downloadAppClickHandler()" class="btn-link"> Download it now </a> </span>
    </div>
  </section>
</template>

<script>
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import utils from '@/resources/utils';

export default {
  props: {
    meetingId: String,
    appLink: String,
    webLink: String,
  },
  components: {
    BrowserGuardedButton,
  },
  watch: {
    appLink: {
      immediate: true,
      handler: function (nv) {
        if (!nv || nv === '#') {
          return;
        }
        if (this.preference) {
          setTimeout(() => {
            // intentional delay of 1 second,
            // without this delay the click handler fires
            // but doesn't open the link
            this.$refs.appLauncher?.click();
          }, 1000);
        }
      },
    },
  },
  data() {
    return {
      preference: utils.storage.ls.getItem('platform.preferred-v2') === 'app',
      showSafariTip: false,
    };
  },
  computed: {
    downloadLink() {
      const platform = window.navigator.platform;
      if (platform.match(/mac/gi)) {
        return 'https://downloads.now-here.com/executables/latest/BIXE.dmg';
      }
      return 'https://downloads.now-here.com/executables/latest/BIXE%20Setup.exe';
    },
  },
  methods: {
    onLaunchApp() {
      if (this.preference) {
        utils.storage.ls.setItem('platform.preferred-v2', 'app');
      }
      this.$emit('launched');
    },
    downloadAppClickHandler() {
      this.$router.push(`/app/downloading?id=${this.meetingId || ''}`);
    },
    onOpenAppInBrowser() {
      this.$router.push(this.webLink).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
section.appComponent {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: rem(12px);
    font-size: rem(24px);
    text-align: center;
    margin-top: auto;
  }
}
.platformPreference {
  text-align: center;
  margin-bottom: rem(36px);
  input {
    margin-right: 0.5em;
  }
}
.actions {
  margin: rem(50px) 0 rem(36px);
}
.download {
  width: 100%;
  text-align: center;
  margin: auto 0 2em 0;
}
</style>
