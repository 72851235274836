<template>
  <div class="info">
    <button :class="[showBubble ? 'showing' : '']" @click="showBubble = !showBubble" @keyup.space="(e) => e.preventDefault()">
      <span class="visuallyHidden">Show more Information</span>
      <span class="icon">i</span>
    </button>
    <bubble-tip direction="below" :show="showBubble" @close="showBubble = false">
      <slot></slot>
    </bubble-tip>
  </div>
</template>

<script>
import BubbleTip from '@/components/BubbleTip.vue';

export default {
  components: {
    BubbleTip,
  },
  props: {},
  data() {
    return {
      showBubble: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.info {
  position: relative;
  height: rem(13px);
  width: rem(13px);
}
:deep(.message) {
  text-align: left;
  @include type-size('small');
}
:deep(.bubbleTip) {
  padding: rem(15px) rem(19px);
}
.info > button {
  height: rem(13px);
  width: rem(13px);
  font-size: rem(11px);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c__bg);
  color: var(--c__text);
  border: 1px solid currentColor;
  border-radius: 50%;
  &:hover,
  &.showing {
    color: var(--c__accent);
  }
}
</style>
