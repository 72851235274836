<template>
  <div class="hamburgerMenu pane">
    <transition-group name="basic" tag="ul">
      <hamburger-menu-sidebar-item
        key="microskills"
        icon="microskills"
        name="microskillsMenu"
        :is-disabled="
          microskillsDisabled ||
          (bubbleShowing &&
            !['noSkillsUntilMeetingStarted', 'needToEndFirst', 'microskillsMeetingOnly', 'microSkillsDisabledCauseMirroring'].includes(bubbleShowing))
        "
        @clicked="request('microskills')"
        @nope="failedClickMicroskills"
      >
        Meeting Skills
        <template #tips>
          <bubble-tip
            name="noSkillsUntilMeetingStarted"
            :show="bubbleShowing === 'noSkillsUntilMeetingStarted'"
            direction="below"
            @close="bubbleShowing = false"
          >
            You can only access the Meeting Skills once the meeting has started
          </bubble-tip>
          <bubble-tip name="needToEndFirst" :show="bubbleShowing === 'needToEndFirst'" direction="below" @close="bubbleShowing = false">
            You need to end the current phase before starting a new one.
          </bubble-tip>
          <bubble-tip name="microskillsMeetingOnly" :show="bubbleShowing === 'microskillsMeetingOnly'" direction="below" @close="bubbleShowing = false">
            You can only access the Meeting Skills during the meeting.
          </bubble-tip>
          <bubble-tip
            name="microSkillsDisabledCauseMirroring"
            :show="bubbleShowing === 'microSkillsDisabledCauseMirroring'"
            direction="below"
            @close="bubbleShowing = false"
          >
            Please wait for {{ mirroring.first_name }} to finish before starting a new phase.
          </bubble-tip>
        </template>
      </hamburger-menu-sidebar-item>

      <hamburger-menu-sidebar-item
        key="spaces"
        icon="spaces"
        name="spaces"
        v-if="usersStore.iAmModerator"
        @clicked="request('spaces')"
        :is-disabled="phaseStore.currentPhase === 'endMeeting' || (bubbleShowing && !['tooLateForSpaces', 'notesMeetingOnly'].includes(bubbleShowing))"
        @nope="bubbleShowing = 'tooLateForSpaces'"
      >
        Spaces
        <template #tips>
          <bubble-tip name="tooLateForSpaces" :show="bubbleShowing === 'tooLateForSpaces'" direction="below" @close="bubbleShowing = false">
            You can only access Spaces during the meeting.
          </bubble-tip>
        </template></hamburger-menu-sidebar-item
      >

      <hamburger-menu-sidebar-item
        key="meetingTime"
        icon="meeting-time"
        name="meetingTime"
        :is-disabled="timerDisabled || (bubbleShowing && !['noTimerUntilMeetingStarted', 'timerMeetingOnly'].includes(bubbleShowing))"
        @clicked="request('meetingTime')"
        @nope="failedClickTimer"
      >
        Timer
        <template #tips>
          <bubble-tip name="noTimerUntilMeetingStarted" :show="bubbleShowing === 'noTimerUntilMeetingStarted'" direction="below" @close="bubbleShowing = false">
            You can only access the Meeting Timer once the meeting has started
          </bubble-tip>
          <bubble-tip name="timerMeetingOnly" :show="bubbleShowing === 'timerMeetingOnly'" direction="below" @close="bubbleShowing = false">
            You can only access the Meeting Timer during the meeting.
          </bubble-tip>
        </template>
      </hamburger-menu-sidebar-item>

      <hamburger-menu-sidebar-item
        key="purpose"
        icon="po3"
        name="purpose"
        :is-disabled="phaseStore.currentPhase === 'endMeeting' || (bubbleShowing && !['po3MeetingOnly', 'notesMeetingOnly'].includes(bubbleShowing))"
        @clicked="request('po3')"
        @nope="failedPo3"
      >
        Purpose
        <template #tips>
          <bubble-tip name="po3MeetingOnly" :show="bubbleShowing === 'po3MeetingOnly'" direction="below" @close="bubbleShowing = false">
            You can only access the PO3 during the meeting.
          </bubble-tip>
        </template>
      </hamburger-menu-sidebar-item>

      <hamburger-menu-sidebar-item key="reactions" icon="smile-control" name="reactions" @clicked="request('emoteGrid')">
        Reactions
      </hamburger-menu-sidebar-item>

      <hamburger-menu-sidebar-item
        key="invite"
        icon="invite"
        name="invite"
        :is-disabled="phaseStore.currentPhase === 'endMeeting' || (bubbleShowing && !['invite', 'inviteMeetingOnly'].includes(bubbleShowing))"
        @nope="bubbleShowing = 'inviteMeetingOnly'"
        @clicked="bubbleShowing = phaseStore.currentPhase === 'endMeeting' ? 'inviteMeetingOnly' : 'invite'"
      >
        Invite
        <template #tips>
          <bubble-tip name="invite" :show="bubbleShowing === 'invite'" @close="bubbleShowing = false"> Invite colleagues </bubble-tip>
          <bubble-tip name="inviteMeetingOnly" :show="bubbleShowing === 'inviteMeetingOnly'" @close="bubbleShowing = false">
            You can only invite people during the meeting.
          </bubble-tip>
        </template>
      </hamburger-menu-sidebar-item>

      <hamburger-menu-sidebar-item
        key="toggleNotes"
        icon="toggle-notes"
        name="toggleNotes"
        @clicked="toggleNotes"
        :is-disabled="timerDisabled || (bubbleShowing && !['notesMeetingOnly'].includes(bubbleShowing))"
        @nope="bubbleShowing = 'notesMeetingOnly'"
        >{{ notesToggleLabel }}
        <template #tips>
          <bubble-tip name="notesMeetingOnly" :show="bubbleShowing === 'notesMeetingOnly'" direction="above" @close="bubbleShowing = false">
            You can only access the Notes once the meeting has started.
          </bubble-tip>
        </template>
      </hamburger-menu-sidebar-item>
    </transition-group>
    <span v-if="isOpenedWithinElectronShell" class="needHelp" @click="openExternal('https://www.bixe.ai/support.html')">Need help?</span>
    <a v-else class="needHelp" href="https://www.bixe.ai/support.html" target="_blank">Need help?</a>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import HamburgerMenuSidebarItem from '@/components/HamburgerMenuSidebarItem';
import BubbleTip from '@/components/BubbleTip.vue';
import utils from '../resources/utils';

export default {
  components: {
    HamburgerMenuSidebarItem,
    BubbleTip,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const sidebarStore = useSidebarStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { meetingStore, sidebarStore, phaseStore, usersStore };
  },
  data() {
    return {
      interrupted: false,
      bubbleShowing: false,
      showNeedsMeetingStartMicroskill: false,
      showNeedsMeetingStartTimer: false,
      phaseRunning: false,
      showInvite: false,
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['mirroring']),
    microskillsDisabled() {
      return this.phaseStore.currentPhase !== 'freeform' || this.phaseStore.settingUp || !!this.phaseStore.mirroring?.requestedBy;
    },
    timerDisabled() {
      return ['waiting', 'lobby', 'endMeeting'].includes(this.phaseStore.currentPhase);
    },
    notesToggleLabel() {
      if (this.meetingStore.useNotes && (!this.meetingStore.personalNotesSetting || this.meetingStore.personalNotesSetting === 'show')) {
        return 'Hide Notes';
      } else {
        return 'Show Notes';
      }
    },
  },
  methods: {
    request(what) {
      if (what === 'po3' && this.phaseStore.currentPhase === 'lobby') {
        this.sidebarStore.requestPane('lobby', {}, 'hamburgerMenu');
      } else {
        this.sidebarStore.requestPane(what, {}, 'hamburgerMenu');
      }
    },
    failedClickMicroskills() {
      if (this.phaseStore.currentPhase === 'lobby') {
        this.bubbleShowing = 'noSkillsUntilMeetingStarted';
      } else if (this.phaseStore.currentPhase === 'endMeeting') {
        this.bubbleShowing = 'microskillsMeetingOnly';
      } else if (this.phaseStore.mirroring?.requestedBy) {
        this.bubbleShowing = 'microSkillsDisabledCauseMirroring';
      } else {
        this.bubbleShowing = 'needToEndFirst';
      }
    },
    failedClickTimer() {
      if (this.phaseStore.currentPhase === 'lobby') {
        this.bubbleShowing = 'noTimerUntilMeetingStarted';
      } else if (this.phaseStore.currentPhase === 'endMeeting') {
        this.bubbleShowing = 'timerMeetingOnly';
      }
    },
    failedPo3() {
      if (this.phaseStore.currentPhase === 'endMeeting') {
        this.bubbleShowing = 'po3MeetingOnly';
      }
    },
    toggleNotes() {
      this.$meetingmanager.updateAdvancedSetting('notes', !this.meetingStore.useNotes);
    },
    openExternal(url) {
      ipcApi.send({ name: 'openExternal', url: url, closeSelf: false });
    },
  },
};
</script>
<style lang="scss" scoped>
.pane {
  align-items: center;
  justify-content: center;
  ul {
    list-style: none;
    padding-top: rem(87px);
    margin: auto;
  }

  :deep(li + li) {
    margin-top: rem(36px);
  }

  :deep(li) {
    width: 100%;
  }

  .needHelp {
    padding: rem(34px) 0;
    color: var(--c__text);
    cursor: pointer;
    @include triggered {
      color: var(--c__accent);
      outline: none;
      outline-color: transparent;
      outline-style: none;
    }
  }
}

:deep(.hamburgerItem) {
  position: relative;

  &.disabled * {
    pointer-events: none;
  }

  button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    & > .icon,
    & > .text {
      transition: opacity 0.5s;
    }
  }

  & > button {
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }
  }

  button[disabled] {
    & > .icon,
    & > .text {
      opacity: 0.25;
    }
    @include triggered {
      color: var(--c__text);
    }
  }

  .text {
    line-height: 1;
    margin-top: rem(11px);
  }

  .text,
  svg {
    display: block;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .microskills-icon svg {
    width: rem(34px);
    height: rem(34px);
  }

  .spaces-icon svg {
    width: rem(32px);
    height: rem(22px);
  }
  .meeting-time-icon svg {
    width: rem(32px);
    height: rem(32px);
  }

  .po3-icon svg {
    width: rem(28px);
    height: rem(26px);
  }

  .smile-control-icon svg {
    width: rem(32px);
    height: rem(32px);
  }

  .invite-icon svg {
    width: rem(26px);
    height: rem(30px);
  }
  .toggle-notes-icon svg {
    width: rem(34px);
    height: rem(34px);
  }
}
li.exit {
  list-style: none;
}
</style>
