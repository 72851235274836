<template>
  <section class="page error-page 404" data-page-name="404">
    <div class="content">
      <h1>AAGGGGHH!</h1>
      <p>Sorry the page you are looking for is <strong>nowhere</strong> to be found.</p>
      <a :href="accountStore.baseUrl" @click.prevent="back">Go to homepage</a>
    </div>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';

export default {
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { accountStore, pageStore };
  },

  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
  methods: {
    back() {
      let url = this.accountStore.isLoggedIn ? '/welcome' : '/join';
      this.$router.push(url).catch(() => null);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  text-align: center;
  align-items: center;
  .content {
    padding: 1em;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
h1 {
  font-size: rem(99px);
  font-weight: bold;
  line-height: normal;
  margin-bottom: rem(27px);
}
p,
a {
  @include type-size(x-large);
  font-weight: 100;
}

p {
  max-width: 18em;
  margin: 0 auto;
}

a {
  margin-top: 1em;
  color: var(--c__text);
  text-decoration: underline;
  @include triggered {
    color: var(--c__accent);
  }
}
</style>
