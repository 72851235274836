<template>
  <div class="flipHome pane agendaSidebar meetingTimeBar">
    <section class="flipContent">
      <header>
        <app-icon icon="meeting-time" />
        <h2>Meeting timer</h2>
        <h3>Using your time well</h3>
        <p>
          Here's how much time you have left for this meeting. You can give yourself more time if you need it. Make sure you allow enough time to end your
          meeting well.
        </p>
      </header>

      <timer-disc
        v-bind="$attrs"
        id="meetingTimePaneTimer"
        type="meeting"
        :current="timeStore.meetingElapsed"
        :total="timeStore.meetingLength"
        :remaining="timeStore.meetingRemaining"
        showing="true"
        view-only="true"
        class="setMeetingTime"
      />
      <div key="buttons" class="phaseButtonContainer">
        <div id="skillBarControls" class="phaseControls btn-pair">
          <button :class="{ halfButton: iAmModerator }" class="moreTime" key="moreTime" ref="moreTime" @click="handleMoreTime">More Time</button>
          <button v-if="iAmModerator" class="halfButton end" key="end" ref="end" @click="handleEnd">End</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup>
import { getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useTimeStore } from '@/store/pinia/time';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';
import TimerDisc from '@/components/TimerDisc.vue';
import AppIcon from '@/components/AppIcon.vue';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const timeStore = useTimeStore();
const sidebarStore = useSidebarStore();
const usersStore = useUsersStore();
const { iAmModerator } = storeToRefs(usersStore);

function handleMoreTime() {
  let baseDate = timeStore.meetingOverdue ? new Date() : new Date(timeStore.meetingEnd);
  let newEnd = new Date(baseDate.getTime() + 300000);

  $meetingmanager.updateMeeting({
    ends_on: newEnd.getTime() / 1000,
    trigger: 'meetingTimeSidebar',
  });
  $ee.emit('nM:addTimeLocal');
}

function handleEnd() {
  if (iAmModerator.value) {
    $ee.emit('nM:meeting:finish');
  } else {
    $ee.emit('nM:meeting:fade');
    sidebarStore.closeSidebar();
    $ee.emit('nM:meeting:leave');
  }
}
</script>
<style lang="scss" scoped>
.meetingTimeBar {
  header {
    margin: rem(85px) rem(29px) auto rem(29px);
    h3,
    p {
      line-height: math.div(17, 14);
    }
  }

  .meeting-time-icon :deep(svg) {
    height: rem(32px);
    width: rem(32px);
    display: block;
  }

  h2 {
    @include type-size(medium);
    font-weight: bold;
    margin: 1em 0 rem(12px) 0;
    line-height: math.div(22, 18);
  }
  h3 {
    font-weight: bold;
  }

  .sidebarTimer {
    margin-bottom: auto;
    padding: rem(18px) 0;
  }

  :deep(.phaseButtonContainer) {
    margin: rem(50px) rem(34px) rem(30px) rem(34px);
  }

  :deep(.phaseControls) {
    --c__button: var(--c__text-loud);
    border-width: 2px;
    height: rem(50px);
  }
}
</style>
