<template>
  <div class="subContent">
    <img class="screenshareExample" :src="accountStore.baseUrl + '/assets/images/screenshare-guide.gif'" />
    <h2>Sharing your screen</h2>
    <p>
      If you use bixe on a Mac you may need to adjust your system preferences to share your screen.
      <a href="https://intercom.help/nowhere-group-ltd/en/articles/8570668-enable-screen-sharing-in-bixe-meetings-mac-os" target="_blank" rel="noopener"
        >Find out how to do this&hellip;</a
      >
    </p>
    <button class="btn btn-border btn-medium btn-blue-flood" @click="moveToNextStep()">Finish</button>
  </div>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
export default {
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  data() {
    return {
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkComplete,
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
    this.$readinessChecker.markAsStarted(this.pageName).markAsCompleted({ subPageName: this.pageName, isPassed: true });
    this.$videomanager.stop();
    /*
    Not needed as of now, the primary purpose of this page is to convey this information,
    we are not required to check screen sharing capability from this screen
    setTimeout(() => {
      //setting a delay for 2 seconds before showing (brower's default) screen share connnectivity prompt
      this.$readinessChecker.checkContentShareConnectivity().then((resp) => {
        this.$readinessChecker.markAsCompleted({ subPageName: this.pageName, isPassed: resp.status });
      });
    }, 2000);
    */
  },
  methods: {
    moveToNextStep() {
      this.$readinessChecker.moveToNextStep({ nextPageName: this.nextPageName });
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  width: rem(350px);
  height: auto;
  margin-bottom: rem(24px);
}
p {
  max-width: 22em;
  a {
    display: block;
    font-weight: bold;
  }
}
img {
  width: rem(351px);
}
</style>
