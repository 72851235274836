<template>
  <form ref="box" class="editBox" @submit.prevent="send">
    <app-auto-textarea v-model="editText" class="input" ref="input" :focus="focus" @enter="send" @cancel="cancel" />
    <p class="">
      Press enter to <button class="btn-borderless" @click.prevent="debouncedSend" :disabled="sending">save</button> or esc to
      <button class="btn-borderless" @click.prevent="cancel">cancel</button>
    </p>
  </form>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import utils from '../resources/utils';
import AppAutoTextarea from '@/components/AppAutoTextarea.vue';
import { useMomentsStore } from '@/store/pinia/moments';
import { useUsersStore } from '@/store/pinia/users';

export default {
  components: {
    AppAutoTextarea,
  },
  props: ['content', 'id'],
  setup() {
    const momentsStore = useMomentsStore();
    return { momentsStore };
  },
  data() {
    return {
      editText: '',
      focus: false,
      sending: false,
      debouncedSend: _.throttle(this.send, 500),
    };
  },
  mounted() {
    this.editText = utils.desanitizeString(this.content);
    _.delay(() => {
      if (this.$refs.box) {
        this.$refs.box.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
      }
    }, 200);
    _.delay(() => {
      this.focus = true;
    }, 400);
  },
  beforeUnmount() {
    if (this.editing) {
      this.send();
    }
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['myId']),
    ...mapPiniaState(useMomentsStore, {
      editing: 'iAmEditing',
    }),
  },
  methods: {
    send() {
      if (!this.editText) {
        this.$emit('deleteLog');
      } else {
        if (!this.sending) {
          this.sending = true;
          const data = {
            id: this.id,
            description: this.editText,
            editedBy: this.myId,
          };
          this.$meetingmanager.updateLog(data);
          // @TODO replace with real success check
          _.delay(() => {
            this.$emit('saved');
          }, 500);
        }
      }
    },
    cancel() {
      this.momentsStore.stopEditing();
    },
  },
};
</script>

<style lang="scss">
.editBox {
  p {
    @include type-size('tiny');
    margin-top: 0.25em;
    padding: 0 0 0.5em 0.5em;
    font-style: italic;
    color: var(--c__kinda-quiet);
    button {
      font-weight: bold;
      font-style: normal;
      display: inline-block;
      color: var(--c__text-loud);
      background: var(--c__edges);
      background: var(--c__quiet-button);
      border-radius: 4px;
      @include triggered {
        color: var(--c__accent);
      }
    }
  }
  textarea {
    padding: 0.5em;
    border-radius: 0.25em;
    border: 2px solid var(--c__edges);
    min-height: rem(38px);
  }
}
</style>
