<template>
  <div class="meetingsList">
    <p v-if="props.error" class="error">{{ props.error }}</p>
    <ul>
      <li v-for="meeting in meetings" :key="meeting.id">
        <span class="rowItem meetingTitle">
          <label>
            <input type="checkbox" v-model="meeting._selected" :disabled="meeting.status === 'started'" />
            {{ utils.desanitizeString(meeting.name) || 'Untitled meeting' }}
          </label>
        </span>
        <span class="rowItem colItem meetingType"> {{ parseMeetingType(meeting.meta || {}) }} </span>
        <span class="rowItem colItem meetingStatus capitalized"> {{ meeting.status }} </span>
        <span class="rowItem colItem meetingDate">{{ parseDate(meeting.starts_on, meeting.timezone) }}</span>
        <span class="rowItem colItem meetingTime"
          >{{ parseDate(meeting.starts_on, meeting.timezone, true) }} - {{ parseDate(meeting.ends_on, meeting.timezone, true) }}</span
        >
      </li>
    </ul>
    <div class="actions">
      <button class="btn btn-solid btn-loud-blue btn-dark-blue-flood" @click="emit('downloadMeetingsCSV')" :disabled="!areMeetingsSelected">
        <span> Download meeting agenda </span>
        <transition name="basic">
          <app-loading-spinner v-if="props.agendaRequested" />
        </transition>
      </button>
      <button class="btn btn-solid btn-loud-blue btn-dark-blue-flood" @click="emit('deleteMeetings')" :disabled="!areMeetingsSelected">
        <span> Delete selected meetings </span>
        <transition name="basic">
          <app-loading-spinner v-if="props.deleteRequested" />
        </transition>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import utils from '@/resources/utils';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
const props = defineProps({
  error: { type: [Boolean, String] },
  meetings: { type: Array },
  isLoading: { type: Boolean },
  agendaRequested: { type: Boolean },
  deleteRequested: { type: Boolean },
  resetRequestedTs: { type: Number },
});
const emit = defineEmits(['downloadMeetingsCSV', 'deleteMeetings']);

const parseMeetingType = (meta) => {
  if (meta.designer) return 'Designed';
  if (meta.conference) return 'Video';
  if (meta.scheduled) return 'Scheduled';
  return ' ';
};
const parseDate = (date, timeZone, timeOnly) => {
  if (!date || !timeZone) return ' ';

  let dtTimeFormatOptions = timeOnly
    ? { hour: 'numeric', hour12: false, minute: 'numeric', timeZone }
    : {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone,
      };
  let formatter = new Intl.DateTimeFormat('en-GB', dtTimeFormatOptions);
  const dt = new Date(date * 1000);

  return formatter.format(dt);
};

const areMeetingsSelected = computed(() => {
  return props.meetings.some((meeting) => meeting._selected);
});
</script>
<style lang="scss" scoped>
.meetingsList {
  margin: rem(64px) 0 rem(100px) 0;

  li {
    display: flex;
    gap: 1em;
    padding: 0.5em 0;
    justify-content: space-between;
    width: rem(800px);
    min-width: rem(600px);
    margin: auto;
    border-bottom: 1px solid var(--c__edges);
  }
  .rowItem {
    font-size: rem(16px);
    text-align: right;
    input {
      margin-right: 12px;
    }
    &.capitalized {
      text-transform: capitalize;
    }
    &.colItem {
      display: inline-block;
    }
    &.meetingType {
      width: rem(70px);
    }
    &.meetingStatus {
      width: rem(80px);
    }
    &.meetingDate {
      width: rem(100px);
    }
    &.meetingTime {
      width: rem(110px);
    }
  }
  .meetingTitle {
    margin-right: auto;
    font-weight: 400;
    text-align: left;
  }
}
.error {
  text-align: center;
  font-size: 1.5em;
  color: var(--c__warn);
}
.actions {
  position: fixed;
  bottom: 0;
  padding: rem(8px) 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    height: rem(45px);
    width: rem(350px);
    margin: rem(16px);
    position: relative;
  }
  .loadingSpinner {
    position: absolute;
    right: 1em;
  }
}
</style>
