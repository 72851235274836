<template>
  <div />
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMediaStore } from '@/store/pinia/media';
import { useStorage } from 'vue3-storage';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });
const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  setup() {
    const mediaStore = useMediaStore();
    return { mediaStore };
  },
  mounted() {
    const _this = this;

    this.$ee.on('bus:bixe-meeting-devices:init', _this.init);
    this.$ee.on('bus:bixe-meeting-devices:update', _this.update);
    this.$ee.on('bus:bixe-meeting-devices:persist', _this.persist);
  },
  unmounted() {
    const _this = this;

    this.$ee.off('bus:bixe-meeting-devices:init', _this.init);
    this.$ee.off('bus:bixe-meeting-devices:update', _this.update);
    this.$ee.off('bus:bixe-meeting-devices:persist', _this.persist);
  },
  computed: {
    ...mapPiniaState(useMediaStore, {
      devices: 'userDevices',
      deviceList: 'devices',
      audioSource: 'audioSource',
      audioOutputSource: 'audioOutputSource',
      videoSource: 'videoSource',
      audioOn: 'audioOn',
      audioOutputOn: 'audioOutputOn',
      videoOn: 'videoOn',
    }),
  },
  methods: {
    init(meetingId) {
      const meetingDevices = {};
      const isVideoOn = localStorage.getStorageSync('user:videoOn');
      const isAudioOn = localStorage.getStorageSync('user:audioOn');
      const isAudioOutputOn = localStorage.getStorageSync('user:audioOutputOn');

      // detect meeting audio device
      const audioDevices = this.deviceList.filter((x) => x.kind === 'audioInput');
      const audioSource = sessionStorage.getStorageSync(`meetings:${meetingId}:audioSource`);
      if (audioDevices.some((x) => x.deviceId === audioSource)) {
        meetingDevices.audioSource = audioSource;
      } else if (audioDevices.some((x) => x.deviceId === this.devices.audioSource)) {
        meetingDevices.audioSource = this.devices.audioSource;
      } else {
        meetingDevices.audioSource = null;
      }

      // detect meeting audio output device
      const audioOutputDevices = this.deviceList.filter((x) => x.kind === 'audioOutput');
      const audioOutputSource = sessionStorage.getStorageSync(`meetings:${meetingId}:audioOutputSource`);

      if (audioOutputDevices.some((x) => x.deviceId === audioOutputSource)) {
        meetingDevices.audioOutputSource = audioOutputSource;
      } else if (audioOutputDevices.some((x) => x.deviceId === this.devices.audioOutputSource)) {
        meetingDevices.audioOutputSource = this.devices.audioOutputSource;
      } else if (audioOutputDevices.length > 0) {
        meetingDevices.audioOutputSource = audioDevices[0].deviceId;
      } else {
        meetingDevices.audioOutputSource = null;
      }

      // detect meeting video device
      const videoDevices = this.deviceList.filter((x) => x.kind === 'videoInput');
      const videoSource = sessionStorage.getStorageSync(`meetings:${meetingId}:videoSource`);
      if (videoDevices.some((x) => x.deviceId === videoSource)) {
        meetingDevices.videoSource = videoSource;
      } else if (videoDevices.some((x) => x.deviceId === this.devices.videoSource)) {
        meetingDevices.videoSource = this.devices.videoSource;
      } else {
        meetingDevices.videoSource = null;
      }

      sessionStorage.setStorageSync(`meetings:${meetingId}:audioSource`, meetingDevices.audioSource);
      sessionStorage.setStorageSync(`meetings:${meetingId}:audioOutputSource`, meetingDevices.audioOutputSource);
      sessionStorage.setStorageSync(`meetings:${meetingId}:videoSource`, meetingDevices.videoSource);
      this.mediaStore.setAudioSource(meetingDevices.audioSource);
      this.mediaStore.setAudioOutputSource(meetingDevices.audioOutputSource);
      this.mediaStore.setVideoSource(meetingDevices.videoSource);
      this.mediaStore.setAudioOn(isAudioOn);
      this.mediaStore.setAudioOutputOn(isAudioOutputOn);
      this.mediaStore.setVideoOn(isVideoOn);
    },
    update(meetingId, meetingDetails) {
      if (Object.prototype.hasOwnProperty.call(meetingDetails, 'audioSource')) {
        this.mediaStore.setAudioSource(meetingDetails.audioSource);
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioSource`, meetingDetails.audioSource);
      }

      if (Object.prototype.hasOwnProperty.call(meetingDetails, 'audioOutputSource')) {
        this.mediaStore.setAudioOutputSource(meetingDetails.audioOutputSource);
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioOutputSource`, meetingDetails.audioOutputSource);
      }

      if (Object.prototype.hasOwnProperty.call(meetingDetails, 'videoSource')) {
        // incase of disbaled video souce, the meetingDetails Object will have videoSource attribute with value set as false
        this.mediaStore.setVideoSource(meetingDetails.videoSource || false);
        sessionStorage.setStorageSync(`meetings:${meetingId}:videoSource`, meetingDetails.videoSource || false);
      }
    },
    persist(meetingId, { audioSource, audioOutputSource, videoSource }) {
      if (audioSource) {
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioSource`, audioSource);
      }

      if (audioOutputSource) {
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioOutputSource`, audioOutputSource);
      }

      if (videoSource) {
        sessionStorage.setStorageSync(`meetings:${meetingId}:videoSource`, videoSource);
      }
    },
  },
};
</script>
