<template>
  <label :for="uid">
    <input type="checkbox" v-model="model" :value="value" :id="uid" :disabled="disabled" />
    <svg class="indicator" view-box="0 0 8 8"><path class="tick" d="M1.5,4.5L3.5,6.5L7,2"></path></svg>
    <span class="text">
      <slot></slot>
    </span>
  </label>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from 'vue';
const instance = getCurrentInstance();
const uid = ref(instance.uid);
const props = defineProps({
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object, String] },
  label: { type: String },
  disabled: { type: Boolean },
});
const emit = defineEmits(['update:modelValue']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>
<style lang="scss" scoped>
label {
  display: grid;
  grid-template-columns: rem(10px) auto;
  column-gap: rem(10px);
}

svg {
  .tick {
    display: none;
  }
  path {
    fill: none;
    stroke: currentColor;
    stroke-width: 1;
  }
}
input {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}
text {
  grid-column: 2;
}
.indicator {
  width: rem(10px);
  height: rem(10px);
  display: block;
  border: solid 1px currentColor;
  border-radius: 2px;
  margin-top: rem(5px);
  grid-column: 1;
}
input:checked ~ .indicator {
  .tick {
    display: initial;
  }
}
input:disabled ~ .indicator,
input:disabled ~ .text {
  color: var(--c__disabled);
}
</style>
