import DetectRtcDeviceManager from '@/devicemanagers/detectrtc.devicemanager';
import MockDeviceManager from '@/devicemanagers/mock.devicemanager';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    // TODO: refactor it, not the best solution
    const { config } = window.__INITIAL_STATE__;

    app.config.globalProperties.$devicemanager = config.video.enabled ? new DetectRtcDeviceManager() : new MockDeviceManager();
  },
};
