export default {
  init: {},
  lobby: {
    skill: 'misc',
    topTitle: 'Green Room',
    microskill: {
      owner: {
        heading: '',
        content: '',
      },
      user: {
        heading: '',
        content: '',
      },
    },
  },
  freeform: {
    skill: 'misc',
    topTitle: 'Circle',
    microskill: {
      check: function (a) {
        return !a || !a.panelName || a.panelName === 'hideAll';
      },
      heading: 'Open Circle',
      content: 'In the open circle, you can talk freely.',
    },
  },
  context: {
    skill: 'opening',
    topTitle: 'Set Context',
    microskill: {
      heading: 'Set Context',
      content: 'The Meeting Holder sets the context for your meeting to ensure the team has a shared understanding of what they are aiming to achieve.',
    },
  },
  checkIn: {
    skill: 'container',
    topTitle: 'Check-In',
    microskill: {
      heading: 'Check-In',
      content:
        'During the Check-In process you are muted until it is your turn to speak. Then you will have time to say how you are feeling and what you want out of the meeting.',
    },
  },
  decision: {
    skill: 'breakthrough',
    topTitle: 'Make a Decision',
    microskill: {
      heading: 'Make a Decision',
      content: (a) => {
        if (a.skillBarType === 'info' || a.skillBarType === 'setTime' || a.phaseStage === 'proposal') {
          return 'Decision Councils clarify what we are deciding on and who the decision-maker is. Begin by making a proposal on which you want a decision.';
        } else {
          return 'Now vote using the thumbs up or down buttons and then either come to an agreement, or resource the decision-maker to take the decision.';
        }
      },
    },
  },
  input: {
    skill: 'opening',
    topTitle: 'Give an Input',
    microskill: {
      heading: 'Give an Input',
      content:
        "Share your thinking with your colleagues. Let them know whether it's an update for questions, sharing work for feedback, or a proposal for a decision. This guidance will help them respond in a useful way.",
    },
  },
  breakTime: {
    skill: 'misc',
    topTitle: 'Break',
    microskill: {
      heading: 'Break',
      content: (a) => {
        if (a.skillBarType === 'phase') {
          return 'The break has started. Get a drink, stretch your legs &mdash; do whatever you need to do to return refreshed and ready to go.';
        } else {
          return "It's important to take a break during long meetings. We recommend having a break at least every two hours.";
        }
      },
    },
  },
  groups: {
    skill: 'misc',
    topTitle: 'Groups',
    microskill: {
      heading: 'Break-out Groups',
      content:
        'Have a discussion in smaller groups. By discussing critical issues or topics in smaller groups in can be easier to unlock new insights and move the work forward together.',
    },
  },
  dialogue: {
    skill: 'misc',
    topTitle: 'Creative Dialogue',
    microskill: {
      heading: 'Dialogue',
      content: 'Enable a new pattern of thought to emerge in the space between you.',
    },
  },
  feedback: {
    skill: 'container',
    topTitle: 'Feedback',
    microskill: {
      heading: 'Feedback',
      content: 'Share your latest thinking and work in progress to intentionally get candid and constructive feedback that helps you leap forward.',
    },
  },
  review: {
    skill: 'breakthrough',
    topTitle: 'Review Moments',
    microskill: {
      heading: 'Review Moments',
      content:
        'Spend a few minutes as a group reflecting upon and sharing with each other the critical moments from the meeting. Also review the key insights, actions and decisions you made. This will help you to end the meeting well.',
    },
  },
  checkOut: {
    skill: 'container',
    topTitle: 'Check-Out',
    microskill: {
      heading: 'Check-Out',
      content:
        'During the Check-Out process you are muted until it is your turn to speak. Then you will have time to talk about your reflections on the meeting and share your learning.',
    },
  },
  endMeeting: {
    skill: 'misc',
    topTitle: 'Green Room',
    microskill: {
      heading: 'Meeting over',
      content: 'Thanks for joining this meeting',
    },
  },
  finish: {
    skill: 'misc',
    topTitle: 'Green Room',
    microskill: {
      heading: 'Meeting over',
      content: 'Thanks for joining this meeting',
    },
  },
  ended: {
    skill: 'misc',
    topTitle: 'Green Room',
    microskill: {
      heading: 'Meeting over',
      content: 'Thanks for joining this meeting',
    },
  },
};
