import initConstants from '@/legacy/constants';
import initDetector from '@/legacy/detector';
import initDispatchers from '@/legacy/dispatchers';
import initTransformation from '@/legacy/transformation';

// export const vueBus = new Vue()
export const nM = {};

window.nM = nM;

//nM.ee = new EventEmitter()

initConstants(nM);
initDetector(nM);
initDispatchers(nM);
initTransformation(nM);

// resources
