import AwsChimeVideoManager from '@/videomanagers/awschime.videomanager';
import MockVideoManager from '@/videomanagers/mock.videomanager';
import { nM } from '@/legacy';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    // TODO: refactor it, not the best solution
    const bixeConfig = window.__INITIAL_STATE__.config;
    const enableWebAudio = !nM.detector.isSafari();
    const config = {
      postUrl: bixeConfig.postLogsUrl,
      video: bixeConfig.video,
      enableWebAudio: enableWebAudio,
    };

    app.config.globalProperties.$videomanager = config.video.enabled ? new AwsChimeVideoManager(config) : new MockVideoManager();
  },
};
