<template>
  <section class="page poster" data-page-name="joinWithLink">
    <div class="content">
      <title-block />
      <form id="joinMeetingForm" class="basicsForm" @submit.prevent="handleForm">
        <transition-group name="basic" tag="div">
          <div class="row" :class="{ hasError: hasWrongInfo }" key="id">
            <label for="joinMeetingField" class="visuallyHidden">Meeting Link</label>
            <input
              id="joinMeetingField"
              v-model.trim="link"
              type="text"
              name="link"
              autocomplete="off"
              :readonly="sending"
              placeholder="Paste meeting link here..."
            />
            <transition name="basic">
              <p v-if="hasWrongInfo && !sending" class="error">This meeting link is invalid. Please check and try again.</p>
            </transition>
          </div>
          <button type="submit" key="continue" class="btn solo btn-border" :disabled="link.length === 0 || sending">
            <span v-if="sending" class="inline-block"><app-loading-spinner /></span>
            <span v-else>Join Meeting</span>
          </button>
        </transition-group>
      </form>
    </div>

    <footer class="pageFooter">
      <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
    </footer>
  </section>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import TitleBlock from '@/components/TitleBlock.vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import utils from '../resources/utils';

export default {
  props: ['pageName'],
  components: {
    TitleBlock,
    AppLoadingSpinner,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { pageStore, accountStore };
  },
  data() {
    return {
      link: '',
      hasWrongInfo: false,
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
      sending: false,
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
  watch: {
    link(nv, ov) {
      if (nv !== ov) {
        this.unsetError();
      }
    },
  },
  methods: {
    unsetError() {
      this.sending = false;
      this.hasWrongInfo = false;
    },
    handleForm() {
      if (this.link.match(new RegExp('/conference/meeting/'))) {
        return this.initJoinMeetingFlow();
      }
      if (this.link.match(new RegExp('/conference/room/'))) {
        return this.initJoinPersonalRoomFlow();
      }
      this.wrongInfo();
    },
    initJoinMeetingFlow() {
      const { id, password } = this.parseLink('/conference/meeting/');
      if (!id || !password) {
        return this.wrongInfo();
      }
      this.sending = true;
      this.$API
        .getMeetingValidityInfo(id)
        .then((meeting) => {
          if (meeting.status === 'finished') {
            return this.$router.push(`/meeting-has-been-ended?id=${encodeURI(meeting.id)}`);
          }
          if (meeting.pin_pass !== password) {
            return this.wrongInfo();
          }
          this.joinMeeting(meeting);
        })
        .catch((err) => {
          console.log('unable to get meeting details', err);
          this.wrongInfo();
        });
    },
    initJoinPersonalRoomFlow() {
      const { id, password } = this.parseLink('/conference/room/');
      if (!id || !password) {
        return this.wrongInfo();
      }
      this.sending = true;
      if (!this.accountStore.isLoggedIn) {
        if (this.isOpenedWithinElectronShell) {
          ipcApi.send({
            name: 'openExternal',
            url: `${window.location.origin}/lauth/nowhere?&returnUrl=/conference/app/post-login&id=${id}&pwd=${password}&flow=pr-invite`,
            closeSelf: false,
          });
        } else {
          window.location = `/lauth/nowhere?&returnUrl=/conference/app/post-login&id=${id}&pwd=${password}`;
        }
        return;
      }
      utils.genericFns
        .joinPersonalRoomMeeting({ pinNumber: id, password })
        .then((meeting) => {
          this.joinMeeting(meeting);
        })
        .catch(() => {
          this.wrongInfo();
        });
    },
    joinMeeting(meeting) {
      utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
      if (this.accountStore.isLoggedIn) {
        this.$router.push(`/meeting/${meeting.id}`);
      } else {
        this.$router.push(`/joining-options?id=${encodeURI(meeting.id)}&pwd=${encodeURI(meeting.pin_pass)}&flow=meeting-invite`);
      }
    },
    parseLink(splitOn) {
      const info = this.link.split(splitOn)[1];
      if (!info) {
        return { id: '', password: '' };
      }

      const [id, password] = info.split('?pwd=');
      return { id, password };
    },
    wrongInfo() {
      this.sending = false;
      this.hasWrongInfo = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  flex-direction: column;
  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .inline-block {
      display: inline-block;
    }
  }
}
footer {
  flex: 0 0 auto;
}
form {
  width: rem(300px);
}

.error {
  @include type-size(medium);
  font-weight: 200;
}
</style>
