const spaces = {
  nowhere: {
    title: 'nowhere',
    topTitle: '',
    thumb: '/assets/images/space-thumbs/nowhere.png',
    lobbyStyle: 'grid',
    table: false,
    sofa: false,
    breakBackground: false,
    breakSofa: true,
    breakPosition: 0.5,
  },
  cafe: {
    title: 'Café',
    topTitle: 'Café',
    thumb: '/assets/images/space-thumbs/cafe.png',
    lobbyStyle: 'rows',
    table: 'standard',
    sofa: 'standard',
    breakBackground: false,
    breakSofa: true,
    breakPosition: 0.35,
  },
  zen: {
    title: 'Zen',
    topTitle: 'Zen',
    thumb: '/assets/images/space-thumbs/zen.png',
    lobbyStyle: 'grid',
    table: false,
    sofa: false,
    breakBackground: false,
    breakSofa: false,
    breakPosition: 0.4,
  },
  beach: {
    title: 'Beach',
    topTitle: 'Beach',
    thumb: '/assets/images/space-thumbs/beach.png',
    lobbyStyle: 'grid',
    table: false,
    sofa: false,
    breakBackground: false,
    breakSofa: false,
    breakPosition: 0.5,
  },
  neon: {
    title: 'Neon',
    topTitle: 'Neon',
    thumb: '/assets/images/space-thumbs/neon.png',
    lobbyStyle: 'rows',
    table: 'neon',
    sofa: 'neon',
    breakBackground: 'window',
    breakSofa: true,
    breakPosition: 0.666,
  },
};

export default spaces;
