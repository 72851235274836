<template>
  <section class="launchingNow poster">
    <div class="content">
      <title-block />
      <p class="launching">Returning you to the app...</p>
      <div class="tryAgain">
        <p>
          <span class="leadin">Having problems? </span>
          <browser-guarded-button inline="true" :to="webLink">Open in browser instead</browser-guarded-button>
        </p>
      </div>
    </div>
    <footer class="pageFooter">
      <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
    </footer>
  </section>
</template>
<script>
import { useCookies } from 'vue3-cookies';
import utils from '@/resources/utils';
import { useAccountStore } from '@/store/pinia/account';
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import TitleBlock from '@/components/TitleBlock.vue';

export default {
  components: {
    TitleBlock,
    BrowserGuardedButton,
  },
  setup() {
    const accountStore = useAccountStore();
    return { accountStore };
  },
  data() {
    return {
      showSafariTip: false,
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    webLink() {
      const { id, pwd, flow } = this.$route.query;
      if (id && pwd) {
        if (flow === 'pr-invite') {
          return `/room/${id}?pwd=${pwd}`;
        }
        return `/meeting/${id}?pwd=${pwd}`;
      }
      return '/';
    },
  },
  async mounted() {
    const { cookies } = useCookies();
    const authOtp = cookies.get('x-nowhere-auth-otp');
    if (authOtp) {
      let encoded = encodeURIComponent(`${origin}${this.accountStore.baseUrl}?requested-otp=${authOtp}`);
      const { id, pwd, flow } = this.$route.query;
      if (id && pwd) {
        if (flow === 'pr-invite') {
          encoded = encodeURIComponent(`${origin}${this.accountStore.baseUrl}/room/${id}?pwd=${pwd}&requested-otp=${authOtp}`);
        } else {
          utils.storage.ss.setItem(`platform.invitationViewed.${id}`, '1');
          encoded = encodeURIComponent(`${origin}${this.accountStore.baseUrl}/meeting/${id}?pwd=${pwd}&requested-otp=${authOtp}`);
        }
      }
      const appProtocol = utils.getNativeAppProtocol(this.accountStore.__env);
      window.location = `${appProtocol}://${encoded}`;
      cookies.remove('x-nowhere-auth-otp');
    } else {
      await this.$API.attemptLogout(true);
      const { id, pwd, flow } = this.$route.query;
      let destination = 'returnUrl=/conference/app/post-login&request-otp=1';
      if (id && pwd) {
        if (flow === 'pr-invite') {
          destination = `returnUrl=/conference/app/post-login&id=${id}&pwd=${pwd}&flow=pr-invite`;
        } else {
          destination = `returnUrl=/conference/app/post-login&id=${id}&pwd=${pwd}&flow=meeting-invite`;
        }
      }
      window.location = `/lauth/nowhere?${destination}`;
    }
  },
};
</script>

<style lang="scss" scoped>
section {
  flex-direction: column;
  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    :deep(.bubbleTip .message) {
      @include type-size('small');
    }
    p.launching {
      @include type-size('large');
      font-weight: 100;
      margin-top: rem(38px);
    }
    .tryAgain {
      margin: rem(68px) 0 0 0;
      p {
        margin: 0;
      }
    }
    a {
      color: var(--c__accent);
      @include triggered {
        color: var(--c__accent-alt);
      }
    }
  }
  footer {
    margin: auto 0 0 0;
  }
}
</style>
