<template>
  <svg class="scaledText" :viewBox="viewbox" preserveAspectRatio="xMidYMid meet">
    <text x="50%" y="50%" :font-size="fontSize" text-anchor="middle" dominant-baseline="central">
      <slot />
    </text>
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      default: 100,
    },
    fontSize: {
      default: 24,
    },
  },
  computed: {
    viewbox() {
      return `0 0 ${this.size} ${this.size}`;
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
  color: var(--c__bg);
  fill: var(--c__bg);
  font-weight: bold;
}
</style>
