import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { usePxToRem } from '@/composables/usePxToRem';
import * as geometry from '@/resources/geometry';
import _ from 'underscore';
import { COUCH_H_PAD, OUTER_V_PAD, OUTER_H_PAD, LOGBAR } from '@/resources/constants/frame-constants';

export function useCouchLayout() {
  const meetingStore = useMeetingStore();
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();
  const phaseStore = usePhaseStore();
  const { pxToRem } = usePxToRem();

  const { space, spaceDetails, useNotes } = storeToRefs(meetingStore);
  const { usersVisibleList: users, speaking, iAmAway, iAmInWaitingRoom, myId } = storeToRefs(usersStore);
  const { contWidth, contHeight, viewportH, cY } = storeToRefs(frameStore);
  const { currentPhase } = storeToRefs(phaseStore);

  function duoCouchLayout() {
    let pos = {};

    let availableHeight = space.value === 'beach' ? contHeight.value * 0.75 : contHeight.value;
    let widthSlice = contWidth.value / 4;

    let heightTest = availableHeight - 150;
    let widthTest = contWidth.value / 2 - 100;
    let maxWidth = heightTest > widthTest ? widthTest : heightTest;

    users.value.forEach((id, i) => {
      let leftNudge = i ? -25 : 25;
      pos[id] = {
        left: widthSlice + widthSlice * i * 2 + leftNudge,
        top: cY.value,
        w: maxWidth,
      };

      if (space.value === 'beach') {
        pos[id].top = contHeight.value - pos[id].w;
      }
    });

    // If there is a speaker, loop over and make the others smaller, and shift them down to be sitting on the sofa still if needs be
    const sizeMod = 1.1;
    if (speaking?.value.id && currentPhase.value !== 'freeform') {
      let others = _.without(users.value, speaking.value.id);
      others.forEach((id) => {
        let vShift = (pos[id].w - pos[id].w / sizeMod) / 2;
        pos[id].w = pos[id].w / sizeMod;
        if (spaceDetails.value.sofa || space.value === 'beach') {
          pos[id].top = pos[id].top + vShift;
        }
      });
    }

    return { pos, layoutName: 'couch' };
  }

  function generalCouchLayout() {
    let count = users.value.length;
    let maxArea = viewportH.value - pxToRem(LOGBAR) - OUTER_V_PAD * 2 - viewportH.value * 0.3;

    if (space.value === 'beach') {
      maxArea = viewportH.value - pxToRem(LOGBAR) - OUTER_V_PAD * 2 - viewportH.value * 0.3;
    }

    const maxSize = maxArea;

    let pos = geometry.row({
      users: users.value,
      y: 0,
      cont: contWidth.value,
      max: contWidth.value,
      maxSize: maxSize,
      scaled: true,
      w: count > 10 ? 100 : 140,
      pad: COUCH_H_PAD,
      outerPad: OUTER_H_PAD,
      row: 0,
    });

    users.value.forEach((id) => {
      if (spaceDetails.value.sofa) {
        //pos[id].top = maxArea * 0.75 + OUTER_V_PAD;
        let table = useNotes.value ? pxToRem(100) : pxToRem(30);

        let sofa = viewportH.value * 0.36458;
        pos[id].top = viewportH.value - pos[id].w / 2 - table - sofa * 0.75;
      } else {
        pos[id].top = cY.value - OUTER_V_PAD / 2;
      }
      if (space.value === 'beach' || space.value === 'neon') {
        // pos[id].top = contHeight.value - pxToRem(280) - pos[id].w / 2;

        pos[id].top = contHeight.value - pos[id].w * 0.87 - pxToRem(60);
      }
    });

    const sizeMod = 1.1;
    if (speaking.value && speaking.value.id && currentPhase.value !== 'freeform') {
      let others = _.without(users.value, speaking.value.id);

      others.forEach((id) => {
        let vShift = (pos[id].w - pos[id].w / sizeMod) / 2;
        pos[id].w = pos[id].w / sizeMod;
        if (spaceDetails.value.sofa) {
          pos[id].top = pos[id].top + vShift;
        }
      });
    }

    if (iAmAway.value || iAmInWaitingRoom.value) {
      pos[myId.value].left = contWidth.value * spaceDetails.value.breakPosition;
    }

    return { pos, layoutName: 'couch' };
  }

  function couchLayout() {
    if (!spaceDetails.value.sofa && users.value.length === 2) {
      return duoCouchLayout();
    } else {
      return generalCouchLayout();
    }
  }

  return { couchLayout };
}
