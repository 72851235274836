<template>
  <div class="inner" :style="pos" ref="inner">
    <div class="badge"></div>
    <div class="visuals" ref="visuals">
      <div class="userPic">
        <img v-if="user.picture_url" :src="imageUrl" :alt="user.first_name" />
        <span class="initials">
          <app-scaled-text v-if="!user.picture_url && !moving">{{ initials }}</app-scaled-text>
        </span>
      </div>
      <div class="video" ref="videoHolder">
        <div class="empty-video" v-if="isVideoStreamBroken"></div>
        <video :id="'video-user-' + user.id" ref="video" @loadeddata="onAvatarVideoLoaded" @emptied="onAvatarVideoEmptied" />
      </div>
      <div class="overlay" />
      <svg v-if="!noTransparency" class="muteOverlay" viewBox="0 0 100 100">
        <defs>
          <clipPath :id="'mute-edge-' + user.id">
            <circle cx="50" cy="50" r="50" />
          </clipPath>
        </defs>
        <circle cx="50" cy="50" r="50" :clip-path="`url(#mute-edge-${user.id})`" fill="none" />
      </svg>
      <app-icon v-if="noTransparency" class="solidMuteOverlay" icon="mute-indicator" />
      <transition name="slowFade">
        <div v-if="badgeShowing" class="microskillBadge">
          <app-icon :icon="sidebarStore.current.phase" class="phaseIcon" />
        </div>
      </transition>
    </div>
    <avatar-tooltip class="avatarTooltip" :user="user" />

    <transition name="basic">
      <button v-if="hasSwapButtons && !swapping" class="startSwap swapButton" key="startSwap" title="move user" @click="startSwap">
        <app-scaled-text font-size="24"> Move </app-scaled-text>
      </button>
    </transition>
    <transition name="basic">
      <button v-if="hasSwapButtons && swapping && iAmSwapping" class="cancel swapButton" key="cancel" title="cancel" @click="cancelSwap">
        <app-scaled-text>Cancel</app-scaled-text>
      </button>
    </transition>
    <transition name="basic">
      <button v-if="hasSwapButtons && swapping && !iAmSwapping" class="swap swapButton" key="swap" title="swap" @click="swap">
        <app-scaled-text>Swap</app-scaled-text>
      </button>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import AppScaledText from '@/components/AppScaledText.vue';
import AppIcon from '@/components/AppIcon.vue';
import AvatarTooltip from '@/components/AvatarTooltip.vue';
import { useFeaturesStore } from '@/store/pinia/features';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import utils from '../resources/utils';

const featuresStore = useFeaturesStore();
const sidebarStore = useSidebarStore();
const phaseStore = usePhaseStore();
const usersStore = useUsersStore();
const props = defineProps(['user', 'pos', 'index', 'count']);

const badgeShowing = ref(false);
const badgeShowingTime = ref(null);
const hasSwapButtons = ref(false);
const swapping = ref(false);
const iAmSwapping = ref(false);
const isVideoStreamBroken = ref(false);
const video = ref(null);
const visuals = ref(null);

onMounted(() => {
  if (props.user.fake) {
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    if (navigator.getUserMedia) {
      navigator.getUserMedia(
        { audio: false, video: true },
        (stream) => {
          video.value.srcObject = stream;
          video.value.play().catch(() => {});
        },
        () => {},
      );
    }
  }
});

onUnmounted(() => {
  usersStore.setVideoLoaded(props.user.id, false);
});

const { isMoving } = storeToRefs(usersStore);
const { cpuLevel, perf } = storeToRefs(featuresStore);
const { settingUp, active } = storeToRefs(phaseStore);

const moving = computed(() => isMoving.value(props.user.id));
const noTransparency = computed(() => ['bad', 'low'].includes(cpuLevel.value));
const showBadge = computed(() => settingUp.value && active.value === props.user.id);

const initials = computed(() => {
  return utils.desanitizeString(props.user.first_name[0].toUpperCase()) + utils.desanitizeString(props.user.last_name[0].toUpperCase());
}); //TODO: check this isn't actually on the user anyway

const iAmVideoMuted = computed(() => props.user.video_muted || props.user.video_muted_by_system);
const imageUrl = computed(() => {
  if (!props.user.picture_url) {
    return false;
  }
  let start = props.user.picture_url.split('?w')[0];
  let size = Math.ceil(parseFloat(props.pos.width) / 100) * 100;
  return `${start}?w=${size * 2}&h=${size * 2}&fit=crop`;
});

watch(
  showBadge,
  (nv) => {
    if (nv) {
      badgeShowing.value = true;
      badgeShowingTime.value = window.setTimeout(hideBadge, 2000);
    } else {
      badgeShowing.value = false;
      badgeShowingTime.value = null;
    }
  },
  { immediate: true },
);

watch(moving, (nv) => {
  if (nv) {
    if (video.value) {
      if (perf.value.pauseVideoWhenMoving) {
        video.value.pause().catch(() => {});
      }
      if (perf.value.hideVideoWhenMoving) {
        visuals.value.style.opacity = 0;
      }
    }
  } else {
    if (video.value) {
      if (perf.value.pauseVideoWhenMoving) {
        video.value.play().catch(() => {});
      }
      if (perf.value.hideVideoWhenMoving) {
        visuals.value.style.opacity = 1;
      }
    }
  }
});

watch(iAmVideoMuted, (nv) => {
  if (nv) {
    isVideoStreamBroken.value = false;
  }
});

function onAvatarVideoEmptied() {
  if (iAmVideoMuted.value) {
    return;
  }
  isVideoStreamBroken.value = true;
}

function onAvatarVideoLoaded() {
  isVideoStreamBroken.value = false;
  usersStore.setVideoLoaded(props.user.id, true);
}

function hideBadge() {
  badgeShowing.value = false;
}

function startSwap() {
  usersStore.setSwapping(props.user.id);
}

function cancelSwap() {
  usersStore.setSwapping(false);
}

function swap() {
  usersStore.setSwapping(false);
  usersStore.swapUsers([swapping.value, props.user.id]);
}
</script>
<style lang="scss" scoped>
.inner {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50% !important;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  & * {
    border-radius: 50% !important;
  }

  .ugly & {
    border-radius: 0 !important;
    & * {
      border-radius: 0% !important;
    }
  }
}
.visuals {
  pointer-events: none;
  transition: opacity 200ms;
  overflow: hidden;
}

.visuals,
.muteOverlay,
.initials {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
}

@keyframes resizeFix {
  0% {
    width: 99%;
    height: 99%;
    opacity: 0;
  }
  100% {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.userPic {
  img {
    opacity: 1;
    height: auto;
    width: 100%;
    overflow: hidden;
    position: absolute;
    text-indent: -9999px;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50% !important;
    .hasVideo & {
      opacity: 0;
    }

    .hasVideo.offline &,
    .hasVideo.mute & {
      opacity: 1;
    }
  }

  .initials {
    background: var(--grad__initials);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    :deep(svg) {
      animation: resizeFix 0.3s 0.3s both;
      width: 100%;
      height: 100%;
    }
    :deep(text) {
      fill: var(--c__bg);
    }
  }
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  border-radius: 50% !important;
  overflow: hidden;
  -webkit-mask-image: -webkit-radial-gradient(circle, white, black);
  transition: opacity 0.3s;
  .empty-video {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  video {
    border-radius: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
  }

  .noMask & {
    -webkit-mask-image: none;
  }

  .ugly & {
    border-radius: 0% !important;
    overflow: visible;
    -webkit-mask-image: none;
    video {
      object-fit: unset;
      height: auto;
    }
  }
}

.avatar.muteVideo img {
  z-index: 3;
  position: relative;
}

.avatar.me .video video {
  transform: translateX(-50%) translateY(-50%) rotateY(180deg) !important;
}

.avatar.onBreak .video {
  opacity: 0;
}

.muteOverlay {
  display: block;
  z-index: 9;
  position: absolute;
  top: 0;
  overflow: hidden;
  circle {
    transition: all 150ms ease;
    fill: none;
    stroke: var(--c__accent);
    stroke-width: 0;
    stroke-opacity: 0.7;

    .avatar.mute & {
      stroke-width: 15;
      transition: all 300ms ease;
    }

    .avatar.sysMute &,
    .breakTime-animateIn .avatar.onBreak &,
    .breakTime .avatar.onBreak & {
      stroke-width: 100;
    }
  }
}

.solidMuteOverlay {
  position: absolute;
  border-radius: 50%;
  background: var(--c__accent);
  color: var(--c__bg);
  bottom: -5px;
  margin-left: -10%;
  height: 20%;
  width: 20%;
  z-index: 9;
  transform: scale(0);
  transform-origin: center bottom;
  transition: all 300ms ease;
  pointer-events: none;
  :deep(svg) {
    width: 100%;
  }

  .avatar.mute &,
  .avatar.sysMute &,
  .breakTime-animateIn .avatar.onBreak &,
  .breakTime .avatar.onBreak & {
    transform: scale(1);
  }
}

@keyframes badge-fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 1em;
  position: absolute;
  top: -2.5em;
  transform-origin: center bottom;
  transition: all 250ms;
  opacity: 0;
  transform: translateY(100%) scale(0.5);
  .avatar.voted & {
    animation: badge-fade 2.5s ease 0.25s !important;
    transform: translateY(0%) scale(1);
    &::before {
      line-height: 0.5;
      content: 'Voted';
      color: var(--c__text);
      font-weight: 300;
    }
  }

  .showingControls & {
    opacity: 0;
  }
}

.microskillBadge {
  display: flex;
  background: var(--c__bg);
  position: absolute;
  align-items: center;
  justify-content: center;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  z-index: 90;
  p {
    margin: 0;
  }
  .phaseIcon {
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    width: auto;
    height: auto;
  }
  :deep(svg) {
    width: 90%;
    height: auto;
  }
}

.avatarTooltip {
  z-index: 15;
}
</style>
