<template>
  <div class="skillBar microskillSidebar" :class="[phase, capacity, skillType]">
    <div class="skill">
      <header key="header">
        <app-icon :icon="phase" class="currentPhaseIcon phaseIcon" key="currentPhase" :data-icon-name="phase" />
        <user-menu key="user" />
      </header>
      <transition-group name="skillbar-bits" tag="section" class="skillBarContent">
        <phase-info key="info" />

        <start-with v-if="showStartWith" />

        <meeting-info
          v-bind="$attrs"
          v-if="showProposal"
          key="skillBarProposal"
          class="proposal"
          :field="phaseStore.proposal"
          :edited-by="phaseStore.editing.proposal"
          expanded="true"
          name="proposal"
          default-text=""
          type="paragraph"
          optional="true"
          :heading="proposalHeading"
        />

        <meeting-info
          v-bind="$attrs"
          v-if="showCheckInQuestion"
          key="skillBarCheckInQuestion"
          :field="checkInQuestions"
          :edited-by="phaseStore.editing.question"
          expanded="true"
          name="checkInQuestion"
          default-text=""
          type="list"
          optional="true"
          :heading="checkInHeading"
        />

        <meeting-info
          v-bind="$attrs"
          v-if="showTopic"
          key="skillBarTopic"
          :field="phaseStore.topic"
          :edited-by="phaseStore.editing.topic"
          expanded="true"
          name="topic"
          default-text=""
          type="paragraph"
          optional="true"
          :heading="topicHeading"
        />

        <groups-group-select v-if="sidebarStore.current.phase === 'groups' && type === 'setTime'" type="select" key="groupSelect" />

        <timer-disc
          v-bind="$attrs"
          v-show="showTimer"
          key="phase-timer"
          type="phase"
          :showing="showTimer"
          :current="timeStore.phaseElapsed"
          :total="timeStore.phaseLength"
          :passed-setting-time="timeStore.phaseSettingTime"
          :remaining="timeStore.phaseRemaining"
          :remote-setting-time="timeStore.phaseSettingTime"
        />

        <microskill-sidebar-buttons key="buttons" />
      </transition-group>
    </div>
  </div>
</template>

<script>
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import GroupsGroupSelect from '@/components/GroupsGroupSelect.vue';
import TimerDisc from '@/components/TimerDisc.vue';
import MeetingInfo from '@/components/MeetingInfo';
import MicroskillSidebarButtons from '@/components/MicroskillSidebarButtons';
import MicroskillSidebarUsers from '@/components/MicroskillSidebarUsers';
import MicroskillSidebarPhaseInfo from '@/components/MicroskillSidebarPhaseInfo';
import StartWith from '@/components/StartWith.vue';
import AppIcon from '@/components/AppIcon.vue';
import screenInfo from '@/resources/screen-info';

export default {
  components: {
    MeetingInfo,
    MicroskillSidebarButtons,
    TimerDisc,
    GroupsGroupSelect,
    StartWith,
    'user-menu': MicroskillSidebarUsers,
    AppIcon,
    'phase-info': MicroskillSidebarPhaseInfo,
  },
  setup() {
    const phaseStore = usePhaseStore();
    const sidebarStore = useSidebarStore();
    const usersStore = useUsersStore();
    const timeStore = useTimeStore();
    return { phaseStore, sidebarStore, usersStore, timeStore };
  },
  data() {
    return {
      topButton: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.topButton = this.topButtonRaw;
    });
  },
  computed: {
    skillBar() {
      if (this.usersStore.iAmStillAway) {
        return 'breakTime';
      } else if (this.usersStore.iAmStillAwayInGroup) {
        return 'groups';
      } else {
        return this.sidebarStore.current.phase;
      }
    },
    skillType() {
      return this.sidebarStore.current.barType;
    },
    skillCapacity() {
      return screenInfo[this.sidebarStore.current.phase].skill;
    },
    phase() {
      if (this.usersStore.iAmStillAway) {
        return 'breakTime';
      } else if (this.usersStore.iAmStillAwayInGroup) {
        return 'groups';
      } else {
        return this.skillBar;
      }
    },
    capacity() {
      return this.phase === 'breakTime' ? 'misc' : this.skillCapacity;
    },
    skill() {
      return this.usersStore.iAmStillAway || this.usersStore.iAmStillAwayInGroup ? 'misc' : this.skillCapacity;
    },
    type() {
      return this.usersStore.iAmStillAway || this.usersStore.iAmStillAwayInGroup ? 'phase' : this.skillType;
    },
    topButtonRaw() {
      return this.skillType === 'info';
    },
    showProposal() {
      return this.sidebarStore.current.phase === 'decision' && this.skillType === 'phase' && this.phaseStore.phaseStage !== 'proposal';
    },
    proposalHeading() {
      return this.phaseStore.proposal ? 'Proposal' : 'Add a proposal';
    },
    showTopic() {
      return ['dialogue', 'feedback'].includes(this.skillBar) && this.skillType === 'phase' && this.phaseStore.phaseStage !== 'topic';
    },
    topicHeading() {
      return this.skillBar === 'feedback' && !this.phaseStore.topic ? 'Add feedback topic...' : 'What are we exploring?';
    },
    showAddCheckInQuestion() {
      return (
        this.checkInQuestions.length < 1 && !this.phaseStore.editing.questions && ['checkIn', 'checkOut'].includes(this.skillBar) && this.skillType !== 'info'
      );
    },
    showCheckInQuestion() {
      return ['checkIn', 'checkOut'].includes(this.skillBar) && this.skillType !== 'info';
    },
    checkInQuestions() {
      return this.phaseStore.questions.map((q) => q.description);
    },
    questionOrQuestions() {
      return this.checkInQuestions.length > 1 ? 'questions' : 'question';
    },
    checkInHeading() {
      if (this.skillBar === 'checkOut') {
        return this.showAddCheckInQuestion ? 'Add a Check-Out question...' : 'Check-Out ' + this.questionOrQuestions;
      } else {
        return this.showAddCheckInQuestion ? 'Add a Check-In question...' : 'Check-In ' + this.questionOrQuestions;
      }
    },
    showStartWith() {
      return this.sidebarStore.current.barType === 'setTime' && ['checkIn', 'checkOut'].includes(this.phaseStore.setupPhase);
    },
    showTimer() {
      return this.skillType !== 'info';
    },
    leaveLabel() {
      return this.usersStore.iAmModerator ? 'End Meeting' : 'Leave Meeting';
    },
  },
  watch: {
    topButtonRaw(newVal) {
      // Do the cool animation here
      this.topButton = newVal;
    },
    type: {
      immediate: true,
      handler(nv) {
        if (nv === 'phase') {
          document.body.classList.add('skillBar-phase');
          document.body.classList.remove('skillBar-setup');
        } else {
          document.body.classList.remove('skillBar-phase');
          document.body.classList.add('skillBar-setup');
        }
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.microskillSidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: rem(83px);
  z-index: 5;
  width: rem($sidebar-width);
  @include no-scrollbars;

  header {
    position: absolute;
    top: rem(85px);
    left: rem(30px);
    height: rem(83px);
    z-index: 3;
    display: flex;
    justify-content: space-between;
  }

  // section + section {
  //   border-top: 1px solid $c_lighter-grey;
  // }

  .currentPhaseIcon {
    z-index: 90;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease;
  }

  .skillBarContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: rem($skillbar-top) 0 0 0;
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  :deep(.isEditing) {
    color: var(--c__text);
    :deep(.text) {
      font-weight: 300;
    }
  }

  :deep(.small.isEditing .text) {
    left: rem(34px);
  }

  :deep(.infoDisplayer),
  :deep(.infoHeading) {
    padding: 0 rem(24px) 0 rem(34px);
    color: var(--c__text);
    width: 100%;
  }

  :deep(.infoDisplayer .infoHeading) {
    padding: 0;
  }

  .infoHeading {
    margin-top: 0;
  }

  .checkInQuestion {
    margin-top: 1em;
  }

  .phaseExtra + .checkInQuestion {
    margin-top: 0;
  }

  .proposal,
  .topic {
    margin-top: 3em;
  }
  .checkInQuestion {
    margin-bottom: rem(10px);
  }

  .proposal {
    margin-bottom: rem(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

@each $name in $skills {
  .microskillSidebar.#{$name} {
    background: var(--c__skillbar-bg-#{$name});

    .currentPhaseIcon {
      color: var(--c__#{$name});
    }

    :deep(.isEditing) {
      background: var(--c__skillbar-bg-#{$name});
      color: var(--c__text-energy-invert);
    }

    :deep(.infoSection .edit),
    :deep(.stealthButton) {
      color: var(--c__text-energy-invert);
      background: var(--c__#{$name}-bg-90);
    }
  }
}
.microskillSidebar.dialogue {
  :deep(.isEditing),
  :deep(.infoSection .edit),
  :deep(.stealthButton) {
    color: var(--c__accent);
  }
}

.sidebarTimer {
  padding: rem(10px) 0;
  flex: 999 0 auto;
  margin-top: auto;
}

.microskillSidebar.container,
.microskillSidebar.opening,
.microskillSidebar.tension,
.microskillSidebar.breakthrough {
  color: var(--c__text-energy);
  :deep(.skillText) {
    color: var(--c__text-energy);
  }
  .sidebarTimer {
    --timer-bar: var(--c__text-energy-invert);
    --timer-track: var(--c__text-energy);
    --timer-unit: var(--c__text-energy);
    --timer-minutes: var(--c__text-energy-invert);
    --timer-seconds: var(--c__text-energy);
  }
}

.endSkill .sidebarTimer {
  @media only screen and (min-height: $sidebar-timer-center-break) {
    margin: auto;
  }
}

.endSkill .sidebarTimer,
.misc .skill .sidebarTimer {
  flex: 999 0 auto;
}

// Transition classes

.skillbar-bits-enter-active,
.skillbar-bits-enter-active.skillbar-bits-move {
  transition: all 1s 1s;
}

.skillbar-bits-leave-active {
  transition: opacity 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.skillbar-bits-enter-from,
.skillbar-bits-leave-to {
  opacity: 0;
}

.skillbar-bits-move {
  transition: transform 0.5s cubic-bezier(0.6, 0, 0, 1) 0.1s;
}
</style>
