<template>
  <div class="skillText">
    <h2 class="title heading-small" v-html="title" />
    <p v-html="desc" />
  </div>
</template>

<script>
import _ from 'underscore';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import screenInfo from '@/resources/screen-info';
import utils from '../resources/utils';

export default {
  setup() {
    const sidebarStore = useSidebarStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { sidebarStore, phaseStore, usersStore };
  },
  data() {
    return {
      currentBar: 'freeform',
    };
  },
  computed: {
    skillBar() {
      if (
        !this.sidebarStore.current.phase ||
        this.sidebarStore.current.phase === 'freeform' ||
        (this.sidebarStore.current.phase === 'lobby' && this.phaseStore.previousPhase)
      ) {
        return this.currentBar;
      } else {
        return this.sidebarStore.current.phase;
      }
    },
    phase() {
      if (this.usersStore.iAmStillAway) {
        return 'breakTime';
      } else if (this.usersStore.iAmStillAwayInGroup) {
        return 'groups';
      } else {
        return this.skillBar;
      }
    },
    type() {
      return this.usersStore.iAmStillAway || this.usersStore.iAmStillAwayInGroup ? 'phase' : this.sidebarStore.current.barType;
    },
    title() {
      let t = this.skillData(this.phase, 'heading');
      return this.parseText(t, this.phaseArgs);
    },
    desc() {
      let t = this.skillData(this.phase, 'content');
      return this.parseText(t, this.phaseArgs);
    },
    phaseArgs() {
      return {
        skillBarType: this.type,
        phaseStage: this.phaseStore.phaseStage,
      };
    },
  },
  watch: {
    skillBar: {
      immediate: true,
      handler(nv) {
        this.currentBar = nv;
      },
    },
  },
  methods: {
    skillData(phase, part) {
      if (!phase || !screenInfo[phase]) {
        return false;
      }

      const data = screenInfo[phase].microskill;
      return data[part];
    },
    parseText(text, args) {
      if (!text) {
        return '';
      }
      text = _.isFunction(text) ? text(args) : text;
      return utils.desanitizeString(text);
    },
  },
};
</script>

<style lang="scss" scoped>
.skillText {
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  padding-right: rem(24px);
  padding-left: rem(34px);
  color: var(--c__text);
  p {
    margin-bottom: 0;
  }
}

h2 {
  text-align: left;
  margin: 0;
  padding: 0;
  text-transform: none;
  line-height: math.div(17, 14);
}
</style>
