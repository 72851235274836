<template>
  <app-modal-overlay @close="close" class="shareableScreenModal">
    <h2>Select screen to share</h2>
    <app-tabs :tabList="tabList">
      <template v-for="(tab, i) in tabsData" v-slot:[slotName(i)] :key="i">
        <ul class="views">
          <li v-for="(item, itemIndex) in tab.items" :key="`item-${itemIndex}`">
            <button @click="onItemClicked(item)">
              <span class="label">{{ item.name }}</span>
              <img class="thumb" :src="item.thumbnailSrc" />
            </button>
          </li>
        </ul>
      </template>
    </app-tabs>
  </app-modal-overlay>
</template>

<script>
import AppModalOverlay from '@/components/AppModalOverlay';
import AppTabs from '@/components/AppTabs';
import _ from 'underscore';

export default {
  components: {
    AppModalOverlay,
    AppTabs,
  },
  props: ['resources'],
  data() {
    return {
      tabsData: [
        {
          title: 'Screens',
          tabId: _.uniqueId('tab_'),
          contentId: _.uniqueId('content_'),
          items: [],
          isActive: true,
        },
        {
          title: 'Windows',
          tabId: _.uniqueId('tab_'),
          contentId: _.uniqueId('content_'),
          items: [],
          isActive: false,
        },
      ],
    };
  },
  computed: {
    tabList() {
      return this.tabsData.map((t) => t.title);
    },
  },
  watch: {
    resources: {
      immediate: true,
      handler(nv) {
        const screens = nv.filter((resource) => resource.id.startsWith('screen:'));
        const windows = nv.filter((resource) => resource.id.startsWith('window:'));
        this.tabsData = [
          { ...this.tabsData[0], items: screens },
          { ...this.tabsData[1], items: windows },
        ];
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    slotName(i) {
      return `item-${1 + i}`;
    },
    onTabActive(activatedTab) {
      this.tabsData = this.tabsData.map((tab) => ({ ...tab, isActive: tab.tabId === activatedTab.tabId }));
    },
    onItemClicked(selectedItemDetails) {
      this.$emit('onSelect', { selectedItemDetails });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 8em 3em 2em 3em;
  max-width: 65em;
  margin: 0 auto;
}
header {
  text-align: center;
}
h1 {
  @include type-size(xx-large);
}
section > h2 {
  @include type-size(x-large);
}
:deep(.modalOverlay) {
  width: 70em !important;
  .tabs {
    .items {
      max-height: 15em;
    }
  }
}
:deep(.views) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  list-style: none;
  gap: 1em;
  height: 30em;
  max-height: 30em;
  overflow-y: auto;
  button,
  img,
  .label {
    display: block;
    padding: 4px 0;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  img {
    border: solid 2px currentColor;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  button {
    @include triggered {
      color: var(--c__accent);
    }
  }
}
</style>
