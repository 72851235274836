import BaseReadinessChecker from './baseReadinessChecker';

export default class MockedReadinessCheckerService extends BaseReadinessChecker {
  constructor() {
    super();
    this.meetingSession = null;
    this.chimeLogger = {};
  }

  onMeetingSessionInitialized(session = {}) {
    this.meetingSession = session;
    this.meetingReadinessChecker = {};
  }

  async checkNetworkConnectivity() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: true });
      }, 5000);
    });
  }

  async checkAudioOutput() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: true });
      }, 5000);
    });
  }

  async checkAudioInput() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: true });
      }, 5000);
    });
  }

  async checkVideoInput() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: true });
      }, 5000);
    });
  }

  async checkContentShareConnectivity() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ status: true });
      }, 5000);
    });
  }
}
