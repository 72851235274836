export default () => (to, from, next) => {
  const roomInvitationDetails = { id: to.params.pinNumber, pwd: to.query.pwd };
  const { user } = window.__INITIAL_STATE__;
  const isAuthenticated = user && user.id;

  if (isAuthenticated || to.query['requested-otp']) {
    next();
    return;
  }

  return next(`/joining-options?id=${roomInvitationDetails.id}&pwd=${roomInvitationDetails.pwd}&flow=pr-invite`);
};
