<template>
  <g :id="id" class="mapItem" :class="[classes, item.color, item.type]" :opacity="adding ? 0.5 : 1">
    <rect
      v-if="hasDots"
      class="sizingDots"
      ref="sizingDots"
      :width="item.width"
      :height="item.height"
      :x="x"
      :y="y"
      stroke-width="1"
      stroke="#aaaaaa"
      stroke-dasharray="4"
      fill="white"
      fill-opacity="0"
    />
    <rect
      class="shape"
      ref="box"
      v-if="item.type !== 'text'"
      :width="item.width - 10"
      :height="item.height - 10"
      :x="x + 5"
      :y="y + 5"
      @dblclick="edit"
      @mousedown.exact="drag"
      @mousedown.alt="newFromCopy"
      :style="shapeStyles"
      stroke-width="10"
      :stroke="cardStroke"
      :fill="mini ? cardStroke : '#ffffff'"
      :fill-opacity="item.color === 'none' ? 0 : 1"
      :stroke-opacity="item.color === 'none' ? 0 : 1"
    />

    <rect
      v-if="showControls && advanced"
      ref="resizerBr"
      class="handle control"
      width="16"
      height="16"
      :x="x + item.width - 8"
      :y="y + item.height - 8"
      @mousedown="startResize('br', $event)"
    />
    <rect
      v-if="showControls && advanced"
      ref="resizerTl"
      class="handle control"
      width="16"
      height="16"
      :x="x - 8"
      :y="y - 8"
      @mousedown="startResize('tl', $event)"
    />
    <g :clip-path="`url('#clip-text-${item.id}')`" class="itemText">
      <text
        v-if="!viewFinder && !adding && !isEditing"
        @dblclick="edit"
        :y="textY"
        :fill="textColor"
        :font-size="fontSize"
        v-wrapper="{
          paddingLeft: item.x + item.width / 2,
          width: item.width + item.x + item.width / 2 - 40,
          text: item.text,
          x: item.x + item.width / 2,
          lineHeight: '1em',
          afterReflow: reflow,
        }"
      />
    </g>
    <rect
      class="borderOver"
      ref="borderOver"
      v-if="item.type !== 'text' && item.color"
      :width="item.width - 10"
      :height="item.height - 10"
      :x="x + 5"
      :y="y + 5"
      stroke-width="10"
      :stroke="cardStroke"
      fill="none"
      :stroke-opacity="item.color === 'none' ? 0 : 1"
    />

    <g v-if="item.logType !== 'note' && !viewFinder" :transform="iconGroupTransform">
      <g v-if="item.logType === 'insight'" transform="translate(-16 -17)">
        <rect
          :stroke="typeColor.insight"
          fill="#ffffff"
          x="7.21"
          y="7.21"
          width="28"
          height="28"
          transform="translate(-8.79 21.21) rotate(-45)"
          stroke-width="2"
        />
        <path :fill="typeColor.insight" d="M17.69,28a1.23,1.23,0,1,1,2.46,0,1.23,1.23,0,0,1-2.46,0Zm.54-3.18-.36-10.75H20l-.36,10.75Z" />
        <path :fill="typeColor.insight" d="M22.84,28a1.23,1.23,0,1,1,2.46,0,1.23,1.23,0,0,1-2.46,0Zm.54-3.18L23,14.06h2.11l-.36,10.75Z" />
      </g>
      <g v-if="item.logType === 'question'" transform="translate(-12 -12)">
        <circle :stroke="typeColor.question" fill="#ffffff" cx="16" cy="16" r="15" stroke-width="2" />
        <path
          :fill="typeColor.question"
          d="M17.1,15.36v2.55H15.26L15,14.33c2.49-.83,4-2.26,4-3.79,0-1.21-.85-1.9-2.31-1.9a8.39,8.39,0,0,0-3.56.87L12.35,8a10.75,10.75,0,0,1,4.82-1.3c2.51,0,4.14,1.39,4.14,3.52S19.83,14.11,17.1,15.36Zm-.85,6.77a1.37,1.37,0,1,1,0-2.73,1.37,1.37,0,0,1,0,2.73Z"
          transform="translate(-0.75, 1.5)"
        />
      </g>
      <g v-if="item.logType === 'decision'" transform="translate(-10 -7)">
        <path :stroke="typeColor.decision" fill="#ffffff" d="M2.62,2H27.38L15,26.76Z" stroke-width="2" />
      </g>
      <g v-if="item.logType === 'action'" transform="translate(-7 -10)">
        <path :stroke="typeColor.action" fill="#ffffff" d="M2,27.38V2.62L26.76,15Z" stroke-width="2" />
      </g>
    </g>

    <rect
      v-if="showControls && !isEditing"
      class="editBackground control"
      :x="x + item.width / 2 - 50"
      :y="y + item.height + em"
      width="100"
      :height="em * 2"
      :rx="em"
      @click="edit"
    ></rect>

    <text
      v-if="showControls && !isEditing"
      ref="edit"
      class="edit control"
      width="60"
      :height="em"
      :x="x + item.width / 2"
      :y="y + item.height + em * 2"
      text-anchor="middle"
      alignment-baseline="middle"
      @click="edit"
      >{{ editLabel }}</text
    >
  </g>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMappingStore } from '@/store/pinia/mapping';
import { useFrameStore } from '@/store/pinia/frame';
import { useMouse } from '@vueuse/core';
import wrapper from '@/directives/svgTextWrap.directive';

const cardStrokes = {
  blue: '#00a2ff',
  red: '#ff5a50',
  orange: '#faaf3c',
  green: '#b2d235',
  yellow: '#f5d010',
  white: '#cccccc',
  black: '#000000',
  purple: '#9a00de',
  none: '#ffffff',
};

const typeColor = {
  decision: '#00a2ff',
  question: '#faaf3c',
  action: '#b2d235',
  insight: '#f5d010',
};

export default {
  name: 'MappingItem',
  data() {
    return {
      dragOffsetX: null,
      dragOffsetY: null,
      dragging: false,
      resizing: false,
      lineHeight: 1,
      lines: 1,
      useDynamicTextSize: true,
    };
  },
  props: {
    item: Object,
    clean: Boolean,
    mini: Boolean,
    viewFinder: Boolean,
    working: Boolean,
  },
  directives: {
    wrapper,
  },
  setup() {
    const frameStore = useFrameStore();
    const mappingStore = useMappingStore();
    const { x: mouseX, y: mouseY } = useMouse();
    return { frameStore, mappingStore, mouseX, mouseY };
  },
  computed: {
    ...mapPiniaState(useMappingStore, {
      draggingItem: 'dragging',
      resizingItem: 'resizing',
      addingItem: 'adding',
      editingItem: 'editing',
    }),
    ...mapPiniaState(useMappingStore, ['items', 'advanced', 'viewBoxX', 'viewBoxY', 'zoom', 'em', 'snap', 'grid', 'backToEdit', 'typeScale', 'zoomMultiplier']),
    ...mapPiniaState(useFrameStore, {
      alt: 'alt',
      frameW: 'contWidth',
      frameH: 'contHeight',
    }),
    textHeight() {
      return this.fontSizeRaw * this.lineHeight * this.lines;
    },
    textHeightBaseline() {
      // let baselineMod = 1.42 + 0.22 * (this.lines - 1); // this works for large

      let baselineMod = this.fontSizeRaw * this.lineHeight * 1.525 + this.fontSizeRaw * this.lineHeight * 0.22 * (this.lines - 1);
      return this.fontSizeRaw * this.lineHeight * this.lines - baselineMod;
    },
    textY() {
      return (this.item.height - this.textHeightBaseline) / 2 + this.y;
    },
    font() {
      return this.clean ? 'proximaNovaAltRegular' : false;
    },
    dynamicTextSize() {
      if (this.item.text.length < 1) {
        return 1;
      }

      let length = this.item.text.length;
      let words = this.item.text.split(' ');
      let wordCount = words.length;

      let check;

      let longestWord = words.reduce((longest, currentWord) => {
        if (currentWord.length > longest.length) {
          return currentWord;
        } else {
          return longest;
        }
      }, '');

      let longestWordCount = longestWord.length;

      if (wordCount < 3) {
        check = longestWordCount;
      } else if (length < 10) {
        check = length;
      } else {
        check = Math.sqrt(length) * 0.86;
      }

      if (check < longestWordCount) {
        check = longestWordCount;
      }

      let size = this.item.width / check;
      return size / this.em;
    },
    fontSize() {
      if (this.useDynamicTextSize) {
        return this.dynamicTextSize + 'em';
      } else {
        return this.item.fontSize ? Math.pow(this.typeScale, this.item.fontSize) + 'em' : '1em';
      }
    },
    fontSizeRaw() {
      if (this.useDynamicTextSize) {
        return this.dynamicTextSize * this.em;
      } else {
        return this.item.fontSize ? Math.pow(this.typeScale, this.item.fontSize) * this.em : this.em;
      }
    },
    hasDots() {
      return !this.clean && (this.item.type !== 'card' || this.item.color === 'none');
    },
    id() {
      return this.clean ? 'mapItem-' + this.item.localId : 'liveItem-' + this.item.localId;
    },
    shapeStyles() {
      let styles = {};

      if (this.frameStore.alt) {
        styles.cursor = this.dragOffsetX ? 'grabbing' : 'copy';
      } else {
        styles.cursor = this.dragOffsetX ? 'grabbing' : 'grab';
      }

      if (!this.viewFinder) {
        styles.filter = 'url(#shadow)';
      }

      return styles;
    },
    lineHeightEm() {
      return this.lineHeight + 'em';
    },
    editable() {
      return !this.isDragging && !this.isResizing;
    },
    classes() {
      return {
        'dragged-item': this.dragging,
        'resized-item': this.resizing,
        editing: this.isEditing,
      };
    },
    editLabel() {
      // return this.item.text ? "Edit" : "Add Text";
      return 'Edit';
    },
    cardStroke() {
      return cardStrokes[this.item.color];
    },
    typeColor() {
      return typeColor;
    },
    textColor() {
      //return this.item.color === "black" ? "#ffffff" : "#222222";
      return '#222222';
    },
    showControls() {
      return !this.clean && !this.adding;
    },
    adding() {
      return this.working && this.addingItem !== false && this.addingItem === this.item.localId;
    },
    isEditing() {
      return this.working && this.editingItem !== false && this.editingItem === this.item.localId;
    },
    isDragging() {
      return this.working && this.draggingItem !== false && this.draggingItem === this.item.localId;
    },
    isResizing() {
      return this.working && this.resizingItem !== false && this.resizingItem === this.item.localId;
    },
    x() {
      return this.adding ? (this.mouseX + this.viewBoxX) * this.zoomMultiplier - this.item.width / 2 : this.item.x;
    },
    y() {
      return this.adding ? (this.mouseY + this.viewBoxY) * this.zoomMultiplier - this.item.height / 2 : this.item.y;
    },
    iconGroupTransform() {
      return `translate(${this.x} ${this.y})`;
    },
  },
  watch: {
    'frameStore.mouseDown': function (nv) {
      if (!nv) {
        this.stopResize('mouseState', {});
        this.drop();
        if (this.adding) {
          this.placeItem();
        }
      }
    },
    isDragging(nv) {
      if (!nv && !this.adding) {
        this.snapFinish();
      } else if (nv) {
        this.drag({
          offsetX: this.mouseX,
          offsetY: this.mouseY,
        });
      }
    },
    isResizing(nv) {
      if (!nv) {
        this.snapFinish();
      }
    },
  },
  methods: {
    placeItem() {
      this.$nextTick(() => {
        this.updateItem({
          x: this.snapVal(this.x),
          y: this.snapVal(this.y),
        });
        this.mappingStore.setAdding(false);

        this.$nextTick(() => {
          this.$ee.emit('nM:dialogue:boxAdded', this.item);
          this.$nextTick(() => {
            this.$ee.emit('nM:dialogue:boxMoved');
            this.mappingStore.setEditingMapItem(this.item.localId);
          });
        });
      });
    },
    drag({ offsetX, offsetY }) {
      this.dragOffsetX = offsetX * this.zoomMultiplier - this.item.x;
      this.dragging = true;
      this.dragOffsetY = offsetY * this.zoomMultiplier - this.item.y;
      this.mappingStore.setDragging(this.item.localId);
      window.addEventListener('mousemove', this.move);
    },
    drop() {
      if (this.dragging) {
        this.dragging = false;
        this.mappingStore.setDragging(false);
        this.dragOffsetX = this.dragOffsetY = null;
        window.removeEventListener('mousemove', this.move);
        this.doneChanging();
      }
    },
    startDrag() {},
    startResize(type) {
      this.resizing = true;
      this.mappingStore.setResizingItem(this.item.localId);
      if (type === 'br') {
        window.addEventListener('mousemove', this.resizeBr);
      }
      if (type === 'tl') {
        window.addEventListener('mousemove', this.resizeTl);
      }
    },
    stopResize(type, { buttons }) {
      if (this.resizing) {
        this.resizing = false;
        this.mappingStore.setResizingItem(false);
        if (type === 'mouseState' || !buttons) {
          window.removeEventListener('mousemove', this.resizeBr);
          window.removeEventListener('mousemove', this.resizeTl);
        }
        this.doneChanging();
      }
    },
    resizeBr({ movementX, movementY }) {
      let width = movementX * this.zoomMultiplier + this.item.width;
      let height = movementY * this.zoomMultiplier + this.item.height;
      this.updateItem({
        width: width >= this.grid ? width : this.grid,
        height: height >= this.grid ? height : this.grid,
      });
    },
    resizeTl({ movementX, movementY }) {
      let width = this.item.width - movementX * this.zoomMultiplier;
      let height = this.item.height - movementY * this.zoomMultiplier;
      this.updateItem({
        x: width >= this.grid ? this.item.x + movementX * this.zoomMultiplier : this.item.x,
        y: height >= this.grid ? this.item.y + movementY * this.zoomMultiplier : this.item.y,
        width: width >= this.grid ? width : this.grid,
        height: height >= this.grid ? height : this.grid,
      });
    },
    move({ offsetX, offsetY }) {
      this.updateItem({
        x: this.snapVal(offsetX * this.zoomMultiplier - this.dragOffsetX),
        y: this.snapVal(offsetY * this.zoomMultiplier - this.dragOffsetY),
      });
    },
    snapFinish() {
      this.updateItem({
        x: this.snapVal(this.item.x),
        y: this.snapVal(this.item.y),
        width: this.snapVal(this.item.width),
        height: this.snapVal(this.item.height),
      });
    },
    snapVal(val) {
      if (!this.snap || this.dragging || this.resizing) {
        return val;
      } else {
        return Math.round(val / this.grid) * this.grid;
      }
    },
    doneChanging() {
      this.$ee.emit('nM:dialogue:boxMoved');
      if (this.backToEdit !== false) {
        this.edit();
      }
    },
    updateItem(args) {
      this.mappingStore.updateItem({ localId: this.item.localId, ...args });
    },
    reflow(tE) {
      let doIt = true;

      if (doIt) {
        const spans = tE.querySelectorAll('tspan');
        const lineLimit = Math.floor(this.item.height / (this.fontSizeRaw * this.lineHeight));

        this.lines = lineLimit > spans.length ? spans.length : lineLimit;

        spans.forEach((span, i) => {
          span.setAttributeNS(null, 'text-anchor', 'middle');
          if (i === lineLimit - 1 && spans.length > lineLimit) {
            span.setAttributeNS(null, 'opacity', 0.5);
          } else if (i === lineLimit && spans.length > lineLimit) {
            span.setAttributeNS(null, 'opacity', 0.25);
          } else if (i > lineLimit) {
            span.parentElement.removeChild(span);
          } else {
            span.setAttributeNS(null, 'opacity', 1);
          }
        });
      }
    },
    edit() {
      this.mappingStore.setBackToEdit(this.item.localId);
      this.mappingStore.setEditingMapItem(this.item.localId);
    },
    newFromCopy() {
      let id = Object.keys(this.items).length;
      this.mappingStore.setAdding(id);
      let args = {
        ...this.item,
        id,
      };
      this.mappingStore.addMappingItem(args);
      this.mappingStore.setEditingMapItem(false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.itemText {
  pointer-events: none;
}
.handle {
  fill: white;
  stroke: black;
  stroke-width: 2;
  cursor: nwse-resize;
}

.control {
  opacity: 0;
  transition: opacity 0.2s, fill 0.2s;
}

.shape {
  transition: fill 300ms;
  .text & {
    stroke-width: 1;
    stroke: #666666;
    fill: white;
    fill-opacity: 0;
    stroke-dasharray: 4;
  }
}

.sizingDots {
  opacity: 0;
}

.mapItem:hover {
  .control,
  .sizingDots {
    opacity: 1;
  }
}

.mapItem.editing {
  .sizingDots {
    opacity: 1;
  }
}

.editBackground,
.duplicateBackground {
  fill: white;
  stroke: $c_blue;
  stroke-width: 2;
  cursor: pointer;
  &:hover {
    fill: $c_blue;
  }
}

text.edit,
text.duplicate {
  fill: $c_blue;
  pointer-events: none;
}

.editBackground:hover + text.edit,
.duplicateBackground:hover + text.duplicate {
  fill: white;
}

.dragged-item,
.resized-item {
  .control.editBackground,
  text.edit.control {
    opacity: 0;
  }
}

.dragged-item {
  .control.handle {
    opacity: 0;
  }
}

.borderOver {
  pointer-events: none;
}
</style>
