<template>
  <a
    type="button"
    class="controlsHoverTarget"
    :class="{ me: isMe, focused: hovered, hasControls: controlsList.length > 0, away: user.away, offline: !user.connected }"
    :style="{ top: pos.top + 'px', left: pos.left + 'px', width: pos.w + 'px', height: pos.w + 'px' }"
    v-if="!moving"
    :value="`Toggle controls for ${user.first_name}`"
    @mouseover="hover"
    @mouseleave="unHover"
  >
    Toggle controls for {{ user.first_name }}</a
  >

  <div
    v-if="!notHere"
    class="controlsContainer"
    :class="{ focused: hovered, ...classes }"
    :style="{ top: contTop, left: contLeft, width: wControlsCont, height: wControlsCont, maskImage: mask }"
  >
    <transition-group
      tag="div"
      class="userControls"
      name="avatars"
      :style="{ width: wControls, height: wControls, top: '50%', left: '50%' }"
      @enter="showControl"
      @leave="hideControl"
      v-click-outside="clickOutsideControls"
    >
      <button
        v-for="control in controlsList"
        :key="control"
        :ref="(el) => (controls[control] = el)"
        class="button user-btn"
        :class="control"
        :data-button-name="control"
        :data-control="control"
        @click="handleControl(control, $event)"
        @mouseover="hoverControl(control)"
        @mouseleave="unHoverControl(control)"
      >
        <app-icon :icon="control + '-control'" class="contolIcon">
          {{ control }}
        </app-icon>
      </button>
    </transition-group>

    <transition-group
      tag="div"
      class="userControlsBGs"
      name="avatars"
      :style="{ width: wControls, height: wControls, top: '50%', left: '50%' }"
      @enter="showControl"
      @leave="hideControl"
    >
      <div
        v-for="control in controlsList"
        class="shadow"
        :key="control + '-shadow'"
        :ref="(el) => (shadows[control] = el)"
        :class="control"
        :data-control="control"
      />
      <div
        v-for="control in controlsList"
        class="matte"
        :key="control + '-matte'"
        :ref="(el) => (mattes[control] = el)"
        :class="control"
        :data-control="control"
        @mouseover="hover"
        @mouseleave="unHover"
      />
      <div
        v-for="(control, i) in controlsList"
        :key="control + '-matteExtra'"
        :ref="(el) => (matteExtras[control] = el)"
        class="matteExtra"
        :class="control"
        :data-control="control"
        data-extra="true"
        :data-first="i === 0 ? true : null"
      />
    </transition-group>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { storeToRefs } from 'pinia';
import { computed, ref, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { useReady } from '@/composables/useReady';
import AppIcon from '@/components/AppIcon.vue';
import { useFeaturesStore } from '@/store/pinia/features';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';
import * as geometry from '@/resources/geometry';
import { CONTROLS_PAD, AVATAR_BUTTON } from '@/resources/constants/frame-constants';

const fullEmoteList = ['eek', 'what', 'smile', 'sad', 'uhh', 'grr', 'heart', 'star', 'confetti', 'wave', 'toggleHand', 'yes', 'no'];

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const { readyForAvatars } = useReady();

const featuresStore = useFeaturesStore();
const sidebarStore = useSidebarStore();
const meetingStore = useMeetingStore();
const messagesStore = useMessagesStore();
const frameStore = useFrameStore();
const phaseStore = usePhaseStore();
const usersStore = useUsersStore();
const pageStore = usePageStore();

const props = defineProps(['user', 'pos', 'index', 'count', 'homeAngle', 'notHere']);
const emit = defineEmits(['showControls', 'hideControls']);

const showEmotes = ref(false);
const mouse = ref(false);
const loaded = ref(true); //TODO: make this dynamic
const containerPad = ref(100);
const tempHideControls = ref(false);

// elements
const controls = ref({});
const mattes = ref({});
const matteExtras = ref({});
const shadows = ref({});

onMounted(() => {
  $ee.on('nM:userButton:allEmotes', handleAllEmotes);
  if (isMe.value) {
    $ee.on('nM:emote:key', sendEmote);
  }
});

onBeforeUnmount(() => {
  $ee.off('nM:userButton:allEmotes', handleAllEmotes);
  if (isMe.value) {
    $ee.off('nM:emote:key', sendEmote);
  }
});

const { emoteList } = storeToRefs(meetingStore);
const { fullscreen, mouseMoving, hoveredControl } = storeToRefs(frameStore);
const {
  moving: movingList,
  aboutToMove: aboutToMoveList,
  hovered: hoveringList,
  myId,
  iAmOwner,
  iAmInWaitingRoom,
  usersVisible,
  isAway,
  firstShown,
  posCallTs,
  isHovered,
  isOwner,
  recommendedModerators,
  isMeetingModerator,
  iAmModerator,
} = storeToRefs(usersStore);
const { currentPhase, mirroring } = storeToRefs(phaseStore);
const { perf } = storeToRefs(featuresStore);
const { isAvatarsDockDrawer } = storeToRefs(pageStore);

const id = computed(() => props.user.id);
const isMe = computed(() => props.user.id === myId.value);
const hovered = computed(() => isHovered.value(props.user.id));
const moving = computed(() => movingList.value.has(props.user.id));
const aboutToMove = computed(() => aboutToMoveList.value.has(props.user.id));
const wControls = computed(() => props.pos.w + CONTROLS_PAD + 'px');
const wControlsCont = computed(() => props.pos.w + CONTROLS_PAD + containerPad.value + 'px');
const contTop = computed(() => props.pos.top - (props.pos.w + CONTROLS_PAD + containerPad.value) / 2 + 'px');
const contLeft = computed(() => props.pos.left - (props.pos.w + CONTROLS_PAD + containerPad.value) / 2 + 'px');
const mask = computed(() => `radial-gradient(ellipse, rgba(0, 0, 0, 0) ${props.pos.w / 2}px, rgba(0, 0, 0, 1) ${props.pos.w / 2 + 2}px, black 100%)`);

const slice = computed(() => {
  return geometry.sliceAngle({
    distance: wControls.value,
    width: AVATAR_BUTTON * 1.5,
  });
});

const classes = computed(() => {
  return {
    me: isMe.value,
    notMe: !isMe.value,
    showingControls: controlsList.value.length > 0,
    owner: props.user.owner,
    user: !props.user.owner,
    offline: !props.user.connected,
    mute: props.user.muted || (isMe.value && isAway.value(props.user.id)),
    sysMute: props.user.muted_by_system,
    muteVideo: props.user.video_muted,
    sysMuteVideo: props.user.video_muted_by_system,
    moving: moving.value || aboutToMove.value,
  };
});

// const controlPointerEvents = computed(() => (hovered.value ? 'auto' : 'none'));
const canFitEmotes = computed(() => {
  const sliceTotal = slice.value * fullEmoteList.length;
  return sliceTotal < 2 * Math.PI;
});

const controlsLength = computed(() => controlsList.value.length);
const controlsList = computed(() => {
  if (
    !(hovered.value || mouse.value) ||
    !firstShown.value ||
    !readyForAvatars.value ||
    !props.user.connected ||
    moving.value ||
    (props.notHere && !isMe.value) ||
    !loaded.value ||
    tempHideControls.value
  ) {
    return [];
  } else if (isMe.value) {
    if (showEmotes.value) {
      return fullEmoteList;
    } else {
      let controlList = [];
      let extra = 'eek';
      if (['lobby', 'endMeeting', 'ended'].includes(currentPhase.value) || iAmInWaitingRoom.value) {
        controlList = ['yes', 'wave', 'muteVideo', 'mute', 'settings', 'no'];
      } else if (currentPhase.value === 'decision') {
        controlList = ['smile', 'sad', 'muteVideo', 'mute', 'settings', 'grr'];
        extra = 'confetti';
      } else if (currentPhase.value === 'checkIn' || currentPhase.value === 'checkOut') {
        controlList = ['heart', 'smile', 'muteVideo', 'mute', 'settings', 'sad'];
        extra = 'grr';
      } else {
        controlList = ['yes', 'what', 'muteVideo', 'mute', 'settings', 'no'];
      }
      if (canFitEmotes.value) {
        controlList.push('allEmotes');
      } else {
        controlList.push(extra);
      }
      return controlList;
    }
  } else if (iAmModerator.value) {
    const adminControls = ['muteVideo', 'mute'];

    if (!isOwner.value(id.value)) {
      adminControls.push('eject');
    }

    if (iAmOwner.value && !recommendedModerators.value.includes(id.value) && !isAvatarsDockDrawer.value) {
      if (!isMeetingModerator.value(id.value)) {
        adminControls.push('promote');
      } else {
        adminControls.push('demote');
      }
    }
    if (mirroring.value?.requestedBy === id.value) {
      return adminControls.concat('stopScreenShare');
    }
    return adminControls;
  } else {
    return [];
  }
});

const controlPositions = computed(() => {
  const sliceTotal = slice.value * controlsList.value.length;
  var startAngle = props.homeAngle - sliceTotal / 2;

  if (showEmotes.value && isMe.value) {
    if (props.homeAngle > 0) {
      startAngle = props.homeAngle - slice.value * 6.5; // 6.5 is a magic number for how long the default controls are atm
    } else {
      startAngle = props.homeAngle + slice.value * 2.5; // 2.5 is a magic number for how long the default controls are atm
    }
  }

  var currentAngle = startAngle;
  const pos = {};
  const controlsR = parseInt(wControls.value) / 2;
  controlsList.value.forEach((c, i) => {
    pos[c] = geometry.circlePoint({
      r: controlsR,
      angle: currentAngle + slice.value / 2,
      cX: controlsR,
      cY: controlsR,
    });

    pos[c].extra = geometry.circlePoint({
      r: props.pos.w / 2,
      angle: currentAngle,
      cX: controlsR,
      cY: controlsR,
    });

    if (showEmotes.value) {
      pos[c].delay = i * 10;
      pos[c].extra.delay = i * 10;
    } else {
      pos[c].delay = 0;
      pos[c].extra.delay = 0;
    }

    currentAngle += slice.value;
  });
  return pos;
});

let mouseStopTimeout;
watch(mouseMoving, (nv) => {
  if (isMe.value) {
    if (nv && !fullscreen.value && currentPhase.value !== 'dialogue') {
      if (!moving.value) {
        clearTimeout(mouseStopTimeout);
        mouse.value = true;
      }
    } else {
      mouseStopTimeout = setTimeout(() => {
        mouse.value = false;
      }, 300);
    }
  }
});

watch(controlsLength, (nv, ov) => {
  if (nv < 1) {
    emit('hideControls');
    if (isMe.value) {
      showEmotes.value = false;
    }
  } else {
    emit('showControls');
  }
  if (nv && ov) {
    let toMove = _.intersection(nv, ov);
    if (toMove.length !== nv) {
      moveControls(toMove);
    }
  }
});

watch(canFitEmotes, (nv) => {
  if (!nv) {
    showEmotes.value = false;
  }
});

watch(showEmotes, (nv) => {
  featuresStore.setShowAllEmotes(nv);
});

watch(posCallTs, () => {
  usersStore.setAboutToMove(id.value, true);
  mouse.value = false;
});

function hover() {
  if (!isMe.value && hoveringList.value.has(myId.value)) {
    // If your own controls are up, don't hover anyone else
    return false;
  }
  usersStore.onHover(id.value, 'controls');
}

function hoverControl(control) {
  hover();
  frameStore.updateHoveredControl({
    type: 'userControl',
    userId: id.value,
    control,
  });
}

function unHover() {
  usersStore.onUnHover(id.value, 'controls');
}

function unHoverControl(control) {
  unHover();
  _.delay(() => {
    if (hoveredControl.value.control === control) {
      frameStore.updateHoveredControl(false);
    }
  }, 200);
}

function clickOutsideControls(e) {
  if (!e.target.classList.contains('user-btn')) {
    //showEmotes.value = false;
    usersStore.onUnHover(id.value, 'clickOutside');
    mouse.value = false;
  }
}

function hideControl($e, done) {
  Velocity(
    $e,
    {
      top: '50%',
      left: '50%',
    },
    {
      duration: 300,
      complete: done,
    },
  );
}

function showControl($e, done) {
  let c = $e.dataset.control;
  if ($e.dataset.extra) {
    if (!$e.dataset.first) {
      Velocity(
        $e,
        {
          top: [controlPositions.value[c].extra.top, '50%'],
          left: [controlPositions.value[c].extra.left, '50%'],
        },
        {
          duration: 300,
          delay: controlPositions.value[c].extra.delay,
          complete: done,
        },
      );
    }
  } else {
    Velocity(
      $e,
      {
        top: [controlPositions.value[c].top, '50%'],
        left: [controlPositions.value[c].left, '50%'],
      },
      {
        duration: 300,
        delay: controlPositions.value[c].delay,
        complete: done,
      },
    );
  }
}

function moveControls(list) {
  list.forEach((c) => {
    if (controls.value[c]) {
      [controls, mattes, shadows].forEach((items) => {
        Velocity(
          items.value[c],
          {
            top: controlPositions.value[c].top,
            left: controlPositions.value[c].left,
          },
          {
            duration: 300,
          },
        );
      });

      if (controlsList.value[0] === c) {
        // if we are first in the list
        Velocity(
          matteExtras[c],
          {
            top: '50%',
            left: '50%',
          },
          {
            duration: 300,
          },
        );
      } else {
        Velocity(
          matteExtras[c],
          {
            top: controlPositions.value[c].extra.top,
            left: controlPositions.value[c].extra.left,
          },
          {
            duration: 300,
          },
        );
      }
    }
  });
}

function handleControl(c, e) {
  let doNothing = false;
  if (c === 'settings') {
    if (isAvatarsDockDrawer.value) {
      ipcApi.send({ name: 'hideAvatarDockDrawer', restoreMainWindow: true, sidebarType: 'settings' });
    } else if (fullscreen.value) {
      sidebarStore.requestPane('settings');
      $ee.emit('bus:settingsPlease');
    } else {
      sidebarStore.toggleSettings();
    }
  } else {
    if (isMe.value) {
      // I'm not the CH
      if (c === 'mute' && props.user.muted && props.user.muted_by_system) {
        messagesStore.addAlert('tryPushToTalk');
        doNothing = true;
      }
      if (c === 'muteVideo' && props.user.video_muted_by_system) {
        $ee.emit('nM:mute:cannotUnmute');
        doNothing = true;
      }
    } else if (iAmModerator.value) {
      if (c === 'mute' && props.user.muted) {
        messagesStore.addAlert('cannotUnmuteMutedUser');
        doNothing = true;
      }
      if (c === 'muteVideo' && props.user.video_muted) {
        doNothing = true;
        messagesStore.addAlert('cannotUnVideoMuteMutedUser');
      }
    }

    if (!doNothing) {
      var args = {
        id: myId.value,
        about: id.value,
        iAmOwner: iAmOwner.value,
      };

      if (c === 'mute') {
        args.muted = props.user.muted;
        args.sysmuted = props.user.muted_by_system;
        args.unmuteInstantly = true;
      } else if (c === 'muteVideo') {
        args.muted = props.user.video_muted;
        args.sysmuted = props.user.video_muted_by_system;
        args.targetElementId = `video-user-${id.value}`;
      }

      if (emoteList.value.includes(c)) {
        sendEmote(c);
      } else {
        $ee.emit('nM:userButton:' + c, args);
      }
    }
  }
  e.target.blur();
}

function handleAllEmotes() {
  tempHideControls.value = true;
  _.delay(() => {
    showEmotes.value = true;
    tempHideControls.value = false;
  }, 100);
}

function sendEmoteRaw(c) {
  $ee.emit(`nM:avatar:emote:${id.value}`, c);
  if (iAmInWaitingRoom.value) {
    return;
  }
  if (!perf.value.fakeEmotes) {
    $meetingmanager.emote(c);
  } else {
    if (usersVisible.length > 1) {
      let list = _.sample(
        usersVisible.filter((u) => !u.me),
        3,
      );
      list.forEach((u) => {
        $ee.emit(`nM:avatar:emote:${u.id}`, c);
      });
    }
  }
}
const sendEmote = _.throttle(sendEmoteRaw, 500);
</script>
<style lang="scss" scoped>
.controlsContainer,
.controlsHoverTarget {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0) translateY(0);
  //backface-visibility: hidden;
  transition: top 0.6s cubic-bezier(0.45, 0, 0.15, 1), left 0.6s cubic-bezier(0.45, 0, 0.15, 1), transform 0.3s cubic-bezier(0.45, 0, 0.15, 1),
    opacity 0.3s ease;

  //background: rgba(red, 0.2);
  border-radius: 50%;

  //transition: opacity 0.2s;

  .bad-cpu & {
    transition: none;
  }

  &.away,
  &.offline {
    pointer-events: none;
  }
}

.controlsContainer.moving {
  display: none;
  transition: none;
}

.controlsHoverTarget,
.userControls,
.userControlsBGs {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.controlsHoverTarget {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $z__avatar-base-controls-target;

  &.me,
  &.focused {
    z-index: $z__avatar-focus-controls-target;
  }
}

.userControls {
  // z-index: $z__avatar-base-controls + 1;
  z-index: 2;
}
.userControlsBGs {
  //z-index: $z__avatar-base-controls;
  z-index: 1;
}

.userControls,
.userControlsBGs {
  transition: all 300ms;

  .moving & {
    opacity: 0;
  }
  .away & {
    display: none;
    opacity: 0;
    transform: scale(0);
  }
}

.controlsContainer {
  z-index: $z__avatar-base-controls;
  &.me,
  &.focused {
    z-index: $z__avatar-focus-controls;
  }
}

$user-button-matte-size: 80px;

.userControlsBGs {
  opacity: var(--opacity_controls);
  .simplified & {
    opacity: 1;
  }

  .matte,
  .shadow {
    touch-action: manipulation;
    top: 50%;
    left: 50%;
    position: absolute;
    border-radius: 50%;
    height: rem($user-button-matte-size);
    width: rem($user-button-matte-size);
    margin: rem(0 - math.div($user-button-matte-size, 2)) 0 0 rem(0 - math.div($user-button-matte-size, 2));
  }

  .matte {
    background: var(--c__bg);
    border-width: 2px;
    border-color: transparent;
    border-style: var(--border-style__avatar);
    background-clip: padding-box;
  }

  .matteExtra {
    top: 50%;
    left: 50%;
    position: absolute;
    background: var(--c__bg);
    height: rem(math.div($user-button-matte-size, 2));
    width: rem(math.div($user-button-matte-size, 2));
    border-radius: 50%;
    margin: rem(0 - math.div($user-button-matte-size, 4)) 0 0 rem(0 - math.div($user-button-matte-size, 4));
  }

  .shadow {
    background: var(--c__shadow);
    box-shadow: var(--shadow__controls);
    border: solid 2px var(--c__avatar-border-edge);
    .simplifiedGraphics &,
    .bad-cpu &,
    .low-cpu & {
      box-shadow: none;
      border: 4px solid rgba(0, 0, 0, 0.8);
      border-top: 2px solid rgba(0, 0, 0, 0.8);
      border-left: 2px solid rgba(0, 0, 0, 0.8);
      @include dark-mode('neon') {
        border: solid 2px var(--c__avatar-border-edge);
      }
    }
  }
}

.user-btn {
  touch-action: manipulation;
  margin: rem(-20px) 0 0 rem(-20px);
  height: rem(40px);
  width: rem(40px);
  border-radius: 50%;
  border: none;
  padding: 0;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  &:hover,
  &:active {
    background: transparent;
    border: none;
  }
  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  span,
  :deep(svg),
  .controlIcon {
    pointer-events: none;
  }

  &.mute,
  &.muteVideo,
  &.settings,
  &.stopScreenShare,
  &.eject,
  &.promote {
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }
  }

  &.demote {
    color: var(--c__accent);
    @include triggered {
      color: var(--c__accent-alt);
    }
  }

  &.stopScreenShare {
    transition: background-color 250ms;
    @include triggered {
      background-color: var(--c__accent);
    }
  }

  &.allEmotes {
    color: var(--c__accent);
  }

  @each $button in $emotes {
    &.#{$button} {
      color: var(--c__emote-#{$button});
    }
  }
}

.controlIcon,
.user-btn :deep(svg) {
  height: rem(40px);
  width: rem(40px);
  margin: none;
  pointer-events: none;

  * {
    transition: all 250ms;
  }
}

.user-btn.toggleHand {
  color: var(--c__question);
}

.mute .user-btn.mute,
.muteVideo .user-btn.muteVideo {
  color: var(--c__accent);
  @include triggered {
    color: var(--c__accent-alt);
  }
  :deep(.hover-stroke) {
    stroke: currentColor;
  }
  :deep(.hover-revStroke) {
    stroke: var(--c__bg);
  }
  :deep(.hover-fill) {
    fill: currentColor;
  }
  :deep(.hover-revFill) {
    fill: var(--c__bg);
  }
}

.user-btn.confetti {
  :deep(.blue) {
    color: var(--c__blue);
  }
  :deep(.green) {
    color: var(--c__green);
  }
  :deep(.yellow) {
    color: var(--c__yellow);
  }
  :deep(.orange) {
    color: var(--c__orange);
  }
  :deep(.red) {
    color: var(--c__red);
  }
}
</style>
