import _ from 'underscore';
import EventEmitter from 'wolfy87-eventemitter';
import { cancelable } from 'cancelable-promise';

export default class ConnectivityService extends EventEmitter {
  constructor(app) {
    super();

    this.videomanager = app.config.globalProperties.$videomanager;
    this.settings = {};
    this.started = false;
    this.promise = null;
    this.initialized = false;

    this.videomanager.on('error', (err) => {
      if (this.initialized) {
        this.emit('error', err);
      }
    });
    this.videomanager.on(
      'audioPreviewPercent',
      _.throttle((e) => {
        if (this.initialized) {
          this.emit('audioPreviewPercent', e);
        }
      }, 50),
    );
  }

  init(settings) {
    if (this.initialized) {
      return;
    }

    this.settings = settings;
    this.initialized = true;
    setTimeout(() => {
      this.emit('ready');
    }, 100);
  }

  enter() {
    this.stopTest(true);
    this.emit('enter');
  }

  destroy(dispose) {
    if (!this.initialized) {
      return;
    }

    if (this.started) {
      this.stopTest(false);
    }

    this.removeAllListeners('ready');
    this.removeAllListeners('restart');
    this.removeAllListeners('dispose');

    this.settings = null;
    this.initialized = false;
    this.emit('destroyed', dispose);
  }

  startTest() {
    this.started = true;
    this.emit('start');

    const devices = {
      audioSource: this.settings.audioSource,
      videoSource: this.settings.videoSource,
    };
    this.promise = cancelable(this.videomanager.startNetworkTest(devices));

    this.promise
      .then((results) => {
        this.started = false;
        this.promise = null;
        this.emit('complete', results);
      })
      .catch((err) => {
        this.started = false;
        this.promise = null;
        this.emit('error', err);
      });
  }

  stopTest(dispose) {
    this.emit('stop', this.started);
    this.started = false;

    if (this.promise) {
      this.promise.cancel();
      this.promise = null;
    }

    if (dispose) {
      this.emit('dispose');
    }
  }

  restartTest() {
    this.stopTest(false);
    setTimeout(() => {
      this.startTest();
    }, 100);
  }

  getSettings() {
    return this.settings;
  }

  updateSettings(type, newValue, mediaOn, targetElementId) {
    if (!this.initialized) {
      return;
    }

    this.settings[type] = newValue;

    if (type === 'audioSource') {
      this.emit('settings:audioSource', newValue, mediaOn);
    }

    if (type === 'videoSource') {
      this.emit('settings:videoSource', newValue, mediaOn, targetElementId);
    }

    if (['videoSource', 'audioSource'].indexOf(type) !== -1) {
      this.emit('restart');
    }
  }
}
