import { defineStore } from 'pinia';
import _ from 'underscore';
import { eventEmitter } from '@/services/event-emitter.service';
import { useUsersStore } from '@/store/pinia/users';
import { nM } from '@/legacy';
import { createGroupEvents } from '../../shared/constants';

//TODO: phase stages
//TODO: breakEndedEarly & groupsReassemble could be better not history based

const parseTopic = (entry) => {
  return {
    id: entry.id,
    user: entry.user_id,
    phase: entry.phase_id,
    content: entry.description,
    type: entry.meta.type,
    timestamp: entry.timestamp,
  };
};

const fieldMap = {
  checkInQuestion: 'question',
  proposal: 'proposal',
  topic: 'topic',
};

export const usePhaseStore = defineStore('phase', {
  state() {
    return {
      __initialized: false,
      skipTitleCard: false,
      requestingPhase: false,
      isFirstPhase: true,
      phaseHistory: [],
      currentPhaseData: {},
      isAnimating: 0,
      initialPhaseShown: false,
      phaseStage: false,
      settingUp: false,
      settingUpData: {},
      setupPhase: false,
      setupSpeaker: false,
      pendingSetupSpeaker: false,
      randomSpeakerTs: false,
      setupActive: false,
      setupGroups: [],
      setupTimeChanged: false,
      onStage: false,
      onStageData: false,
      phaseActive: false,
      question: false,
      questions: ['default'],
      proposal: false,
      topic: false,
      proposals: [],
      topics: [],
      editing: {
        question: false,
        proposal: false,
        topic: false,
      },
      // voting
      hasTempVote: false,
      tempVote: false,
      // groups
      groupEventStatus: false,
      groupsOverlayShowing: false,
      // others,
      phasesEndedEarly: [],
      // screen share
      mirroring: {
        mirroringId: null,
        requestedBy: null,
        isActive: false,
        sourceType: null,
        stopMirroringRequested: false,
      },
      flashPresenterChoice: false,
    };
  },
  getters: {
    preparing(s) {
      return (!s.currentPhaseData.started_on || !s.settingUp) && s.currentPhaseData.needs_edition && s.currentPhaseData.duration;
    },
    someoneIsMirroring(s) {
      return !!s.mirroring.requestedBy;
    },
    proposalId: (s) => {
      //TODO: find a better way of handling this need for the history ID
      let proposal = s.proposals.find((l) => l.phase === s.currentPhaseData.id);
      return proposal ? proposal.id : false;
    },
    topicId: (s) => {
      //TODO: find a better way of handling this need for the history ID
      let topic = s.topics.find((l) => l.phase === s.currentPhaseData.id);
      return topic ? topic.id : false;
    },
    phaseList: (s) => s.phaseHistory.map((p) => nM.phaseMap[p.module_id] || p.module_id),
    rawCurrentPhase: (s) => nM.phaseMap[s.currentPhaseData.module_id],
    currentPhase: (s) => {
      const usersStore = useUsersStore();

      if (usersStore.iAmAway) {
        return 'breakTime';
      }
      if (usersStore.iAmAwayInGroup) {
        return 'groups';
      }

      return nM.phaseMap[s.currentPhaseData.module_id];
    },
    previousPhase: (s) => {
      if (s.phaseHistory.length < 2) {
        return 'init';
      } else {
        return nM.phaseMap[s.phaseHistory[s.phaseHistory.length - 2]] || s.phaseHistory[s.phaseHistory.length - 2];
      }
    },
    currentPhaseId: (s) => s.currentPhaseData.id,
    lastBreakData: (state) => {
      let breaks = state.phaseHistory.filter((p) => p.module_id === 'break');
      if (breaks.length > 0) {
        return breaks.slice(-1);
      } else {
        return {};
      }
    },
    lastGroupsData: (state) => {
      let groups = state.phaseHistory.filter((p) => p.module_id === 'groups');
      if (groups.length > 0) {
        return groups[groups.length - 1];
      } else {
        return state.currentPhaseData;
      }
    },
    isPresentation: (s) => {
      if (s.currentPhase === 'input' || s.mirroring.requestedBy) {
        return true;
      }
      return false;
    },
    speaking: (s) => {
      if (s.settingUp) {
        return s.setupSpeaker;
      } else {
        return s.onStage;
      }
    },
    active: (s) => {
      if (s.settingUp) {
        return s.setupActive;
      } else {
        return s.phaseActive;
      }
    },
    isFeedback(s) {
      return this.currentPhase === 'feedback' && !s.settingUp;
    },
    votes(s) {
      if (this.currentPhase !== 'decision') {
        return {};
      }

      const usersStore = useUsersStore();
      if (!usersStore.allUsers.length) {
        return false;
      }

      let v = {};

      usersStore.allUsers.forEach((u) => {
        v[u.id] = u.vote;
      });

      if (s.hasTempVote) {
        v[usersStore.myId] = s.tempVote;
      }

      return v;
    },
    myVote() {
      const usersStore = useUsersStore();
      return this.votes[usersStore.myId];
    },
    collectedVotes() {
      if (this.currentPhase !== 'decision') {
        return {};
      }

      const usersStore = useUsersStore();
      let v = {
        yes: [],
        no: [],
        unsure: [],
      };
      _.each(this.votes, (vote, id) => {
        if (usersStore.usersVisibleList.includes(parseInt(id))) {
          if (!vote) {
            v.unsure.push(parseInt(id));
          } else {
            v[vote].push(parseInt(id));
          }
        }
      });

      return v;
    },
    breakEndedEarly(s) {
      if (this.currentPhase === 'breakTime' && s.phasesEndedEarly.includes(s.currentPhaseData.id)) {
        return true;
      } else {
        return false;
      }
    },
    groupsReassemble(s) {
      if (this.currentPhase === 'breakTime' && s.phasesEndedEarly.includes(s.currentPhaseData.id)) {
        return true;
      } else {
        return false;
      }
    },
  },
  actions: {
    initMeeting(data, isReSync) {
      if (this.__initialized && !isReSync) {
        return;
      }
      const usersStore = useUsersStore();
      if (!isReSync) {
        this.$reset();
      }
      let { history, phases, state } = data;
      this.proposals = history.filter((l) => l.level === 20).map((l) => parseTopic(l));
      this.topics = history.filter((l) => l.level === 25).map((l) => parseTopic(l));
      this.phaseHistory = [...phases];
      this.initialPhaseShown = false;
      this.isAnimating = 0;
      this.updatePhase({ phase: data.state.current_phase, init: true, state }, isReSync);
      this.updateView(data.state, isReSync);
      if (data.state.next_phase) {
        this.updateQuestion(data.state.next_phase, isReSync);
        this.updateTopic(data.state.next_phase, isReSync);
        if (!isReSync || this.setupActive !== usersStore.me.id) {
          this.updateSetupActive(data.state.next_phase);
          this.updateSetupSpeaker(data.state.next_phase);
          this.updateSetupGroups(data.state.next_phase);
          this.setupTimeEdited = data.state.next_phase.timeModified;
        }
      }
      this.updateOnStage({ speaker: data.state.user_on_stage });
      if (data.state.mirroring?.length) {
        const usersStore = useUsersStore();
        const haveGroups = data.state.mirroring.some((m) => m.groupMembers?.length > 0);
        if (!haveGroups) {
          this.requestStartMirroring(data.state.mirroring[0]);
          return;
        }
        const thisGroupMirroringDetails = data.state.mirroring.find((m) => m.groupMembers.includes(usersStore.me.id));
        if (thisGroupMirroringDetails) {
          this.requestStartMirroring(thisGroupMirroringDetails);
        }
      } else {
        this.mirroring = {
          mirroringId: null,
          requestedBy: null,
          isActive: false,
          sourceType: null,
          stopMirroringRequested: false,
        };
      }
      // Do field locking
      Object.keys(data.state.fields).forEach((k) => {
        this.lockField({ field: k, userId: data.state.fields[k] });
      });
      this.phaseStage = this.parseStage({ ...data, init: true });
      this.tempVote = false;
      this.hasTempVote = false;
      history.forEach((event) => {
        this.addToHistory({ event });
      });
      this.__initialized = true;
    },
    updateSkipTitleCard(v) {
      this.skipTitleCard = v;
    },
    startedAnimating() {
      this.isAnimating++;
    },
    stoppedAnimating() {
      if (this.isAnimating > 0) {
        this.isAnimating--;
      }
      this.initialPhaseShown = true;
    },
    updatePhase({ phase, init, state }, isReSync) {
      let delay = 0;
      if (phase.module_id === 'freeform' && this.currentPhase === 'lobby') {
        delay = 1500;
        eventEmitter.emit('nM:showTitleCard', 'meetingSpace');
      }
      if (phase.module_id === 'groups' && !isReSync) {
        this.setGroupEventStatus({
          forced: true,
          status: createGroupEvents.BREAKOUT_GROUP_PHASE_STARTING,
        });
      }
      this.settingUp = false;
      this.setupPhase = false;
      this.tempVote = false;
      this.hasTempVote = false;
      this.requestingPhase = false;
      _.delay(() => {
        this.isFirstPhase = init;
        this.currentPhaseData = phase;
        if (phase.questions) {
          this.updateQuestion(phase, isReSync);
        }
        this.updateProposal(phase, isReSync);
        this.updateTopic(phase, isReSync);
        this.phaseActive = phase.start_by;
        this.phaseStage = this.parseStage({ init, state });

        if (phase.module_id === 'input') {
          this.flashPresenterChoice = true;
        } else {
          this.flashPresenterChoice = false;
        }
      }, delay);
    },
    phaseStarted({ phase }) {
      this.phaseHistory.push(phase);
    },
    phaseEnded(data) {
      const { phase } = data;
      let index = _.findIndex(this.phaseHistory, (p) => {
        phase.id === p.id;
      });
      if (!index) {
        return false;
      }
      this.phaseHistory[index] = { ...phase };
    },
    phaseEnding() {},
    updateView(data, isReSync) {
      let setupFor = nM.setups[data.view];
      if (setupFor) {
        this.settingUp = true;
        this.setupPhase = setupFor;
        this.updateSetupActive({ active: data.activeUserId });
        this.updateQuestion({ questions: data.questions || [] }, isReSync);
        this.updateSetupGroups(data);
        this.setupTimeEdited = false;
      } else {
        this.updateSetupGroups({ groups: [] });
        this.settingUp = false;
        this.setupPhase = false;
        this.updateSetupActive({ active: false });
        this.updateQuestion({ questions: data.questions || [] }, isReSync);
      }
    },
    quickVote(v) {
      this.tempVote = v;
      this.hasTempVote = true;
    },
    updateSetupSpeaker({ speaker, isRandom }) {
      if (typeof speaker === 'object') {
        speaker = speaker.id;
      }
      if (isRandom) {
        this.randomSpeakerTs = Date.now();
        this.pendingSetupSpeaker = speaker;
      } else {
        this.pendingSetupSpeaker = false;
        this.setupSpeaker = speaker;
      }
    },
    updateSetupActive({ active }) {
      if (typeof active === 'object') {
        active = active.id;
      }
      this.setupActive = active;
    },
    updateSetupTime() {
      this.setupTimeEdited = true;
    },
    updateSetupGroups({ groups }) {
      if (groups) {
        this.setupGroups = groups;
      }
    },
    updateTime(data) {
      let { time } = data;
      if (this.onStage && this.onStageData.timeout) {
        this.onStageData.timeout = this.onStageData.started_on + time;
        this.onStageData.time_remaining = this.onStageData.timeout - this.onStageData.started_on;
      }
      this.currentPhaseData.duration = time;
    },
    updateOnStage({ speaker }) {
      if (!speaker) {
        this.onStage = null;
        this.onStageData = null;
        return;
      }

      this.settingUp = false;
      this.setupPhase = false;
      this.onStage = speaker.id;
      this.onStageData = { ...speaker };
    },
    addToHistory({ event }) {
      //TODO: Find a way to not need these
      if (event.level === 20) {
        this.proposals.push(parseTopic(event));
      }
      if (event.level === 25) {
        this.topics.push(parseTopic(event));
      }
      if (event.level === 60) {
        this.phasesEndedEarly.push(event.phase_id);
      }
    },
    updateHistory({ event }) {
      //TODO: Find a way to not need these
      if (event.level === 20) {
        this.proposals = this.proposals.map((p) => {
          if (p.id === event.id) {
            return parseTopic(event);
          } else {
            return p;
          }
        });
      }
      if (event.level === 25) {
        this.topics = this.topics.map((p) => {
          if (p.id === event.id) {
            return parseTopic(event);
          } else {
            return p;
          }
        });
      }
    },
    lockField({ field, userId }) {
      let phaseField = fieldMap[field];
      if (phaseField) {
        this.editing[phaseField] = userId;
      }
    },
    unlockField({ field }) {
      let phaseField = fieldMap[field];
      if (phaseField) {
        this.editing[phaseField] = false;
      }
    },
    updateQuestion({ question, questions }, isReSync) {
      if (isReSync) {
        return;
      }
      if (!questions) {
        this.questions = question ? [question] : [];
      } else {
        this.questions = questions;
      }

      this.questions = this.questions.filter((q) => q.description); // take out falsy values
    },
    updateProposal({ proposal }, isReSync) {
      if (isReSync) {
        return;
      }
      this.proposal = proposal;
    },
    updateTopic({ topic }, isReSync) {
      if (isReSync) {
        return false;
      }
      this.topic = topic;
    },
    updatePhaseStage(stage) {
      this.phaseStage = stage;
    },
    proposalFinished() {
      this.updatePhaseStage('voting');
    },
    topicFinished() {
      if (this.currentPhase === 'feedback') {
        this.updatePhaseStage('responding');
      }
      if (this.currentPhase === 'dialogue') {
        this.updatePhaseStage('web');
      }
    },
    respondingFinished() {
      this.updatePhaseStage('reviewing');
    },
    parseStage(data) {
      const { init, state } = data;
      if (this.currentPhase === 'decision') {
        if (!init || state.waiting_for_proposal) {
          return 'proposal';
        } else if (state.voting) {
          return 'voting';
        } else {
          return 'voted';
        }
      }
      if (this.currentPhase === 'feedback') {
        if (!init || state.waiting_for_topic) {
          return 'topic';
        } else if (state.is_responding) {
          return 'responding';
        } else {
          return 'reviewing';
        }
      }
      if (this.currentPhase === 'dialogue') {
        if (!init || state.waiting_for_topic) {
          return 'topic';
        } else {
          return 'web';
        }
      }

      return false;
    },
    setGroupEventStatus({ status, forced }) {
      if (this.currentPhase === 'groups' || forced) {
        this.groupEventStatus = status;
      }
    },
    setGroupOverlay(v) {
      this.groupsOverlayShowing = v;
    },
    requestStartPhase(phase) {
      this.requestingPhase = phase;
      if (phase === 'input') {
        this.flashPresenterChoice = true;
      }
    },
    requestStartMirroring({ mirroringId, presenterId, inputType, isActive }) {
      this.mirroring = {
        mirroringId,
        requestedBy: presenterId,
        isActive: isActive || false,
        sourceType: inputType,
        stopMirroringRequested: false,
      };
    },
    updateMirroringDetails(mirroringId, updatesObj) {
      if (this.mirroring.mirroringId !== mirroringId) {
        return;
      }

      for (let props in updatesObj) {
        this.mirroring[props] = updatesObj[props];
      }
    },
    requestEndMirroring({ mirroringId }) {
      if (this.mirroring.mirroringId !== mirroringId) {
        return;
      }
      this.mirroring = {
        mirroringId: null,
        requestedBy: null,
        isActive: false,
        sourceType: null,
        stopMirroringRequested: false,
      };
    },
    updateCurrentPhase({ updatedBreakoutGroups }) {
      if (updatedBreakoutGroups) {
        this.currentPhaseData.groups = updatedBreakoutGroups;
      }
    },
  },
});
