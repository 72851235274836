<template>
  <edit-sidebar class="editMeetingInfoList">
    <div class="part">
      <h2>{{ title }}</h2>
      <form @submit.prevent="saveAndReturn">
        <transition-group name="fieldList">
          <app-list-input
            key="list"
            :field-data="fields"
            item="an objective"
            :name="name"
            :limit="255"
            :warn-limit="200"
            @capture="capture"
            @save="saveAndReturn"
          />
          <div key="actions" class="actions btn-pair">
            <button type="button" @click.prevent="back">Cancel</button>
            <button type="button" @click.prevent="saveAndReturn">Save</button>
          </div>
        </transition-group>
      </form>
    </div>
  </edit-sidebar>
</template>

<script>
import AppListInput from '@/components/AppListInput';
import EditSidebar from '@/components/EditSidebar';
import utils from '../resources/utils.js';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';

export default {
  components: {
    AppListInput,
    EditSidebar,
  },
  props: ['field', 'name', 'title'],
  setup() {
    const sidebarStore = useSidebarStore();
    const meetingStore = useMeetingStore();
    return { sidebarStore, meetingStore };
  },
  data: function () {
    return {
      fields: [],
    };
  },
  watch: {
    field: {
      immediate: true,
      deep: true,
      handler(nv) {
        this.fields = nv.map((v) => utils.desanitizeString(v));
      },
    },
  },
  methods: {
    capture(data) {
      this.fields = data;
    },
    saveAndReturn(e) {
      if (e) {
        this.meetingStore.updateLocalMeetingInfo({ prop: this.name, value: this.fields || [] });

        let dataObj = {
          trigger: this.name,
        };
        dataObj[this.name] = this.fields;
        this.$meetingmanager.updateMeeting(dataObj);

        this.$nextTick(() => {
          this.sidebarStore.finishedWithPane();
        });
      }
    },
    back() {
      this.sidebarStore.finishedWithPane();
    },
  },
};
</script>
