<template>
  <li
    ref="listItem"
    class="noteItem"
    :class="[note.type, editingClass, showEdit ? 'canEdit' : '']"
    :data-item-id="note.id"
    :data-type="note.type"
    :data-description="note.content"
  >
    <header v-if="!grouped">
      <div class="meta" v-if="!changingType" @click="openTypes">
        <app-icon :icon="what" />
        <span class="what">{{ note.type }}</span>
        <button class="changeTypeButton" @click="openTypes" v-if="showEdit">Change</button>
        <span class="when">{{ when }}</span>
      </div>
      <div v-if="changingType" class="changeType">
        <transition-group name="typesListTransition" tag="ul" class="typeChoices">
          <li v-for="(type, name) in logTypes" :class="[name === note.type ? 'current' : '', name]" :key="'type-' + name">
            <button class="btn-borderless" @click="handleTypes(type.desc)" :data-name="name">
              <app-icon :icon="type.icon" />
              <span class="desc">{{ type.desc }}</span>
            </button>
          </li>
        </transition-group>

        <button class="cancel" @click="changingType = false">Cancel</button>
      </div>
    </header>

    <transition name="basic" mode="out-in">
      <p v-if="!isEditing && !showDeleteConfirmation" key="content">
        <span>{{ desanitizeString(note.content) }} </span> <span class="who">({{ initialList }})</span>
      </p>
    </transition>
    <transition name="basic" mode="out-in">
      <meeting-notes-delete-confirmation v-if="showDeleteConfirmation" :id="note.id" key="deleteConfirmation" @cancel="showDeleteConfirmation = false" />
    </transition>
    <transition name="basic" mode="out-in">
      <meeting-notes-edit-box v-if="isEditing" :content="note.content" :id="note.id" key="editBox" @saved="saved" @deleteLog="showDeleteConfirmation = true" />
    </transition>

    <transition name="basic">
      <button v-if="showDelete && !showDeleteConfirmation" @click="showDeleteConfirmation = true" title="delete" class="delete btn-borderless">
        <app-icon icon="log-delete" />
      </button>
    </transition>
    <transition name="basic">
      <button v-if="showEdit && !showDeleteConfirmation" @click="editLog" title="edit" class="edit btn-borderless">
        <app-icon icon="pencil" />
      </button>
    </transition>
  </li>
</template>

<script>
import _ from 'underscore';
import moment from 'moment';
import { mapState as mapPiniaState } from 'pinia';
import AppIcon from '@/components/AppIcon.vue';
import MeetingNotesEditBox from '@/components/MeetingNotesEditBox';
import MeetingNotesDeleteConfirmation from '@/components/MeetingNotesDeleteConfirmation';
import utils from '../resources/utils';
import { logTypes } from '@/shared/constants';
import { useMomentsStore } from '@/store/pinia/moments';
import { useUsersStore } from '@/store/pinia/users';

export default {
  components: {
    AppIcon,
    MeetingNotesEditBox,
    MeetingNotesDeleteConfirmation,
  },
  props: ['note', 'index', 'count', 'grouped'],
  setup() {
    const momentsStore = useMomentsStore();
    return { momentsStore };
  },
  data() {
    return {
      desanitizeString: utils.desanitizeString,
      showSuccess: false,
      showDeleteConfirmation: false,
      changingType: false,
      logTypes: logTypes,
      extraEdit: [],
    };
  },
  mounted() {
    if (this.count === this.index && this.isMe && this.momentsStore.momentOpen) {
      this.$refs.listItem.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      myId: 'myId',
      iAmModerator: 'iAmModerator',
      users: 'allUsers',
    }),
    editing() {
      return this.momentsStore.iAmEditing;
    },
    user() {
      return _.findWhere(this.users, { id: this.note.user });
    },
    editors() {
      if (!this.note.editedBy && !this.extraEdit) {
        return [];
      } else {
        let editList = this.note.editedBy || this.extraEdit;
        if (!Array.isArray(editList)) {
          editList = [editList];
        }
        return editList
          .filter((u) => u !== this.note.user)
          .map((u) => {
            return _.findWhere(this.users, { id: u });
          });
      }
    },
    initialList() {
      return [this.user, ...this.editors].map((u) => u.initials).join('/');
    },
    who() {
      return utils.desanitizeString(this.user.first_name);
    },
    when() {
      return moment(this.note.timestamp, 'X').format('HH:mm');
    },
    what() {
      return 'log-' + this.note.type;
    },
    idTag() {
      return 'note-' + this.note.id;
    },
    isMe() {
      return this.user.id === this.myId;
    },
    showDelete() {
      return (this.isMe || this.iAmModerator) && !this.editing;
    },
    showEdit() {
      return (this.isMe || this.iAmModerator) && !this.editing;
    },
    showCancel() {
      return this.isEditing;
    },
    isEditing() {
      return this.note.id === this.editing || this.showSuccess;
    },
    editingClass() {
      if (this.showSuccess) {
        return 'success';
      } else {
        return this.isEditing ? 'editing' : 'viewing';
      }
    },
  },
  watch: {
    isEditing(nv) {
      if (!nv) {
        this.changingType = false;
      }
    },
  },
  methods: {
    deleteLog() {
      this.$meetingmanager.deleteLog(this.note.id);
    },
    editLog() {
      this.momentsStore.startEditing(this.note.id);
    },
    openTypes() {
      if (this.showEdit) {
        this.changingType = true;
        this.momentsStore.startEditing(this.note.id);
      }
    },
    handleTypes(type) {
      if (type !== this.note.type) {
        const data = {
          id: this.note.id,
          type: type,
          description: this.note.content,
          editedBy: this.myId,
        };
        this.$meetingmanager.updateLog(data);
      }
      this.changingType = false;
    },
    cancel() {
      this.momentsStore.stopEditing();
    },
    saved() {
      this.showSuccess = true;
      this.extraEdit.push(this.myId);
      _.delay(() => {
        this.showSuccess = false;
        this.momentsStore.stopEditing();
        this.$emit('updated', this.note);
      }, 300);
    },
  },
};
</script>
<style lang="scss" scoped>
.changeTypeButton {
  color: var(--c__accent);
  padding-right: rem(100px);
  @include triggered {
    colour: var(--c__accent-alt);
  }
}
.changeType {
  display: flex;
  width: 100%;
  ul {
    display: flex;
    list-style: none;
    margin: 0 0 0 rem(-7px);
    padding: 0;
  }
  li {
    margin-top: 0;
  }
  li::before {
    display: none;
  }
  li button {
    width: rem(45px);
    height: rem(34px);
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .desc {
    font-size: rem(10px);
    text-transform: capitalize;
    margin: rem(-4px) 0 rem(4px) 0;
  }
  .cancel {
    margin-left: auto;
  }
}
</style>
