import _ from 'underscore';

const QUARTER_TURN = Math.PI * 0.5;
const FULL_CIRCLE = Math.PI * 2;

function circle(a) {
  if (!a.count && a.order) {
    a.count = a.order.length;
  } else if (!a.order) {
    a.order = _.range(a.count);
  }

  let nudge = a.angleNudge || 0;

  let points = {};
  a.order.forEach(function (id, i) {
    points[id] = circlePoint({
      cX: a.cX,
      cY: a.cY,
      angle: ((2 * Math.PI) / a.count) * i - 0.5 * Math.PI + nudge,
      r: a.r,
      filters: a.filters,
    });
  });
  return points;
}

function arc(a) {
  if (!a.count && a.order) {
    a.count = a.order.length;
  } else if (!a.order) {
    a.order = _.range(a.count);
  }

  let nudge = a.angleNudge || 0;

  let points = {};
  a.order.forEach(function (id, i) {
    points[id] = circlePoint({
      cX: a.cX,
      cY: a.cY,
      angle: (a.arc / a.count) * i - 0.5 * Math.PI + nudge,
      r: a.r,
      filters: a.filters,
    });
  });
  return points;
}

function sliceWidth(a) {
  let { angle, r, count } = a;
  if (!angle && count) {
    angle = (2 * Math.PI) / count;
  }
  return Math.sin(angle / 2) * (parseInt(r) * 2);
}

function sliceAngle(a) {
  let distance = parseInt(a.distance);
  let width = parseInt(a.width);

  return Math.atan(width / 2 / (distance / 2)) * 2;
}

function circlePoint(a) {
  const { r, angle, cX, cY } = a;
  let pos = {
    left: r * Math.cos(angle) + cX,
    top: r * Math.sin(angle) + cY,
  };

  return pos;
}

function row(a) {
  let w = a.users.length * (a.pad + a.w) - a.pad;
  if ((w > a.max && !a.static) || a.scaled) {
    return scaledRow(a);
  } else {
    return staticRow(a);
  }
}

function column(a) {
  let w = a.users.length * (a.pad + a.w) - a.pad;
  if ((w > a.max && !a.static) || a.scaled) {
    return scaledColumn(a);
  } else {
    return staticColumn(a);
  }
}

function staticRow(a) {
  const { users, pad, cont, w, y, row } = a;
  const full = (w + pad) * users.length - pad;
  const start = (cont - full + w) / 2;

  let pos = {};
  users.forEach(function (id, i) {
    pos[id] = {
      top: y,
      left: start + (w + pad) * i,
      w: w,
      row: row,
    };
  });

  return pos;
}

function staticColumn(a) {
  const { users, pad, cont, w, x } = a;
  const full = (w + pad) * users.length - pad;
  const start = (cont - full + w) / 2;
  let pos = {};
  users.forEach(function (id, i) {
    pos[id] = {
      left: x,
      top: start + (w + pad) * i,
      w: w,
    };
  });

  return pos;
}

function scaledSize(a) {
  let { users, pad, maxSize } = a;
  const count = a.count || users.length;
  const outerPad = a.outerPad || 0;

  let max;
  let paddedMax = a.max - outerPad * 2;
  if (maxSize) {
    let maxTest = count * maxSize + (count + 3) * pad;
    max = paddedMax > maxTest ? maxTest : paddedMax;
  } else {
    max = paddedMax;
  }
  const w = (max - (count + 3) * pad) / count;
  return {
    w,
  };
}

function scaledRow(a) {
  const { users, pad, cont, y, row } = a;
  const sizes = scaledSize(a);
  const full = (sizes.w + pad) * users.length - pad;
  const start = (cont - full + sizes.w) / 2;

  let pos = {};
  users.forEach(function (id, i) {
    pos[id] = {
      top: y,
      left: start + (sizes.w + pad) * i,
      w: sizes.w,
      row: row,
    };
  });

  return pos;
}

function scaledColumn(a) {
  const { users, pad, cont, rightEdge } = a;
  const sizes = scaledSize(a);
  const full = (sizes.w + pad) * users.length - pad;
  const start = (cont - full + sizes.w) / 2;

  let pos = {};
  users.forEach(function (id, i) {
    pos[id] = {
      left: rightEdge - sizes.w / 2 - pad,
      top: start + (sizes.w + pad) * i,
      w: sizes.w,
    };
  });

  return pos;
}

function flip(a) {
  let { start, end, unit } = a;
  return parseInt(start) - parseInt(end) + unit;
}

function flipScale(a) {
  let { start, end } = a;
  return parseInt(start) / parseInt(end);
}

export { circle, arc, circlePoint, sliceWidth, sliceAngle, scaledSize, row, column, flip, flipScale, QUARTER_TURN, FULL_CIRCLE };
