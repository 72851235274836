<template>
  <div class="feebackView">
    <transition name="basic">
      <div class="topicFrame" v-if="showTopic">
        <div class="box">
          <topic-box :model-field="topic" placeholder="What do you want feedback on?" @send="send" @finish="finishTopic"
            >{{ speaking?.first_name }} is typing</topic-box
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { computed, getCurrentInstance } from 'vue';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';
import TopicBox from '@/components/TopicBox';
import utils from '../resources/utils';

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;
const phaseStore = usePhaseStore();
const frameStore = useFrameStore();
const usersStore = useUsersStore();
const { speaking } = storeToRefs(usersStore);
const { contHeight } = storeToRefs(frameStore);
const { topic: topicRaw, phaseStage, topicId } = storeToRefs(phaseStore);

const topicBoxTop = computed(() => contHeight.value / 3 - 58 + 'px');
const topic = computed(() => utils.desanitizeString(topicRaw.value));
const showTopic = computed(() => phaseStage.value === 'topic' && !phaseStore.mirroring.requestedBy);

function send(v) {
  const id = topicId.value || null;
  $meetingmanager.updateTopic({ id, topic: v });
}
function finishTopic(v) {
  $meetingmanager.updateTopic({ topic: v, finished: true });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dialogueView {
  background: var(--c__bg);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.topicFrame {
  width: 100%;
  top: v-bind(topicBoxTop);
  display: flex;
  flex-direction: column;
  position: absolute;
  height: rem(108px);
  padding: 0 rem(50px);
  align-items: center;
  justify-content: center;
}

.box {
  width: rem(482px);
  min-height: rem(54px);
  margin: 0 auto;
  border: 2px solid var(--c__edges-box);
  max-width: 100%;
  background: var(--c__bg);
  position: relative;
}
</style>
