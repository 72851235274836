<template>
  <div id="endLobbySpace" class="space endLobby endLobbySpace">
    <transition name="basic">
      <div v-if="showTable" class="table dynamic" id="exitLobbyTable" ref="table" :style="{ top: top }">&nbsp;</div>
    </transition>
    <footer v-if="!iAmInWaitingRoom" :style="footerStyles" :class="[showTable ? 'hasTable' : 'noTable']">
      <p id="exitDownloadMessage" class="download">
        Want to save your meeting notes?
        <button id="exitDownloadNotes" class="btn-borderless btn-link" @click="download">Download</button>
      </p>
    </footer>
  </div>
</template>

<script>
import spaces from '@/resources/spaces';
import { useFrameStore } from '@/store/pinia/frame';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';
import { mapState as mapPiniaState } from 'pinia';

export default {
  setup() {
    const frameStore = useFrameStore();
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    return { frameStore, meetingStore, usersStore };
  },
  data() {
    return {
      top: 0,
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['iAmInWaitingRoom']),
    rawTop() {
      return this.usersStore.positionDetails.bottom + this.frameStore.ringTop + 'px';
    },
    footerStyles() {
      if (this.showTable) {
        return {
          top: this.top,
        };
      } else {
        return {};
      }
    },
    showTable() {
      return this.usersStore.usersVisible && spaces[this.meetingStore.space]?.table;
    },
  },
  watch: {
    rawTop(nv) {
      this.top = nv;
    },
  },
  methods: {
    download() {
      this.$ee.emit('bus:downloadNotes', { isMeetingEnded: true });
    },
  },
};
</script>
<style lang="scss" scoped>
.endLobbySpace {
  footer {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: all 0.6s ease;
    z-index: 100;
    &.hasTable {
      top: (95vh - (math.div(182, $design-height) * 100vh));
      top: calc((var(--vh, 1vh) * 95) - (var(--vh, 1vh) * 100 * (182 / #{$design-height})));
      padding-top: math.div(182, $design-height) * 100vh;
      padding-top: calc(var(--vh, 1vh) * (182 / #{$design-height}) * 100);
    }
  }

  .download p {
    width: 100%;
  }
  .download button {
    display: inline-block;
  }

  h2 {
    @include type-size(medium);
    font-weight: 300;
    display: none;
  }
}

:deep(.table) {
  margin-top: 3rem;
  bottom: math.div(113, $design-height) * 100vh;
  bottom: calc(var(--vh, 1vh) * 100 * (113 / #{$design-height}));
  transition: top 500ms cubic-bezier(0.45, 0, 0.55, 1);

  background-image: var(--bg__table-end);
}
</style>
