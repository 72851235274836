<template>
  <section class="page introPages" :class="pageName" :data-page-name="pageName">
    <router-view v-slot="{ Component }">
      <transition name="basic" mode="out-in" @before-enter="startAnimation" @after-enter="stopAnimation">
        <component :is="Component" />
      </transition>
    </router-view>

    <div key="welcomeTable" class="table">&nbsp;</div>

    <footer key="welcomeFooter" class="extra">
      <p v-if="pageName === 'welcome' && !accountStore.canCreate">
        Want to start your own meeting? <a class="underlined" href="https://www.bixe.ai" target="_blank">Register your interest</a>
      </p>
      <p v-if="pageName === 'welcome' && accountStore.canCreate">Already invited to a bixe meeting?<br />To join the meeting, click on your invite link.</p>
      <p v-if="pageName === 'downtime' || pageName === 'meetingFull'">
        Having problems with bixe? <a class="btn-borderless btn-link" href="https://www.bixe.ai/support.html" target="_blank">Contact Support</a>
      </p>
    </footer>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import { useSidebarStore } from '@/store/pinia/sidebar';
import TableFrame from '@/components/TableFrame';

export default {
  components: {
    TableFrame,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const sidebarStore = useSidebarStore();
    return { accountStore, pageStore, sidebarStore };
  },
  beforeRouteUpdate() {
    this.sidebarStore.closeSidebar();
  },
  props: ['pageName'],
  computed: {
    previousPageName() {
      return this.pageStore.previous.pageName;
    },
  },
  methods: {
    startAnimation() {
      this.pageStore.startAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page');
        document.body.classList.add(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.add(this.pageName + 'Page');
      document.body.classList.add(this.pageName + 'Page-animateIn');
      document.body.classList.add('page-animating');

      if (this.pageStore.isMeeting) {
        document.body.classList.add('meeting');
      } else {
        document.body.classList.remove('meeting');
      }
    },
    stopAnimation() {
      this.pageStore.stopAnimating();
      if (this.previousPageName) {
        document.body.classList.remove(this.previousPageName + 'Page-animateOut');
      }
      document.body.classList.remove(this.pageName + 'Page-animateIn');
      document.body.classList.remove('page-animating');
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  background: var(--c__bg-alt);
  text-align: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: rem(100px);
}

.outerContent {
  width: 100%;
}

:deep(.content) {
  margin-bottom: rem(60px);
}

:deep(h2) {
  @include type-size(large);
  margin: auto 0 rem(35px) 0;
}

:deep(.solo),
:deep(.btn-pair) {
  width: rem(300px);
  flex: 0 0 auto;
}

:deep(.solo + .solo),
:deep(.btn-pair + .solo) {
  margin-top: rem(13px);
}

footer {
  margin: auto 0;
  min-height: 3em;
  p {
    margin-bottom: 0;
  }
}

.table {
  width: 100%;
  background-image: var(--bg__table-foyer);
}

:deep(.subContent) {
  margin-top: auto;
}
</style>
