<template>
  <div class="modalOverlay">
    <div v-click-outside="vcoConfig" class="modalFrame">
      <button class="close" @click="close">
        <svg viewbox="0 0 24 24">
          <path d="M1,1L23,23z" />
          <path d="M1,23L23,1z" />
        </svg>
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      vcoConfig: {
        handler: this.close,
        middleware: (event) => {
          // do not trigger `close` if clicked on close icon of guide notification.
          return !event.target.classList.contains('close-top-alert');
        },
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
.modalOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
  width: calc(var(--vw, 1vw) * 100);
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px) grayscale(0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  z-index: $z_modal-overlay;

  :deep(h2) {
    @include type-size('large');
    text-transform: uppercase;
  }

  & > div {
    background: var(--c__bg);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    border-radius: 2px;
    padding: 2em;
    position: relative;
    width: 30em;
    max-width: 90%;
  }

  :deep(form) {
    @include type-size('medium');
    margin-top: 2em;
  }

  :deep(form button) {
    display: inline-block;
    margin-left: 1em;
  }

  :deep(.send) {
    width: 8em;
  }

  :deep(.cancel) {
    color: $c_mid-grey;
    @include triggered {
      color: $c_accent;
    }
  }

  :deep(.actions) {
    margin-top: 1em;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  :deep(textarea) {
    text-align: left;
    margin: 1em 0;
    width: 100%;
    padding: 1em;
    border: 2px solid black;
    height: 10em;
    &:focus,
    &:active {
      border: 2px solid $c_accent;
    }
  }

  .close {
    position: absolute;
    right: 2.25em;
    top: 2.25em;

    width: rem(24px);
    height: rem(24px);
    padding: 0;
    color: var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }

    path {
      stroke: currentColor;
      stroke-width: 1px;
    }
    svg {
      pointer-events: none;
      width: rem(24px);
      height: rem(24px);
    }
  }
}
</style>
