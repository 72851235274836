const log = {
  // log
  'log-action': `
    <svg version="1.1" viewBox="0 0 32 32">
      <g transform="translate(1 1)">
        <path class="action border stroke hover-fill" d="M2,27.38V2.62L26.76,15Z" stroke-width="2"/>
      </g>
    </svg>
  `,
  'log-decision': `
    <svg version="1.1" viewBox="0 0 32 32">
      <g transform="translate(1 1)">
        <path class="decision border stroke hover-fill" d="M2.62,2H27.38L15,26.76Z" stroke-width="2"/>
      </g>
    </svg>
  `,
  'log-question': `
    <svg version="1.1" viewBox="0 0 34 34">
      <g transform="translate(1 1)">
        <circle class="border stroke hover-fill" cx="16" cy="16" r="15" stroke-width="2"/>
        <path class="mark interior fill hover-revFill" d="M17.1,15.36v2.55H15.26L15,14.33c2.49-.83,4-2.26,4-3.79,0-1.21-.85-1.9-2.31-1.9a8.39,8.39,0,0,0-3.56.87L12.35,8a10.75,10.75,0,0,1,4.82-1.3c2.51,0,4.14,1.39,4.14,3.52S19.83,14.11,17.1,15.36Zm-.85,6.77a1.37,1.37,0,1,1,0-2.73,1.37,1.37,0,0,1,0,2.73Z" transform="translate(-0.75, 1.5)"/>
      </g>
    </svg>
  `,
  'log-insight': `
    <svg version="1.1" viewBox="0 0 45 45">
      <g transform="translate(1 1)">
        <rect class="stroke border hover-fill" x="7.21" y="7.21" width="28" height="28" transform="translate(-8.79 21.21) rotate(-45)" stroke-width="2"/>
        <path class="bang interior fill hover-revFill" d="M17.69,28a1.23,1.23,0,1,1,2.46,0,1.23,1.23,0,0,1-2.46,0Zm.54-3.18-.36-10.75H20l-.36,10.75Z"/>
        <path class="bang interior fill hover-revFill" d="M22.84,28a1.23,1.23,0,1,1,2.46,0,1.23,1.23,0,0,1-2.46,0Zm.54-3.18L23,14.06h2.11l-.36,10.75Z"/>
      </g>
    </svg>
  `,
  'log-note': `
    <svg version="1.1" viewBox="0 0 22 23">
      <rect class="stroke hover-fill" x="1" y="1" width="20" height="21" stroke-width="2"/>
      <rect class="fill hover-revFill" x="5" y="5" height="1" width="12" />
      <rect class="fill hover-revFill" x="5" y="8" height="1" width="12" />
      <rect class="fill hover-revFill" x="5" y="11" height="1" width="12" />
      <rect class="fill hover-revFill" x="5" y="14" height="1" width="12" />
      <rect class="fill hover-revFill" x="5" y="17" height="1" width="12" />
    </svg>
  `,
  'log-good': `
    <svg viewBox="0 0 34 34">
      <path class="stroke stroke-broad hover-fill" d="M18,5c3.2,0,6.2,1.3,8.3,3.4s3.4,5.1,3.4,8.3s-1.3,6.2-3.4,8.3s-5.1,3.4-8.3,3.4c-2.4,0-4.7-0.7-6.6-2l-5.9,2l2.1-6.2c-0.9-1.6-1.4-3.5-1.4-5.5c0-3.2,1.3-6.2,3.4-8.3S14.8,5,18,5z"/>
     <path class="fill hover-revFill" d="m13,16.99c0,3.04,2.25,5.01,5,5.01,1.7,0,3.04-.72,4-1.82v-3.69h-4.48v1.77h2.51v1.17c-.41.38-1.17.74-2.03.74-1.7,0-2.94-1.35-2.94-3.17s1.24-3.17,2.94-3.17c.99,0,1.79.54,2.23,1.22l1.66-.93c-.72-1.14-1.94-2.11-3.89-2.11-2.75,0-5,1.94-5,4.99Z"/>
    </svg>
  `,
  'log-tricky': `
    <svg version="1.1" viewBox="0 0 34 34">
      <path class="stroke stroke-broad hover-fill" d="M18,5c3.2,0,6.2,1.3,8.3,3.4s3.4,5.1,3.4,8.3s-1.3,6.2-3.4,8.3s-5.1,3.4-8.3,3.4c-2.4,0-4.7-0.7-6.6-2l-5.9,2l2.1-6.2c-0.9-1.6-1.4-3.5-1.4-5.5c0-3.2,1.3-6.2,3.4-8.3S14.8,5,18,5z"/>
      <polygon class="fill hover-revFill" points="17.07 22 18.95 22 18.95 14.69 21.5 14.69 21.5 13 14.5 13 14.5 14.69 17.07 14.69 17.07 22"/>
    </svg>
  `,
  'log-different': `
    <svg version="1.1" viewBox="0 0 34 34">
      <path class="stroke stroke-broad hover-fill" d="M18,5c3.2,0,6.2,1.3,8.3,3.4s3.4,5.1,3.4,8.3s-1.3,6.2-3.4,8.3s-5.1,3.4-8.3,3.4c-2.4,0-4.7-0.7-6.6-2l-5.9,2l2.1-6.2c-0.9-1.6-1.4-3.5-1.4-5.5c0-3.2,1.3-6.2,3.4-8.3S14.8,5,18,5z"/>
      <path class="fill hover-revFill" d="M14,21.5h3.8c3,0,5.2-1.8,5.2-4.5s-2.1-4.5-5.2-4.5H14V21.5z M16,20v-6h1.8c2.1,0,3.2,1.3,3.2,3c0,1.6-1.2,3-3.2,3H16z"/>
    </svg>
  `,
  'log-toggle': `
    <svg version="1.1" viewBox="0 0 21 12">
      <path class="fill" d="M20.84,10.44l-1.41,1.39a.52.52,0,0,1-.73,0L10.43,4,2.32,11.86a.53.53,0,0,1-.74,0L.16,10.44a.51.51,0,0,1,0-.73L10.05.14a.53.53,0,0,1,.74,0L12,1.3l8.9,8.42A.5.5,0,0,1,20.84,10.44Z"/>
    </svg>
  `,
  'log-expand': `
    <svg version="1.1" viewBox="0 0 20 22">
      <path class="stroke" d="M.75,9.74l9-9,1.8,1.8,7.2,7.2m-9-9V21.1" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
    </svg>
  `,
  'log-send': `
    <svg version="1.1" viewBox="0 0 24 24">
      <g transform="translate(1 1)">
        <polyline class="stroke main" points="3.5 14.5 0.5 21.5 21.5 11.55 0.5 0.5 4.5 11.5 11.5 11.5" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
        <polyline class="sending" points="4.63 11.86 0.5 21.5 21.5 11.55 0.5 0.5 4.63 11.86" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </svg>
  `,
  'log-close': `
    <svg version="1.1" viewBox="0 0 21 21">
      <line class="stroke" x1="1.6" x2="19.4" y1="1.6" y2="19.4" stroke-width="1.6" />
      <line class="stroke" x1="1.6" x2="19.4" y1="19.4" y2="1.6" stroke-width="1.6" />
    </svg>
  `,
  'log-delete': `
    <svg version="1.1" viewBox="0 0 20 40"> // 22
      <g transform="translate(0 9)">
        <g class="bin">
          <path d="M17.92,4,16.74,20a1,1,0,0,1-1,.93H4.26a1,1,0,0,1-1-.93L2.08,4A1,1,0,0,1,3,3H16.92a1,1,0,0,1,1,1S17.92,4,17.92,4Z" fill="none" stroke="#da0000" stroke-linejoin="round"/>
          <path d="M5.74,7h0a.5.5,0,0,1,.53.47l.52,10a.5.5,0,0,1-.47.53h0a.5.5,0,0,1-.53-.47l-.52-10A.5.5,0,0,1,5.74,7Z" fill="#da0000"/>
          <path d="M10,7h0a.5.5,0,0,1,.5.5v10a.5.5,0,0,1-.5.5h0a.5.5,0,0,1-.5-.5v-10A.5.5,0,0,1,10,7Z" fill="#da0000"/>
          <path d="M14.26,7h0a.5.5,0,0,1,.47.53l-.52,10a.5.5,0,0,1-.53.47h0a.5.5,0,0,1-.47-.53l.52-10A.5.5,0,0,1,14.26,7Z" fill="#da0000"/>
        </g>
        <g class="lid">
          <path d="M7.43.55A.5.5,0,0,0,7.25.8L6.69,2.46h6.56L12.53.7A4.25,4.25,0,0,0,12,.6,30.19,30.19,0,0,0,7.43.55Z" fill="transparent" stroke="#da0000"/>
          <path d="M1.5,3h17a.5.5,0,0,1,.5.5h0a.5.5,0,0,1-.5.5H1.5a.5.5,0,0,1-.5-.5H1A.5.5,0,0,1,1.5,3Z" stroke="#da0000" stroke-width="2"/>
        </g>
      </g>
    </svg>
  `,
};

export { log };
