<template>
  <div class="phaseButtonContainer">
    <div id="skillBarControls" class="phaseControls btn-pair" :class="iAmOverdue ? 'iAmOverdue' : ''">
      <button
        v-for="(button, index) in buttons"
        :key="index"
        :ref="button.name"
        class="halfButton"
        :class="button.name"
        :disabled="request === button.name || showError"
        @click="handleButton($event, button)"
        @keyup.space="(e) => e.preventDefault()"
      >
        <transition name="basic" mode="out-in">
          <span v-if="request !== button.name">{{ button.text }}</span>
        </transition>
        <transition name="basic" mode="out-in">
          <span v-if="request === button.name" class="waiting">
            <span class="visuallyHidden">Please wait</span>
            <app-loading-spinner />
          </span>
        </transition>
      </button>
      <transition name="basic" mode="out-in">
        <div v-if="showError" class="error">
          {{ error }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import { mapState as mapPiniaState } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { usePageStore } from '@/store/pinia/page';
import { startPhaseMap } from '@/resources/start-phase-map';
import { asUnit } from '@/resources/time-helpers';
import { nM } from '@/legacy';
import { videoSource } from '@/shared/constants';
import utils from '../resources/utils';
export default {
  components: {
    AppLoadingSpinner,
  },
  props: {
    buttonSet: {
      default: 'auto',
    },
  },
  setup() {
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const phaseStore = usePhaseStore();
    const sidebarStore = useSidebarStore();
    const usersStore = useUsersStore();
    const timeStore = useTimeStore();
    const pageStore = usePageStore();
    return { phaseStore, sidebarStore, usersStore, timeStore, meetingStore, messagesStore, pageStore };
  },
  data: function () {
    return {
      delayer: false,
      delayerTimeout: false,
      request: false,
      error: false,
      showError: false,
      handleButtonRepeat: _.throttle(this.handleButtonRaw, 500),
      handleButtonSingle: _.debounce(this.handleButtonRaw, 1000, true),
      controls: {
        request: { text: 'Setup', method: 'requestPhase' },
        cancel: { text: 'Cancel', method: 'cancel' },
        start: { text: 'Start', method: 'requestStart' },
        back: { text: 'Back', method: 'requestBack' },
        next: { text: 'Next', method: 'next' },
        checkNext: { text: 'Next', method: 'requestCheckNext' },
        done: { text: `I'm done`, method: 'iAmDone' },
        finish: { text: 'Finish', method: 'requestEnd' },
        moreTime: { text: 'More time', method: 'requestMoreTime', repeat: true },
        proposeDecision: { text: 'Next', method: 'requestPropose' },
        setTopic: { text: 'Next', method: 'requestTopic' },
        endVote: { text: 'Reveal', method: 'requestReveal' },
        endResponding: { text: 'Review', method: 'requestReviewFeedback' },
        iAmBack: { text: `I'm back`, method: 'back' },
        finishEarly: { text: 'Finish Early', method: 'alarm' },
        moveOn: { text: 'Finish', method: 'requestEnd' },
        weAreDone: { text: `We're done`, method: 'returned' },
        leave: { text: 'Leave', method: 'getOut' },
        end: { text: 'End', method: 'getOut' },
      },
      controlSets: {
        info: ['cancel', 'request'],
        setTime: ['back', 'start'],
        chooseUser: ['back', 'start'],
        running: ['moreTime', 'finish'],
        staged: ['moreTime', 'checkNext'],
        onStage: ['moreTime', 'done'],
        propose: ['moreTime', 'proposeDecision'],
        topic: ['moreTime', 'setTopic'],
        voting: ['moreTime', 'endVote'],
        givingFeedback: ['moreTime', 'endResponding'],
        breakAwayUser: ['iAmBack'],
        breakAwayOwner: ['moreTime', 'iAmBack'],
        breakBackUser: ['moveOn'],
        breakBackOwner: ['moreTime', 'moveOn'],
        groupsAwayUser: ['weAreDone'],
        groupsAwayOwner: ['moreTime', 'weAreDone'],
        groupsBackOwner: ['moreTime', 'moveOn'],
        groupsBackUser: ['moveOn'],
        endMeetingOwner: ['moreTime', 'end'],
        endMeetingUser: ['moreTime', 'leave'],
      },
    };
  },
  created() {
    const _this = this;
    this.$ee.on('nM:cannotAddTime', _this.cannotAddTime);
    this.$ee.on('api:cannotAddTime', _this.cannotAddTime);
    this.$ee.on('nM:fullscreenMoreTime', _this.requestMoreTime);
  },
  beforeUnmount() {
    const _this = this;
    this.$ee.off('nM:cannotAddTime', _this.cannotAddTime);
    this.$ee.off('api:cannotAddTime', _this.cannotAddTime);
    this.$ee.off('nM:fullscreenMoreTime', _this.requestMoreTime);
  },
  computed: {
    ...mapPiniaState(useUsersStore, [
      'me',
      'myId',
      'owner',
      'speaking',
      'iAmSpeaking',
      'iAmActive',
      'iAmModerator',
      'usersOnline',
      'usersHere',
      'iAmAway',
      'iAmOverdue',
      'everyoneIsBack',
    ]),
    screenshare() {
      return this.sidebarStore.current.screensharePlease;
    },
    skillBar() {
      return this.sidebarStore.current.phase;
    },
    barType() {
      return this.sidebarStore.current.barType;
    },
    controlSet() {
      if (this.buttonSet !== 'auto' && this.controlSets[this.buttonSet]) {
        return this.buttonSet;
      }

      if (this.usersStore.iAmStillAway) {
        return 'breakAwayUser';
      } else if (this.usersStore.iAmStillAwayInGroup) {
        return 'groupsAwayUser';
      }
      if (this.barType === 'info') {
        return 'info';
      } else if (this.barType === 'setTime') {
        return 'setTime';
      } else if (!this.phaseStore.settingUp && this.phaseStore.currentPhase === 'decision') {
        const stage = this.phaseStore.phaseStage || 'proposal';
        return {
          proposal: 'propose',
          voting: 'voting',
          voted: 'running',
        }[stage];
      } else if (!this.phaseStore.settingUp && this.phaseStore.currentPhase === 'feedback') {
        const stage = this.phaseStore.phaseStage || 'topic';
        return {
          topic: 'topic',
          responding: 'givingFeedback',
          reviewing: 'running',
        }[stage];
      } else if (!this.phaseStore.settingUp && this.phaseStore.currentPhase === 'dialogue') {
        return this.phaseStore.phaseStage === 'topic' ? 'topic' : 'running';
      } else if (this.phaseStore.currentPhase === 'endMeeting' || this.phaseStore.currentPhase === 'ended') {
        return this.iAmModerator ? 'endMeetingOwner' : 'endMeetingUser';
      } else if (['checkIn', 'checkOut'].includes(this.skillBar)) {
        return this.iAmSpeaking ? 'onStage' : 'staged';
      } else if (this.phaseStore.currentPhase === 'breakTime') {
        if (this.iAmAway) {
          return this.iAmModerator || this.iAmSpeaking || this.iAmActive ? 'breakAwayOwner' : 'breakAwayUser';
        } else {
          return this.iAmModerator || this.iAmSpeaking || this.iAmActive ? 'breakBackOwner' : 'breakBackUser';
        }
      } else if (this.phaseStore.currentPhase === 'groups') {
        if (this.usersStore.iAmAwayInGroup) {
          return this.iAmModerator || this.iAmSpeaking || this.iAmActive ? 'groupsAwayOwner' : 'groupsAwayUser';
        } else {
          return this.iAmModerator || this.iAmSpeaking || this.iAmActive ? 'groupsBackOwner' : 'groupsBackUser';
        }
      } else if (this.controlSets[this.barType]) {
        return this.barType;
      } else {
        return ['checkIn', 'checkOut'].includes(this.skillBar) ? 'staged' : 'running';
      }
    },
    buttons() {
      return this.controlSets[this.controlSet].map((c) => {
        let item = this.controls[c];
        item.name = c;
        return item;
      });
    },
    seconds() {
      return this.timeStore.phaseElapsed ? Math.floor(asUnit(this.timeStore.phaseElapsed, 'seconds')) : 0;
    },
  },
  watch: {
    controlSet: {
      immediate: true,
      deep: true,
      handler() {
        this.delayer = true;
        this.delayerTimeout = setTimeout(() => {
          this.delayer = false;
        }, 1000);
      },
    },
    'phaseStore.currentPhase': function (nv) {
      if (nv !== 'breakTime') {
        this.meetingStore.setAddedMoreTimeToBreak(false);
      }

      if (nv !== 'groups') {
        this.meetingStore.setAddedMoreTimeToGroups(false);
      }
    },
  },
  methods: {
    handleButton(e, button) {
      if (button.repeat) {
        this.handleButtonRepeat(e, button);
      } else {
        this.handleButtonSingle(e, button);
      }
    },
    handleButtonRaw(e, { event, method }) {
      if (this.seconds > 2 || !(this.speaking && this.speaking.id) || !['checkIn', 'checkOut'].includes(this.skillBar)) {
        if (event) {
          this.$ee.emit(event, {
            user: this.myId,
            about: this.speaking ? this.speaking.id : this.myId,
          });
        }
        if (method && this[method]) {
          this[method]();
        }
        e.target.blur();
      }
    },
    cancel() {
      this.sidebarStore.requestPhaseSource();
    },
    requestPhase() {
      let check = new Promise((resolve, reject) => {
        if (this.phaseStore.someoneIsMirroring) {
          this.messagesStore.addAlert('cannotStartWhileMirroring');
          reject('cannot');
        } else if (this.usersOnline.length < 2) {
          this.messagesStore.addAlert('pleaseWaitForUsersPhase');
          Velocity(this.$refs.request, 'callout.swing', { duration: 500 });
          reject('cannot');
        } else if (this.phaseStore.currentPhase === 'freeform') {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToReplacePhase', {
              phase: this.sidebarStore.current.phase,
              speakingName: utils.desanitizeString(this.usersStore.speaking.first_name),
              activeName: utils.desanitizeString(this.usersStore.active.first_name),
            })
            .then(resolve)
            .catch(reject);
        }
      });

      check
        .then(() => {
          const groups = this.skillBar === 'groups' ? _.chunk(_.shuffle(_.pluck(this.usersHere, 'id')), Math.ceil(this.usersHere.length / 2)) : false;
          // const groups = this.skillBar === 'groups' ? [this.usersHere.map((user) => user.id)] : false;
          const phase = nM.phaseMapReverse[this.skillBar] || this.skillBar;
          const view = `${phase}Setup:time`;

          if (this.screenshare) {
            this.$meetingmanager.changeView(view, {
              source: videoSource.NONE,
            });
          } else if (groups) {
            this.$meetingmanager.changeView(view, { groups });
          } else {
            this.$meetingmanager.changeView(view);
          }
        })
        .catch((reason) => {
          if (reason === 'cannot') {
            Velocity(this.$refs.request, 'callout.swing', { duration: 500 });
          }
        });
    },
    requestStart() {
      let check = new Promise((resolve) => {
        if (this.iAmActive) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToStartPhase', {
              speakingName: utils.desanitizeString(this.usersStore.speaking.first_name),
              activeName: utils.desanitizeString(this.usersStore.active.first_name),
            })
            .then(resolve)
            .catch(() => {});
        }
      });

      check.then(() => {
        const setupPhase = this.phaseStore.setupPhase;

        const params = startPhaseMap[setupPhase].params({
          phase: setupPhase,
          time: this.timeStore.settingTime,
          maxTime: this.timeStore.times[setupPhase]?.max || 0,
          speaking: this.speaking.id,
          groups: this.phaseStore.settingUp ? this.phaseStore.setupGroups : [],
        });
        const phase = nM.phaseMapReverse[setupPhase] || setupPhase;
        this.$meetingmanager.moveToPhase(phase, params);
        this.phaseStore.requestStartPhase(phase);
      });
    },
    requestBack() {
      let check = new Promise((resolve) => {
        if (this.iAmActive) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToCancelSetup', {
              speakingName: utils.desanitizeString(this.usersStore.speaking.first_name),
              activeName: utils.desanitizeString(this.usersStore.active.first_name),
            })
            .then(resolve)
            .catch(() => {});
        }
      });

      check.then(() => {
        this.$meetingmanager.changeView(this.phaseStore.currentPhaseData.module_id);
        if (this.iAmActive) {
          this.sidebarStore.requestPhaseSource({ iCancelled: true });
        }
      });
    },
    requestEnd() {
      let check = new Promise((resolve) => {
        if (this.delayer) {
          return false;
        } else if (this.phaseStore.currentPhase === 'freeform') {
          return false;
        } else if (this.phaseStore.currentPhase === 'groups' && !this.everyoneIsBack) {
          this.messagesStore.interrupt('notEveryoneIsBack', { phase: this.skillBar }).then(resolve);
        } else if (!this.iAmSpeaking && this.phaseStore.currentPhase !== 'groups' && this.speaking) {
          this.messagesStore
            .interrupt('sureYouWantToEndPhase', {
              phase: this.skillBar,
              speakingName: utils.desanitizeString(this.speaking.first_name),
            })
            .then(resolve)
            .catch(() => {});
        } else {
          resolve();
        }
      });
      check.then(() => {
        this.$meetingmanager.endPhase();
      });
    },
    requestCheckNext() {
      let check = new Promise((resolve) => {
        if (!this.speaking || !this.speaking.first_name) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('timeLeftToCheckIn', { speakingName: utils.desanitizeString(this.speaking.first_name), speakingId: this.speaking.id })
            .then(resolve)
            .catch(() => {});
        }
      });
      check.then(() => {
        this.next({
          user: this.myId,
          about: this.speaking.id,
        });
      });
    },
    requestPropose() {
      let check = new Promise((resolve) => {
        if (this.speaking.id === this.myId) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToEndProposal', { speakingName: utils.desanitizeString(this.speaking.first_name) })
            .then(resolve)
            .catch(() => {});
        }
      });

      check.then(() => {
        this.$meetingmanager.updateProposal({ proposal: this.phaseStore.proposal, finished: true });
      });
    },
    requestTopic() {
      let check = new Promise((resolve) => {
        if (this.speaking.id === this.myId) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToEndTopic', { speakingName: utils.desanitizeString(this.speaking.first_name), phase: this.phaseStore.currentPhase })
            .then(resolve)
            .catch(() => {});
        }
      });
      check.then(() => {
        this.$meetingmanager.updateTopic({ topic: this.phaseStore.topic, finished: true });
      });
    },
    requestReveal() {
      this.messagesStore
        .interrupt('sureYouWantToReveal')
        .then(() => {
          this.$meetingmanager.endVoting();
        })
        .catch(() => {});
    },
    requestReviewFeedback() {
      let check = new Promise((resolve) => {
        if (this.speaking.id === this.myId) {
          resolve();
        } else {
          this.messagesStore
            .interrupt('sureYouWantToReviewFeedback', { speakingName: utils.desanitizeString(this.speaking.first_name) })
            .then(resolve)
            .catch(() => {});
        }
      });
      check.then(() => {
        let payload = {};
        if (this.phaseStore.someoneIsMirroring) {
          payload = { mirroringId: this.phaseStore.mirroring.mirroringId };
        }
        this.$meetingmanager.endResponding(payload);
      });
    },
    requestMoreTime() {
      let check = new Promise((resolve) => {
        if (this.phaseStore.currentPhase === 'breakTime' && !this.meetingStore.addedMoreTimeToBreak) {
          this.messagesStore.interrupt('sureYouWantToAddTimeToBreak').then(() => {
            this.meetingStore.setAddedMoreTimeToBreak(true);
            resolve();
          });
        } else if (this.phaseStore.currentPhase === 'groups' && !this.meetingStore.addedMoreTimeToGroups) {
          this.messagesStore.interrupt('sureYouWantToAddTimeToGroups').then(() => {
            this.meetingStore.setAddedMoreTimeToGroups(true);
            resolve();
          });
        } else {
          resolve();
        }
      });
      check.then(() => {
        const amount =
          this.phaseStore.currentPhase === 'endMeeting' ||
          (this.timeStore.phaseSetTime <= 30 && !['breakTime', 'checkIn', 'checkOut'].includes(this.phaseStore.currentPhase))
            ? 30
            : 60;
        this.$meetingmanager.addTime(amount);
        this.$ee.emit('nM:addTimeLocal');
      });
    },
    cannotAddTime() {
      Velocity(this.$refs.moreTime, 'callout.swing', { duration: 500 });
    },
    getOut() {
      const ev = this.iAmModerator ? 'nM:meeting:finish' : 'nM:meeting:leave';
      this.$ee.emit('nM:meeting:fade');
      this.sidebarStore.meetingOver();
      this.$ee.emit(ev);
    },
    iAmDone() {
      this.$meetingmanager.finish();
    },
    next(args) {
      if (args.user === args.about) {
        this.$meetingmanager.finish();
      } else {
        this.$meetingmanager.finish(args.about);
      }
    },
    back() {
      this.request = 'iAmBack';
      this.$API.getMeetingSession(this.meetingStore.meetingId).then(
        (session) => {
          this.$videomanager.init(session, {
            externalAttendeeId: this.me.id,
            externalMeetingId: this.meetingStore.meetingId,
            pageName: this.pageStore.current.pageName,
          });
          this.$meetingmanager.haveReturned();
          setTimeout(() => {
            this.request = false;
          }, 1000);
        },
        () => {
          this.request = false;
          this.handleError();
        },
      );
    },
    alarm() {
      this.$meetingmanager.endingPhase();
    },
    returned() {
      this.$meetingmanager.haveReturned();
    },
    endPhase() {},
    handleError(msg) {
      msg = msg || 'please try again';
      this.error = msg;
      let thisError = msg;
      this.showErrror = true;
      _.delay(() => {
        if (this.error === thisError) {
          this.showError = false;
          _.delay(() => {
            this.error = false;
          }, 300);
        }
      }, 3000);
    },
  },
};
</script>
<style lang="scss" scoped>
.phaseButtonContainer {
  flex: 0 0 auto;
  margin: 0 rem(34px) 0 rem(34px);
  //TODO: padding-top was 68px in dialogue for some reason, check this
  padding: rem(20px) 0 rem($log-height - 50px) 0;
  transition: all 0.5s cubic-bezier(0.6, 0, 0, 1);

  @media only screen and (min-height: $sidebar-timer-center-break) {
    flex: 0 0 auto;
  }
}

.phaseControls {
  --c__button: var(--c__text-energy-invert);
  border-width: 3px;
  height: rem(50px);
  position: relative;
  @include triggered {
    animation: none !important;
  }
}

@each $name in $skills {
  .microskillSidebar.#{$name} {
    .phaseControls {
      --c__button-bg: var(--c__skillbar-bg-#{$name}-solid);
    }
  }
}

.microskillSidebar.misc {
  .phaseControls {
    --c__button-bg: var(--c__bg);
    --c__button: var(--c__text);
  }
}

.error {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--c__bg);
  color: var(--c__warn);
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes flash-skill-button {
  0%,
  3%,
  4%,
  7%,
  100% {
    color: var(--c__text-energy-invert);
    background: var(--c__button-bg);
  }

  1%,
  2%,
  5%,
  6% {
    color: var(--c__button-bg);
    background: var(--c__text-energy-invert);
  }
}

@keyframes flash-skill-button-constant {
  0%,
  20%,
  80%,
  100% {
    color: var(--c__text-energy-invert);
    background: var(--c__button-bg);
  }

  40%,
  60% {
    color: var(--c__button-bg);
    background: var(--c__text-energy-invert);
  }
}

.checkIn,
.checkOut {
  .phaseControls .done {
    animation: flash-skill-button 30s infinite linear 30s;
    @include triggered {
      animation: none;
    }
  }

  .phaseControls.iAmOverdue .done {
    animation: flash-skill-button-constant 1s infinite linear;
    @include triggered {
      animation: none;
    }
  }
}
</style>
