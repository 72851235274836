<template>
  <div class="editBar pane">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.editBar {
  :deep(.part) {
    padding: rem(21px) rem(54px) rem(21px) rem(54px);
  }

  :deep(h2) {
    line-height: math.div(15, 12);
    margin: rem(77px) 0 rem(4px) 0;
    display: block;
    font-weight: bold;
  }

  :deep(input[type='text']) {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--c__text);
    height: rem(28px);
    padding: 0;
    line-height: rem(27px);
    margin-top: rem(10px);
    @include type-size('small');
    color: var(--c__text);
    @include placeholder-content {
      color: var(--c__placeholder);
      font-weight: 200;
    }
    &:focus {
      outline: none;
    }
    &.over {
      border-bottom: 1px solid var(--c__warn);
    }
  }

  :deep(.row) {
    width: rem(191px);
    .content {
      position: relative;
      width: 100%;
    }
    input {
      padding-right: 1em;
    }
  }

  :deep(.autoTextarea) {
    --border: var(--c__text);
    width: 100%;
    border-bottom: 1px solid var(--border);
  }

  :deep(.actions) {
    margin: rem(38px) 0 rem(77px) 0;
    .extra {
      width: 100%;
      margin-top: rem(15px);
      text-decoration: underline;
      @include triggered {
        color: var(--c__accent);
      }
    }
  }
  :deep(.actions.btn-pair),
  :deep(.actions .btn-pair) {
    border-width: 1px;
    height: rem(35px);

    & > * {
      @include type-size('small');
    }

    & > *:first-child::after {
      display: none;
    }
  }

  :deep(.textarea + .actions) {
    margin-top: rem((28px - 14px));
  }

  :deep(.error) {
    margin-top: 0.5em;
    color: var(--c__warn);
  }
}

:deep(.fieldList-enter-active) {
  transition: opacity 0.6s linear 0.3s;
}
:deep(.fieldList-leave-active) {
  position: absolute;
  transition: opacity 0.6s linear;
}

:deep(.fieldList-enter-from),
:deep(.fieldList-leave-to) {
  opacity: 0;
}

:deep(.fieldList-move) {
  transition: transform 0.6s cubic-bezier(0, 0.5, 0.5, 1);
}
</style>
