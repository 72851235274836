<template>
  <div class="subContent" :class="status">
    <transition-group name="basic-group" mode="out-in">
      <section class="videoPreview" key="preview">
        <transition name="basic">
          <div v-if="status !== 'fail'" id="checkVideos" class="videos">
            <div class="connectivityVideo">
              <video id="checkVideo" />
            </div>
            <audio id="checkAudio" class="connectivityAudio" />
          </div>
        </transition>
        <transition name="basic">
          <app-icon v-if="status === 'fail'" class="icon" icon="camera" />
        </transition>
      </section>
      <h2 key="heading">Can we see you?</h2>
      <p v-if="status === 'pending'" key="pendingMsg">Can you see yourself in the circle? Adjust your camera settings if you can’t.</p>
      <p v-if="status === 'success'" key="successMsg">Nice to see you. Nearly done, your sound and vision is ready.</p>
      <p v-if="status === 'fail'" key="failMsg">Don’t worry. If you don’t have a camera set up you can use bixe with audio only.</p>
      <div v-if="!devices" class="loadingDevices" key="loading">fetching your devices <app-loading-spinner /></div>
      <app-select v-if="devices" class="deviceSelect" v-model="selectedDevice" :options="deviceOptions" placeholder="please select a device" key="select">
        <template #arrow>
          <app-icon icon="down-arrow" />
        </template>
        <template #placeholder> please select a device </template>
        <template #label> please select a device </template>
      </app-select>

      <div class="btn-pair" v-if="status === 'pending'" key="status">
        <button type="button" class="btn-blue-flood" @click="status = 'fail'">No</button>
        <button type="button" class="btn-blue-flood" @click="status = 'success'">Yes</button>
      </div>
      <button v-if="status !== 'pending'" class="btn btn-border btn-medium btn-blue-flood" @click="moveToNextStep()" key="next">Next</button>
    </transition-group>
  </div>
</template>

<script>
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import AppIcon from '@/components/AppIcon';
import AppSelect from '@/components/AppSelect';
import utils from '@/resources/utils.js';
import { usePageStore } from '@/store/pinia/page';
import { mapState as mapPiniaState } from 'pinia';
import { useMediaStore } from '@/store/pinia/media';
import { useStorage } from 'vue3-storage';

const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  components: {
    AppLoadingSpinner,
    AppIcon,
    AppSelect,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
  },
  beforeUnmount() {
    this.$videomanager.detach('checkVideo');
    this.$videomanager.muteVideo();
  },
  data() {
    return {
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkScreenSharing,
      selectedDevice: undefined,
      status: 'pending',
    };
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['devices', 'videoSource']),
    deviceOptions() {
      return this.devices
        .filter((d) => d.kind === 'videoInput')
        .map((d) => {
          return {
            value: d.deviceId,
            label: utils.cleanDeviceLabel(d.label),
          };
        });
    },
  },
  watch: {
    selectedDevice(nv) {
      this.$readinessChecker.markAsStarted(this.pageName);
      this.testVideoInput(nv).then((status) => {
        this.previewVideoFromSelectedDevice(status);
      });
      this.$nextTick(() => {
        this.status = 'pending';
      });
    },
    videoSource: {
      immediate: true,
      handler(nv) {
        if (this.devices && this.devices.length && nv && nv !== this.selectedDevice) {
          // wait till devices and last selected video input device loaded to show selected device
          this.selectedDevice = nv;
        }
      },
    },
    devices: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.length && this.videoSource && !this.selectedDevice) {
          // wait till devices and last selected video input device loaded to show selected device
          this.selectedDevice = this.videoSource;
        } else if (nv && this.deviceOptions.length && !this.selectedDevice) {
          this.selectedDevice = this.deviceOptions[0].value;
        }
      },
    },
  },
  methods: {
    moveToNextStep() {
      this.$readinessChecker.moveToNextStep({ nextPageName: this.nextPageName });
    },
    testVideoInput(deviceId) {
      return new Promise((resolve) => {
        let video = document.createElement('video');
        video.muted = true;
        video.id = 'checkVideo_stubbed';
        video.style.display = 'none';
        (document.body || document.documentElement).appendChild(video);
        this.$readinessChecker.checkVideoInput(deviceId).then((resp) => {
          video.parentNode.removeChild(video);
          resolve(resp.status);
        });
      });
    },
    previewVideoFromSelectedDevice(readinessStatus) {
      this.mediaStore.setVideoSource(this.selectedDevice || false);
      this.$connectivity.updateSettings('videoSource', this.selectedDevice, true, 'checkVideo');
      this.$emit('video:change', this.selectedDevice);
      localStorage.setStorageSync('user:videoSource', this.selectedDevice);
      this.$readinessChecker.markAsCompleted({ subPageName: this.pageName, isPassed: readinessStatus });
    },
  },
};
</script>

<style lang="scss" scoped>
section.videoPreview {
  height: rem(280px);
  width: rem(280px);
  background: var(--c__bg);
  border-radius: 50% !important;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    width: rem(130px);
  }
}

p {
  max-width: 20em;
}

.videos {
  position: absolute;
  top: 2.8%;
  left: 2.8%;
  bottom: 2.8%;
  right: 2.8%;
  border-radius: 50% !important;
  overflow: hidden;
  background: $grad_avatar;

  .connectivityVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-radius: 50%;
    overflow: hidden;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      border-radius: 50%;
      animation: fadeOut 300ms 400ms forwards;
    }
    video {
      transform: rotateY(180deg);
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
    }
  }
}
:deep(.videoPreview svg) {
  color: var(--c__warn);
}
:deep(.strike) {
  display: none;
}
</style>
