import { defineStore } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { usePageStore } from '@/store/pinia/page';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { usePhaseStore } from '@/store/pinia/phase';
import { H_PAD, CIRCLE_TOP_PAD, CIRCLE_BOTTOM_PAD, CIRCLE_H_PAD, RANDOMNESS } from '@/resources/constants/frame-constants';

const randomList = Array.from(
  {
    length: 100,
  },
  () => Math.random(),
);

export const useFrameStore = defineStore('frame', {
  state() {
    return {
      // Controls
      mouseDown: false,
      mouseMoving: false,
      hoveredControl: false,
      offsetX: 0,
      offsetY: 0,
      moxeX: 0,
      moveY: 0,
      alt: false,
      //States
      resizing: false,
      fullscreen: false,
      // Sizes
      viewportH: 0,
      viewportW: 0,
      landscapeContainer: true,
      sidebarW: 0,
      baseRem: 14,
      rem: 0,
      contHeight: 0,
      orientation: 0,
    };
  },
  getters: {
    pxToRem: (state) => (px) => (px / state.baseRem) * state.rem,
    contWidth(s) {
      let sidebarStore = useSidebarStore();
      let phaseStore = usePhaseStore();
      let pageStore = usePageStore();

      if (s.fullscreen || pageStore.isAvatarsDockDrawer) {
        return s.viewportW;
      }

      if (phaseStore.currentPhase !== 'freeform' || sidebarStore.sidebarOpen || phaseStore.settingUp) {
        return s.viewportW - this.pxToRem(300);
      }

      if (!sidebarStore.mustBeOpen && !sidebarStore.sidebarOpen) {
        return s.viewportW;
      }

      return s.viewportW - this.pxToRem(300);
    },
    containerRatio(state) {
      let ratio = this.contWidth / state.contHeight;
      return ratio.toFixed(3);
    },
    containerRatioWithSpace(state) {
      let ratio = (this.contWidth - 50) / state.contHeight;
      return ratio.toFixed(3);
    },
    containerSet() {
      return this.contWidth && this.ringBasis;
    },
    ringBasis(state) {
      let w = this.contWidth - CIRCLE_H_PAD * 2;
      let h = state.contHeight - CIRCLE_TOP_PAD - CIRCLE_BOTTOM_PAD;
      return w > h ? h : w;
    },
    ringTop(state) {
      let space = state.contHeight - CIRCLE_TOP_PAD - CIRCLE_BOTTOM_PAD;
      return (space - this.ringBasis) / 2 + CIRCLE_TOP_PAD;
    },
    ringLeft(state) {
      let space = state.contWidth - CIRCLE_H_PAD * 2;
      return space / 2 - this.ringBasis / 2 + CIRCLE_H_PAD;
    },
    cY(s) {
      return s.contHeight / 2;
    },
    cX() {
      return this.contWidth / 2;
    },
    voteMaxPerPage() {
      let usersStore = useUsersStore();
      let userCount = usersStore.visibleUserCount;

      let space = this.contWidth - 150;
      let spaceCount = Math.floor(space / (100 + H_PAD));
      let max = 16;

      return Math.min(userCount, spaceCount, max);
    },
    voteSlots(s) {
      let userCount = this.voteMaxPerPage;

      let yes = [];
      let no = [];

      const spaceWidth = this.contWidth - RANDOMNESS - 80;
      const spaceHeight = s.contHeight / 2 - RANDOMNESS - 100;
      const ratio = spaceWidth / spaceHeight;

      let rows = Math.ceil(Math.sqrt(userCount) / ratio);
      if (userCount > 8) {
        rows = Math.max(rows, 2);
      }

      let cols = Math.ceil(userCount / rows);

      const gridCount = cols * rows;

      const padding = RANDOMNESS / 2;
      const fullSize = Math.min(spaceHeight / rows, spaceWidth / cols);
      const size = fullSize - RANDOMNESS;
      const fullBlockWidth = fullSize * cols;
      const fullBlockHeight = fullSize * rows;
      const leftPadding = 40 + (spaceWidth - fullBlockWidth) / 2;
      const topPadding = 40 + (spaceHeight - fullBlockHeight) / 2;

      let col = 0;
      let row = 0;
      let randomId = 0;

      const getRand = () => {
        randomId++;
        if (randomId >= randomList.Length) {
          randomId = 0;
        }
        return padding - randomList[randomId] * RANDOMNESS;
      };

      for (let i = 0; i < gridCount; i++) {
        let yesTopRand = getRand();
        let yesLeftRand = getRand();
        let noTopRand = getRand();
        let noLeftRand = getRand();

        yes.push({
          top: row * fullSize + RANDOMNESS + topPadding + yesTopRand,
          left: col * fullSize + RANDOMNESS + leftPadding + yesLeftRand,
        });
        no.push({
          top: row * fullSize + RANDOMNESS + topPadding + noTopRand + s.contHeight / 2,
          left: col * fullSize + RANDOMNESS + leftPadding + noLeftRand,
        });
        col++;
        if (col >= cols) {
          row++;
          col = 0;
        }
      }

      return {
        yes,
        no,
        size,
        rows,
        cols,
        count: yes.length,
      };
    },
  },
  actions: {
    initMeeting() {},
    onMouseDown() {
      this.mouseDown = true;
    },
    onMouseUp() {
      this.mouseDown = false;
    },
    updateMouse({ offsetX, offsetY, moveX, moveY }) {
      this.offsetX = offsetX;
      this.offsetY = offsetY;
      this.moxeX = moveX;
      this.moveY = moveY;
    },
    onMouseMoveStart() {
      this.mouseMoving = true;
    },
    onMouseMoveStop() {
      this.mouseMoving = false;
    },
    updateHoveredControl(data) {
      this.hoveredControl = data;
    },
    onAltDown() {
      this.alt = true;
    },
    onAltUp() {
      this.alt = false;
    },
    setResizing(v) {
      this.resizing = v;
    },
    onResize() {
      this.viewportH = window.innerHeight;
      this.viewportW = window.innerWidth;
      this.sidebarW = document.getElementById('sidebarSizeCheck').setWidth;
      this.landscapeContainer = this.viewportH <= this.viewportW - this.sidebarW;
      this.rem = parseFloat(getComputedStyle(document.documentElement).fontSize);
      this.updateContainer();
    },
    onOrientationChange(v) {
      this.orientation = v;
    },
    updateContainer() {
      const cont = document.getElementById('counterFrame');
      if (cont) {
        this.contHeight = cont.offsetHeight;
      }
    },
    setFullscreen(v) {
      this.fullscreen = v;
    },
  },
});
