<template>
  <div class="subContent">
    <h2 key="inviteHeader">Invite Colleagues</h2>
    <div key="quickstart" class="content quickstartContent">
      <div class="actions btn-pair">
        <a id="quickstartEmail" class="btn" :href="mailToInvite"><span class="text">Send email</span></a>

        <button ref="copyBtn" type="button" @click="meetingInviteLinkCloned()">
          <span class="text">Copy link</span>
        </button>

        <transition name="basic">
          <span v-if="successMsg" class="copied">Copied!</span>
        </transition>
      </div>
      <browser-guarded-button id="quickstartGo" class="done btn solo btn-border btn-green-flood" @click="go">
        <app-loading-spinner v-if="isLoading" />
        <span v-else class="text">Continue</span>
      </browser-guarded-button>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { nextTick } from 'vue';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import BixeApi from '@/services/bixe-api.service';
import emailString from '@/resources/emails';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import BrowserGuardedButton from '@/components/BrowserGuardedButton.vue';
import utils from '../../resources/utils';

export default {
  components: {
    AppLoadingSpinner,
    BrowserGuardedButton,
  },
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    return { accountStore, pageStore, meetingStore };
  },
  beforeRouteEnter(to, from, next) {
    const accountStore = useAccountStore();
    BixeApi.getMeeting(to.params.id, 'personalRoomDetails=1')
      .then((meeting) => {
        if (accountStore.currentUser.id === meeting.owner_id) {
          next((vm) => vm.setMeeting(meeting));
        } else {
          next({ path: `/meeting/${to.params.id}` });
        }
      })
      .catch(() => {
        next({ path: '/join?reason=wrongLink' });
      });
  },

  beforeRouteUpdate(to, from, next) {
    const accountStore = useAccountStore();
    this.meeting = null;
    BixeApi.getMeeting(to.params.id, 'personalRoomDetails=1')
      .then((meeting) => {
        this.meeting = meeting;
        if (accountStore.currentUser.id === meeting.owner_id) {
          next((vm) => vm.setMeeting(meeting));
        } else {
          next({ path: `/meeting/${to.params.id}` });
        }
      })
      .catch(() => {
        next({ path: '/join?reason=wrongLink' });
      });
  },
  props: ['pageName'],
  data() {
    return {
      isLoading: false,
      meeting: null,
      successMsg: false,
    };
  },
  mounted() {
    nextTick(() => {
      this.pageStore.update({
        pageName: 'quickstart',
        meeting: {},
      });
    });
  },
  unmounted() {
    this.isLoading = false;
  },
  computed: {
    meetingUrl() {
      if (!this.meeting) {
        return false;
      }
      const origin = window.location.origin;
      return `${origin}${this.accountStore.baseUrl}/meeting/${this.meeting.id}?pwd=${this.meeting.pin_pass}`;
    },
    personalRoomInviteUrl() {
      if (this.meeting?.meta?.prInviteDetails?.pin) {
        const origin = window.location.origin;
        const { pin, pinPass } = this.meeting.meta.prInviteDetails;
        return `${origin}${this.accountStore.baseUrl}/room/${pin}?pwd=${pinPass}`;
      }
      return false;
    },
    mailToInvite() {
      if (!this.meeting) {
        return null;
      }
      const isRoom = this.accountStore.preferredMeetingType === 'personalroom';
      if (isRoom) {
        const origin = window.location.origin;
        const baseUrl = this.accountStore.baseUrl;
        const { pin_number, pin_pass } = this.accountStore.currentUser;
        const roomUrl = `${origin}${baseUrl}/room/${pin_number}?pwd=${pin_pass}`;

        return emailString('room', {
          roomUrl: roomUrl,
          roomNumber: pin_number,
          roomPassword: pin_pass,
          baseUrl: baseUrl,
        });
      }

      return emailString('quickstart', {
        meetingId: this.meeting.id,
        pinNumber: this.meeting.pin_number,
        pinPass: this.meeting.pin_pass,
        baseUrl: this.accountStore.baseUrl,
      });
    },
  },
  methods: {
    go() {
      this.isLoading = true;
      utils.storage.ss.setItem(`platform.invitationViewed.${this.meeting.id}`, '1');
      this.$router.push('/meeting/' + this.meeting.id).catch(() => null);
    },
    setMeeting(meeting) {
      this.meeting = meeting;
    },
    success() {
      this.successMsg = true;
      this.$refs.copyBtn.blur();
      _.delay(() => {
        this.successMsg = false;
      }, 3000);
    },
    async meetingInviteLinkCloned() {
      if (this.meeting?.meta?.prInviteDetails?.pin) {
        utils.clipBoard.addItem(this.personalRoomInviteUrl);
      } else {
        utils.clipBoard.addItem(this.meetingUrl);
      }
      this.success();
      await this.$API.insertMeetingStats(this.meeting.id, { action: 'menu_copy_invite_used', source: 'quickStart' });
    },
  },
};
</script>
<style lang="scss" scoped>
.quickstart {
  .quickstartContent {
    .loadingSpinner {
      width: 100%;
    }
  }
}
</style>
