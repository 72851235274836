<template>
  <div class="meetingSpace" :style="{ opacity: opacity }" @click="clicked">
    <transition name="basic">
      <lobby-view v-show="currentPhase === 'lobby'" key="lobby" />
    </transition>
    <transition name="basic">
      <decision-view v-show="currentPhase === 'decision' && !phaseStore.isAnimating" key="decision" />
    </transition>
    <transition name="basic">
      <presentation-view v-show="phaseStore.isPresentation && !phaseStore.isAnimating" key="presentation" :animating="phaseStore.isAnimating" />
    </transition>
    <transition name="basic">
      <dialogue-view v-show="currentPhase === 'dialogue' && !phaseStore.isAnimating" key="dialogue" :animating="phaseStore.isAnimating" />
    </transition>
    <transition name="basic">
      <feedback-view v-show="currentPhase === 'feedback' && !phaseStore.isAnimating" key="feedback" :animating="phaseStore.isAnimating" />
    </transition>
    <transition name="basic">
      <finish-view v-show="currentPhase === 'endMeeting' && !phaseStore.isAnimating" key="presentation" />
    </transition>
    <transition name="basic">
      <window-view v-show="showWindow" key="windowView" />
    </transition>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import LobbyView from '@/components/LobbyView';
import DecisionView from '@/components/DecisionView';
import PresentationView from '@/components/PresentationView';
import FinishView from '@/components/FinishView';
import WindowView from '@/components/WindowView';
import DialogueView from '@/components/DialogueView';
import FeedbackView from '@/components/FeedbackView';
import spaces from '@/resources/spaces';

import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    return { frameStore, phaseStore, meetingStore, usersStore };
  },
  components: {
    LobbyView,
    DecisionView,
    FeedbackView,
    PresentationView,
    FinishView,
    WindowView,
    DialogueView,
  },
  data() {
    return {};
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      avatarsMoving: 'avatarsAnimating',
      myId: 'myId',
    }),
    ...mapPiniaState(useUsersStore, [
      'me',
      'owner',
      'usersVisible',
      'speaking',
      'iAmSpeaking',
      'iAmOwner',
      'iAmActive',
      'iAmMuted',
      'iAmVideoMuted',
      'iAmAway',
      'iAmInWaitingRoom',
    ]),
    currentPhase() {
      return this.phaseStore.currentPhase;
    },
    opacity() {
      return this.meetingStore.initTitleCardShown && this.usersStore.firstShown ? 1 : 0;
    },
    showWindow() {
      return spaces[this.meetingStore.space]?.breakBackground && (this.iAmAway || this.iAmInWaitingRoom) && !this.phaseStore.isAnimating;
    },
    thinking() {
      return (!this.meetingStore.initTitleCardShown || !this.usersStore.firstShown || this.phaseStore.isAnimating) && this.page === 'meetingFrame';
    },
    animating() {
      return !this.meetingStore.initTitleCardShown || !this.usersStore.firstShown || this.phaseStore.isAnimating || this.avatarsMoving;
    },
  },
  watch: {
    iAmOwner: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmOwner');
          document.body.classList.remove('iAmUser');
        } else {
          document.body.classList.add('iAmUser');
          document.body.classList.remove('iAmOwner');
        }
      },
    },
    iAmSpeaking: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmSpeaking');
          document.body.classList.remove('iAmNotSpeaking');
        } else {
          document.body.classList.add('iAmNotSpeaking');
          document.body.classList.remove('iAmSpeaking');
        }
      },
    },
    'phaseStore.mirroring.requestedBy': {
      immediate: true,
      handler(nv) {
        if (nv && nv === this.myId) {
          document.body.classList.add('iAmMirroring');
        } else {
          document.body.classList.remove('iAmMirroring');
        }
      },
    },
    iAmMuted: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmMuted');
        } else {
          document.body.classList.remove('iAmMuted');
        }
      },
    },
    iAmVideoMuted: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmVideoMuted');
        } else {
          document.body.classList.remove('iAmVideoMuted');
        }
      },
    },
    iAmActive: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmActive');
          document.body.classList.remove('iAmNotActive');
        } else {
          document.body.classList.add('iAmNotActive');
          document.body.classList.remove('iAmActive');
        }
      },
    },
    thinking: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('thinking');
        } else {
          document.body.classList.remove('thinking');
        }
      },
    },
    currentPhase: {
      immediate: true,
      handler(nv, ov) {
        if (nv !== 'init') {
          let delay = this.meetingStore.initTitleCardShown ? 2000 : 3000;

          document.body.classList.add(nv, nv + '-animateIn', 'phase-animating');

          if (ov) {
            document.body.classList.add(ov + '-animateOut');
            document.body.classList.remove(ov);
          }

          this.phaseStore.startedAnimating();
          _.delay(() => {
            document.body.classList.remove(nv + '-animateIn', 'phase-animating');
            if (ov) {
              document.body.classList.remove(ov + '-animateOut');
            }
            this.phaseStore.stoppedAnimating();
          }, delay);
        }
      },
    },
    'frameStore.landscapeContainer': {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.remove('narrow');
          document.body.classList.add('wide');
        } else {
          document.body.classList.remove('wide');
          document.body.classList.add('narrow');
        }
      },
    },
    animating: {
      immediate: true,
      handler(doing) {
        if (doing) {
          // Start of animation
        } else {
          // End of animation
        }
      },
    },
  },
  methods: {
    clicked(e) {
      this.$ee.emit('nM:environmentClicked', e);
    },
  },
};
</script>
<style lang="scss" scoped>
.meetingSpace {
  transition: opacity 0.5s;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;
}
</style>
