<template>
  <div class="whatNext pane" :class="extraClass">
    <transition-group name="what">
      <phase-menu v-if="current === 'beginWell'" class="beginWell content" key="beginWell" title="Begin Well" :sequential="true">
        <phase-button phase="context" :class="{ next: contextNext }" title="Set Context" @navigate="navigate"> Set Context </phase-button>
        <phase-button phase="checkIn" :class="{ next: checkInNext }" title="Check-In" @navigate="navigate"> Check-In </phase-button>
        <template #after>
          <skip-button to="inputXchanges"> Skip </skip-button>
        </template>
      </phase-menu>

      <phase-menu v-if="current === 'inputXchange'" class="inputXchange content" key="inputXchange" title="Meet Well">
        <phase-button phase="input" @navigate="navigate" title="Present"> Give an Input </phase-button>
        <phase-button phase="decision" title="Decision Council" @navigate="navigate" @nope="noDecision">
          Make a Decision
          <template #tips>
            <bubble-tip
              name="tooManyForDecision"
              class="rightTail"
              :show="bubbleShowing === 'tooManyForDecision'"
              direction="above"
              @close="bubbleShowing = false"
            >
              Make a Decision phase is currently only available for meetings with 16 or less participants.
            </bubble-tip>
          </template>
        </phase-button>
        <phase-button phase="breakTime" title="Take a Break" @navigate="navigate"> Take a Break </phase-button>
        <phase-button phase="groups" title="Split into Groups" @navigate="navigate" @nope="noGroups">
          Break-out Groups
          <template #tips>
            <bubble-tip
              name="notEnoughForGroups"
              class="rightTail"
              :show="bubbleShowing === 'notEnoughForGroups'"
              direction="above"
              @close="bubbleShowing = false"
            >
              You can only access the Groups Micro-Skill if there are four or more meeting participants.
            </bubble-tip>
          </template>
        </phase-button>

        <phase-button v-if="featuresStore.flags['CREATIVE_DIALOGUE']" phase="dialogue" @navigate="navigate" title="Creative Dialogue">Dialogue</phase-button>
        <phase-button phase="feedback" @navigate="navigate" title="Feedback">Feedback</phase-button>
        <template #after>
          <skip-button to="endWell"> End Well </skip-button>
        </template>
      </phase-menu>

      <phase-menu v-if="current === 'endWell'" class="endWell content" key="endWell" title="End Well" :sequential="true">
        <phase-button phase="review" :class="{ next: reviewNext }" title="Review" @navigate="navigate"> Review Moments </phase-button>
        <phase-button phase="checkOut" :class="{ next: checkOutNext }" title="Check-Out" @navigate="navigate"> Check-Out </phase-button>
        <template #after>
          <skip-button to="finish"> End Now </skip-button>
        </template>
      </phase-menu>
    </transition-group>
    <button class="stopGuide" v-if="usersStore.iAmModerator && current !== 'endWell'" @click.prevent="stopGuide">
      Stop Guide
      <transition name="basic">
        <app-loading-spinner v-if="waiting" />
      </transition>
    </button>
  </div>
</template>

<script>
import { useFeaturesStore } from '@/store/pinia/features';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import BubbleTip from '@/components/BubbleTip.vue';
import PhaseButton from '@/components/PhaseButton.vue';
import PhaseMenu from '@/components/PhaseMenu.vue';
import WhatNextSidebarSkipButton from '@/components/WhatNextSidebarSkipButton';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';

export default {
  components: {
    BubbleTip,
    PhaseButton,
    PhaseMenu,
    'skip-button': WhatNextSidebarSkipButton,
    AppLoadingSpinner,
  },
  setup() {
    const featuresStore = useFeaturesStore();
    const sidebarStore = useSidebarStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { featuresStore, sidebarStore, meetingStore, phaseStore, usersStore };
  },
  data() {
    return {
      bubbleShowing: false,
      waiting: false,
    };
  },
  computed: {
    current() {
      if (this.meetingStore.startedEndWell) {
        return 'endWell';
      } else if (!this.meetingStore.skippedBeginWell && !this.phaseStore.phaseList.includes('checkIn')) {
        return 'beginWell';
      } else {
        return 'inputXchange';
      }
    },
    contextNext() {
      return this.current === 'beginWell' && !this.phaseStore.phaseList.includes('context');
    },
    checkInNext() {
      return this.current === 'beginWell' && this.phaseStore.phaseList.includes('context');
    },
    reviewNext() {
      return this.current === 'endWell' && !this.phaseStore.phaseList.includes('review');
    },
    checkOutNext() {
      return this.current === 'endWell' && this.phaseStore.phaseList.includes('review');
    },
    extraClass() {
      return this.current === 'inputXchange' && this.featuresStore.flags['CREATIVE_DIALOGUE'] ? 'hasSoloDialogue' : '';
    },
  },
  watch: {
    'meetingStore.useGuide': function () {
      this.waiting = false;
    },
  },
  methods: {
    navigate(p) {
      this.sidebarStore.requestPane('skillBar', { phase: p, barType: 'info', screensharePlease: p === 'input', iStarted: true }, 'whatNext');
    },
    skipBeginWell() {
      this.$meetingmanager.skipBeginWell();
      this.meetingStore.skipBeginWell();
    },
    startEndWell() {
      this.$meetingmanager.startEndWell();
      this.meetingStore.startEndWell();
    },
    requestLeave() {
      this.$ee.emit('nM:meeting:fade');
      this.sidebarStore.closeSidebar();
      this.$ee.emit('nM:meeting:leave');
    },
    requestFinish() {
      this.$ee.emit('nM:meeting:finish');
    },
    noGroups() {
      this.bubbleShowing = 'notEnoughForGroups';
    },
    noDecision() {
      this.bubbleShowing = 'tooManyForDecision';
    },
    stopGuide() {
      this.$meetingmanager.updateAdvancedSetting('guide', false);
      this.waiting = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes flash-phaseIcon {
  0%,
  10%,
  90%,
  100% {
    background: var(--c__bg);
    color: var(--skill);
    border-color: var(--skill);
  }
  40%,
  60% {
    border-color: var(--skill);
    color: var(--c__bg);
    background: var(--skill);
  }
}

.hasSoloDialogue :deep(h3) {
  margin-bottom: 2em;
}

.what-enter-active,
.what-enter-active.basic-move {
  transition: all 0.3s;
}

.what-leave-active,
.what-leave-to {
  position: absolute;
  transition: opacity 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.what-enter-from,
.what-leave-to {
  opacity: 0;
}

.what-move {
  transition: transform 0.3s cubic-bezier(0.6, 0, 0, 1);
}
:deep(.hamburgerItem) {
  position: relative;
}

.pane.whatNext {
  justify-content: center;
  align-items: center;
  padding-top: rem(79px);
  .content {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h3 {
    @include type-size('medium');
    text-align: center;
    font-weight: bold;
    margin-bottom: rem(32px);
  }
  .stopGuide {
    margin: auto auto rem(33px) auto;
    position: relative;
    @include triggered {
      color: var(--c__accent);
    }
    .loadingSpinner {
      position: absolute;
      right: -1em;
      color: var(--c__accent);
    }
  }
  .iAmUser & .content,
  & .content.endWell {
    margin-bottom: auto;
    padding-bottom: rem(53px);
  }

  .next {
    :deep(.phaseIcon) {
      animation: flash-phaseIcon 2s infinite;
    }
    :deep(button) {
      @include triggered {
        :deep(.phaseIcon) {
          animation: none;
        }
      }
    }
  }
}
</style>
