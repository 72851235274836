<template>
  <div
    v-if="!adding"
    class="itemControls"
    :class="item.color"
    :style="{
      pointerEvents: editing ? 'auto' : 'none',
      transform: `scale(${1 / zoomMultiplier})`,
    }"
  >
    <div class="itemContents" v-if="editing" :style="{ top: top, left: left, width: width, height: height }">
      <div
        class="box"
        @mousedown.exact="drag"
        @mouseup.exact="drop"
        @mousedown.alt="newFromCopy"
        :style="{
          width: width,
          height: height,
        }"
      >
        <div
          class="textAligner"
          :style="{
            width: textareaWidth,
            height: textareaHeight,
            fontSize: fontSize,
          }"
        >
          <div
            ref="textDiv"
            class="editable"
            tabindex="0"
            contenteditable="true"
            v-once
            :value="text"
            @input="updateText($event.target.innerHTML)"
            @paste="updateText($event.target.innerHTML)"
            @keydown.prevent.enter="done"
            :style="{
              width: textareaWidth,
              height: textareaHeight,
            }"
          >
            {{ text }}
          </div>
          <textarea
            ref="textbox"
            v-on:keydown.enter.prevent="done"
            v-model="text"
            :style="{
              width: textareaWidth,
              height: textareaHeight,
              fontSize: fontSize,
            }"
            resizable="false"
          ></textarea>
        </div>
      </div>

      <button
        class="delete"
        @click="deleteNote"
        :style="{
          transform: `scale(${zoomMultiplier})`,
        }"
      >
        <svg viewBox="0 0 10 10" class="icon" aria-hidden="true">
          <path d="M1,1L9,9" stroke-width="2" />
          <path d="M1,9L9,1" stroke-width="2" />
        </svg>
        <span class="visuallyHidden">Delete</span>
      </button>

      <div
        class="toolkit"
        :style="{
          transform: `scale(${zoomMultiplier})`,
        }"
      >
        <div class="colors">
          <button class="colorButton" v-for="color in colors" :key="color" :class="[color, item.color === color ? 'selected' : '']" @click="selectColor(color)">
            <span class="visuallyHidden">{{ color }}</span>
          </button>
        </div>
        <div class="sizes" v-if="advanced">
          <button
            class="fontButton"
            :class="['size-' + size, item.fontSize === size ? 'selected' : '']"
            v-for="size in fontSizes"
            @click="selectSize(size)"
            :key="'size-' + size"
          >
            <span
              class="icon"
              aria-hidden="true"
              :style="{
                fontSize: size ? Math.pow(typeScale, size) * 0.75 + 'em' : '0.75em',
              }"
              >Aa</span
            >
            <span class="visuallyHidden">Size {{ size }}</span>
          </button>
        </div>
        <div class="utils">
          <button class="cardSize size-s" @click="selectCardSize('s')">S</button>
          <button class="cardSize size-m" @click="selectCardSize('m')">M</button>
          <button class="cardSize size-l" @click="selectCardSize('l')">L</button>

          <button class="duplicate" @click="newFromCopy">
            <svg viewBox="0 0 20 20" class="icon" aria-hidden="true">
              <path class="square" d="M19,1l0,13l-13,0l0,-13z" stroke-width="2" fill="none" />
              <path d="M4,6l-3,0l0,12l12,0l0,-2" stroke-width="1" fill="none" />
              <path class="cross" d="M12.5,4l0,7" stroke-width="1.5" />
              <path class="cross" d="M9,7.5l7,0" stroke-width="1.5" />
            </svg>
            <span class="visuallyHidden">Duplicate</span>
          </button>
          <button class="done" @click="done">Done</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { useMappingStore } from '@/store/pinia/mapping';
import { useFrameStore } from '@/store/pinia/frame';
import mappingConstants from '@/resources/mapping-constants';
const { sizes } = mappingConstants;
import { useMouse } from '@vueuse/core';

export default {
  name: 'MappingItemControls',
  data() {
    return {
      showing: false,
      fontSizes: [0, 1, 2, 3, 4, 5],
    };
  },
  props: {
    item: Object,
  },
  setup() {
    const mappingStore = useMappingStore();
    const { x: mouseX, y: mouseY } = useMouse();
    return { mappingStore, mouseX, mouseY };
  },
  mounted() {
    this.sizeBox();
    if (this.editing) {
      this.focusText();
    }
  },
  computed: {
    ...mapPiniaState(useFrameStore, {
      frameW: 'contWidth',
      frameH: 'contHeight',
    }),
    ...mapPiniaState(useMappingStore, {
      isDragging: 'dragging',
      isResizing: 'resizing',
      addingItem: 'adding',
      editingItem: 'editing',
    }),
    ...mapPiniaState(useMappingStore, ['grid', 'items', 'advanced', 'zoom', 'em', 'typeScale', 'viewBoxX', 'viewBoxY', 'colors', 'zoomMultiplier']),
    ...mapPiniaState(useUsersStore, ['myId']),
    text: {
      get() {
        return this.item.text;
      },
      set(v) {
        this.mappingStore.updateItem({
          localId: this.item.localId,
          text: v.replace(/(<([^>]+)>)/gi, ''),
        });
      },
    },
    lineHeightEm() {
      return '1em';
    },
    editable() {
      return !this.isDragging && !this.isResizing;
    },
    textColor() {
      return this.item.color === 'black' ? '#ffffff' : '#222222';
    },
    adding() {
      return this.addingItem !== false && this.addingItem === this.item.localId;
    },
    editing() {
      return this.editingItem !== false && this.editingItem === this.item.localId;
    },
    width() {
      return this.item.width + 'px';
    },
    height() {
      return this.item.height + 'px';
    },
    left() {
      return this.item.x - this.viewBoxX + 'px';
    },
    top() {
      return this.item.y - this.viewBoxY + 'px';
    },
    textareaWidth() {
      // return (this.item.width - 20) / this.zoomMultiplier + 'px';
      return this.item.width - 20 + 'px';
    },
    textareaHeight() {
      // return (this.item.height - 20) / this.zoomMultiplier + 'px';
      return this.item.height - 20 + 'px';
    },
    fontSize() {
      return this.item.fontSize ? Math.pow(this.typeScale, this.item.fontSize) + 'em' : '1em';
    },
  },
  watch: {
    textareaWidth(nv) {
      if (this.$refs.textDiv) {
        this.$refs.textDiv.style.width = nv;
      }
    },
    textareaHeight(nv) {
      if (this.$refs.textDiv) {
        this.$refs.textDiv.style.height = nv;
      }
    },
    text: function (newValue) {
      if (document.activeElement == this.$refs.textDiv) {
        return;
      }
      if (this.$refs.textDiv) {
        this.$refs.textDiv.innerHTML = newValue;
      }
    },
    editing(nv) {
      if (nv) {
        this.$nextTick(() => {
          // this.$refs.textbox.focus();
          this.focusText();
          this.showing = true;
        });
      } else {
        if (this.$refs.textDiv) {
          this.$refs.textDiv.blur();
        }
        this.$nextTick(() => {
          this.showing = false;
        });
      }
    },
  },
  methods: {
    focusText() {
      if (this.$refs.textDiv) {
        this.$refs.textDiv.innerHTML = this.item.text;
        this.$refs.textDiv.focus();
        this.sizeBox();
        if (this.$refs.textDiv.innerText && document.createRange) {
          let range = document.createRange();
          let selection = window.getSelection();
          range.selectNodeContents(this.$refs.textDiv);
          range.setStart(this.$refs.textDiv.firstChild, this.$refs.textDiv.innerText.length);
          range.setEnd(this.$refs.textDiv.firstChild, this.$refs.textDiv.innerText.length);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    sizeBox() {
      if (this.$refs.textDiv) {
        this.$refs.textDiv.style.width = this.textareaWidth;
        this.$refs.textDiv.style.height = this.textareaHeight;
      }
    },
    drag(e) {
      if (e.target !== this.$refs.textDiv) {
        this.done();
        this.mappingStore.setBackToEdit(this.item.localId);
        this.mappingStore.setDragging(this.item.localId);
      }
    },
    drop() {
      this.mappingStore.setBackToEdit(false);
      this.mappingStore.setDragging(false);
    },
    focusDiv() {
      if (this.$refs.textDiv) {
        this.$refs.textDiv.focus();
      }
    },
    updateItem(args) {
      this.mappingStore.updateItem({ localId: this.item.localId, ...args });
    },
    selectColor(v) {
      this.updateItem({ color: v });
    },
    selectSize(v) {
      this.updateItem({ fontSize: v });
    },
    selectCardSize(v) {
      this.$ee.emit('nM:dialogue:boxMoved');
      this.updateItem({
        width: sizes[v].card * this.grid,
        height: sizes[v].card * this.grid,
        fontSize: sizes[v].font,
      });
    },
    updateText(v) {
      this.mappingStore.updateItem({ localId: this.item.localId, text: v });
    },
    edit() {
      this.mappingStore.setEditingMapItem(this.item.localId);
    },
    done() {
      this.mappingStore.setBackToEdit(false);
      this.mappingStore.updateItem({ localId: this.item.localId, text: this.text });
      this.mappingStore.setEditingMapItem(false);
    },
    newFromCopy() {
      const localId = `card_${this.myId}_${Object.keys(this.items).length}`;
      this.mappingStore.setAdding(localId);
      let args = {
        ...this.item,
        localId,
      };
      this.mappingStore.addMappingItem(args);
      this.mappingStore.setEditingMapItem(false);
    },
    deleteNote() {
      this.mappingStore.removeItem({ type: 'card', id: this.item.id, localId: this.item.localId });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.itemControls {
  background: rgba(black, 0.5);

  .itemContents {
    position: absolute;
  }

  .textAligner,
  .box {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    cursor: text;
  }

  .editable {
    text-align: center;
    padding: 5px 0;
    margin: auto 0;
    color: var(--c__black);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    &:focus {
      outline: none;
    }
  }

  textarea {
    display: none;
    border: none;
    background: none;
    padding: 0;
    line-height: 1;
    margin-top: 10px;
    margin-left: 10px;
    resize: none;
    &:focus {
      outline: none;
    }
  }

  .delete {
    position: absolute;
    top: 0;
    right: 0;
    margin: -1em -1em 0 0;
    border-radius: 50%;
    height: 2em;
    width: 2em;
    padding: 0.3em;
    border: 3px solid $c_red;
    background: white;
    color: $c_red;
    svg {
      width: 100%;
    }
    path {
      stroke: currentColor;
    }
    @include triggered {
      color: white;
      background: $c_red;
    }
  }

  .toolkit {
    margin: 10px -0.25em 0 -0.25em;
    width: 22.5em;
    transform-origin: top left;
    & > div {
      display: flex;
    }
    //flex-wrap: wrap;

    button {
      flex: 0 0 auto;
      margin: 0.25em;
    }

    .sizes {
      flex-direction: row-reverse;
      justify-content: flex-end;
      button {
        height: 3em;
        min-width: 2em;
        padding: 0 0.25em;
        border-radius: 0.25em;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background: none;
        border: none;
        @include triggered {
          color: blue;
          outline: none;
        }
        &.selected {
          background: rgba($c_blue, 0.5);
          color: black;
        }
      }
      .size-0 {
        padding-bottom: 0.5em;
      }
      .size-1 {
        padding-bottom: 0.35em;
      }
      .size-2 {
        padding-bottom: 0.28em;
      }
      .size-3 {
        padding-bottom: 0.25em;
      }
      .size-4 {
        padding-bottom: 0.15em;
      }
    }
  }
  .colorButton {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    border: solid 5px;
    flex: 0 0 auto;
    @each $color, $col in $note_full-colors {
      &.#{$color} {
        border-color: map-get($col, border);
        background: map-get($col, fill);
        color: map-get($col, text);
        @include triggered {
          background: map-get($col, border);
        }

        &.selected {
          background: map-get($col, border);
        }
      }
    }

    &.selected {
      color: white;
    }

    &.black {
      @include triggered {
        color: white;
      }
      &.selected {
        @include triggered {
          color: white;
        }
      }
    }

    &.none {
      border: 2px dashed #cccccc;
      background: none;
      @include triggered {
        border-color: black;
        background: #dddddd;
      }
      &.selected {
        color: black;
      }
    }

    &.selected::after {
      content: '\2713';
    }
  }

  .utils {
    button {
      height: 2em;
      border: 3px solid var(--c__text-loud);
      color: var(--c__text-loud);
      background: var(--c__bg);
      @include triggered {
        color: var(--c__bg);
        background: var(--c__text-loud);
      }
    }

    .done {
      border: 3px solid $c_blue;
      color: $c_blue;
      @include triggered {
        background: $c_blue;
      }
    }

    .duplicate {
      border: none;
      padding: 0;
      width: 2em;
      background: none;
      color: var(--c__text-loud);
      svg {
        width: 100%;
      }
      path {
        stroke: currentColor;
      }
      @include triggered {
        color: var(--c__text-loud);
        background: none;
        .square {
          fill: var(--c__text-loud);
        }
        .cross {
          stroke: var(--c__bg);
        }
      }
    }

    .cardSize {
      width: 2em;
    }
  }
}
</style>
