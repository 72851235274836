<template>
  <please-use class="pleaseUseMedia" data-page-name="pleaseUseMedia">
    <img class="mediaPlease" :src="accountStore.baseUrl + '/assets/images/mediaPlease.png'" />
    <h1>Media device not connected</h1>
    <p>Please connect audio and video devices to use bixe.</p>
  </please-use>
</template>

<script>
import { nM } from '@/legacy';
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';
import PleaseUse from '@/components/PleaseUse';
export default {
  components: {
    PleaseUse,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { pageStore, accountStore };
  },
  mounted() {
    const timeId = setInterval(() => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          clearInterval(timeId);
          const redirectUrl = this.$route.query.redirectUrl;
          const url = redirectUrl ? nM.normalizeUrl(redirectUrl) : '/welcome';
          this.$router.push(url).catch(() => null);
        })
        .catch(() => null);
    }, 3000);

    this.pageStore.update({
      pageName: this.pageName,
    });
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-top: rem(14px);
  max-width: 15em;
}
img {
  height: rem(38px);
  width: rem(94px);
  margin-bottom: rem(42px);
}
</style>
