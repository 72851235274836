import KeyboardShortcuts from '@/services/keyboard-shortcuts.service';

let installed = false;

export default {
  install: () => {
    if (installed) return;
    KeyboardShortcuts.init();
  },
};
