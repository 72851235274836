<template>
  <div class="background">
    <transition-group name="basic">
      <nowhere-background v-if="meetingStore.space === 'nowhere' && showBackground" key="nowhere" />
      <cafe-background v-if="meetingStore.space === 'cafe' && showBackground" key="cafe" />
      <zen-background v-if="meetingStore.space === 'zen' && showBackground" key="zen" />
      <beach-background v-if="meetingStore.space === 'beach' && showBackground" key="beach" />
      <neon-background v-if="meetingStore.space === 'neon' && showBackground" key="neon" />
    </transition-group>
  </div>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import NowhereBackground from '@/components/NowhereBackground';
import ZenBackground from '@/components/ZenBackground';
import BeachBackground from '@/components/BeachBackground';
import NeonBackground from '@/components/NeonBackground';
import CafeBackground from '@/components/CafeBackground';
export default {
  components: {
    NowhereBackground,
    ZenBackground,
    BeachBackground,
    NeonBackground,
    CafeBackground,
  },
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const phaseStore = usePhaseStore();
    return { pageStore, meetingStore, phaseStore };
  },
  computed: {
    showBackground() {
      if (!this.pageStore.isMeeting) {
        return false;
      }
      if (!this.meetingStore.initTitleCardShown) {
        return false;
      }
      if (this.phaseStore.currentPhase === 'ended') {
        return false;
      }
      if (this.phaseStore.currentPhase === 'decision' && this.phaseStore.phaseStage !== 'proposal') {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.background {
  pointer-events: none;
  background: var(--c__bg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.meeting .background {
  opacity: 1;
  background: var(--c__bg-alt);
}
</style>
