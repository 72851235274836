const generalPanes = {
  basic: {
    position: 0,
  },
  outside: {
    name: 'outside',
    position: 0,
  },
  whatNext: {
    name: 'whatNext',
    position: 1,
  },
  lobby: {
    name: 'lobby',
    position: 0,
  },
  waitingRoom: {
    name: 'waitingRoom',
    position: 0,
  },
  endBar: {
    name: 'endBar',
    position: 0,
  },
  skillBar: {
    name: 'skillBar',
    position: 2,
  },
  hamburger: {
    name: 'hamburger',
    inHamburger: true,
    hasClose: true,
    position: 12,
  },
  microskills: {
    name: 'microskills',
    inHamburger: true,
    hasClose: true,
    position: 11,
  },
  po3: {
    name: 'po3',
    inHamburger: true,
    hasClose: true,
    position: 11,
  },
};

const editPanes = {
  name: {
    name: 'name',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'po3',
    position: 15,
  },
  purpose: {
    name: 'purpose',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'po3',
    position: 15,
  },
  objectives: {
    name: 'objectives',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'po3',
    position: 15,
  },
  outputs: {
    name: 'outputs',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'po3',
    position: 15,
  },
  outcomes: {
    name: 'outcomes',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'po3',
    position: 15,
  },
  checkInQuestion: {
    name: 'checkInQuestion',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'skillBar',
    position: 3,
  },
  proposal: {
    name: 'proposal',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'skillBar',
    position: 3,
  },
  topic: {
    name: 'topic',
    isEditPane: true,
    noInterrupt: true,
    hasClose: true,
    backTo: 'skillBar',
    position: 3,
  },
};

const overLayPanes = {
  meetingTime: {
    name: 'meetingTime',
    noBackTo: true,
    inHamburger: true,
    hasClose: true,
    position: 11,
  },
  settings: {
    name: 'settings',
    noBackTo: true,
    inHamburger: true,
    hasClose: true,
    noLeaveButton: true,
    position: 50,
    temp: true,
  },
  emoteGrid: {
    name: 'emoteGrid',
    noBackTo: true,
    inHamburger: true,
    hasClose: true,
    noLeaveButton: true,
    position: 60,
    temp: true,
  },
  participantList: {
    name: 'participantList',
    noBackTo: true,
    inHamburger: true,
    hasClose: true,
    noLeaveButton: true,
    position: 70,
    temp: true,
  },
  spaces: {
    name: 'spaces',
    noBackTo: true,
    noInterrupt: false,
    inHamburger: true,
    hasClose: true,
    noLeaveButton: true,
    backTo: 'hamburger',
    position: 60,
  },
};

const sidebarPanes = {
  ...editPanes,
  ...overLayPanes,
  ...generalPanes,
};

export default sidebarPanes;
