<template>
  <div
    class="outerContainer"
    :style="{ '--vh': frameStore.viewportH * 0.01 + 'px', '--vw': frameStore.viewportW * 0.01 + 'px' }"
    :class="[
      {
        alt: frameStore.alt,
        clicking: frameStore.mouseDown,
        hasNotes: meetingStore.useNotes,
        simplifiedGraphics: featuresStore.settings.simplified_graphics,
        ugly: featuresStore.perf.uglyMode,
        noAnimations: featuresStore.noAnimations,
        noMask: featuresStore.perf.noMask,
      },
      featuresStore.cpuLevel + '-cpu',
    ]"
  >
    <div id="container">
      <header id="topHeader" class="topHeader layout_sb" v-if="!['stopSharingDrawer', 'avatarsDockDrawer'].includes(pageName)" ß>
        <the-top-logo />
        <the-top-title />
        <the-hamburger-button />
        <the-meeting-buttons />
        <the-meeting-timer v-if="pageStore.isMeeting" />
        <top-alerts />
      </header>
      <the-background />
      <the-page-frame class="page-frame" />
      <audio-alarm />
      <audio-ding />
      <template v-if="!['stopSharingDrawer', 'avatarsDockDrawer'].includes(pageName)">
        <the-sidebar />
        <devices-user />
        <devices-meeting />
      </template>
    </div>
    <please-use-bigger v-if="showSizeOverlay" />
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import _ from 'underscore';
import anim from '@/resources/animation-constants';
import screenfull from 'screenfull';
import { saveAs } from 'file-saver';
import AudioAlarm from '@/components/AudioAlarm.vue';
import AudioDing from '@/components/AudioDing.vue';
import TopAlerts from '@/components/TopAlerts.vue';
import TheMeetingTimer from '@/components/TheMeetingTimer.vue';
import TheSidebar from '@/components/TheSidebar.vue';
import TheBackground from '@/components/TheBackground.vue';
import TheHamburgerButton from '@/components/TheHamburgerButton.vue';
import TheMeetingButtons from '@/components/TheMeetingButtons.vue';
import ThePageFrame from '@/components/ThePageFrame.vue';
import TheTopLogo from '@/components/TheTopLogo.vue';
import TheTopTitle from '@/components/TheTopTitle.vue';
import DevicesMeeting from '@/components/DevicesMeeting';
import DevicesUser from '@/components/DevicesUser';
import PleaseUseBigger from '@/components/PleaseUseBigger';
import { nM } from '@/legacy';
import utils from './resources/utils.js';
import { useStorage } from 'vue3-storage';
import { useCookies } from 'vue3-cookies';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useMomentsStore } from '@/store/pinia/moments';
import { usePhaseStore } from '@/store/pinia/phase';
import { useFeaturesStore } from '@/store/pinia/features';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import { useFrameStore } from '@/store/pinia/frame';
import { useTimeStore } from '@/store/pinia/time';
import { useMediaStore } from '@/store/pinia/media';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { localStorageKeysMap } from './shared/constants';
import errorReportingService from './services/errorReportingService';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });
const localStorage = useStorage({ namespace: '', storage: 'local' });
const { cookies } = useCookies();

const perfBrackets = {
  small: [1, 2, 3],
  medium: [3, 4, 5],
  large: [4, 7, 10],
  larger: [7, 10, 12],
  largest: [9, 11, 16],
};

export default {
  name: 'App',
  components: {
    AudioAlarm,
    AudioDing,
    TopAlerts,
    TheMeetingTimer,
    TheSidebar,
    TheBackground,
    TheHamburgerButton,
    TheMeetingButtons,
    ThePageFrame,
    TheTopLogo,
    TheTopTitle,
    DevicesMeeting,
    DevicesUser,
    PleaseUseBigger,
  },

  setup() {
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const momentsStore = useMomentsStore();
    const phaseStore = usePhaseStore();
    const featuresStore = useFeaturesStore();
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const frameStore = useFrameStore();
    const timeStore = useTimeStore();
    const mediaStore = useMediaStore();
    const usersStore = useUsersStore();
    const sidebarStore = useSidebarStore();

    accountStore.init(window.__INITIAL_STATE__);

    return {
      meetingStore,
      momentsStore,
      messagesStore,
      phaseStore,
      featuresStore,
      accountStore,
      pageStore,
      timeStore,
      frameStore,
      mediaStore,
      usersStore,
      sidebarStore,
    };
  },
  data() {
    return {
      debouncedOnResize: _.debounce(this.onResize, 20),
      debouncedUpdateResizing: _.debounce(this.updateResizing, 500),
      debouncedOnOrientation: _.debounce(this.onOrientation, 20),
      debouncedUpdateState: _.debounce(this.updateState, 20),
      debouncedStopMousemove: _.debounce(this.onStopMousemove, 200),
      debouncedStartMousemove: _.debounce(this.onStartMousemove, 200, true),
      debouncedRequestReSync: _.debounce(this.requestReSync, 500, true),
      debouncedCheckVideoQuality: _.debounce(this.checkVideoQuality, 800),
      currentTheme: false,
      loaded: false,
      resizing: false,
    };
  },
  created: function () {
    this.testCpu();
    this.featuresStore.init(window.__INITIAL_STATE__);
    Velocity.defaults.easing = anim.BASE_EASE_IN_OUT;

    const isOnWindows = window.navigator.userAgent.toLowerCase().includes('windows');
    if (utils.isOpenedWithinElectronShell() && isOnWindows) {
      document.title = '';
    }

    Velocity.RegisterEffect('transition.blipIn', {
      defaultduration: anim.BASE_SPEED,
      calls: [
        [
          {
            opacity: [1, 0],
            scaleX: [1, [500, 20], 0],
            scaleY: [1, [500, 20], 0],
          },
          1,
        ],
      ],
    });

    Velocity.RegisterEffect('transition.blipOut', {
      defaultduration: anim.BASE_SPEED / 2,
      calls: [
        [
          {
            opacity: [0, 1],
            scaleX: [0, 1],
            scaleY: [0, 1],
          },
          1,
        ],
      ],
    });

    const _this = this;

    this.$API.on('settings:updated', _this.settingsUpdated);
    this.$API.on('retestCpu', _this.testCpu);

    this.$ee.on('api:startMeeting', () => {
      this.$ee.emit('bus:meetingStart');
    });

    this.$ee.on('api:initMeetingState', () => {
      this.$ee.emit('bus:meetingStart');
    });

    this.$ee.on('nM:loaded', () => {
      _this.hideLoading();
    });

    this.$ee.on('api:audioLevels', (data) => {
      this.$ee.emit('nM:volume:' + data.userId, data.volume);
      _this.usersStore.updateVolume(data);
    });

    this.$ee.on('api:talkingChange', (data) => {
      _this.usersStore.updateTalkingList(data);
    });

    this.$ee.on('sfx', (sound) => {
      _this.$sfx(sound);
    });

    this.$ee.on('nM:notice:add', (data) => {
      _this.sidebarStore.updateNotice(data);
    });

    this.$ee.on('api:alertAdd', (data) => {
      if (data.type === 'inline') {
        this.$ee.emit('nM:inlineMessage:' + data.name);
      } else {
        let hide = data.duration || false;
        hide = hide === 'temporary' ? 3000 : hide;
        this.messagesStore.addAlert({
          content: data.content,
          type: data.type,
          messageName: data.content,
          name: `alert:` + data.content,
          tipClass: data.class,
          tipId: _.uniqueId('msg_'),
          removeOnClose: true,
          autoHide: hide,
          force: true,
        });
      }
    });

    this.$ee.on('nM:userButton:toggleHand', () => {
      if (this.me) {
        if (this.me.hand) {
          this.$meetingmanager.handDown();
        } else {
          this.$meetingmanager.handUp();
        }
      }
    });

    this.$ee.on('nM:toggleHand', () => {
      if (this.me) {
        if (this.me.hand) {
          this.$meetingmanager.handDown();
        } else {
          this.$meetingmanager.handUp();
        }
      }
    });

    this.$ee.on('nM:handDown', () => {
      this.$meetingmanager.handDown();
    });

    this.$ee.on('nM:quickstart', _this.quickstart);

    this.$ee.on('navigate', (location, args) => {
      if (args && args.global) {
        window.location = location;
      } else {
        this.$router.push(location).catch(() => null);
      }
    });

    this.$ee.on('nM:guestLogout', _this.logout);
    this.$ee.on('nM:toggleMute', _this.toggleMute);
    this.$ee.on('nM:toggleMuteVideo', _this.toggleMuteVideo);
    this.$ee.on('nM:userButton:mute', _this.handleMute);
    this.$ee.on('nM:userButton:muteVideo', _this.handleMuteVideo);
    this.$ee.on('nM:userButton:eject', _this.handleEjectButton);
    this.$ee.on('nM:userButton:promote', _this.handlePromoteButton);
    this.$ee.on('nM:userButton:demote', _this.handleDemoteButton);
    this.$ee.on('nM:userButton:stopScreenShare', _this.handleStopSharingByMeetingOwner);
    this.$ee.on('bus:downloadNotes', _this.downloadNotes);
    this.$ee.on('bus:copyUrl', _this.copyUrl);
    this.$meetingmanager.on('user:returned', _this.usersReturned);
    this.$meetingmanager.on('user:unreturned', _this.usersUnreturned);
  },
  mounted() {
    const _this = this;

    this.$nextTick(() => {
      this.onResize();
      window.addEventListener('resize', this.startResize);
      window.addEventListener('resize', this.debouncedOnResize);
      this.onOrientation();
      window.addEventListener('orientationchange', this.debouncedOnOrientation);
      window.addEventListener('mousemove', this.debouncedStartMousemove);
      window.addEventListener('mousemove', this.debouncedStopMousemove);
      window.addEventListener('mousedown', this.frameStore.onMouseDown);
      window.addEventListener('mouseup', this.frameStore.onMouseUp);
      window.addEventListener('keydown', this.keydown);
      window.addEventListener('keyup', this.keyup);
    });

    this.$ee.on('nM:darkmode', _this.darkmode);
    this.$ee.on('nM:unDarkmode', _this.unDarkmode);
    this.$ee.on('nM:toggleCustomGroups', _this.toggleCustomGroups);
    this.$ee.on('nM:toggleDialogueMaps', _this.toggleDialogueMaps);
    this.$ee.on('nM:toggleShowTalking', _this.toggleShowTalking);
    this.$ee.on('nM:toggleSentry', _this.toggleSentry);
    this.$ee.on('nM:toggleThemes', _this.toggleThemes);
    this.$ee.on('nM:toggleConnectivityIndicator', _this.toggleConnectivityIndicator);
    this.$ee.on('nM:toggleDebug', _this.toggleDebug);
    this.$ee.on('nM:toggleExtraSettings', _this.toggleExtraSettings);

    this.$ee.on('nM:toggleDialogue', () => {
      _this.featuresStore.overrideFeatureFlag('dialogue');
    });
    this.$ee.on('nM:toggleFeedback', () => {
      _this.featuresStore.overrideFeatureFlag('feedback');
    });

    this.$ee.on('nM:showHostInfo', _this.showHostInfo);
    this.$ee.on('nM:toggleDevConsole', _this.toggleDevConsole);
    this.$ee.on('nM:deviceListChanged', _this.onDeviceAddedOrRemoved);
    this.init();

    if (utils.isOpenedWithinElectronShell()) {
      /* eslint-disable */
      if (ipcApi.onLogoutRequested) {
        ipcApi.onLogoutRequested(() => {
          this.logout();
        });
      }
      if (ipcApi.onStopSharingRequested) {
        ipcApi.onStopSharingRequested(
          _.debounce(async () => {
            await this.$videomanager.stopScreen();
            _this.mediaStore.setStopScreenSharingTimestamp(Date.now());
          }, 1000),
        );
      }
      if (ipcApi.onShowSidebarRequested) {
        ipcApi.onShowSidebarRequested(({ sidebarType }) => {
          _this.sidebarStore.requestPane(sidebarType);
        });
      }
      if (ipcApi.onWindowSizeManipulated) {
        ipcApi.onWindowSizeManipulated(() => {
          this.debouncedOnResize();
        });
      }
      if (ipcApi.registerNewVersionNotificationHandler) {
        ipcApi.registerNewVersionNotificationHandler((updates) => {
          console.log(`[bixe] - a new version ${updates.newVersion}, is available and ready to install`);
          const pagesToIgnoreUpdateNotification = ['meetingFrame', 'connectivity'];
          if (pagesToIgnoreUpdateNotification.includes(this.pageName)) {
            return;
          }
          const now = Date.now();
          const lastRemindedOn = utils.storage.ls.getItem(localStorageKeysMap.UPDATE_BIXE_NATIVE_REMINDED_ON);
          const currentVersion = utils.storage.ls.getItem(localStorageKeysMap.CURRENT_BIXE_NATIVE_VERSION);
          const msInADay = 1000 * 60 * 60 * 24;
          const lastReminded24HoursAgo = lastRemindedOn ? now - lastRemindedOn > msInADay : true;
          if (lastReminded24HoursAgo || updates.newVersion !== currentVersion) {
            this.messagesStore.addAlert('bixeNativeNewVersionAvailable').then((doIt) => {
              if (doIt && utils.isOpenedWithinElectronShell()) {
                ipcApi.send({ name: 'installUpdatesAndRestart' });
              }
            });
            utils.storage.ls.setItem(localStorageKeysMap.UPDATE_BIXE_NATIVE_REMINDED_ON, now);
            utils.storage.ls.setItem(localStorageKeysMap.CURRENT_BIXE_NATIVE_VERSION, updates.newVersion);
          }
        });
      }
      /* eslint-enable */
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.debouncedOnResize);
    window.removeEventListener('resize', this.startResize);
  },
  computed: {
    ...mapPiniaState(useFeaturesStore, ['theme']),
    ...mapPiniaState(useFrameStore, ['fullscreen']),
    ...mapPiniaState(useMeetingStore, ['space']),
    ...mapPiniaState(useMediaStore, ['devices', 'audioSource', 'audioOutputSource', 'videoSource', 'audioOn', 'audioOutputOn', 'videoOn']),
    ...mapPiniaState(useUsersStore, ['me', 'myId', 'getUserById', 'iAmMuted', 'iAmVideoMuted', 'iAmSysMuted', 'iAmSysVideoMuted', 'isSpeaking']),
    ...mapPiniaState(useUsersStore, {
      userCount: 'visibleUserCount',
      videoLoadedList: 'videoLoaded',
    }),
    videosLoaded() {
      if (this.videoLoadedList.has('connectivity')) {
        return this.videoLoadedList.size - 1;
      } else {
        return this.videoLoadedList.size;
      }
    },
    meetingCpuLevel() {
      if (!this.pageStore.isMeeting) {
        return false;
      } else {
        return this.featuresStore.cpuLevel;
      }
    },
    pageName() {
      return this.pageStore.current.pageName;
    },
    showSizeOverlay() {
      return ['meetingFrame', 'connectivity'].includes(this.pageName);
    },
    dateSlug() {
      let start = this.timeStore.meetingStart;
      if (!start) {
        return false;
      } else {
        let day = Intl.DateTimeFormat('en-uk', { day: '2-digit' }).format(start);
        let month = Intl.DateTimeFormat('en-uk', { month: '2-digit' }).format(start);
        let year = Intl.DateTimeFormat('en-uk', { month: 'numeric' }).format(start);
        let hour = Intl.DateTimeFormat('en-uk', { hour: '2-digit' }).format(start);
        let minute = Intl.DateTimeFormat('en-uk', { minute: '2-digit' }).format(start);

        let str = `${day}-${month}-${year}_${hour}-${minute}`;
        return str;
      }
    },
  },
  watch: {
    'usersStore.resyncRequestTs': {
      handler() {
        this.debouncedRequestReSync();
      },
    },
    '$route.params.id': function (nv) {
      this.meetingStore.updateMeetingId(nv);
    },
    userCount() {
      this.testCpu();
    },
    videosLoaded(nv, ov) {
      if (window.sessionStorage.getItem('temp_dynamic_video_quality_enabled')) {
        if (nv === 1 || (ov <= 4 && nv > 4) || (ov > 4 && nv <= 4)) {
          this.debouncedCheckVideoQuality(nv);
        }
      }
    },
    'featuresStore.noAnimations': {
      immediate: true,
      handler(v) {
        if (v) {
          Velocity.mock = true;
        } else {
          Velocity.mock = false;
        }
      },
    },
    meetingCpuLevel: {
      immediate: true,
      handler(nv, ov) {
        if (nv !== 'low' && nv !== 'bad') {
          this.messagesStore.removeAlert({ messageName: 'powerSaving' });
        }
        if (!this.featuresStore.hasShownDegradeMessage) {
          if (nv === 'bad') {
            this.featuresStore.setHasShownDegradeMessage(true);
            this.messagesStore.addAlert('powerSaving');
          }
          if (nv === 'low' && ov !== 'bad') {
            this.featuresStore.setHasShownDegradeMessage(true);
            this.messagesStore.addAlert('powerSaving');
          }
        }
      },
    },
    'featuresStore.theme': {
      immediate: true,
      deep: false,
      handler(nv) {
        ['dark', 'light', 'default', 'dynamic', ''].forEach((t) => {
          if (t === nv) {
            document.body.classList.add(nv + '-theme');
          } else {
            document.body.classList.remove(t + '-theme');
          }
        });
        document.body.classList.remove('-theme');
        document.body.classList.remove('undefined-theme');
        this.currentTheme = nv;
      },
    },
    'meetingStore.space': {
      immediate: true,
      deep: false,
      handler(nv) {
        if (this.currentSpace) {
          document.body.classList.remove(this.currentSpace + '-space');
        }
        document.body.classList.add(nv + '-space');
        document.body.classList.remove('-space');
        document.body.classList.remove('underfined-space');

        this.currentSpace = nv;
      },
    },
    resizing: {
      handler(nv) {
        if (nv) {
          this.updateResizing(nv);
        } else {
          this.debouncedUpdateResizing(nv);
        }
      },
    },
    fullscreen() {
      if (utils.isOpenedWithinElectronShell()) {
        this.debouncedOnResize();
      }
    },
  },
  methods: {
    init() {
      this.$API.getMpts().then((mpts) => {
        this.timeStore.setTimes(mpts);

        this.initExtras();
        this.initFullScreen();
        this.hideLoading();

        nM.dispatchers.init(this);
      });
    },
    initExtras() {
      if (window.NM_NO_OVERLAY) {
        document.documentElement.classList.add('noOverlay');
      }

      document.body.classList.remove('no-js');
      document.body.classList.remove('pre');
    },
    initFullScreen() {
      if (screenfull.enabled || screenfull.isEnabled) {
        document.addEventListener(screenfull.raw.fullscreenchange, () => {
          if (screenfull.isFullscreen) {
            this.frameStore.setFullscreen(true);
          } else {
            this.frameStore.setFullscreen(false);
          }
          this.onResize();
        });
      }
    },
    requestReSync() {
      this.$meetingmanager.requestReSync();
    },
    testCpu() {
      let cores;
      let count = navigator.hardwareConcurrency;

      if (navigator.userAgent.indexOf('Firefox') != -1) {
        count = count + 2;
      }

      if (!this.userCount || this.userCount < 5) {
        cores = perfBrackets.small;
      } else if (this.userCount < 9) {
        cores = perfBrackets.medium;
      } else if (this.userCount < 14) {
        cores = perfBrackets.large;
      } else if (this.userCount < 17) {
        cores = perfBrackets.larger;
      } else {
        cores = perfBrackets.largest;
      }

      if (count < cores[0]) {
        this.featuresStore.setDetectedCpuLevel('bad');
      } else if (count < cores[1]) {
        this.featuresStore.setDetectedCpuLevel('low');
      } else if (count < cores[2]) {
        this.featuresStore.setDetectedCpuLevel('normal');
      } else {
        this.featuresStore.setDetectedCpuLevel('great');
      }
    },
    checkVideoQuality(count) {
      let quality = 'high';
      let framerate = 'high';
      if (count > 4) {
        quality = this.featuresStore.settings.quality;
        framerate = this.featuresStore.settings.framerate || nM.frameRateMap[this.featuresStore.settings.frame_rate];
      }
      const [width, height] = nM.resolutionMapReverse[quality].split('x');
      this.$videomanager.onVideoResolutionUpdated(width, height, nM.frameRateMapReverse[framerate]);
    },
    updateState() {},
    startResize() {
      this.resizing = true;
    },
    updateResizing(v) {
      this.frameStore.setResizing(v);
    },
    onResize() {
      this.frameStore.onResize();

      this.$nextTick(() => {
        this.resizing = false;
      });
    },
    onOrientation() {
      this.frameStore.onOrientationChange(window.orientation);
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      });
    },
    onStartMousemove(e) {
      this.frameStore.updateMouse(e);
      this.frameStore.onMouseMoveStart(e);
    },
    onStopMousemove() {
      this.$nextTick(() => {
        this.frameStore.onMouseMoveStop();
      });
    },
    keydown(e) {
      if (e.keyCode === 18) {
        this.frameStore.onAltDown();
      }
    },
    keyup(e) {
      if (e.keyCode === 18) {
        this.frameStore.onAltUp();
      }
    },
    downloadNotes({ isMeetingEnded }) {
      const timezone = new Intl.DateTimeFormat('en-GB').resolvedOptions().timeZone;
      this.$API.downloadMeetingNotes(this.meetingStore.meetingId, timezone, isMeetingEnded).then((blob) => {
        let title = utils.desanitizeString(this.meetingStore.name || 'Untitled meeting');
        title = title.trim().replace(/\s+/g, '_');
        title = title.charAt(0).toUpperCase() + title.slice(1);
        const filename = `${title}_${this.dateSlug}.pdf`;
        saveAs(blob, filename);
      });
    },
    copyUrl() {
      this.$clipboard(this.meetingUrl);
    },
    toggleMute() {
      this.handleMute({
        id: this.myId,
        about: this.myId,
        muted: this.iAmMuted,
      });
    },
    toggleMuteVideo() {
      this.handleMuteVideo({
        id: this.myId,
        about: this.myId,
        muted: this.iAmVideoMuted,
      });
    },
    handleEjectButton(args) {
      let id = args.about;
      this.messagesStore
        .addAlert('sureYouWantToEject', { toRemove: utils.desanitizeString(this.getUserById(id).first_name), id })
        .then((doIt) => {
          if (doIt) {
            if (this.isSpeaking(id) && ['context', 'input'].includes(this.phaseStore.currentPhase)) {
              this.$meetingmanager.endPhase();
            }
            setTimeout(() => this.$meetingmanager.eject({ id }), 300);
          }
        })
        .catch(() => {});
    },
    handlePromoteButton(args) {
      let id = args.about;
      this.messagesStore
        .addAlert('sureYouWantToPromote', { toPromote: utils.desanitizeString(this.getUserById(id).first_name), id })
        .then((doIt) => {
          if (doIt) {
            this.$meetingmanager.recommendAsMeetingController({ userId: id });
          }
        })
        .catch(() => {});
    },
    handleDemoteButton(args) {
      let id = args.about;
      this.messagesStore
        .addAlert('sureYouWantToDemote', { toDemote: utils.desanitizeString(this.getUserById(id).first_name), id })
        .then((doIt) => {
          if (doIt) {
            this.$meetingmanager.removeUserFromMeetingControllers({ userId: id });
          }
        })
        .catch(() => {});
    },
    handleStopSharingByMeetingOwner(args) {
      this.messagesStore
        .addAlert('sureYouWantToStopSharing', { presenter: utils.desanitizeString(this.getUserById(args.about).first_name), id: args.about })
        .then((doIt) => {
          if (doIt) {
            const { requestedBy, mirroringId } = this.phaseStore.mirroring || {};
            if (requestedBy === args.about) {
              this.$meetingmanager.stopMirroring({ mirroringId, forceEndedByAdmin: true });
            }
          }
        })
        .catch(() => {});
    },
    handleMute(args) {
      if (args.about === args.id) {
        this.usersStore.updateUser(args.about, { muted: !args.muted });
      }
      if (args.about === this.myId) {
        const audioSource = sessionStorage.getStorageSync(`meetings:${this.meetingStore.meetingId}:audioSource`);
        this.mediaStore.setAudioSource(audioSource);
        this.mediaStore.setAudioOn(args.muted);
      }
      if (args.muted) {
        this.$meetingmanager.unmute(args.about, { unmuteAudio: true });
      } else {
        this.$meetingmanager.mute(args.about, { muteAudio: true });
      }
    },
    updateAudioStreamingDevices(muted) {
      if (muted) {
        this.$videomanager.stopAudioPreview();
      } else {
        if (!this.audioSource) {
          const audioSource = sessionStorage.getStorageSync(`meetings:${this.meetingStore.meetingId}:audioSource`);
          if (this.devices.find((x) => x.deviceId === audioSource)) {
            this.mediaStore.setAudioSource(audioSource);
          }
        }
        const devices = { audioSource: this.audioSource };
        this.$videomanager.restart({ devices, type: 'audioSource' });
      }
    },
    handleMuteVideo(args) {
      if (args.about === args.id) {
        this.usersStore.updateUser(args.about, { video_muted: !args.muted });
      }
      if (args.about === this.myId) {
        const videoSource = sessionStorage.getStorageSync(`meetings:${this.meetingStore.meetingId}:videoSource`);
        this.mediaStore.setVideoSource(videoSource);
        this.mediaStore.setVideoOn(args.muted);
      }
      if (args.muted) {
        this.$meetingmanager.unmute(args.about, { unmuteVideo: true });
      } else {
        this.$meetingmanager.mute(args.about, { muteVideo: true });
      }
    },
    updateVideoStreamingDevices(muted, args, selectedDeviceChanged) {
      const targetElementId = args.targetElementId || 'connectivityVideo';
      if (muted) {
        const targetElement = document.getElementById(targetElementId);
        this.$videomanager.stopPreview({ targetElement });
      } else {
        if (!this.videoSource) {
          const videoSource = sessionStorage.getStorageSync(`meetings:${this.meetingStore.meetingId}:videoSource`);
          if (this.devices.find((x) => x.deviceId === videoSource)) {
            this.mediaStore.setVideoSource(videoSource);
          }
        }
        if (selectedDeviceChanged) {
          const devices = { videoSource: this.videoSource };
          this.$videomanager.restart({ devices, type: 'videoSource' }).then(() => {
            const tile = this.$videomanager.tiles[this.$videomanager.localTileId];
            this.$videomanager.attach(tile, targetElementId);
          });
        }
      }
    },
    changeTheme(theme) {
      this.$API.updateSettings({ theme: theme }).then((r) => {
        this.featuresStore.updateUserSettings(r);
      });
    },
    changeSpace(space) {
      this.$API.updateSettings({ meetingSpace: space }).then((r) => {
        this.featuresStore.updateUserSettings(r);
      });
    },
    darkmode() {
      this.changeTheme('dark');
    },
    unDarkmode() {
      this.changeTheme('default');
    },
    toggleCustomGroups() {
      this.featuresStore.toggleCustomGroups();
    },
    toggleDialogueMaps() {
      this.featuresStore.toggleDialogueMaps();
    },
    toggleSentry() {
      this.featuresStore.toggleSentry();
    },
    toggleThemes() {
      this.featuresStore.toggleThemes();
    },
    toggleConnectivityIndicator() {
      this.featuresStore.toggleConnectivityIndicator();
    },

    toggleExtraSettings() {
      this.featuresStore.toggleExtraSettings();
    },
    toggleDebug() {
      if (this.featuresStore.debug) {
        this.messagesStore.addAlert('debugOff');
      } else {
        this.messagesStore.addAlert('debugOn');
      }
      this.featuresStore.toggleDebug();
    },
    showHostInfo() {
      this.messagesStore.addAlert('showHostGuide');
    },
    toggleDevConsole() {
      if (utils.isOpenedWithinElectronShell()) {
        ipcApi.send({ name: 'toggleDevConsole' });
      }
    },
    toggleShowTalking() {
      this.featuresStore.toggleShowTalking();
    },
    hideLoading() {
      var $loading = document.getElementById('loading');
      var $content = document.getElementById('loadingSpinnerContent');
      var $last = document.getElementById('spinnerLast');
      $last.addEventListener('animationiteration', () => {
        $content.classList.add('loaded');
        if (!this.loaded) {
          Velocity(
            $loading,
            {
              opacity: 0,
              scale: 2,
              display: 'none',
            },
            {
              display: 'none',
              duration: 600,
              delay: 600,
              easing: 'easeInQuint',
            },
          );
          Velocity(
            $content,
            {
              scale: [0, 1],
              display: 'none',
            },
            {
              display: 'none',
              delay: 300,
              duration: 400,
              easing: 'easeInQuint',
            },
          );
        }
      });
    },

    logout(returnUrl) {
      sessionStorage.clearStorageSync();
      cookies.remove('connect.sid');

      if (utils.isOpenedWithinElectronShell()) {
        this.$router.push('/app/welcome');
      } else {
        if (returnUrl) {
          window.location = `/lauth/logout?returnUrl=${encodeURI(returnUrl)}`;
        } else {
          window.location = '/lauth/logout';
        }
      }
    },
    usersReturned({ users }) {
      const ids = users.map((x) => x.id);

      if (ids.includes(this.myId)) {
        this.$ee.emit('bus:leaveGroup');
      }
    },
    usersUnreturned({ users }) {
      const ids = users.map((x) => x.id);

      if (ids.includes(this.myId)) {
        this.$ee.emit('bus:enterGroup');
      }
    },
    quickstart() {
      utils.genericFns
        .quickStartMeeting()
        .then((meeting) => {
          this.$router.push(`/quickstart/${meeting.id}`).catch(() => null);
        })
        .catch((err) => {
          errorReportingService.reportError(`quickStartMeeting - ${nM.toErrorMessage(err)}`);
          this.$ee.emit('api:alertAdd', {
            type: 'misc',
            class: 'error',
            duration: 'temporary',
            content: nM.toErrorMessage(err),
          });
        });
    },

    onDeviceAddedOrRemoved(refreshedDevices) {
      /* On device change, set recent connected device as active device*/
      if (refreshedDevices && refreshedDevices.length) {
        let recentUpdates = this.getUpdatedDeviceDetails(refreshedDevices);
        if (recentUpdates && recentUpdates.devices && recentUpdates.devices.length) {
          let { deviceIDs, updatedDevices } = this.getNewDeviceIDsDueToChangeInDeviceList(recentUpdates, refreshedDevices);
          this.updateDevicesInStoreAndStorages(deviceIDs, refreshedDevices);
          this.useUpdatedDevicesForMediaStreaming(updatedDevices);
        }
      }
    },

    getUpdatedDeviceDetails(refreshedDevices) {
      const existingDevicesById = this.devices.reduce((accumulator, d) => ({ ...accumulator, [d.deviceId]: d }), {});
      const recentlyAddedDevice = refreshedDevices.filter((d) => !existingDevicesById[d.deviceId]);
      if (recentlyAddedDevice.length) {
        return { devices: recentlyAddedDevice, added: true };
      }

      const refreshedDevicesById = refreshedDevices.reduce((accumulator, d) => ({ ...accumulator, [d.deviceId]: d }), {});
      const recentlyRemovedDevice = this.devices.filter((d) => !refreshedDevicesById[d.deviceId]);
      if (recentlyRemovedDevice.length) {
        return { devices: recentlyRemovedDevice, removed: true };
      }
    },

    getNewDeviceIDsDueToChangeInDeviceList(recentUpdates, refreshedDevices) {
      let deviceIDs = {
        audioSource: this.audioSource,
        audioOutputSource: this.audioOutputSource,
        videoSource: this.videoSource,
      };
      let updatedDevices = {
        audio: false,
        audioOutput: false,
        video: false,
      };

      recentUpdates.devices
        .filter(({ label }) => !label?.toLowerCase().includes('iphone'))
        .forEach((device) => {
          if (device.kind === 'audioInput') {
            updatedDevices.audio = true;
            if (recentUpdates.added) {
              deviceIDs.audioSource = device.deviceId;
            } else if (!refreshedDevices.some((device) => device.kind === 'audioInput' && device.deviceId === deviceIDs.audioSource)) {
              // device removed
              // last selected device isn't available in refreshed device
              // set first of its kind as default device
              deviceIDs.audioSource = (refreshedDevices.find((device) => device.kind === 'audioInput') || {}).deviceId;
            }
          } else if (device.kind === 'audioOutput') {
            updatedDevices.audioOutput = true;
            if (recentUpdates.added) {
              deviceIDs.audioOutputSource = device.deviceId;
            } else if (!refreshedDevices.some((device) => device.kind === 'audioOutput' && device.deviceId === deviceIDs.audioOutputSource)) {
              // device removed
              // last selected device isn't available in refreshed device
              // set first of its kind as default device
              deviceIDs.audioOutputSource = (refreshedDevices.find((device) => device.kind === 'audioOutput') || {}).deviceId;
            }
          } else if (device.kind === 'videoInput') {
            updatedDevices.video = true;
            if (recentUpdates.added) {
              deviceIDs.videoSource = device.deviceId;
            } else if (!refreshedDevices.some((device) => device.kind === 'videoInput' && device.deviceId === deviceIDs.videoSource)) {
              // device removed
              // last selected device isn't available in refreshed device
              // set first of its kind as default device
              deviceIDs.videoSource = (refreshedDevices.find((device) => device.kind === 'videoInput') || {}).deviceId;
            }
          }
        });

      return { deviceIDs, updatedDevices };
    },

    updateDevicesInStoreAndStorages(deviceIDs, allDevices) {
      //TODO: there are various objects and events related to audio and video sources
      // merge them into single place with only 1 source of truth
      const meetingId = this.$route.params.id;

      this.mediaStore.updateUserDevices({ ...deviceIDs });
      this.mediaStore.setAudioSource(deviceIDs.audioSource);
      this.mediaStore.setAudioOutputSource(deviceIDs.audioOutputSource);
      this.mediaStore.setVideoSource(deviceIDs.videoSource);
      this.mediaStore.updateDevices(allDevices);
      this.$videomanager.updateSettings({ devices: { audioSource: deviceIDs.audioSource }, type: 'audioSource' });
      this.$videomanager.updateSettings({ devices: { audioOutputSource: deviceIDs.audioOutputSource }, type: 'audioOutputSource' });
      this.$videomanager.updateSettings({ devices: { videoSource: deviceIDs.videoSource }, type: 'videoSource' });
      localStorage.setStorageSync('user:audioSource', deviceIDs.audioSource);
      localStorage.setStorageSync('user:audioOutputSource', deviceIDs.audioOutputSource);
      localStorage.setStorageSync('user:videoSource', deviceIDs.videoSource);
      if (meetingId) {
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioSource`, deviceIDs.audioSource);
        sessionStorage.setStorageSync(`meetings:${meetingId}:audioOutputSource`, deviceIDs.audioOutputSource);
        sessionStorage.setStorageSync(`meetings:${meetingId}:videoSource`, deviceIDs.videoSource);
      }
    },

    useUpdatedDevicesForMediaStreaming(updatedDevices) {
      if (this.pageName === 'connectivity') {
        this.renderDeviceUpdatesLikeConnectivityCheck(updatedDevices);
      } else {
        // meetingFrame, readiness check
        this.renderDeviceUpdatesLikeSplitPane(updatedDevices);
      }
    },

    renderDeviceUpdatesLikeConnectivityCheck(updatedDeviceType) {
      if (updatedDeviceType.audio) {
        this.$connectivity.updateSettings('audioSource', this.audioSource, this.audioOn);
      }
      if (updatedDeviceType.audioOutput) {
        this.$connectivity.updateSettings('audioOutputSource', this.audioOutputSource, this.audioOutputOn);
      }
      if (updatedDeviceType.video) {
        this.$connectivity.updateSettings('videoSource', this.videoSource, this.videoOn);
      }
    },

    renderDeviceUpdatesLikeSplitPane(updatedDeviceType) {
      if (updatedDeviceType.audio) {
        this.updateAudioStreamingDevices(!this.audioOn);
      }
      if (updatedDeviceType.audioOutput) {
        this.updateAudioStreamingDevices(!this.audioOutputOn);
      }
      if (updatedDeviceType.video) {
        this.updateVideoStreamingDevices(!this.videoOn, {}, true);
      }
    },
    settingsUpdated(settings) {
      this.featuresStore.updateUserSettings(settings);
    },
  },
};
</script>
<style lang="scss">
#sidebarSizeCheck {
  width: rem($sidebar-width);
  position: absolute;
  z-index: -1;
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.noAnimations * {
  transition: none !important;
  animation: none !important;
}
</style>
