<template>
  <div :class="containerClass">
    <h3 v-if="type === 'buttons'">Groups of:</h3>
    <div v-if="type === 'buttons'">
      <button
        v-for="button in buttons"
        :key="'button-' + button"
        :value="button"
        :class="button === groupSize ? 'current' : ''"
        :disabled="button === groupSize"
        @click="handleButton(button)"
      >
        {{ button }}
      </button>
    </div>

    <div v-if="type !== 'buttons'" class="select">
      <button title="Change groups" @click="toggleMenu">Split {{ usersHere.length }} participants into {{ groups?.length }} groups</button>
      <button class="toggle" @click="toggleMenu" :class="showingMenu ? 'showing' : ''" title="Change groups">
        <app-icon icon="down-arrow" />
      </button>
      <transition name="basic">
        <ul v-if="showingMenu" class="menu dropdown">
          <li v-for="(button, i) in buttons" :key="'select-' + i">
            <button :value="button" @click="handleButton(button)">{{ button }} groups</button>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import AppIcon from '@/components/AppIcon.vue';

export default {
  components: {
    AppIcon,
  },
  props: {
    type: {
      default: 'buttons',
    },
  },
  setup() {
    let usersStore = useUsersStore();
    return { usersStore };
  },
  data() {
    return {
      showingMenu: false,
    };
  },

  mounted() {
    let _this = this;

    this.$ee.on('nM:changeNextGroups', _this.sendGroups);
  },
  unmounted() {
    let _this = this;

    this.$ee.off('nM:changeNextGroups', _this.sendGroups);
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['usersHere', 'groupSize', 'groups']),
    containerClass() {
      return this.type === 'buttons' ? 'groupsButtons' : 'groupsSelect';
    },
    buttons() {
      let max = Math.floor(this.usersHere.length / 2);
      return _.range(2, max + 1);
    },
  },
  methods: {
    handleButton(n) {
      let groups = this.type === 'buttons' ? this.makeGroups(n) : this.makeRandomGroups(n);
      this.usersStore.updateGroups({ groups, button: true });
      this.showingMenu = false;
    },
    makeGroups(n) {
      let groups = _.chunk(_.pluck(this.usersHere, 'id'), n);
      if (this.usersHere.length % n !== 0) {
        let last = groups.pop();
        let penultimate = groups.pop();
        groups.push([...penultimate, ...last]);
      }
      return groups;
    },
    makeRandomGroups(n) {
      let baseGroups = _.shuffle(_.pluck(this.usersHere, 'id'));
      let group = 0;
      let groups = [];

      baseGroups.forEach((id) => {
        if (!groups[group]) {
          groups[group] = [];
        }

        groups[group].push(id);
        group++;
        if (group >= n) {
          group = 0;
        }
      });

      return groups;
    },
    sendGroups(groups) {
      this.$meetingmanager.changeNextGroups(groups);
    },
    toggleMenu() {
      this.showingMenu = !this.showingMenu;
    },
  },
};
</script>
<style lang="scss" scoped>
.groupsSelect {
  margin-top: 1em;
  .select {
    padding: 0 rem(34px) 0 rem(34px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .select > button {
    padding: 0;
    display: inline-block;
  }

  :deep(svg) {
    width: 1.5em;
  }

  ul.menu {
    z-index: 1;
    position: absolute;
    top: -0.6em;
    right: rem(24px);
    list-style: none;
    padding-right: 2.25em;
    margin-left: 1em;
    button {
      padding: 0.25em 0.5em;
      display: block;
      line-height: 1;
      @include triggered {
        color: var(--c__accent);
      }
    }
  }

  .toggle {
    z-index: 2;
    position: relative;
    transform-origin: 50% 50%;
    transition: transform 0.3s ease;
  }

  .toggle.showing {
    transform: rotateZ(180deg);
  }
}
</style>
