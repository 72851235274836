<template>
  <li :class="['status-' + meeting.status]">
    <p class="team" @click="go">
      {{ title }}
    </p>
    <p class="date" @click="go">
      {{ date }}
    </p>
    <p class="time" @click="go">
      {{ time }}
    </p>
    <p class="status" @click="go">
      {{ label }}
    </p>
    <div class="controls">
      <button v-if="finished" class="record btn-alt">View your meeting log</button>
      <button v-if="!finished" class="delete" @click="deleteMeeting">Delete</button>
      <button v-if="!finished && ['pending', 'ready', 'started'].includes(meeting.status)" class="start btn-alt" @click="go">Join Meeting</button>
    </div>
  </li>
</template>

<script>
import { nM } from '@/legacy';

export default {
  props: ['meeting'],
  computed: {
    title() {
      return this.meeting.name || 'no title';
    },
    type() {
      return this.meeting.step ? 'scheduled' : 'quickstart';
    },
    date() {
      return '';
    },
    time() {
      return '';
    },
    finished() {
      return this.meeting.status === 'finished';
    },
    label() {
      if (this.meeting.status === 'finished') {
        return 'finished';
      } else if (!['ready', 'started'].includes(this.meeting.status) && this.meeting.type === 'scheduled') {
        return 'scheduling';
      } else {
        return this.meeting.type;
      }
    },
  },
  methods: {
    go() {
      if (['ready', 'pending', 'started'].includes(this.meeting.status)) {
        this.$router.push('/meeting/' + this.meeting.id).catch(() => null);
      }
    },
    deleteMeeting() {
      this.$API.deleteMeeting(this.meeting.id).catch((err) => {
        this.$ee.emit('api:alertAdd', {
          type: 'misc',
          class: 'error',
          duration: 'temporary',
          content: nM.toErrorMessage(err),
        });
      });
    },
  },
};
</script>
