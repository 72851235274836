<template>
  <div class="microskillsMenu pane">
    <h2>Meeting Skills</h2>

    <phase-menu title="Begin Well">
      <phase-button phase="context" v-on:navigate="navigate" title="Set Context"> Set Context </phase-button>
      <phase-button phase="checkIn" v-on:navigate="navigate" title="Check-In"> Check-In </phase-button>
    </phase-menu>

    <phase-menu title="Meet Well">
      <phase-button phase="input" v-on:navigate="navigate" title="Present"> Give an Input </phase-button>
      <phase-button phase="decision" title="Decision Council" @navigate="navigate" @nope="noDecision">
        Make a Decision
        <template #tips>
          <bubble-tip
            name="tooManyForDecision"
            class="rightTail"
            :show="bubbleShowing === 'tooManyForDecision'"
            direction="above"
            @close="bubbleShowing = false"
          >
            Make a Decision phase is currently only available for meetings with 16 or less participants.
          </bubble-tip>
        </template>
      </phase-button>
      <phase-button phase="breakTime" title="Take a Break" @navigate="navigate"> Take a Break </phase-button>
      <phase-button phase="groups" title="Split into Groups" @navigate="navigate" @nope="noGroups">
        Break-out Groups
        <template #tips>
          <bubble-tip
            name="notEnoughForGroups"
            class="rightTail"
            :show="bubbleShowing === 'notEnoughForGroups'"
            direction="above"
            @close="bubbleShowing = false"
          >
            You can only access the Groups Micro-Skill if there are four or more meeting participants.
          </bubble-tip>
        </template>
      </phase-button>
      <phase-button v-if="featuresStore.flags['CREATIVE_DIALOGUE']" phase="dialogue" v-on:navigate="navigate" title="Creative Dialogue">Dialogue</phase-button>
      <phase-button phase="feedback" v-on:navigate="navigate" title="Feedback">Feedback</phase-button>
    </phase-menu>

    <phase-menu title="End Well">
      <phase-button phase="review" v-on:navigate="navigate" title="Review"> Review Moments </phase-button>
      <phase-button phase="checkOut" v-on:navigate="navigate" title="Check-Out"> Check-Out </phase-button>
    </phase-menu>
  </div>
</template>

<script>
import { useFeaturesStore } from '@/store/pinia/features';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import PhaseButton from '@/components/PhaseButton.vue';
import PhaseMenu from '@/components/PhaseMenu.vue';
import BubbleTip from '@/components/BubbleTip.vue';

export default {
  components: {
    PhaseButton,
    PhaseMenu,
    BubbleTip,
  },
  setup() {
    const featuresStore = useFeaturesStore();
    const sidebarStore = useSidebarStore();
    const usersStore = useUsersStore();
    return { featuresStore, sidebarStore, usersStore };
  },
  data() {
    return {
      bubbleShowing: false,
    };
  },
  computed: {},
  methods: {
    navigate(p) {
      this.sidebarStore.requestPane('skillBar', { phase: p, barType: 'info', screensharePlease: p === 'input', iStarted: true }, 'microskillMenu');
    },
    noGroups() {
      this.bubbleShowing = 'notEnoughForGroups';
    },
    noDecision() {
      this.bubbleShowing = 'tooManyForDecision';
    },
  },
};
</script>
<style lang="scss" scoped>
.pane.microskillsMenu {
  justify-content: center;
  align-items: center;
  padding: rem(75px) 0 0 0;
  @include respond-to-longer-than(800px) {
    padding: 0;
  }
  h2 {
    height: rem(34px);
    line-height: rem(34px);
    position: relative;
    text-align: center;
    @include type-size('medium');
    font-weight: bold;
    margin: auto 0 0 0;
  }
  .microskills-icon {
    height: rem(34px);
    width: rem(34px);
    position: absolute;
    top: 0;
    left: 0;
  }

  section {
    margin-top: rem(31px - 18px);
  }

  section:last-of-type {
    margin-bottom: auto;
  }
}
</style>
