<template>
  <div class="page introPages appIndexPage poster" data-page-name="appIndex">
    <div class="content">
      <title-block />

      <div class="actions">
        <router-link to="../join-with-link" class="btn btn-solid btn-accent btn-dark-blue-flood">Join Meeting</router-link>
        <a v-if="isOpenedWithinElectronShell" href="#" @click="tryLoginViaBrowser()" class="btn btn-border btn-mid-border">
          <span v-if="isLoginRequested"><app-loading-spinner /></span>
          <span v-else>Log In</span>
        </a>
        <a v-else :href="`/lauth/nowhere?returnUrl=${baseUrl}`" class="btn btn-border btn-mid-border">Log In</a>
        <div v-if="isLoginRequested" class="opening-now">
          <p>
            Opening your browser to log you in… <br />
            Having problems? <a href="#" @click="initLoginViaBrowser()">Try again.</a>
          </p>
        </div>
      </div>
    </div>
    <footer class="pageFooter">
      <p>&copy; <strong>nowhere</strong> group {{ year }}</p>
    </footer>
  </div>
</template>

<script>
import utils from '../resources/utils';
import TitleBlock from '@/components/TitleBlock.vue';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import { localStorageKeysMap } from '../shared/constants';

export default {
  components: {
    TitleBlock,
    AppLoadingSpinner,
  },
  props: ['pageName'],
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      isLoginRequested: false,
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  methods: {
    tryLoginViaBrowser() {
      if (this.isLoginRequested) {
        return;
      }
      this.isLoginRequested = true;
      this.initLoginViaBrowser();
      setTimeout(() => {
        this.isLoginRequested = false;
      }, 1000 * 60);
    },
    initLoginViaBrowser() {
      ipcApi.send({
        name: 'openExternal',
        url: `${window.location.origin}/lauth/nowhere?returnUrl=/conference/app/post-login&request-otp=1`,
        closeSelf: false,
      });
    },
  },
  created() {
    if (this.isOpenedWithinElectronShell) {
      ipcApi.send({ name: 'checkIsOnLatestVersion' });
    }
  },
  mounted() {
    // if (!utils.isOpenedWithinElectronShell()) {
    //   this.$router.push('/');
    //   return;
    // }
    this.pageStore.update({
      pageName: this.pageName,
    });
    const meetingId = utils.storage.ls.getItem(localStorageKeysMap.RELAUNCH_MEETING_ID);
    let redirectUrl = '/welcome';
    if (meetingId && this.accountStore.isLoggedIn) {
      this.$API
        .getMeeting(meetingId)
        .then((meeting) => {
          if (['ready', 'pending', 'started'].includes(meeting.status)) {
            utils.storage.ls.removeItem(localStorageKeysMap.RELAUNCH_MEETING_ID);
            redirectUrl = `/meeting/${meetingId}`;
          }
          this.$router.push(redirectUrl).catch(() => {});
        })
        .catch(() => {});
    } else if (this.accountStore.isLoggedIn) {
      this.$router.push(redirectUrl).catch(() => {});
    }
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    baseUrl() {
      return encodeURI(this.accountStore.baseUrl);
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  flex-direction: column;
}
.content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .opening-now {
    margin-top: rem(18px);
    font-size: rem(16px);
    font-weight: 300;
    text-align: center;
    a {
      font-weight: 500;
    }
  }
}

footer {
  flex: 0 0 auto;
}

.actions {
  margin-top: rem(50px);
  width: rem(280px);
  a {
    font-weight: 200;
  }
  a + a {
    margin-top: rem(12px);
  }
}
</style>
