<template>
  <div id="bixeTips" class="bixeTips">
    <div id="microskills" class="microskills">
      <transition-group name="slideInRight" tag="span">
        <top-alerts-alert v-for="alert in visibleAlerts" :alert="alert" :key="alert.tipId" @hide="hide(alert.tipId)" class="slideInRight-item" />
      </transition-group>
    </div>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { ref, computed, watch, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import TopAlertsAlert from '@/components/TopAlertsAlert';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { usePhaseStore } from '@/store/pinia/phase';
import { usePageStore } from '@/store/pinia/page';
import { useUsersStore } from '@/store/pinia/users';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;
const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const meetingStore = useMeetingStore();
const messagesStore = useMessagesStore();
const phaseStore = usePhaseStore();
const pageStore = usePageStore();
const usersStore = useUsersStore();

const waitingAlertsShown = ref(new Set());
const confirmModeratorRecommendationShown = ref(new Set());

const { alerts, alertsShowing: show } = storeToRefs(messagesStore);
const { initTitleCardShown } = storeToRefs(meetingStore);
const { me, iAmModerator, iAmSpeaking, iAmMuted, iAmSysMuted, firstShown: usersShown, inWaitingRoom, recommendedModerators } = storeToRefs(usersStore);
const { currentPhase, settingUp, isAnimating: phaseAnimating } = storeToRefs(phaseStore);
const { current: currentPage } = storeToRefs(pageStore);

const visibleAlerts = computed(() => {
  return show.value ? [...alerts.value].reverse() : [];
});

const animating = computed(() => {
  return !initTitleCardShown.value || !usersShown.value || phaseAnimating.value;
});

watch(animating, (doing) => {
  if (!doing) {
    if (!['setTime', 'chooseUser', 'init', 'checkIn', 'checkOut'].includes(currentPhase.value) && iAmSpeaking.value) {
      myTurnToSpeak();
    }
  } else {
    if (['checkIn', 'checkOut'].includes(currentPhase.value) && iAmMuted.value && !inWaitingRoom.value) {
      messagesStore
        .addAlert('willUnmute', { phase: currentPhase.value })
        .then(willUnmute)
        .catch(() => {});
    } else {
      messagesStore.removeAlert({ messageName: 'willUnmute' });
    }
  }
});
watch(iAmSpeaking, (nv) => {
  if (!animating.value) {
    if (nv) {
      if (iAmMuted.value) {
        myTurnToSpeak();
      }
    } else {
      messagesStore.removeAlert({ messageName: 'pleaseUnmute' });
    }
  }
});
watch(iAmMuted, (nv) => {
  if (!nv) {
    messagesStore.removeAlert({ messageName: 'pleaseUnmute' });
    messagesStore.removeAlert({ messageName: 'willUnmute' });
  }
});
watch(iAmSysMuted, (nv) => {
  if (!nv) {
    messagesStore.removeAlert({ messageName: 'tryPushToTalk' });
  }
});
watch(inWaitingRoom, handleJoinMeetingRequestNotification, { immediate: true });
watch(recommendedModerators, getConfirmationForJoiningAsModerator, { immediate: true });
watch(currentPhase, messagesStore.clearAlertsFromLayout);
watch(currentPage, messagesStore.clearAllAlerts);

function hide(tipId) {
  // messagesStore.hideAlerts();
  messagesStore.removeAlert({ tipId });
}

function myTurnToSpeak() {
  _.delay(() => {
    if (!settingUp.value && iAmMuted.value) {
      messagesStore.removeAlert({ messageName: 'willUnmute' });
      messagesStore
        .addAlert('pleaseUnmute', { phase: currentPhase.value })
        .then((value) => {
          if (value) {
            unmute();
          }
        })
        .catch(() => {});
    }
  }, 300);
}

function unmute() {
  $ee.emit('nM:userButton:mute', {
    id: me.value.id,
    about: me.value.id,
    iAmModerator: iAmModerator.value,
    muted: iAmMuted.value,
    sysmuted: iAmSysMuted.value,
    unmuteInstantly: true,
  });

  messagesStore.removeAlert({ messageName: 'pleaseUnmute' });
}

function willUnmute() {
  $ee.emit('nM:userButton:mute', {
    id: me.value.id,
    about: me.value.id,
    iAmModerator: iAmModerator.value,
    muted: iAmMuted.value,
    willUnmute: true,
    sysmuted: iAmSysMuted.value,
    unmuteInstantly: iAmSpeaking.value || false,
  });
  messagesStore.removeAlert({ messageName: 'willUnmute' });
}

function handleJoinMeetingRequestNotification(requests, oldRequests) {
  if (!iAmModerator.value) {
    return;
  }
  const allUsersInWaitingRoom = requests.map(({ id }) => id);
  requests.forEach(({ id, firstName, lastName }) => {
    if (!waitingAlertsShown.value.has(id)) {
      $ee.emit('sfx', 'knock');
      messagesStore
        .addAlert('userWaiting', { firstName, lastName, userId: id })
        .then(() => {
          $meetingmanager.approveJoiningRequest({ userId: id });
          messagesStore.removeAlert({ filter: (t) => t.messageName !== 'userWaiting' || t.userId !== id });
        })
        .catch(() => {
          console.log('Cancelled joining request notification for ', id);
        });
    }
  });

  waitingAlertsShown.value = new Set(allUsersInWaitingRoom);

  _.difference(oldRequests, requests).forEach(({ id }) => {
    messagesStore.removeAlert({ filter: (t) => t.messageName !== 'userWaiting' || t.userId !== id });
  });
}

function getConfirmationForJoiningAsModerator(recommendedModerators) {
  const userId = me.value.id;
  if (!recommendedModerators.includes(userId) || confirmModeratorRecommendationShown.value.has(userId)) {
    return;
  }

  confirmModeratorRecommendationShown.value.add(userId);
  messagesStore
    .addAlert('acceptPromotion')
    .then((doIt) => {
      messagesStore.removeAlert({ messageName: 'acceptPromotion' });
      if (doIt) {
        $meetingmanager.confirmJoiningAsMeetingController();
      } else {
        $meetingmanager.rejectMeetingControllerRecommendation();
      }
    })
    .catch(() => {
      $meetingmanager.rejectMeetingControllerRecommendation();
    })
    .finally(() => {
      confirmModeratorRecommendationShown.value.delete(userId);
    });
}
</script>
<style lang="scss" scoped>
.microskills {
  position: absolute;
  z-index: $z_alerts;
  transition: 300ms ease-in-out;
  left: rem(0px);
  top: rem($top-timer-mini-height);
  width: rem(323px);
  transform-origin: rem(24px) rem(-24px);

  .presentationSpace & {
    top: 0;
  }

  & > * + * {
    margin-top: 1em;
  }
}
</style>
