<template>
  <transition name="basic">
    <div v-if="message" class="avatarTip">
      <p>
        {{ message }}
      </p>
    </div>
  </transition>
</template>
<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
const frameStore = useFrameStore();
const usersStore = useUsersStore();

const props = defineProps(['user']);

const { hoveredControl } = storeToRefs(frameStore);
const { isMuted, isVideoMuted, myId } = storeToRefs(usersStore);

const messages = {
  mute: `Mute ${props.user.first_name}'s microphone`,
  muteVideo: `Mute ${props.user.first_name}'s camera`,
  stopScreenShare: `End ${props.user.first_name}'s screen share`,
  eject: `Remove ${props.user.first_name} from the meeting`,
  promote: `Assign meeting controls to ${props.user.first_name}`,
  demote: `Remove meeting controls from ${props.user.first_name}`,
};

const message = computed(() => {
  if (!hoveredControl.value || hoveredControl.value.type !== 'userControl' || hoveredControl.value.userId !== props.user.id) {
    return false;
  }

  if (props.user.id === myId.value) {
    return false;
  } else {
    if (hoveredControl.value.control === 'mute' && isMuted.value(props.user.id)) {
      return false;
    }
    if (hoveredControl.value.control === 'muteVideo' && isVideoMuted.value(props.user.id)) {
      return false;
    }
    return messages[hoveredControl.value.control];
  }
});
</script>
<style lang="scss" scoped>
.avatarTip {
  background: var(--c__text-80);
  border-radius: 50%;
  color: var(--c__bg);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: balance;
  container-type: size;
  p {
    margin: 1em;
    font-size: min(10cqw, 2rem);
    line-height: 1em;
  }
}
</style>
