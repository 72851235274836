<template>
  <div class="sidebarOverlay">
    <slot />
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebarOverlay {
  padding-top: rem(56px);
  padding-bottom: rem(112px);
  justify-content: center;
  flex-direction: column;
  display: flex;
  top: 0;
  position: absolute;
  width: rem($sidebar-width);
  bottom: 0;
  flex-direction: column;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: var(--c__bg);
}
:deep(h2) {
  font-weight: bold;
  text-align: center;
  @include type-size('medium');
}
</style>
