import { CONCENTRIC_RINGS, CIRCLE_PACK } from '@/resources/position-constants';
import { clockwise } from '@/resources/order';
import { circle, FULL_CIRCLE } from '@/resources/geometry';
import { C_PAD } from '@/resources/constants/frame-constants';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import _ from 'underscore';

export function useFishbowlLayout() {
  const frameStore = useFrameStore();
  const usersStore = useUsersStore();

  const { ringBasis, cX, cY } = storeToRefs(frameStore);
  const { usersVisibleList: users, speaking, owner } = storeToRefs(usersStore);

  function fishbowlLayout() {
    let orderedUsers = clockwise(users.value);

    let centerUser = speaking.value.id || owner.value.id;

    let main = _.without(orderedUsers, centerUser);

    let pos = {};
    let fullSpace;
    let vMod = 0;

    let rings = [];

    const ringMap = CONCENTRIC_RINGS[main.length];
    let maxTrack = ringBasis.value;
    let minTrack = ringBasis.value;

    if (main.length > 10) {
      let start = 0;
      ringMap.forEach((l, i) => {
        rings[i] = main.slice(start, start + l.users);
        start = start + l.users;
      });
    } else {
      rings[0] = main;
    }

    let sizeCheck = 8;
    if (rings.length === 1) {
      sizeCheck = sizeCheck > main.length ? sizeCheck : main.length;
      fullSpace = maxTrack / CIRCLE_PACK[sizeCheck];
    } else {
      sizeCheck = sizeCheck > rings[0].length ? sizeCheck : rings[0].length;
      minTrack = ringBasis.value * (0.95 - 0.11 * rings.length);
      fullSpace = (minTrack / CIRCLE_PACK[sizeCheck]) * (1 - 0.135 * (rings.length - 1));
    }

    if (ringMap[0].sizeMod) {
      fullSpace = fullSpace * ringMap[0].sizeMod;
    }

    const outerSpace = fullSpace - C_PAD;

    if (ringMap[0].vMod) {
      vMod = outerSpace * ringMap[0].vMod;
    }

    let rModSlice = rings.length >= 2 ? (maxTrack - minTrack) / 2 / (rings.length - 1) : 0;
    rings.forEach((ring, i) => {
      let rMod = rModSlice * i;

      //let rMod = ((ringBasis.value * 0.35) / rings.length) * i;
      let nudge = (FULL_CIRCLE / rings[0].length / 2) * (i % 2);

      let extraNudge = ringMap[i].turnMod ? ringMap[i].turnMod * FULL_CIRCLE : 0;

      let ringPos = circle({
        angleNudge: nudge + extraNudge,
        cX: cX.value,
        cY: cY.value + vMod,
        r: (minTrack - outerSpace) / 2 + rMod,
        count: ringMap[i].positions || _.max(ringMap, (r) => r.users).users,
        order: ring,
      });

      pos = { ...pos, ...ringPos };
    });

    main.forEach((id) => {
      pos[id].w = outerSpace;
    });

    pos[centerUser] = {
      left: cX.value,
      top: cY.value + vMod,
      w: (minTrack - fullSpace * 2) * 0.75,
    };

    return { pos, layoutName: 'fishbowl' };
  }

  return { fishbowlLayout };
}
