<template>
  <div />
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';

export default {
  setup() {
    const phaseStore = usePhaseStore();
    const meetingStore = useMeetingStore();
    const sidebarStore = useSidebarStore();
    return { phaseStore, meetingStore, sidebarStore };
  },
  data() {
    return {
      alarmSound: document.getElementById('ding'),
      alarmTimeout: null,
      volume: 0.6,
      dingedThisPhase: false,
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['iAmSpeaking', 'firstShown']),
    ...mapPiniaState(usePhaseStore, ['currentPhase', 'isAnimating']),
    hasDing() {
      return ['checkIn', 'checkOut'].includes(this.currentPhase);
    },
    ready() {
      return !this.sidebarStore.isAnimating && !this.phaseStore.isAnimating && this.meetingStore.initTitleCardShown && this.firstShown;
    },
    shouldDing() {
      return this.hasDing && this.iAmSpeaking && this.ready && !this.dingedThisPhase;
    },
  },
  watch: {
    shouldDing(nv, ov) {
      if (nv && !ov) {
        this.dingedThisPhase = true;
        this.$sfx('ding');
      }
    },
    currentPhase() {
      this.dingedThisPhase = false;
    },
  },
  mounted() {},
};
</script>
