<template>
  <section id="componentTest" class="page" data-page-name="componentTest">
    <div class="content">
      <header>
        <h1>Component Library</h1>
        <p>Testing and styling some of our various components</p>
      </header>
      <section>
        <h2>Tabs</h2>
        <p>Controls for tabbed content</p>
        <app-tabs :tabList="['one', 'two', 'three']">
          <template v-slot:item-1>
            <h2>Item One</h2>
            <p>I am some content</p>
          </template>
          <template v-slot:item-2>
            <h2>Item Two</h2>
            <p>I am some more content</p>
          </template>
          <template v-slot:item-3>
            <h2>Item Three</h2>
            <p>I am yet more content</p>
          </template>
        </app-tabs>

        <app-tabs :tabList="tabList">
          <template v-for="(tab, i) in tabsData" v-slot:[slotName(i)] :key="i">
            <ul class="views">
              <li v-for="(item, itemIndex) in tab.items" :key="`item-${itemIndex}`">
                <button @click="onItemClicked(item)">
                  <img class="thumb" :src="item.thumbnailSrc" />
                  <span class="label">{{ item.name }}</span>
                </button>
              </li>
            </ul>
          </template>
        </app-tabs>
      </section>
    </div>
  </section>
</template>

<script>
import AppTabs from '@/components/AppTabs.vue';
import _ from 'underscore';
import { usePageStore } from '@/store/pinia/page';
export default {
  components: {
    AppTabs,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  data() {
    return {
      tabsData: [
        {
          title: 'Screens',
          tabId: _.uniqueId('tab_'),
          contentId: _.uniqueId('content_'),
          items: [
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/220/184',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/100/284',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/300/300',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/500/800',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/100/184',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/1080/984',
            },
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/220/284',
            },
          ],
          isActive: true,
        },
        {
          title: 'Windows',
          tabId: _.uniqueId('tab_'),
          contentId: _.uniqueId('content_'),
          items: [
            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/220/184',
            },

            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/200/204',
            },

            {
              name: 'a kitten',
              thumbnailSrc: 'https://placekitten.com/220/184',
            },
          ],
          isActive: false,
        },
      ],
    };
  },
  computed: {
    tabList() {
      return this.tabsData.map((t) => t.title);
    },
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
  methods: {
    slotName(i) {
      return `item-${1 + i}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  padding: 8em 3em 2em 3em;
  max-width: 65em;
  margin: 0 auto;
}
header {
  text-align: center;
}
h1 {
  @include type-size(xx-large);
}
section > h2 {
  @include type-size(x-large);
}

:deep(.views) {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
  list-style: none;
  gap: 1em;
  button,
  img,
  .label {
    display: block;
    max-width: 100%;
  }

  img {
    border: solid 2px currentColor;
  }

  li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    @include triggered {
      color: var(--c__accent);
    }
  }
}
</style>
