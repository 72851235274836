let startPhaseMap = {
  breakTime: {
    params: ({ time }) => ({
      module_id: 'break',
      needs_edition: false,
      duration: time,
    }),
  },
  checkIn: {
    params: ({ speaking, time }) => ({
      module_id: 'checkin',
      needs_edition: false,
      start_with: speaking,
      default_time_per_turn: time,
    }),
  },
  checkOut: {
    params: ({ speaking, time }) => ({
      module_id: 'checkout',
      needs_edition: false,
      start_with: speaking,
      default_time_per_turn: time,
    }),
  },
  context: {
    params: ({ speaking, time }) => ({
      module_id: 'context',
      needs_edition: false,
      start_with: speaking,
      duration: time,
    }),
  },
  decision: {
    params: ({ speaking, time }) => ({
      module_id: 'decision',
      needs_edition: false,
      start_with: speaking,
      duration: time,
    }),
  },
  groups: {
    params: ({ time, groups }) => ({
      module_id: 'groups',
      needs_edition: true,
      duration: time,
      groups,
    }),
  },
  dialogue: {
    params: ({ speaking, time }) => ({
      module_id: 'dialogue',
      needs_edition: false,
      start_with: speaking,
      duration: time,
    }),
  },
  input: {
    params: ({ speaking, time }) => ({
      module_id: 'input',
      needs_edition: false,
      presentation_by: speaking,
      duration: time,
    }),
  },
  feedback: {
    params: ({ speaking, time }) => ({
      module_id: 'feedback',
      needs_edition: false,
      presentation_by: speaking,
      duration: time,
    }),
  },
  review: {
    params: ({ speaking, time }) => ({
      module_id: 'review',
      needs_edition: false,
      start_with: speaking,
      duration: time,
    }),
  },
};

export { startPhaseMap };
