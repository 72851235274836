import axios from 'axios';

export default {
  get: (url) => {
    return axios.request({
      method: 'GET',
      url: url,
    });
  },
  post: (url, data) => {
    return axios.request({
      method: 'POST',
      url: url,
      data: data || {},
    });
  },
  put: (url, data) => {
    return axios.request({
      method: 'PUT',
      url: url,
      data: data || {},
    });
  },
  patch: (url, data) => {
    return axios.request({
      method: 'PATCH',
      url: url,
      data: data || {},
    });
  },
  delete: (url) => {
    return axios.request({
      method: 'DELETE',
      url: url,
    });
  },
  download: (url) => {
    return axios.request({
      method: 'GET',
      url: url,
      responseType: 'blob',
    });
  },
};
