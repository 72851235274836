<template>
  <div class="page meeting-list-page">
    <search-meeting-list-form :isLoading="isLoading.form" @submit="searchMeetings($event)" :resetRequestedTs="requestResetForm.form" />
    <meeting-list
      :meetings="meetings"
      :error="error"
      @downloadMeetingsCSV="requestCSVDownload"
      @deleteMeetings="requestDeleteMeetings"
      :isLoading="isLoading.form"
      :agendaRequested="isLoading.agendaRequested"
      :deleteRequested="isLoading.deleteRequested"
      :resetRequestedTs="requestResetForm.meetings"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import SearchMeetingListForm from '@/components/adminReports/SearchMeetingsListForm';
import MeetingList from '@/components/adminReports/MeetingList';
import $API from '@/services/bixe-api.service';

const isLoading = ref({ form: false, deleteRequested: false, agendaRequested: false });
const requestResetForm = ref({ form: 0, meetings: 0 });
const meetings = ref([]);
const error = ref(false);

/** API requests to search, get complete agenda and delete meetings */
const searchMeetings = (searchObj) => {
  isLoading.value = { form: true, deleteRequested: false, agendaRequested: false };
  meetings.value = [];
  $API
    .getMeetingsForAdminReport({ filterObj: searchObj.model })
    .then((response) => {
      meetings.value = response.meetings;
      error.value = false;
    })
    .catch((err) => {
      console.log('error in getting meetings', err);
      error.value = err.error.message;
    })
    .finally(() => {
      isLoading.value = { form: false, deleteRequested: false, agendaRequested: false };
    });
};

const requestCSVDownload = () => {
  const selectedMeetings = meetings.value.filter((meeting) => meeting._selected);

  isLoading.value = { form: false, deleteRequested: false, agendaRequested: true };
  $API
    .getMeetingAgendaForMeetingIDs({ meetings: selectedMeetings })
    .then((resp) => {
      const formattedMeetings = formatMeetingsForCsvExport(resp.meetingStates);
      const meetingsAgendaAsCsv = convertMeetingJsonToCsv(formattedMeetings);
      exportToCsv('meetings.csv', meetingsAgendaAsCsv);
    })
    .catch((err) => {
      console.log('Error in generating agenda csv', err);
    })
    .finally(() => {
      isLoading.value = { form: false, deleteRequested: false, agendaRequested: false };
    });
};

const requestDeleteMeetings = () => {
  const selectedMeetings = meetings.value.filter((meeting) => meeting._selected);
  const haveConformation = confirm(`Are you sure you want to delete ${selectedMeetings.length} meeting${selectedMeetings.length > 1 ? 's' : ''}?`);
  if (!haveConformation) {
    return;
  }
  const meetingsToBeDeleted = selectedMeetings.map((meeting) => meeting.id);
  isLoading.value = { form: false, deleteRequested: true, agendaRequested: false };
  $API
    .deleteMultipleMeetings({ meetings: meetingsToBeDeleted })
    .then(() => {
      meetings.value = meetings.value.filter((meeting) => !meeting._selected);
    })
    .catch((err) => {
      console.log('error in deleting selected meeting', err);
    })
    .finally(() => {
      isLoading.value = { form: false, deleteRequested: false, agendaRequested: false };
    });
};

/** functions to mould meetings agenda for CSV file */
function desanitizeString(str) {
  if (!str) {
    return '';
  }
  return str.replace(/\n/g, '').replace(/,/g, '').replace(/"/g, '');
}

function flattenPhases(phases) {
  if (!phases || !phases.length) {
    return [];
  }
  let _phases = [];
  // flatten phases
  phases.forEach((phase) => {
    const isNestedPhase = phase.phases && phase.phases.length;
    _phases.push(...(isNestedPhase ? phase.phases : [phase]));
  });
  _phases = _phases.map((phase) => {
    if (!phase.participants || !phase.participants.length) {
      return { ...phase, participants: [{ alias: 'all' }] };
    }
    return phase;
  });
  return _phases;
}

function formatMeetingDesignerPhases(phases) {
  if (!phases || !phases.length) {
    return '';
  }
  let _phases = flattenPhases(phases);
  if (_phases && _phases.length) {
    const formattedPhases = _phases
      .map((phase) => {
        const participants = phase.participants.length
          ? `${phase.participants.map((participant) => participant.name || participant.alias || '').join(', ')}`
          : '';
        return `- ${phase.description || phase.name}\n\tDuration: ${phase.duration / 60} mins${participants.length ? `\n\tParticipants: ${participants}` : ''}`;
      })
      .join('\n');
    return `"${formattedPhases}"`;
  }
}

function formatFeedback(feedbacks) {
  const renderMultiRows = (arr) => {
    return arr.map((item) => `    * ${desanitizeString(item)}`).join('\n');
  };
  let formattedFeedbacks = {};
  feedbacks.forEach((feedback, index) => {
    const { good, tricky, different } = feedback.notes;
    formattedFeedbacks[`feedback-${index}`] = `"Presented By: ${feedback.startWith}\nTopic: ${feedback.topic}\n${
      good.length ? `\nGood:\n${renderMultiRows(good)}` : ''
    }${tricky.length ? `\nTricky:\n${renderMultiRows(tricky)}` : ''}${different.length ? `\nDifferent:\n${renderMultiRows(different)}` : ''}"`;
  });

  return formattedFeedbacks;
}

function formatMeetingsForCsvExport(meetings) {
  const propsWithMultipleValues = ['objectives', 'outcomes', 'outputs', 'participants', 'insights', 'questions', 'decisions', 'actions'];
  const propsNeedEncoding = ['title', 'purpose'];

  return meetings.map(({ meeting, users, events, feedbacks, phases, participants }) => {
    let meetingUsers = users && users.length ? users.map((user) => user.name) : [];
    let meetingParticipants = participants && participants.length ? participants.map((participant) => participant.name) : [];
    let meetingToExport = {
      id: meeting.id,
      account: meeting.account?.name,
      title: `${meeting.name}`.replace(/\n/g, '').replace(/,/g, '","'),
      startDate: meeting.startDate,
      startTime: meeting.startTime,
      endTime: meeting.endTime,
      timezone: meeting.timezoneWithOffset,
      purpose: meeting.purpose,
      objectives: meeting.objectives || [],
      outcomes: meeting.outcomes || [],
      outputs: meeting.outputs || [],
      participants: meeting.meta && meeting.meta.designer ? meetingParticipants : meetingUsers,
      insights: events.insights || [],
      questions: events.questions || [],
      decisions: events.decisions || [],
      actions: events.actions || [],
      phases: meeting.meta && meeting.meta.designer ? formatMeetingDesignerPhases(phases) : [],
      ...formatFeedback([...feedbacks] || []),
    };

    propsNeedEncoding.forEach((key) => {
      meetingToExport[key] = desanitizeString(meetingToExport[key]);
    });
    propsWithMultipleValues.forEach((key) => {
      meetingToExport[key] = meetingToExport[key].map((item) => `* ${desanitizeString(item)}`).join('\n');
      if (meetingToExport[key] && meetingToExport[key].length) {
        meetingToExport[key] = `"${meetingToExport[key]}"`;
      }
    });

    return meetingToExport;
  });
}

function convertMeetingJsonToCsv(meetings) {
  const longestMeeting = meetings.reduce((acc, meeting) => (Object.keys(meeting).length > Object.keys(acc).length ? meeting : acc), {});
  const headers = Object.keys(longestMeeting);
  const csv = meetings.map((meeting) => headers.map((header) => meeting[header] || '').join(','));

  return [headers.join(','), ...csv].join('\n');
}

function exportToCsv(filename, csvData) {
  var blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>
