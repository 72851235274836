<template>
  <div v-if="message" class="textLimit" :class="level">{{ message }}</div>
</template>

<script>
export default {
  props: {
    limit: Number,
    value: [String, InputEvent],
    warnLimit: Number,
  },
  computed: {
    message() {
      if (this.level === 'over') {
        return `Please enter less than ${this.limit} characters.`;
      } else if (this.level === 'warn' || this.level === 'limit') {
        return this.value.length + ' / ' + this.limit;
      } else {
        return false;
      }
    },
    level() {
      if (this.value && this.value.length === this.limit) {
        return 'limit';
      } else if (this.value && this.value.length > this.limit) {
        return 'over';
      } else if (this.value && this.value.length > this.warnLimit) {
        return 'warn';
      } else {
        return 'ok';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.textLimit {
  text-align: right;
  @include type-size('x-small');
  &.limit {
    color: var(--c__warn);
  }
  &.over {
    text-align: left;
    letter-spacing: -0.03em;
    color: var(--c__warn);
  }
}
</style>
