<template>
  <section class="page" :class="pageName" :data-page-name="pageName">
    <transition mode="out-in" name="basic">
      <div class="content outerContent">
        <slot name="content">
          <h2>No content?</h2>
        </slot>

        <slot name="actions"></slot>

        <div class="table" :class="table">&nbsp;</div>

        <footer>
          <slot name="footer">
            <p>
              Having problems with bixe?
              <a v-if="isOpenedWithinElectronShell" class="btn-borderless btn-link" href="#" @click="openExternal('https://www.bixe.ai/support.html')"
                >Contact Support</a
              >
              <a v-else class="btn-borderless btn-link" href="https://www.bixe.ai/support.html" target="_blank">Contact Support</a>
            </p>
          </slot>
        </footer>
      </div>
    </transition>
  </section>
</template>

<script>
import utils from '../resources/utils';

export default {
  props: {
    pageName: String,
    table: {
      type: String,
      default: 'foyer',
    },
  },
  data() {
    return {
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  methods: {
    openExternal(url) {
      ipcApi.send({ name: 'openExternal', url: url, closeSelf: true });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  display: flex;
  background: var(--c__bg-alt);
  text-align: center;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.outerContent {
  width: 100%;
}

:deep(h2) {
  @include type-size(large);
  margin: auto 0 rem(35px) 0;
}

:deep(.solo),
:deep(.btn-pair) {
  width: rem(300px);
  flex: 0 0 auto;
}

:deep(.solo + .solo),
:deep(.btn-pair + .solo) {
  margin-top: rem(13px);
}

footer {
  margin: 1em 0 auto 0;
  min-height: 3em;
  p {
    margin-bottom: 0;
  }
}

.table {
  width: 100%;
}

.table.end {
  margin: auto auto 0 auto;
  bottom: math.div(113, $design-height) * 100vh;
  bottom: calc(var(--vh, 1vh) * 100 * (113 / #{$design-height}));
  background-image: var(--bg__table-end);
  transition: top 500ms cubic-bezier(0.45, 0, 0.55, 1);
}

.table.foyer {
  background-image: var(--bg__table-foyer);
}
</style>
