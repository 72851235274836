import P from 'bluebird';
import { nM } from '@/legacy';

const data = [
  {
    deviceId: 'default',
    label: 'Default - Internal Microphone (Built-in)',
    kind: 'audioInput',
  },
  {
    deviceId: 'ba96c68d900e9262ddcdf268240ae568596b344597c331d2f61098a3a316720d',
    label: 'Internal Microphone (Built-in)',
    kind: 'audioInput',
  },
  {
    deviceId: '8356af766aa1d4f362252881e49adfbef6634a4690c3434f362fe6bbe6be0522',
    label: 'FaceTime HD Camera',
    kind: 'videoInput',
  },
];

export default class MockDeviceManager {
  addStreamingDevicesChangeListener(callback) {
    navigator.mediaDevices.ondevicechange = () => {
      callback(data);
    };
  }

  removeStreamingDevicesChangeListener(callback) {
    navigator.mediaDevices.ondevicechange = null;
    if (callback) callback();
  }

  getDevices(callback) {
    let promise;

    if (!callback) {
      promise = new P((resolve, reject) => {
        callback = nM.callbackPromise(resolve, reject);
      });
    }

    callback(null, data);

    return promise;
  }
}
