<template>
  <label class="toggle">
    <input
      :id="name"
      type="checkbox"
      v-bind="$attrs"
      class="visuallyHidden"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <div class="control">
      <span class="track"></span>
      <span class="nub"></span>
    </div>
  </label>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  inheritAttrs: false,
  data() {
    return {
      checked: false,
    };
  },
  computed: {
    vis() {
      return this.modelValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle {
  display: flex;
}
.control {
  width: rem(35px);
  height: rem(20px);
  position: relative;
  display: inline-block;
}

.track {
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 0.25em;
  top: rem(5px);
  height: rem(10px);
  background: var(--c__placeholder-alt);
  transition: background 0.3s;
}

.nub {
  position: absolute;
  height: rem(20px);
  width: rem(20px);
  border-radius: 50%;
  background: var(--c__bg);
  box-shadow: var(--shadow__controls);
  transition: transform 0.3s cubic-bezier(0.6, 0, 0, 1);
  @include dark-mode() {
    background: var(--c__text);
  }
}

input:checked + .control {
  .track {
    background: var(--c__accent);
  }
  .nub {
    transform: translateX(1em);
  }
}
</style>
