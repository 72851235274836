<template>
  <transition-group name="basic" tag="header">
    <app-icon class="bixeLogo" icon="circle-logo" v-if="show" key="logo" />
    <app-icon class="wordmark" icon="wordmark" v-if="show" key="wordmark" />
    <h1 class="topHeading visuallyHidden" v-if="show" key="h1">bixe</h1>
  </transition-group>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import AppIcon from '@/components/AppIcon.vue';
import utils from '../resources/utils';

export default {
  components: {
    AppIcon,
  },
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  data() {
    return {
      isNative: utils.isOpenedWithinElectronShell(),
    };
  },
  computed: {
    show() {
      return (
        !this.isNative &&
        this.pageStore.current.pageName &&
        !['init', 'meetingFrame', 'hello', 'allow', 'appIndex', 'loginPage', 'downloading', 'joinWithLink'].includes(this.pageStore.current.pageName)
      );
    },
  },
};
</script>
<style lang="scss">
.bixeLogo {
  padding: 0;
  border: none;
  display: block;
  top: rem(23px);
  left: rem(43px);
  height: rem(50px);
  width: rem(50px);
  :deep(svg) {
    .e,
    .w {
      color: var(--c__text);
    }
    .n,
    .s {
      color: var(--c__opening);
    }
    .nw,
    .se {
      color: var(--c__breakthrough);
    }
    .ne,
    .sw {
      color: var(--c__container);
    }
  }
}

.wordmark {
  height: rem(20px);
  width: rem(43px);
  color: var(--c__text);
  top: rem(36px);
  left: rem(104px);
  display: block;
}

.topHeading {
  font-size: rem(14px);
  font-weight: 300;
  line-height: math.div(17, 14);
  top: rem(35px);
  left: rem(103px);
}

.topBeta {
  font-size: rem(10px);
  top: rem(34px);
  left: rem(147px);
  line-height: math.div(12, 10);
}

.version {
  transition: opacity 0.3s;
  opacity: 0;
  color: #ccc;
}

.bixeLogo,
.topHeading,
.topBeta,
.wordmark {
  z-index: $z_under-panels;
  position: absolute;
}
</style>
