const emotes = {
  // emotes
  'correct-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z"></path>
    </svg>
  `,
  'incorrect-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z"></path>
    </svg>
  `,
  'yes-emote': `
    <svg version="1.1" viewBox="0 0 20 23">
      <path class="fill" fill-rule="evenodd" d="M19,14.85c0-1.17,0-1.09,0-2.26a6.22,6.22,0,0,0-.9-3c-1.15-2-4.11-2.45-5.54-4.26A7,7,0,0,1,11.12.92,4.07,4.07,0,0,1,11.2.07a2.45,2.45,0,0,0-1-.06,3,3,0,0,0-.9.23A2.36,2.36,0,0,0,8.13,1.67,7.56,7.56,0,0,0,8.28,6.3,8.32,8.32,0,0,0,9,7.93L8,8a1.92,1.92,0,0,1,.47,1.28,2.24,2.24,0,0,1-1.28,2,2.35,2.35,0,0,1,0,4.1,2.16,2.16,0,0,1,1.18,1.92A2,2,0,0,1,7.5,19h.6a2,2,0,0,1,2,2,1.72,1.72,0,0,1-.4,1.13c1.61-.32,3.21-.62,4.83-.9s3-.58,3.83-2a4.72,4.72,0,0,0,.54-1.8,22.52,22.52,0,0,0,0-2.54"/>
      <path class="fill" fill-rule="evenodd" d="M6.86,8.14A1.73,1.73,0,0,0,6,7.92H6L2.06,7.74a1.51,1.51,0,1,0,0,3H6A1.51,1.51,0,0,0,7.49,9.25a1.24,1.24,0,0,0-.64-1.11"/>
      <path class="fill" fill-rule="evenodd" d="M5.78,11.7H1.62a1.62,1.62,0,1,0,0,3.24h.3l.25,0H6a1.62,1.62,0,0,0-.21-3.23"/>
      <path class="fill" fill-rule="evenodd" d="M6,15.88h-4a1.41,1.41,0,0,0,.21,2.8H2.7l3.23-.08a1.33,1.33,0,0,0,1.41-1.32A1.41,1.41,0,0,0,6,15.88"/>
      <path class="fill" fill-rule="evenodd" d="M7.84,19.69H4.42a1.3,1.3,0,0,0,0,2.59l.41,0,3-.16h0A1.16,1.16,0,0,0,9.14,21a1.3,1.3,0,0,0-1.3-1.3"/>
    </svg>
  `,
  'no-emote': `
    <svg version="1.1" viewBox="0 0 20 23">
        <path class="fill" fill-rule="evenodd" d="M19.4,5.1a4.78,4.78,0,0,0-.53-1.8c-1.11-2-3.81-2-5.78-2.41C12.23.73,7.45.18,5.91.08L5.09,0,4.62,0a1.67,1.67,0,0,0-1.5,1.8,2.15,2.15,0,0,0,.08.57,1.74,1.74,0,0,0,.77,1l-1,0H2.43A1.81,1.81,0,0,0,.81,5.3,1.93,1.93,0,0,0,1.94,7.14H1.82A1.91,1.91,0,0,0,0,9.12a1.91,1.91,0,0,0,1.82,2h0A1.89,1.89,0,0,0,.56,12.94a1.82,1.82,0,0,0,1.71,1.91L5,14.71H5c1.23-.06,3.74-.09,4.47-.1a8.17,8.17,0,0,0-.74,1.58,7.57,7.57,0,0,0-.15,4.62,2.36,2.36,0,0,0,1.19,1.42,3,3,0,0,0,.9.23,2.51,2.51,0,0,0,1-.06,3.93,3.93,0,0,1-.08-.84A7,7,0,0,1,13,17.17c1.43-1.81,4.39-2.21,5.54-4.26a6.26,6.26,0,0,0,.9-3c0-1.18,0-1.09,0-2.26a22.54,22.54,0,0,0,0-2.54"/>
    </svg>
  `,
  'what-emote': `
    <svg version="1.1" viewBox="0 0 12 20">
      <path class="fill" fill-rule="evenodd" d="M6.1,11.1v3.3H3.7L3.4,9.8C6.6,8.8,8.6,6.9,8.6,5c0-1.6-1.1-2.4-3-2.4c-1.4,0-3,0.4-4.6,1.1l-1-2
        C1.7,0.7,4.3,0,6.2,0c3.2,0,5.3,1.8,5.3,4.5C11.5,7.2,9.6,9.5,6.1,11.1z M5,19.8c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8
        c0.9,0,1.7,0.8,1.7,1.8C6.7,19.1,6,19.8,5,19.8z"/>
    </svg>
  `,
  'smile-emote': `
  <svg version="1.1"viewBox="0 0 16 16">
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM11 4c0.552 0 1 0.448 1 1s-0.448 1-1 1-1-0.448-1-1 0.448-1 1-1zM5 4c0.552 0 1 0.448 1 1s-0.448 1-1 1-1-0.448-1-1 0.448-1 1-1zM8 13c-1.82 0-3.413-0.973-4.288-2.427l1.286-0.772c0.612 1.018 1.727 1.699 3.002 1.699s2.389-0.681 3.002-1.699l1.286 0.772c-0.874 1.454-2.467 2.427-4.288 2.427z"></path>
  </svg>
  `,
  'gasp-emote': `
  <svg version="1.1" viewBox="0 0 16 16">
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM9 13h-2v-2h2v2zM9 9h-2v-6h2v6z"></path>
  </svg>
  `,
  'eek-emote': `
  <svg version="1.1" viewBox="0 0 4 21">
    <path class="fill" class="fill" d="M1.8,20.2c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C3.7,19.4,2.8,20.2,1.8,20.2z
       M3.1,14.5H0.5L0.2,0h3.1L3.1,14.5z"/>
  </svg>
  `,
  'roll-emote': `
  <svg version="1.1" viewBox="0 0 16 16">
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zM11 3.688c0.999 0 1.813 0.813 1.813 1.813 0 0.1-0.009 0.201-0.025 0.302-0.025 0.151-0.156 0.261-0.308 0.261s-0.283-0.11-0.308-0.261c-0.096-0.573-0.589-0.833-1.171-0.833s-1.074 0.26-1.171 0.833c-0.025 0.151-0.156 0.261-0.308 0.261-0 0 0 0-0 0-0.153 0-0.283-0.11-0.308-0.261-0.017-0.101-0.025-0.202-0.025-0.302 0-0.999 0.813-1.813 1.813-1.813zM5 3.688c0.999 0 1.813 0.813 1.813 1.813 0 0.1-0.009 0.201-0.025 0.302-0.025 0.151-0.156 0.261-0.308 0.261s-0.283-0.11-0.308-0.261c-0.096-0.573-0.589-0.833-1.171-0.833s-1.074 0.26-1.171 0.833c-0.025 0.151-0.156 0.261-0.308 0.261 0 0 0 0 0 0-0.153 0-0.283-0.11-0.308-0.261-0.017-0.101-0.025-0.202-0.025-0.302 0-0.999 0.813-1.813 1.813-1.813zM3 9h3v3.873c-1.72-0.447-3-2.018-3-3.873zM7 13v-4h2v4h-2zM10 12.873v-3.873h3c0 1.855-1.28 3.426-3 3.873z"></path>
  </svg>
  `,
  'grin-emote': `
  <svg version="1.1" viewBox="0 0 16 16">
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zM11 3.688c0.999 0 1.813 0.813 1.813 1.813 0 0.1-0.009 0.201-0.025 0.302-0.025 0.151-0.156 0.261-0.308 0.261s-0.283-0.11-0.308-0.261c-0.096-0.573-0.589-0.833-1.171-0.833s-1.074 0.26-1.171 0.833c-0.025 0.151-0.156 0.261-0.308 0.261-0 0 0 0-0 0-0.153 0-0.283-0.11-0.308-0.261-0.017-0.101-0.025-0.202-0.025-0.302 0-0.999 0.813-1.813 1.813-1.813zM5 3.688c0.999 0 1.813 0.813 1.813 1.813 0 0.1-0.009 0.201-0.025 0.302-0.025 0.151-0.156 0.261-0.308 0.261s-0.283-0.11-0.308-0.261c-0.096-0.573-0.589-0.833-1.171-0.833s-1.074 0.26-1.171 0.833c-0.025 0.151-0.156 0.261-0.308 0.261 0 0 0 0 0 0-0.153 0-0.283-0.11-0.308-0.261-0.017-0.101-0.025-0.202-0.025-0.302 0-0.999 0.813-1.813 1.813-1.813zM3 9h3v3.873c-1.72-0.447-3-2.018-3-3.873zM7 13v-4h2v4h-2zM10 12.873v-3.873h3c0 1.855-1.28 3.426-3 3.873z"></path>
  </svg>
  `,
  'grr-emote': `
  <svg version="1.1" viewBox="0 0 16 16">
    <path class="fill" d="M4 10.25v1.5c0 0.136 0.114 0.25 0.25 0.25h1.75v-2h-1.75c-0.136 0-0.25 0.114-0.25 0.25z"></path>
    <path class="fill" d="M7 10h2v2h-2v-2z"></path>
    <path class="fill" d="M11.75 10h-1.75v2h1.75c0.136 0 0.25-0.114 0.25-0.25v-1.5c0-0.136-0.114-0.25-0.25-0.25z"></path>
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM9.002 5.946c0.032-0.741 0.706-1.234 1.275-1.518 0.543-0.271 1.080-0.407 1.102-0.413 0.268-0.067 0.539 0.096 0.606 0.364s-0.096 0.539-0.364 0.606c-0.275 0.070-0.602 0.189-0.89 0.334 0.166 0.179 0.268 0.418 0.268 0.681 0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.018 0.001-0.036 0.002-0.054zM4.015 4.379c0.067-0.268 0.338-0.431 0.606-0.364 0.023 0.006 0.559 0.141 1.102 0.413 0.568 0.284 1.243 0.776 1.275 1.518 0.001 0.018 0.002 0.036 0.002 0.054 0 0.552-0.448 1-1 1s-1-0.448-1-1c0-0.263 0.102-0.503 0.268-0.681-0.288-0.144-0.614-0.264-0.89-0.334-0.268-0.067-0.431-0.338-0.364-0.606zM13 11.75c0 0.689-0.561 1.25-1.25 1.25h-7.5c-0.689 0-1.25-0.561-1.25-1.25v-1.5c0-0.689 0.561-1.25 1.25-1.25h7.5c0.689 0 1.25 0.561 1.25 1.25v1.5z"></path>
  </svg>
  `,
  'sad-emote': `
  <svg version="1.1" viewBox="0 0 16 16">
    <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM11 4c0.552 0 1 0.448 1 1s-0.448 1-1 1-1-0.448-1-1 0.448-1 1-1zM5 4c0.552 0 1 0.448 1 1s-0.448 1-1 1-1-0.448-1-1 0.448-1 1-1zM11.002 12.199c-0.612-1.018-1.727-1.699-3.002-1.699s-2.389 0.681-3.002 1.699l-1.286-0.772c0.874-1.454 2.467-2.427 4.288-2.427s3.414 0.973 4.288 2.427l-1.286 0.772z"></path>
  </svg>
  `,
  'tongue-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM5 4c0.552 0 1 0.448 1 1s-0.448 1-1 1-1-0.448-1-1 0.448-1 1-1zM12 10h-1v1.5c0 0.828-0.672 1.5-1.5 1.5s-1.5-0.672-1.5-1.5v-1.5h-4v-1h8v1zM11 6c-0.552 0-1-0.448-1-1s0.448-1 1-1 1 0.448 1 1-0.448 1-1 1z"></path>
    </svg>
  `,
  'uhh-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M6 6.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5 0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5z"></path>
      <path class="fill" d="M11 6.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5 0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5z"></path>
      <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM4 6.5c0-0.828 0.672-1.5 1.5-1.5s1.5 0.672 1.5 1.5-0.672 1.5-1.5 1.5-1.5-0.672-1.5-1.5zM10 12h-4v-1h4v1zM10.5 8c-0.828 0-1.5-0.672-1.5-1.5s0.672-1.5 1.5-1.5 1.5 0.672 1.5 1.5-0.672 1.5-1.5 1.5z"></path>
    </svg>
  `,
  'whoa-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM5 7c-0.552 0-1-0.672-1-1.5s0.448-1.5 1-1.5 1 0.672 1 1.5-0.448 1.5-1 1.5zM8 13c-1.105 0-2-0.895-2-2s0.895-2 2-2c1.105 0 2 0.895 2 2s-0.895 2-2 2zM11 7c-0.552 0-1-0.672-1-1.5s0.448-1.5 1-1.5 1 0.672 1 1.5-0.448 1.5-1 1.5z"></path>
    </svg>
  `,
  'wave-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M15.33,7.7c-.21-1.77-1-5-1-5S14,1.92,12.78,2.2c-1,.23-.84,1.4-.84,1.4l.36,2.3a2.85,2.85,0,0,1-.84-.37L11,5.12,9.82,4l-.15-.15L6,.34H6A1.24,1.24,0,0,0,5.14,0,1.18,1.18,0,0,0,3.93,1.15a1.12,1.12,0,0,0,.33.79h0L8.8,6.27a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0l-5.29-5h0a1.31,1.31,0,0,0-.91-.36A1.26,1.26,0,0,0,1.1,2.31a1.19,1.19,0,0,0,.37.86h0L7,8.4a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L2,4.13a1.17,1.17,0,0,0-.83-.34A1.13,1.13,0,0,0,.06,4.89a1.07,1.07,0,0,0,.34.78h0l.74.7,4.61,4.39a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L1.74,7.36h0A1,1,0,0,0,1,7.07,1,1,0,0,0,0,8a.92.92,0,0,0,.22.57h0l4.42,4.23.76.72,1.81,1.72c.87.83,4.6,1.37,6.88-.84a5.54,5.54,0,0,0,1.82-2.75C16.25,10.14,15.54,9.47,15.33,7.7Z">
    </svg>
  `,
  'thoughtful-emote': `
  <svg version="1.1" viewBox="0 0 700 700">
    <path class="fill" d="m608.95 267.17c-54.414-77.715-116.59-118.51-132.13-157.38-15.543-38.871-16.48-71.402-20.895-93.258-4.3867-21.855-33.52-31.059-56.82 31.086-23.301 62.172 15.543 155.43 15.543 155.43-67.621 18.355-218.87-11.891-321.2-2.9414-62.781 5.4766-51.93 64.555-3.043 70.461 78.906 9.5859 161.92 24.52 201.2 28.93-31.211-0.55859-53.805 12.121-57.961 15.469-20.613 16.582-29.969 67.016 13.285 79.133-26.648 22.793-32.355 67.066 17.824 79.438-17.621 13.945-29.059 40.469 10.98 64.633 38.617 23.453 148.33 26.496 201.12 16.203 175.91-34.305 213.52-162.71 132.08-287.25z" fill-rule="evenodd"/>
</svg>
  `, // From https://thenounproject.com/icon/hand-2491523/
  hand: `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M15.33,7.7c-.21-1.77-1-5-1-5S14,1.92,12.78,2.2c-1,.23-.84,1.4-.84,1.4l.36,2.3a2.85,2.85,0,0,1-.84-.37L11,5.12,9.82,4l-.15-.15L6,.34H6A1.24,1.24,0,0,0,5.14,0,1.18,1.18,0,0,0,3.93,1.15a1.12,1.12,0,0,0,.33.79h0L8.8,6.27a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0l-5.29-5h0a1.31,1.31,0,0,0-.91-.36A1.26,1.26,0,0,0,1.1,2.31a1.19,1.19,0,0,0,.37.86h0L7,8.4a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L2,4.13a1.17,1.17,0,0,0-.83-.34A1.13,1.13,0,0,0,.06,4.89a1.07,1.07,0,0,0,.34.78h0l.74.7,4.61,4.39a.14.14,0,0,1,0,.21.16.16,0,0,1-.22,0L1.74,7.36h0A1,1,0,0,0,1,7.07,1,1,0,0,0,0,8a.92.92,0,0,0,.22.57h0l4.42,4.23.76.72,1.81,1.72c.87.83,4.6,1.37,6.88-.84a5.54,5.54,0,0,0,1.82-2.75C16.25,10.14,15.54,9.47,15.33,7.7Z">
    </svg>
  `,
  'heart-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M11.75 1c-1.624 0-3.034 0.911-3.75 2.249-0.716-1.338-2.126-2.249-3.75-2.249-2.347 0-4.25 1.903-4.25 4.25 0 5.75 8 9.75 8 9.75s8-4 8-9.75c0-2.347-1.903-4.25-4.25-4.25z"></path>
    </svg>
  `,
  'star-emote': `
    <svg version="1.1" viewBox="0 0 16 16">
      <path class="fill" d="M16 6.204l-5.528-0.803-2.472-5.009-2.472 5.009-5.528 0.803 4 3.899-0.944 5.505 4.944-2.599 4.944 2.599-0.944-5.505 4-3.899z"></path>
    </svg>
  `,
  'confetti-emote': `
    <svg version="1.1" viewBox="0 0 33 33">
      <defs><style>.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{fill:none;stroke-linejoin:round;}.cls-1{stroke:#b2d235;}.cls-1,.cls-2,.cls-6{stroke-width:1.25px;}.cls-2{stroke:#ff5a50;}.cls-3{stroke:#faaf3c;}.cls-3,.cls-5{stroke-width:1.25px;}.cls-4,.cls-6{stroke:#00a2ff;}.cls-4{stroke-width:1.25px;}.cls-5{stroke:#f5d010;}</style></defs>
      <polygon class="green fill" stroke-linejoin="round" points="24.77 15.74 29.57 13.6 31.28 17.44 26.48 19.57 24.77 15.74"/>
      <polygon class="red fill" stroke-linejoin="round" points="24.77 26.24 29.57 24.1 31.28 27.94 26.48 30.07 24.77 26.24"/>
      <polygon class="orange fill" stroke-linejoin="round" points="14.24 18.36 17.55 14.28 20.81 16.92 17.51 21 14.24 18.36"/>
      <polygon class="blue fill" stroke-linejoin="round" points="16.54 5.32 19 0.68 22.71 2.65 20.25 7.29 16.54 5.32"/>
      <polygon class="yellow fill" stroke-linejoin="round" points="3.74 28.86 7.05 24.77 10.31 27.42 7.01 31.5 3.74 28.86"/>
      <polygon class="green fill" stroke-linejoin="round" points="6.92 11.54 11.72 9.4 13.43 13.24 8.63 15.37 6.92 11.54"/>
      <polygon class="red fill" stroke-linejoin="round" points="0.63 18.89 5.42 16.75 7.13 20.59 2.33 22.72 0.63 18.89"/>
      <polygon class="orange fill" stroke-linejoin="round" points="2.69 4.71 6 0.63 9.26 3.27 5.96 7.35 2.69 4.71"/>
      <polygon class="blue fill" stroke-linejoin="round" points="13.22 28.34 18.02 26.2 19.73 30.04 14.93 32.17 13.22 28.34"/>
      <polygon class="yellow fill" stroke-linejoin="round" points="25.79 6.8 29.1 2.73 32.36 5.37 29.06 9.45 25.79 6.8"/></svg>
  `,
};

export { emotes };
