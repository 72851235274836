<template>
  <transition name="basic">
    <div v-if="!iAmSpeaking" class="textHolder">
      <div class="text">
        <div v-if="field">{{ field }}</div>
        <div v-else class="placeholderText"><slot /></div>
      </div>
    </div>
  </transition>

  <transition name="basic">
    <form v-if="iAmSpeaking" class="proposalForm" @submit.prevent="finish">
      <app-auto-textarea
        ref="topicField"
        v-model="field"
        class="field"
        :placeholder="placeholder"
        size="1"
        maxlength="255"
        focus="true"
        @focus="focussing = true"
        @blur="focussing = true"
        @keyup="throttledSend"
        @enter="finish"
      />
      <button type="submit" class="go">Done</button>
    </form>
  </transition>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import AppAutoTextarea from '@/components/AppAutoTextarea';
import _ from 'underscore';

export default {
  props: {
    modelField: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  emits: ['send', 'finish'],
  components: {
    AppAutoTextarea,
  },
  data() {
    return {
      throttledSend: _.debounce(this.send, 800),
      field: '',
      focussing: false,
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['iAmSpeaking']),
  },
  watch: {
    modelField: {
      immediate: true,
      handler(nv) {
        if (!nv || !this.focussing) {
          this.field = nv;
        }
      },
    },
  },

  methods: {
    send() {
      this.$emit('send', this.field);
    },
    finish() {
      this.$emit('finish', this.field);
    },
  },
};
</script>

<style lang="scss" scoped>
@at-root {
  @keyframes ellipsis {
    to {
      width: 0.9em;
    }
  }
}
.textHolder {
  max-height: 7.2em;
  overflow: auto;
}

:deep(textarea),
.clone,
.text,
.placeholderText {
  font-weight: 200;
  @include type-size(medium);
}

.placeholderText {
  z-index: 2;
  cursor: default;
}

form {
  display: flex;
}

.autoTextarea {
  flex: 1 1 rem(400px);
  margin: rem(3px) rem(17px) 0;
  position: relative;
  max-height: 7.2em;
  overflow: scroll;
}

:deep(textarea) {
  border: none;
  margin: 0;
  min-height: rem(40px);
  height: rem(40px);
  &::placeholder {
    color: $c_grey;
  }
}

form button {
  flex: 0 0 rem(72px);
  width: rem(72px);
  margin: 0;
  padding: 0;
  height: rem(50px);
  border: none;
  font-weight: 400;
  color: var(--c__text);
  font-size: rem(18px);
  &:hover,
  &:active {
    background: none;
    color: var(--c__accent);
  }
}

.text {
  padding: rem(12px) rem(17px) rem(11px) rem(17px);
  min-height: rem(50px - 15px - 12px);
}

.placeholderText:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; /* ascii code for the ellipsis character */
  width: 0px;
}
</style>
