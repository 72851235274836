import Sfx from '@/services/sfx.service';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;
    Sfx.init();
    app.config.globalProperties.$sfx = Sfx.play;
  },
};
