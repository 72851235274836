import { CIRCLE_PACK } from '@/resources/position-constants';
import { circle, QUARTER_TURN } from '@/resources/geometry';
import { C_PAD } from '@/resources/constants/frame-constants';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';

export function useRingLayout() {
  const frameStore = useFrameStore();
  const usersStore = useUsersStore();
  const phaseStore = usePhaseStore();

  const { ringBasis, cX, cY } = storeToRefs(frameStore);
  const { usersVisibleList: users } = storeToRefs(usersStore);
  const { currentPhase } = storeToRefs(phaseStore);

  function ringLayout() {
    const fullSpace = ringBasis.value / CIRCLE_PACK[users.value.length];
    const outerSpace = fullSpace - C_PAD;

    let pos = {};
    let edgePoints = {};

    if (users.value.length === 1) {
      // if we only have one user, stick them in the middle
      pos[users.value[0]] = {
        left: cX.value,
        top: cY.value,
        w: outerSpace,
      };
    } else {
      pos = circle({
        angleNudge: users.value.length > 2 ? 0 : QUARTER_TURN * 3,
        cX: cX.value,
        cY: cY.value,
        r: (ringBasis.value - outerSpace) / 2,
        count: users.value.length,
        order: users.value,
      });

      if (currentPhase.value === 'dialogue') {
        edgePoints = circle({
          angleNudge: users.value.length > 2 ? 0 : QUARTER_TURN * 3,
          cX: cX.value,
          cY: cY.value,
          r: ringBasis.value / 2 - outerSpace,
          count: users.value.length,
          order: users.value,
        });
      }

      // nudge them a bit when there is 3 or 5 for more visual centering
      let extraTop = 0;
      if (users.value.length === 3) {
        extraTop = outerSpace * 0.15;
      }
      if (users.value.length === 5) {
        extraTop = outerSpace * 0.07;
      }

      users.value.forEach((id) => {
        pos[id].w = outerSpace;
        pos[id].top = pos[id].top + extraTop;
        if (edgePoints[id]) {
          edgePoints[id].top = edgePoints[id].top + extraTop;
        }
      });
    }
    return { pos, layoutName: 'ring', extras: { edgePoints } };
  }
  return { ringLayout };
}
