<template>
  <div class="templateBuilder">
    <div class="typeSelect">
      <button @click="type = 'columns'">Columns</button>
      <button @click="type = 'rows'">Rows</button>
      <button @click="type = 'grid'">Grid</button>
    </div>
    <div class="basics">
      <div class="columns" v-if="type === 'columns'">Columns!</div>
      <div class="columns" v-if="type === 'rows'">Rows!</div>
      <div class="columns" v-if="type === 'grid'">Grid!</div>
    </div>
    <ol class="areas">
      <li v-for="id in templateAreas" :key="id">
        <input @keyup="title($event, id)" :value="template[id].title" />
        <div class="colors">
          <button
            class="colorButton"
            v-for="color in colors"
            :key="color"
            :class="[color, template[id].color === color ? 'selected' : '']"
            @click="selectColor(id, color)"
          >
            <span class="visuallyHidden">{{ color }}</span>
          </button>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useMappingStore } from '@/store/pinia/mapping';

export default {
  name: 'MappingTemplateBuilder',
  setup() {
    const mappingStore = useMappingStore();
    return { mappingStore };
  },
  data() {
    return {
      type: 'columns',
    };
  },
  computed: {
    ...mapPiniaState(useMappingStore, ['items', 'colors', 'template', 'templateAreas']),
  },
  watch: {},
  methods: {
    title($e, id) {
      this.mappingStore.updateTemplateItem({
        id,
        title: $e.target.value,
      });
    },
    selectColor(id, color) {
      this.mappingStore.updateTemplateItem({
        id,
        color,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.areas {
  li {
    display: flex;
  }
  li + li {
    margin-top: 1em;
  }
}

.colors {
  margin-left: 1em;
}
.colorButton {
  height: 2em;
  width: 2em;
  border-radius: 50%;
  border: solid 5px;
  flex: 0 0 auto;
  margin-left: 0.5em;
  &::after {
    content: '\00a0';
  }
  @each $color, $col in $note_full-colors {
    &.#{$color} {
      border-color: map-get($col, border);
      background: map-get($col, fill);
      color: map-get($col, text);
      @include triggered {
        background: map-get($col, border);
      }

      &.selected {
        background: map-get($col, border);
      }
    }
  }

  &.selected {
    color: white;
  }

  &.black {
    @include triggered {
      color: white;
    }
    &.selected {
      @include triggered {
        color: white;
      }
    }
  }

  &.none {
    border: 2px dashed #cccccc;
    background: none;
    @include triggered {
      border-color: black;
      background: #dddddd;
    }
    &.selected {
      color: black;
    }
  }

  &.selected::after {
    content: '\2713';
  }
}
</style>
