const phases = {
  // phases
  context: `
  <svg version="1.1" viewBox="0 0 83 83">
    <path class="fill" d="M51.2,30.5c0,5.2-4.2,9.5-9.4,9.5s-9.4-4.2-9.4-9.5c0-5.2,4.2-9.5,9.4-9.5S51.2,25.2,51.2,30.5L51.2,30.5z"/>
    <path class="stroke" d="M53.1,57.3c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7C51,52.5,53.1,54.7,53.1,57.3L53.1,57.3z"/>
    <path class="stroke" d="M67,57.3c0,2.6-2.1,4.7-4.7,4.7c-2.6,0-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7C64.9,52.5,67,54.7,67,57.3L67,57.3z"/>
    <path class="stroke" d="M25.4,57.3c0,2.6-2.1,4.7-4.7,4.7S16,59.9,16,57.3c0-2.6,2.1-4.7,4.7-4.7S25.4,54.7,25.4,57.3L25.4,57.3z"/>
    <path class="stroke" d="M39.3,57.3c0,2.6-2.1,4.7-4.7,4.7s-4.7-2.1-4.7-4.7c0-2.6,2.1-4.7,4.7-4.7S39.3,54.7,39.3,57.3L39.3,57.3z"/>
  </svg>
  `,
  checkIn: `
  <svg version="1.1" viewBox="0 0 83 83">
    <defs>
      <mask id="noCirc-UNIQ" x="0" y="0" width="83" height="83">
        <rect width="83" height="83" fill="white"/>
        <path fill="black" d="M46.4,23.9c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,19,46.4,21.2,46.4,23.9L46.4,23.9z"/>
        <path fill="black" d="M46.4,59.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,54.2,46.4,56.4,46.4,59.1L46.4,59.1z"/>
        <path fill="black" d="M57.4,32.5c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C59.3,27.5,59.3,30.6,57.4,32.5L57.4,32.5z"/>
        <path fill="black" d="M32.5,57.4c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C34.4,52.4,34.4,55.5,32.5,57.4L32.5,57.4z"/>
        <path fill="black" d="M59.1,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C64,44.2,61.8,46.4,59.1,46.4L59.1,46.4z"/>
        <path fill="black" d="M23.9,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C28.8,44.2,26.6,46.4,23.9,46.4L23.9,46.4z"/>
        <path fill="black" d="M50.5,57.4c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C55.5,59.3,52.4,59.3,50.5,57.4L50.5,57.4z"/>
        <path fill="black" d="M25.6,32.5c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C30.6,34.4,27.5,34.4,25.6,32.5L25.6,32.5z"/>
      </mask>
    </defs>
    <path mask="url(#noCirc-UNIQ)" class="stroke" d="M59,42c0,9.4-7.6,17-17,17c-9.4,0-17-7.6-17-17c0-9.4,7.6-17,17-17C51.4,25,59,32.6,59,42L59,42z"/>
    <path class="stroke" d="M46.4,23.9c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,19,46.4,21.2,46.4,23.9L46.4,23.9z"/>
    <path class="stroke" d="M46.4,59.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,54.2,46.4,56.4,46.4,59.1L46.4,59.1z"/>
    <path class="fill" d="M57.4,32.5c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C59.3,27.5,59.3,30.6,57.4,32.5L57.4,32.5z"/>
    <path class="stroke" d="M32.5,57.4c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C34.4,52.4,34.4,55.5,32.5,57.4L32.5,57.4z"/>
    <path class="stroke" d="M59.1,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C64,44.2,61.8,46.4,59.1,46.4L59.1,46.4z"/>
    <path class="stroke" d="M23.9,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C28.8,44.2,26.6,46.4,23.9,46.4L23.9,46.4z"/>
    <path class="stroke" d="M50.5,57.4c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C55.5,59.3,52.4,59.3,50.5,57.4L50.5,57.4z"/>
    <path class="stroke" d="M25.6,32.5c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C30.6,34.4,27.5,34.4,25.6,32.5L25.6,32.5z"/>
  </svg>
  `,
  'input-old': `
  <svg version="1.1" viewBox="0 0 83 83">
    <rect class="stroke" x="17.2" y="22.7" width="49.9" height="20.1"/>
    <path class="stroke" d="M54.2,57.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C52,52.2,54.2,54.4,54.2,57.1L54.2,57.1z"/>
    <path class="stroke" d="M68,57.1c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9S68,54.4,68,57.1L68,57.1z"/>
    <path class="stroke" d="M26.6,57.1c0,2.7-2.2,4.9-4.9,4.9s-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9S26.6,54.4,26.6,57.1L26.6,57.1z"/>
    <path class="stroke" d="M40.4,57.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C38.2,52.2,40.4,54.4,40.4,57.1L40.4,57.1z"/>
  </svg>
  `,
  input: `
  <svg version="1.1" viewBox="0 0 83 83"> // 53 40
    <g transform="translate(15 21.5)">
      <rect class="stroke" x="1" y="1" width="51" height="29" rx="6.8" ry="6.8" stroke-width="2"/>
      <path class="fill" d="M9,38H43a1,1,0,0,1,1,1h0a1,1,0,0,1-1,1H9a1,1,0,0,1-1-1H8A1,1,0,0,1,9,38Z"/>
    <g>
  </svg>
  `,
  decision: `
  <svg version="1.1" viewBox="0 0 83 83">
    <path class="stroke" d="M59.7,43.2c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C54.8,41,57,43.2,59.7,43.2z"/>
    <path class="stroke" d="M53.1,60.9c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C48.3,58.7,50.5,60.9,53.1,60.9z"/>
    <path class="stroke" d="M36.5,30.3c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C31.7,28.1,33.8,30.3,36.5,30.3z"/>
    <path class="stroke" d="M42.5,43.2c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C37.6,41,39.8,43.2,42.5,43.2z"/>
    <path class="stroke thinStroke" d="M67,47.2H13H67z"/>
    <path class="stroke" d="M55.5,27.3c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C50.6,25.2,52.8,27.3,55.5,27.3z"/>
    <path class="stroke" d="M40.1,70.2c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C35.2,68,37.4,70.2,40.1,70.2z"/>
    <path class="stroke" d="M25.2,41.4c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C20.4,39.3,22.6,41.4,25.2,41.4z"/>
    <path class="stroke" d="M27,60.8c2.7,0,4.9-2.2,4.9-4.8c0-2.7-2.2-4.8-4.9-4.8c-2.7,0-4.9,2.2-4.9,4.8C22.2,58.6,24.3,60.8,27,60.8z"/>
  </svg>
  `,
  review: `
  <svg version="1.1" viewBox="0 0 83 83">
    <rect class="stroke" x="22" y="21" width="40" height="42"stroke-width="1.4"/>
    <rect class="fill" x="30" y="29" width="24" height="2"/>
    <rect class="fill"x="30" y="35" width="24" height="2"/>
    <rect class="fill"x="30" y="41" width="24" height="2"/>
    <rect class="fill"x="30" y="47" width="24" height="2"/>
    <rect class="fill"x="30" y="53" width="24" height="2"/>
  </svg>
  `,
  feedback: `
  <svg version="1.1" viewBox="0 0 83 83">
    <path class="stroke" d="M46.4,23.9c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,19,46.4,21.2,46.4,23.9L46.4,23.9z"/>
    <path class="stroke" d="M46.4,59.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,54.2,46.4,56.4,46.4,59.1L46.4,59.1z"/>
    <path class="stroke" d="M57.4,32.5c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C59.3,27.5,59.3,30.6,57.4,32.5L57.4,32.5z"/>
    <path class="stroke" d="M32.5,57.4c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C34.4,52.4,34.4,55.5,32.5,57.4L32.5,57.4z"/>
    <path class="stroke" d="M59.1,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C64,44.2,61.8,46.4,59.1,46.4L59.1,46.4z"/>
    <path class="stroke" d="M23.9,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C28.8,44.2,26.6,46.4,23.9,46.4L23.9,46.4z"/>
    <path class="stroke" d="M50.5,57.4c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C55.5,59.3,52.4,59.3,50.5,57.4L50.5,57.4z"/>
    <path class="stroke" d="M25.6,32.5c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C30.6,34.4,27.5,34.4,25.6,32.5L25.6,32.5z"/>
    <circle class="fill" cx="41.5" cy="41.5" r="7.5" />
  </svg>
  `,
  checkOut: `
  <svg version="1.1" viewBox="0 0 83 83">
    <path class="stroke" d="M46.4,23.9c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,19,46.4,21.2,46.4,23.9L46.4,23.9z"/>
    <path class="stroke" d="M46.4,59.1c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9C44.2,54.2,46.4,56.4,46.4,59.1L46.4,59.1z"/>
    <path class="fill" d="M57.4,32.5c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C59.3,27.5,59.3,30.6,57.4,32.5L57.4,32.5z"/>
    <path class="stroke" d="M32.5,57.4c-1.9,1.9-5,1.9-6.9,0c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0C34.4,52.4,34.4,55.5,32.5,57.4L32.5,57.4z"/>
    <path class="stroke" d="M59.1,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C64,44.2,61.8,46.4,59.1,46.4L59.1,46.4z"/>
    <path class="stroke" d="M23.9,46.4c-2.7,0-4.9-2.2-4.9-4.9c0-2.7,2.2-4.9,4.9-4.9c2.7,0,4.9,2.2,4.9,4.9C28.8,44.2,26.6,46.4,23.9,46.4L23.9,46.4z"/>
    <path class="stroke" d="M50.5,57.4c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C55.5,59.3,52.4,59.3,50.5,57.4L50.5,57.4z"/>
    <path class="stroke" d="M25.6,32.5c-1.9-1.9-1.9-5,0-6.9c1.9-1.9,5-1.9,6.9,0c1.9,1.9,1.9,5,0,6.9C30.6,34.4,27.5,34.4,25.6,32.5L25.6,32.5z"/>
  </svg>
  `,
  breakTime: `
    <svg version="1.1" viewBox="0 0 83 83">
      <path class="stroke" d="M56.7,51.6c0,9-7.5,16.3-16.8,16.3S23,60.6,23,51.6" stroke-width="1.4" />
      <path class="stroke" d="M56.7,32c6.5,0,11.8,4.4,11.8,9.8s-5.3,9.8-11.8,9.8" stroke-width="1.4" />
      <line class="stroke" x1="23" y1="51.6" x2="23" y2="25.4" stroke-width="1.4" />
      <line class="stroke" x1="56.8" y1="51.6" x2="56.8" y2="25.4" stroke-width="1.4"/>
      <line class="stroke" x1="23" y1="37.4" x2="56.7" y2="37.4"/>
      <path class="stroke" d="M34.8,14c-6.7,9.8,6.7,8.2,0,18"/>
      <path class="stroke" d="M44.6,14c-6.7,9.8,6.7,8.2,0,18"/>
    </svg>
  `,
  groups: `
  <svg version="1.1" viewBox="0 0 83 83">
    <circle class="stroke" cx="24" cy="42" r="10" stroke-width="1.4"/>
    <circle class="stroke" cx="59" cy="42" r="10" stroke-width="1.4"/>
    <circle class="stroke" cx="42" cy="59" r="10" stroke-width="1.4"/>
    <circle class="stroke" cx="42" cy="24" r="10" stroke-width="1.4"/>
  </svg>
  `,
  dialogue: `
  <svg version="1.1" viewBox="0 0 83 83">
    <g class="lines">
      <line class="stroke stroke-mid-thin" x1="30.9" y1="54.94" x2="58.5" y2="43.9"/>
      <line class="stroke stroke-mid-thin" x1="52.1" y1="28.06" x2="24.38" y2="39.15"/>
      <line class="stroke stroke-mid-thin" x1="24.5" y1="43.96" x2="51.96" y2="55.23"/>
      <line class="stroke stroke-mid-thin" x1="55.09" y1="52.02" x2="43.07" y2="24.63"/>
      <line class="stroke stroke-mid-thin" x1="58.14" y1="41.5" x2="24.83" y2="41.5"/>
      <line class="stroke stroke-mid-thin" x1="31.02" y1="27.82" x2="58.5" y2="39.39"/>
      <line class="stroke stroke-mid-thin" x1="42.66" y1="58.17" x2="55.03" y2="30.95"/>
      <line class="stroke stroke-mid-thin" x1="39.43" y1="24.63" x2="27.53" y2="51.86"/>
      <line class="stroke stroke-mid-thin" x1="27.47" y1="31.16" x2="39.1" y2="58.64"/>
    </g>
    <g class="circles">
      <path class="stroke stroke-mid-broad" d="M47.91,18.41A6.41,6.41,0,1,1,41.5,12a6.42,6.42,0,0,1,6.41,6.41Z"/>
      <path class="stroke stroke-mid-broad" d="M47.91,64.59a6.41,6.41,0,1,1-6.41-6.42,6.41,6.41,0,0,1,6.41,6.42Z"/>
      <path class="stroke stroke-mid-broad" d="M62.36,29.71a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
      <path class="stroke stroke-mid-broad" d="M29.71,62.36a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
      <path class="stroke stroke-mid-broad" d="M64.59,47.91A6.41,6.41,0,1,1,71,41.5a6.42,6.42,0,0,1-6.41,6.41Z"/>
      <path class="stroke stroke-mid-broad" d="M18.41,47.91a6.41,6.41,0,1,1,6.42-6.41,6.41,6.41,0,0,1-6.42,6.41Z"/>
      <path class="stroke stroke-mid-broad" d="M53.29,62.36a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
      <path class="stroke stroke-mid-broad" d="M20.64,29.71a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
    </g>
  </svg>
  `,
  'dialogue-old': `
  <svg version="1.1" viewBox="0 0 83 83">
      <defs>
      <mask id="noCirc-UNIQ" x="0" y="0" width="83" height="83">
        <rect width="83" height="83" fill="white"/>
        <path fill="black" d="M47.91,18.41A6.41,6.41,0,1,1,41.5,12a6.42,6.42,0,0,1,6.41,6.41Z"/>
        <path fill="black" d="M47.91,64.59a6.41,6.41,0,1,1-6.41-6.42,6.41,6.41,0,0,1,6.41,6.42Z"/>
        <path class="stroke stroke-broad" d="M62.36,29.71a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
        <path fill="black" d="M29.71,62.36a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
        <path fill="black" d="M64.59,47.91A6.41,6.41,0,1,1,71,41.5a6.42,6.42,0,0,1-6.41,6.41Z"/>
        <path fill="black" d="M18.41,47.91a6.41,6.41,0,1,1,6.42-6.41,6.41,6.41,0,0,1-6.42,6.41Z"/>
        <path fill="black" d="M53.29,62.36a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
        <path fill="black" d="M20.64,29.71a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
      </mask>
    </defs>
    <g class="lines" mask="url(#noCirc-UNIQ)" >
    <line class="stroke stroke-thin" x1="28.67" y1="31.24" x2="55.43" y2="51.87"/>
    <line class="stroke stroke-thin" x1="28.67" y1="51.46" x2="60.01" y2="41.5"/>
    <line class="stroke stroke-thin" x1="61.27" y1="41.89" x2="26.11" y2="41.5"/>
    <line class="stroke stroke-thin" x1="56.68" y1="31.24" x2="26.11" y2="41.91"/>
    <line class="stroke stroke-thin" x1="26.11" y1="41.5" x2="56.68" y2="51.46"/>
    <line class="stroke stroke-thin" x1="56.54" y1="51.72" x2="42.78" y2="26.11"/>
    <line class="stroke stroke-thin" x1="42.78" y1="26.11" x2="26.11" y2="41.5"/>
    <line class="stroke stroke-thin" x1="28.67" y1="31.24" x2="60.01" y2="41.91"/>
    <line class="stroke stroke-thin" x1="42.78" y1="57.41" x2="43.1" y2="26.11"/>
    <line class="stroke stroke-thin" x1="61.13" y1="41.5" x2="42.78" y2="56.44"/>
    <line class="stroke stroke-thin" x1="28.67" y1="29.96" x2="55.43" y2="29.96"/>
    <line class="stroke stroke-thin" x1="41.67" y1="26.11" x2="28.67" y2="51.72"/>
    <line class="stroke stroke-thin" x1="28.67" y1="31.24" x2="40.9" y2="57.56"/>
  </g>
  <g class="circles">
    <path class="stroke stroke-broad" d="M47.91,18.41A6.41,6.41,0,1,1,41.5,12a6.42,6.42,0,0,1,6.41,6.41Z"/>
    <path class="stroke stroke-broad" d="M47.91,64.59a6.41,6.41,0,1,1-6.41-6.42,6.41,6.41,0,0,1,6.41,6.42Z"/>
    <path class="stroke stroke-broad" d="M62.36,29.71a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
    <path class="stroke stroke-broad" d="M29.71,62.36a6.41,6.41,0,1,1,0-9.07,6.42,6.42,0,0,1,0,9.07Z"/>
    <path class="stroke stroke-broad" d="M64.59,47.91A6.41,6.41,0,1,1,71,41.5a6.42,6.42,0,0,1-6.41,6.41Z"/>
    <path class="stroke stroke-broad" d="M18.41,47.91a6.41,6.41,0,1,1,6.42-6.41,6.41,6.41,0,0,1-6.42,6.41Z"/>
    <path class="stroke stroke-broad" d="M53.29,62.36a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
    <path class="stroke stroke-broad" d="M20.64,29.71a6.41,6.41,0,1,1,9.07,0,6.42,6.42,0,0,1-9.07,0Z"/>
  </g>
  </svg>
  `,
};

export { phases };
