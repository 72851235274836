<template>
  <div class="subContent" :class="status">
    <transition-group name="basic-group" mode="out-in">
      <button class="hearingButton" title="Toggle audio test" :aria-pressed="playing" @click="toggle" key="tesrtButton">
        <svg v-if="!playing && status !== 'success'" class="icon play" viewBox="0 0 36 36">
          <path class="fill" d="M8,0l26,18l-26,18z" />
        </svg>
        <svg v-if="playing && status !== 'success'" class="icon pause" viewBox="0 0 36 36">
          <rect class="fill" x="0" y="0" width="12" height="36" />
          <rect class="fill" x="24" y="0" width="12" height="36" />
        </svg>
        <svg v-if="status === 'success'" class="icon tick" viewBox="0 0 42 42">
          <polygon class="fill" points="40.85 10.39 36.61 6.15 15 27.76 5.05 17.81 0.81 22.05 14.95 36.19 15.34 35.8 15.39 35.85 40.85 10.39" />
        </svg>
      </button>
      <audio id="checkAudio" style="display: none" key="audio" />
      <h2 key="heading">Can you hear us?</h2>
      <p v-if="status === 'pending'" key="pendingMsg" class="pendingMsg">
        Press play. Can you hear the music? If not adjust your speaker settings until you can.
      </p>
      <p v-if="status === 'fail'" key="failMsg" class="failMsg">
        Drat. Try adjusting your system settings before you join or start a bixe meeting. Come back here anytime to test again.
      </p>
      <p v-if="status === 'success'" key="successMsg" class="successMsg">Excellent! Now that you can hear us let’s move on to get your microphone working.</p>
      <div v-if="!isFirefox">
        <div v-if="!devices" class="loadingDevices" key="loading">fetching your devices <app-loading-spinner /></div>
        <app-select
          v-if="devices"
          v-model="selectedDevice"
          class="deviceSelect"
          :options="deviceOptions"
          placeholder="please select a device"
          @change="onDeviceSelection"
          key="select"
        >
          <template #arrow>
            <app-icon icon="down-arrow" />
          </template>
          <template #placeholder> Please select a device </template>
          <template #label> Please select a device </template>
        </app-select>
      </div>
      <div class="btn-pair" v-if="status === 'pending'" key="status">
        <button type="button" class="btn-blue-flood" @click="status = 'fail'">No</button>
        <button type="button" class="btn-blue-flood" @click="status = 'success'">Yes</button>
      </div>
      <button v-if="status !== 'pending'" class="btn btn-border btn-medium btn-blue-flood" @click="moveToNextStep()" key="next">Next</button>
    </transition-group>
  </div>
</template>

<script>
import { useStorage } from 'vue3-storage';
import { mapState as mapPiniaState } from 'pinia';
import { usePageStore } from '@/store/pinia/page';
import { useMediaStore } from '@/store/pinia/media';
import utils from '@/resources/utils.js';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import AppIcon from '@/components/AppIcon';
import AppSelect from '@/components/AppSelect';

const localStorage = useStorage({ namespace: '', storage: 'local' });

export default {
  components: {
    AppLoadingSpinner,
    AppIcon,
    AppSelect,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  data() {
    return {
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkSpeaking,
      playing: false,
      selectedDevice: undefined,
      audioElementId: 'meetings-meetings',
      status: 'pending',
    };
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['devices', 'audioOutputSource']),
    isFirefox() {
      return navigator.userAgent.indexOf('Firefox') != -1;
    },
    deviceOptions() {
      const matchingDevices = this.devices
        .filter((d) => d.kind === 'audioOutput')
        .map((d) => {
          return {
            value: d.deviceId,
            label: utils.cleanDeviceLabel(d.label),
          };
        });

      return matchingDevices.length ? matchingDevices : [{ label: 'Default Speaker', value: 'default' }];
    },
  },
  watch: {
    selectedDevice() {
      this.stop();
      this.onDeviceSelection();
      this.$nextTick(() => {
        this.status = 'pending';
      });
    },
    audioOutputSource: {
      immediate: true,
      handler(nv) {
        if (this.devices && this.devices.length && nv && !this.selectedDevice) {
          // wait till devices and last selected audio output device loaded to show selected device
          this.selectedDevice = nv;
        }
      },
    },
    devices: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv && nv.length && this.audioOutputSource && !this.selectedDevice) {
          // wait till devices and last selected audio output device loaded to show selected device
          this.selectedDevice = this.audioOutputSource;
        } else if (nv && nv.length && !this.selectedDevice) {
          this.selectedDevice = 'default';
        }
      },
    },
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
    this.$readinessChecker.markAsStarted(this.pageName);
  },
  beforeUnmount() {
    this.stop();
  },
  methods: {
    toggle() {
      if (!this.selectedDevice) {
        this.selectedDevice = this.deviceOptions[0].value;
      }
      if (this.playing) {
        this.playing = false;
        this.stop();
      } else {
        if (this.status === 'fail') {
          return;
        }
        this.playing = true;
        this.play();
      }
    },
    onDeviceSelection() {
      localStorage.setStorageSync('user:audioOutputSource', this.selectedDevice);
      this.mediaStore.setAudioOutputSource(this.selectedDevice || false);
      this.$readinessChecker.markAsCompleted({ subPageName: this.pageName, isPassed: true });
    },
    play() {
      const $audioEl = document.getElementById(this.audioElementId);
      if (!this.isFirefox) {
        $audioEl.setSinkId(this.selectedDevice).then(() => {
          $audioEl.play();
        });
      } else {
        $audioEl.play();
      }
    },
    stop() {
      this.playing = false;
      let $audioEl = document.getElementById(this.audioElementId);
      $audioEl.pause();
    },
    moveToNextStep() {
      this.$readinessChecker.moveToNextStep({ nextPageName: this.nextPageName });
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  max-width: 20em;
  &.failMsg {
    max-width: 27em;
  }
}

.hearingButton {
  --color: var(--c__accent);
  color: var(--color);
  border: 3px solid var(--color);
  width: rem(94px);
  height: rem(94px);
  border-radius: 50%;
  margin-bottom: rem(36px);
  svg {
    width: rem(36px);
    .success & {
      width: rem(42px);
    }
  }
  @include triggered {
    color: var(--c__bg);
    background: var(--color);
  }
  .fail & {
    --color: var(--c__warn);
  }
  .success & {
    --color: var(--c__success);
  }
}
</style>
