import { defineStore } from 'pinia';
import _ from 'underscore';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useTimeStore } from '@/store/pinia/time';
import { useMediaStore } from '@/store/pinia/media';
import { useMessagesStore } from '@/store/pinia/messages';
import { eventEmitter } from '@/services/event-emitter.service';
import utils from '@/resources/utils';

//TODO: handle system mutes
//TODO: handle groups

export const useUsersStore = defineStore('users', {
  state() {
    return {
      __initialized: false,
      myId: window.__INITIAL_STATE__.user?.id,
      resyncRequestTs: 0,
      sandboxCount: 1,
      sandbox: false,
      sandboxUsers: [],
      sandboxUserIndex: 0,
      sandboxSpeaker: false,
      sandboxOwner: false,
      me: {},
      owner: {},
      lastTalking: false,
      firstShown: false,
      all: [],
      moderators: [],
      recommendedModerators: [],
      new: [],
      entrances: [],
      away: [],
      awayInGroup: [],
      inWaitingRoom: [],
      connected: [],
      returned: [],
      unreturnedGroups: [],
      talking: [],
      loading: [],
      hovered: new Set(),
      hoverTimeout: {},
      unstableNetwork: new Set(),
      swapping: false,
      // AV and loading
      videoLoaded: new Set(),
      needsNoBoopList: new Set(),
      // Layout & Positioning
      randomTicker: false,
      randomTickerList: [],
      animating: 0,
      hide: false,
      positions: {},
      positionDetails: {},
      posCallTs: false,
      moving: new Set(),
      aboutToMove: new Set(),
      volumes: {},
      debugLayout: false,
      layout: false,
      resize: false,
      paged: false,
      page: 0,
      pages: [],
      currentPage: 0,
      // groups
      localGroups: false,
    };
  },
  getters: {
    allUsersList: (s) => s.all.map((u) => u.id),
    allUsers: (s) => {
      return s.all.filter((u) => !u.ejected);
    },
    usersVisible(s) {
      if (!s.me) {
        return [];
      }

      if (s.me.away || s.me.inWaitingRoom) {
        return [s.me];
      } else if ((this.iAmAwayInGroup && this.groups) || this.iAmStillAwayInGroup) {
        return [...this.myGroupUsers];
      } else {
        const phaseStore = usePhaseStore();
        const mediaStore = useMediaStore();
        if (phaseStore.isPresentation && !mediaStore.screenSharing && !phaseStore.someoneIsMirroring) {
          return this.usersHere.filter((u) => u.id !== this.speaking?.id);
        } else {
          return [...this.usersHere];
        }
      }
    },
    usersVisibleList() {
      return this.usersVisible.map((u) => u.id);
    },
    usersOnline(s) {
      return s.all.length > 0 ? s.all.filter((u) => u.connected && !u.away && !u.gone && !u.inWaitingRoom) : [];
    },
    usersOfflineList(s) {
      if (s.all.length < 0) {
        return [];
      }
      return s.all.filter((u) => !u.connected).map((u) => u.id);
    },
    usersGoneList(s) {
      if (s.all.length < 0) {
        return [];
      }
      return s.all.filter((u) => u.gone).map((u) => u.id);
    },
    usersHere: (s) => {
      return s.all.length > 0 ? s.all.filter((u) => !u.gone && !u.ejected && !u.inWaitingRoom) : [];
    },
    visibleUserCount() {
      return this.usersVisibleList.length;
    },
    haveModeratorsJoined(s) {
      return s.all.some((user) => s.moderators.includes(user.id) && user.connected && !user.gone);
    },
    //avatarsAnimating: (s) => s.animating > 0,
    //TODO: check why this is busted (is 32 users breaking it?)
    avatarsAnimating: () => false,
    speaking: (s) => {
      let id;
      if (s.sandboxSpeaker) {
        id = s.sandboxSpeaker;
      } else {
        const phaseStore = usePhaseStore();
        id = phaseStore.speaking;
      }
      if (!id) {
        return {};
      }
      return s.all.find((u) => u.id === id) || {};
    },
    speakingId() {
      return this.speaking.id;
    },
    mirroring: (s) => {
      const phaseStore = usePhaseStore();
      if (!phaseStore.mirroring.requestedBy) {
        return {};
      }
      return s.all.find((u) => u.id === phaseStore.mirroring.requestedBy) || {};
    },
    active: (s) => {
      const phaseStore = usePhaseStore();
      if (!phaseStore.active) {
        return {};
      }
      return s.all.find((u) => u.id === phaseStore.active) || {};
    },
    talkingUser(s) {
      if (s.talking.length < 1) {
        return false;
      } else {
        let user = this.getUserById(s.talking[0]);
        return user;
      }
    },
    mostRecentTalking(s) {
      return s.talking.length > 0 ? s.talking[0] : false;
    },
    currentlyTalking() {
      let user = this.talkingUser;
      return user && this.volumes[user.id] > 0 && !user.muted && !user.muted_by_system ? user.id : false;
    },
    // finding
    getUserById: (s) => (id) => _.findWhere(s.all, { id: id }),
    // checks
    isMe: (s) => (id) => s.me?.id === id,
    isOwner: (s) => (id) => s.owner?.id === id,
    isMeetingModerator(s) {
      return (id) => s.moderators.includes(id);
    },
    isMoving: (s) => (id) => s.moving.has(id),
    isSpeaking() {
      const _this = this;
      return function (id) {
        return _this.speaking?.id === id;
      };
    },
    isActive() {
      const _this = this;
      return function (id) {
        return _this.active?.id === id;
      };
    },
    isConnected() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id).connected;
      };
    },
    isInWaitingRoom() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id).inWaitingRoom;
      };
    },
    isAway() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.away;
      };
    },
    isAwayInGroup() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.unreturned;
      };
    },
    hasHandUp() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.hand;
      };
    },
    isMuted() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.muted;
      };
    },
    isTalking() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.talking;
      };
    },
    isSysMuted() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.muted_by_system;
      };
    },
    isVideoMuted() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.video_muted;
      };
    },
    isSysVideoMuted() {
      const _this = this;
      return function (id) {
        return _this.getUserById(id)?.video_muted_by_system;
      };
    },
    isHovered(s) {
      return function (id) {
        return s.hovered.has(id);
      };
    },
    iAmOwner: (s) => (s.owner.id && s.me.id ? s.owner.id === s.me.id : false),
    iAmModerator(s) {
      return s.moderators.includes(s.me.id);
    },
    iAmSpeaking(s) {
      return this.speaking?.id === s.me?.id;
    },
    iAmInWaitingRoom: (s) => s.inWaitingRoom.some((u) => u.id === s.me?.id),
    iAmMirroring: (s) => {
      const phaseStore = usePhaseStore();
      return s.me?.id === phaseStore.mirroring.requestedBy;
    },
    iAmVideoMuted: (s) => s.me?.video_muted,
    iAmSysVideoMuted: (s) => s.me?.video_muted_by_system,
    iAmMuted: (s) => s.me?.muted,
    iAmSysMuted: (s) => s.me?.muted_by_system,
    iAmActive(s) {
      return this.active?.id === s.me?.id;
    },
    iAmPresenting() {
      const phaseStore = usePhaseStore();
      const mediaStore = useMediaStore();
      if (phaseStore.someoneIsMirroring) {
        return this.iAmMirroring;
      }
      return this.iAmSpeaking && (mediaStore.screenSharing || mediaStore.cameraSharing);
    },
    otherPresenting() {
      const mediaStore = useMediaStore();
      return !this.iAmSpeaking && (mediaStore.screenSharing || mediaStore.cameraSharing);
    },
    iAmSharingScreen() {
      const mediaStore = useMediaStore();
      return this.iAmPresenting && mediaStore.screenSharing;
    },
    iAmOverdue() {
      const timeStore = useTimeStore();
      return timeStore.phaseStatus === 'overdue' && this.iAmSpeaking;
    },
    iAmAway: (s) => s.me?.away,
    iAmStillAway() {
      const phaseStore = usePhaseStore();
      return this.iAmAway && phaseStore.rawCurrentPhase !== 'breakTime';
    },
    iAmAwayInGroup: (s) => s.me?.unreturned,
    iAmStillAwayInGroup() {
      const phaseStore = usePhaseStore();
      return this.iAmAwayInGroup && phaseStore.rawCurrentPhase !== 'groups';
    },
    // pagination
    currentAvatarPageList(s) {
      if (s.paged) {
        return s.pages[s.page] ? [...s.pages[s.page]] : [...s.pages[0]];
      } else {
        return this.usersVisible.map((u) => u.id);
      }
    },
    whatPage(s) {
      const _this = this;
      return function (id) {
        if (!s.paged) {
          return 0;
        } else if (_this.currentAvatarPageList.includes(id)) {
          return s.page;
        } else {
          return _.findIndex(s.pages, (p) => p.includes(id));
        }
      };
    },
    userOnMyPage(s) {
      const _this = this;
      return function (id) {
        return s.pages.length < 2 || _this.currentAvatarPageList.includes(id) || _this.speaking?.id === id;
      };
    },
    // Groups
    groups(s) {
      let phaseStore = usePhaseStore();
      let groups = [];

      if (s.localGroups) {
        groups = s.localGroups;
      } else if (this.iAmStillAwayInGroup) {
        groups = s.unreturnedGroups;
      } else if (phaseStore.settingUp) {
        groups = phaseStore.setupGroups;
      } else {
        groups = phaseStore.currentPhaseData.groups;
      }

      if (groups) {
        groups = groups.map((group) => group.filter((u) => !this.getUserById(u).gone));
      }
      return groups;
    },
    groupSize() {
      let shortest = 99;
      for (var i = 1; i < this.groups?.length; i++) {
        if (shortest > this.groups[i].length) {
          shortest = this.groups[i].length;
        }
      }
      return shortest;
    },
    myGroup() {
      if (!this.groups) {
        return _.pluck(this.usersHere, 'id');
      } else {
        return this.groups.filter((group) => group.includes(this.myId))[0];
      }
    },
    myLastGroup() {
      if (this.iAmStillAwayInGroup) {
        const phaseStore = usePhaseStore();
        return phaseStore.lastGroupsData?.groups.find((group) => group.includes(this.myId));
      }
      return null;
    },
    myGroupUsers() {
      if (this.myLastGroup) {
        return this.myLastGroup.map((user) => this.getUserById(user));
      }
      return this.myGroup ? this.myGroup.map((user) => this.getUserById(user)) : [...this.usersHere];
    },
    usersToLoad(s) {
      return this.myGroup ? this.myGroup.filter((id) => s.loading.includes(id)) : [];
    },
    inMyGroup() {
      return function (id) {
        if (this.myLastGroup) {
          return this.myLastGroup.includes(id);
        }
        return this.myGroup && this.myGroup.includes(id);
      };
    },
    isNonEmptyGroup() {
      const usersOtherThanMe = this.myGroupUsers.filter((user) => user.id !== this.myId);
      return usersOtherThanMe.some((user) => user.unreturned);
    },
    everyoneIsBack() {
      let phaseStore = usePhaseStore();
      if (phaseStore.currentPhase === 'groups') {
        return !this.usersHere.some((user) => user.unreturned);
      } else {
        return true;
      }
    },
    // Extra bits
    secondUserTime(s) {
      if (s.all.length < 2 || s.entrances.length < 1) {
        return 0;
      } else {
        let second = _.findWhere(s.entrances, { id: s.all[1].id });
        if (!second || !second.timestamp) {
          return 0;
        } else {
          return Date.now() / 1000 - second.timestamp;
        }
      }
    },
    userVideoDown(s) {
      return function (id) {
        return !s.videoLoaded.has(id);
      };
    },
    userHasSpinner(s) {
      const _this = this;
      return function (id) {
        if (id === 'connectivity') {
          /* conditions regarding connectivity settings page */
          const mediaStore = useMediaStore();
          if (!s.videoLoaded.has('connectivity') && mediaStore.videoOn) {
            return true;
          } else {
            return false;
          }
        } else {
          /* conditions regarding meeting frame */
          // being away in groups or break supercedes the loader
          if (_this.isAway(id) || _this.isAwayInGroup(id) || !_this.userOnMyPage(id) || _this.isInWaitingRoom(id)) {
            return false;
          }
          // video is not available yet
          if (!s.videoLoaded.has(id)) {
            return true;
          }
          // user is offline
          if (!_this.isConnected(id)) {
            return true;
          }
          // Otherwise, no spinner.
          return false;
        }
      };
    },
  },
  actions: {
    initMeeting(data, isResync) {
      if (this.__initialized && !isResync) {
        return;
      }
      const { users, history } = data;
      if (!isResync) {
        this.$reset();
        this.firstShown = false;
        this.inWaitingRoom = users
          .filter((u) => u.inWaitingRoom)
          .map((u) => ({
            id: u.id,
            firstName: utils.desanitizeString(u.first_name || ''),
            lastName: utils.desanitizeString(u.last_name || ''),
          }));
      }

      this.all = _.sortBy(users, 'joinedAt').map((u) => {
        const first = u.first_name ? utils.desanitizeString(u.first_name[0].toUpperCase()) : '';
        const second = u.last_name ? utils.desanitizeString(u.last_name[0].toUpperCase()) : '';
        const initials = first + second;
        return { ...u, initials };
      });
      this.me = users.find((u) => u.id === window.__INITIAL_STATE__.user?.id);
      this.updateMe({ me: true });
      this.owner = users.find((u) => u.owner);
      this.away = users.filter((u) => u.away).map((u) => u.id);
      this.awayInGroup = users.filter((u) => u.unreturned).map((u) => u.id);
      this.recommendedModerators = data.state.recommendedModerators || [];
      this.moderators = data.state.moderators || [];
      this.createSandboxUsers();
      this.updatePhase({ phase: data.state.current_phase });

      // Do the entrances
      this.entrances = [...history]
        .reverse()
        .filter((e) => e.level === 40)
        .map((e) => {
          return {
            id: e.user_id,
            timestamp: e.timestamp,
          };
        });
      this.__initialized = true;
    },
    updatePhase({ phase }) {
      if (phase.module_id === 'decision') {
        if (phase.votes) {
          phase.votes.forEach((v) => {
            this.updateUser(v.id, { vote: v.vote });
          });
        } else {
          this.resetVotes();
        }
      } else {
        this.resetVotes();
      }
    },
    resetVotes() {
      this.updateAllUsers({ vote: false });
    },
    createSandboxUsers() {
      let list = [];

      let alp = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let prefixes = ['', 'Super-', 'Mega-', 'Hyper-', 'Giga-', 'Ultra-'];
      let vowels = ['A', 'E', 'I', 'O', 'U'];
      let imageNumber = 1;
      let alpI = 0;

      for (let i = 0; i < 100; i++) {
        list[i] = { ...this.me };
        list[i].owner = false;
        list[i].me = false;
        list[i].fake = true;
        list[i].initials = i + 1;
        list[i].first_name = prefixes[Math.floor(i / alp.length)] + (vowels.includes(alp[alpI]) ? alp[alpI] + 'bby' : alp[alpI] + 'abby');
        list[i].last_name = '!test';
        list[i].id = list[i].id + i + 100;
        list[i].picture_url = `${window.__INITIAL_STATE__.config.baseUrl}/assets/images/avatar-placeholders/${imageNumber}.png`;
        list[i].name = list[i].first_name + ' ' + list[i].last_name;

        imageNumber++;
        imageNumber = imageNumber <= 20 ? imageNumber : 1;

        alpI++;
        alpI = alpI < alp.length ? alpI : 0;
      }
      this.sandboxUsers = [...list];
    },
    updateSandboxSpeaker(id) {
      this.sandboxSpeaker = id;
    },
    updateSandboxOwner(id) {
      this.sandboxOwner = id;
    },
    updateAllUsers(update) {
      Object.keys(update).forEach((prop) => {
        this.all.forEach((user, index) => {
          this.all[index][prop] = update[prop];
        });
        this.me[prop] = update[prop];
        this.owner[prop] = update[prop];
      });
    },
    updateUser(id, update) {
      let user = this.all.find((u) => u.id === id);
      if (!user) {
        return false;
      }

      Object.keys(update).forEach((prop) => {
        user[prop] = update[prop];
      });

      if (id === this.me?.id) {
        Object.keys(update).forEach((prop) => {
          this.me[prop] = update[prop];
        });
      }
      if (id === this.owner.id) {
        Object.keys(update).forEach((prop) => {
          this.owner[prop] = update[prop];
        });
      }
    },
    updateMe(update) {
      this.updateUser(this.me?.id, update);
    },
    handleMutes({ users }) {
      users.forEach((u) => {
        this.updateUser(u.id, {
          muted: !!u.audio?.by_user,
          muted_by_system: !!u.audio?.by_system,
          video_muted: !!u.video?.by_user,
          video_muted_by_system: !!u.video?.by_system,
        });
      });
    },
    markSelfAsMuted({ muteAudio, muteVideo }) {
      if (muteAudio === 1) {
        this.updateMe({ muted: true });
      } else if (muteAudio === -1) {
        this.updateMe({ muted: false });
      }

      if (muteVideo === 1) {
        this.updateMe({ video_muted: true });
      } else if (muteVideo === -1) {
        this.updateMe({ video_muted: false });
      }
    },
    onEventCreated() {},
    onUserOnline({ triggeredBy }) {
      this.updateUser(triggeredBy, { connected: true });
    },
    onUserOffline({ triggeredBy }) {
      this.updateUser(triggeredBy, { connected: false });
      this.setNeedsNoBoop(triggeredBy, false);
    },
    onJoin(data) {
      const oldUsers = this.all.map((u) => u.id);
      data.users
        .map((u) => {
          var first = u.first_name ? utils.desanitizeString(u.first_name[0].toUpperCase()) : '';
          var second = u.last_name ? utils.desanitizeString(u.last_name[0].toUpperCase()) : '';
          let initials = first + second;
          return { ...u, initials };
        })
        .forEach((user) => {
          const isAddedAlready = oldUsers.includes(user.id);
          if (user.id !== this.me?.id && !isAddedAlready) {
            eventEmitter.emit('nM:event:userAdded', data);
            this.all.push(user);
            this.new.push(user);
            this.entrances.push({
              userId: user.id,
              timestamp: Math.floor(Date.now() / 1000),
            });
          } else {
            this.updateUser(user.id, { gone: false, joinedAt: user.joinedAt });
          }
        });
      this.all = _.sortBy(this.all, 'joinedAt');
    },
    addSandboxUser() {
      this.onJoin({ users: [this.sandboxUsers[this.sandboxUserIndex]] });
      this.sandboxUserIndex++;
    },
    removeSandboxUsers() {
      this.usersLeft({
        users: this.all.filter((u) => u.fake),
      });
      //this.sandboxUserIndex = 0;
    },
    usersEjected({ users }) {
      users.forEach((u) => {
        this.updateUser(u.id, { ejected: true });
        this.setNeedsNoBoop(u.id, false);
      });
    },
    usersLeft({ users }) {
      users.forEach((u) => {
        this.updateUser(u.id, { gone: true });
        this.setNeedsNoBoop(u.id, false);
      });
    },
    clearNewUsers() {
      this.new = [];
    },
    finishedAddingUsers() {
      this.firstShown = true;
    },
    updateTalkingList(v) {
      this.talking = v;
      if (v.length > 0) {
        this.lastTalking = v[0];
        v.forEach((id) => {
          this.mustNotBeOfflineOrGone(id); // Chime thinks they are talking so they must be here
        });
      }
    },
    usersAway({ users }) {
      let ids = users.map((u) => u.id);
      this.away = [...this.away, ...ids];
      ids.forEach((id) => {
        this.updateUser(id, { away: true });
        this.setNeedsNoBoop(id, false);
      });
    },
    usersUnaway({ users }) {
      let ids = users.map((u) => u.id);
      let newAway = [...this.away].filter((u) => !ids.includes(u));
      this.away = [...newAway];
      ids.forEach((id) => {
        this.updateUser(id, { away: false });
      });
    },
    usersJoinWaitingRoom({ users }) {
      const waitingRoomUsers = [];
      const alreadyInWaitingRoom = this.inWaitingRoom.map((u) => u.id);
      users
        .filter((u) => !alreadyInWaitingRoom.includes(u.id))
        .forEach((u) => {
          waitingRoomUsers.push({
            id: u.id,
            firstName: utils.desanitizeString(u.first_name || ''),
            lastName: utils.desanitizeString(u.last_name || ''),
          });
          this.updateUser(u.id, { inWaitingRoom: true });
        });
      this.inWaitingRoom = [...this.inWaitingRoom, ...waitingRoomUsers];
    },
    onWaitingRoomRequestApproved({ users }) {
      const ids = users.map((u) => u.id);
      this.inWaitingRoom = this.inWaitingRoom.filter(({ id }) => !ids.includes(id));
      ids.forEach((id) => {
        this.updateUser(id, { inWaitingRoom: false });
      });
    },
    onWaitingRoomRequestRejected({ users }) {
      const ids = users.map((u) => u.id);
      this.inWaitingRoom = this.inWaitingRoom.filter(({ id }) => !ids.includes(id));
      ids.forEach((id) => {
        this.updateUser(id, { inWaitingRoom: false, gone: true });
        this.setNeedsNoBoop(id, false);
      });
    },
    recommendAsModerator({ recommendedUserId }) {
      if (this.recommendedModerators.includes(recommendedUserId)) {
        return;
      }
      this.recommendedModerators = [...this.recommendedModerators, recommendedUserId];
    },
    rejectRecommendationAsModerator({ rejectedBy }) {
      this.recommendedModerators = this.recommendedModerators.filter((id) => id !== rejectedBy);
    },
    acceptRecommendationAsModerator({ moderators }) {
      this.recommendedModerators = this.recommendedModerators.filter((id) => !moderators.includes(id));
      this.moderators = [...moderators];
    },
    onRemovedFromModerators({ moderators, demotedUserId }) {
      this.moderators = [...moderators];
      if (demotedUserId === this.myId) {
        const messagesStore = useMessagesStore();
        messagesStore.addAlert('youHaveBeenDemoted');
      }
    },
    onJoinedMeetingFromWaitingRoom({ user, triggeredBy, state }) {
      const sidebarStore = useSidebarStore();
      const meetingStore = useMeetingStore();
      const phaseStore = usePhaseStore();
      const isReSync = false;
      const init = false;
      if (triggeredBy !== this.myId) {
        return;
      }

      if (state.current_phase.module_id === 'break') {
        this.usersAway({ users: [user] });
      } else if (state.current_phase.module_id === 'groups') {
        // eslint-disable-next-line
        window.location.href = window.location.href;
      }
      sidebarStore.handleSkillBar({ view: state.view }, isReSync);
      sidebarStore.checkFields({ fields: state.fields }, isReSync);
      phaseStore.updatePhase({ phase: state.current_phase, init, state }, isReSync);
      if (state.meeting_notes_disabled_for?.length) {
        meetingStore.setPersonalNotes({ state });
      }
    },
    usersUnreturned({ users }) {
      let ids = users.map((u) => u.id);
      this.awayInGroup = [...this.awayInGroup, ...ids];
      ids.forEach((id) => {
        this.updateUser(id, { unreturned: true });
        this.setNeedsNoBoop(id, false);
      });
    },
    usersReturned({ users }) {
      let ids = users.map((u) => u.id);
      let newAwayInGroup = [...this.awayInGroup].filter((u) => !ids.includes(u));
      this.awayInGroup = [...newAwayInGroup];
      ids.forEach((id) => {
        this.updateUser(id, { unreturned: false });
      });
    },
    // Locally triggered

    updateAnimating(v) {
      if (v) {
        this.animating++;
      } else {
        if (this.animating <= 0) {
          this.animating = 0;
        } else {
          this.animating--;
        }
      }
    },
    updatePositions({ pos, details, resize }) {
      this.resize = resize;
      this.positions = { ...pos };
      this.positionDetails = { ...details };
      this.paged = details.paged;
      if (details) {
        this.layout = details.layoutName;
        if (details.paged && details.pages?.length > 1) {
          this.pages = [...details.pages];
          this.currentPage = this.pages[this.page] ? [...this.pages[this.page]] : [...this.pages[0]];
        } else {
          this.pages = [[...details.users]];
          this.page = 0;
          this.currentPage = [...details.users];
        }
      }
      this.posCallTs = Date.now();
    },
    toggleHide() {
      this.hide = !this.hide;
    },
    hideUsers() {
      this.hide = true;
    },
    showUsers() {
      this.hide = false;
    },
    setSwapping(v) {
      this.swapping = v;
    },
    updateGroups({ groups }) {
      eventEmitter.emit('nM:changeNextGroups', groups);
      this.localGroups = groups;
    },
    swapUsers([a, b]) {
      console.log('gonna swap', a, b);
      //TODO implement once we have groups sorted
      // let groups = getters.groups.map((group) => {
      //   return group.map((user) => {
      //     if (user === a) {
      //       return b;
      //     } else if (user === b) {
      //       return a;
      //     } else {
      //       return user;
      //     }
      //   });
      // });
      // this.commit('UPDATE_LOCAL_GROUPS', { groups });
      // eventEmitter.emit('nM:changeNextGroups', groups);
    },
    addUserToGroup({ user, group }) {
      let groups = this.groups.map((g) => {
        return g.filter((u) => u !== user);
      });
      groups[group].push(user);
      groups = groups.filter((g) => g.length > 0);
      this.localGroups = groups;
      eventEmitter.emit('nM:changeNextGroups', groups);
    },
    updatePage(v) {
      if (this.pages[v]) {
        this.page = v;
        this.currentPage = [...this.pages[v]];
      }
    },
    updateVolume({ userId, volume }) {
      this.volumes[userId] = volume;
      if (volume > 0) {
        this.mustNotBeOfflineOrGone(userId); // Chime thinks they are making sound so they must be here
      }
    },
    updateRandomTicker(id) {
      this.randomTicker = id;
    },
    // Autohealing
    mustNotBeOfflineOrGone(id) {
      if (!this.allUsersList.includes(id)) {
        // not in the list at all
        this.incrementResync();
        return false;
      }

      let offline = this.usersOfflineList.includes(id);
      let gone = this.usersGoneList.includes(id);
      if (gone || offline) {
        if (gone) {
          this.updateUser(id, { gone: false });
        }
        if (offline) {
          this.updateUser(id, { connected: true });
        }
        this.incrementResync();
      }
    },
    incrementResync() {
      this.resyncRequestTs = Date.now();
    },
    setVideoLoaded(id, loaded) {
      if (loaded) {
        this.videoLoaded.add(id);
      } else {
        this.videoLoaded.delete(id);
      }
    },
    onHover(id) {
      //console.log('hover from ' + from);
      if (this.hoverTimeout[id]) {
        clearTimeout(this.hoverTimeout[id]);
      }
      this.setHovered(id, true);
    },
    onUnHover(id, from) {
      const delay = from === 'clickOutside' ? 0 : 1000;
      //console.log('unhover from ' + from);
      this.hoverTimeout[id] = setTimeout(() => {
        this.setHovered(id, false);
      }, delay);
    },
    setHovered(id, hovered) {
      if (hovered) {
        this.hovered.add(id);
      } else {
        this.hovered.delete(id);
      }
    },
    setMoving(id, moving) {
      if (moving) {
        this.moving.add(id);
        this.aboutToMove.delete(id);
      } else {
        this.moving.delete(id);
        setTimeout(this.cancelMove, 300);
      }
    },
    cancelMove(id) {
      this.aboutToMove.delete(id);
    },
    setAboutToMove(id, v) {
      if (v) {
        this.aboutToMove.add(id);
      } else {
        this.aboutToMove.delete(id);
      }
    },
    setHoverTimeout(id, v) {
      this.hoverTimeout = v;
    },
    showUnstableNetworkWarn(userId) {
      if (this.unstableNetwork.has(userId)) {
        return;
      }
      this.unstableNetwork.add(userId);
      if (userId === this.myId) {
        const messagesStore = useMessagesStore();
        messagesStore.addAlert('connectionTrouble');
      }
    },
    hideUnstableNetworkWarn(userId) {
      if (!this.unstableNetwork.has(userId)) {
        return;
      }
      this.unstableNetwork.delete(userId);
      if (userId === this.myId) {
        const messagesStore = useMessagesStore();
        messagesStore.removeAlert({ messageName: 'connectionTrouble' });
      }
    },
    setDebugLayout(v) {
      this.debugLayout = v;
    },
    setNeedsNoBoop(id, needsNoBoop) {
      if (needsNoBoop) {
        this.needsNoBoopList.add(id);
      } else {
        this.needsNoBoopList.delete(id);
      }
    },
  },
});
