import EventEmitter from 'wolfy87-eventemitter';

const defaults = {
  timeout: 2000,
};

export default class Queue extends EventEmitter {
  constructor(config) {
    super();

    this.config = Object.assign({}, defaults, config);
    this.queue = {};
  }

  start() {
    this.timerId = setInterval(() => {
      this.emit('process', this.queue);
    }, this.config.timeout);
    this.started = true;
  }

  stop() {
    clearTimeout(this.timerId);
    this.timerId = null;
    this.started = false;
  }

  add(id, data) {
    this.queue[id] = data;
  }

  remove(id) {
    delete this.queue[id];
  }

  keys() {
    return Object.keys(this.queue);
  }

  get(id) {
    return this.queue[id];
  }

  clear() {
    this.queue = {};
  }
}
