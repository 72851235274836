<template>
  <exit-lobby page-name="meetingTimedOut">
    <h2>Your meeting was closed automatically due to inactivity</h2>
  </exit-lobby>
</template>

<script>
import ExitLobby from '@/components/ExitLobby';
import { usePageStore } from '@/store/pinia/page';

export default {
  components: {
    ExitLobby,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
  },
};
</script>
<style lang="scss" scoped>
h2 {
  max-width: 16em;
}
.background {
  width: 100%;
}
</style>
