<template>
  <div class="dialogueView">
    <transition name="basic">
      <div class="topicFrame" v-if="showTopic">
        <div class="box">
          <topic-box :model-field="cleanTopic" placeholder="Begin with a topic..." @send="debouncedSend" @finish="finishTopic"
            >{{ speaking?.first_name }} is choosing a topic</topic-box
          >
        </div>
      </div>
    </transition>
    <transition name="web">
      <talking-web v-show="showWeb" />
    </transition>
    <transition name="basic">
      <mapping v-if="showMapping" />
    </transition>
  </div>
</template>

<script setup>
import { getCurrentInstance, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useFeaturesStore } from '@/store/pinia/features';
import { useFrameStore } from '@/store/pinia/frame';
import _ from 'underscore';
import Mapping from '@/components/Mapping';
import TopicBox from '@/components/TopicBox';
import TalkingWeb from '@/components/TalkingWeb';
import utils from '../resources/utils';

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const phaseStore = usePhaseStore();
const featuresStore = useFeaturesStore();
const frameStore = useFrameStore();
const usersStore = useUsersStore();

const { dialogueMaps } = storeToRefs(featuresStore);
const { contHeight } = storeToRefs(frameStore);
const { speaking, layout, visibleUserCount } = storeToRefs(usersStore);
const { topic, phaseStage, currentPhase } = storeToRefs(phaseStore);

const cleanTopic = computed(() => {
  return utils.desanitizeString(topic.value);
});

const showTopic = computed(() => phaseStage.value === 'topic' && !phaseStore.mirroring.requestedBy);
const showMapping = computed(() => phaseStage.value !== 'topic' && dialogueMaps.value);
const showWeb = computed(() => layout.value === 'ring' && currentPhase.value === 'dialogue' && visibleUserCount.value > 2);

const topicBoxTop = computed(() => contHeight.value / 3 - 58 + 'px');

function send(v) {
  const id = phaseStore.topicId || null;
  $meetingmanager.updateTopic({ id, topic: v });
}
const debouncedSend = _.debounce(send, 500);
function finishTopic(v) {
  $meetingmanager.updateTopic({ topic: v, finished: true });
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.web-enter-active {
  transition: all 0.3s cubic-bezier(0, 0.5, 0.5, 1) 1s;
}

.web-leave-active {
  transition: all 0.3s cubic-bezier(0.5, 0, 1, 0.5);
}

.web-enter-from,
.web-leave-to {
  transform: scale(0);
  opacity: 0;
}

.dialogueView {
  background: var(--c__bg);
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.topicFrame {
  width: 100%;
  top: v-bind(topicBoxTop);
  display: flex;
  flex-direction: column;
  position: absolute;
  height: rem(108px);
  padding: 0 rem(50px);
  align-items: center;
  justify-content: center;
}

.box {
  width: rem(482px);
  min-height: rem(54px);
  margin: 0 auto;
  border: 2px solid var(--c__edges-box);
  max-width: 100%;
  background: var(--c__bg);
  position: relative;
}
</style>
