export const hide = 30000;

export const alerts = {
  cannotUnmuteMutedUser: {
    heading: false,
    content: 'For privacy reasons you cannot unmute this user.',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  cannotUnVideoMuteMutedUser: {
    heading: false,
    content: `For privacy reasons you cannot unmute this user's video.`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  tryPushToTalk: {
    heading: false,
    content: 'Want to speak? Hold your spacebar to temporarily unmute.',
    tipClass: 'microskill',
    hideOnLayout: false,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  willUnmute: {
    heading: false,
    content: 'Your audio mute will be removed when it is your turn to speak',
    autoRemove: false,
    tipClass: 'microskill',
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  pleaseUnmute: {
    heading: false,
    content: function ({ phase }) {
      if (phase === 'checkIn') {
        return `It's your turn to Check-In. Are you ready to speak?`;
      } else if (phase === 'checkOut') {
        return `It's your turn to Check-Out. Are you ready to speak?`;
      }
      return 'Your microphone is muted &mdash; <br/> are you ready to speak?';
    },
    messageName: 'pleaseUnmute',
    buttons: function ({ phase }) {
      if (phase === 'checkIn' || phase === 'checkOut') {
        return [{ text: 'Unmute me', isResolve: true, value: 'unmuteMe' }];
      } else {
        return [
          { text: 'Cancel', isResolve: true, value: false },
          { text: 'Unmute', isResolve: true, value: 'unmuteMe' },
        ];
      }
    },
    noAutoHide: true,
  },
  youNeedToEndYourTurn: {
    heading: false,
    content: 'You need to end your turn',
    autoRemove: false,
    hideOnLayout: true,
    tipClass: 'microskill',
    buttons: [{ text: "I'm done", isResolve: true, value: 'done' }],
  },
  pleaseWaitForOwner: {
    heading: false,
    content: (a) => `Please wait for ${a.ownerName} to join before starting the&nbsp;meeting.`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  pleaseWaitForRoomOwner: {
    heading: false,
    content: (a) => `Only ${a.ownerName} can start the meeting as this is their Personal&nbsp;Room.`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  pleaseWaitForUsers: {
    heading: false,
    content: 'Please wait for someone to join your meeting before&nbsp;starting',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  pleaseWaitForUsersPhase: {
    heading: false,
    content: 'Please wait for someone to join your meeting before starting a&nbsp;phase',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  cannotStartWhileMirroring: {
    heading: false,
    content: 'You cannot start a new phase while someone is sharing their&nbsp;screen',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
  },
  meetingExtended: {
    heading: false,
    content: (a) => `${a.addedBy} has added ${a.amount} mins to the overall meeting time`,
    tipClass: 'microskill',
    hideOnLayout: false,
    autoRemove: 5000,
    replaceSelf: true,
  },
  groupExtended: {
    heading: false,
    content: (a) => `${a.addedBy} added more time to the break-out groups`,
    tipClass: 'microskill',
    hideOnLayout: false,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  breakExtended: {
    heading: false,
    content: (a) => `${a.addedBy} extended the break`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  groupEndedEarly: {
    heading: false,
    content: (a) => `${a.endedBy} finished the break-out groups`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  breakEndedEarly: {
    heading: false,
    content: (a) => `${a.endedBy} finished the break`,
    tipClass: 'microskill',
    hideOnLayout: false,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  pleaseReview: {
    heading: false,
    content: 'You have 15 mins left.</br>Want to Review & Check-Out?',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  connectionTrouble: {
    heading: false,
    content: `Low bandwidth&hellip; this may cause audio & video issues`,
    tipClass: 'microskill warning',
    hideOnLayout: true,
    autoRemove: 5000,
    force: false,
  },
  userAdded: {
    heading: false,
    content: (a) => `<b>${a.name}</b> has joined the&nbsp;meeting`,

    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
  },
  userWaiting: {
    heading: false,
    content: (a) => `${a.firstName} ${a.lastName} has arrived in the meeting room. Admit them now?`,
    tipClass: 'microskill',
    buttons: [{ text: 'Admit', isResolve: true, value: true }],
  },
  userEjected: {
    heading: false,
    content: (a) => `<b>${a.name}</b> has been removed from the&nbsp;meeting`,

    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
  },
  userLeft: {
    heading: false,
    content: (a) => `<b>${a.name}</b> has left the&nbsp;meeting`,

    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
  },
  userAddedToGroup: {
    heading: false,
    content: (a) => `<b>${a.name}</b> has just arrived and has been automatically added to this break-out group`,

    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
  },
  userRaisedHand: {
    heading: false,
    content: (a) => `<b>${a.name}</b> raised their hand`,
    about: (a) => a.id,
    tipClass: 'microskill',
    hideOnLayout: false,
  },
  userLoweredHand: {
    heading: false,
    content: (a) => `<b>${a.name}</b> put their hand down`,
    about: (a) => a.id,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
  },
  debugOn: {
    heading: false,
    content: 'debug mode on',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 3000,
  },
  debugOff: {
    heading: false,
    content: 'debug mode off',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 3000,
  },
  showHostGuide: {
    heading: false,
    content: `Host URL : ${window.location.href}`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 10000,
  },
  sureYouWantToPromote: {
    heading: false,
    content: (a) => `Are you sure you want to give ${a.toPromote} additional meeting&nbsp;controls?`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  promotionRejected: {
    heading: false,
    content: (a) => `${a.toPromote} has not accepted the meeting controls.`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [{ text: 'Okay', isResolve: true, value: false }],
  },
  acceptPromotion: {
    heading: false,
    content: `OWNER has given you extra meeting controls. Do you want to accept?`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  sureYouWantToDemote: {
    heading: false,
    content: (a) => `Are you sure you want to remove’s ${a.toDemote} additional meeting&nbsp;controls?`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  youHaveBeenDemoted: {
    heading: false,
    content: `Your extra meeting controls have been removed.`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: 5000,
    buttons: [{ text: 'Okay', isResolve: true, value: false }],
  },
  sureYouWantToEject: {
    heading: false,
    content: (a) => `Are you sure you want to <b>remove</b> ${a.toRemove} permanently from the&nbsp;meeting?`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  sureYouWantToStopSharing: {
    heading: false,
    content: (a) => `Are you sure you want to end ${a.presenter}'s screenshare?`,
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'No', isResolve: true, value: false },
      { text: 'Yes', isResolve: true, value: true },
    ],
  },
  enableScreenRecording: {
    heading: false,
    content: 'Screen share disabled. Adjust System Preferences to give bixe "Screen Recording" permission.',
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
    tipClass: 'microskill',
  },
  powerSaving: {
    heading: false,
    content: 'To improve system performance, simplified graphics mode is on.',
    tipClass: 'microskill',
    hideOnLayout: false,
    autoRemove: hide,
    buttons: [{ text: 'Okay', isResolve: true, value: 'okay' }],
  },
  bixeNativeNewVersionAvailable: {
    heading: false,
    content: 'Update available. Restart the app now to get the latest version',
    tipClass: 'microskill',
    hideOnLayout: true,
    autoRemove: false,
    buttons: [
      { text: 'Cancel', isResolve: true, value: false },
      { text: 'Restart', isResolve: true, value: 'restart' },
    ],
  },
  macOsMaximizedScreenDetected: {
    content: 'You are currently in fullscreen mode. Exit fullscreen to share other apps and files.',
    tipClass: 'microskill',
    hideOnLayout: false,
  },
};
