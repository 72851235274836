<template>
  <exit-lobby :page-name="pageName" table="foyer">
    <h2>You have been removed from this meeting</h2>
  </exit-lobby>
</template>

<script>
import ExitLobby from '@/components/ExitLobby';
import { usePageStore } from '@/store/pinia/page';
import utils from '../resources/utils';

export default {
  components: {
    ExitLobby,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  mounted() {
    this.pageStore.update({ pageName: this.pageName });
    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'checkIsOnLatestVersion' });
      ipcApi.send({ name: 'releaseWakeLock' });
    }
  },
};
</script>
<style lang="scss" scoped>
h2 {
  max-width: 11em;
}
</style>
