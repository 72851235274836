import { nM } from '@/legacy';
import { eventEmitter } from '@/services/event-emitter.service';
import { useStorage } from 'vue3-storage';
import { useUsersStore } from '@/store/pinia/users';
import { useFeaturesStore } from '@/store/pinia/features';
import { useMediaStore } from '@/store/pinia/media';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePhaseStore } from '@/store/pinia/phase';
import { usePageStore } from '@/store/pinia/page';
import errorReportingService from '../services/errorReportingService';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });
const localStorage = useStorage({ namespace: '', storage: 'local' });

export default (vueApp) => {
  const localHandlers = {};
  const remoteHandlers = {};
  const usersStore = useUsersStore();
  const featuresStore = useFeaturesStore();
  const mediaStore = useMediaStore();
  const meetingStore = useMeetingStore();
  const phaseStore = usePhaseStore();
  const pageStore = usePageStore();

  function deleteAudioSource(meetingId) {
    sessionStorage.removeStorageSync(`meetings:${meetingId}:audioSource`);
  }

  function deleteVideoSource(meetingId) {
    sessionStorage.removeStorageSync(`meetings:${meetingId}:videoSource`);
  }

  function getDevicesSetting(meetingId) {
    const audioSource = sessionStorage.getStorageSync(`meetings:${meetingId}:audioSource`);
    const videoSource = sessionStorage.getStorageSync(`meetings:${meetingId}:videoSource`);
    const audioOutputSource = sessionStorage.getStorageSync(`meetings:${meetingId}:audioOutputSource`);
    const videoOn = localStorage.getStorageSync('user:videoOn');
    const audioOn = localStorage.getStorageSync('user:audioOn');

    return {
      audioSource: audioSource || null,
      videoSource: videoSource || null,
      audioOutputSource: audioOutputSource || null,
      videoOn,
      audioOn,
    };
  }

  function localVideoStarted({ tile }) {
    const iAmPresenting = usersStore.iAmPresenting;
    const videoSource = mediaStore.videoKind;

    vueApp.$videomanager.bindVideoElement(tile, `video-${tile.boundExternalUserId}`);
    if (iAmPresenting && videoSource === nM.videoSource.CAMERA) {
      vueApp.$videomanager.attach(tile, 'presentationVideo');
    }
  }

  function remoteVideoStarted({ tile }) {
    const userId = nM.parseExternalUserId(tile.boundExternalUserId);
    const isUserPresenting = usersStore.isSpeaking(userId);
    const videoSource = mediaStore.videoKind;

    vueApp.$videomanager.bindVideoElement(tile, `video-${tile.boundExternalUserId}`);
    if (isUserPresenting && videoSource === nM.videoSource.CAMERA) {
      vueApp.$videomanager.attach(tile, 'presentationVideo');
    }
  }

  function remoteScreenStarted({ tile }) {
    vueApp.$videomanager.bindVideoElement(tile, 'presentationVideo');
  }

  localHandlers[nM.videoSource.CAMERA] = localVideoStarted;
  localHandlers[nM.videoSource.SCREEN] = () => null;
  localHandlers[nM.videoSource.NONE] = () => null;

  remoteHandlers[nM.videoSource.CAMERA] = remoteVideoStarted;
  remoteHandlers[nM.videoSource.SCREEN] = remoteScreenStarted;
  remoteHandlers[nM.videoSource.NONE] = () => null;

  vueApp.$videomanager.on('error', (err) => {
    console.error('videomanager|error', err);
    const user = usersStore.me;
    if (user?.id) {
      usersStore.setVideoLoaded(user.id, true);
    }
    if (pageStore.current?.pageName === 'connectivity') {
      usersStore.setVideoLoaded('connectivity', true);
    }
    errorReportingService.reportError(`videoManagerError: ${err?.toString()}`);
  });

  vueApp.$videomanager.on('ready', async () => {
    if (mediaStore.readinessSessionInitialized) {
      return;
    }
    if (vueApp.$connectivity.initialized) {
      const connectivitySettings = vueApp.$connectivity.getSettings();
      const devices = {
        audioSource: connectivitySettings.audioSource,
        audioOn: connectivitySettings.audioOn,
        videoSource: connectivitySettings.videoSource,
        videoOn: connectivitySettings.videoOn,
      };
      await vueApp.$videomanager.startAudioPreview({ devices });
      if (mediaStore.isCameraPermissionGranted) {
        await vueApp.$videomanager.startPreview({ devices, targetElementId: 'connectivityVideo' });
      }
    }
  });

  vueApp.$videomanager.on('stopped', (dispose) => {
    vueApp.$videomanager.disconnect();

    if (dispose) {
      vueApp.$videomanager.once('disconnected', () => {
        vueApp.$videomanager.destroy();
      });
    }
  });

  vueApp.$videomanager.on('connected', () => {
    const meetingId = meetingStore.meetingId;
    const settings = nM.toVideoSettings(featuresStore.settings);
    const devices = getDevicesSetting(meetingId);
    const audioElement = document.getElementById('meeting-audio');
    vueApp.$videomanager.start({ settings, devices, audioElement });
  });

  vueApp.$videomanager.on('video:started', async ({ tile }) => {
    const videoSource = tile.isContent ? nM.videoSource.SCREEN : nM.videoSource.CAMERA;
    if (mediaStore.readinessSessionInitialized) {
      return;
    }
    if (tile.localTile) {
      localHandlers[videoSource]({ tile });
    } else {
      remoteHandlers[videoSource]({ tile });
    }
  });

  vueApp.$videomanager.on('video:stop', ({ userId, videoSource, videoKind }) => {
    const user = usersStore.me;

    if (user.id === userId && videoSource === nM.videoSource.SCREEN) {
      vueApp.$videomanager.stopScreen();
    }

    if (videoSource === nM.videoSource.CAMERA) {
      const externalUserId = nM.buildExternalUserId(userId);
      const tile = Object.values(vueApp.$videomanager.tiles).find((t) => t.boundExternalUserId === externalUserId);
      if (tile) {
        setTimeout(() => {
          vueApp.$videomanager.detach('presentationVideo', true);
        }, 1000);
      }
    }

    if (videoKind === nM.videoKind.PRESENTATION) {
      // vueApp.$videomanager.detach('presentationVideo');
    }
  });

  vueApp.$videomanager.on('video:stopped', ({ tile }) => {
    const userId = nM.parseExternalUserId(tile.boundExternalUserId);
    const isUserPresenting = usersStore.isSpeaking(userId);
    const videoSource = mediaStore.videoKind;
    if (isUserPresenting && videoSource === nM.videoSource.CAMERA) {
      vueApp.$videomanager.detach('presentationVideo'); // only so that the emptied event of video element get called
    }
  });

  vueApp.$videomanager.on('audioInputStreamEnded', () => {
    const meetingId = meetingStore.meetingId;
    deleteAudioSource(meetingId);
  });

  vueApp.$videomanager.on('videoInputStreamEnded', () => {
    const meetingId = meetingStore.meetingId;
    deleteVideoSource(meetingId);
  });

  vueApp.$videomanager.on('contentShareDidStart', () => {
    const iAmPresenting = usersStore.iAmPresenting;
    const videoSource = mediaStore.videoKind;

    if (phaseStore.mirroring.requestedBy) {
      vueApp.$meetingmanager.updateMirroring({ isActive: true, mirroringId: phaseStore.mirroring.mirroringId });
    } else if (iAmPresenting) {
      vueApp.$meetingmanager.switchSource(videoSource, true, nM.screenSharingStatus.SHARING_STARTED);
    }
  });

  vueApp.$videomanager.on('contentShareDidStop', () => {
    const iAmPresenting = usersStore.iAmPresenting;
    const videoSource = mediaStore.videoKind;
    const { requestedBy, stopMirroringRequested } = phaseStore.mirroring;
    const mirroringRequestedByMe = requestedBy === usersStore.me?.id;
    if (mirroringRequestedByMe) {
      if (!stopMirroringRequested) {
        vueApp.$meetingmanager.stopMirroring({ mirroringId: phaseStore.mirroring.mirroringId });
      }
    } else if (iAmPresenting && videoSource === nM.videoSource.SCREEN) {
      vueApp.$meetingmanager.switchSource(nM.videoSource.NONE, false, nM.screenSharingStatus.SHARING_STOPPED);
    }
  });

  vueApp.$videomanager.on('attendeeVolumeChange', (e) => {
    const userId = nM.parseExternalUserId(e.externalUserId);
    eventEmitter.emit('api:audioLevels', {
      userId: userId,
      volume: e.volume,
      signalStrength: e.signalStrength,
      muted: e.muted,
    });
  });

  vueApp.$videomanager.on('activeSpeakerChange', (e) => {
    const ids = e.map((u) => nM.parseExternalUserId(u));
    eventEmitter.emit('api:talkingChange', ids);
  });

  vueApp.$videomanager.on('setGroupsEventStatus', (status, forced = false) => {
    if (status && status <= phaseStore.groupEventStatus) {
      return;
    }
    phaseStore.setGroupEventStatus({ status, forced });
  });
};
