import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';
import { storeToRefs } from 'pinia';
import _ from 'underscore';

export function useApplyOffscreenPages() {
  const frameStore = useFrameStore();
  const usersStore = useUsersStore();

  const { contWidth } = storeToRefs(frameStore);
  const { myId } = storeToRefs(usersStore);

  function applyOffscreenPages({ pos, pages, page, refs, w, nextPos, prevPos, vertical, y }) {
    pages.forEach((line, i) => {
      let users = _.flatten(line);
      if (i < page) {
        // It is before, add everyone's positions except me, just off screen on the left
        users.forEach((id, ri) => {
          if (id !== myId.value) {
            if (vertical) {
              pos[id] = {
                top: refs[ri].top,
                left: contWidth.value + w * 2,
                w: w,
                scale: 0,
                opacity: 0,
              };
            } else {
              pos[id] = {
                left: prevPos,
                top: y,
                w: w,
              };
            }
          }
        });
      } else if (i > page) {
        // It is after, add everyone's positions except me, just off screen on the right
        users.forEach((id, ri) => {
          if (id !== myId.value) {
            if (vertical) {
              pos[id] = {
                top: refs[ri].top,
                //left: x,
                left: contWidth.value + w * 2,
                w: w,
                scale: 0,
              };
            } else {
              pos[id] = {
                left: nextPos,
                top: y,
                w: w,
              };
            }
          }
        });
      }
    });
    return pos;
  }
  return { applyOffscreenPages };
}
