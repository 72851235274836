<template>
  <sidebar-overlay class="spaces">
    <transition-group name="basic" class="spaceList content" tag="div">
      <h2 key="heading">Choose meeting space</h2>
      <label key="nowhere">
        <input class="visuallyHidden" type="radio" v-model="selectedSpace" id="nowhere-space" value="nowhere" />
        <div class="thumb"><img :src="accountStore.baseUrl + spaces.nowhere.thumb" /></div>
        <span class="textLabel">
          <strong> {{ spaces.nowhere.title }} </strong>
        </span>
      </label>
      <button v-if="canChange && selectedSpace === 'nowhere'" class="btn-solid btn-dark-blue-flood btn-accent" @click="publish" key="nowhere-button">
        Change for everyone
        <transition name="basic">
          <app-loading-spinner v-if="waiting" />
        </transition>
      </button>

      <label key="cafe">
        <input class="visuallyHidden" type="radio" v-model="selectedSpace" id="cafe-space" value="cafe" />
        <div class="thumb"><img :src="accountStore.baseUrl + spaces.cafe.thumb" /></div>
        <span class="textLabel"> {{ spaces.cafe.title }} </span>
      </label>
      <button v-if="canChange && selectedSpace === 'cafe'" class="btn-solid btn-dark-blue-flood btn-accent" @click.prevent="publish" key="cafe-button">
        Change for everyone
        <transition name="basic">
          <app-loading-spinner v-if="waiting" />
        </transition>
      </button>

      <label key="zen">
        <input class="visuallyHidden" type="radio" v-model="selectedSpace" id="zen-space" value="zen" />
        <div class="thumb"><img :src="accountStore.baseUrl + spaces.zen.thumb" /></div>
        <span class="textLabel"> {{ spaces.zen.title }} </span>
      </label>
      <button v-if="canChange && selectedSpace === 'zen'" class="btn-solid btn-dark-blue-flood btn-accent" @click.prevent="publish" key="zen-button">
        Change for everyone
        <transition name="basic">
          <app-loading-spinner v-if="waiting" />
        </transition>
      </button>

      <label key="beach">
        <input class="visuallyHidden" type="radio" v-model="selectedSpace" id="beach-space" value="beach" />
        <div class="thumb"><img :src="accountStore.baseUrl + spaces.beach.thumb" /></div>
        <span class="textLabel"> {{ spaces.beach.title }} </span>
      </label>
      <button v-if="canChange && selectedSpace === 'beach'" class="btn-solid btn-dark-blue-flood btn-accent" @click.prevent="publish" key="beach-button">
        Change for everyone
        <transition name="basic">
          <app-loading-spinner v-if="waiting" />
        </transition>
      </button>

      <label key="neon">
        <input class="visuallyHidden" type="radio" v-model="selectedSpace" id="neon-space" value="neon" />
        <div class="thumb"><img :src="accountStore.baseUrl + spaces.neon.thumb" /></div>
        <span class="textLabel"> {{ spaces.neon.title }} </span>
      </label>
      <button v-if="canChange && selectedSpace === 'neon'" class="btn-solid btn-dark-blue-flood btn-accent" @click.prevent="publish" key="neon-button">
        Change for everyone
        <transition name="basic">
          <app-loading-spinner v-if="waiting" />
        </transition>
      </button>
    </transition-group>
  </sidebar-overlay>
</template>

<script>
import AppLoadingSpinner from '@/components/AppLoadingSpinner';
import SidebarOverlay from '@/components/SidebarOverlay';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useAccountStore } from '@/store/pinia/account';
import spaces from '@/resources/spaces';

export default {
  components: {
    SidebarOverlay,
    AppLoadingSpinner,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const accountStore = useAccountStore();
    return { meetingStore, accountStore };
  },
  data() {
    return {
      selectedSpace: false,
      interacted: false,
      spaces: spaces,
      waiting: false,
    };
  },
  computed: {
    canChange() {
      return this.selectedSpace !== this.meetingStore.space;
    },
  },
  mounted() {
    this.selectedSpace = this.meetingStore.space;
  },
  watch: {
    'meetingStore.space': function () {
      this.waiting = false;
    },
  },
  methods: {
    publish() {
      this.$meetingmanager.updateAdvancedSetting('space', this.selectedSpace);
      this.waiting = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.spaces {
  display: block;
}
.content {
  padding-top: rem(87px - 56px);
  display: block;
}
h2 {
  margin: 0em 1em rem(24px) 1em;
}
label {
  display: flex;
  width: 100%;
  position: relative;
}

label + label {
  margin-top: 24px;
}
.thumb {
  position: relative;
  margin: 0 auto;
  &::after {
    border: 4px solid var(--c__accent);
    position: absolute;
    top: rem(-2px);
    left: rem(-2px);
    right: rem(-2px);
    bottom: rem(-2px);
    display: block;
    content: '';
    opacity: 0;
    transition: all 0.3s;
  }
}
img {
  display: block;
  height: rem(118px);
  width: rem(158px);
  background: var(--c__bg-alt);
  &:after {
    height: rem(118px);
    width: rem(158px);
    background: var(--c__bg-alt);
    display: flex;
    justify-content: center;
    align-items: center;
    content: 'oops';
    position: absolute;
    top: 0;
    color: white;
    font-weight: bold;
  }
}

.textLabel {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  left: 50%;
  top: 0;
  margin-left: rem(-79px);
  height: rem(118px);
  width: rem(158px);
  background: var(--c__accent-90);
  color: var(--c__bg);
  opacity: 0;
  transition: opacity 0.3s;
}
input:checked + .thumb:after {
  opacity: 1;
}

label {
  @include triggered {
    .thumb:after,
    .textLabel {
      opacity: 1;
    }
  }
}

button {
  height: rem(50px);
  width: rem(250px);
  margin: rem(18px) auto;
  position: relative;
  .loadingSpinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--c__accent-90);
    :deep(.spin) {
      height: 1em;
      width: 1em;
    }
  }
}
</style>
