<template>
  <div class="guardedButton" :class="[props.inline ? 'inline' : 'block']">
    <div class="buttonWrapper" v-if="needsBlocking">
      <button type="button" :class="$attrs.class" @click="showTip = !showTip" :disabled="disabled">
        <slot />
      </button>
      <bubble-tip name="browserTip" class="" :show="showTip" direction="below" @close="showTip = false">
        Not available on {{ yourBrowser }}.<br />
        Please use <a href="https://www.google.com/chrome/browser/desktop/index.html">Chrome</a> or <a href="https://www.microsoft.com/en-us/edge">Edge</a>
      </bubble-tip>
    </div>
    <button v-if="!needsBlocking && !to" v-bind="$attrs" @click="handleClick" :disabled="disabled">
      <slot />
    </button>
    <router-link v-if="!needsBlocking && to" :to="to"><slot /></router-link>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import BubbleTip from '@/components/BubbleTip.vue';
import { nM } from '@/legacy';

const config = window.__INITIAL_STATE__.config;

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits(['click']);
const props = defineProps(['disabled', 'to', 'inline']);
const router = useRouter();

const showTip = ref(false);
const isFirefox = computed(() => nM.detector.isFirefox());
const isSafari = computed(() => nM.detector.isSafari());
const needsBlocking = computed(() => {
  return (isSafari.value && !config.safari?.allowed) || (isFirefox.value && !config.firefox?.allowed);
});
const yourBrowser = computed(() => {
  if (isFirefox.value) {
    return 'Firefox';
  }
  if (isSafari.value) {
    return 'Safari';
  }
  return 'this browser';
});

function handleClick() {
  if (props.to) {
    router.push(props.to);
  } else {
    emit('click');
  }
}
</script>
<style lang="scss" scoped>
.inline {
  &.guardedButton,
  .buttonWrapper {
    display: inline-block;
  }
  button {
    display: inline;
    width: auto;
    height: auto;
    margin: 0;
    color: var(--c__accent);
    @include triggered {
      text-decoration: underline;
    }
  }
}

.buttonWrapper {
  position: relative;
}
:deep(.content) {
  margin-bottom: 0;
}
</style>
