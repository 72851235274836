<template>
  <button class="stealthButton" v-bind="$attrs">
    <span><slot></slot></span>
  </button>
</template>
<style lang="scss" scoped>
button {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  border: none;
  text-align: right;
  opacity: 0;
  transition: opacity 0.3s linear 0.1s;

  & > span {
    font-weight: bold;
    visibility: visible;
  }

  &:focus {
    opacity: 1;
  }

  &:focus:not(:focus-visible) {
    opacity: 0;
  }
}
</style>
