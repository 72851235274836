<template>
  <div>
    <transition name="basic">
      <div v-if="choiceHappening">
        <presentation-how-to-pane v-if="iAmPresenting || (isInputPhase && iAmSpeaking)" @onPresentationModeUpdated="onPresentationModeUpdated" />
        <presentation-setting-up-pane v-else :style="sizingStyle" />
      </div>
    </transition>

    <transition name="basic">
      <div
        v-if="!choiceHappening && !phaseStore.requestingPhase"
        id="presentationSpace"
        class="space presentation presentationSpace"
        :style="videoContainerStyle"
      >
        <div id="presentation" class="presentationBox extra">
          <div id="presentationVideos" class="videos" ref="videos" :class="speakerMuted ? 'muted' : ''" :style="sizingStyle">
            <div id="presentationVideoHolder" class="presentationVideoHolder" :class="{ isScreen: iAmSharingScreen || iAmMirroring }">
              <div class="overlayHolder">
                <div v-if="videoH && videoW && !(screenSharing && iAmSharingScreen)" class="overlay" :style="overlaySizing" />
              </div>
              <transition name="basic">
                <div v-if="screenSharing && !iAmSharingScreen" class="loadingScreenshare">
                  <span class="speakerName">SPEAKER</span> is starting a screen sharing session.
                </div>
              </transition>
              <video id="presentationVideo" class="presentationVideo" ref="video" @loadeddata="checkOverlaySize" @emptied="empty" />
            </div>

            <transition name="basic">
              <div v-if="isAvatarOrInitialsVisible" id="presenterAvatar" class="presenterAvatar userPic">
                <img v-if="speaking.picture_url" :src="speaking.picture_url" :alt="speaking.first_name" />
                <span class="initials" v-else>
                  <app-scaled-text>
                    {{ initials }}
                  </app-scaled-text>
                </span>
              </div>
            </transition>

            <div class="subscriber" />
          </div>

          <div v-if="isCTAVisible && (iAmSpeaking || iAmModerator || iAmMirroring)" class="speakerControls">
            <div class="content">
              <button class="video presentMuteVideo" @click="muteVideoDebounced" ref="muteVideo" @keyup.space="(e) => e.preventDefault()">
                <app-icon icon="camera" />
              </button>
              <button class="mic presentMute" @click="muteAudioDebounced" ref="mute" @keyup.space="(e) => e.preventDefault()">
                <app-icon icon="mic" />
              </button>
              <button v-if="iAmSpeaking || iAmMirroring" class="settings" @click="handleSettings" ref="settings" @keyup.space="(e) => e.preventDefault()">
                <app-icon icon="settings-control-present" />
              </button>
            </div>
          </div>
        </div>

        <transition name="basic">
          <fullscreen-bar v-if="fullscreen && isInputPhaseOrMirroring" @exit="unfull" :isInputPhase="isInputPhase" />
        </transition>

        <transition name="basic">
          <top-alerts v-if="fullscreen" />
        </transition>
        <transition name="basic">
          <div v-if="(iAmSharingScreen && isVideoSourceActive) || iAmMirroring" class="youAreSharing sharingButton" ref="iAmSharing">
            <p>You are currently sharing your screen</p>
            <button type="button" class="btn btn-border stopSharingButton" @click="stopSharingScreen" @keyup.space="(e) => e.preventDefault()">
              Stop Sharing
            </button>
          </div>
        </transition>
      </div>
    </transition>

    <transition name="basic">
      <select-shareable-screen-overlay
        v-if="!choiceHappening && isScreenSelectionModalVisible"
        :resources="shareableScreens"
        @close="onCloseModal"
        @onSelect="onScreenSelected"
      />
    </transition>

    <transition name="basic">
      <button
        v-if="!choiceHappening && isGoFullScreenBtnVisible"
        class="fullScreen btn-solid btn-action btn-blue-alt-flood"
        :class="[screenSharing && meetingStore.initTitleCardShown && usersStore.firstShown ? 'screenIsShared' : '']"
        data-button-name="fullScreen"
        :style="{ top: frameHeight + 'px' }"
        @click="toggleFullscreen"
        @keyup.space="(e) => e.preventDefault()"
      >
        Enter Fullscreen
      </button>
    </transition>

    <transition name="basic">
      <avatar-dock v-if="!choiceHappening && fullscreen && pos.dockLeft" />
    </transition>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import screenfull from 'screenfull';
import FullscreenBar from '@/components/FullscreenBar';
import PresentationHowToPane from '@/components/PresentationHowToPane';
import PresentationSettingUpPane from '@/components/PresentationSettingUpPane';
import AppIcon from '@/components/AppIcon';
import TopAlerts from '@/components/TopAlerts.vue';
import AvatarDock from '@/components/AvatarDock.vue';
import SelectShareableScreenOverlay from '@/components/SelectShareableScreenOverlay.vue';
import { nM } from '@/legacy';
import utils from '../resources/utils';
import AppScaledText from '@/components/AppScaledText.vue';
import { localStorageKeysMap } from '../shared/constants';

import { useMeetingStore } from '@/store/pinia/meeting';
import { useMessagesStore } from '@/store/pinia/messages';
import { useMediaStore } from '@/store/pinia/media';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useSidebarStore } from '@/store/pinia/sidebar';

import { INPUT_RATIO } from '@/resources/constants/frame-constants';

export default {
  setup() {
    const meetingStore = useMeetingStore();
    const messagesStore = useMessagesStore();
    const mediaStore = useMediaStore();
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    const sidebarStore = useSidebarStore();
    return { mediaStore, meetingStore, messagesStore, frameStore, phaseStore, usersStore, sidebarStore };
  },
  components: {
    FullscreenBar,
    PresentationHowToPane,
    PresentationSettingUpPane,
    AppIcon,
    TopAlerts,
    AvatarDock,
    AppScaledText,
    SelectShareableScreenOverlay,
  },
  props: ['animating'],
  data() {
    return {
      hasSpeakerChosenHowToShare: false,
      source: false,
      videoH: 0,
      videoW: 0,
      videoEmpty: true,
      muteVideoDebounced: null,
      muteAudioDebounced: null,
      isCTAVisible: true,
      isScreenSelectionModalVisible: false,
      shareableScreens: [],
      isStopSharingButtonVisible: false,
    };
  },
  mounted() {
    const _this = this;

    this.muteVideoDebounced = _.debounce(this.muteVideo, 500);
    this.muteAudioDebounced = _.debounce(this.mute, 500);

    this.$ee.on('bus:videoSourceChanged', _this.switchInput);
    this.$ee.on('bus:user:stage:on', _this.userOnStage);
    this.$ee.on('bus:user:stage:off', _this.userOffStage);
    this.$ee.on('bus:settingsPlease', _this.unfull);

    this.$videomanager.on('error', _this.videoManagerError);
  },
  beforeUnmount() {
    const _this = this;

    this.$ee.off('bus:videoSourceChanged', _this.switchInput);
    this.$ee.off('bus:user:stage:on', _this.userOnStage);
    this.$ee.off('bus:user:stage:off', _this.userOffStage);
    this.$ee.off('bus:settingsPlease', _this.unfull);
    this.$videomanager.off('error', _this.videoManagerError);
  },
  computed: {
    ...mapPiniaState(useMediaStore, { isVideoSourceActive: 'videoSourceActive', videoSource: 'videoKind' }),
    ...mapPiniaState(useUsersStore, { pos: 'positionDetails' }),
    ...mapPiniaState(useFrameStore, ['fullscreen']),
    ...mapPiniaState(useMediaStore, ['stopScreenSharingRequestTs', 'screenSharing']),
    ...mapPiniaState(useUsersStore, [
      'me',
      'iAmSpeaking',
      'iAmModerator',
      'speaking',
      'mirroring',
      'iAmPresenting',
      'iAmSharingScreen',
      'otherPresenting',
      'myId',
    ]),
    choiceHappening() {
      const { requestedBy, isActive } = this.phaseStore.mirroring;
      if (this.isInputPhase) {
        return !this.isVideoSourceActive && !this.hasSpeakerChosenHowToShare;
      } else if (requestedBy) {
        return requestedBy !== this.me.id && !isActive;
      }
      return false;
    },
    iAmMirroring() {
      return this.phaseStore.mirroring.requestedBy === this.me.id;
    },
    isMyPresentation() {
      return this.iAmSharingScreen || this.iAmMirroring;
    },
    isAvatarOrInitialsVisible() {
      if (this.iAmSharingScreen || !this.speaking || !this.speaking.id) {
        return false;
      }
      if (this.source === nM.videoSource.SCREEN) {
        return false;
      }
      if (this.source === nM.videoSource.CAMERA && !this.speaking.video_muted) {
        return false;
      }
      if (this.phaseStore.someoneIsMirroring) {
        return false;
      }
      return true;
    },
    speakerMuted() {
      return this.speaking && this.speaking.muted;
    },
    videoContainerClass() {
      return this.screenSharing ? 'screenShowing' : 'cameraShowing';
    },
    avatarClass() {
      return this.screenSharing ? 'screenShowing' : 'cameraShowing';
    },
    initials() {
      if (this.speaking && this.speaking.first_name) {
        return utils.desanitizeString(this.speaking.first_name[0].toUpperCase()) + utils.desanitizeString(this.speaking.last_name[0].toUpperCase());
      } else {
        return '';
      }
    },
    frameHeight() {
      return this.fullscreen ? this.frameStore.contHeight : INPUT_RATIO * this.frameStore.contHeight;
    },
    frameAspect() {
      return this.frameStore.contWidth / this.frameHeight;
    },
    videoAspect() {
      return this.videoW / this.videoH;
    },
    overlaySizing() {
      if (this.frameAspect > this.videoAspect) {
        // base on height
        if (this.fullscreen) {
          return {
            height: this.frameStore.viewportH + 'px',
            width: this.frameStore.viewportH * this.videoAspect + 'px',
          };
        } else {
          return {
            height: this.frameHeight + 'px',
            width: this.frameHeight * this.videoAspect + 'px',
          };
        }
      } else {
        return {
          height: this.frameStore.contWidth / this.videoAspect + 'px',
          width: this.frameStore.contWidth + 'px',
        };
        // base on width
      }
    },
    sizingStyle() {
      if (!this.fullscreen) {
        return {
          height: this.frameHeight + 'px',
        };
      } else {
        return {};
      }
    },
    videoContainerStyle() {
      let opacity = 0;
      if (this.isInputPhase && this.isVideoSourceActive) {
        opacity = 1;
      } else if (this.phaseStore.mirroring.isActive) {
        opacity = 1;
      }
      return { ...this.sizingStyle, opacity };
    },
    isInputPhase() {
      return this.phaseStore.currentPhase === 'input';
    },
    isInputPhaseOrMirroring() {
      return this.isInputPhase || this.phaseStore.mirroring?.requestedBy;
    },
    shouldGoFullScreen() {
      // `isVideoSourceActive` - will be set as false before screen selection, once user select
      // which screen s/he would like to present, this will turn true.
      // check $meetingmanager.switchSource for more info
      const { requestedBy, isActive } = this.phaseStore.mirroring;
      const inputPhaseConditions = this.isInputPhase && this.isVideoSourceActive && this.isCTAVisible && !this.iAmSpeaking;
      const screenMirroringConditions = requestedBy && requestedBy !== this.me.id && isActive;
      return screenMirroringConditions || inputPhaseConditions;
    },
    isGoFullScreenBtnVisible() {
      if (!this.fullscreen && this.shouldGoFullScreen) {
        return true;
      }
      return false;
    },
  },
  watch: {
    'phaseStore.mirroring.requestedBy': async function (nv, ov) {
      const iJustEndedPresentation = !nv && ov === this.me.id;
      this.source = false;
      if (iJustEndedPresentation) {
        await this.$videomanager.stopScreen();
        this.$videomanager.detach('presentationVideo');
      }
    },
    'phaseStore.isPresentation': async function (nv, ov) {
      const isPresentationJustStarted = this.phaseStore.mirroring.requestedBy;
      const isPresentationJustEnded = !nv && ov;
      if (nv && isPresentationJustStarted) {
        this.source = false;
        this.switchInput(this.phaseStore.mirroring.sourceType);
      }
      if (isPresentationJustEnded) {
        this.unfull(true);

        this.messagesStore.removeAlert({ messageName: 'macOsMaximizedScreenDetected' });
        this.hasSpeakerChosenHowToShare = false;
        if (utils.isOpenedWithinElectronShell() && this.isStopSharingButtonVisible) {
          await ipcApi.send({ name: 'hideStopSharingWindow' });
          await ipcApi.send({ name: 'hideAvatarDockDrawer' });
          this.isStopSharingButtonVisible = false;
        }
      }
    },
    shouldGoFullScreen: {
      immediate: true,
      handler: async function (nv) {
        if (nv && utils.isOpenedWithinElectronShell()) {
          await ipcApi.send({ name: 'goFullScreen' });
          this.frameStore.setFullscreen(true);
        }
      },
    },
    screenSharing: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('screenShowing');
          document.body.classList.remove('cameraShowing');
        } else {
          document.body.classList.add('cameraShowing');
          document.body.classList.remove('screenShowing');
          this.unfull();
        }
      },
    },
    isMyPresentation: {
      immediate: true,
      handler(nv) {
        if (nv) {
          document.body.classList.add('iAmSharingScreen');
        } else {
          document.body.classList.remove('iAmSharingScreen');
        }
      },
    },
    iAmPresenting: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.switchInput(this.source);
        }
      },
    },
    fullscreen(nv) {
      if (nv) {
        document.getElementById('mainFrame').classList.add('fullscreenOn');
        document.body.classList.add('isFullScreen');
      } else {
        document.getElementById('mainFrame').classList.remove('fullscreenOn');
        document.body.classList.remove('isFullScreen');
      }
    },
    stopScreenSharingRequestTs() {
      const { mirroringId, stopMirroringRequested } = this.phaseStore.mirroring;
      if (this.isInputPhase || (mirroringId && !stopMirroringRequested)) {
        this.stopSharingScreen();
      }
    },
  },
  methods: {
    onCloseModal() {
      this.messagesStore.removeAlert({ messageName: 'macOsMaximizedScreenDetected' });
      this.isScreenSelectionModalVisible = false;
      if (this.isInputPhase) {
        this.stopSharingScreen();
      } else {
        this.$meetingmanager.stopMirroring({ mirroringId: this.phaseStore.mirroring.mirroringId });
      }
    },
    onPresentationModeUpdated({ presentationMode }) {
      this.hasSpeakerChosenHowToShare = true;
      this.switchInput(presentationMode);
    },
    mute() {
      let user = this.speaking;
      if (this.isInputPhase) {
        user = this.speaking;
        if (!this.iAmSpeaking && this.speaking.muted) {
          this.messagesStore.addAlert('cannotUnmuteMutedUser');
          return;
        }
      }
      if (this.phaseStore.mirroring.requestedBy) {
        user = this.mirroring;
      }

      this.$ee.emit('nM:userButton:mute', {
        id: this.myId,
        about: user.id,
        iAmModerator: this.iAmModerator,
        muted: user.muted,
        sysmuted: user.muted_by_system,
        unmuteInstantly: true,
      });
      this.$refs.mute.blur();
    },
    async muteVideo() {
      let user = this.speaking;
      if (this.isInputPhase) {
        if (!this.iAmSpeaking && this.speaking.video_muted) {
          this.messagesStore.addAlert('cannotUnVideoMuteMutedUser');
          return;
        }
      }
      if (this.phaseStore.mirroring.requestedBy) {
        user = this.mirroring;
      }

      const muteUnmuteVideoParams = {
        id: this.myId,
        about: user.id,
        iAmModerator: this.iAmModerator,
        muted: user.video_muted,
        sysmuted: user.video_muted_by_system,
      };
      if (this.isInputPhase) {
        const externalUserId = nM.buildExternalUserId(user.id);
        const tile = Object.values(this.$videomanager.tiles).find((t) => t.boundExternalUserId === externalUserId && !t.isContent);
        const targetElementId = this.iAmSharingScreen ? `video-user-${user.id}` : 'presentationVideo';
        if (tile && user.video_muted) {
          this.$videomanager.attach({ targetElementId, tile });
          await this.$videomanager.bindVideoElement(tile, targetElementId);
        }
      }
      this.$ee.emit('nM:userButton:muteVideo', muteUnmuteVideoParams);
      this.$refs.muteVideo.blur();
    },
    startSharingScreen() {
      this.switchInput('screen');
    },
    stopSharingScreen() {
      if (this.isInputPhase) {
        this.hasSpeakerChosenHowToShare = false;
        this.switchInput('none');
      } else {
        const mirroringDetails = this.phaseStore.mirroring;
        if (mirroringDetails.stopMirroringRequested) {
          // already requested, user clicked this stop sharing button twice?, ignore the 2nd event.
          return;
        }
        this.phaseStore.updateMirroringDetails(mirroringDetails.mirroringId, { stopMirroringRequested: true });
        this.$meetingmanager.stopMirroring({ mirroringId: mirroringDetails.mirroringId });
      }
    },
    async stopScreenSharingStream(user) {
      if (this.myId === user.id) {
        if (utils.isOpenedWithinElectronShell() && this.isStopSharingButtonVisible) {
          await ipcApi.send({ name: 'hideStopSharingWindow' });
          await ipcApi.send({ name: 'hideAvatarDockDrawer' });
          this.isStopSharingButtonVisible = false;
        }
        await this.$videomanager.stopScreen();
      }
    },

    async switchInput(source, force) {
      if ((!force && !this.phaseStore.isPresentation) || source === this.source) return; // jshint ignore:line
      let presenterId = this.isInputPhase ? this.phaseStore.currentPhaseData.presentation_by : this.phaseStore.mirroring.requestedBy;
      this.source = source;
      if (source === 'screen') {
        if (presenterId === this.me.id) {
          this.hasSpeakerChosenHowToShare = true;
          if (this.isInputPhase) {
            this.$meetingmanager.switchSource(source, false);
          }
          try {
            if (utils.isOpenedWithinElectronShell()) {
              await this.showScreenPickerModal();
            } else {
              await this.$videomanager.startScreen({ targetElementId: 'presentationVideo' });
            }
          } catch (e) {
            this.videoManagerError(e);
          }
        }
      } else if (source === 'camera') {
        this.$meetingmanager.switchSource(source, true);
        await this.switchFromScreenToCamera(this.speaking);
      } else if (source === 'none') {
        this.hasSpeakerChosenHowToShare = false;
        this.$meetingmanager.switchSource(source, false);
        await this.stopScreenSharingStream(this.speaking);
      }
    },

    async switchFromScreenToCamera(user) {
      await this.stopScreenSharingStream(user);
      if (!user.video_muted && !user.video_muted_by_system) {
        const externalUserId = nM.buildExternalUserId(user.id);
        const tile = Object.values(this.$videomanager.tiles).find((t) => t.boundExternalUserId === externalUserId && !t.isContent);
        if (tile) {
          const targetElementId = 'presentationVideo';
          this.$videomanager.attach(tile, targetElementId);
          this.$videomanager.bindVideoElement(tile, targetElementId);
        }
      }
    },

    async showScreenPickerModal() {
      try {
        const response = await ipcApi.send({ name: 'getShareableSources' });
        const parsed = JSON.parse(response);
        if (parsed.SCREEN_RECORDING_PERMISSION_NEEDED) {
          utils.storage.ls.setItem(localStorageKeysMap.RELAUNCH_MEETING_ID, this.meetingStore.meetingId);
          this.messagesStore.addAlert('enableScreenRecording').finally(() => {
            this.stopSharingScreen();
          });
          if (this.isInputPhase) {
            this.$meetingmanager.switchSource('camera', true);
          }
          setTimeout(() => {
            ipcApi.send({ name: 'showSystemPref' });
          }, 3000);
          return;
        }
        if (!parsed.sources.length) {
          return;
        }
        if (parsed.isInMaximizedMode) {
          this.messagesStore.addAlert('macOsMaximizedScreenDetected');
        }
        this.isScreenSelectionModalVisible = true;
        this.shareableScreens = parsed.sources.filter((item) => item.name.toLowerCase() !== 'bixe');
        await ipcApi.send({
          name: 'setDrawerWindowAddress',
          address: `${window.location.href}/stopSharing`,
        });
      } catch (err) {
        console.log('unable to getShareableSources');
        console.log(err);
      }
    },

    async onScreenSelected({ selectedItemDetails }) {
      if (selectedItemDetails.id) {
        this.messagesStore.removeAlert({ messageName: 'macOsMaximizedScreenDetected' });
        this.isScreenSelectionModalVisible = false;
        if (this.isInputPhase) {
          this.$meetingmanager.switchSource(this.source, true);
        }
        await this.$videomanager.startScreen({ targetElementId: 'presentationVideo', sourceId: selectedItemDetails.id });
        this.isStopSharingButtonVisible = true;
        await ipcApi.send({
          name: 'setAvatarsDockDrawerAddress',
          address: `${window.location.href}/avatarsDock`,
        });
        setTimeout(async () => {
          /* wait for 2 seconds, selected screen starts presenting, before minimizing main window */
          await ipcApi.send({ name: 'minimizeWindow' });
        }, 2000);
      }
    },

    async toggleFullscreen() {
      if (utils.isOpenedWithinElectronShell()) {
        this.frameStore.setFullscreen(true);
        await ipcApi.send({ name: 'goFullScreen' });
        return;
      }
      if (screenfull.enabled || screenfull.isEnabled) {
        const mainFrame = document.getElementById('mainFrame');
        screenfull.toggle(mainFrame).catch(() => {});
      }
    },
    triggerFull() {
      if ((screenfull.enabled || screenfull.isEnabled) && !this.fullscreen) {
        const mainFrame = document.getElementById('mainFrame');
        screenfull.toggle(mainFrame).catch(() => {});
      }
    },
    userOnStage(userId) {
      if (this.myId === userId) {
        this.source = this.videoSource;
        this.switchInput(this.source);
      }

      if (this.myId !== userId) {
        this.source = this.videoSource;
      }
    },
    userOffStage(userId) {
      this.isCTAVisible = false;
      setTimeout(() => {
        this.isCTAVisible = true;
      }, 2500);
      this.$videomanager.emit('video:stop', {
        userId: userId,
        videoKind: nM.videoKind.PRESENTATION,
        videoSource: this.source,
      });
      this.source = false;
    },
    async unfull(force) {
      if (utils.isOpenedWithinElectronShell()) {
        this.frameStore.setFullscreen(false);
        await ipcApi.send({ name: 'exitFullScreen' });
        return;
      }
      if ((screenfull.enabled || screenfull.isEnabled) && this.fullscreen) {
        const mainFrame = document.getElementById('mainFrame');
        screenfull.toggle(mainFrame).catch(() => {});
      }
      if (force) {
        this.frameStore.setFullscreen(false);
      }
    },
    videoManagerError(err) {
      const { name, message } = err;
      if (name.match(/NotAllowedError/i)) {
        this.source = false;
        const isScreenRecordingDisabled = message.match(/permission denied by system/i);
        const screenSelectionModalCancelled = message.match(/Permission denied/i);
        if (isScreenRecordingDisabled) {
          this.messagesStore.addAlert('enableScreenRecording').finally(() => {
            this.stopSharingScreen();
          });
          if (utils.isOpenedWithinElectronShell()) {
            utils.storage.ls.setItem(localStorageKeysMap.RELAUNCH_MEETING_ID, this.meetingStore.meetingId);
          }
        } else if (screenSelectionModalCancelled) {
          this.stopSharingScreen();
        }
      } else {
        console.error('Unhandled video manager err', err);
        this.source = false;
      }
    },
    empty() {
      this.videoH = 0;
      this.videoW = 0;
      this.videoEmpty = true;
    },
    checkOverlaySize() {
      this.videoEmpty = false;
      if (this.$refs.video) {
        this.videoH = this.$refs.video.videoHeight;
        this.videoW = this.$refs.video.videoWidth;
      }
    },
    handleSettings() {
      this.sidebarStore.toggleSettings();
    },
  },
};
</script>
<style lang="scss" scoped>
$presentation-button-size: em(42px);

$presentation-border-radius: 0.25em;

.presentationSpace {
  display: block;
  position: absolute;
  left: 0;
  top: rem($top-timer-mini-height);
  height: math.div(408px, 760px) * 100vh;
  height: calc(var(--vh, 1vh) * math.div(408px, 760px) * 100);
  width: 100%;
  transform: translateX(0) translateY(0);
  overflow: hidden;
  //z-index: 15; // Was 23, not sure why, check this
  z-index: auto;
  transition: height 0.3s ease;

  .input-animateOut {
    opacity: 0;
  }

  .presentationBox {
    margin: 0 auto;
    position: relative;
    border-bottom: 1px solid #efefef;
    background: var(--grad__presentation), var(--c__bg-alt);

    &::after {
      display: none;
      content: '';
      text-align: center;
      @include type-size(medium);
      color: var(--c__bg);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      padding: 1em;
      z-index: 10;
      background: var(--c__accent-70);
    }
    .speakerIsMuted.cameraIsShowing &::after {
      display: block;
    }
  }

  .userPic {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    transition: opacity 300ms;
    &.screenShowing {
      opacity: 0;
    }
    .initials {
      position: absolute;
      border-radius: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      :deep(svg) {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        margin: 0 auto;
        :deep(text) {
          font-weight: 400;
          color: var(--c__bg);
          fill: var(--c__bg);
        }
      }
    }
    img {
      height: 100%;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }

  .sharingButton {
    position: absolute;
    z-index: 10;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--c__text);
    text-align: center;
    width: 100%;
    background: transparent;
    p {
      @include type-size('large');
    }
    button {
      padding: math.div(13em, 18) math.div(25em, 18);
      @include type-size('medium');
      width: rem(300px);
    }
  }

  .youAreSharing {
    top: 60%;
  }

  .youAreStartingSharing {
    top: 20%;
  }

  .videos {
    position: relative;
    margin: 0 auto;
  }

  .presentationVideoHolder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    border: 0px var(--c__active-0) solid;
    &.isScreen video {
      height: 50%;
      width: auto;
      border: 8px var(--c__active) solid;
      left: 50%;
      bottom: 40%;
      transform: translateX(-50%) !important;
      position: absolute;
    }
  }

  .overlayHolder {
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
  }

  .overlay {
    border-style: solid;
    border-color: var(--c__accent-70);
    border-width: rem(0px);
    transition: border 300ms ease;
  }

  .muted .overlay {
    border-width: rem(24px);
  }

  .loadingScreenshare {
    width: 100%;
    font-weight: 100;
    font-size: 2em;
    top: 4em;
    position: absolute;
    text-align: center;
    color: var(--c__bg);
    transition: all 300ms;
    opacity: 0;
    .iAmSpeaking & {
      display: none !important;
    }
  }

  .presentationVideo {
    width: 100%;
    height: 100%;
  }

  .presentationVideos {
    transition: height 0.3s ease;
  }

  .isFullScreen & {
    .presentationBox {
      max-width: 100%;
      width: 100%;
    }
    .presentationVideo {
      border-radius: 0;
      max-width: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .videos {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  button {
    z-index: 100;
  }

  .speakerControls button {
    position: absolute;
    top: rem(15px);
    transition: all 300ms;
    color: var(--c__text);
    height: rem(40px);
    width: rem(40px);
    display: block;
    text-align: center;
    background: var(--c__bg);
    border-radius: 50%;
    border: 1px solid var(--c__text);
    @include triggered {
      color: var(--c__accent);
    }
  }

  .speakerControls {
    :deep(.doubleStrike) {
      display: none;
    }

    :deep(.stroke),
    :deep(.next .fill) {
      stroke-width: 1px;
    }
  }

  .iAmSpeaking.iAmMuted & .mic,
  .iAmSpeaking.iAmVideoMuted & .video,
  .iAmMirroring.iAmMuted & .mic,
  .iAmMirroring.iAmVideoMuted & .video {
    color: var(--c__bg);
    background: var(--c__accent);
    @include triggered {
      color: var(--c_text);
    }
  }

  .presentMute {
    left: rem(15px);
    :deep(svg) {
      margin-top: rem(3px);
    }
  }

  .presentMuteVideo {
    left: rem(65px);
    :deep(svg) {
      margin-top: rem(5px);
    }
  }

  .settings {
    left: rem(115px);
    padding: 0;
  }

  .presentScreen {
    left: rem(165px);
    padding: 0;
    :deep(svg) {
      margin-top: rem(1px);
    }
    :deep(.stroke) {
      stroke-width: (math.div(1, 40) * 83);
    }
  }
}

button.fullScreen {
  position: absolute;
  margin-top: rem(-57px);
  left: 50%;
  width: rem(172px);
  height: rem(50px);
  padding: 0;
  margin-left: rem(math.div(-172px, 2));
  z-index: 100;
}
.isFullScreen .presentationSpace {
  top: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>
