<template>
  <section class="setupComplete page" data-page-name="setupComplete">
    <div class="content">
      <div class="iconHolder">
        <avatars-extra-emotes direction="success" />
        <title-block :wordless="true" />
      </div>
      <h2>Setup complete!</h2>
      <p v-if="isTeam" class="message">We have let your team know they can create <b>bixe</b> meetings too.</p>
      <p v-if="!isTeam" class="message">Get ready to experience purposeful, timely and creative meetings.</p>
      <button class="btn btn-border btn-accent btn-solid-bg" @click="next">Launch&nbsp;<b>bixe</b></button>
    </div>
  </section>
</template>

<script>
import { useAccountStore } from '@/store/pinia/account';
import { usePageStore } from '@/store/pinia/page';
import _ from 'underscore';
import AvatarsExtraEmotes from '@/components/AvatarsExtraEmotes';
import utils from '../resources/utils';
import TitleBlock from '@/components/TitleBlock';

export default {
  components: {
    AvatarsExtraEmotes,
    TitleBlock,
  },
  props: ['pageName'],

  setup() {
    const pageStore = usePageStore();
    const accountStore = useAccountStore();
    return { accountStore, pageStore };
  },
  data() {
    return {
      initialState: false,
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });

    _.delay(this.addParticle, 400);
    _.delay(this.addParticle, 600);
    _.delay(this.addParticle, 800);
    _.delay(this.addParticle, 1100);
    _.delay(this.addParticle, 1500);
  },
  computed: {
    has() {
      return this.accountStore.permissions;
    },
    isTeam() {
      return this.has.account && this.has.type === 'owner' && (this.has.account.bixe_ea_account === 2 || this.has.account.bixe_ea_account === 4);
    },
  },
  methods: {
    next() {
      if (!this.isOpenedWithinElectronShell) {
        this.$router.push('/app/invite').catch(() => null);
        return;
      }
      let url = this.$route.query.redirectUrl || '/welcome';
      this.$router.push(url).catch(() => null);
    },
    addParticle() {
      this.$ee.emit('nM:emoteEmitter:success', 'yes');
    },
  },
};
</script>
