import initApiDispatcher from '../dispatchers/api.dispatcher';
import initConnectivityDispatcher from '../dispatchers/connectivity.dispatcher';
import initMeetingManagerDispatcher from '../dispatchers/meeting-manager.dispatcher';
import initVideoManagerDispatcher from '../dispatchers/video-manager.dispatcher';

export default (nM) => {
  nM.dispatchers = {
    init: (vueApp) => {
      initApiDispatcher(vueApp);
      initConnectivityDispatcher(vueApp);
      initMeetingManagerDispatcher(vueApp);
      initVideoManagerDispatcher(vueApp);
    },
  };
};
