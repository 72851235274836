import BaseReadinessChecker from './baseReadinessChecker';
import {
  ConsoleLogger,
  LogLevel,
  DefaultMeetingReadinessChecker,
  CheckNetworkTCPConnectivityFeedback,
  CheckNetworkUDPConnectivityFeedback,
  CheckAudioInputFeedback,
  CheckVideoInputFeedback,
  CheckContentShareConnectivityFeedback,
  CheckAudioOutputFeedback,
} from 'amazon-chime-sdk-js';
import { readinessCheckResponses } from '../../shared/constants';

export default class ReadinessCheckerService extends BaseReadinessChecker {
  constructor() {
    super();

    this.meetingSession = null;
    this.meetingReadinessChecker = null;
    this.chimeLogger = new ConsoleLogger('ChimeMeetingReadinessLogs', LogLevel.WARN);
  }

  onMeetingSessionInitialized(session = {}) {
    this.meetingSession = session;
    this.meetingReadinessChecker = new DefaultMeetingReadinessChecker(this.chimeLogger, this.meetingSession);
  }

  async checkNetworkConnectivity() {
    return Promise.all([
      await this.meetingReadinessChecker.checkNetworkTCPConnectivity(),
      await this.meetingReadinessChecker.checkNetworkUDPConnectivity(),
    ]).then(([TCPResp, UDPResp]) => {
      const resp = [CheckNetworkTCPConnectivityFeedback[TCPResp], CheckNetworkUDPConnectivityFeedback[UDPResp]];
      return { status: resp.every((r) => r === readinessCheckResponses.SUCCEEDED) };
    });
  }

  async checkAudioOutput(deviceId) {
    const resp = await this.meetingReadinessChecker.checkAudioOutput(deviceId);
    return { status: CheckAudioOutputFeedback[resp] === readinessCheckResponses.SUCCEEDED };
  }

  async checkAudioInput(deviceId) {
    const resp = await this.meetingReadinessChecker.checkAudioInput(deviceId);
    return { status: CheckAudioInputFeedback[resp] === readinessCheckResponses.SUCCEEDED };
  }

  async checkVideoInput(deviceId) {
    const resp = await this.meetingReadinessChecker.checkVideoInput(deviceId);
    return { status: CheckVideoInputFeedback[resp] === readinessCheckResponses.SUCCEEDED };
  }

  async checkContentShareConnectivity() {
    const resp = await this.meetingReadinessChecker.checkContentShareConnectivity();
    return { status: CheckContentShareConnectivityFeedback[resp] === readinessCheckResponses.SUCCEEDED };
  }
}
