import { LogLevel, ConsoleLogger, POSTLogger, MultiLogger } from 'amazon-chime-sdk-js';

function getPOSTLogger({ url, meetingId, attendeeId, logLevel, additionalMeta }) {
  const options = {
    url,
    logLevel,
    metadata: {
      ...(additionalMeta || {}),
      meetingId,
      attendeeId,
    },
    headers: {
      'content-type': 'application/json',
    },
  };
  return new POSTLogger(options);
}

class MeetingEventsLogger {
  postUrl;
  meetingSessionLogger;
  realtimeEventsPostLogger;

  metaData = {
    attendeeId: null,
    meetingId: null,
    externalAttendeeId: null,
    externalMeetingId: null,
    pageName: null,
  };

  MEETING_EVENTS = {
    info: [
      'meetingStartRequested',
      'meetingStartSucceeded',
      'meetingEnded',
      'audioInputSelected',
      'videoInputSelected',
      'audioInputUnselected',
      'videoInputUnselected',
      'meetingReconnected',
      'receivingAudioDropped',
      'signalingDropped',
      'sendingAudioFailed',
      'sendingAudioRecovered',
      'attendeePresenceReceived',
    ],
    warn: ['audioInputFailed', 'videoInputFailed', 'deviceLabelTriggerFailed', 'meetingStartFailed', 'meetingFailed'],
  };

  constructor({ postUrl, attendeeId, meetingId, externalAttendeeId, externalMeetingId, pageName }) {
    this.postUrl = postUrl;
    this.metaData = {
      attendeeId,
      meetingId,
      externalAttendeeId,
      externalMeetingId,
      pageName,
    };

    this.initMeetingSessionLogger(LogLevel.WARN);
    this.initPostRealtimeEventsLogger(LogLevel.INFO);
  }

  initMeetingSessionLogger(logLevel) {
    const { attendeeId, meetingId, externalAttendeeId, externalMeetingId, pageName } = this.metaData;
    const consoleLogger = new ConsoleLogger(`[meeting-event-${attendeeId}]`, logLevel);

    if (!this.postUrl) {
      this.meetingSessionLogger = consoleLogger;
      return;
    }
    const meetingSessionPostLogger = getPOSTLogger({
      attendeeId,
      logLevel,
      meetingId,
      url: this.postUrl,
      additionalMeta: { eventType: 'MeetingSession', externalAttendeeId, externalMeetingId, pageName },
    });
    this.meetingSessionLogger = new MultiLogger(consoleLogger, meetingSessionPostLogger);
  }

  getSessionLogger() {
    return this.meetingSessionLogger;
  }

  initPostRealtimeEventsLogger(logLevel) {
    if (!this.postUrl) {
      return;
    }
    const { attendeeId, meetingId, externalAttendeeId, externalMeetingId, pageName } = this.metaData;
    this.realtimeEventsPostLogger = getPOSTLogger({
      attendeeId,
      logLevel,
      meetingId,
      url: this.postUrl,
      additionalMeta: { eventType: 'RealTimeEvent', externalAttendeeId, externalMeetingId, pageName },
    });
  }

  logRealtimeChimeEvent(eventName, attributes) {
    if (!this.realtimeEventsPostLogger) {
      return;
    }
    const { meetingHistory, ...otherAttributes } = attributes;

    if (this.MEETING_EVENTS.info.includes(eventName)) {
      this.realtimeEventsPostLogger.info(JSON.stringify({ eventName, attributes: otherAttributes }));
    } else if (this.MEETING_EVENTS.warn.includes(eventName)) {
      this.realtimeEventsPostLogger.warn(
        JSON.stringify({
          eventName,
          attributes: otherAttributes,
          meetingHistory: meetingHistory.filter(({ timestampMs }) => {
            return Date.now() - timestampMs < 5000;
          }),
        }),
      );
    }
  }

  updateMetaData(updatedMeta) {
    this.metaData = { ...this.metaData, ...updatedMeta };

    this.initMeetingSessionLogger(LogLevel.WARN);
    this.initPostRealtimeEventsLogger(LogLevel.INFO);
  }
}

export default MeetingEventsLogger;
