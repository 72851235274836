<template>
  <transition name="line" appear>
    <line v-if="canDraw" :class="[track ? 'track' : 'live']" :x1="xStart" :y1="yStart" :x2="xEnd" :y2="yEnd" :stroke-opacity="opacity" :style="lineStyle" />
  </transition>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { useFrameStore } from '@/store/pinia/frame';

const props = defineProps({
  from: {
    required: true,
    type: [Number, String, Boolean],
  },
  to: {
    required: true,
    type: [Number, String],
  },
  limit: {
    default: 10,
    type: Number,
  },
  index: {
    type: Number,
  },
  lineCounts: {
    type: Object,
  },
  track: {
    type: Boolean,
    default: false,
  },
});

const usersStore = useUsersStore();
const frameStore = useFrameStore();
const { positions, avatarsAnimating, positionDetails } = storeToRefs(usersStore);
const { resizing } = storeToRefs(frameStore);

const canDraw = computed(() => props.from && start.value && end.value);
const start = computed(() => (positionDetails.value.edgePoints ? positionDetails.value.edgePoints[props.from] : positions.value[props.from]));
const end = computed(() => (positionDetails.value.edgePoints ? positionDetails.value.edgePoints[props.to] : positions.value[props.to]));

const xStart = computed(() => (start.value ? start.value.left : 0));
const yStart = computed(() => (start.value ? start.value.top : 0));
const xEnd = computed(() => (end.value ? end.value.left : 0));
const yEnd = computed(() => (end.value ? end.value.top : 0));

const opacity = computed(() => 1 - (1 / props.limit) * props.index);
const lineLength = computed(() => {
  let x = Math.abs(xEnd.value - xStart.value);
  let y = Math.abs(yEnd.value - yStart.value);
  return Math.sqrt(x * x + y * y);
});

const offset = computed(() => (avatarsAnimating.value || resizing.value ? lineLength.value : 0));
const hideShowOpacity = computed(() => (avatarsAnimating.value || resizing.value ? 0 : 1));
const thinWidth = computed(() => (avatarsAnimating.value ? 0 : Math.min(Math.max(3, count.value * 1.2), 10)));
const bigWidth = computed(() => (avatarsAnimating.value ? 0 : 8 + thinWidth.value));

const count = computed(() => (!props.lineCounts ? 1 : props.lineCounts[props.from + '-' + props.to] || 0));

const lineStyle = computed(() => {
  let width = props.track ? 0.6 : thinWidth.value;
  return {
    'stroke-dasharray': lineLength.value,
    'stroke-dashoffset': offset.value,
    'stroke-width': width,
    opacity: hideShowOpacity.value,
  };
});
</script>

<style lang="scss" scoped>
line {
  stroke: var(--c__yellow);
  transition: stroke-dashoffset 400ms, stroke-width 300ms;

  stroke-linecap: round;
}

.line-enter-from {
  stroke: var(--c__yellow);
  stroke-width: v-bind(bigWidth) !important;
  stroke-dashoffset: v-bind(lineLength) !important;
}
</style>
