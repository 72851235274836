import { defineStore } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { eventEmitter } from '@/services/event-emitter.service';

function parseCard(l) {
  return {
    localId: l.meta.cardArgs.localId,
    id: l.id,
    user: l.user_id,
    height: l.meta.cardArgs.height,
    width: l.meta.cardArgs.width,
    fontSize: l.meta.cardArgs.fontSize,
    x: l.meta.cardArgs.x,
    y: l.meta.cardArgs.y,
    color: l.meta.cardArgs.color,
    logType: l.meta.type,
    type: l.meta.cardArgs.type,
    text: l.description,
  };
}

export const useMappingStore = defineStore('mapping', {
  state() {
    return {
      showFull: false,
      w: 0,
      h: 0,
      viewBoxX: 0,
      viewBoxY: 0,
      em: 18,
      zoom: 100,
      snap: true,
      grid: 28,
      advanced: false,
      dragging: false,
      draggingArena: false,
      resizing: false,
      editing: false,
      detailedEditing: false,
      backToEdit: false,
      adding: false,
      latestColor: 'yellow',
      colors: ['yellow', 'orange', 'red', 'purple', 'blue', 'green', 'white', 'black', 'none'],
      items: {},
      latestItemId: false,
      template: {},
      typeScale: 1.333,
    };
  },
  getters: {
    isMapping() {
      let phaseStore = usePhaseStore();
      return phaseStore.currentPhase === 'dialogue';
    },
    zoomMultiplier: (s) => 100 / s.zoom,
    latestItem: (s) => s.items[s.latestItemId],
    currentItem: (s) => s.items[s.editing],
    itemCentre: (s) => (id) => {
      if (!s.items[id]) {
        return false;
      } else {
        return {
          x: s.items[id].x + s.items[id].width / 2,
          y: s.items[id].y + s.items[id].height / 2,
        };
      }
    },
    itemsArray: (s) => Object.values(s.items),
    itemCount() {
      this.itemsArray.length;
    },
    templateAreas: (s) => {
      return Object.keys(s.template).length === 0 ? false : Object.keys(s.template);
    },
    templateChildren: (s) => (id) => {
      let children = [];
      let i = s.template[id];
      let xMax = i.x + i.width;
      let yMax = i.y + i.height;
      this.itemsArray.forEach((item) => {
        let c = this.itemCentre(item.id);
        if (c.x > i.x && c.x < xMax && c.y > i.y && c.y < yMax) {
          children.push(item.id);
        }
      });

      children.sort((a, b) => {
        let centerA = this.itemCentre(a);
        let centerB = this.itemCentre(b);
        if (Math.abs(centerA.y - centerB.y) < s.grid) {
          return centerA.x - centerB.x;
        } else {
          return centerA.y - centerB.y;
        }
      });

      return children;
    },
    templateChildrenWithText: (s) => (id) => {
      return this.templateChildren(id).filter((item) => s.items[item].text);
    },
    editingItem: (s) => s.items[s.editing] || false,
  },
  actions: {
    initMeeting() {},
    // updateMeetingState(ctx, { data, init }) {
    //   const ev = clientEvent(data);
    //   //const eventData = clientEventData(data);
    //   if (init || _.includes(['on_event_created', 'on_event_deleted', 'on_event_updated'], ev)) {
    //     this.commit('EXTERNAL_UPDATE_CARDS', data);
    //   }
    // },

    externalUpdateCards() {},
    add(data) {
      if (!this.isMapping) {
        return false;
      }
      console.log('add', data);
    },
    delete(data) {
      if (!this.isMapping) {
        return false;
      }
      console.log('delete', data);
    },
    update(data) {
      if (!this.isMapping) {
        return false;
      }
      console.log('update', data);
    },
    setAllCards(v) {
      let items = {};
      if (this.isMapping) {
        const cards = v.history.filter((l) => l.level === 10 && l.phase_id === v.state.current_phase.id && l.meta.cardArgs);
        cards.forEach((l) => {
          items[l.meta.cardArgs.localId] = parseCard(l);
        });
      }
      this.items = { ...items };
    },
    toggleFullMap() {
      this.showFull = !this.showFull;
    },
    updateWindowSize({ w, h }) {
      this.w = w;
      this.h = h;
    },
    updateViewBoxPosition({ jump, x, y }) {
      if (jump) {
        this.viewBoxX = x;
        this.viewBoxY = y;
      } else {
        this.viewBoxX = this.viewBoxX + x / (this.zoom / 100);
        this.viewBoxY = this.viewBoxY + y / (this.zoom / 100);
      }
    },
    setZoom(v) {
      this.zoom = v;
    },
    setTemplate(v) {
      this.template = v ? { ...v } : {};
    },
    updateTemplateItem(args) {
      if (this.template[args.id]) {
        this.template[args.id] = { ...this.template[args.id], ...args };
      }
    },
    updateItem(args) {
      eventEmitter.emit('nM:dialogue:update', args);
      if (this.items[args.localId]) {
        let resetIds = { id: this.items[args.localId].id, localId: args.localId };
        this.items[args.localId] = { ...this.items[args.localId], ...args, ...resetIds };
        if (args.color) {
          this.latestColor = args.color;
        }
      }
    },
    setDragging(v) {
      this.dragging = v;
      if (v) {
        this.latestItemId = v;
      }
    },
    setDraggingArena(v) {
      this.draggingArena = v;
    },
    setResizingItem(v) {
      this.resizing = v;
      if (v) {
        this.latestItemId = v;
      }
    },
    setEditingMapItem(v) {
      this.editing = v;
      if (v) {
        this.latestItemId = v;
      }
    },
    setBackToEdit(v) {
      this.backToEdit = v;
    },
    setAdding(v) {
      this.adding = v;
      if (v) {
        this.latestItemId = v;
      }
    },
    addMappingItem(args) {
      let newItems = {};
      newItems[args.localId] = args;
      this.items = {
        ...this.items,
        ...newItems,
      };
    },
    removeItem(item) {
      const { localId } = item;
      eventEmitter.emit('nM:dialogue:boxDeleted', item);
      delete this.items[localId];
      this.items = { ...this.items };
    },
    setSnap(ctx, v) {
      this.snap = v;
    },
    setAdvanced(ctx, v) {
      this.advanced = v;
    },
  },
});
