<template>
  <sidebar-overlay class="participantList">
    <h2>Waiting Room</h2>
    <ul>
      <li v-for="user in inWaitingRoom" :key="user.id">
        <span class="name"> {{ user.firstName }} {{ user.lastName }} </span>
        <button class="reject" @click="reject(user.id)">Reject</button>
        <button class="admit" @click="admit(user.id)">Admit</button>
      </li>
    </ul>
    <button class="admitAll btn btn-border" @click="admitAll">Admit all</button>
  </sidebar-overlay>
</template>

<script setup>
import { watch, computed, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useUsersStore } from '@/store/pinia/users';
import { useMessagesStore } from '@/store/pinia/messages';
import SidebarOverlay from '@/components/SidebarOverlay';

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;
const usersStore = useUsersStore();
const sidebarStore = useSidebarStore();
const messagesStore = useMessagesStore();
const { inWaitingRoom } = storeToRefs(usersStore);

const hasWaiting = computed(() => inWaitingRoom.value.length > 0);

function admit(id) {
  $meetingmanager.approveJoiningRequest({ userId: id });
  messagesStore.removeAlert({ filter: (t) => t.messageName !== 'userWaiting' || t.userId !== id });
}

function admitAll() {
  $meetingmanager.approveAllJoiningRequests();
  messagesStore.removeAlert({ messageName: 'userWaiting' });
}

function reject(id) {
  $meetingmanager.rejectJoiningRequest({ userId: id });
  messagesStore.removeAlert({ filter: (t) => t.messageName !== 'userWaiting' || t.userId !== id });
}

// automatically close once there is nobody left
watch(hasWaiting, (nv) => {
  if (!nv) {
    sidebarStore.requestBack();
  }
});
</script>

<style lang="scss" scoped>
.participantList {
  padding-top: rem(78px);
  padding-bottom: 0;
}
ul,
h2 {
  padding: 0 rem($sidebar-left-pad);
}

h2 {
  text-align: left;
}

ul {
  margin-top: rem(28px);
}

li {
  display: flex;
  gap: 1em;
}
li + li {
  margin-top: 0.5em;
}
.name {
  margin-right: auto;
  flex: 1 1 auto;
}

ul button {
  flex: 0 0 auto;
}

.reject {
  color: var(--c__warn);
  @include triggered {
    color: var(--c__warn-loud);
  }
}
.admit {
  color: var(--c__accent);
  @include triggered {
    color: var(--c__accent-alt);
  }
}

ul {
  margin-bottom: auto;
}

.admitAll {
  margin: auto rem($sidebar-left-pad) rem($log-height - 50px) rem($sidebar-left-pad);
  border-width: 3px;
  height: rem(50px);
  width: auto;
}
</style>
