import { computed, watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';
import { useApplyOffscreenPages } from '@/composables/layouts/useApplyOffscreenPages';
import * as geometry from '@/resources/geometry';
import _ from 'underscore';
import { H_PAD } from '@/resources/constants/frame-constants';

export function useVotingLayout() {
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();

  const phaseStore = usePhaseStore();
  const { applyOffscreenPages } = useApplyOffscreenPages();

  const { usersVisibleList: users, myId, visibleUserCount, page } = storeToRefs(usersStore);
  const { cY, contWidth, contHeight, voteSlots, voteMaxPerPage } = storeToRefs(frameStore);
  const { currentPhase, collectedVotes } = storeToRefs(phaseStore);

  const voteRows = computed(() => voteSlots.value.rows);
  const voteCols = computed(() => voteSlots.value.cols);

  const voteMap = ref({});

  watch(currentPhase, (nv) => {
    if (nv === 'decision') {
      generateVoteMap();
    }
  });
  watch(voteRows, generateVoteMap);
  watch(voteCols, generateVoteMap);
  watch(visibleUserCount, generateVoteMap);

  function splitIntoBasicPages({ users, limit }) {
    let pages = _.chunk(_.without(users, myId.value), limit - 1);
    pages = pages.map((page) => [myId.value, ...page]);
    return pages;
  }

  function generateVoteMap() {
    voteMap.value = {};
    // create the vote pages
    let pages = splitIntoBasicPages({ users: users.value, limit: voteMaxPerPage.value });

    // Do your own slots and remove them as an option for anyone else
    let mainYesSlots = _.shuffle(_.range(voteSlots.value.count));
    let mainNoSlots = _.shuffle(_.range(voteSlots.value.count));
    voteMap.value[myId.value] = {
      yes: mainYesSlots.pop(),
      no: mainNoSlots.pop(),
    };

    // for each page, do the slots
    pages.forEach((page) => {
      let yesSlots = _.shuffle(mainYesSlots);
      let noSlots = _.shuffle(mainNoSlots);

      page.forEach((u) => {
        if (u !== myId.value) {
          voteMap.value[u] = {
            yes: yesSlots.pop(),
            no: noSlots.pop(),
          };
        }
      });
    });
  }

  function votedLayout() {
    let pos = {};
    let hasNoSlots;
    let w = voteSlots.value.size;
    let pages = splitIntoBasicPages({ users: users.value, limit: voteMaxPerPage.value });

    // Check we have slots
    users.value.forEach((id) => {
      if (!voteMap.value[id]) {
        hasNoSlots = true;
      }
    });

    if (hasNoSlots) {
      generateVoteMap();
    }

    // Get our pages and save it

    // check our page exists and if it doesn't, go back to ones that does
    if (!pages[page.value]) {
      usersStore.updatePage(pages.length - 1);
    }

    let thisPage = pages[page.value];

    // filter the votes to what is on the page
    let visibleYesVotes = _.intersection(collectedVotes.value.yes, thisPage);
    let visibleNoVotes = _.intersection(collectedVotes.value.no, thisPage);
    let visibleUnsureVotes = _.intersection(collectedVotes.value.unsure, thisPage);
    const cont = contWidth.value - 150;
    const unsureW = (cont - voteMaxPerPage.value * H_PAD) / voteMaxPerPage.value;

    // Do the previous pages

    let prevPos = 0 - w - H_PAD;
    let nextPos = contWidth.value + w * 2 + H_PAD;
    pos = applyOffscreenPages({
      pos,
      pages,
      w,
      nextPos,
      prevPos,
      page: page.value,
      y: cY.value,
    });

    // loop over the off screen users and set their width based on their vote so it doesn't get weird.
    Object.keys(pos).forEach((id) => {
      if (collectedVotes.value.unsure.includes(parseInt(id))) {
        pos[id].w = unsureW;
      } else {
        pos[id].w = w;
      }
    });

    // Layout the actual page:

    // Loop over them and apply them to their slot
    if (visibleYesVotes && visibleYesVotes.length > 0) {
      visibleYesVotes.forEach((id) => {
        let yesId = voteMap.value[id].yes;
        pos[id] = {
          top: voteSlots.value.yes[yesId].top + voteSlots.value.size / 2,
          left: voteSlots.value.yes[yesId].left + voteSlots.value.size / 2,
          w: voteSlots.value.size,
        };
      });
    }
    if (visibleNoVotes && visibleNoVotes.length > 0) {
      visibleNoVotes.forEach((id) => {
        let noId = voteMap.value[id].no;
        pos[id] = {
          top: voteSlots.value.no[noId].top + voteSlots.value.size / 2,
          left: voteSlots.value.no[noId].left + voteSlots.value.size / 2,
          w: voteSlots.value.size,
        };
      });
    }

    if (visibleUnsureVotes && visibleUnsureVotes.length > 0) {
      let middle = geometry.row({
        users: visibleUnsureVotes,
        y: contHeight.value / 2,
        cont,
        max: cont,
        maxSize: 100,
        w: unsureW,
        pad: H_PAD,
        scaled: true,
      });

      visibleUnsureVotes.forEach((id) => {
        pos[id] = middle[id];
        pos[id].left = parseInt(pos[id].left) + 100;
      });
    }

    let extras = {
      pagerTop: (contHeight.value - w) / 2,
      pagerHeight: w,
    };

    return { pos, extras, pages, layoutName: 'voted', paged: true };
  }

  return { votedLayout };
}
