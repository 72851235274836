<template>
  <li class="hamburgerItem" :class="[name, isDisabled ? 'disabled' : '']" @click="handleDisabled">
    <button ref="icon" :disabled="isDisabled" @click="handleClick" @keyup.space="(e) => e.preventDefault()">
      <app-icon :icon="icon" />
      <span class="text">
        <slot />
      </span>
    </button>
    <slot name="tips" />
  </li>
</template>

<script>
import AppIcon from '@/components/AppIcon.vue';

export default {
  components: {
    AppIcon,
  },
  props: {
    name: {
      type: String,
    },
    icon: {
      type: String,
    },
    isDisabled: {
      required: false,
      default: false,
    },
  },
  methods: {
    handleDisabled() {
      if (this.isDisabled) {
        Velocity(this.$refs.icon, 'callout.swing', { duation: 300 });
        this.$emit('nope');
      }
    },
    handleClick() {
      if (this.isDisabled) {
        this.$emit('nope');
        return false;
      } else {
        this.$emit('clicked');
      }
    },
  },
};
</script>
