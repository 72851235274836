<template>
  <div id="windowView" class="windowView">
    <div class="floor" :style="{ top: lowFloorTop + 'px' }" />
    <div class="windowPane" :style="{ height: windowHeight + 'px', top: windowTop + 'px' }" />
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';

import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const frameStore = useFrameStore();
    return { frameStore };
  },
  data() {
    return {
      showing: false,
      floorGap: 35, // was 35;
    };
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      pos: 'positionDetails',
    }),
    floorTop() {
      return this.pos.bottom + this.frameStore.ringTop + this.floorGap;
    },
    lowFloorTop() {
      return this.pos.bottom + this.frameStore.ringTop + this.pos.height * 0.1;
    },
    windowTop() {
      return this.pos.top + this.frameStore.ringTop - this.pos.height * 0.1;
    },
    windowBottom() {
      return this.pos.bottom;
    },
    windowHeight() {
      return this.pos.height;
    },
    top() {
      return this.pos.bottom + this.frameStore.ringTop;
    },
    width() {
      return this.pos.width + 120 > this.frameStore.contWidth - 48 ? this.frameStore.contWidth - 48 + 'px' : this.pos.width + 120 + 'px';
    },
  },
};
</script>
<style lang="scss" scoped>
.floor {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--grad__break);
}
.windowPane {
  border: solid rem(20px) var(--c__window-frame);
  position: absolute;
  left: 50%;
  width: 90%;
  max-width: rem(1100px);
  transform: translateX(-50%);
  background-image: var(--bg__break-window);
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
}

.windowView {
  @include dark-mode('neon') {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000000;
    .floor {
      background: linear-gradient(to bottom, rgba(30, 30, 30, 0.86) 0%, rgba(35, 35, 35, 1) 15%, rgba(35, 35, 35, 1) 25%, rgba(216, 216, 216, 0) 100%);
    }
  }
}
</style>
