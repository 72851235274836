<template>
  <div class="flipHome pane waitingSidebar">
    <div class="content">
      <h2>
        {{ displayName }}
        <transition name="basic">
          <div class="isEditing" v-if="editedBy">
            <span class="text">{{ editedBy }} is editing</span><span class="elipHolder"><span class="elip">&hellip;</span></span>
          </div>
        </transition>
      </h2>
      <p v-if="isOwnerHere">{{ owner.first_name }} knows that you are here and will let you in soon.</p>
      <p v-else>Waiting for {{ owner.first_name }} to start the meeting…</p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';
const meetingStore = useMeetingStore();
const usersStore = useUsersStore();

const { name: meetingName, editing, isRoom } = storeToRefs(meetingStore);
const { owner, getUserById } = storeToRefs(usersStore);

const editedBy = computed(() => {
  if (editing.value.name) {
    return getUserById.value(editing.value.name).first_name;
  } else {
    return false;
  }
});

const isOwnerHere = computed(() => owner.value && owner.value.id && !owner.value.gone);
const displayName = computed(() => {
  if (meetingName.value && !['undefined', 'Undefined'].includes(meetingName.value)) {
    return meetingName.value;
  } else {
    if (isRoom.value) {
      return `${owner?.value?.first_name}'s meeting`;
    } else {
      return 'Welcome!';
    }
  }
});
</script>
<style lang="scss" scoped>
.content {
  padding: rem(140px) rem($sidebar-left-pad);
}

h2 {
  position: relative;

  @include type-size('medium');
  line-height: 1;
  padding: rem(2px) 0;
  font-weight: bold;
}

p {
  margin-top: 1em;
}

.isEditing {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--c__bg-90);
  color: var(--c__kinda-quiet);
  font-weight: bold;
  z-index: 9;
  .elipHolder {
    font-size: rem(27px);
    width: 1em;
    margin-left: -0.5em;
    position: absolute;
    right: rem($sidebar-left-pad);
    left: auto;
    top: -0.45em;
    bottom: auto;
  }
  .text {
    width: 100%;
    position: absolute;
    top: 0.2em;
    text-align: left;
    width: auto;
  }
  .elip {
    animation: info-elip 1500ms infinite;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
  }
}
</style>
