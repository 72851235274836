<template>
  <div class="container-search-query">
    <form @submit.prevent="searchMeetings()">
      <div class="formField">
        <app-select v-model="searchModel.searchBy" :options="searchByOptions" placeholder="Select search by" @change="() => {}" key="search-by">
          <template #arrow>
            <app-icon icon="down-arrow" />
          </template>
          <template #placeholder> Please select search parameter </template>
          <template #label> Search By: </template>
        </app-select>
      </div>
      <div class="formField">
        <label>
          Query: <br />
          <input
            type="text"
            v-model="searchModel.query"
            autofocus
            :placeholder="`Enter ${searchModel.searchBy === 'email' ? 'email' : 'meeting ID'}`"
            class="txt-search-by"
            key="search-query"
            required
          />
        </label>
      </div>
      <div class="formField">
        <button type="submit" class="btn btn-solid btn-loud-blue btn-dark-blue-flood" :disabled="!searchModel.query">
          <span class="text"> Search </span>
          <transition name="basic">
            <app-loading-spinner v-if="isLoading" />
          </transition>
        </button>
      </div>
    </form>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import AppIcon from '@/components/AppIcon';
import AppSelect from '@/components/AppSelect';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';

defineProps({
  isLoading: { type: Boolean },
  resetRequestedTs: { type: Number },
});
const emit = defineEmits(['submit']);

// const searchModel = ref({ query: 'tahaa@now-here.com', searchBy: 'email' });
const searchModel = ref({ query: '', searchBy: 'email' });
const searchByOptions = [
  { label: 'Email', value: 'email' },
  { label: 'Meeting ID', value: 'meetingId' },
];

// watch(
//   props.resetRequestedTs,
//   () => {
//     searchModel.value = { query: '', searchBy: '' };
//   },
// );

const searchMeetings = () => {
  emit('submit', { model: searchModel.value });
};
</script>

<style lang="scss" scoped>
.container-search-query {
  flex-grow: 2;
  margin-top: rem(50px);
  display: flex;
  flex-direction: column;
}

form {
  width: rem(600px);
  min-width: rem(300px);
  margin: auto;

  & > * {
    margin-top: 1.5em;
  }
}

input[type='text'] {
  width: 100%;
  height: rem(36px);
  padding: rem(5px);
  border: none;
  border-bottom: 2px solid black;
  outline: none;
}

button {
  height: rem(36px);
  width: rem(200px);
  margin: rem(12px) auto 0;
  position: relative;
}

.loadingSpinner {
  position: absolute;
  right: 1em;
}
</style>
