import { storeToRefs } from 'pinia';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';
import { usePxToRem } from '@/composables/usePxToRem';

export function useSoloLayout() {
  const usersStore = useUsersStore();
  const frameStore = useFrameStore();
  const meetingStore = useMeetingStore();
  const { pxToRem } = usePxToRem();

  const { myId } = storeToRefs(usersStore);
  const { ringBasis, cY, contWidth, contHeight } = storeToRefs(frameStore);
  const { spaceDetails, space } = storeToRefs(meetingStore);

  function soloLayout() {
    const pos = {};

    let w = ringBasis.value / 2;
    let id = myId.value;

    pos[id] = {
      w,
      left: contWidth.value * spaceDetails.value.breakPosition,
      top: cY.value,
    };

    if (space.value === 'beach') {
      pos[id].top = contHeight.value - w * 0.87 - pxToRem(60);
    }

    if (space.value === 'neon') {
      pos[id].top = cY.value - w / 6;
    }

    return { pos, layoutName: 'solo' };
  }

  return { soloLayout };
}
