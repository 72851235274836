<template>
  <div class="subContent">
    <title-block :wordless="true" />
    <h2>Welcome</h2>
    <p>To get the best experience with bixe, let's make sure everything is set up.</p>
    <button :disabled="isNextDisabled" @click="createSessionAndMoveToNextStep()" class="btn btn-border btn-medium btn-accent">Start</button>
  </div>
</template>

<script>
import TitleBlock from '@/components/TitleBlock.vue';
import { usePageStore } from '@/store/pinia/page';
import { mapState as mapPiniaState } from 'pinia';
import { useMediaStore } from '@/store/pinia/media';
import { useUsersStore } from '@/store/pinia/users';

export default {
  components: {
    TitleBlock,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    return { pageStore, mediaStore };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });

    this.$readinessChecker.markAsStarted(this.pageName);
    this.$videomanager.on('ready', () => {
      this.$connectivity.init({
        videoSource: this.videoSource || null,
        audioSource: this.audioSource || null,
        audioOn: true,
        videoOn: true,
      });
      this.mediaStore.initReadinessCheckSession(true);
      this.$readinessChecker
        .markAsCompleted({ subPageName: this.pageName, isPassed: true })
        .moveToNextStep({ nextPageName: this.nextPageName })
        .onMeetingSessionInitialized(this.$videomanager.meetingSession);

      setTimeout(() => {
        this.isNextDisabled = false;
        // so that the button enables after user has transitioned from this screen.
      }, 500);
    });
  },
  beforeUnmount() {
    this.$videomanager.off('ready', () => {});
  },
  computed: {
    ...mapPiniaState(useMediaStore, {
      audioSource: (s) => s.userDevices.audioSource,
      videoSource: (s) => s.userDevices.videoSource,
    }),
    ...mapPiniaState(useUsersStore, ['me']),
  },
  data() {
    return {
      isNextDisabled: false,
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkSpeed,
    };
  },
  methods: {
    registerVideoManager() {
      this.$videomanager.once('ready', () => {
        this.$connectivity.init({
          audioSource: this.audioSource || null,
          videoSource: this.videoSource || null,
          audioOn: true,
          videoOn: true,
        });
        this.mediaStore.initReadinessCheckSession(true);
        this.$readinessChecker
          .markAsCompleted({ subPageName: this.pageName, isPassed: true })
          .moveToNextStep({ nextPageName: this.nextPageName })
          .onMeetingSessionInitialized(this.$videomanager.meetingSession);

        setTimeout(() => {
          this.isNextDisabled = false;
          // so that the button enables after user has transitioned from this screen.
        }, 500);
      });
    },

    createSessionAndMoveToNextStep() {
      const attendeeDetails = this.$readinessChecker.getAttendeeDetails();
      this.isNextDisabled = true;
      this.registerVideoManager();
      this.$API
        .initializeReadinessCheck(attendeeDetails)
        .then((resp) => {
          this.$readinessChecker.setMetaData({
            attendeeId: resp.attendee.AttendeeId,
            externalAttendeeId: resp.attendee.ExternalUserId,
            meetingId: resp.meeting.MeetingId,
            externalMeetingId: resp.meeting.ExternalMeetingId,
          });
          this.$videomanager.init(resp, {
            externalAttendeeId: resp.attendee.ExternalUserId,
            externalMeetingId: resp.meeting.ExternalMeetingId,
            pageName: this.pageName,
          }); // <= will trigger $videoManager.on('ready')
        })
        .catch((err) => {
          this.isNextDisabled = false;
          console.log('unable to init readiness check', err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  max-width: 17em;
}
</style>
