<template>
  <li class="addToGroup">
    <button :style="{ top: position.top + 'px', left: position.left + 'px' }" title="add to group" @click="handleClick">+</button>
  </li>
</template>

<script>
import { useUsersStore } from '@/store/pinia/users';
export default {
  props: ['group', 'position'],
  setup() {
    const usersStore = useUsersStore();
    return { usersStore };
  },
  computed: {},
  methods: {
    handleClick() {
      this.usersStore.addUserToGroup({ user: this.usersStore.swapping, group: this.group });
      this.usersStore.setStapping(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.addToGroup {
  list-style: none;
  button {
    z-index: 50;
    position: absolute;
    border: 2px solid var(--c__accent);
    font-weight: bold;
    transition: all 0.3s ease;
    width: 4rem;
    height: 4rem;
    line-height: 4rem;
    margin: -2rem 0 0 -2rem;
    font-size: 4rem;
    background: var(--c__bg-alt);
    color: var(--c__accent);
    border-radius: 50%;
    @include triggered {
      color: var(--c__bg);
      background: var(--c__accent);
    }
  }
}
</style>
