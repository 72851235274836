import { defineStore } from 'pinia';
import { interrupts } from '@/shared/messages/sidebarNotices';
import { alerts } from '@/shared/messages/alerts';
import _ from 'underscore';

function parseMessage(messageSet, messageName, args = {}) {
  if (messageSet[messageName]) {
    let message = _.extend(args, messageSet[messageName]);
    message.content = _.isFunction(message.content) ? message.content(args) : message.content;
    message.heading = _.isFunction(message.heading) ? message.heading(args) : message.heading;
    message.about = _.isFunction(message.about) ? message.about(args) : message.about;
    message.type = _.isFunction(message.type) ? message.type(args) : message.type;
    message.buttons = _.isFunction(message.buttons) ? message.buttons(args) : message.buttons;
    message.messageName = messageName;
    message.name = messageName;

    if (message.buttons && message.buttons.length > 0) {
      message.buttons = message.buttons.map((b) => {
        return {
          ...b,
          args,
        };
      });
    }

    return message;
  } else {
    return false;
  }
}

export const useMessagesStore = defineStore('messages', {
  state() {
    return {
      notice: false,
      alerts: [],
      alertsShowing: false,
    };
  },
  getters: {
    hasNotice: (s) => s.notice && s.notice.messageName,
    currentAlert: (s) => (s.alerts.length > 0 ? s.alerts[s.alerts.length - 1] : false),
  },
  actions: {
    init() {},
    showAlerts() {
      this.alertsShowing = true;
    },
    hideAlerts() {
      this.alertsShowing = false;
    },
    interrupt(messageName, args) {
      let p = new Promise((resolve, reject) => {
        let interrupt = parseMessage(interrupts, messageName, args);
        if (!interrupt?.messageName) {
          console.log('that is not a real check');
          reject('no tip');
        } else {
          interrupt.resolve = resolve;
          interrupt.reject = reject;
          this.notice = interrupt;
        }
      });
      return p;
    },
    clearInterrupt() {
      this.notice = false;
    },
    async addAlert(t, args) {
      let p = new Promise((resolve, reject) => {
        if (t.messageName !== this.currentAlert.messageName || t.content !== this.currentAlert.content || t.force || !this.show) {
          let alert;
          if (t.messageName) {
            alert = { ...t };
          } else {
            alert = parseMessage(alerts, t, args);
          }
          if (!alert.messageName) {
            console.log('that is not a real check');
            reject('no tip');
          } else {
            this.replaceSelf(alert).then(() => {
              alert.resolve = resolve;
              alert.reject = reject;
              alert.tipId = _.uniqueId('alert_');
              this.alerts.push(alert);
              this.alertsShowing = true;
              if (!alert.buttons) {
                resolve();
              }
            });
          }
        }
      });

      return p;
    },
    replaceSelf({ messageName, about, content, replaceSelf }) {
      return new Promise((resolve) => {
        const alertsCount = this.alerts.length;
        if (['userLoweredHand', 'userRaisedHand'].includes(messageName)) {
          this.alerts = this.alerts.filter((t) => !['userLoweredHand', 'userRaisedHand'].includes(t.messageName) || t.about !== about);
        }

        if (messageName === 'acceptPromotion') {
          this.alerts = this.alerts.filter((t) => t.messageName !== 'youHaveBeenDemoted');
        }

        if (messageName === 'youHaveBeenDemoted') {
          this.alerts = this.alerts.filter((t) => t.messageName !== 'acceptPromotion');
        }

        if (replaceSelf) {
          this.alerts = this.alerts.filter((t) => messageName !== t.messageName || t.about !== about);
        }
        this.alerts = this.alerts.filter((t) => t.content !== content && !t.multiple);
        if (alertsCount !== this.alerts.length) {
          _.delay(resolve, 500);
        } else {
          resolve();
        }
      });
    },
    removeAlert(args) {
      if (!args) {
        this.alerts = this.alerts.filter((t) => t.tipId !== this.currentAlert.tipId);
      } else {
        const { tipId, messageName, filter } = args;
        if (tipId) {
          this.alerts = this.alerts.filter((t) => t.tipId !== tipId);
        } else if (filter) {
          this.alerts = this.alerts.filter(filter);
        } else if (messageName) {
          this.alerts = this.alerts.filter((t) => t.messageName !== messageName);
        }
      }
    },
    clearAllAlerts() {
      this.alerts = [];
    },
    clearAlertsFromLayout() {
      this.alerts = this.alerts.filter((t) => !t.hideOnLayout);
    },
  },
});
