<template>
  <div class="tabs">
    <ul>
      <li v-for="(tab, i) in tabList" :class="[i + 1 === activeTab ? 'current' : '']" :key="i">
        <label :for="`${uid}${i}`" v-text="tab"></label>
        <input :id="`${uid}${i}`" type="radio" :name="`${uid}-tab`" :value="i + 1" v-model="activeTab" class="visuallyHidden" />
      </li>
    </ul>
    <div class="items">
      <transition-group name="tab" mode="out-in">
        <template v-for="(tab, index) in tabList">
          <div :key="index" v-if="index + 1 === activeTab">
            <slot :name="`item-${index + 1}`" />
          </div>
        </template>
      </transition-group>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
  },
  emits: ['onTabActive'],
  data() {
    return {
      activeTab: 1,
      uid: false,
    };
  },
  mounted() {
    this.uid = _.uniqueId('tabs_');
  },
  watch: {
    activeTab(nv) {
      this.$emit('onTabActive', nv);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-enter-active {
  transition: all 0.3s 0.6s;
}

.tab-leave-active {
  transition: opacity 0.3s cubic-bezier(0.5, 0, 1, 0.5);
  position: absolute;
  left: 0;
  right: 0;
}

.tab-enter-from,
.tab-leave-to {
  opacity: 0;
}
.tabs {
  width: 100%;
}
.items {
  transition: height 0.3s ease-in-out;
  position: relative;
}
ul {
  display: flex;
  list-style: none;
  margin-bottom: 1em;
}
li {
  flex: 1 1 auto;
}
label {
  @include type-size(medium);
  text-align: center;
  display: block;
  padding: 0.5em;
  border-bottom: 2px solid var(--c__edges);
  transition: border-bottom 0.3s;
}
.current label {
  border-bottom: 2px solid var(--c__accent);
}
</style>
