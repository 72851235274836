<template>
  <section class="poster page">
    <div class="content">
      <title-block v-if="isApp" class="titleBlock" />
      <p class="form-heading">Let's get into the meeting</p>
      <div class="actions">
        <button class="btn btn-solid btn-accent btn-dark-blue-flood" @click="redirectToAuth()">
          <span v-if="isLoginRequested"><app-loading-spinner /></span>
          <span v-else>Sign In</span>
        </button>
        <button class="btn btn-border btn-blue-flood" @click="redirectToGuestLogin()">Join as a guest</button>
      </div>

      <div v-if="isLoginRequested" class="opening-now">
        <p>
          Opening your browser to log you in… <br />
          Having problems? <a href="#" @click="retryLoginViaBrowser()">Try again.</a>
        </p>
      </div>
    </div>
    <the-copyright-footer />
  </section>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { usePageStore } from '@/store/pinia/page';
import { useAccountStore } from '@/store/pinia/account';

import utils from '../resources/utils';

import TitleBlock from '@/components/TitleBlock';
import TheCopyrightFooter from '@/components/TheCopyrightFooter';
import AppLoadingSpinner from '@/components/AppLoadingSpinner';

const route = useRoute();
const router = useRouter();
const pageStore = usePageStore();
const accountStore = useAccountStore();
const props = defineProps(['pageName']);
const isApp = utils.isOpenedWithinElectronShell();
const isLoginRequested = ref(false);

onMounted(() => {
  pageStore.update({
    pageName: props.pageName,
  });
});

function retryLoginViaBrowser() {
  const { id, pwd, flow } = route.query;
  const hostUrl = `${window.location.origin}/lauth/nowhere`;
  ipcApi.send({
    name: 'openExternal',
    url: `${hostUrl}?returnUrl=${accountStore.baseUrl}/app/post-login&id=${id}&pwd=${pwd}&flow=${flow}`,
  });
}

function redirectToAuth() {
  const { id, pwd, flow } = route.query;
  const hostUrl = `${window.location.origin}/lauth/nowhere`;
  if (isApp) {
    if (isLoginRequested.value) {
      return;
    }
    isLoginRequested.value = true;
    ipcApi.send({
      name: 'openExternal',
      url: `${hostUrl}?returnUrl=${accountStore.baseUrl}/app/post-login&id=${id}&pwd=${pwd}&flow=${flow}`,
    });
  } else {
    if (flow === 'pr-invite') {
      window.location.href = `${hostUrl}?returnUrl=${accountStore.baseUrl}/room/${id}&pwd=${pwd}&flow=${flow}`;
    } else {
      window.location.href = `${hostUrl}?returnUrl=${accountStore.baseUrl}/meeting/${id}&pwd=${pwd}&flow=${flow}`;
    }
  }
}

function redirectToGuestLogin() {
  const { id, pwd, flow } = route.query;
  if (flow === 'pr-invite') {
    router.push(`/join-as-guest?id=${id}&pwd=${pwd}&flow=pr-invite`);
    return;
  }
  router.push(`/join-as-guest?meetingId=${id}&pwd=${pwd}&flow=meeting-invite`);
}
</script>

<style lang="scss" scoped>
section {
  flex-direction: column;
}
.content {
  text-align: center;
  margin-top: auto;
  .opening-now {
    margin-top: rem(18px);
    font-size: rem(16px);
    font-weight: 300;
    text-align: center;
    a {
      font-weight: 500;
    }
  }
  .form-heading {
    @include type-size('large');
    font-weight: 100;
  }
}

h2 + p {
  margin-top: rem(27px);
}

.actions {
  width: rem(230px);
  margin: rem(25px) auto 0 auto;
}

button + button {
  margin-top: rem(14px);
}
</style>
