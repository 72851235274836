import BixeApi from '@/services/bixe-api.service';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    app.config.globalProperties.$API = BixeApi;
  },
};
