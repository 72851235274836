import { createApp } from 'vue';
import App from '@/App.vue';
import { router } from '@/router';
import { createPinia } from 'pinia';
import BixeApi from '@/plugins/bixe-api.plugin';
import Connectivity from '@/plugins/connectivity.plugin';
import DeviceManager from '@/plugins/device-manager.plugin';
import MeetingManager from '@/plugins/meeting-manager.plugin';
import VideoManager from '@/plugins/video-manager.plugin';
import eventEmitter from '@/plugins/event-emitter.plugin';
import KeyboardShortcuts from '@/plugins/keyboard-shortcuts.plugin';
import Sfx from '@/plugins/sfx.plugin';
import ReadinessChecker from '@/plugins/readiness-checker.plugin';
import vClickOutside from 'click-outside-vue3';
import ResizeTextarea from 'resize-textarea-vue3';
import errorReportingService from './services/errorReportingService';

const pinia = createPinia();
const app = createApp(App);

if (window.__INITIAL_STATE__.config?.sentry?.isEnabled) {
  errorReportingService.setConfigParams({
    app,
    env: window.__INITIAL_STATE__.config.environment,
    dsn: window.__INITIAL_STATE__.config.sentry.dsn,
  });
}
app.use(router);
app.use(pinia);
app.use(BixeApi);
app.use(DeviceManager);
app.use(MeetingManager);
app.use(VideoManager);
app.use(Connectivity);
app.use(eventEmitter);
app.use(KeyboardShortcuts);
app.use(Sfx);
app.use(ReadinessChecker);
app.use(vClickOutside);
app.use(ResizeTextarea);

export { app, router };
