import { nM } from '@/legacy';
import { useStorage } from 'vue3-storage';
import utils from '../resources/utils';

const localStorage = useStorage({ namespace: '', storage: 'local' });

export default () => (to, from, next) => {
  if (utils.isOpenedWithinElectronShell()) {
    return next();
  }

  const config = window.__INITIAL_STATE__.config;

  const isPlatformInvitationViewed = localStorage.getStorageSync('platform.invitationViewed') > 0;

  if (nM.detector.isSafari() && !config.safari?.allowed && isPlatformInvitationViewed) {
    return next('/browser-not-supported');
  }

  if (nM.detector.isFirefox() && !config.firefox?.allowed && isPlatformInvitationViewed) {
    return next('/browser-not-supported');
  }

  return next();
};
