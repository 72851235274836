<template>
  <div class="web" :style="containerStyle" ref="container">
    <svg :viewBox="viewBox">
      <talking-web-line
        v-for="(line, index) in talkingList"
        :to="line.to"
        :from="line.from"
        :index="index"
        :limit="LIMIT"
        :checkId="line.checkId"
        :lineCounts="lineCounts"
        :key="line.key"
      />
      <g class="tracks">
        <talking-web-line
          v-for="(line, index) in trackList"
          :to="line.to"
          :from="line.from"
          :index="index"
          :limit="trackList.length * 10"
          :key="line.key"
          :track="true"
        />
      </g>
    </svg>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'underscore';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';
import TalkingWebLine from '@/components/TalkingWebLine';

const frameStore = useFrameStore();
const usersStore = useUsersStore();
const phaseStore = usePhaseStore();
const LIMIT = 35;

const talkingList = ref([]);
const lineCounts = ref({});
const lastUserTalking = ref(null);
const displayed = ref(true);
const container = ref(null);

onMounted(() => {
  // setInterval(addRandom, 1000);
});

const { usersVisibleList, lastTalking } = storeToRefs(usersStore);
const { contWidth, contHeight } = storeToRefs(frameStore);
const { currentPhase, phaseStage } = storeToRefs(phaseStore);

const viewBox = computed(() => `0 0 ${contWidth.value} ${contHeight.value}`);

const containerStyle = computed(() => {
  return {
    top: '0px',
    left: '0px',
    width: contWidth.value + 'px',
    height: contHeight.value + 'px',
  };
});

const trackList = ref([]);
const currentUserList = ref([]);

function generateTrackList(nv) {
  if (_.isEqual(currentUserList.value, nv)) {
    return false;
  }
  currentUserList.value = nv;
  let list = [];
  let done = [];
  usersVisibleList.value.forEach((from) => {
    usersVisibleList.value.forEach((to) => {
      if (!done.includes(to)) {
        list.push({
          to: to,
          from: from,
          key: _.uniqueId('track_'),
        });
      }
    });
    done.push(from);
  });
  trackList.value = list;
}

watch(
  lastTalking,
  (nv) => {
    if (currentPhase.value !== 'dialogue' || phaseStage.value === 'topic') {
      return false;
    }
    if (nv && lastUserTalking.value !== nv && displayed.value) {
      debouncedAddToList(parseInt(nv));
    }
  },
  { immediate: true },
);

watch(usersVisibleList, generateTrackList, { immediate: true });
watch(
  currentPhase,
  (nv, ov) => {
    if (nv === 'freeform' && ov === 'dialogue') {
      talkingList.value = [];
      lineCounts.value = {};
      lastUserTalking.value = null;
    }
    if (nv === 'dialogue' && lastTalking.value) {
      debouncedAddToList(parseInt(lastTalking.value));
    }
  },
  { immediate: true },
);

function addToList(id) {
  if (displayed.value) {
    const listLength = talkingList.value.length;
    let item = {
      to: id,
      key: _.uniqueId('line_'),
      from: lastUserTalking.value ? lastUserTalking.value : false,
    };

    lastUserTalking.value = parseInt(id);
    item.checkId = item.from ? item.from + '-' + item.to : 'solo-' + item.to;

    addToCounts(item);

    if (listLength === LIMIT) {
      let remove = talkingList.value.pop();
      removeFromCounts(remove);
    }

    talkingList.value.unshift(item);
  }
}
const debouncedAddToList = _.debounce(addToList, 1000);

function lineIds({ to, from }) {
  if (from) {
    return [from + '-' + to, to + '-' + from]; //TODO: is this right?
  } else {
    return ['solo-' + to];
  }
}

function addToCounts(item) {
  lineIds(item).forEach((id) => {
    if (lineCounts.value[id]) {
      lineCounts.value[id]++;
    } else {
      lineCounts.value[id] = 1;
    }
  });
}
function removeFromCounts(item) {
  lineIds(item).forEach((id) => {
    if (lineCounts.value[id]) {
      lineCounts.value[id]--;
    } else {
      lineCounts.value[id] = 0;
    }
  });
}

// function addRandom() {
//   if (usersVisibleList.value.length > 0) {
//     const user = _.sample(usersVisibleList.value);
//     addToList(user);
//   }
// }
</script>

<style lang="scss" scoped>
.web {
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  position: relative;
  svg {
    transition: opacity 200ms;
    height: 100%;
    width: 100%;
  }
}
</style>
