<template>
  <div class="nowhere poster">
    <transition name="slowFade" v-if="meetingStore.initTitleCardShown && usersStore.firstShown">
      <div class="propsFrame poster">
        <avatars-sofa />
      </div>
    </transition>
  </div>
</template>

<script>
import AvatarsSofa from '@/components/AvatarsSofa';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    return { meetingStore, usersStore };
  },
  components: {
    AvatarsSofa,
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.propsFrame {
  @include sidebar-response;
}
</style>
