<template>
  <div class="zen" :class="showClass">
    <div class="bg stars"></div>
    <div class="bg-farBack bg"></div>
    <div class="bg-back bg"></div>
    <div class="bg-mid bg"></div>
    <Transition name="basic">
      <div class="bg sakura static" v-if="!featuresStore.useSpaceAnimation"></div>
    </Transition>
    <div class="particles sakura" :class="[featuresStore.useSpaceAnimation ? 'animated' : 'static']">
      <div v-for="p in particles" class="particle-move" :style="`--down: ${p.down}vh; --time: ${p.time};`" :key="p.id">
        <div class="particle-spin particle" :class="p.class" :style="`--scale: ${p.scale}; --tint: ${p.tint}; --spinTime: ${p.spinTime};`"></div>
      </div>
    </div>
    <div class="bg-front bg"></div>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

export default {
  setup() {
    const featuresStore = useFeaturesStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { featuresStore, phaseStore, usersStore };
  },
  data() {
    return {
      particles: [],
      interval: null,
      animationLength: 30000,
    };
  },
  mounted() {
    this.interval = setInterval(this.addParticle, 1600);
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['me']),
    animationLengthMs() {
      return this.animationLength + 'ms';
    },
    showClass() {
      if (this.usersStore.firstShown) {
        if (this.me?.away) {
          return ['show', 'break'];
        } else if (
          this.phaseStore.currentPhase === 'waiting' ||
          this.phaseStore.currentPhase === 'finish' ||
          this.phaseStore.currentPhase === 'ended' ||
          this.phaseStore.settingUpPhase === 'breakTime'
        ) {
          return ['show', 'showMountain'];
        } else {
          return ['show'];
        }
      } else {
        return ['hide'];
      }
    },
  },
  methods: {
    addParticle() {
      if (!this.featuresStore.useSpaceAnimation) {
        return false;
      }
      this.particles.push({
        id: _.uniqueId(),
        class: _.sample(['petal-1', 'petal-2', 'petal-3', 'petal-4', 'petal-5']),
        down: Math.random() * 60 + 20,
        scale: Math.random() * 0.7 + 0.3,
        tint: Math.random() * 27 + 70 + '%',
        spinTime: Math.random() * 20 + 4 + 's',
        time: this.animationLength - Math.random() * 15000 + 'ms',
      });
      _.delay(() => {
        this.particles.shift();
      }, this.animationLength + 200);
    },
  },
};
</script>
<style lang="scss" scoped>
.zen {
  pointer-events: none;
  background: var(--c__bg);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg.stars {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 50%;
  right: 0;
  background-image: radial-gradient(white, rgba(255, 255, 255, 0.2) 2px, transparent 4px),
    radial-gradient(white, rgba(255, 255, 255, 0.15) 1px, transparent 3px), radial-gradient(white, rgba(255, 255, 255, 0.1) 2px, transparent 0.25em),
    radial-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) 2px, transparent 0.5em),
    radial-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.1) 2px, transparent 0.5em);
  background-size: 47em 29em, 17em 41em, 29em 47em, 23em 17em, 49em 23em;
  background-position: 0 0, 3vw 5vh, 30vw 11vh, 7vw 13vh, 13vw 1vh;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
}
.meeting .background {
  opacity: 1;
  background: var(--c__bg-alt);
}

.bg-farBack {
  z-index: 1;
}
.bg-back {
  z-index: 2;
}
.bg-mid {
  z-index: 3;
}
.particles {
  z-index: 4;
}
.bg-front {
  z-index: 8;
}

.bg {
  background-position: right bottom;
  opacity: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-farBack {
  opacity: 0;
  transition: transform 0.8s ease-in-out 0.8s, right $avatar-animation-duration $avatar-ease;
  transform: translateY(8%);
  background-image: url($base-url + '/assets/images/spaces/zen/zen-sun.png');
  @include transition-optional;
}
.bg-back {
  opacity: 0;
  transform: translateY(5%);
  transition: transform 0.6s ease-in-out 0.5s, right $avatar-animation-duration $avatar-ease;
  background-image: url($base-url + '/assets/images/spaces/zen/zen-mountain.png');
  @include transition-optional;
}
.bg-mid {
  transition: transform 0.4s ease-in-out, right $avatar-animation-duration $avatar-ease;
  background-image: url($base-url + '/assets/images/spaces/zen/zen-floor.png');
  @include transition-optional;
}
.bg-front {
  transition: transform 0.4s ease-in-out, right $avatar-animation-duration $avatar-ease;
  transform: translateX(10%);
  background-image: url($base-url + '/assets/images/spaces/zen/zen-tree.png');
  @include transition-optional;
}

.bg.sakura {
  background-image: url($base-url + '/assets/images/spaces/zen/just-sakura.png');
  background-size: contain;
  z-index: 4;
}

.show {
  .bg-front,
  .bg-mid,
  .bg.sakura,
  .particles {
    opacity: 1;
    transform: translateX(0) translateY(0);
  }
}

.break {
  .bg-front {
    right: rem(300px);
  }
  .bg-mid {
    right: rem(300px);
  }
}

.showMountain {
  .bg-farBack,
  .bg-back {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes sakura-move {
  0% {
    transform: translateX(0vw) translateY(0vh) translateZ(0);
  }
  100% {
    transform: translateX(-110vw) translateY(var(--down)) translateZ(0);
  }
}
@keyframes sakura-spin {
  from {
    transform: rotate(0deg) scale(var(--scale)) translateZ(0);
  }
  to {
    transform: rotate(359deg) scale(var(--scale)) translateZ(0);
  }
}

.particles {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  bottom: 0;
  opacity: 0;

  transition: all 0.8s ease-in-out;
  .particle-move {
    position: absolute;
    top: 0;
    right: -2vw;
    animation: sakura-move var(--time) linear;
  }
  .particle {
    height: 2em;
    width: 2em;
    // border-radius: 100% 0 100% 0;
    // background: hsl(330deg, 80%, var(--tint)) linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    animation: sakura-spin var(--spinTime) infinite linear;
    &.petal-1 {
      background-image: url($base-url + '/assets/images/spaces/zen/petal-1.png');
    }
    &.petal-2 {
      background-image: url($base-url + '/assets/images/spaces/zen/petal-2.png');
    }
    &.petal-3 {
      background-image: url($base-url + '/assets/images/spaces/zen/petal-3.png');
    }
    &.petal-4 {
      background-image: url($base-url + '/assets/images/spaces/zen/petal-4.png');
    }
    &.petal-5 {
      background-image: url($base-url + '/assets/images/spaces/zen/petal-5.png');
    }
  }
}

@mixin dark-zen-background {
  // background: linear-gradient(to bottom, rgb(12, 7, 28), rgb(12, 43, 114), rgb(92, 119, 153) 70%);
  background: linear-gradient(to top, #4b6081 rem(20px), #19001e rem(768px));
  .stars {
    display: block;
    opacity: 1;
    mix-blend-mode: screen;
  }
  .bg-farBack {
    filter: hue-rotate(200deg) blur(10px);
  }
  .bg-back {
    filter: brightness(25%) sepia(0.9) hue-rotate(180deg) contrast(140%);
  }

  .bg-mid {
    filter: brightness(0.4) contrast(1.7) sepia(0.9) hue-rotate(200deg);
  }
  .bg-front {
    filter: brightness(0.5) sepia(0.4) contrast(1.8) saturate(1) hue-rotate(-70deg);
  }

  .particle {
    // background: hsl(330deg, 80%, var(--tint)) linear-gradient(rgba(20, 5, 106, 0.5), rgba(20, 5, 106, 0));
    // border-right: 1px solid hsl(330deg, 80%, var(--tint));
  }
}

.dark-theme .zen {
  @include dark-zen-background;
}
@media (prefers-color-scheme: dark) {
  .dynamic-theme .zen {
    @include dark-zen-background;
  }
}
</style>
