import 'regenerator-runtime/runtime';
import { app, router } from './app';
import './assets/sass/main.scss';

if (module.hot) {
  module.hot.accept();
}
router.isReady().then(() => {
  app.mount('#app');
});
