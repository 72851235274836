<template>
  <div class="alert microskill tailless" :class="alertClasses">
    <button class="close close-top-alert" @click="close" @keyup.space="(e) => e.preventDefault()">
      <svg version="1.1" viewBox="0 0 18 18" pointer-events="none">
        <circle cx="9" cy="9" r="8.8" />
        <path d="M9,8.4l3.5-3.5l0.6,0.6L9.6,9l3.5,3.5l-0.6,0.6L9,9.6l-3.5,3.5l-0.6-0.6L8.4,9L4.9,5.5l0.6-0.6L9,8.4z" />
      </svg>
    </button>
    <div class="content">
      <h1 v-if="heading" class="messageTitle heading" v-html="heading" />
      <p v-if="content" class="body" v-html="content" />

      <div v-if="alert.buttons" class="buttons btn-pair">
        <button
          v-for="(button, i) in alert.buttons"
          :key="'button-' + i"
          :class="{ halfButton: alert.buttons.length > 1 }"
          @click="handleButton(button)"
          @keyup.space="(e) => e.preventDefault()"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import _ from 'underscore';
import { computed, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import utils from '../resources/utils';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import { useMessagesStore } from '@/store/pinia/messages';

const props = defineProps(['alert']);
const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;

const usersStore = useUsersStore();
const phaseStore = usePhaseStore();
const messagesStore = useMessagesStore();
const { owner, speaking, me } = storeToRefs(usersStore);
const { currentPhase } = storeToRefs(phaseStore);

const alertClasses = computed(() => {
  let alertClass = props.alert.class || '';
  return [alertClass, currentPhase.value, props.alert.messageName];
});
const heading = computed(() => (props.alert.heading ? parseText(props.alert.heading) : false));
const content = computed(() => (props.alert.content ? parseText(props.alert.content) : false));
const otherUser = computed(() => {
  if (props.alert.user) {
    return usersStore.getUserById(props.alert.user);
  } else {
    return { first_name: 'Someone' };
  }
});

let removeTimeout = false;

onMounted(() => {
  // handle Autoremove
  if (props.alert.autoRemove) {
    removeTimeout = setTimeout(() => {
      props.alert.resolve('autoRemove');
      messagesStore.removeAlert(props.alert);
    }, props.alert.autoRemove);
  }
});

onUnmounted(() => {
  clearTimeout(removeTimeout);
});

function parseText(text, args) {
  if (!text) {
    return '';
  }
  text = _.isFunction(text) ? text(args) : text;
  text = replaceLineBreaks(text);
  text = replaceOwner(text);
  text = replaceOtherUser(text);
  text = replaceUser(text);
  text = replaceSpeaker(text);
  return utils.desanitizeString(text);
}

function replaceLineBreaks(p) {
  return p.replace(/\r\n|\r|\n/g, '<br/>');
}

function replaceOwner(p) {
  return owner.value ? p.replace('OWNER', `<span class="tipOwner messageName">${utils.desanitizeString(owner.value.first_name)}</span>`) : p;
}

function replaceUser(p) {
  return me.value ? p.replace('USER', `<span class="tipMe messageName">${utils.desanitizeString(me.value.first_name)}</span>`) : p;
}

function replaceOtherUser(p) {
  return otherUser.value ? p.replace('OTHER_USER', `<span class="tipMe messageName">${utils.desanitizeString(otherUser.value.first_name)}</span>`) : p;
}

function replaceSpeaker(p) {
  if (speaking.value) {
    return p.replace('SPEAKER', `<span class="tipSpeaker messageName">${utils.desanitizeString(speaking.value.first_name)}</span>`);
  } else {
    return p.replace('SPEAKER', 'someone');
  }
}

function close() {
  props.alert.reject('closed');
  messagesStore.removeAlert(props.alert);
}

function handleButton(button) {
  if (button.isResolve) {
    props.alert.resolve(button.value);
  }
  if (button.isReject) {
    props.alert.reject(button.value);
  }
  if (button.event) {
    $ee.emit(button.event, props.alert);
  }
  messagesStore.removeAlert(props.alert);
}
</script>
<style lang="scss" scoped>
.alert {
  position: relative;
  background: var(--c__bg-frosted);
  filter: drop-shadow(0px 1px 10px var(--c__shadow));
  transform-origin: rem(24px) rem(-24px);
  will-change: filter;
  backface-visibility: hidden;
  margin-bottom: rem(4px);
  -webkit-backface-visibility: hidden; /* Chrome and Safari */
  -moz-backface-visibility: hidden; /* Firefox */

  .close {
    position: absolute;
    top: rem(8px);
    right: rem(8px);
    height: rem(18px);
    width: rem(18px);
    background: transparent;
    padding: 0;
    border: none;
    transform: scale(0);
    opacity: 0;
    transition: all 0.3s;

    @include triggered {
      transform: scale(1);
      opacity: 1;
      circle {
        fill: var(--c__text);
      }
      path {
        fill: var(--c__bg);
      }
    }
    svg {
      width: 100%;
    }
    circle {
      stroke-width: 0.5;
      fill: none;
      stroke: var(--c__text);
    }
    path {
      fill: var(--c__text);
    }
  }

  @include triggered {
    .close {
      transform: scale(1);
      opacity: 1;
    }
  }

  .content {
    padding: rem(24px);
  }

  h1 {
    @include type-size(small);
    font-weight: bold;
    margin-right: 1.5em;
    margin-bottom: 0.5em;
  }

  p {
    font-weight: bold;
    @include type-size('medium');
    text-align: center;
  }

  p:last-child {
    margin-bottom: 0;
  }

  &.connectionTrouble .body {
    color: var(--c__notice);
  }

  .buttons {
    --c__button: var(--c__text);
    border-width: 2px;
    height: rem(50px);
    max-width: rem(232px);
    margin: rem(22px) auto 0 auto;
  }

  .messageName {
    font-weight: bold;
  }

  .proposalStem {
    color: var(--c__breakthrough);
  }
}

@keyframes flash-on-tip {
  0%,
  3%,
  4%,
  7%,
  100% {
    color: var(--c__text);
    background: var(--c__bg);
    border-color: var(--c__text);
  }

  1%,
  2%,
  5%,
  6% {
    color: var(--c__bg);
    background: var(--c__text);
    border-color: var(--c__text);
  }
}

@keyframes flash-on-tip-constant {
  0%,
  20%,
  80%,
  100% {
    color: var(--c__text);
    background: var(--c__bg);
    border-color: var(--c__text);
  }

  40%,
  60% {
    color: var(--c__bg);
    background: var(--c__text);
    border-color: var(--c__text);
  }
}

.alert.youNeedToEndYourTurn .buttons button {
  animation: flash-on-tip 30s infinite linear 30s;
  .alarmRunning & {
    animation: flash-on-tip-constant 1s infinite linear;
  }
}
</style>
