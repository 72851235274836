<template>
  <div id="connectivityCore">
    <avatar-loader v-if="showVideo" connectivity="true" />
    <section v-show="showVideo" class="videoPreview" :class="[hasSpinner ? 'loading' : 'loaded']">
      <div id="connectivityVideos" class="videos">
        <div class="userPic">
          <img v-if="user && avatar" id="connectivityImg" :src="avatar" />
          <app-scaled-text v-if="!avatar">{{ initials }}</app-scaled-text>
        </div>
        <div class="connectivityVideo" :class="{ stacked: isVideoDevicesAvailable }">
          <video id="connectivityVideo" @loadeddata="onConnectivityVideoLoaded" />
        </div>
        <audio id="connectivityAudio" class="connectivityAudio" />
      </div>
    </section>

    <div class="deviceSelect">
      <connectivity-settings-device type="video" device="camera" :currentId="videoSource" :on="videoOn" @change="onVideoDeviceChanged" direction="in" />
      <connectivity-settings-device
        type="audio"
        device="microphone"
        :currentId="audioSource"
        :on="isAudioInputEnabled"
        @change="onAudioDeviceChanged"
        direction="in"
      />
      <connectivity-settings-device
        v-if="!isFirefox"
        type="audio"
        device="speaker"
        :currentId="audioOutputSource"
        :on="isAudioOutputEnabled"
        @change="onAudioOutputDeviceChanged"
        direction="out"
      />
    </div>
  </div>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useAccountStore } from '@/store/pinia/account';
import { useMediaStore } from '@/store/pinia/media';
import { usePageStore } from '@/store/pinia/page';
import { useUsersStore } from '@/store/pinia/users';

import AppScaledText from '@/components/AppScaledText.vue';
import ConnectivitySettingsDevice from '@/components/ConnectivitySettingsDevice.vue';
import AvatarLoader from '@/components/AvatarLoader.vue';
import utils from '../resources/utils';

export default {
  components: {
    AppScaledText,
    AvatarLoader,
    ConnectivitySettingsDevice,
  },
  props: ['showVideo'],
  setup() {
    const accountStore = useAccountStore();
    const pageStore = usePageStore();
    const mediaStore = useMediaStore();
    const usersStore = useUsersStore();
    return { accountStore, pageStore, mediaStore, usersStore };
  },
  data: function () {
    return {
      isChanged: {
        audioDevice: false,
        audioOutputDevice: false,
      },
    };
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['audioSource', 'audioOutputSource', 'videoSource', 'audioOn', 'audioOutputOn', 'videoOn']),
    ...mapPiniaState(useUsersStore, {
      hasSpinner(s) {
        return s.userHasSpinner('connectivity');
      },
    }),
    hasAudioIn() {
      return this.devices.filter((d) => d.kind === 'audioInput').length > 0;
    },
    hasAudioOut() {
      return this.devices.filter((d) => d.kind === 'audioOutput').length > 0;
    },
    hasVideoIn() {
      return this.devices.filter((d) => d.kind === 'videoInput').length > 0;
    },
    isFirefox() {
      return navigator.userAgent.indexOf('Firefox') != -1;
    },
    hasDevices() {
      if (!this.placeholderTimeOut) {
        return false;
      }
      if (this.isFirefox) {
        return this.hasAudioIn && this.hasVideoIn;
      } else {
        return this.hasAudioIn && this.hasAudioOut && this.hasVideoIn;
      }
    },
    isConnectivityCheckPage() {
      return this.pageStore.current.pageName === 'connectivity';
    },
    user() {
      return this.accountStore.currentUser;
    },
    avatar() {
      return this.user && this.user.picture_url;
    },
    initials() {
      return this.user ? utils.desanitizeString(this.user.first_name[0].toUpperCase()) + utils.desanitizeString(this.user.last_name[0].toUpperCase()) : 'ME';
    },
    isVideoDevicesAvailable() {
      return this.videoOn && this.videoSource && this.videoSource.length > 0;
    },
    isAudioInputEnabled() {
      return this.isConnectivityCheckPage && !this.isChanged.audioDevice ? true : this.audioOn;
    },
    isAudioOutputEnabled() {
      return this.isConnectivityCheckPage && !this.isChanged.audioOutputDevice ? true : this.audioOutputOn;
    },
  },
  methods: {
    onAudioDeviceChanged(deviceId) {
      this.isChanged.audioDevice = true;
      this.mediaStore.setAudioOn(!!deviceId);
      this.mediaStore.setAudioSource(deviceId);
      this.$emit('audio:change', deviceId);
    },
    onAudioOutputDeviceChanged(deviceId) {
      this.isChanged.audioOutputDevice = true;
      this.mediaStore.setAudioOutputOn(!!deviceId);
      this.mediaStore.setAudioOutputSource(deviceId);
      this.$emit('audio-output:change', deviceId);
    },
    onConnectivityVideoLoaded() {
      this.usersStore.setVideoLoaded('connectivity', true);
    },
    onVideoDeviceChanged(deviceId) {
      this.mediaStore.setVideoOn(!!deviceId);
      this.mediaStore.setVideoSource(deviceId);
      this.$emit('video:change', deviceId);
    },
  },
};
</script>
<style lang="scss" scoped>
.userPic img {
  width: 100%;
  object-fit: cover;
}
section.videoPreview {
  height: rem(300px);
  background: var(--c__bg);
  border-width: 2px;
  border-color: var(--c__avatar-border-edge);
  border-style: var(--border-style__avatar);
  border-radius: 50% !important;
  overflow: hidden;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  margin-bottom: 2em;
  transition: all 250ms ease-in-out;
  &.loading {
    opacity: 0;
    transform: scale(0);
  }
}

.loadingAvatar {
  height: rem(262px);
  width: rem(262px);
  z-index: 10;
}

.videos {
  position: absolute;
  top: 2.8%;
  left: 2.8%;
  bottom: 2.8%;
  right: 2.8%;
  border-radius: 50% !important;
  overflow: hidden;
  background: var(--grad__initials);

  :deep(text) {
    fill: var(--c__bg);
  }

  .connectivityVideo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 50%;
    overflow: hidden;
    &.stacked {
      z-index: 2;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: black;
      border-radius: 50%;
      animation: fadeOut 300ms 400ms forwards;
    }
    video {
      transform: rotateY(180deg);
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
    }
  }
}
</style>
