import { nM } from '@/legacy';
import { eventEmitter } from '@/services/event-emitter.service';
import { requestRestartVideo } from '../shared/constants';
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import { useMomentsStore } from '@/store/pinia/moments';
import { useUsersStore } from '@/store/pinia/users';
import { useTimeStore } from '@/store/pinia/time';
import { useFeaturesStore } from '@/store/pinia/features';
import utils from '@/resources/utils.js';
import errorReportingService from '../services/errorReportingService';

export default (vueApp) => {
  const recentActionsRequest = [];

  const pageStore = usePageStore();
  const meetingStore = useMeetingStore();
  const mediaStore = useMediaStore();
  const phaseStore = usePhaseStore();
  const sidebarStore = useSidebarStore();
  const momentsStore = useMomentsStore();
  const usersStore = useUsersStore();
  const timeStore = useTimeStore();
  const featuresStore = useFeaturesStore();

  vueApp.$ee.on('startTalking', () => {
    const me = usersStore.me;
    if (me.muted || me.muted_by_system) {
      usersStore.updateUser(me.id, { talking: true });
      vueApp.$meetingmanager.startTalking();
    }
  });

  vueApp.$ee.on('stopTalking', () => {
    const me = usersStore.me;
    if (me.muted || me.muted_by_system) {
      const muted = me.muted_by_system ? { muted_by_system: true } : { muted: true };
      usersStore.updateUser(me.id, {
        talking: false,
        ...muted,
      });
      vueApp.$meetingmanager.stopTalking();
    }
  });

  vueApp.$meetingmanager.on('error', (err) => {
    if (err.error.code === 'MeetingIsFinished') {
      vueApp.$meetingmanager.disconnect();
      vueApp.$router.push(`/meeting-has-been-ended`).catch(() => null);
    } else {
      errorReportingService.reportError(`meetingManagerError - ${nM.toErrorMessage(err)}`);
      eventEmitter.emit('api:alertAdd', {
        type: 'misc',
        class: 'error',
        hideOnLayout: true,
        duration: 30000,
        content: nM.toErrorMessage(err),
      });
    }
  });

  vueApp.$meetingmanager.on('success', (e) => {
    eventEmitter.emit('api:alertAdd', {
      type: 'misc',
      class: 'success',
      duration: 'temporary',
      content: e.message,
    });
  });

  vueApp.$meetingmanager.on('meeting_state', (meetingState, firstRun, isResync) => {
    if (firstRun) {
      const origin = window.location.origin;
      const baseUrl = window.__INITIAL_STATE__.config.baseUrl;
      const meeting = meetingState.meeting;
      let args = {
        meetingUrl: `${origin}${baseUrl}/meeting/${meeting.id}?pwd=${meeting.pin_pass}`,
      };
      if (meeting.meta?.prInviteDetails?.pin) {
        const { pin, pinPass } = meeting.meta.prInviteDetails;
        args.personalRoomUrl = `${origin}${baseUrl}/room/${pin}?pwd=${pinPass}`;
        args.prInviteDetails = { pin, pass: pinPass };
      }

      if (isResync && featuresStore.sandbox) {
        return false;
      }
      eventEmitter.emit('api:startMeeting', meetingState, args);
      phaseStore.initMeeting(meetingState, isResync);
      usersStore.initMeeting(meetingState, isResync);
      meetingStore.initMeeting({ ...meetingState, ...args }, isResync);
      momentsStore.initMeeting(meetingState, isResync);
      sidebarStore.initMeeting(meetingState, isResync);
      timeStore.initMeeting(meetingState, isResync);
      mediaStore.initMeeting(meetingState, isResync);
    } else {
      eventEmitter.emit('api:updateState', meetingState);
    }
  });

  vueApp.$meetingmanager.on('user:away', (e) => {
    const user = usersStore.me;

    const ids = e.users.map((x) => x.id);
    if (ids.indexOf(user.id) > -1) {
      vueApp.$videomanager.stop(true);
    }
  });

  vueApp.$meetingmanager.on('user:unaway', (e) => {
    const user = usersStore.me;

    const ids = e.users.map((x) => x.id);
    if (ids.indexOf(user.id) > -1) {
      vueApp.$videomanager.connect();
    }
  });

  vueApp.$meetingmanager.on('user:left', async (e) => {
    const ids = e.users.map((x) => x.id);

    const user = usersStore.me;
    const meetingId = meetingStore.meetingId;

    if (ids.indexOf(user.id) > -1) {
      if (utils.isOpenedWithinElectronShell()) {
        ipcApi.send({ name: 'releaseWakeLock' });
      }
      if (user.meta && user.meta.guest) {
        await vueApp.$API.attemptLogout(true);
      }
      const destination = user.meta && user.meta.guest ? '/meeting-over' : '/meeting-feedback';
      eventEmitter.emit('navigate', `${destination}?id=${meetingId}`, { global: true });
    }
  });

  vueApp.$meetingmanager.on('user:joiningRequestApproved', (e) => {
    const ids = e.users.map((x) => x.id);
    const user = usersStore.me;
    if (ids.indexOf(user.id) > -1) {
      // console.log('user:joiningRequestApproved!!', JSON.stringify({ muteAudio: !mediaStore.audioOn, muteVideo: !mediaStore.videoOn }));
      vueApp.$meetingmanager.onJoiningRequestApproved({ muteAudio: !mediaStore.audioOn, muteVideo: !mediaStore.videoOn });
      if (vueApp.$videomanager.initialized) {
        usersStore.onWaitingRoomRequestApproved(e, true);
        return;
      }
      vueApp.$videomanager.once('ready', () => {
        usersStore.onWaitingRoomRequestApproved(e, true);
        vueApp.$videomanager.connect();
      });

      vueApp.$API.getMeetingSession(meetingStore.meetingId).then((session) => {
        vueApp.$videomanager.init(session, {
          externalAttendeeId: user.id,
          externalMeetingId: meetingStore.meetingId,
          pageName: pageStore.current.pageName,
        });
      });
      // window.location = window.location
    } else {
      usersStore.onWaitingRoomRequestApproved(e, false);
    }
  });

  vueApp.$meetingmanager.on('user:removedFromMeeting', (e, ejected) => {
    const ids = e.users.map((x) => x.id);

    const user = usersStore.me;
    if (ids.indexOf(user.id) > -1) {
      if (utils.isOpenedWithinElectronShell()) {
        ipcApi.send({ name: 'releaseWakeLock' });
      }
      if (user.meta && user.meta.guest) {
        vueApp.$videomanager.once('stopped', () => {
          vueApp.$meetingmanager.disconnect();
          eventEmitter.emit('navigate', `/ejected-from-meeting`);
          if (ejected) {
            vueApp.$API.attemptLogout(true); // logout in background, not needed .then/.finally
          }
        });
        vueApp.$videomanager.stop(true);
      } else {
        eventEmitter.emit('navigate', `/ejected-from-meeting`, { global: !!ejected });
      }
    }
  });

  vueApp.$meetingmanager.on('phase:stage:off', ({ user }) => {
    eventEmitter.emit('bus:user:stage:off', user.id);
  });

  vueApp.$meetingmanager.on('meeting:ended', async (e) => {
    const meetingId = e.meeting.id;
    const user = usersStore.me;
    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'releaseWakeLock' });
    }
    if (e.meeting.meta.autoClose) {
      eventEmitter.emit('navigate', `/meeting-timed-out`, { global: true });
    } else if (user.inWaitingRoom) {
      eventEmitter.emit('navigate', `/meeting-has-been-ended?id=${meetingId}`, { global: true });
    } else if (user.meta && user.meta.guest) {
      eventEmitter.emit('navigate', `/meeting-over?id=${meetingId}`, { global: true });
    } else {
      eventEmitter.emit('navigate', `/meeting-feedback?id=${meetingId}`, { global: true });
    }
  });

  vueApp.$meetingmanager.on('action:common.requestVideo', (data) => {
    const user = usersStore.me;
    const allRequestedAt = recentActionsRequest.map(({ requestedAt }) => requestedAt);

    if (data.requestedTo === user.id) {
      if (allRequestedAt.length) {
        const lastRequestedAt = Math.max(...allRequestedAt);
        const afterTimeout = new Date(lastRequestedAt + requestRestartVideo.restartAllowedAfter);
        const isAlreadyRequestedRecently = new Date() < afterTimeout;
        if (isAlreadyRequestedRecently) {
          return;
        }
      }
      vueApp.$videomanager.restart({ type: 'videoSource', devices: { videoSource: vueApp.$videomanager.videoSource } });
      data.requestedAt = Date.now();
      recentActionsRequest.push(data);
    }
  });
};
