<template>
  <section class="joinAsGuest introPage page" data-page-name="joinAsGuest">
    <div class="extra">
      <div class="content">
        <h2>Join as a guest</h2>
        <form id="joinMeetingForm" class="basicsForm" @submit.prevent="debouncedHandleForm">
          <transition-group name="basic">
            <div class="row" :class="{ hasError: firstError }" key="first">
              <label for="firstNameField" class="visuallyHidden">First Name</label>
              <input
                id="firstNameField"
                v-model.trim="first"
                type="text"
                name="firstname"
                placeholder="First Name..."
                @keyup="setFirstTimeout"
                @blur="checkFirst"
              />
              <transition name="basic">
                <p v-if="firstError" class="error">
                  {{ firstError }}
                </p>
              </transition>
            </div>
            <div class="row" :class="{ hasError: lastError }" key="last">
              <label for="lastNameField" class="visuallyHidden">Last Name</label>
              <input v-model.trim="last" type="text" id="lastNameField" name="lastname" placeholder="Last Name..." @keyup="setLastTimeout" @blur="checkLast" />
              <transition name="basic">
                <p v-if="lastError" class="error">
                  {{ lastError }}
                </p>
              </transition>
            </div>

            <app-button button-class="btn solo btn-border" key="continue" :disabled="disabled" @real-click="handleForm" @disabled-click="checkWrapper">
              Continue
            </app-button>
          </transition-group>
        </form>
        <p class="minor">
          By clicking 'Continue' you agree to our
          <template v-if="isOpenedWithinElectronShell">
            <a href="#" @click="openExternal('https://www.now-here.com/legals/tos-applications', true)">Terms of Service</a>,
            <a href="#" @click="openExternal('https://www.now-here.com/legals/privacy', true)">Privacy Policy</a> &amp;
            <a href="#" @click="openExternal('https://www.now-here.com/legals/eula', true)">EULA</a>
          </template>
          <template v-else>
            <a href="https://www.now-here.com/legals/tos-applications" target="_blank" rel="noopener">Terms of Service</a>,
            <a href="https://www.now-here.com/legals/privacy" target="_blank" rel="noopener">Privacy Policy</a> &amp;
            <a href="https://www.now-here.com/legals/eula" target="_blank" rel="noopener">EULA</a>
          </template>
        </p>

        <p class="minor">Have an account? <a href="#" @click.prevent="redirectToAuth('sign_in')">log in</a></p>
      </div>
      <the-copyright-footer />
    </div>
  </section>
</template>

<script>
import { usePageStore } from '@/store/pinia/page';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useAccountStore } from '@/store/pinia/account';
import AppButton from '@/components/AppButton';
import TheCopyrightFooter from '@/components/TheCopyrightFooter';
import _ from 'underscore';
import validate from 'validate.js';
import utils from '../resources/utils';

const rules = {
  presence: {
    message: 'Please fill out this field',
    allowEmpty: false,
  },
  format: {
    flag: 'gi',
    pattern: '^([^!@#$%^&*()_<>0-9+=\\\\]+)$',
    message: 'Name contains invalid characters',
  },
  length: {
    minimum: 1,
    maximum: 140,
    message: 'Name must be between 1 and 140 characters',
  },
};

export default {
  props: ['pageName'],
  components: {
    AppButton,
    TheCopyrightFooter,
  },
  setup() {
    const pageStore = usePageStore();
    const meetingStore = useMeetingStore();
    const accountStore = useAccountStore();
    return { pageStore, meetingStore, accountStore };
  },
  data() {
    return {
      first: '',
      last: '',
      loading: false,
      hasWrongInfo: false,
      firstError: false,
      lastError: false,
      fistTimeout: null,
      lastTimeout: null,
      debouncedHandleForm: _.debounce(this.handleForm, 500, true),
      isOpenedWithinElectronShell: utils.isOpenedWithinElectronShell(),
    };
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
    });
    let _this = this;
    this.$ee.on('nM:inlineMessage:wrongMeetingInfo', _this.wrongInfo);
  },
  unmounted() {
    let _this = this;
    this.$ee.off('nM:inlineMessage:wrongMeetingInfo', _this.wrongInfo);
  },
  computed: {
    password() {
      return (this.$route.query.pwd || '').trim();
    },
    meetingId() {
      return (this.$route.query.meetingId || '').trim();
    },
    capitalFirst() {
      return this.first.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    },
    capitalLast() {
      return this.first.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
    },
    disabled() {
      return !this.first || !this.last || this.firstError || this.lastError || this.loading;
    },
  },
  watch: {
    page() {
      this.loading = false;
    },
  },
  methods: {
    checkWrapper() {
      this.handleForm();
    },
    handleForm() {
      this.checkFirst();
      this.checkLast();
      if (!this.lastError && !this.firstError) {
        this.loading = true;
        const { flow, id, pwd } = this.$route.query;
        let data = {
          first_name: utils.sanitizeString(this.first),
          last_name: utils.sanitizeString(this.last),
        };
        if (this.meetingId && this.password) {
          data = { ...data, meeting_id: this.meetingId, password: this.password };
        }

        this.$API
          .createGuestUser(data)
          .then(() => {
            if (flow === 'pr-invite') {
              this.joinPersonalRoom({ id, pwd });
              return;
            }
            this.redirectToMeeting({ id: this.meetingId, pwd: this.password });
          })
          .catch((err) => {
            if (err?.error?.code === 'InvalidOperation') {
              this.$router.push(`/meeting-has-been-ended?id=${encodeURI(this.meetingId)}`).catch(() => null);
            } else {
              this.$ee.emit('nM:inlineMessage:roomGuestValidation');
            }
          });
      }
    },
    joinPersonalRoom({ id, pwd }) {
      utils.genericFns
        .joinPersonalRoomMeeting({ pinNumber: id, password: pwd })
        .then((meeting) => {
          utils.storage.ss.setItem(`platform.invitationViewed.${meeting.id}`, '1');
          this.redirectToMeeting({ id: meeting.id, pwd: meeting.pin_pass });
        })
        .catch((err) => {
          console.log('Unable to join personal room', err);
        });
    },
    redirectToMeeting({ id, pwd }) {
      window.location = `${this.accountStore.baseUrl}/meeting/${id}?pwd=${pwd}`;
    },
    redirectToAuth(action) {
      const { flow, id, pwd } = this.$route.query;
      if (flow === 'pr-invite') {
        /** login click handler for personal room invite link */
        if (this.isOpenedWithinElectronShell) {
          this.openExternal(
            `${window.location.origin}/lauth/nowhere?action=${action}&returnUrl=/conference/app/post-login&id=${id}&pwd=${pwd}&flow=pr-invite`,
            false,
          );
          return;
        }
        window.location = `/lauth/nowhere?returnUrl=/conference/room/${id}?pwd=${pwd}&action=${action}`;
      } else {
        /** login click handler for meeting invite link */
        if (this.isOpenedWithinElectronShell) {
          this.openExternal(
            `${window.location.origin}/lauth/nowhere?action=${action}&returnUrl=/conference/app/post-login&id=${this.meetingId}&pwd=${this.password}`,
            false,
          );
          return;
        }
        window.location = `/lauth/nowhere?returnUrl=/conference/meeting/${this.meetingId}?pwd=${this.password}&action=${action}`;
      }
    },
    setFirstTimeout() {
      clearTimeout(this.firstTimeout);
      this.firstTimeout = setTimeout(() => {
        this.checkFirst();
      }, 2000);
    },
    setLastTimeout() {
      clearTimeout(this.lastTimeout);
      this.lastTimeout = setTimeout(() => {
        this.checkLast();
      }, 2000);
    },
    checkFirst() {
      let errors = validate.single(this.first, rules);
      if (errors) {
        this.firstError = errors[0];
      } else {
        this.firstError = false;
      }
    },
    checkLast() {
      let errors = validate.single(this.last, rules);
      if (errors) {
        this.lastError = errors[0];
      } else {
        this.lastError = false;
      }
    },
    wrongInfo() {
      this.hasWrongInfo = true;
      this.loading = false;
    },
    openExternal(url, closeSelf) {
      ipcApi.send({ name: 'openExternal', url: url, closeSelf: closeSelf });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  position: relative;
}
.checker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  opacity: 0;
}
</style>
