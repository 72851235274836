<template>
  <div class="subContent" :class="statusClass">
    <transition-group name="basic-group" mode="out-in">
      <app-icon class="bandwidthCheckIcon" icon="bandwidth" :class="statusClass" key="icon" />
      <h2 key="heading">Let’s see how fast you are</h2>
      <p v-if="!status" key="checking">We are testing your connection speed&hellip;</p>
      <div v-if="!status" class="progress" key="progress">
        <div class="speedCheckBar" ref="speedCheckBar"></div>
      </div>
      <p v-if="status === 'poor'" key="poor">
        Your connection speed is poor, <strong>bixe</strong> should work okay but you could<br />switch off your video for a better experience.
      </p>
      <p v-if="status === 'good'" key="good">
        Your connection speed is fast. <strong>bixe</strong><br />
        should work just fine.
      </p>
      <button class="btn btn-border btn-medium btn-blue-flood" @click="moveToNextStep()" key="next">Next</button>
    </transition-group>
  </div>
</template>

<script>
import AppIcon from '@/components/AppIcon';
import { usePageStore } from '@/store/pinia/page';

export default {
  components: {
    AppIcon,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    return { pageStore };
  },
  data() {
    return {
      nextPageName: this.$readinessChecker.getAllCheckPointNames().checkHearing,
      status: false, // possible values : false, 'good', 'poor'
      progressNumber: 0,
    };
  },
  computed: {
    statusClass() {
      return this.status || 'pending';
    },
    barWidth() {
      if (this.status) {
        return '100%';
      } else {
        return this.progressNumber + '%';
      }
    },
  },
  mounted() {
    this.pageStore.update({
      pageName: this.pageName,
      isCheck: true,
    });
    this.$readinessChecker
      .markAsStarted(this.pageName)
      .checkNetworkConnectivity()
      .then((resp) => {
        this.status = resp.status ? 'good' : 'poor';
        this.$readinessChecker.markAsCompleted({ subPageName: this.pageName, isPassed: resp.status });
      });

    Velocity(
      this.$refs.speedCheckBar,
      {
        tween: 95,
      },
      {
        duration: 15000,
        easing: 'easeInOutCirc',
        progress: (elements, complete, remaining, start, tweenValue) => {
          this.progressNumber = tweenValue;
        },
      },
    );
  },
  methods: {
    moveToNextStep() {
      this.$readinessChecker.moveToNextStep({ nextPageName: this.nextPageName });
    },
  },
};
</script>

<style lang="scss">
@keyframes bandwidth-pulse {
  0%,
  20%,
  80%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}

.progress {
  border: 2px solid var(--c__success);
  width: rem(290px);
  height: rem(60px);
  margin: rem(36px) 0;
  position: relative;
  &.basic-group-leave-active {
    position: absolute;
  }
}

.speedCheckBar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: var(--c__success);
  opacity: 0.25;
  width: v-bind(barWidth);
}

.bandwidthCheckIcon {
  width: rem(90px);
  height: rem(73px);
  margin-bottom: rem(34px);
  color: var(--c__green);
  &.poor {
    color: var(--c__orange);
  }

  &.pending {
    path:nth-child(1) {
      animation: bandwidth-pulse 2s ease 1.5s infinite;
    }
    path:nth-child(2) {
      animation: bandwidth-pulse 2s ease 1s infinite;
    }
    path:nth-child(3) {
      animation: bandwidth-pulse 2s ease 0.5s infinite;
    }
  }
}
</style>
