<template>
  <div class="userSelectContainer" :class="[tail ? 'hasTail' : '']" v-click-outside="outside">
    <b v-if="title">{{ title }}</b>
    <ul class="userSelectList">
      <li v-for="user in usersOnline" :key="'user-' + user.id">
        <button :class="[speaking?.id === user.id ? 'selected' : 'unselected']" @click.prevent="handleListClick(user.id)">
          <span class="username">{{ getFullName(user) }}</span>
        </button>
      </li>
      <li v-if="hasRandom">
        <button @click.prevent="chooseRandomUser">Random</button>
      </li>
    </ul>
  </div>
</template>
<script setup>
import { getCurrentInstance, computed } from 'vue';
import { storeToRefs } from 'pinia';
import _ from 'underscore';

import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';

import utils from '../resources/utils';

const emit = defineEmits(['close', 'outside']);
const props = defineProps(['tail', 'title']); // eslint-disable-line no-unused-vars

const usersStore = useUsersStore();
const phaseStore = usePhaseStore();

const { usersOnline, speaking } = storeToRefs(usersStore);
const { setupPhase } = storeToRefs(phaseStore);

const $meetingmanager = getCurrentInstance().appContext.config.globalProperties.$meetingmanager;

const hasRandom = computed(() => {
  return ['checkIn', 'checkOut'].includes(setupPhase.value);
});

function handleListClick(id) {
  $meetingmanager.changeNextSpeaker({ speakerId: id, isRandom: false });
  emit('close');
}
function chooseRandomUser() {
  let user = _.sample(usersOnline.value);
  $meetingmanager.changeNextSpeaker({ speakerId: user.id, isRandom: true });
  emit('close');
}

function getFullName(user) {
  return utils.desanitizeString(`${user.first_name} ${user.last_name}`);
}

function outside() {
  emit('outside');
}
</script>
<style lang="scss" scoped>
.userSelectContainer {
  background: var(--c__bg);
  color: var(--c__text);
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.2));
  will-change: filter;
  padding: rem(16px) rem(18px);
  //display: none;
  flex-direction: column;
  position: absolute;
  width: rem(240px);
  left: 0;
  padding: rem(16px) rem(18px);
  z-index: 4;
  transform-origin: top left;
}

.hasTail {
  transform-origin: rem(143px) rem(-18px);
  top: rem((83px + 18px));
  &::after {
    content: '';
    height: 0;
    width: 0;
    border-top: rem(18px) solid transparent;
    border-bottom: rem(18px) solid var(--c__bg);
    border-left: rem(24px) solid transparent;
    border-right: rem(24px) solid transparent;
    position: absolute;
    top: rem(0 - (18px * 2));
    left: rem(120px);
  }
}

ul {
  list-style: none;
}

li {
  display: block;
}

button {
  display: block;
  border: none;
  color: var(--c__text);
  padding: 0.5em 0;
  width: 100%;
  text-align: left;
  @include type-size(small);
  &:hover,
  &:active {
    background: transparent;
    color: $c_accent;
  }
}

.selected {
  font-weight: 800;
}
</style>
