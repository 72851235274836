<template>
  <div id="meeting-vue">
    <transition name="basic" mode="out-in">
      <section class="page meetingFrame" data-page-name="meetingFrame" v-if="subPageName === 'meetingFrame'" key="meetingFrame">
        <the-meeting-frame />
      </section>
    </transition>
    <transition name="basic" mode="out-in">
      <section class="page connectivity" data-page-name="connectivity" key="connectivity" v-if="subPageName === 'connectivity'">
        <connectivity-check />
      </section>
    </transition>
  </div>
</template>

<script>
import P from 'bluebird';
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { nextTick } from 'vue';
import { nM } from '@/legacy';
import utils from '../resources/utils';
import { createGroupEvents } from '../shared/constants';
import { useStorage } from 'vue3-storage';
import { usePageStore } from '@/store/pinia/page';
import { usePhaseStore } from '@/store/pinia/phase';
import { useAccountStore } from '@/store/pinia/account';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { useUsersStore } from '@/store/pinia/users';

import TheMeetingFrame from '@/components/TheMeetingFrame';
import ConnectivityCheck from '@/components/ConnectivityCheck';

const sessionStorage = useStorage({ namespace: '', storage: 'session' });

export default {
  components: {
    TheMeetingFrame,
    ConnectivityCheck,
  },
  props: ['pageName'],
  setup() {
    const pageStore = usePageStore();
    const phaseStore = usePhaseStore();
    const accountStore = useAccountStore();
    const meetingStore = useMeetingStore();
    const mediaStore = useMediaStore();
    const usersStore = useUsersStore();
    return { pageStore, phaseStore, accountStore, meetingStore, mediaStore, usersStore };
  },
  data() {
    return {
      meeting: null,
      subPageName: null,
      debouncedMissedPongsHandler: _.debounce(this.missedPongsHandler, 5000),
    };
  },
  mounted() {
    const _this = this;
    this.init();
    this.$ee.on('bus:enterGroup', _this.enterGroup);
    this.$ee.on('bus:leaveGroup', _this.leaveGroup);
    this.$videomanager.on('missedPongs', _this.debouncedMissedPongsHandler);
    this.$videomanager.on('onConnectionHealthChanged', _this.connectionHealthChangedHandler);
    this.$devicemanager.addStreamingDevicesChangeListener(_.debounce(_this.notifyDeviceListChange, 250), (e) => {
      if (e === 'AUDIO_PERMISSION_DENIED') {
        utils.storage.ls.removeItem('mediaAllowed');
        const address = new URL(window.location.href);
        this.$router.push(`/allow?redirectUrl=${encodeURI(address.pathname + address.search)}`);
        return;
      }
    });

    if (utils.isOpenedWithinElectronShell()) {
      ipcApi.send({ name: 'requestWakeLock' });
    }
  },
  unmounted() {
    const _this = this;
    this.dispose();
    this.$ee.off('bus:enterGroup', _this.enterGroup);
    this.$ee.off('bus:leaveGroup', _this.leaveGroup);
    this.$devicemanager.removeStreamingDevicesChangeListener();
  },
  computed: {
    ...mapPiniaState(useUsersStore, ['isNonEmptyGroup', 'me', 'myId']),
    ...mapPiniaState(usePhaseStore, ['groupEventStatus']),
    ...mapPiniaState(useMediaStore, ['devices', 'videoOn', 'audioOn']),
  },
  methods: {
    init() {
      const meetingId = this.$route.params.id;
      const password = (this.$route.query.pwd || '').trim();

      if (password) {
        this.joinMeeting(meetingId, { password });
      } else {
        this.attemptRunMeeting(meetingId);
      }
    },
    enterConnectivity(meeting, state, session) {
      /** purposefully avoiding usage of `await` for `insertMeetingStats` as the next action is not dependent on it. */
      this.$API.insertMeetingStats(meeting.id, { action: 'enter_connectivity_settings_page' });
      this.meetingStore.applySettings(meeting);
      this.accountStore.setOwnerId(meeting.owner_id);
      this.$connectivity.once('ready', () => {
        this.$videomanager.init(session, {
          externalAttendeeId: this.me.id || window.__INITIAL_STATE__?.user?.id,
          externalMeetingId: meeting.id,
          pageName: this.subPageName,
        });
        if (!this.mediaStore.isCameraPermissionGranted) {
          this.usersStore.setVideoLoaded('connectivity', true);
          this.mediaStore.setVideoOn(false);
          this.mediaStore.setVideoSource('');
        }
      });

      this.$connectivity.on('restart', () => {
        this.$connectivity.restartTest();
      });

      this.$connectivity.once('dispose', () => {
        this.$connectivity.destroy(true);
        this.$videomanager.updateLogMetaData({ pageName: 'meetingFrame' });
        this.enterMeetingOrWaitingRoom(meeting);
      });

      nextTick(() => {
        this.subPageName = 'connectivity';
      });

      this.pageStore.update({
        pageName: 'connectivity',
        meeting: meeting,
        id: meeting.id,
      });
    },
    enterMeetingOrWaitingRoom(meeting) {
      this.meetingStore.applySettings(meeting);
      Promise.all([this.$API.getMeetingState(meeting.id), this.$API.getMeetingSession(meeting.id)])
        .then(([state, session]) => {
          // console.log('enterMeetingOrWaitingRoom!!', JSON.stringify({ muteAudio: !this.audioOn, muteVideo: !this.videoOn }));
          this.$meetingmanager.connect(
            state,
            { avDeviceState: { muteAudio: !this.audioOn, muteVideo: !this.videoOn } },
            { requestResync: true, isDuplicateClient: false },
          );
          this.$videomanager.init(session, {
            externalAttendeeId: this.me.id || window.__INITIAL_STATE__?.user?.id,
            externalMeetingId: meeting.id,
            pageName: 'meetingFrame',
          });
          this.meetingStore.isInitialized();
          this.initialized = true;
          this.pageStore.update({
            pageName: 'meetingFrame',
            meeting: meeting,
            id: meeting.id,
          });
          this.subPageName = 'meetingFrame';
        })
        .catch((err) => {
          if (err.error && err.error.message && err.error.message.match(/ended|finished/gi)) {
            return this.$ee.emit('navigate', `/meeting-has-been-ended?id=${meeting.id}`);
          }
          throw err;
        });
    },
    stopVideoManager() {
      return new Promise((resolve) => {
        this.$videomanager.stop(true);
        this.$videomanager.once('destroyed', () => {
          setTimeout(() => {
            resolve('destroyed');
          }, 5000);
        });
      });
    },
    enterGroup() {
      const meetingId = this.$route.params.id;
      this.phaseStore.setGroupEventStatus({
        status: createGroupEvents.CLOSING_LAST_SESSION,
      });
      this.stopVideoManager().then(() => {
        this.phaseStore.setGroupEventStatus({
          status: createGroupEvents.CLOSED_LAST_SESSION,
        });
        this.$API.getMeetingSession(meetingId).then(async (session) => {
          this.phaseStore.setGroupEventStatus({
            status: createGroupEvents.GENERATED_NEW_SESSION_ID,
          });
          if (this.isNonEmptyGroup) {
            await this.$videomanager.init(session, {
              externalAttendeeId: this.me.id,
              externalMeetingId: meetingId,
              pageName: this.pageName,
            });
            this.$videomanager.connect();
            if (this.phaseStore.currentPhaseData && !this.phaseStore.currentPhaseData.started_on) {
              this.$meetingmanager.doneEditingPhaseStartedOn({ forceStart: false });
            }
          } else {
            this.leaveGroup();
          }
        });
      });
    },
    leaveGroup() {
      const meetingId = this.$route.params.id;
      this.phaseStore.setGroupEventStatus({
        forced: true,
        status: createGroupEvents.CLOSING_LAST_SESSION,
      });
      this.stopVideoManager().then(() => {
        this.phaseStore.setGroupEventStatus({
          status: createGroupEvents.CLOSED_LAST_SESSION,
        });
        this.$API.getMeetingSession(meetingId).then(async (session) => {
          this.phaseStore.setGroupEventStatus({
            status: createGroupEvents.GENERATED_NEW_SESSION_ID,
          });
          await this.$videomanager.init(session, {
            externalAttendeeId: this.me.id,
            externalMeetingId: meetingId,
            pageName: this.pageName,
          });
          this.$videomanager.connect();
        });
      });
    },
    dispose() {
      this.$connectivity.destroy(true);
      if (this.meetingStore.initialized) {
        this.$meetingmanager.disconnect();
        this.$videomanager.stop(true);

        //this.$store.dispatch('emptyMeeting');
        //TODO check if we still need to purge
        this.$ee.emit('bus:meetingReset');
      }
    },
    attemptRunMeeting(meetingId) {
      this.$API
        .getMeeting(meetingId)
        .then((meeting) => {
          if (meeting.status === nM.meetingStatus.FINISHED) {
            return this.$router.push(`/meeting-has-been-ended?id=${meetingId}`);
          }
          this.run(meeting);
        })
        .catch(() => {
          this.$router.push('/join?reason=badLink');
        });
    },
    joinMeeting(meetingId, { password }) {
      this.$API
        .joinMeeting(meetingId, { password })
        .then(() => {
          window.location = `${this.accountStore.baseUrl}/meeting/${meetingId}`;
        })
        .catch((e) => {
          const { code, status } = e.error || {};
          if (code === 'GuestUserAttemptedToJoinAnotherMeeting') {
            window.__INITIAL_STATE__.user = {};
            this.$router.push(`/joining-options?id=${encodeURI(meetingId)}&pwd=${encodeURI(password)}&flow=meeting-invite`);
          } else if (status === 403) {
            this.$router.push(`/ejected-from-meeting?id=${encodeURI(meetingId)}`).catch(() => null);
          } else {
            this.$router.push('/join?reason=wrongLink').catch(() => null);
          }
        });
    },
    run(meeting) {
      P.join(this.$API.getMeetingState(meeting.id), this.$API.getMeetingSession(meeting.id), (state, session) => {
        this.meetingStore.applySettings(state.meeting);
        const isMeetingVisited = sessionStorage.getStorageSync(`meetings:${meeting.id}:dateVisited`);
        if (isMeetingVisited) {
          this.enterMeetingOrWaitingRoom(meeting);
        } else {
          this.enterConnectivity(meeting, state, session);
        }
      });
    },
    notifyDeviceListChange(updatedDevices) {
      this.$ee.emit('nM:deviceListChanged', updatedDevices);
    },
    connectionHealthChangedHandler({ isConnectivityWentPoor }) {
      const noOfMissedPongs = isConnectivityWentPoor ? 1 : 0;
      this.missedPongsHandler({ noOfMissedPongs });
    },
    missedPongsHandler({ noOfMissedPongs }) {
      this.meetingStore.setMissedPongs(noOfMissedPongs);
      if (noOfMissedPongs === 0) {
        this.usersStore.hideUnstableNetworkWarn(this.myId);
      } else {
        this.usersStore.showUnstableNetworkWarn(this.myId);
      }
      this.$meetingmanager.publishConnectionHealthChanged({ isConnectivityWentPoor: noOfMissedPongs > 0 });
    },
  },
};
</script>
<style lang="scss" scoped>
.meetingFrame {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
</style>
