const defaultButtons = [
  { text: 'Cancel', value: 'cancel', isReject: true },
  { text: 'Yes', value: 'confirm', isResolve: true },
];

const endLines = {
  context: (u) => `<b>${u}</b> is setting context. End it anyway?`,
  input: (u) => `Are you sure you want to end <b>${u}'s</b> input?`,
  decision: () => 'Are you sure you want to <b>end</b> this Decision Council?',
  review: () => 'Are you sure you want to <b>finish</b> this review?',
  checkIn: () => 'Are you sure you want to <b>end</b> this Check-In?',
  checkOut: () => 'Are you sure you want to <b>end</b> this Check-Out?',
  breakTime: () => 'Are you sure you want to <b>move</b> on now?',
  dialogue: () => 'Are you sure you want to <b>finish</b> this exchange?',
  feedback: () => 'Are you sure you want to <b>finish</b> this feedback phase?',
};

export const interrupts = {
  sureYouWantToStartMeeting: {
    content: 'This will <b>start</b> the meeting for everyone. Are you ready?',
    type: 'outer',
    buttons: defaultButtons,
  },
  sureYouWantToFinishMeeting: {
    content: 'Are you sure you want to <strong>end</strong> the meeting for all?',
    type: 'outer',
    buttons: [
      { text: 'Cancel', value: 'cancel', isReject: true },
      { text: 'Yes', value: 'confirm', isResolve: true },
    ],
  },
  sureYouWantToLeaveMeeting: {
    content: '<strong>Leave meeting?</strong>',
    type: 'outer',
    tipClass: 'short',
    buttons: [
      { text: 'Cancel', value: 'cancel', isReject: true },
      { text: 'Yes', value: 'confirm', isResolve: true },
    ],
  },
  sureYouWantToCloseMeeting: {
    content: '<strong>End meeting for everyone?</strong>',
    type: 'outer',
    tipClass: 'short',
    buttons: [
      { text: 'Cancel', value: 'cancel', isReject: true },
      { text: 'End', value: 'confirm', isResolve: true },
    ],
  },
  timeLeftToCheckIn: {
    content: (a) => `Are you sure you want to end <b>${a.speakingName}'s</b> turn?`,
    type: 'container',
    buttons: defaultButtons,
  },
  sureYouWantToCancelSetup: {
    content: (a) => `<b>${a.activeName}</b> is configuring this phase. Go back anyway?`,
    type: (a) => a.skillType,
    buttons: defaultButtons,
  },
  sureYouWantToStartPhase: {
    content: (a) => `<b>${a.activeName}</b> is configuring this phase. Start it anyway?`,
    type: (a) => a.skillType,
    buttons: defaultButtons,
  },
  sureYouWantToEndPhase: {
    content: function (a) {
      const user = a.speakingName ? a.speakingName : a.activeName;

      if (endLines[a.phase]) {
        return endLines[a.phase](user);
      } else {
        return `<b>${user}</b> is running this ${a.phase} phase. <b>End</b> it anyway?`;
      }
    },
    type: function (sentArgs) {
      return sentArgs.skillType;
    },
    buttons: defaultButtons,
  },
  notEveryoneIsBack: {
    content: 'Some people are not back yet. <b>Finish</b> anyway?',
    type: (a) => a.skillType,
    buttons: defaultButtons,
  },
  sureYouWantToReplacePhase: {
    content: function (a) {
      const user = a.speakingName ? a.speakingName : a.activeName;

      if (endLines[a.phase]) {
        return endLines[a.phase](user);
      } else {
        return `<b>${user}</b> is running this ${a.phase} phase. <b>End</b> it anyway?`;
      }
    },
    type: (a) => a.skillType,
    buttons: defaultButtons,
  },
  sureYouWantToEndMeeting: {
    content: 'This will <b>end</b> the meeting for everyone. Are you sure?',
    type: 'outer',
    buttons: defaultButtons,
  },
  sureYouWantToEndProposal: {
    content: (a) => `Has <b>${a.speakingName}</b> finished making their proposal?`,
    type: 'breakthrough',
    buttons: defaultButtons,
  },
  sureYouWantToReviewFeedback: {
    content: (a) => `Is <b>${a.speakingName}</b> ready to review the feedback?`,
    type: 'breakthrough',
    buttons: defaultButtons,
  },
  sureYouWantToEndTopic: {
    content: (a) => {
      if (a.phase === 'dialogue') {
        return `Has <b>${a.speakingName}</b> submitted the dialogue topic?`;
      } else {
        return `Has <b>${a.speakingName}</b> finished choosing a topic?`;
      }
    },
    type: 'misc',
    buttons: defaultButtons,
  },
  sureYouWantToReveal: {
    content: "Some people haven't voted yet. <b>Reveal</b> anyway?",
    type: 'breakthrough',
    buttons: defaultButtons,
  },
  sureYouWantToAddTimeToBreak: {
    content: 'Are you sure you want to add <b>more time</b> for this break?',
    type: 'breakthrough',
    buttons: defaultButtons,
  },
  sureYouWantToAddTimeToGroups: {
    content: 'Are you sure you want to add more time to <b>every</b> break-out group?',
    type: 'misc',
    buttons: defaultButtons,
  },
};
