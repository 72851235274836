<template>
  <div class="flipHome pane agendaSidebar">
    <section class="flipContent">
      <app-icon icon="po3" class="po3Header" key="header" />
      <meeting-info
        v-bind="$attrs"
        id="flipTitleSection"
        key="flipTitle"
        :field="meetingStore.name"
        :edited-by="meetingStore.editing.name"
        name="name"
        edit-name="title"
        default-text="Add meeting title..."
        type="heading"
        truncate="false"
      />

      <h3 key="flipPurposeHeading" class="infoHeading">Purpose</h3>
      <meeting-info
        v-bind="$attrs"
        id="flipPurposeSection"
        key="flipPurpose"
        :field="meetingStore.purpose"
        :edited-by="meetingStore.editing.purpose"
        expanded="true"
        name="purpose"
        default-text="Add meeting purpose"
        type="paragraph"
        truncate="true"
      />

      <h3 key="flipObjectivesHeading" class="infoHeading">Objectives</h3>
      <meeting-info
        v-bind="$attrs"
        id="flipObjectivesSection"
        key="flipObjectives"
        :field="meetingStore.objectives"
        :edited-by="meetingStore.editing.objectives"
        expanded="true"
        name="objectives"
        default-text="Add meeting objectives..."
        type="list"
        truncate="false"
      />

      <h3 v-if="meetingStore.outputs.length > 0" key="flipOutputsHeading" class="infoHeading">Outputs</h3>
      <meeting-info
        v-bind="$attrs"
        v-if="meetingStore.outputs.length > 0"
        id="flipOutputsSection"
        key="flipOutputs"
        :field="meetingStore.outputs"
        :edited-by="meetingStore.editing.outputs"
        expanded="true"
        name="outputs"
        default-text="Add meeting outputs..."
        type="list"
        truncate="false"
      />

      <h3 v-if="meetingStore.outcomes.length > 0" key="flipOutcomesHeading" class="infoHeading">Outcomes</h3>
      <meeting-info
        v-bind="$attrs"
        v-if="meetingStore.outcomes.length > 0"
        id="flipOutcomesSection"
        key="flipOutcomes"
        :field="meetingStore.outcomes"
        :edited-by="meetingStore.editing.outcomes"
        expanded="true"
        name="outcomes"
        default-text="Add meeting outcomes..."
        type="list"
        truncate="false"
      />
    </section>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useUsersStore } from '@/store/pinia/users';
import { usePhaseStore } from '@/store/pinia/phase';
import { useSidebarStore } from '@/store/pinia/sidebar';
import MeetingInfo from '@/components/MeetingInfo';
import AppIcon from '@/components/AppIcon.vue';

export default {
  components: {
    MeetingInfo,
    AppIcon,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const usersStore = useUsersStore();
    const phaseStore = usePhaseStore();
    return { meetingStore, usersStore, phaseStore };
  },
  data() {
    return {
      flashHeyStart: false,
      expanded: true,
      userExpanded: false,
      userInteractedWithToggle: false,
      showingNotes: false,
      debouncedToggleExpand: _.debounce(this.toggleExpand, 200),
    };
  },
  created() {
    this.$ee.on('toggleExpand', () => {
      this.expanded = !this.expanded;
    });
    this.$ee.on('bus:expandInfo', this.expandInfo);
    this.$nextTick(() => {
      this.expanded = this.infoExpanded;
    });
  },
  mounted() {},
  beforeUnmount() {
    this.$ee.off('bus:expandInfo', this.expandInfo);
  },
  computed: {
    ...mapPiniaState(useSidebarStore, ['notice']),
    animating() {
      return !this.meetingStore.initTitleCardShown || !this.usersStore.firstShown || this.phaseStore.isAnimating;
    },
    infoExpanded() {
      if (this.userInteractedWithToggle) {
        return this.userExpanded;
      } else {
        return true;
      }
    },
    toggleLabel() {
      return this.expanded ? 'less' : 'more';
    },
  },
  watch: {
    infoExpanded: {
      immediate: true,
      handler(nv) {
        this.debouncedToggleExpand(nv);
      },
    },
  },
  methods: {
    toggleInfo() {
      this.userInteractedWithToggle = true;
      this.userExpanded = !this.userExpanded;
    },
    expandInfo() {
      this.userInteractedWithToggle = true;
      this.expanded = true;
      this.userExpanded = true;
    },
    contractInfo() {
      this.userInteractedWithToggle = true;
      this.expanded = false;
      this.userExpanded = false;
    },
    toggleExpand(nv) {
      this.expanded = nv;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.infoHeading) {
  padding: 0 rem($sidebar-left-pad);
  flex: 0 0 auto;
  font-weight: bold;
  display: inline-block;
  margin-top: 1em;
}
</style>
