import { defineStore } from 'pinia';
import { usePhaseStore } from '@/store/pinia/phase';
import { useTimeStore } from '@/store/pinia/time';
import { useUsersStore } from '@/store/pinia/users';
import spaces from '@/resources/spaces';
import _ from 'underscore';

const fields = ['name', 'purpose', 'objectives', 'outputs', 'outcomes'];

function isField(f) {
  return fields.includes(f);
}

export const useMeetingStore = defineStore('meeting', {
  state() {
    return {
      __initialized: false,
      missedPongs: 0,
      rawMeeting: {},
      initialized: false,
      meetingId: false,
      meetingUrl: '',
      personalRoomUrl: '',
      prInviteDetails: { pin: '', pass: '' },
      pin: false,
      pass: false,
      // Meeting Info
      name: false,
      purpose: false,
      objectives: [],
      outputs: [],
      outcomes: [],
      editing: {
        name: false,
        purpose: false,
        objectives: false,
        outputs: false,
        outcomes: false,
      },
      meta: {},
      // Meeting settings
      advancedSettings: {},
      personalNotesSetting: false,
      // Title Card stuff
      initTitleCardShown: false,
      titleCard: false,
      titleCardShowing: false,
      // Progress
      startedEndWell: false,
      ignoredEndWell: false,
      skippedBeginWell: false,
      addedMoreTimeToGroups: false,
      addedMoreTimeToBreak: false,
      breakEndedEarly: false,
      showReviewNotice: false,
      emoteList: ['yes', 'no', 'what', 'smile', 'grr', 'sad', 'heart', 'star', 'uhh', 'eek', 'wave', 'confetti'],
    };
  },
  getters: {
    me() {
      const usersStore = useUsersStore();
      return usersStore.me;
    },
    owner() {
      const { owner } = useUsersStore();
      return owner;
    },
    isRoom: (state) => state.meta.room,
    isScheduled: (state) => state.meta.scheduled,
    useNotes(state) {
      if (state.personalNotesSetting === 'show') {
        return true;
      }
      if (state.personalNotesSetting === 'hide') {
        return false;
      }
      return state.advancedSettings.notes;
    },
    useLobby: (state) => state.advancedSettings.lobby,
    useGuide(state) {
      const phaseStore = usePhaseStore();
      if (['lobby', 'endMeeting', 'waiting'].includes(phaseStore.currentPhase)) {
        return this.me.id === this.owner.id;
      } else {
        return state.advancedSettings.guide;
      }
    },
    space: (state) => state.advancedSettings.space,
    spaceDetails() {
      return spaces[this.space];
    },
    emailInfo(state) {
      const timeStore = useTimeStore();
      return {
        name: state.name,
        startDateString: timeStore.startDateString,
        startTimeString: timeStore.startTimeString,
        endDateString: timeStore.endDateString,
        endTimeString: timeStore.endTimeString,
        timezoneOffset: state.rawMeeting.timezone_with_offset,
        purpose: state.purpose,
        objectives: state.objectives,
        outputs: state.outputs,
        outcomes: state.outcomes,
        meetingId: state.meetingId,
        pinPass: state.rawMeeting.pin_pass,
        pinNumber: state.rawMeeting.pin_number,
        prInviteDetails: state.prInviteDetails,
      };
    },
  },
  actions: {
    initMeeting(data, isResync) {
      if (this.__initialized && !isResync) {
        return;
      }
      if (!isResync) {
        this.$reset();
      }
      let { meeting, state, meetingUrl, personalRoomUrl, prInviteDetails } = data;
      this.rawMeeting = { ...meeting };
      this.updateMeetingId(meeting.id);
      this.updateMeta(meeting.meta);

      this.pin = meeting.pin_number;
      this.pass = meeting.pin_pass;
      this.meetingUrl = meetingUrl;
      if (personalRoomUrl) {
        this.personalRoomUrl = personalRoomUrl;
      }
      if (prInviteDetails) {
        this.prInviteDetails = prInviteDetails;
      }

      // Do field locking
      Object.keys(state.fields).forEach((k) => {
        this.lockField({ field: k, userId: state.fields[k] });
      });

      // set the initial data

      ['name', 'purpose', 'objectives', 'outputs', 'outcomes'].forEach((f) => {
        if (!isResync || this.editing[f] !== this.me.id) {
          this[f] = meeting[f];
        }
      });

      this.advancedSettings = meeting.advancedSettings;
      this.startedEndWell = state.started_end_well;
      this.ignoredEndWell = state.ignored_end_well;
      this.skippedBeginWell = state.skipped_begin_well;

      this.setPersonalNotes(data);
      this.__initialized = true;
    },
    isInitialized() {
      this.initialized = true;
    },
    setMissedPongs(v) {
      this.missedPongs = v;
    },
    updateMeta(meta) {
      this.meta = { ...meta };
    },
    updateMeetingId(nv) {
      this.meetingId = nv;
    },
    applySettings({ advancedSettings }) {
      this.advancedSettings = { ...advancedSettings };
    },
    updateTitleCard(v) {
      if (v === false) {
        _.delay(() => {
          this.initTitleCardShown = true;
        }, 100);
        this.titleCardShowing = false;
      } else {
        this.titleCard = v;
        this.titleCardShowing = true;
      }
    },
    startEndWell() {
      this.startedEndWell = true;
    },
    ignoreEndWell() {
      this.ignoredEndWell = true;
    },
    skipBeginWell() {
      this.skippedBeginWell = true;
    },
    setPersonalNotes(data) {
      if (this.me?.id) {
        if (data.state.meeting_notes_disabled_for && data.state.meeting_notes_disabled_for.includes(this.me.id)) {
          this.personalNotesSetting = 'hide';
        } else {
          this.personalNotesSetting = 'show';
        }
      }
    },
    updatePersonalNotes(v) {
      this.personalNotesSetting = v;
    },
    meetingUpdated(data) {
      // set the initial data
      fields.forEach((p) => {
        if (this.editing[p] !== this.me.id) {
          this[p] = data.meeting[p];
        }
      });

      // this.name = data.meeting.name;
      // this.purpose = data.meeting.purpose;
      // this.objectives = data.meeting.objectives;
      // this.outputs = data.meeting.outputs;
      // this.outcomes = data.meeting.outcomes;
    },
    updateLocalMeetingInfo({ prop, value }) {
      if (fields.includes(prop)) {
        this[prop] = value;
      }
      this.editing[prop] = -1;
    },
    updateAdvancedSettings({ name, value, updatedBy }) {
      if (name === 'notes') {
        if (updatedBy === this.me.id) {
          this.personalNotesSetting = value ? 'show' : 'hide';
        }
      } else {
        this.advancedSettings[name] = value;
      }
    },
    lockField({ field, userId }) {
      if (isField(field)) {
        this.editing[field] = userId;
      }
    },
    unlockField({ field }) {
      if (isField(field)) {
        this.editing[field] = false;
      }
    },
    setAddedMoreTimeToGroups(v) {
      this.addedMoreTimeToGroups = v;
    },
    setAddedMoreTimeToBreak(v) {
      this.addedMoreTimeToBreak = v;
    },
    setReviewNotice(v) {
      this.showReviewNotice = v;
    },
    setPersonalRoomInviteDetails({ pin, pinPass }) {
      this.personalRoomInviteDetails = { pin, pinPass };
    },
  },
});
