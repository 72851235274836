import MeetingManager from '@/services/meeting-manager.service';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    // TODO: refactor it, not the best solution
    const bixeConfig = window.__INITIAL_STATE__.config;
    const config = {
      realtimeServerConfig: bixeConfig.realtimeServerConfig,
    };

    app.config.globalProperties.$meetingmanager = new MeetingManager(config);
  },
};
