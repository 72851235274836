import ReadinessCheckerService from '../services/readinessCheckers/readinessChecker.service';
import MockedReadinessChecker from '../services/readinessCheckers/mockedReadinessChecker.service';

let installed = false;
export default {
  install: (app) => {
    if (installed) return;

    const { config } = window.__INITIAL_STATE__;
    app.config.globalProperties.$readinessChecker = config.video.enabled ? new ReadinessCheckerService(app) : new MockedReadinessChecker(app);
  },
};
