import { nM } from '@/legacy';
import { useMediaStore } from '@/store/pinia/media';
import errorReportingService from '../services/errorReportingService';

const getListOfAvailableDevices = () => {
  return new Promise((resolve) => {
    const ignoredTypes = [];
    const deviceKindsMap = {
      audioinput: 'audioInput',
      audiooutput: 'audioOutput',
      videoinput: 'videoInput',
      videooutput: 'videoOutput',
    };
    const notSupportedDeviceLabels = ['microsoft teams audio device', 'steam streaming', 'zoomaudiodevice', 'google meet'];
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const filtered = devices
        .filter(({ kind }) => !ignoredTypes.includes(kind))
        .filter(({ label }) => {
          // remove invalid kind of devices like MS Teams, steam, google meet etc.
          const lowerCasedLabel = label.toLowerCase();
          let isCurrentDeviceSupported = true;
          notSupportedDeviceLabels.forEach((notSupportedDevice) => {
            if (lowerCasedLabel.includes(notSupportedDevice)) {
              isCurrentDeviceSupported = false;
            }
          });
          return isCurrentDeviceSupported;
        });
      resolve(filtered.map((d) => ({ deviceId: d.deviceId, label: d.label, kind: deviceKindsMap[d.kind] || '' })));
    });
  });
};

export default class DetectRtcDeviceManager {
  audioStream = [];
  videoStream = [];

  addStreamingDevicesChangeListener(callback, errHandler) {
    navigator.mediaDevices.ondevicechange = () => {
      if (callback) {
        this.getDevices()
          .then(callback)
          .catch((err) => {
            if (errHandler) {
              errHandler(err);
            }
          });
      }
    };
  }

  removeStreamingDevicesChangeListener(callback) {
    navigator.mediaDevices.ondevicechange = null;
    if (callback) callback();
  }

  async getDevices(audioSource = null) {
    this.stopAudioStream();
    this.stopVideoStream();

    let audioStream;
    try {
      audioStream = audioSource
        ? await navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: audioSource } } })
        : await navigator.mediaDevices.getUserMedia({ audio: true });

      this.audioStream.push(audioStream);
    } catch (e) {
      errorReportingService.reportError(e);
      if (e instanceof DOMException) {
        return Promise.reject('AUDIO_PERMISSION_DENIED');
      }
    }
    try {
      await navigator.mediaDevices.getUserMedia({ video: true }).then((stream) => {
        let video;
        video = document.createElement('video');
        video.muted = true;
        if ('srcObject' in video) {
          video.srcObject = stream;
        } else {
          video.src = URL.createObjectURL(stream);
        }
        video.style.display = 'none';
        (document.body || document.documentElement).appendChild(video);

        stream.getTracks().forEach((track) => track.stop());
        this.videoStream.push(stream);

        if (video && video.parentNode) {
          video.parentNode.removeChild(video);
        }
      });
    } catch (err) {
      if (err.name === 'NotAllowedError') {
        const mediaStore = useMediaStore();
        mediaStore.markAsCameraPermissionNotGranted();
      }
      errorReportingService.reportError(err);
      console.log('Video error', err);
    }

    const devices = await getListOfAvailableDevices();
    if (audioStream) {
      audioStream.getTracks().forEach((track) => track.stop());
    }
    return Promise.resolve(devices);
  }

  stopAudioStream() {
    if (nM.detector.isFirefox() && this.audioStream) {
      this.audioStream.forEach((stream) => {
        stream.getTracks().forEach((x) => {
          x.stop();
          stream.removeTrack(x);
        });
      });
    }
  }

  stopVideoStream() {
    this.videoStream.forEach((stream) => {
      stream.getTracks().forEach((x) => {
        x.stop();
        stream.removeTrack(x);
      });
    });
  }
}
