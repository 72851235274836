<template>
  <!-- an empty component, it uses vue's reactivity to trigger chime's mute/unmute AV function -->
  <div v-show="false" class="debug-user-data">{{ user }}</div>
</template>
<script>
import { useMediaStore } from '@/store/pinia/media';
import { mapState as mapPiniaState } from 'pinia';

export default {
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    ...mapPiniaState(useMediaStore, ['videoSource', 'videoOn']),
    audioMuted() {
      const user = this.user;
      if (user.talking) {
        return false;
      }
      return user.muted || user.muted_by_system;
    },
    videoMuted() {
      const user = this.user;
      return user.video_muted || user.video_muted_by_system;
    },
  },
  watch: {
    videoSource: {
      immediate: true,
      handler: function () {
        if (this.user.inWaitingRoom) {
          this.videoPreviewHandler();
        }
      },
    },
    audioMuted: {
      immediate: true,
      handler: function (nv) {
        if (this.user.inWaitingRoom) {
          return;
        }
        if (nv) {
          this.$videomanager.mute();
        } else {
          this.$videomanager.unmute();
        }
      },
    },
    videoMuted: {
      immediate: true,
      handler: function (nv) {
        if (this.user.inWaitingRoom) {
          this.videoPreviewHandler();
          return;
        }
        if (nv) {
          this.$videomanager.muteVideo();
        } else {
          this.$videomanager.unmuteVideo();
        }
      },
    },
  },
  methods: {
    videoPreviewHandler() {
      const devices = { videoSource: this.videoSource, videoOn: this.videoOn };
      const targetElementId = 'video-user-' + this.user.id;
      const targetElement = document.getElementById('video-user-' + this.user.id);
      if (this.videoSource && this.videoOn) {
        this.$videomanager.startPreview({ devices, targetElementId: targetElementId });
      } else {
        this.$videomanager.stopPreview({ targetElement });
      }
    },
  },
};
</script>
