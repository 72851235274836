<template>
  <transition name="basic">
    <div v-if="usersStore.me" id="counterFrame" class="counterFrame" :class="[noLog ? 'noLog' : 'hasLog']" @click="clicked">
      <avatars-positions />
      <avatars-sandbox v-if="featuresStore.sandbox" />
      <transition-group id="counterRingList" class="avatarCores" tag="ul" name="avatars" @before-enter="beforeEnter" @enter="enter" @leave="leave">
        <avatar
          v-for="(user, index) in renderedAvatars"
          :key="'user-' + user.id"
          :user="user"
          :index="index"
          :count="visible.length"
          :data-user-id="user.id"
          :data-user-name="user.first_name"
        />
      </transition-group>

      <avatar-extras v-for="(user, index) in visible" :key="'userExtras-' + user.id" :user="user" :index="index" :count="visible.length" />

      <transition-group class="addToGroupContainer" name="basic" v-if="swapping" :style="containerStyle" tag="ul">
        <avatars-add-to-group v-for="(position, index) in groupPositions" :group="index" :key="'addTo-' + index" :position="position" />
      </transition-group>

      <transition name="basic">
        <div
          v-if="
            visible.length > 0 &&
            usersStore.firstShown &&
            !frameStore.fullscreen &&
            !usersStore.hide &&
            avatarPages.length > 1 &&
            !pageStore.isAvatarsDockDrawer
          "
          class="avatarPagers"
        >
          <div class="avatarPager back" :class="positionDetails.vertical ? 'vertical' : 'horizontal'" key="back" :style="pagerStylesBack">
            <button :disabled="avatarPage === 0" @click="prevAvatars">
              <app-icon :icon="positionDetails.vertical ? 'up-arrow' : 'left-arrow'" />
            </button>
            <transition name="basic">
              <div
                v-if="talkingPage !== false && !(talkingUser.id === speaking.id && phaseStore.currentPhase === 'context') && talkingPage < avatarPage"
                class="isSpeaking"
              >
                <volume-indicator :volume="talkingUser.volume" />
              </div>
            </transition>
            <avatars-extra-emotes direction="back" />
          </div>

          <div key="forward" class="avatarPager forward" :class="positionDetails.vertical ? 'vertical' : 'horizontal'" :style="pagerStylesForward">
            <button :disabled="lastPage" @click="nextAvatars">
              <app-icon :icon="positionDetails.vertical ? 'down-arrow' : 'right-arrow'" />
            </button>
            <transition name="basic">
              <div
                v-if="talkingPage !== false && !(talkingUser.id === speaking.id && phaseStore.currentPhase === 'context') && talkingPage > avatarPage"
                class="isSpeaking"
              >
                <volume-indicator :volume="talkingUser.volume" />
              </div>
            </transition>
            <avatars-extra-emotes direction="forward" />
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import _ from 'underscore';
import { mapState as mapPiniaState } from 'pinia';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMediaStore } from '@/store/pinia/media';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePageStore } from '@/store/pinia/page';
import { useFrameStore } from '@/store/pinia/frame';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';
import AvatarsPositions from '@/components/AvatarsPositions';
import AvatarsSandbox from '@/components/AvatarsSandbox';
import Avatar from '@/components/Avatar';
import AvatarExtras from '@/components/AvatarExtras';
import AvatarsAddToGroup from '@/components/AvatarsAddToGroup';
import AppIcon from '@/components/AppIcon';
import VolumeIndicator from '@/components/VolumeIndicator';
import AvatarsExtraEmotes from '@/components/AvatarsExtraEmotes';
import anim from '@/resources/animation-constants';
import { nM } from '@/legacy';

export default {
  components: {
    AppIcon,
    AvatarsPositions,
    AvatarsSandbox,
    Avatar,
    AvatarExtras,
    AvatarsExtraEmotes,
    AvatarsAddToGroup,
    VolumeIndicator,
  },
  setup() {
    const meetingStore = useMeetingStore();
    const featuresStore = useFeaturesStore();
    const pageStore = usePageStore();
    const frameStore = useFrameStore();
    const phaseStore = usePhaseStore();
    const usersStore = useUsersStore();
    return { meetingStore, featuresStore, pageStore, frameStore, phaseStore, usersStore };
  },
  data() {
    return {
      sidebarDone: false,
      initRender: true,
      staggerCount: 0,
      positions: {},
      userIdsForIncomingVideoSubscription: [], // a list of users id for whom the incoming video streams should be subscribed
      notifyUpdatesInAvatarsListDebounced: _.debounce(this.notifyUpdatesInAvatarsList, 500),
    };
  },
  mounted() {
    this.$meetingmanager.on('emote:added', (args) => {
      this.handleEmote(args);
    });
    if (!this.meetingStore.useGuide || this.pageStore.isAvatarsDockDrawer) {
      this.sidebarDone = true;
    }
  },
  beforeUnmount() {
    this.$meetingmanager.off('emote:added', (args) => {
      this.handleEmote(args);
    });
  },
  computed: {
    ...mapPiniaState(useUsersStore, {
      rawPositions: 'positions',
      avatarPages: 'pages',
      avatarPage: 'page',
    }),
    ...mapPiniaState(useUsersStore, [
      'whatPage',
      'usersHere',
      'positionDetails',
      'swapping',
      'speaking',
      'me',
      'usersVisible',
      'allUsers',
      'allUsersList',
      'currentAvatarPageList',
      'isMeeting',
      'talkingPage',
      'talkingUser',
      'iAmSpeaking',
    ]),
    ...mapPiniaState(useMeetingStore, ['initTitleCardShown']),
    ...mapPiniaState(useMediaStore, ['videoSource', 'screenSharing', 'videoSourceActive']),
    showing() {
      return this.pageStore.isMeeting && this.phaseStore.currentPhase !== 'ended';
    },
    noLog() {
      return (!this.meetingStore.useNotes && !['review', 'feedback'].includes(this.phaseStore.currentPhase)) || this.pageStore.isAvatarsDockDrawer;
    },
    visible() {
      if (
        !this.meetingStore.initTitleCardShown ||
        Object.keys(this.rawPositions).length === 0 ||
        !this.allUsers ||
        this.allUsers.length < 1 ||
        !this.frameStore.containerSet ||
        !this.sidebarDone
      ) {
        return [];
      } else if (this.phaseStore.currentPhase === 'input') {
        if (this.iAmSpeaking && !this.videoSourceActive) {
          return [];
        } else {
          return this.usersVisible;
        }
      } else {
        return this.usersVisible;
      }
    },
    lastPage() {
      return this.avatarPage + 1 >= this.avatarPages.length;
    },
    groupPositions() {
      if (this.swapping) {
        return this.positionDetails.groupAddPositions;
      } else {
        return [];
      }
    },
    pagerStyles() {
      if (this.positionDetails.vertical) {
        return {
          left: this.positionDetails.pagerLeft + 'px',
          width: this.positionDetails.pagerWidth + 'px',
        };
      } else {
        return {
          top: this.positionDetails.pagerTop + this.positionDetails.pagerHeight / 2 + 'px',
          //height: this.positionDetails.pagerHeight + 'px',
        };
      }
    },
    pagerStylesBack() {
      return this.positionDetails.vertical ? { top: this.positionDetails.pagerBackTop + 'px', ...this.pagerStyles } : this.pagerStyles;
    },
    pagerStylesForward() {
      return this.positionDetails.vertical ? { top: this.positionDetails.pagerForwardTop + 'px', ...this.pagerStyles } : this.pagerStyles;
    },
    renderedAvatars() {
      const currentPageAndSpeaker = [...this.currentAvatarPageList];
      if (this.speaking?.id && !currentPageAndSpeaker.includes(this.speaking.id)) {
        currentPageAndSpeaker.push(this.speaking.id);
      }

      return this.visible.filter((u) => {
        if (this.rawPositions[u.id] && currentPageAndSpeaker.includes(u.id)) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    initTitleCardShown(nv) {
      if (nv) {
        _.delay(() => {
          this.sidebarDone = true;
        }, 800);
      }
    },
    currentAvatarPageList: {
      immediate: true,
      handler(nv) {
        const userIdsForIncomingVideoSubscription = [...nv];
        if (this.speaking?.id && !userIdsForIncomingVideoSubscription.includes(this.speaking.id)) {
          userIdsForIncomingVideoSubscription.push(this.speaking.id);
        }
        if (this.phaseStore?.mirroring?.requestedBy && !userIdsForIncomingVideoSubscription.includes(this.phaseStore.mirroring.requestedBy)) {
          userIdsForIncomingVideoSubscription.push(this.phaseStore.mirroring.requestedBy);
        }
        if (!this.isAvatarsListSame(this.userIdsForIncomingVideoSubscription, userIdsForIncomingVideoSubscription)) {
          this.usersHere.forEach(({ id }) => {
            this.usersStore.setVideoLoaded(id, true);
          });
          this.notifyUpdatesInAvatarsListDebounced(userIdsForIncomingVideoSubscription);
        }
      },
    },
    visible: {
      deep: true,
      immediate: true,
      handler() {
        this.staggerCount = 0;
      },
    },
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.display = 'none';
    },
    staggerIn(i, inOut, count) {
      if (inOut) {
        if (inOut === 'in' && count) {
          var time = count > 2 ? 200 * count : 400 * count;
          time = time > 3000 ? 3000 : time;
          return -time * Math.cos((i / count) * (Math.PI / 2)) + time;
        } else if (inOut === 'move') {
          return -time * Math.cos((i / count) * (Math.PI / 2)) + 400;
        } else {
          return 20 * i * i;
        }
      } else {
        return 20 * i * i;
      }
    },
    enter(el, done) {
      let id = parseInt(el.dataset.userId, 10);

      if (this.allUsers?.length > 0 && this.pageStore.isMeeting && id === this.me.id) {
        if (!this.usersStore.iAmAway && !this.me.inWaitingRoom) {
          this.$videomanager.connect();
        }
      }

      if (this.allUsers && this.allUsers.length > 0 && this.pageStore.isMeeting) {
        const externalUserId = nM.buildExternalUserId(id);
        const tile = Object.values(this.$videomanager.tiles).find((t) => t.boundExternalUserId === externalUserId && !t.isContent);

        if (tile) {
          const targetElementId = `video-${tile.boundExternalUserId}`;
          this.$videomanager.attach(tile, targetElementId);
          this.$videomanager.bindVideoElement(tile, targetElementId);
        }
      }

      if (this.usersStore.hide) {
        Velocity(
          el,
          {
            opacity: 0,
            scale: 0,
          },
          {
            display: 'block',
            duration: 0,
            complete: () => {
              done();
              if (id === this.me.id) {
                this.initRender = false;
                this.usersStore.finishedAddingUsers();
              }
            },
          },
        );
      } else {
        Velocity(el, 'transition.blipIn', {
          display: 'block',
          easing: anim.BASE_EASE_IN,
          duration: anim.BASE_SPEED,
          begin: () => {
            if (!this.usersStore.needsNoBoopList.has(id)) {
              this.$sfx('blipIn', this.staggerCount, this.currentAvatarPageList.length);
              this.usersStore.setNeedsNoBoop(id, true);
            }
            _.delay(() => {
              if (id === this.me.id) {
                this.initRender = false;
                this.usersStore.finishedAddingUsers();
              }
            }, anim.BASE_SPEED);
          },
          delay: this.staggerIn(this.staggerCount, 'in', this.currentAvatarPageList.length) + 600,
          complete: () => {
            done();
            this.$ee.emit('nM:animatedAvatarIn:' + id);

            this.$ee.emit(`nM:avatar:stopMoving:${id}`);
          },
        });

        if (this.currentAvatarPageList && this.currentAvatarPageList.includes(id)) {
          this.staggerCount++;
        }
      }
    },
    leave(el, done) {
      Velocity(el, 'transition.blipOut', {
        delay: 0,
        easing: anim.BASE_EASE_OUT,
        duration: anim.BASE_SPEED,
        begin: function () {
          // audio({
          //   type: 'in',
          //   noStagger: noStagger,
          //   i: args.showI || 0,
          //   users: args.users || [id]
          // });
        },
        complete: () => {
          done();
          this.$ee.emit('avatarRemoved', {
            id: parseInt(el.dataset.userId, 10),
          });
        },
      });
    },
    nextAvatars() {
      this.usersStore.updatePage(this.avatarPage + 1);
    },
    prevAvatars() {
      this.usersStore.updatePage(this.avatarPage - 1);
    },
    clicked(e) {
      this.$ee.emit('nM:countersClicked', e);
    },
    handleEmote({ triggeredBy, emote }) {
      if (this.me.id === triggeredBy) {
        return false;
      }
      let page = this.whatPage(triggeredBy);
      if (this.phaseStore.currentPhase === 'context' && triggeredBy === this.speaking.id) {
        this.$ee.emit(`nM:avatar:emote:${triggeredBy}`, emote);
      } else if (page > this.avatarPage) {
        this.$ee.emit('nM:emoteEmitter:forward', emote);
      } else if (page < this.avatarPage) {
        this.$ee.emit('nM:emoteEmitter:back', emote);
      } else {
        this.$ee.emit(`nM:avatar:emote:${triggeredBy}`, emote);
      }
    },
    isAvatarsListSame(ov, nv) {
      if (ov.length !== nv.length) {
        return false;
      }
      return ov.every((uId) => nv.includes(uId));
    },
    notifyUpdatesInAvatarsList(userIdsForIncomingVideoSubscription) {
      this.$videomanager.onAvatarsListUpdated(userIdsForIncomingVideoSubscription.map((id) => nM.buildExternalUserId(id)));
      this.userIdsForIncomingVideoSubscription = userIdsForIncomingVideoSubscription;
    },
  },
};
</script>
<style lang="scss" scoped>
.counterFrame {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: rem($log-height);
  right: 0;
  display: flex;

  & > .avatars,
  .addToGroupContainer {
    position: absolute;
    transition: top 300ms, left 300ms;
  }

  &.noLog {
    bottom: 0;
  }
}

.avatarPager {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--c__text);
  transition: opacity 0.5s, color 0.3s;
  z-index: 100;

  button {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--c__text);
    transition: opacity 0.5s, color 0.3s;
    &[disabled] {
      opacity: 0.25;
      //z-index: 0;
    }
    :deep(path) {
      fill: currentColor;
    }
    @include triggered {
      color: var(--c__accent);
      &[disabled] {
        color: var(--c__text);
      }
    }
  }

  .span,
  :deep(svg) {
    pointer-events: none;
  }

  .isSpeaking {
    pointer-events: none;
    & > * {
      position: absolute;
    }
    .volumeContainer {
      left: 50%;
      height: 4rem;
      width: 4rem;
      margin: -2rem 0 0 -2rem;
    }
    .volume {
      border-width: 1px;
    }
    .label {
      line-height: 1;
      color: var(--c__text);
      text-align: center;
    }
  }

  &.horizontal {
    width: rem(12px);
    button {
      width: rem(12px);
      height: rem(21px);
      margin-top: rem(-10.5px);
      :deep(svg) {
        width: rem(12px);
        height: rem(21px);
      }
    }

    .isSpeaking > * {
      top: 50%;
    }

    .label {
      margin-top: rem(30px);
    }

    &.back {
      left: rem(18px);
      .label {
        left: 0.5em;
      }
    }
    &.forward {
      right: rem(18px);
      .label {
        right: 0.5em;
      }
    }
  }

  &.vertical {
    height: rem(48px);
    button {
      width: 100%;
      height: rem(48px);
      :deep(svg) {
        width: rem(21px);
        height: rem(12px);
      }
    }

    .label {
      left: 0;
    }

    &.back .label {
      bottom: rem(30px);
    }
    &.forward .label {
      top: rem(30px);
    }
  }
}
</style>
