<template>
  <transition name="snapInFade">
    <div
      v-if="showLabel && !moving"
      class="labels"
      :class="{ focused: coreHovered }"
      ref="labels"
      :style="{ top: top + 'px', width: w + 'px', marginLeft: marginLeft + 'px' }"
    >
      <p class="name">
        {{ desensitizedFirstName }}
        <span v-if="featuresStore.showConnectivityIndicator"><br />(signal: {{ signal }}, missed pongs: {{ missedPongs }})</span>
        <transition name="basic">
          <span v-if="userUnstable" class="badSignal" @mouseenter="showBandwidthBubble = true" @mouseleave="showBandwidthBubble = false">
            <app-icon icon="bandwidth" />
            <span class="visuallyHidden">Low Bandwidth&hellip;</span>
            <bubble-tip
              name="showBandwidthBubble"
              class="small warning leftTail"
              :show="showBandwidthBubble"
              direction="above"
              @close="showBandwidthBubble = false"
            >
              Low bandwidth&hellip;
            </bubble-tip>
          </span>
        </transition>
      </p>
    </div>
  </transition>
</template>

<script>
import { mapState as mapPiniaState } from 'pinia';
import { useFeaturesStore } from '@/store/pinia/features';
import { usePhaseStore } from '@/store/pinia/phase';
import { useFrameStore } from '@/store/pinia/frame';
import { useUsersStore } from '@/store/pinia/users';
import { useMeetingStore } from '@/store/pinia/meeting';
import utils from '../resources/utils';

import AppIcon from '@/components/AppIcon.vue';
import BubbleTip from '@/components/BubbleTip.vue';
import * as geometry from '@/resources/geometry';
import { CONTROL_CLEARANCE } from '@/resources/constants/frame-constants';
export default {
  components: {
    AppIcon,
    BubbleTip,
  },
  props: ['user', 'pos', 'index', 'count', 'showControls', 'coreHovered', 'emoting'],
  setup() {
    const featuresStore = useFeaturesStore();
    const phaseStore = usePhaseStore();
    const frameStore = useFrameStore();
    const usersStore = useUsersStore();
    return { featuresStore, phaseStore, frameStore, usersStore };
  },
  data() {
    return {
      showBandwidthBubble: false,
      unit: 'px',
    };
  },
  mounted() {},
  beforeUnmount() {},
  computed: {
    ...mapPiniaState(useMeetingStore, ['missedPongs']),
    ...mapPiniaState(useUsersStore, ['allUsers', 'userOnMyPage', 'unstableNetwork']),
    ...mapPiniaState(useUsersStore, {
      hasSpinner(s) {
        return s.userHasSpinner(this.user.id);
      },
      moving(s) {
        return s.isMoving(this.user.id);
      },
    }),
    actualTopEdge() {
      return parseInt(this.pos.top) - this.w / 2;
    },
    userUnstable() {
      return this.unstableNetwork.has(this.user.id);
    },
    homeAngle() {
      if (this.usersStore.positionDetails.vertical) {
        return geometry.QUARTER_TURN * 2;
      } else {
        return this.actualTopEdge < CONTROL_CLEARANCE ? geometry.QUARTER_TURN : 0 - geometry.QUARTER_TURN;
      }
    },
    showLabel() {
      if (this.moving) {
        return false;
      }
      if (!this.onMyPage || !this.usersStore.firstShown || !this.coreHovered) {
        return false;
      }

      if (this.featuresStore.useBadgeEmotes && this.emoting) {
        return false;
      }

      if ((this.homeAngle > 0 || this.pos.w < 130) && this.showControls) {
        return false;
      }

      if (this.featuresStore.showAllEmotes && this.user.me) {
        return false;
      }

      return true;
    },
    w() {
      return parseInt(this.pos.width);
    },
    marginLeft() {
      return this.w / -2;
    },
    top() {
      return this.hasSpinner ? this.w * 0.325 : this.w * 0.5;
    },
    left() {
      return this.pos.left;
    },
    connected() {
      return this.user.connected;
    },
    desensitizedFirstName() {
      return this.user && this.user.first_name ? utils.desanitizeString(this.user.first_name) : '';
    },
    onMyPage() {
      return this.userOnMyPage(this.user.id);
    },
  },
  watch: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.labels {
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z__avatar-base-labels;
  text-align: center;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  margin-top: rem(-14px);
  @include type-size(small);

  .ownerTitle {
    font-weight: 300;
    display: none;
  }

  p {
    background: var(--c__bg);
    border-radius: rem(9px);
    padding: rem(5px) rem(13px);
    border: 1px solid var(--c__edges);
    line-height: 1em;

    position: relative;
    line-height: 1;
    color: var(--c__text-soft);
    margin-bottom: 0;
  }

  .badSignal {
    width: rem(13px);
    height: rem(11px);
    margin: 0 0 0 0.5em;
    position: relative;
    top: 0.1em;
    display: inline-block;
    color: $c_orange;
    .bubbleTip {
      width: rem(130px);
    }
  }
}
</style>
