import P from 'bluebird';
import EventEmitter from 'wolfy87-eventemitter';

const debug = require('debug')('bixe:videomanagers:mock');

export default class MockVideoManager extends EventEmitter {
  constructor() {
    super();

    this.tiles = {};
  }

  init() {
    debug('init');
    setTimeout(() => {
      this.emit('ready');
    }, 0);
  }

  destroy() {
    debug('destroy');
    setTimeout(() => {
      this.emit('destroyed');
    }, 0);
  }

  connect() {
    debug('connect');
    setTimeout(() => {
      this.emit('connected');
    }, 0);
  }

  disconnect() {
    debug('disconnect');
    setTimeout(() => {
      this.emit('disconnected');
    }, 0);
  }

  start({ devices, settings, audioElement }) {
    debug('start: devices = %j, settings = %j, audioElement = %o', devices, settings, audioElement);
  }

  stop(dispose) {
    debug('stop: dispose = %s', dispose);
    setTimeout(() => {
      this.emit('stopped', dispose);
    }, 0);
  }

  async restart({ devices }) {
    debug('restart: dispose = %j', devices);
  }

  startScreen({ targetElement }) {
    debug('startScreen: targetElement = %o', targetElement);
  }

  stopScreen() {
    debug('stopScreen');
  }

  startPreview({ devices, targetElement }) {
    debug('startPreview: devices = %j, targetElement = %o', devices, targetElement);
  }

  stopPreview({ targetElement }) {
    debug('stopPreview: targetElement = %o', targetElement);
  }

  startAudioPreview({ devices }) {
    debug('startAudioPreview: devices = %j', devices);
  }

  stopAudioPreview() {
    debug('stopAudioPreview');
  }

  bindVideoElement(tile, targetElementId) {
    debug('bindVideoElement: tile = %j, targetElementId = %o', tile, targetElementId);
  }

  unbindVideoElement(tileId) {
    debug('unbindVideoElement: tileId = %j', tileId);
  }

  attach(tile, targetElementId) {
    debug('attach: tile = %j, targetElementId = %s', tile, targetElementId);
  }

  detach(targetElementId) {
    debug('detach: targetElementId = %s', targetElementId);
  }

  mute() {
    debug('mute');
  }

  unmute() {
    debug('unmute');
  }

  muteVideo() {
    debug('muteVideo');
  }

  unmuteVideo() {
    debug('unmuteVideo');
  }

  updateSettings({ devices, type }) {
    if (type === 'audioSource') {
      this.audioSource = devices.audioSource;
    }

    if (type === 'videoSource') {
      this.videoSource = devices.videoSource;
    }
  }

  startNetworkTest() {
    debug('startNetworkTest');

    return new P((resolve) => {
      setTimeout(() => {
        resolve({});
      }, 5000);
    });
  }

  subscribeToVolumeIndicator(presentAttendeeId) {
    let counter = 0;
    let intervalIdentifier = setInterval(() => {
      if (++counter === 10) {
        return clearInterval(intervalIdentifier);
      }
      this.emit('attendeeVolumeChange', { attendeeId: presentAttendeeId, volume: Math.floor(Math.random() * 10) });
    }, 2000);
  }
}
