import Connectivity from '@/services/connectivity.service';

let installed = false;

export default {
  install: (app) => {
    if (installed) return;

    // TODO: refactor it, not the best solution
    app.config.globalProperties.$connectivity = new Connectivity(app);
  },
};
