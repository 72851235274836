<template>
  <div class="notesFrame" :class="[openClass, feedbackClass]" ref="frame">
    <transition name="notes-slide">
      <meeting-notes-bar v-show="showBar" ref="bar" />
    </transition>
    <transition name="notes-slide">
      <div v-show="showNotes" class="notes" ref="notes">
        <transition-group name="basic" tag="header">
          <h3 key="h">{{ title }}</h3>
          <nav v-if="currentPhase === 'feedback' && meetingStore.useNotes" class="noteTypeToggle">
            <span>
              <input type="radio" v-model="showFeedback" :value="false" id="barNotesToggle" class="visuallyHidden" />
              <label for="barNotesToggle">Meeting Notes</label>
            </span>
            <span>
              <input type="radio" v-model="showFeedback" :value="true" id="barFeedbackToggle" class="visuallyHidden" />
              <label for="barFeedbackToggle">Feedback Notes</label>
            </span>
          </nav>
          <button v-if="barMode === 'moments'" class="groupedToggle" :class="{ isGrouped: isGrouped }" key="tag" @click="toggleGrouped">Tag&nbsp;</button>
          <button key="download" class="btn-borderless download" title="Download Notes" @click.prevent="download">
            <app-icon icon="download" />
          </button>
          <app-button
            key="hide"
            @real-click="hide"
            button-class="btn-borderless close"
            :disabled="(phaseStore.currentPhase === 'review' || isFeedbackReview) && !someoneIsMirroring"
            wobble="true"
            title="Close log"
            ><app-icon icon="log-close" />
          </app-button>
        </transition-group>
        <meeting-notes-list :notes="currentNotes" :allNotes="filteredAllNotes" :showing="showNotes" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import MeetingNotesBar from '@/components/MeetingNotesBar.vue';
import MeetingNotesList from '@/components/MeetingNotesList.vue';
import AppButton from '@/components/AppButton.vue';
import AppIcon from '@/components/AppIcon.vue';
import { useMeetingStore } from '@/store/pinia/meeting';
import { useMomentsStore } from '@/store/pinia/moments';
import { usePageStore } from '@/store/pinia/page';
import { usePhaseStore } from '@/store/pinia/phase';
import { useUsersStore } from '@/store/pinia/users';

const $ee = getCurrentInstance().appContext.config.globalProperties.$ee;

const meetingStore = useMeetingStore();
const momentsStore = useMomentsStore();
const pageStore = usePageStore();

const phaseStore = usePhaseStore();
const usersStore = useUsersStore();
const filter = ref(false);
const hasUpdated = ref(false);
const lastEmitted = ref(false);
const showFeedback = ref(false);

onMounted(() => {
  $ee.on('bus:showNotes', show);
  $ee.on('nM:environmentClicked', handleOutside);
  $ee.on('nM:countersClicked', handleOutside);
  if ((currentPhase.value === 'review' || phaseStage.value === 'review') && !someoneIsMirroring.value) {
    momentsStore.openMoments();
  }
});
onBeforeUnmount(() => {
  $ee.off('bus:showNotes', show);
  $ee.off('nM:environmentClicked', handleOutside);
  $ee.off('nM:countersClicked', handleOutside);
});

const { isFeedback, someoneIsMirroring, currentPhase, phaseStage, currentPhaseId, isPresentation } = storeToRefs(phaseStore);
const { myId, iAmInWaitingRoom } = storeToRefs(usersStore);
const { ordered: logs, momentsOpen: frameOpen, isGrouped, barMode } = storeToRefs(momentsStore);
const { isMeeting } = storeToRefs(pageStore);
const { initTitleCardShown, useNotes } = storeToRefs(meetingStore);

const isFeedbackReview = computed(() => isFeedback.value && phaseStage.value === 'reviewing');
const feedbackClass = computed(() => (isFeedbackReview.value ? 'feedbackReview' : false));
const title = computed(() => {
  if (isFeedback.value) {
    return useNotes.value ? 'Notes' : 'Feedback Notes';
  } else {
    return 'Key Moments';
  }
});
const openClass = computed(() => (frameOpen.value ? 'open' : 'closed'));
const showNotes = computed(() => showBar.value && frameOpen.value);
const filteredAllNotes = computed(() => {
  if (barMode.value === 'feedback') {
    return logs.value.filter((l) => ['good', 'tricky', 'different'].includes(l.type) && l.phaseId === currentPhaseId.value);
  } else {
    return logs.value.filter((l) => !['good', 'tricky', 'different'].includes(l.type));
  }
});

const currentNotes = computed(() => (filter.value ? logs.value.filter((l) => l.type === filter.value) : filteredAllNotes.value));

const showBar = computed(() => {
  if (!isMeeting.value || !initTitleCardShown.value || ['lobby', 'endMeeting'].includes(currentPhase.value) || iAmInWaitingRoom.value) {
    return false;
  }

  if (!useNotes.value && !['review', 'feedback'].includes(currentPhase.value)) {
    return false;
  }

  if (!useNotes.value && currentPhase.value === 'review' && isPresentation.value) {
    return false;
  }

  return true;
});

watch(showFeedback, (nv) => {
  if (nv) {
    momentsStore.changeBarMode('feedback');
  } else {
    momentsStore.changeBarMode('moments');
  }
});

watch(logs, updateNotes, { deep: true });
watch(
  isFeedbackReview,
  (nv) => {
    if (nv) {
      momentsStore.changeBarMode('feedback');
      showFeedback.value = true;
      momentsStore.groupMoments();
      momentsStore.openMoments();
    } else {
      momentsStore.ungroupMoments();
      momentsStore.closeMoments();
    }
  },
  {
    immediate: true,
  },
);

watch(someoneIsMirroring, (nv) => {
  if (nv) {
    momentsStore.closeMoments();
  } else {
    if (currentPhase.value === 'review') {
      momentsStore.openMoments();
    }

    if (currentPhase.value === 'feedback' && phaseStage.value === 'reviewing') {
      momentsStore.openMoments();
    }
  }
});

watch(
  currentPhase,
  (nv, ov) => {
    if (nv === 'review' && !someoneIsMirroring.value) {
      momentsStore.groupMoments();
      show();
    }

    if (ov === 'review' && nv !== 'review') {
      momentsStore.ungroupMoments();
      hide();
    }

    if (nv === 'endMeeting') {
      hide();
    }

    if (nv === 'feedback') {
      showFeedback.value = true;
      momentsStore.changeBarMode('feedback');
    } else {
      showFeedback.value = false;
      momentsStore.changeBarMode('moments');
    }
  },
  {
    immediate: true,
  },
);

watch(isMeeting, (nv) => {
  if (!nv) {
    hide();
  }
});

const hide = momentsStore.closeMoments;
const show = momentsStore.openMoments;

function toggleGrouped() {
  if (isGrouped.value) {
    momentsStore.ungroupMoments();
  } else {
    momentsStore.groupMoments();
  }
}

function handleOutside() {
  if (currentPhase.value !== 'review' && !isFeedbackReview.value) {
    hide();
  }
}

function updateNotes() {
  if (logs.value && hasUpdated.value) {
    let latest = logs.value[logs.value.length - 1];
    if (!lastEmitted.value || logs.value.length < 1 || lastEmitted.value.id !== latest.id) {
      lastEmitted.value = latest;
      if (latest && latest.user !== myId.value && latest.type !== 'note') {
        $ee.emit('nM:emoteEmitter:log', latest.type);
      }
    }
  }
  hasUpdated.value = true;
}
function download() {
  $ee.emit('bus:downloadNotes', { isMeetingEnded: false });
}
</script>
<style lang="scss" scoped>
.noteTypeToggle {
  margin: 0 auto 0 0;
  border-bottom: solid 2px transparent;
  label {
    display: inline-block;
    padding-bottom: rem(4px);
    line-height: math.div(17em, 14);
    margin: 0 rem(21px);
  }
  input:checked + label {
    border-bottom: solid 2px currentColor;
  }
}
</style>
